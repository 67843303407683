import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';
import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';

import { useLoadsServiceSearchLazyQuery } from 'generated/graphql';
import { Autocomplete } from 'components';

import styles from './CustomModal.module.scss';
import { queue } from 'utilities';

export const SearchLoadService = ({ open, setOpen, additionalModalProps }: any) => {
  const { formState, control, handleSubmit, setError, clearErrors, reset, watch } = useForm();
  const { errors } = formState;
  const [Service] = watch(['Service']);
  const [searchServices, { data: SearchData, loading: SearchLoading }] = useLoadsServiceSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });

  React.useEffect(() => {
    searchServices({ variables: { search: `%%` } });
    // eslint-disable-next-line
  }, ['open']);

  const onSubmit = async (data: any) => {
    try {
      const variables = { ...additionalModalProps.variables };
      variables[additionalModalProps.updateFieldName] = data.Service.ServiceId;
      const { errors } = await additionalModalProps.updateFn({ variables });
      if (errors?.length > 0) {
        throw new Error(errors[0].message);
      }
      queue.clearAll();
      queue.notify({
        icon: 'done',
        title: 'Success!',
        body: `Service Linked!`,
      });
      setOpen(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (ex) {
      queue.clearAll();
      queue.notify({
        icon: 'priority_high',
        title: 'Oh oh, error!',
        body: ex.message,
      });
      setError('api', { type: 'manual', message: ex.message });
    }
  };

  return (
    <div className={styles.customModal}>
      <Dialog
        className={styles.SearchLoadService}
        open={open}
        onClose={(evt) => {
          reset();
          clearErrors();
          if (open) {
            setOpen(false);
          }
        }}>
        <form onSubmit={handleSubmit(onSubmit)} data-private autoComplete="off">
          <DialogTitle>Search Load Service</DialogTitle>
          <DialogContent>
            <div className={styles.row}>
              <Controller
                name="Service"
                control={control}
                rules={{
                  required: 'Please select a service',
                }}
                render={({ field }) => (
                  <Autocomplete
                    label="Service"
                    data={SearchData?.dw_LoadsService || []}
                    labelKey="Label"
                    valueKey="ServiceId"
                    loading={SearchLoading}
                    getData={searchServices}
                    value={Service}
                    callback={(item) => {
                      field.onChange(item.FullData);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.Service?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.Service.message}</Typography>
                </div>
              ) : null}
            </div>
            <div className={styles.row}>
              {errors.api && (
                <div className={styles.error} style={{ marginBottom: '16px' }}>
                  <Typography use="caption">{errors.api.message}</Typography>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-between' }}>
            <Button
              type="button"
              label="Close"
              disabled={formState.isSubmitting}
              outlined
              onClick={() => {
                setOpen(false);
                clearErrors();
                reset();
              }}
            />
            <Button
              raised
              disabled={formState.isSubmitting}
              label={formState.isSubmitting ? 'Saving' : 'Save'}
              icon={formState.isSubmitting ? <CircularProgress theme="secondary" /> : null}
              onClick={() => {
                clearErrors();
              }}
              type="submit"
            />
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default SearchLoadService;
