import apolloClient from 'apollo';
import moment from 'moment';
import {
  AccountChannelDataForCsvImportQuery,
  UpdateChannelBulkItemDocument,
  UpdateChannelBulkItemMutationVariables,
} from 'generated/graphql';
import { CsvResult, MappedChannelCsvRow } from './interfaces';
import { SummationTypeEnum } from './enum';

export const handleChannelCsvImport = async (
  existingData: AccountChannelDataForCsvImportQuery,
  rows: MappedChannelCsvRow[],
  accountId?: string,
  trialRun?: boolean
) => {
  const csvResult = {
    Channel: { complete: [], error: [] },
  } as CsvResult;
  sessionStorage.setItem('csvResult', JSON.stringify(csvResult));
  const clonedExistingData = JSON.parse(JSON.stringify(existingData));
  // @ts
  await processRow(rows, 0, clonedExistingData, csvResult, accountId, trialRun);
  return csvResult;
};

const processRow = async (
  rows: MappedChannelCsvRow[],
  index: number,
  existingData: AccountChannelDataForCsvImportQuery,
  csvResult: CsvResult,
  accountId?: string,
  trialRun?: boolean
) => {
  const row = rows[index];
  if (!row) {
    return;
  }
  // row.AccountId = accountId;
  if (row.ChannelId) {
    await processItem(row, existingData, csvResult, trialRun);
    sessionStorage.setItem('csvResult', JSON.stringify(csvResult));
  }
  // start next row
  await processRow(rows, index + 1, existingData, csvResult, accountId, trialRun);
};

const processItem = async (
  row: MappedChannelCsvRow,
  existingData: AccountChannelDataForCsvImportQuery,
  csvResult: CsvResult,
  trialRun?: boolean
) => {
  let matchFn = (x: any) => {
    return x['ChannelId'] === row['ChannelId'];
  };
  const existsInDb = existingData[`dw_Channels`].find(matchFn);
  const payload = mapCsvToDbObj(row);
  // @ts-ignore
  if (payload.clientValidationError) {
    // @ts-ignore
    csvResult['Channel'].error.push({ ...payload, Error: payload.clientValidationError });
  } else {
    if (!existsInDb) {
      // @ts-ignore
      csvResult['Channel'].error.push({ ...payload, Error: "Couldn't match load id" });
    } else {
      if (!trialRun) {
        // @ts-ignore
        delete payload.clientValidationError;

        try {
          const result = await apolloClient.mutate({
            mutation: UpdateChannelBulkItemDocument,
            variables: payload,
            update: () => {
              return payload.ChannelId;
            },
          });
          if (result?.data?.update_dw_Channels_by_pk?.ChannelId) {
            csvResult['Channel'].complete.push(payload);
          } else {
            // @ts-ignore
            csvResult['Channel'].error.push({ ...payload, Error: result.message });
          }
        } catch (ex) {
          csvResult['Channel'].error.push({ ...payload, Error: ex?.message || ex.toString() });
        }
      } else {
        csvResult['Channel'].complete.push(payload);
      }
    }
  }
};

const mapCsvToDbObj = (row: MappedChannelCsvRow) => {
  return {
    ChannelId: row.ChannelId,
    LoadId: row.LoadId,
    FeedId: row.FeedId,
    ChannelKey: row.ChannelKey,
    Label: row.Label,
    Type: row.Type,
    SummationType: row.SummationType || SummationTypeEnum.SUM,
    ValueTypeEnum: row.ValueTypeEnum,
    IntervalFrequency: row.IntervalFrequency,
    IntervalMins: row.IntervalMins,
    TimeZone: row.TimeZone,
    EnableAlerts: row.EnableAlerts?.toString() === 'true',
    Status: row.Status || 1,
    UpdatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
    NatureType: row.NatureType,
  } as UpdateChannelBulkItemMutationVariables;
};
