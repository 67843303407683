import React from 'react';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';
import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';
import { TextField } from '@rmwc/textfield';

import { queue, useAppState } from 'utilities';

import styles from './CustomModal.module.scss';

export const TriggerCalculatedChannelsModal = ({ open, setOpen, additionalModalProps }: any) => {
  const { triggerCalculatedChannels } = useAppState();
  const { formState, control, handleSubmit, setError, clearErrors, reset } = useForm();
  const { errors } = formState;

  const onSubmit = async (data: any) => {
    try {
      const diff = moment(data.endDate, 'YYYY-MM-DD').diff(moment(data.startDate, 'YYYY-MM-DD'), 'days');
      if (diff > 366) {
        throw new Error('Maximum job period is 365 days! Please adjust and retry.');
      }
      if (moment(data.endDate).isAfter(moment().startOf('day'))) {
        throw new Error('You cannot trigger calculated jobs in the future');
      }
      const variables = {
        channelId: additionalModalProps.variables.id,
        from: `${data.startDate}`,
        to: `${data.endDate}`,
      };

      const result = await triggerCalculatedChannels(variables);
      if (!result) {
        throw new Error('Failed to trigger Calculated Channels Job');
      }
      queue.clearAll();
      queue.notify({
        icon: 'done',
        title: 'Success!',
        body: `Calculated Channels Job Triggered!`,
      });
      setOpen(false);
    } catch (ex) {
      queue.clearAll();
      queue.notify({
        icon: 'priority_high',
        title: 'Oh oh, error!',
        body: ex.message,
      });
      setError('api', { type: 'manual', message: ex.message });
    }
  };

  return (
    <div className={styles.customModal}>
      <Dialog
        className={styles.triggerCalculatedChannels}
        open={open}
        onClose={(evt) => {
          reset();
          clearErrors();
          if (open) {
            setOpen(false);
          }
        }}>
        <form onSubmit={handleSubmit(onSubmit)} data-private autoComplete="off">
          <DialogTitle>Trigger Calculated Channels Job</DialogTitle>
          <DialogContent>
            <div className={styles.row}>
              <Controller
                name="startDate"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={moment().startOf('year').subtract(1, 'year').format('YYYY-MM-DD')}
                render={({ field }) => (
                  <TextField
                    label="Start Date"
                    name="startDate"
                    type="date"
                    outlined
                    required
                    autoFocus
                    floatLabel={true}
                    {...field}
                  />
                )}
              />
              {errors.startDate && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a valid date DD/MM/YYYY</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <Controller
                name="endDate"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={moment().startOf('year').format('YYYY-MM-DD')}
                render={({ field }) => (
                  <TextField
                    label="End Date"
                    name="endDate"
                    type="date"
                    outlined
                    required
                    autoFocus
                    floatLabel={true}
                    {...field}
                  />
                )}
              />
              {errors.endDate && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a valid date DD/MM/YYYY</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              {errors.api && (
                <div className={styles.error} style={{ marginBottom: '16px' }}>
                  <Typography use="caption">{errors.api.message}</Typography>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-between' }}>
            <Button
              type="button"
              label="Close"
              disabled={formState.isSubmitting}
              outlined
              onClick={() => {
                setOpen(false);
                clearErrors();
                reset();
              }}
            />
            <Button
              raised
              disabled={formState.isSubmitting}
              label={formState.isSubmitting ? 'Triggering' : 'Trigger'}
              icon={formState.isSubmitting ? <CircularProgress theme="secondary" /> : null}
              onClick={() => {
                clearErrors();
              }}
              type="submit"
            />
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default TriggerCalculatedChannelsModal;
