import React from 'react';
import Select from 'react-select';

import styles from './Autocomplete.module.scss';

interface AutocompleteProps {
  label: string;
  labelKey: string;
  valueKey: string;
  loading: boolean;
  data: any;
  defaultValue?: any;
  value?: any;
  isDisabled?: boolean;
  forceEnabled?: boolean;
  isMulti?: boolean;
  getData: (query: any) => any;
  callback: (result: any) => void;
}

export const Autocomplete = ({
  label,
  labelKey,
  valueKey,
  loading,
  data,
  defaultValue,
  value,
  isDisabled,
  forceEnabled,
  isMulti,
  getData,
  callback,
}: AutocompleteProps) => {
  let debounceTimeout = null as any;
  const target = document.querySelector('body');

  return (
    <div className={styles.customAutocomplete}>
      <label className={styles.label}>{label}</label>
      <Select
        className={'custom-autocomplete'}
        classNamePrefix="custom-autocomplete-inner"
        menuPortalTarget={target}
        menuPlacement="bottom"
        isLoading={loading}
        isClearable={true}
        isDisabled={forceEnabled ? false : isDisabled || defaultValue}
        options={data}
        defaultValue={defaultValue}
        getOptionLabel={(option) => option[labelKey]}
        getOptionValue={(option) => option[valueKey]}
        isMulti={isMulti}
        value={value}
        onInputChange={(inputValue) => {
          clearTimeout(debounceTimeout);
          debounceTimeout = setTimeout(() => {
            if (inputValue) {
              getData({ variables: { search: `%${inputValue}%` } });
            }
          }, 500);
        }}
        onChange={(option) => {
          if (isMulti) {
            callback(option.map((x) => ({ label: x[labelKey], value: x[valueKey], FullData: x })));
          } else {
            callback(option ? { label: option[labelKey], value: option[valueKey], FullData: option } : null);
          }
        }}
      />
    </div>
  );
};

export default Autocomplete;
