import React from 'react';
import { useHistory } from 'react-router-dom';

import { SimpleMenu } from '@rmwc/menu';
import { Button } from '@rmwc/button';
import { Typography } from '@rmwc/typography';
import { TopAppBar, TopAppBarFixedAdjust, TopAppBarRow, TopAppBarSection, TopAppBarTitle } from '@rmwc/top-app-bar';

import { logout, useAppState } from 'utilities';

import styles from './Navbar.module.scss';
export interface NavbarComponentProps {
  open: boolean;
  toggleDrawer: () => void;
  setOpen: (open: boolean) => void;
}

export const NavbarComponent: React.FC<NavbarComponentProps> = ({ open, toggleDrawer, setOpen }) => {
  const { user, toggleModal } = useAppState();
  const history = useHistory();
  return (
    <div className={styles.navbar}>
      <TopAppBar fixed>
        <TopAppBarRow className={styles.navbarRow}>
          <TopAppBarSection alignStart className={styles.logoWrapper}>
            <TopAppBarTitle className={styles['title']}>
              <a
                id="logo"
                className={styles.logoLink}
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push('/app');
                }}>
                <div className={styles.logo} />
              </a>
            </TopAppBarTitle>
          </TopAppBarSection>
          <TopAppBarSection alignStart className={styles.logoWrapper}>
            <TopAppBarTitle className={styles['title']}>
              <span className={`${styles.dynamicHeading} dynamicHeading`} />
            </TopAppBarTitle>
          </TopAppBarSection>
          <TopAppBarSection className={styles.searchWrapper}>{/* <SearchComponent /> */}</TopAppBarSection>
          <TopAppBarSection className={styles.profileWrapper} alignEnd>
            <div className={styles.buttonWrapper} style={{ marginRight: '16px' }}>
              <Button
                label="Bulk Update Loads"
                raised
                theme={['secondaryBg']}
                onClick={() => {
                  toggleModal('importCsvLoad', true);
                }}
              />
            </div>

            <div className={styles.buttonWrapper} style={{ marginRight: '16px' }}>
              <Button
                label="Bulk Update Channels"
                raised
                theme={['secondaryBg']}
                onClick={() => {
                  toggleModal('importCsvChannel', true);
                }}
              />
            </div>

            <div className={styles.buttonWrapper} style={{ marginRight: '16px' }}>
              <Button
                label="Bulk Insert"
                raised
                theme={['secondaryBg']}
                onClick={() => {
                  toggleModal('importCsv', true);
                }}
              />
            </div>
            <Profile name={user?.profile?.particulars.displayName} email={user?.profile?.particulars.emailAddress} />
          </TopAppBarSection>
        </TopAppBarRow>
      </TopAppBar>
      <TopAppBarFixedAdjust />
    </div>
  );
};

export const Profile = ({ name, email }) => {
  const [open, setOpen] = React.useState(false);
  const initials = name
    ? name
        .split(' ')
        .map((n) => n[0])
        .join('')
    : '';

  const handleWindowClick = (e) => {
    try {
      // @ts-ignore
      if (!document.getElementById('profileContainer').contains(e.target)) {
        setOpen(false);
      }
    } catch (e) {}
  };
  React.useEffect(() => {
    window.addEventListener('click', handleWindowClick);
    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  });

  return (
    // @ts-ignore
    <SimpleMenu
      anchorCorner="bottomLeft"
      open={open}
      rootProps={{ id: 'profileContainer' }}
      handle={
        <div className={styles.profileLinkWrapper}>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setOpen(!open);
            }}
            className={styles.profileLink}>
            {initials}
          </a>
          <div className={styles.arrow} style={{ opacity: open ? 1 : 0 }} />
        </div>
      }>
      <li className={styles.profile}>
        <div className={styles.profileUser}>
          <div className={styles.initials}>{initials}</div>
          <Typography use="body2" className={styles.profileName}>
            {name}
          </Typography>
          <Typography use="caption" className={styles.profileEmail}>
            {email}
          </Typography>
        </div>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            logout(false);
          }}
          className={styles.signOut}>
          <Typography use="body2">Sign Out</Typography>
        </a>
      </li>
    </SimpleMenu>
  );
};

export default NavbarComponent;
