import apolloClient from 'apollo';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import {
  AccountDataForCsvImportQuery,
  Dw_Buildings_Insert_Input,
  Dw_Channels_Insert_Input,
  Dw_Loads_Insert_Input,
  Dw_Sites_Insert_Input,
  InsertBuildingDocument,
  InsertChannelDocument,
  InsertLoadDocument,
  InsertSiteDocument,
} from 'generated/graphql';
import { MappedCsvRow, utilityObjRev, CsvResult, intervalFrequency, intervalMins, uomTypes } from './interfaces';
import { ChannelTypeEnum, StatusEnum, SummationTypeEnum, ValueTypeEnum } from './enum';
import { uniqueChannelKey } from './utils';

export const handleCsvImport = async (
  existingData: AccountDataForCsvImportQuery,
  rows: MappedCsvRow[],
  accountId?: string,
  feedId?: string,
  trialRun?: boolean
) => {
  const csvResult = {
    Site: { new: [], existing: [], duplicate: [], complete: [], error: [] },
    Building: { new: [], existing: [], duplicate: [], complete: [], error: [] },
    Load: { new: [], existing: [], duplicate: [], complete: [], error: [] },
    Channel: { new: [], existing: [], duplicate: [], complete: [], error: [] },
  } as CsvResult;
  sessionStorage.setItem('csvResult', JSON.stringify(csvResult));
  const clonedExistingData = JSON.parse(JSON.stringify(existingData));
  // @ts
  await processRow(rows, 0, clonedExistingData, csvResult, accountId, feedId, trialRun);
  return csvResult;
};

const processRow = async (
  rows: MappedCsvRow[],
  index: number,
  existingData: AccountDataForCsvImportQuery,
  csvResult: CsvResult,
  accountId?: string,
  feedId?: string,
  trialRun?: boolean
) => {
  const row = rows[index];
  if (!row) {
    return;
  }
  row.AccountId = accountId;
  row.FeedId = feedId;
  if (row.SiteName) {
    await processItem(row, existingData, csvResult, 'Site', 'Account', trialRun);
    sessionStorage.setItem('csvResult', JSON.stringify(csvResult));
  }
  if (row.BuildingName) {
    await processItem(row, existingData, csvResult, 'Building', 'Site', trialRun);
    sessionStorage.setItem('csvResult', JSON.stringify(csvResult));
  }
  if (row.LoadName) {
    await processItem(row, existingData, csvResult, 'Load', 'Building', trialRun);
    sessionStorage.setItem('csvResult', JSON.stringify(csvResult));
  }
  if (row.ChannelName) {
    await processItem(row, existingData, csvResult, 'Channel', 'Load', trialRun);
    sessionStorage.setItem('csvResult', JSON.stringify(csvResult));
  }

  // start next row
  await processRow(rows, index + 1, existingData, csvResult, accountId, feedId, trialRun);
};

const processItem = async (
  row: MappedCsvRow,
  existingData: AccountDataForCsvImportQuery,
  csvResult: CsvResult,
  type: string,
  parentType: string,
  trialRun?: boolean
) => {
  let matchFn = (x: any) => {
    if (x[`${parentType}Id`] && row[`${parentType}Id`] && x.Label && row[`${type}Name`]) {
      return (
        x[`${parentType}Id`] === row[`${parentType}Id`] &&
        x.Label?.trim().toLowerCase() === row[`${type}Name`]?.toLowerCase()
      );
    } else {
      return false;
    }
  };
  if (type === 'Load') {
    if (row.LoadMainIncomer && row.LoadIcpNumber) {
      matchFn = (x: any) => {
        return (
          x[`${parentType}Id`] === row[`${parentType}Id`] &&
          x.IcpNumber?.toString()?.trim() === row.LoadIcpNumber.toString()?.trim()
        );
      };
    }
  } else if (type === 'Channel') {
    matchFn = (x: any) => {
      const ck = x.ChannelKey?.toString().trim() || '';
      const ck2 = row.ChannelKey?.toString().trim() || '';
      return x[`${parentType}Id`] === row[`${parentType}Id`] && ck === ck2;
    };
  }
  const existsInDb = existingData[`dw_${type}s`].find(matchFn);
  let theId = uuidv4();
  const payload = mapCsvToDbObj(row, type);
  // @ts-ignore
  if (payload.clientValidationError) {
    // @ts-ignore
    csvResult[type].error.push({ ...payload, Error: payload.clientValidationError });
  } else {
    if (existsInDb) {
      theId = existsInDb[`${type}Id`];
      row[`${type}Id`] = theId;
      const existsInCsvResult = csvResult[type].existing.find((x) => {
        return x[`${type}Id`] === row[`${type}Id`];
      });
      if (existsInCsvResult) {
        csvResult[type].duplicate.push(existsInDb);
      } else {
        csvResult[type].existing.push(existsInDb);
      }
    } else {
      payload[`${type}Id`] = theId;
      row[`${type}Id`] = theId;
      if (!trialRun) {
        // @ts-ignore
        delete payload.clientValidationError;
        const result = await handleInsertDb(type, payload);
        if (result?.valid) {
          csvResult[type].complete.push(payload);
        } else {
          // @ts-ignore
          csvResult[type].error.push({ ...payload, Error: result.message });
        }
      }
      existingData[`dw_${type}s`].push(payload);
      csvResult[type].new.push(payload);
    }
  }
};

const handleInsertDb = async (type: string, payload: any) => {
  let document = InsertSiteDocument;
  if (type === 'Site') {
    document = InsertSiteDocument;
  } else if (type === 'Building') {
    document = InsertBuildingDocument;
  } else if (type === 'Load') {
    document = InsertLoadDocument;
  } else if (type === 'Channel') {
    document = InsertChannelDocument;
  }
  try {
    const result = await apolloClient.mutate({ mutation: document, variables: { object: payload } });
    if (result?.errors?.length > 0) {
      throw new Error(result.errors[0].message);
    } else {
      return { valid: true, message: '' };
    }
  } catch (ex) {
    return { valid: false, message: ex?.message || ex.toString() };
  }
};

const mapCsvToDbObj = (row: MappedCsvRow, theType: string) => {
  const output = {
    CreatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
    UpdatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
  };
  if (theType === 'Site') {
    return {
      ...output,
      AccountId: row.AccountId,
      Label: row.SiteName,
      Line1: '',
      Line2: '',
      City: row.SiteCity,
      Status: StatusEnum[row.SiteStatus] || 1,
    } as Dw_Sites_Insert_Input;
  } else if (theType === 'Building') {
    return {
      ...output,
      SiteId: row.SiteId,
      Label: row.BuildingName,
      Status: StatusEnum[row.BuildingStatus] || 1,
    } as Dw_Buildings_Insert_Input;
  } else if (theType === 'Load') {
    return {
      ...output,
      BuildingId: row.BuildingId,
      FeedId: row.FeedId,
      Label: row.LoadName,
      IcpNumber: row.LoadIcpNumber?.toString() || '',
      MainIncomer: row.LoadMainIncomer?.toString() === 'true' ? true : false,
      NegotiatedCapacity: isNaN(Number(row.LoadNegotiatedCapacity)) ? 0 : Number(row.LoadNegotiatedCapacity),
      TransformerCapacity: isNaN(Number(row.LoadTransformerCapacity)) ? 0 : Number(row.LoadTransformerCapacity),
      BreakerRating: row.LoadBreakerRating,
      MeterSerialNumber: row.LoadMeterSerialNumber,
      UtilityType: utilityObjRev[row.LoadUtilityType.toLowerCase()] || 0,
      Description: row.LoadDescription,
      // ModbusId: row.LoadModbusId && isNaN(Number(row.LoadModbusId)) === false ? Number(row.LoadModbusId) : 0,
      Status: StatusEnum[row.LoadStatus] || 1,
    } as Dw_Loads_Insert_Input;
  } else if (theType === 'Channel') {
    const idx =
      intervalFrequency.indexOf(row.ChannelIntervalFrequency) > -1
        ? intervalFrequency.indexOf(row.ChannelIntervalFrequency)
        : 1;
    const mins = intervalMins[idx];

    let startDate = null;
    if (row.ChannelStartDate && moment(row.ChannelStartDate).isValid()) {
      startDate = moment(row.ChannelStartDate).format('YYYY-MM-DDTHH:mm:00');
    }

    let valueTypeEnum = 0;
    if (row.ChannelValueType) {
      if (row.ChannelValueType.toLowerCase() === 'actual for interval') {
        valueTypeEnum = ValueTypeEnum.ActualForInterval;
      } else if (row.ChannelValueType === 'Cumulative') {
        valueTypeEnum = ValueTypeEnum.Cumulative;
      } else if (row.ChannelValueType === 'Instantaneous') {
        valueTypeEnum = ValueTypeEnum.Instantaneous;
      }
    }

    // validate UOMs
    // validate summation type
    // validate value type enum

    // VALUE TYPE ENUM AND SUMMATION TYPE MUST BE REQUIRED WHEN CREATING CHANNEL
    let clientValidationError = '';
    if (!row.ChannelUom || (row.ChannelUom && uomTypes.indexOf(row.ChannelUom?.toString().trim()) === -1)) {
      clientValidationError = 'Missing or Invalid Channel UOM';
    }
    if (valueTypeEnum === 0) {
      clientValidationError = 'Missing Value Type Enum';
    }
    return {
      ...output,
      LoadId: row.LoadId,
      FeedId: row.FeedId,
      Label: row.ChannelName,
      ChannelKey: row.ChannelKey ? row.ChannelKey : uniqueChannelKey(),
      UnitOfMeasure: row.ChannelUom?.toString()?.trim(),
      SummationType: row.ChannelSummationType ? SummationTypeEnum[row.ChannelSummationType] : SummationTypeEnum.SUM,
      ValueTypeEnum: valueTypeEnum,
      IntervalFrequency: row.ChannelIntervalFrequency,
      IntervalMins: mins,
      StartDate: startDate,
      TimeZone: row.ChannelTimeZone || 'NZST/NZDT',
      EnableAlerts: row.ChannelEnableAlerts?.toString() === 'true',
      // todo: handle calculated channels
      Type: ChannelTypeEnum.Actual,
      Status: StatusEnum[row.ChannelStatus] || 1,
      // @ts-ignore
      clientValidationError: clientValidationError,
    } as Dw_Channels_Insert_Input;
  }
};
