import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';
import { TextField } from '@rmwc/textfield';
import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';

import { useInsertChannelMappingsMutation, useChannelSearchLazyQuery } from 'generated/graphql';
import { queue, StatusEnum } from 'utilities';
import Autocomplete from 'components/Autocomplete/Autocomplete';

import styles from './CustomModal.module.scss';

export const AddChannelsMappingsModal = ({ open, setOpen }: any) => {
  const { formState, control, register, handleSubmit, setError, clearErrors, reset, watch } = useForm();
  const { errors } = formState;
  const [Channel] = watch(['Channel']);

  const [searchChannels, { data: ChannelSearchData, loading: ChannelSearchLoading }] = useChannelSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [insertFn] = useInsertChannelMappingsMutation();

  const statusFields = [];
  for (let k in Object.keys(StatusEnum)) {
    if (StatusEnum[k]) {
      statusFields.push({ label: StatusEnum[k], value: Number(k) });
    }
  }

  const lastDetail = JSON.parse(sessionStorage.getItem('lastDetail'));

  const onSubmit = async (data: any) => {
    try {
      const variables = {
        object: {
          ChannelId: data.Channel.ChannelId,
          Reference: data.Reference,
          ExternalId: data.ExternalId,
        },
      };
      const { errors } = await insertFn({ variables });
      if (errors?.length > 0) {
        throw new Error(errors[0].message);
      }
      queue.clearAll();
      queue.notify({
        icon: 'done',
        title: 'Success!',
        body: `Channel Mapping Added!`,
      });

      setOpen(false);
      setTimeout(() => {
        window.location.reload();
        // history.push('/app/detail/contacts/' + resultData.insert_dw_Contacts_one.ContactId);
      }, 1000);
    } catch (ex) {
      queue.clearAll();
      queue.notify({
        icon: 'priority_high',
        title: 'Oh oh, error!',
        body: ex.message,
      });
      setError('api', { type: 'manual', message: ex.message });
    }
  };

  React.useEffect(() => {
    searchChannels({ variables: { FeedId: lastDetail.data?.FeedId, search: `%%` } });
    // eslint-disable-next-line
  }, ['open']);
  return (
    <div className={styles.customModal}>
      <Dialog
        className={styles.AddChannelMapping}
        open={open}
        onClose={(evt) => {
          reset();
          clearErrors();
          if (open) {
            setOpen(false);
          }
        }}>
        <form onSubmit={handleSubmit(onSubmit)} data-private autoComplete="off">
          <DialogTitle>
            Add Channel Mapping to: <br />
            {lastDetail?.data?.Label} ({lastDetail?.data?.SerialNumber})
          </DialogTitle>
          <DialogContent>
            <div className={styles.row}>
              <Controller
                name="Channel"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    label="Channel"
                    data={
                      ChannelSearchData?.dw_Channels.map((x) => {
                        return {
                          Label: `${x.Label} ${x.ChannelKey ? `(${x.ChannelKey})` : ''}`,
                          ChannelId: x.ChannelId,
                          ChannelKey: x.ChannelKey,
                        };
                      }) || []
                    }
                    labelKey="Label"
                    valueKey="ChannelId"
                    loading={ChannelSearchLoading}
                    value={Channel}
                    getData={searchChannels}
                    callback={(item) => {
                      field.onChange(item?.FullData);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.SourceChannel?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.Load.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <TextField
                label="ExternalId"
                name="ExternalId"
                outlined
                required
                {...register('ExternalId')}
                floatLabel={true}
              />
              {errors.ExternalId && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a External Id</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <TextField
                label="Reference"
                name="Reference"
                outlined
                required
                {...register('Reference')}
                floatLabel={true}
              />
              {errors.Reference && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a Reference</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              {errors.api && (
                <div className={styles.error} style={{ marginBottom: '16px' }}>
                  <Typography use="caption">{errors.api.message}</Typography>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-between' }}>
            <Button
              type="button"
              label="Close"
              disabled={formState.isSubmitting}
              outlined
              onClick={() => {
                setOpen(false);
                clearErrors();
                reset();
              }}
            />
            <Button
              raised
              disabled={formState.isSubmitting}
              label={formState.isSubmitting ? 'Saving' : 'Save'}
              icon={formState.isSubmitting ? <CircularProgress theme="secondary" /> : null}
              onClick={() => {
                clearErrors();
              }}
              type="submit"
            />
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AddChannelsMappingsModal;
