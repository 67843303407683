import React from 'react';
import moment from 'moment';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import HighchartsBoostCanvas from 'highcharts/modules/boost-canvas';
import HighchartsBoost from 'highcharts/modules/boost';

import { Dialog, DialogTitle, DialogContent, DialogActions } from '@rmwc/dialog';
import { CircularProgress } from '@rmwc/circular-progress';
import { Checkbox } from '@rmwc/checkbox';
import { TextField } from '@rmwc/textfield';
import { Button } from '@rmwc/button';
import { useAppState, CorrelationReport, queue } from 'utilities';

import { useBuildingCorrelationDataQuery } from 'generated/graphql';

import styles from './CustomModal.module.scss';

HighchartsHeatmap(Highcharts);
HighchartsBoostCanvas(Highcharts);
HighchartsBoost(Highcharts);

export const CorrelationReportModal = ({ open, setOpen, additionalModalProps }: any) => {
  const { getCorrelationReport } = useAppState();
  const [loaded, setLoaded] = React.useState(false);
  const [includeWeather, setIncludeWeather] = React.useState(false);
  const [startDate, setStartDate] = React.useState(moment().subtract(30, 'days').format('YYYY-MM-DD 00:00:00'));
  const [endDate, setEndDate] = React.useState(moment().format('YYYY-MM-DD 00:00:00'));

  const [correlationData, setCorrelationData] = React.useState([] as CorrelationReport[]);

  const { loading, error, data } = useBuildingCorrelationDataQuery({
    variables: { buildingId: additionalModalProps.id },
  });

  const channels = data?.dw_Loads?.flatMap((load) => {
    return load.Channels.flatMap((channel) => {
      return { ChannelId: channel.ChannelId, LoadId: load.LoadId, Label: `${load.Label}: ${channel.UnitOfMeasure}` };
    });
  });

  const xAxisCategories = [];
  const yAxisCategories = [];
  const hubUrl =
    window.location.href.indexOf('admin-test') === -1 ? 'https://test-hub.esphq.com' : 'https://hub.esphq.com';

  const loadCorrelation = async () => {
    setLoaded(false);
    const result = await getCorrelationReport(
      channels.flatMap((channel) => channel.ChannelId) || [],
      startDate,
      endDate,
      additionalModalProps.siteId,
      includeWeather
    );
    setCorrelationData(result);

    setLoaded(true);
  };

  React.useEffect(() => {
    if (!loading) {
      loadCorrelation();
    }
  }, [loading]);

  correlationData.forEach((item) => {
    if (xAxisCategories.indexOf(item.var_1) === -1) {
      xAxisCategories.push(item.var_1);
    }
    if (yAxisCategories.indexOf(item.var_2) === -1) {
      yAxisCategories.push(item.var_2);
    }
  });

  const seriesData = correlationData.map((item) => {
    const xPos = xAxisCategories.indexOf(item.var_1);
    const yPos = yAxisCategories.indexOf(item.var_2);
    let val = null;
    if (item.corr_value !== undefined && item.corr_value !== null && isNaN(item.corr_value) === false) {
      val = parseFloat(item.corr_value.toFixed(2));
    }
    return [xPos, yPos, val];
  });

  const getLabelFromChannelId = (channelId: string) => {
    const channel = channels.find((x) => x.ChannelId === channelId);
    return channel ? channel.Label : channelId;
  };

  let recentPoint = null as any;
  return (
    <div className={`${styles.customModal} ${styles.fullscreen}`}>
      <Dialog
        className={styles.missingNotifications}
        open={open}
        onClose={(evt) => {
          if (open) {
            setOpen(false);
          }
        }}>
        <DialogTitle>
          <label style={{ marginTop: '24px', display: 'inline-block' }}>
            Correlation Report <small>({'Date Range >= 30 days. Drag to zoom. Shift to pan.'})</small>
          </label>
        </DialogTitle>
        <DialogContent>
          <div className={styles.options}>
            <div className={styles.controls}>
              <Checkbox
                label="Include Weather?"
                disabled={!loaded}
                onChange={(evt) => {
                  // @ts-ignore
                  setIncludeWeather(evt.target.checked);
                }}
              />

              <TextField
                label="Start Date"
                name="startDate"
                type="date"
                outlined
                required
                floatLabel={true}
                disabled={!loaded}
                defaultValue={moment(startDate).format('YYYY-MM-DD')}
                onChange={(evt) => {
                  // @ts-ignore
                  if (moment(evt.target.value, 'YYYY-MM-DD').isValid()) {
                    // @ts-ignore
                    setStartDate(moment(evt.target.value, 'YYYY-MM-DD').format('YYYY-MM-DD 00:00:00'));
                  }
                }}
              />
              <TextField
                label="End Date"
                name="endDate"
                type="date"
                outlined
                required
                floatLabel={true}
                disabled={!loaded}
                defaultValue={moment(endDate).format('YYYY-MM-DD')}
                onChange={(evt) => {
                  // @ts-ignore
                  if (moment(evt.target.value, 'YYYY-MM-DD').isValid()) {
                    // @ts-ignore
                    setEndDate(moment(evt.target.value, 'YYYY-MM-DD').format('YYYY-MM-DD 00:00:00'));
                  }
                }}
              />
              <Button
                outlined
                type="button"
                disabled={!loaded}
                onClick={() => {
                  loadCorrelation();
                }}>
                Apply
              </Button>
            </div>
          </div>
          <div className={styles.chartContainer} style={{ maxWidth: '1024px', margin: '32px auto 0px auto' }}>
            {!loaded ? (
              <div className={styles.customLoader}>
                <CircularProgress style={{ fontSize: '125px' }} />
              </div>
            ) : null}

            {loaded && (
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  title: {
                    text: '',
                  },
                  chart: {
                    // margin: [0, 0, 0, 0],
                    spacing: [0, 0, 0, 0],
                    height: '660px',
                    style: {
                      fontFamily: '"Roboto", Helvetica, sans-serif',
                    },
                    type: 'heatmap',
                    panning: {
                      enabled: true,
                      pan: 'xy',
                    },
                    panKey: 'shift',
                    zoomType: 'xy',
                    events: {
                      click: function () {
                        const xChannelId = xAxisCategories[recentPoint.x];
                        const yChannelId = xAxisCategories[recentPoint.y];
                        const xChannel = channels.find((x) => x.ChannelId === xChannelId);
                        const yChannel = channels.find((y) => y.ChannelId === yChannelId);

                        const chartMinDate = moment(endDate).subtract(14, 'days').toDate().getTime();
                        const chartMaxDate = moment(endDate).toDate().getTime();
                        if (!xChannel || !yChannel) {
                          queue.notify({
                            icon: 'priority_high',
                            title: 'Oh oh!',
                            body: 'We can`t compare these two loads!',
                          });
                          return;
                        }
                        if (
                          window.confirm(
                            'This will open a new window to the hub with the two loads compared, please ensure your logged in first.'
                          )
                        ) {
                          const newUrl = `${hubUrl}/app/detail?accountId=${additionalModalProps.accountId}&type=load&area=${xChannel.LoadId}|${yChannel.LoadId}&chartMinDate=${chartMinDate}&chartMaxDate=${chartMaxDate}`;
                          window.open(newUrl);
                        }
                      },
                    },
                  },
                  credits: {
                    enabled: false,
                  },
                  tooltip: {
                    formatter: function () {
                      const xLabel = getLabelFromChannelId(xAxisCategories[this.point.x]);
                      const yLabel = getLabelFromChannelId(yAxisCategories[this.point.y]);
                      const val = this.point.value;
                      return `${xLabel}<br/>${yLabel}<br/><b>${val}</b>`;
                    },
                  },
                  xAxis: {
                    categories: xAxisCategories.map((x) => {
                      return getLabelFromChannelId(x);
                    }),
                  },
                  yAxis: {
                    categories: yAxisCategories.map((y) => {
                      return getLabelFromChannelId(y);
                    }),
                    title: null,
                    // reversed: true,
                  },

                  colorAxis: {
                    reversed: false,
                    min: -1,
                    max: 1,
                    stops: [
                      [0, '#fff'],
                      [0.5, '#F35044'],
                      [0.5000000000000000000000001, '#3C98AB'],
                      [1, '#fff'],
                    ],
                    // minColor: '#3C98AB',
                    // maxColor: '#FFF', //Highcharts.getOptions().colors[0],
                  },
                  boost: {
                    useGPUTranslations: true,
                    usePreallocated: true,
                    seriesThreshold: 1,
                  },
                  legend: {
                    align: 'right',
                    layout: 'vertical',
                    // margin: 0,
                    verticalAlign: 'top',
                    y: 25,
                    symbolHeight: 280,
                  },
                  series: [
                    {
                      name: 'Correlation',
                      borderWidth: 1,
                      data: seriesData,
                    },
                  ],
                  plotOptions: {
                    heatmap: {
                      clip: false,
                      boostThreshold: 1,
                      nullColor: 'purple',
                    },
                    series: {
                      boostThreshold: 1,
                      turboThreshold: 1,
                      point: {
                        events: {
                          mouseOver: function () {
                            recentPoint = this;
                          },
                        },
                      },
                    },
                  },
                }}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            label="Close"
            raised
            onClick={() => {
              setOpen(false);
            }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CorrelationReportModal;
