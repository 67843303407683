import React from 'react';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';
import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';
import { TextField } from '@rmwc/textfield';
import { Select } from '@rmwc/select';

import { queue, useAppState, AnalyticsRequestDto, intervalObj, intervalObjRev } from 'utilities';

import styles from './CustomModal.module.scss';

const intervals = [
  {
    label: '15 Minutes',
    value: intervalObj.Minutes.toString(),
  },
  {
    label: 'Half Hourly',
    value: intervalObj.HalfHourly.toString(),
  },
  {
    label: 'Hourly',
    value: intervalObj.Hours.toString(),
  },
  {
    label: 'Daily',
    value: intervalObj.Days.toString(),
  },
  {
    label: 'Weekly',
    value: intervalObj.Weeks.toString(),
  },
  {
    label: 'Monthly',
    value: intervalObj.Months.toString(),
  },
];

export const ExportAnalyticsModal = ({ open, setOpen, additionalModalProps }: any) => {
  const { requestAnalytics } = useAppState();
  const { formState, control, handleSubmit, setError, clearErrors, reset } = useForm();
  const { errors } = formState;

  const selectedData = additionalModalProps.variables.selectedData;

  const onSubmit = async (data: any) => {
    try {
      const diff = moment(data.endDate, 'YYYY-MM-DD').diff(moment(data.startDate, 'YYYY-MM-DD'), 'days');
      if (diff > 732) {
        throw new Error('Maximum export time period is 2 years! Please adjust and retry.');
      }

      // const type = additionalModalProps.variables.type;
      const start = moment(data.startDate, 'YYYY-MM-DD').format('YYYY-MM-DDT00:00:00');
      const end = moment(data.endDate, 'YYYY-MM-DD').format('YYYY-MM-DDT23:59:59');
      const interval = Number(data.interval);

      const requestParams = additionalModalProps.variables.payload.map((x) => {
        return {
          ...x,
          type: 4,
          start,
          end,
          interval,
        } as AnalyticsRequestDto;
      });
      const result = await requestAnalytics(requestParams);
      if (!result || result.length === 0) {
        throw new Error(
          'Failed to export data. Either there is no data, the date range is too long, or you have selected more than 1 cumulative channel.'
        );
      }
      let csv = '';
      if (data.exportOption === 'rawTable') {
        csv = `Event,Load/Channel Name,Channel Key,Utility Type,Unit,Usage\n`;
        result.forEach((item) => {
          const matchedChannel = selectedData.find((data) => data.channelId === item.id);
          if (!matchedChannel) {
            throw Error(
              'Oh oh! There was an error matching the results from the API to the select items. Please reload the page and try again'
            );
          }
          item.utilities.forEach((utility) => {
            utility.consumption.forEach((consumption) => {
              const dt = moment(consumption.event).format('DD/MM/YYYY HH:mm:ss');
              csv += `${dt},${matchedChannel.name},${matchedChannel.channelKey},${matchedChannel.utility},${utility.unit},${consumption.usage}\n`;
            });
          });
        });
      } else {
        csv = `,${selectedData.map((x) => x.name).join(',')}\n`;
        csv += `Event,${selectedData.map((x) => x.channelKey).join(',')}\n`;
        const groupHash = {};

        let dates = [];
        result.forEach((item) => {
          if (dates.length > 0) {
            return;
          }
          item.utilities.forEach((utility) => {
            if (dates.length === 0 && utility.consumption.length > 0) {
              dates = utility.consumption.map((consumption) => moment(consumption.event).format('DD/MM/YYYY HH:mm:ss'));
              return;
            }
          });
        });

        result.forEach((item) => {
          item.utilities.forEach((utility) => {
            if (utility.consumption.length === 0) {
              dates.forEach((dt) => {
                if (!groupHash[dt]) {
                  groupHash[dt] = [];
                }
                groupHash[dt].push(null);
              });
            }
            utility.consumption.forEach((consumption, i) => {
              const dt = moment(consumption.event).format('DD/MM/YYYY HH:mm:ss');
              if (!groupHash[dt]) {
                groupHash[dt] = [];
              }
              groupHash[dt].push(consumption.usage || null);
            });
          });
        });
        for (var k in groupHash) {
          csv += k + ',' + groupHash[k].join(',') + '\n';
        }
      }

      const intervalStr = intervalObjRev[interval];
      const exportType = data.exportOptions === 'rawTable' ? 'Raw' : 'Pivot';
      const dt = moment(data.startDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
      const dt2 = moment(data.endDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
      const fileName = `ESP Analytics Export ${intervalStr} ${exportType} ${dt}-${dt2}.csv`;
      const link = document.createElement('a');
      link.setAttribute('href', `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURIComponent(csv)}`);
      link.setAttribute('download', fileName);
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      queue.clearAll();
      queue.notify({
        icon: 'done',
        title: 'Success!',
        body: `Export should open shortly!`,
      });
      setOpen(false);
    } catch (ex) {
      queue.clearAll();
      queue.notify({
        icon: 'priority_high',
        title: 'Oh oh, error!',
        body: ex.message,
      });
      setError('api', { type: 'manual', message: ex.message });
    }
  };

  return (
    <div className={styles.customModal}>
      <Dialog
        className={styles.exportAnalytics}
        open={open}
        onClose={(evt) => {
          reset();
          clearErrors();
          if (open) {
            setOpen(false);
          }
        }}>
        <form onSubmit={handleSubmit(onSubmit)} data-private autoComplete="off">
          <DialogTitle>Export Analytics</DialogTitle>
          <DialogContent>
            <div className={styles.row}>
              <Controller
                name="startDate"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={moment().startOf('day').subtract(1, 'month').format('YYYY-MM-DD')}
                render={({ field }) => (
                  <TextField
                    label="Start Date"
                    name="startDate"
                    type="date"
                    outlined
                    required
                    autoFocus
                    floatLabel={true}
                    {...field}
                  />
                )}
              />
              {errors.startDate && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a valid date DD/MM/YYYY</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <Controller
                name="endDate"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={moment().endOf('day').format('YYYY-MM-DD')}
                render={({ field }) => (
                  <TextField
                    label="End Date"
                    name="endDate"
                    type="date"
                    outlined
                    required
                    autoFocus
                    floatLabel={true}
                    {...field}
                  />
                )}
              />
              {errors.endDate && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a valid date DD/MM/YYYY</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <Controller
                name="interval"
                control={control}
                defaultValue={'4'}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Select
                    label="Interval"
                    outlined
                    required
                    options={intervals}
                    autoFocus
                    defaultValue={'4'}
                    {...field}
                  />
                )}
              />
            </div>

            <div className={styles.row}>
              <Controller
                name="exportOption"
                control={control}
                defaultValue={'rawTable'}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Select
                    label="Export Type"
                    outlined
                    required
                    options={[{ label: 'Raw Table', value: 'rawTable' }]}
                    autoFocus
                    defaultValue={'rawTable'}
                    {...field}
                  />
                )}
              />
            </div>

            <div className={styles.row}>
              {errors.api && (
                <div className={styles.error} style={{ marginBottom: '16px' }}>
                  <Typography use="caption">{errors.api.message}</Typography>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-between' }}>
            <Button
              type="button"
              label="Close"
              disabled={formState.isSubmitting}
              outlined
              onClick={() => {
                setOpen(false);
                clearErrors();
                reset();
              }}
            />
            <Button
              raised
              disabled={formState.isSubmitting}
              label={formState.isSubmitting ? 'Exporting' : 'Export'}
              icon={formState.isSubmitting ? <CircularProgress theme="secondary" /> : null}
              onClick={() => {
                clearErrors();
              }}
              type="submit"
            />
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default ExportAnalyticsModal;
