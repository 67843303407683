import React from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';
import { TextField } from '@rmwc/textfield';
import { Select } from '@rmwc/select';
import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';

import { useAccountSearchLazyQuery, useInsertAccountAddressMutation } from 'generated/graphql';
import { AddressTypeEnum, queue } from 'utilities';
import { Autocomplete } from 'components';

import styles from './CustomModal.module.scss';

export const AddAccountAddress = ({ open, setOpen }: any) => {
  const history = useHistory();
  const { formState, control, register, handleSubmit, setError, clearErrors, reset, setValue, watch } = useForm();
  const { errors } = formState;
  const [Account] = watch(['Account']);
  const [searchAccounts, { data: AccountSearchData, loading: AccountSearchLoading }] = useAccountSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [insertFn] = useInsertAccountAddressMutation();
  const addressTypeFields = [];
  for (let k in Object.keys(AddressTypeEnum)) {
    if (AddressTypeEnum[k]) {
      addressTypeFields.push({ label: AddressTypeEnum[k], value: Number(k) });
    }
  }

  const lastDetail = JSON.parse(sessionStorage.getItem('lastDetail'));
  let accountDefaultValue = null as any;
  if (lastDetail?.data['AccountId'] && lastDetail?.data['Label']) {
    accountDefaultValue = lastDetail.data;
  }

  React.useEffect(() => {
    if (accountDefaultValue) {
      setValue('Account', accountDefaultValue);
    } else {
      searchAccounts({ variables: { search: `%%` } });
    }
    // eslint-disable-next-line
  }, ['open']);

  const onSubmit = async (data: any) => {
    try {
      const variables = {
        object: {
          ...data,
          AccountId: data.Account.AccountId,
          Composite: `${data.Line1 || ''} ${data.Line2 || ''} ${data.Line3 || ''} ${data.City || ''} ${
            data.State || ''
          } ${data.PostalCode || ''} ${data.Country || ''}`,
          AddressId: uuidv4(),
          CreatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
          UpdatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
        },
      };
      delete variables.object.Account;
      const { data: resultData, errors } = await insertFn({ variables });
      if (errors?.length > 0) {
        throw new Error(errors[0].message);
      }
      queue.clearAll();
      queue.notify({
        icon: 'done',
        title: 'Success!',
        body: `Address Added!`,
      });

      setOpen(false);
      setTimeout(() => {
        // window.location.reload();
        history.push('/app/detail/accountsAddress/' + resultData.insert_dw_AccountsAddress_one.AddressId);
      }, 1000);
    } catch (ex) {
      queue.clearAll();
      queue.notify({
        icon: 'priority_high',
        title: 'Oh oh, error!',
        body: ex.message,
      });
      setError('api', { type: 'manual', message: ex.message });
    }
  };

  return (
    <div className={styles.customModal}>
      <Dialog
        className={styles.addAddress}
        open={open}
        onClose={(evt) => {
          reset();
          clearErrors();
          if (open) {
            setOpen(false);
          }
        }}>
        <form onSubmit={handleSubmit(onSubmit)} data-private autoComplete="off">
          <DialogTitle>Add Address</DialogTitle>
          <DialogContent>
            <div className={styles.row}>
              <Controller
                name="Account"
                control={control}
                rules={{
                  required: 'Please select an account',
                }}
                render={({ field }) => (
                  <Autocomplete
                    label="Account"
                    data={AccountSearchData?.dw_Accounts || []}
                    labelKey="Label"
                    valueKey="AccountId"
                    loading={AccountSearchLoading}
                    getData={searchAccounts}
                    isDisabled={accountDefaultValue}
                    value={Account}
                    callback={(item) => {
                      field.onChange(item.FullData);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.Account?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.Account.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <TextField label="Name" name="Name" outlined floatLabel={true} {...register('Name')} />
              {errors.Name && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a Name</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <Controller
                name="Type"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={'1'}
                render={({ field }) => (
                  <Select
                    label="Type"
                    name="Type"
                    outlined
                    required
                    options={addressTypeFields}
                    defaultValue={'1'}
                    {...field}
                  />
                )}
              />
              {errors.Type && (
                <div className={styles.error}>
                  <Typography use="caption">Please select a valid type</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <TextField label="Line1" name="Line1" outlined floatLabel={true} {...register('Line1')} />
              {errors.Line1 && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a valid line 1</Typography>
                </div>
              )}
            </div>
            <div className={styles.row}>
              <TextField label="Line2" name="Line2" outlined floatLabel={true} {...register('Line2')} />
              {errors.Line2 && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a valid line 2</Typography>
                </div>
              )}
            </div>
            <div className={styles.row}>
              <TextField label="Line3" name="Line3" outlined floatLabel={true} {...register('Line3')} />
              {errors.Line3 && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a valid line 3</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <TextField label="PostalCode" name="PostalCode" outlined floatLabel={true} {...register('PostalCode')} />
              {errors.Line3 && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a valid postal code</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <TextField label="City" name="City" outlined floatLabel={true} {...register('City')} />
              {errors.City && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a valid city</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <TextField label="State" name="State" outlined floatLabel={true} {...register('State')} />
              {errors.State && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a valid state</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <TextField label="Country" name="Country" outlined {...register('Country')} floatLabel={true} />
              {errors.Country && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a valid country</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <TextField
                label="Latitude"
                name="Latitude"
                outlined
                {...register('Latitude')}
                floatLabel={true}
                type="number"
                defaultValue={0}
                required
              />
              {errors.Latitude && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a valid latitude</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <TextField
                label="Longitude"
                name="Longitude"
                outlined
                {...register('Longitude')}
                floatLabel={true}
                type="number"
                defaultValue={0}
                required
              />
              {errors.Longitude && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a valid longitude</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <TextField label="Phone1" name="Phone1" outlined {...register('Phone1')} floatLabel={true} />
              {errors.Phone1 && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a valid phone 1</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <TextField label="Phone2" name="Phone2" outlined {...register('Phone2')} floatLabel={true} />
              {errors.Phone2 && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a valid phone 2</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <TextField label="Phone3" name="Phone3" outlined {...register('Phone3')} floatLabel={true} />
              {errors.Phone3 && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a valid phone 3</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              {errors.api && (
                <div className={styles.error} style={{ marginBottom: '16px' }}>
                  <Typography use="caption">{errors.api.message}</Typography>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-between' }}>
            <Button
              type="button"
              label="Close"
              disabled={formState.isSubmitting}
              outlined
              onClick={() => {
                setOpen(false);
                clearErrors();
                reset();
              }}
            />
            <Button
              raised
              disabled={formState.isSubmitting}
              label={formState.isSubmitting ? 'Saving' : 'Save'}
              icon={formState.isSubmitting ? <CircularProgress theme="secondary" /> : null}
              onClick={() => {
                clearErrors();
              }}
              type="submit"
            />
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AddAccountAddress;
