import React from 'react';
import { CircularProgress } from '@rmwc/circular-progress';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@rmwc/dialog';
import { Button } from '@rmwc/button';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';

import styles from './CustomModal.module.scss';

export const NotificationRollingCountChartModal = ({ open, setOpen, additionalModalProps }: any) => {
  const [showCharts, setShowCharts] = React.useState(false);

  const analyticsData = additionalModalProps.analyticsData as any[];
  const startDate = moment.utc(additionalModalProps.timestamp, 'YYYY-MM-DD');
  const endDate = moment.utc().endOf('day');
  const days = endDate.diff(startDate, 'days') + 1;
  const arrDays = [];
  for (let i = 0; i < days; i++) {
    const clone = startDate.clone();
    arrDays.push(clone.add(i, 'days').toDate().getTime());
  }

  const series = [
    { name: `Qualified`, key: 'QUALIFIED_BY_ESP', data: arrDays.map((x) => [x, 0]) },
    { name: `Disqualified`, key: 'DISQUALIFIED_BY_ESP', data: arrDays.map((x) => [x, 0]) },
    { name: `Disqualified By Customer`, key: 'DISQUALIFIED_BY_CUSTOMER', data: arrDays.map((x) => [x, 0]) },
    { name: `Qualified by Customer`, key: 'QUALIFIED_BY_CUSTOMER', data: arrDays.map((x) => [x, 0]) },
  ];

  series.forEach((singleSeries, i) => {
    analyticsData[singleSeries.key].nodes.forEach((item) => {
      const dt = new Date(item.UpdatedOn);
      const dtItem = singleSeries.data.find((q) => moment(q[0]).diff(dt, 'days') === 0);
      if (dtItem) {
        dtItem[1] = dtItem[1] + 1;
      }
    });
  });

  React.useEffect(() => {
    setTimeout(() => {
      setShowCharts(true);
    }, 250);
  }, []);

  return (
    <div className={`${styles.customModal} ${styles.fullscreen}`}>
      <Dialog
        className={styles.SearchACcount}
        open={open}
        onClose={(evt) => {
          if (open) {
            setOpen(false);
          }
        }}>
        <DialogTitle>Notification Rolling Count Chart Modal: At {additionalModalProps.title} Level</DialogTitle>
        <DialogContent>
          <div className={styles.chartContainer}>
            {!showCharts && (
              <div className={styles.customLoader}>
                <CircularProgress style={{ fontSize: '125px' }} />
              </div>
            )}
            {showCharts && (
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  title: {
                    text: '',
                    margin: 40,
                  },
                  chart: {
                    height: '600px',
                    type: 'line',
                    alignTicks: false,
                    showAxis: true,
                    animation: false,
                    style: {
                      fontFamily: '"Roboto", Helvetica, sans-serif',
                      color: '#616161',
                    },
                  },
                  colors: ['#2196f3', '#f7941d', '#f44336', '#4caf50'],
                  scrollbar: {
                    buttonBackgroundColor: '#fff',
                    buttonBorderColor: '#fff',
                    buttonArrowColor: '#fff',
                    minWidth: 0,
                    height: 0,
                    liveRedraw: true,
                  },
                  credits: {
                    enabled: false,
                  },
                  legend: {
                    enabled: true,
                    align: 'center',
                    verticalAlign: 'bottom',
                    style: {
                      itemStyle: {
                        fontWeight: 500,
                        color: '#616161',
                      },
                    },
                  },
                  tooltip: {
                    split: false,
                    shared: true,
                  },
                  yAxis: {
                    title: {
                      text: 'Count',
                    },
                    min: 0,
                  },
                  xAxis: {
                    type: 'datetime',
                    title: {
                      text: 'Date',
                    },
                  },
                  time: {
                    isUTC: true,
                    moment: moment,
                  },
                  series: series,
                }}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            label="Close"
            raised
            onClick={() => {
              setOpen(false);
            }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NotificationRollingCountChartModal;
