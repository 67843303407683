import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { setContext } from '@apollo/client/link/context';
import { InsertHistoryDocument } from 'generated/graphql';
import { SourceTypeEnum } from 'utilities';

const sourceTypes = Object.keys(SourceTypeEnum)
  .filter((x) => !isNaN(Number(x)))
  .map((x) => {
    return { value: Number(x), title: SourceTypeEnum[x].toString() };
  });

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENV,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const historyLink = new ApolloLink((operation, forward) => {
  const opName = operation.operationName.toLowerCase();
  const isEdit = opName.indexOf('insert') > -1 || opName.indexOf('delete') > -1 || opName.indexOf('update') > -1;

  return forward(operation).map((response) => {
    const ctx = operation.getContext();

    let isValid =
      isEdit && [200, 201, 204].indexOf(ctx.response?.status) > -1 && operation.operationName !== 'InsertHistory';

    if (isValid) {
      const contactId = localStorage.getItem('contactId');
      const displayName = localStorage.getItem('displayName');
      const emailAddress = localStorage.getItem('emailAddress');
      const payload = JSON.stringify(operation.variables);
      let source = operation.operationName;

      if (source.indexOf('Bulk') === -1) {
        source = source
          .replace('Detail', '')
          .replace('Insert', '')
          .replace('Update', '')
          .replace('Delete', '')
          .replace('Associations', '');

        if (source.endsWith('s') && source !== 'AccountAddress') {
          source = source.substring(0, source.length - 1);
        }
      }

      let sourceType = sourceTypes.find((x) => source === x.title);
      const variables = {
        object: {
          ContactId: contactId,
          Description: `${operation.operationName} by ${displayName} (${emailAddress}). Payload: ${payload}`,
          OccuredOn: new Date(),
          SourceId: process.env.REACT_APP_UNIQUE_ID,
          SourceType: sourceType?.value || 0,
          HistoryId: uuidv4(),
        },
      };
      apolloClient.mutate({ mutation: InsertHistoryDocument, variables: variables }).catch(() => {
        console.log('history failed');
      });
    }
    return response;
  });
});

export const apolloClient = new ApolloClient({
  link: historyLink.concat(authLink).concat(httpLink),
  cache: new InMemoryCache(),
});

export default apolloClient;
