import React from 'react';
import { useHistory } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@rmwc/dialog';
import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

import { ObjByString } from 'utilities';

import { MlNotificationGroupsFieldsFragment } from 'generated/graphql';
import styles from './CustomModal.module.scss';

export const NotificationChartModal = ({ open, setOpen, additionalModalProps }: any) => {
  const [showCharts, setShowCharts] = React.useState(false);
  const [legendVisible, setLegendVisible] = React.useState(
    sessionStorage.getItem('notificationsChartLegend') || 'count'
  );
  const history = useHistory();

  const notifications = additionalModalProps.Notifications as MlNotificationGroupsFieldsFragment[];

  const data = notifications.map((x) => {
    const mainIncomers = x.MLNotifications.filter((x) => x.Channel?.Load?.MainIncomer);
    const nonMainIncomers = x.MLNotifications.filter((x) => !x.Channel?.Load?.MainIncomer);
    const notificationsToSum = mainIncomers.length === 0 ? nonMainIncomers : mainIncomers;
    const costs = notificationsToSum.reduce(
      (allNotifications, nextNotification) => {
        allNotifications.TotalCost += nextNotification.EquivalentCost;
        return allNotifications;
      },
      { TotalCost: 0 }
    );
    return { ...x, ...costs };
  });

  const countObj = data.reduce(function (countMap, item) {
    const title = ObjByString(item, additionalModalProps.field);
    countMap[title] = ++countMap[title] || 1;
    return countMap;
  }, {});

  const impactObj = data.reduce(function (impactMap, item) {
    const title = ObjByString(item, additionalModalProps.field);
    if (impactMap[title] === undefined) {
      impactMap[title] = item.TotalCost ? item.TotalCost : 0;
    } else {
      impactMap[title] = impactMap[title] + (item.TotalCost ? item.TotalCost : 0);
    }
    return impactMap;
  }, {});

  const values = [] as any[];
  for (var k in countObj) {
    values.push({ name: k, y: Math.round(countObj[k]) });
  }
  values.sort((a, b) => (a.y > b.y ? -1 : a.y < b.y ? 1 : 0));

  const values2 = [] as any[];
  for (var k in impactObj) {
    values2.push({ name: k, y: Math.round(impactObj[k]) });
  }
  values2.sort((a, b) => (a.y > b.y ? -1 : a.y < b.y ? 1 : 0));

  const splicedValues = values.splice(0, 20);
  const splicedValues2 = values2.splice(0, 20);
  const categories = splicedValues.map((x) => x.name);
  const categories2 = splicedValues2.map((x) => x.name);
  const series = [
    { name: `${additionalModalProps.title} # count`, data: splicedValues, visible: legendVisible === 'count' },
    { name: `${additionalModalProps.title} $ impact`, data: splicedValues2, visible: legendVisible === 'impact' },
  ];

  React.useEffect(() => {
    setTimeout(() => {
      setShowCharts(true);
    }, 250);
  }, []);

  return (
    <div className={`${styles.customModal} ${styles.fullscreen}`}>
      <Dialog
        className={styles.SearchACcount}
        open={open}
        onClose={(evt) => {
          if (open) {
            setOpen(false);
          }
        }}>
        <DialogTitle>Notification Chart Modal: At {additionalModalProps.title} Level</DialogTitle>
        <DialogContent>
          <div className={styles.chartContainer}>
            {!showCharts && (
              <div className={styles.customLoader}>
                <CircularProgress style={{ fontSize: '125px' }} />
              </div>
            )}
            {showCharts && (
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  title: {
                    text: '',
                    margin: 40,
                  },
                  chart: {
                    height: '600px',
                    type: 'column',
                    alignTicks: false,
                    showAxis: true,
                    animation: false,
                    style: {
                      fontFamily: '"Roboto", Helvetica, sans-serif',
                      color: '#616161',
                    },
                  },
                  colors: ['#3c98ab', '#f7941d'],
                  scrollbar: {
                    buttonBackgroundColor: '#fff',
                    buttonBorderColor: '#fff',
                    buttonArrowColor: '#fff',
                    minWidth: 0,
                    height: 0,
                    liveRedraw: true,
                  },
                  credits: {
                    enabled: false,
                  },
                  legend: {
                    enabled: true,
                    align: 'center',
                    verticalAlign: 'bottom',
                    style: {
                      itemStyle: {
                        fontWeight: 500,
                        color: '#616161',
                      },
                    },
                  },
                  plotOptions: {
                    series: {
                      borderWidth: 0,
                      dataLabels: {
                        enabled: true,
                      },
                      events: {
                        click: (e) => {
                          const chartFilter = e.point.category;

                          if (chartFilter) {
                            const item = notifications.find((notification) => {
                              const title = ObjByString(notification, additionalModalProps.field);
                              if (title === chartFilter) {
                                return true;
                              } else {
                                return false;
                              }
                            });
                            if (item) {
                              let id = '';
                              let tab = 1;
                              if (additionalModalProps.title === 'Account') {
                                id = item.Building.Site.Account.AccountId;
                                tab = 3;
                              } else if (additionalModalProps.title === 'Site') {
                                id = item.Building.Site.SiteId;
                              } else if (additionalModalProps.title === 'Building') {
                                id = item.Building.BuildingId;
                              }
                              if (id) {
                                const url = `/app/detail/${additionalModalProps.title.toLowerCase()}s/${id}?tab=${tab}`;
                                history.push(url);
                                setOpen(false);
                                setTimeout(() => {
                                  // @ts-ignore
                                  document.getElementById('openChartBtn').click();
                                }, 1000);
                              }
                            } else {
                              alert('There was a problem finding this item, please reload the page and try again');
                            }
                          }
                        },
                        legendItemClick: (e) => {
                          e.preventDefault();
                          sessionStorage.setItem(
                            'notificationsChartLegend',
                            legendVisible === 'count' ? 'impact' : 'count'
                          );
                          setLegendVisible(legendVisible === 'count' ? 'impact' : 'count');
                        },
                      },
                    },
                  },
                  xAxis: {
                    categories: legendVisible === 'count' ? categories : categories2,
                  },
                  series: series,
                }}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            label="Close"
            raised
            onClick={() => {
              setOpen(false);
            }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NotificationChartModal;
