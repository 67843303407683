import React from 'react';

import styles from './CoreLayout.module.scss';

export const CoreLayout: React.FC = ({ children }) => {
  return (
    <div className={styles['core-layout']}>
      <div className={styles['core-layout-content']}>{children}</div>
    </div>
  );
};

export default CoreLayout;
