import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _int4: any;
  _text: any;
  bigint: any;
  bpchar: any;
  float8: any;
  json: any;
  numeric: any;
  smallint: any;
  timestamp: any;
  uuid: any;
};


/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};


/** Boolean expression to compare columns of type "_int4". All fields are combined with logical 'AND'. */
export type _Int4_Comparison_Exp = {
  _eq?: Maybe<Scalars['_int4']>;
  _gt?: Maybe<Scalars['_int4']>;
  _gte?: Maybe<Scalars['_int4']>;
  _in?: Maybe<Array<Scalars['_int4']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['_int4']>;
  _lte?: Maybe<Scalars['_int4']>;
  _neq?: Maybe<Scalars['_int4']>;
  _nin?: Maybe<Array<Scalars['_int4']>>;
};


/** Boolean expression to compare columns of type "_text". All fields are combined with logical 'AND'. */
export type _Text_Comparison_Exp = {
  _eq?: Maybe<Scalars['_text']>;
  _gt?: Maybe<Scalars['_text']>;
  _gte?: Maybe<Scalars['_text']>;
  _in?: Maybe<Array<Scalars['_text']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['_text']>;
  _lte?: Maybe<Scalars['_text']>;
  _neq?: Maybe<Scalars['_text']>;
  _nin?: Maybe<Array<Scalars['_text']>>;
};


/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};


/** Boolean expression to compare columns of type "bpchar". All fields are combined with logical 'AND'. */
export type Bpchar_Comparison_Exp = {
  _eq?: Maybe<Scalars['bpchar']>;
  _gt?: Maybe<Scalars['bpchar']>;
  _gte?: Maybe<Scalars['bpchar']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['bpchar']>;
  _in?: Maybe<Array<Scalars['bpchar']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['bpchar']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['bpchar']>;
  _lt?: Maybe<Scalars['bpchar']>;
  _lte?: Maybe<Scalars['bpchar']>;
  _neq?: Maybe<Scalars['bpchar']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['bpchar']>;
  _nin?: Maybe<Array<Scalars['bpchar']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['bpchar']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['bpchar']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['bpchar']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['bpchar']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['bpchar']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['bpchar']>;
};

/** columns and relationships of "dw.Accounts" */
export type Dw_Accounts = {
  __typename?: 'dw_Accounts';
  AccMgrContactId?: Maybe<Scalars['uuid']>;
  AccountId: Scalars['uuid'];
  /** An array relationship */
  AccountsAddresses: Array<Dw_AccountsAddress>;
  /** An aggregate relationship */
  AccountsAddresses_aggregate: Dw_AccountsAddress_Aggregate;
  /** An array relationship */
  AccountsSubscriptions: Array<Dw_AccountsSubscription>;
  /** An aggregate relationship */
  AccountsSubscriptions_aggregate: Dw_AccountsSubscription_Aggregate;
  BillingEmailAddress?: Maybe<Scalars['String']>;
  /** An object relationship */
  Contact?: Maybe<Dw_Contacts>;
  /** An array relationship */
  ContactsRoles: Array<Dw_ContactsRole>;
  /** An aggregate relationship */
  ContactsRoles_aggregate: Dw_ContactsRole_Aggregate;
  CountryCode?: Maybe<Scalars['String']>;
  CreatedOn: Scalars['timestamp'];
  CustomerType?: Maybe<Scalars['String']>;
  /** An array relationship */
  Dashboards: Array<Dw_Dashboards>;
  /** An aggregate relationship */
  Dashboards_aggregate: Dw_Dashboards_Aggregate;
  EstablishedOn?: Maybe<Scalars['timestamp']>;
  FinancialStartMonth: Scalars['smallint'];
  /** An array relationship */
  Groups: Array<Dw_Groups>;
  /** An aggregate relationship */
  Groups_aggregate: Dw_Groups_Aggregate;
  IndustryCode?: Maybe<Scalars['String']>;
  Label: Scalars['String'];
  Notes?: Maybe<Scalars['String']>;
  /** An array relationship */
  Notifications: Array<Dw_Notifications>;
  /** An aggregate relationship */
  Notifications_aggregate: Dw_Notifications_Aggregate;
  NumEmployees: Scalars['Int'];
  ParentAccountId?: Maybe<Scalars['uuid']>;
  PrimaryContactId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  Projects: Array<Dw_Projects>;
  /** An aggregate relationship */
  Projects_aggregate: Dw_Projects_Aggregate;
  /** An array relationship */
  Reports: Array<Dw_Reports>;
  /** An aggregate relationship */
  Reports_aggregate: Dw_Reports_Aggregate;
  SectorId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  Sites: Array<Dw_Sites>;
  /** An aggregate relationship */
  Sites_aggregate: Dw_Sites_Aggregate;
  Status: Scalars['smallint'];
  TaxNumber?: Maybe<Scalars['String']>;
  TimeZoneIana: Scalars['String'];
  UpdatedOn: Scalars['timestamp'];
  WebsiteUrl?: Maybe<Scalars['String']>;
  XeroContactId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  contactByPrimarycontactid?: Maybe<Dw_Contacts>;
};


/** columns and relationships of "dw.Accounts" */
export type Dw_AccountsAccountsAddressesArgs = {
  distinct_on?: Maybe<Array<Dw_AccountsAddress_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_AccountsAddress_Order_By>>;
  where?: Maybe<Dw_AccountsAddress_Bool_Exp>;
};


/** columns and relationships of "dw.Accounts" */
export type Dw_AccountsAccountsAddresses_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_AccountsAddress_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_AccountsAddress_Order_By>>;
  where?: Maybe<Dw_AccountsAddress_Bool_Exp>;
};


/** columns and relationships of "dw.Accounts" */
export type Dw_AccountsAccountsSubscriptionsArgs = {
  distinct_on?: Maybe<Array<Dw_AccountsSubscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_AccountsSubscription_Order_By>>;
  where?: Maybe<Dw_AccountsSubscription_Bool_Exp>;
};


/** columns and relationships of "dw.Accounts" */
export type Dw_AccountsAccountsSubscriptions_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_AccountsSubscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_AccountsSubscription_Order_By>>;
  where?: Maybe<Dw_AccountsSubscription_Bool_Exp>;
};


/** columns and relationships of "dw.Accounts" */
export type Dw_AccountsContactsRolesArgs = {
  distinct_on?: Maybe<Array<Dw_ContactsRole_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ContactsRole_Order_By>>;
  where?: Maybe<Dw_ContactsRole_Bool_Exp>;
};


/** columns and relationships of "dw.Accounts" */
export type Dw_AccountsContactsRoles_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_ContactsRole_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ContactsRole_Order_By>>;
  where?: Maybe<Dw_ContactsRole_Bool_Exp>;
};


/** columns and relationships of "dw.Accounts" */
export type Dw_AccountsDashboardsArgs = {
  distinct_on?: Maybe<Array<Dw_Dashboards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Dashboards_Order_By>>;
  where?: Maybe<Dw_Dashboards_Bool_Exp>;
};


/** columns and relationships of "dw.Accounts" */
export type Dw_AccountsDashboards_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Dashboards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Dashboards_Order_By>>;
  where?: Maybe<Dw_Dashboards_Bool_Exp>;
};


/** columns and relationships of "dw.Accounts" */
export type Dw_AccountsGroupsArgs = {
  distinct_on?: Maybe<Array<Dw_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Groups_Order_By>>;
  where?: Maybe<Dw_Groups_Bool_Exp>;
};


/** columns and relationships of "dw.Accounts" */
export type Dw_AccountsGroups_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Groups_Order_By>>;
  where?: Maybe<Dw_Groups_Bool_Exp>;
};


/** columns and relationships of "dw.Accounts" */
export type Dw_AccountsNotificationsArgs = {
  distinct_on?: Maybe<Array<Dw_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Notifications_Order_By>>;
  where?: Maybe<Dw_Notifications_Bool_Exp>;
};


/** columns and relationships of "dw.Accounts" */
export type Dw_AccountsNotifications_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Notifications_Order_By>>;
  where?: Maybe<Dw_Notifications_Bool_Exp>;
};


/** columns and relationships of "dw.Accounts" */
export type Dw_AccountsProjectsArgs = {
  distinct_on?: Maybe<Array<Dw_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Projects_Order_By>>;
  where?: Maybe<Dw_Projects_Bool_Exp>;
};


/** columns and relationships of "dw.Accounts" */
export type Dw_AccountsProjects_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Projects_Order_By>>;
  where?: Maybe<Dw_Projects_Bool_Exp>;
};


/** columns and relationships of "dw.Accounts" */
export type Dw_AccountsReportsArgs = {
  distinct_on?: Maybe<Array<Dw_Reports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Reports_Order_By>>;
  where?: Maybe<Dw_Reports_Bool_Exp>;
};


/** columns and relationships of "dw.Accounts" */
export type Dw_AccountsReports_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Reports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Reports_Order_By>>;
  where?: Maybe<Dw_Reports_Bool_Exp>;
};


/** columns and relationships of "dw.Accounts" */
export type Dw_AccountsSitesArgs = {
  distinct_on?: Maybe<Array<Dw_Sites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Sites_Order_By>>;
  where?: Maybe<Dw_Sites_Bool_Exp>;
};


/** columns and relationships of "dw.Accounts" */
export type Dw_AccountsSites_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Sites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Sites_Order_By>>;
  where?: Maybe<Dw_Sites_Bool_Exp>;
};

/** columns and relationships of "dw.AccountsAddress" */
export type Dw_AccountsAddress = {
  __typename?: 'dw_AccountsAddress';
  /** An object relationship */
  Account: Dw_Accounts;
  AccountId: Scalars['uuid'];
  AddressId: Scalars['uuid'];
  City?: Maybe<Scalars['String']>;
  Composite?: Maybe<Scalars['String']>;
  ContactName?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  CreatedOn: Scalars['timestamp'];
  Fax?: Maybe<Scalars['String']>;
  FreightTerms?: Maybe<Scalars['String']>;
  Latitude: Scalars['numeric'];
  Line1?: Maybe<Scalars['String']>;
  Line2?: Maybe<Scalars['String']>;
  Line3?: Maybe<Scalars['String']>;
  Longitude: Scalars['numeric'];
  Name?: Maybe<Scalars['String']>;
  Phone1?: Maybe<Scalars['String']>;
  Phone2?: Maybe<Scalars['String']>;
  Phone3?: Maybe<Scalars['String']>;
  PostOfficeBox?: Maybe<Scalars['String']>;
  PostalCode?: Maybe<Scalars['String']>;
  ShippingMethod?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  Type: Scalars['smallint'];
  UpdatedOn: Scalars['timestamp'];
};

/** aggregated selection of "dw.AccountsAddress" */
export type Dw_AccountsAddress_Aggregate = {
  __typename?: 'dw_AccountsAddress_aggregate';
  aggregate?: Maybe<Dw_AccountsAddress_Aggregate_Fields>;
  nodes: Array<Dw_AccountsAddress>;
};

/** aggregate fields of "dw.AccountsAddress" */
export type Dw_AccountsAddress_Aggregate_Fields = {
  __typename?: 'dw_AccountsAddress_aggregate_fields';
  avg?: Maybe<Dw_AccountsAddress_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_AccountsAddress_Max_Fields>;
  min?: Maybe<Dw_AccountsAddress_Min_Fields>;
  stddev?: Maybe<Dw_AccountsAddress_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_AccountsAddress_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_AccountsAddress_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_AccountsAddress_Sum_Fields>;
  var_pop?: Maybe<Dw_AccountsAddress_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_AccountsAddress_Var_Samp_Fields>;
  variance?: Maybe<Dw_AccountsAddress_Variance_Fields>;
};


/** aggregate fields of "dw.AccountsAddress" */
export type Dw_AccountsAddress_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_AccountsAddress_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.AccountsAddress" */
export type Dw_AccountsAddress_Aggregate_Order_By = {
  avg?: Maybe<Dw_AccountsAddress_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_AccountsAddress_Max_Order_By>;
  min?: Maybe<Dw_AccountsAddress_Min_Order_By>;
  stddev?: Maybe<Dw_AccountsAddress_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_AccountsAddress_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_AccountsAddress_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_AccountsAddress_Sum_Order_By>;
  var_pop?: Maybe<Dw_AccountsAddress_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_AccountsAddress_Var_Samp_Order_By>;
  variance?: Maybe<Dw_AccountsAddress_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.AccountsAddress" */
export type Dw_AccountsAddress_Arr_Rel_Insert_Input = {
  data: Array<Dw_AccountsAddress_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_AccountsAddress_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_AccountsAddress_Avg_Fields = {
  __typename?: 'dw_AccountsAddress_avg_fields';
  Latitude?: Maybe<Scalars['Float']>;
  Longitude?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.AccountsAddress" */
export type Dw_AccountsAddress_Avg_Order_By = {
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.AccountsAddress". All fields are combined with a logical 'AND'. */
export type Dw_AccountsAddress_Bool_Exp = {
  Account?: Maybe<Dw_Accounts_Bool_Exp>;
  AccountId?: Maybe<Uuid_Comparison_Exp>;
  AddressId?: Maybe<Uuid_Comparison_Exp>;
  City?: Maybe<String_Comparison_Exp>;
  Composite?: Maybe<String_Comparison_Exp>;
  ContactName?: Maybe<String_Comparison_Exp>;
  Country?: Maybe<String_Comparison_Exp>;
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  Fax?: Maybe<String_Comparison_Exp>;
  FreightTerms?: Maybe<String_Comparison_Exp>;
  Latitude?: Maybe<Numeric_Comparison_Exp>;
  Line1?: Maybe<String_Comparison_Exp>;
  Line2?: Maybe<String_Comparison_Exp>;
  Line3?: Maybe<String_Comparison_Exp>;
  Longitude?: Maybe<Numeric_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  Phone1?: Maybe<String_Comparison_Exp>;
  Phone2?: Maybe<String_Comparison_Exp>;
  Phone3?: Maybe<String_Comparison_Exp>;
  PostOfficeBox?: Maybe<String_Comparison_Exp>;
  PostalCode?: Maybe<String_Comparison_Exp>;
  ShippingMethod?: Maybe<String_Comparison_Exp>;
  State?: Maybe<String_Comparison_Exp>;
  Type?: Maybe<Smallint_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  _and?: Maybe<Array<Dw_AccountsAddress_Bool_Exp>>;
  _not?: Maybe<Dw_AccountsAddress_Bool_Exp>;
  _or?: Maybe<Array<Dw_AccountsAddress_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.AccountsAddress" */
export enum Dw_AccountsAddress_Constraint {
  /** unique or primary key constraint */
  AccountsaddressAddressidUindex = 'accountsaddress_addressid_uindex',
  /** unique or primary key constraint */
  AccountsaddressPk = 'accountsaddress_pk'
}

/** input type for incrementing numeric columns in table "dw.AccountsAddress" */
export type Dw_AccountsAddress_Inc_Input = {
  Latitude?: Maybe<Scalars['numeric']>;
  Longitude?: Maybe<Scalars['numeric']>;
  Type?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.AccountsAddress" */
export type Dw_AccountsAddress_Insert_Input = {
  Account?: Maybe<Dw_Accounts_Obj_Rel_Insert_Input>;
  AccountId?: Maybe<Scalars['uuid']>;
  AddressId?: Maybe<Scalars['uuid']>;
  City?: Maybe<Scalars['String']>;
  Composite?: Maybe<Scalars['String']>;
  ContactName?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  Fax?: Maybe<Scalars['String']>;
  FreightTerms?: Maybe<Scalars['String']>;
  Latitude?: Maybe<Scalars['numeric']>;
  Line1?: Maybe<Scalars['String']>;
  Line2?: Maybe<Scalars['String']>;
  Line3?: Maybe<Scalars['String']>;
  Longitude?: Maybe<Scalars['numeric']>;
  Name?: Maybe<Scalars['String']>;
  Phone1?: Maybe<Scalars['String']>;
  Phone2?: Maybe<Scalars['String']>;
  Phone3?: Maybe<Scalars['String']>;
  PostOfficeBox?: Maybe<Scalars['String']>;
  PostalCode?: Maybe<Scalars['String']>;
  ShippingMethod?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Dw_AccountsAddress_Max_Fields = {
  __typename?: 'dw_AccountsAddress_max_fields';
  AccountId?: Maybe<Scalars['uuid']>;
  AddressId?: Maybe<Scalars['uuid']>;
  City?: Maybe<Scalars['String']>;
  Composite?: Maybe<Scalars['String']>;
  ContactName?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  Fax?: Maybe<Scalars['String']>;
  FreightTerms?: Maybe<Scalars['String']>;
  Latitude?: Maybe<Scalars['numeric']>;
  Line1?: Maybe<Scalars['String']>;
  Line2?: Maybe<Scalars['String']>;
  Line3?: Maybe<Scalars['String']>;
  Longitude?: Maybe<Scalars['numeric']>;
  Name?: Maybe<Scalars['String']>;
  Phone1?: Maybe<Scalars['String']>;
  Phone2?: Maybe<Scalars['String']>;
  Phone3?: Maybe<Scalars['String']>;
  PostOfficeBox?: Maybe<Scalars['String']>;
  PostalCode?: Maybe<Scalars['String']>;
  ShippingMethod?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "dw.AccountsAddress" */
export type Dw_AccountsAddress_Max_Order_By = {
  AccountId?: Maybe<Order_By>;
  AddressId?: Maybe<Order_By>;
  City?: Maybe<Order_By>;
  Composite?: Maybe<Order_By>;
  ContactName?: Maybe<Order_By>;
  Country?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  FreightTerms?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line1?: Maybe<Order_By>;
  Line2?: Maybe<Order_By>;
  Line3?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  Phone1?: Maybe<Order_By>;
  Phone2?: Maybe<Order_By>;
  Phone3?: Maybe<Order_By>;
  PostOfficeBox?: Maybe<Order_By>;
  PostalCode?: Maybe<Order_By>;
  ShippingMethod?: Maybe<Order_By>;
  State?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_AccountsAddress_Min_Fields = {
  __typename?: 'dw_AccountsAddress_min_fields';
  AccountId?: Maybe<Scalars['uuid']>;
  AddressId?: Maybe<Scalars['uuid']>;
  City?: Maybe<Scalars['String']>;
  Composite?: Maybe<Scalars['String']>;
  ContactName?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  Fax?: Maybe<Scalars['String']>;
  FreightTerms?: Maybe<Scalars['String']>;
  Latitude?: Maybe<Scalars['numeric']>;
  Line1?: Maybe<Scalars['String']>;
  Line2?: Maybe<Scalars['String']>;
  Line3?: Maybe<Scalars['String']>;
  Longitude?: Maybe<Scalars['numeric']>;
  Name?: Maybe<Scalars['String']>;
  Phone1?: Maybe<Scalars['String']>;
  Phone2?: Maybe<Scalars['String']>;
  Phone3?: Maybe<Scalars['String']>;
  PostOfficeBox?: Maybe<Scalars['String']>;
  PostalCode?: Maybe<Scalars['String']>;
  ShippingMethod?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "dw.AccountsAddress" */
export type Dw_AccountsAddress_Min_Order_By = {
  AccountId?: Maybe<Order_By>;
  AddressId?: Maybe<Order_By>;
  City?: Maybe<Order_By>;
  Composite?: Maybe<Order_By>;
  ContactName?: Maybe<Order_By>;
  Country?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  FreightTerms?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line1?: Maybe<Order_By>;
  Line2?: Maybe<Order_By>;
  Line3?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  Phone1?: Maybe<Order_By>;
  Phone2?: Maybe<Order_By>;
  Phone3?: Maybe<Order_By>;
  PostOfficeBox?: Maybe<Order_By>;
  PostalCode?: Maybe<Order_By>;
  ShippingMethod?: Maybe<Order_By>;
  State?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.AccountsAddress" */
export type Dw_AccountsAddress_Mutation_Response = {
  __typename?: 'dw_AccountsAddress_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_AccountsAddress>;
};

/** on conflict condition type for table "dw.AccountsAddress" */
export type Dw_AccountsAddress_On_Conflict = {
  constraint: Dw_AccountsAddress_Constraint;
  update_columns?: Array<Dw_AccountsAddress_Update_Column>;
  where?: Maybe<Dw_AccountsAddress_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.AccountsAddress". */
export type Dw_AccountsAddress_Order_By = {
  Account?: Maybe<Dw_Accounts_Order_By>;
  AccountId?: Maybe<Order_By>;
  AddressId?: Maybe<Order_By>;
  City?: Maybe<Order_By>;
  Composite?: Maybe<Order_By>;
  ContactName?: Maybe<Order_By>;
  Country?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  Fax?: Maybe<Order_By>;
  FreightTerms?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Line1?: Maybe<Order_By>;
  Line2?: Maybe<Order_By>;
  Line3?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  Phone1?: Maybe<Order_By>;
  Phone2?: Maybe<Order_By>;
  Phone3?: Maybe<Order_By>;
  PostOfficeBox?: Maybe<Order_By>;
  PostalCode?: Maybe<Order_By>;
  ShippingMethod?: Maybe<Order_By>;
  State?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_AccountsAddress */
export type Dw_AccountsAddress_Pk_Columns_Input = {
  AddressId: Scalars['uuid'];
};

/** select columns of table "dw.AccountsAddress" */
export enum Dw_AccountsAddress_Select_Column {
  /** column name */
  AccountId = 'AccountId',
  /** column name */
  AddressId = 'AddressId',
  /** column name */
  City = 'City',
  /** column name */
  Composite = 'Composite',
  /** column name */
  ContactName = 'ContactName',
  /** column name */
  Country = 'Country',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Fax = 'Fax',
  /** column name */
  FreightTerms = 'FreightTerms',
  /** column name */
  Latitude = 'Latitude',
  /** column name */
  Line1 = 'Line1',
  /** column name */
  Line2 = 'Line2',
  /** column name */
  Line3 = 'Line3',
  /** column name */
  Longitude = 'Longitude',
  /** column name */
  Name = 'Name',
  /** column name */
  Phone1 = 'Phone1',
  /** column name */
  Phone2 = 'Phone2',
  /** column name */
  Phone3 = 'Phone3',
  /** column name */
  PostOfficeBox = 'PostOfficeBox',
  /** column name */
  PostalCode = 'PostalCode',
  /** column name */
  ShippingMethod = 'ShippingMethod',
  /** column name */
  State = 'State',
  /** column name */
  Type = 'Type',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** input type for updating data in table "dw.AccountsAddress" */
export type Dw_AccountsAddress_Set_Input = {
  AccountId?: Maybe<Scalars['uuid']>;
  AddressId?: Maybe<Scalars['uuid']>;
  City?: Maybe<Scalars['String']>;
  Composite?: Maybe<Scalars['String']>;
  ContactName?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  Fax?: Maybe<Scalars['String']>;
  FreightTerms?: Maybe<Scalars['String']>;
  Latitude?: Maybe<Scalars['numeric']>;
  Line1?: Maybe<Scalars['String']>;
  Line2?: Maybe<Scalars['String']>;
  Line3?: Maybe<Scalars['String']>;
  Longitude?: Maybe<Scalars['numeric']>;
  Name?: Maybe<Scalars['String']>;
  Phone1?: Maybe<Scalars['String']>;
  Phone2?: Maybe<Scalars['String']>;
  Phone3?: Maybe<Scalars['String']>;
  PostOfficeBox?: Maybe<Scalars['String']>;
  PostalCode?: Maybe<Scalars['String']>;
  ShippingMethod?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Dw_AccountsAddress_Stddev_Fields = {
  __typename?: 'dw_AccountsAddress_stddev_fields';
  Latitude?: Maybe<Scalars['Float']>;
  Longitude?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.AccountsAddress" */
export type Dw_AccountsAddress_Stddev_Order_By = {
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_AccountsAddress_Stddev_Pop_Fields = {
  __typename?: 'dw_AccountsAddress_stddev_pop_fields';
  Latitude?: Maybe<Scalars['Float']>;
  Longitude?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.AccountsAddress" */
export type Dw_AccountsAddress_Stddev_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_AccountsAddress_Stddev_Samp_Fields = {
  __typename?: 'dw_AccountsAddress_stddev_samp_fields';
  Latitude?: Maybe<Scalars['Float']>;
  Longitude?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.AccountsAddress" */
export type Dw_AccountsAddress_Stddev_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_AccountsAddress_Sum_Fields = {
  __typename?: 'dw_AccountsAddress_sum_fields';
  Latitude?: Maybe<Scalars['numeric']>;
  Longitude?: Maybe<Scalars['numeric']>;
  Type?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "dw.AccountsAddress" */
export type Dw_AccountsAddress_Sum_Order_By = {
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
};

/** update columns of table "dw.AccountsAddress" */
export enum Dw_AccountsAddress_Update_Column {
  /** column name */
  AccountId = 'AccountId',
  /** column name */
  AddressId = 'AddressId',
  /** column name */
  City = 'City',
  /** column name */
  Composite = 'Composite',
  /** column name */
  ContactName = 'ContactName',
  /** column name */
  Country = 'Country',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Fax = 'Fax',
  /** column name */
  FreightTerms = 'FreightTerms',
  /** column name */
  Latitude = 'Latitude',
  /** column name */
  Line1 = 'Line1',
  /** column name */
  Line2 = 'Line2',
  /** column name */
  Line3 = 'Line3',
  /** column name */
  Longitude = 'Longitude',
  /** column name */
  Name = 'Name',
  /** column name */
  Phone1 = 'Phone1',
  /** column name */
  Phone2 = 'Phone2',
  /** column name */
  Phone3 = 'Phone3',
  /** column name */
  PostOfficeBox = 'PostOfficeBox',
  /** column name */
  PostalCode = 'PostalCode',
  /** column name */
  ShippingMethod = 'ShippingMethod',
  /** column name */
  State = 'State',
  /** column name */
  Type = 'Type',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** aggregate var_pop on columns */
export type Dw_AccountsAddress_Var_Pop_Fields = {
  __typename?: 'dw_AccountsAddress_var_pop_fields';
  Latitude?: Maybe<Scalars['Float']>;
  Longitude?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.AccountsAddress" */
export type Dw_AccountsAddress_Var_Pop_Order_By = {
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_AccountsAddress_Var_Samp_Fields = {
  __typename?: 'dw_AccountsAddress_var_samp_fields';
  Latitude?: Maybe<Scalars['Float']>;
  Longitude?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.AccountsAddress" */
export type Dw_AccountsAddress_Var_Samp_Order_By = {
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_AccountsAddress_Variance_Fields = {
  __typename?: 'dw_AccountsAddress_variance_fields';
  Latitude?: Maybe<Scalars['Float']>;
  Longitude?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.AccountsAddress" */
export type Dw_AccountsAddress_Variance_Order_By = {
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
};

/** columns and relationships of "dw.AccountsSubscription" */
export type Dw_AccountsSubscription = {
  __typename?: 'dw_AccountsSubscription';
  /** An object relationship */
  Account: Dw_Accounts;
  AccountId: Scalars['uuid'];
  Balance: Scalars['numeric'];
  BalanceAsAt: Scalars['timestamp'];
  CeasedOn?: Maybe<Scalars['timestamp']>;
  CommerceOn: Scalars['timestamp'];
  CreatedOn: Scalars['timestamp'];
  PaymentCustomerRef?: Maybe<Scalars['String']>;
  /** An object relationship */
  Plan: Dw_Plans;
  PlanId: Scalars['uuid'];
  Status: Scalars['smallint'];
  SubscriptionId: Scalars['uuid'];
  UpdatedOn: Scalars['timestamp'];
};

/** aggregated selection of "dw.AccountsSubscription" */
export type Dw_AccountsSubscription_Aggregate = {
  __typename?: 'dw_AccountsSubscription_aggregate';
  aggregate?: Maybe<Dw_AccountsSubscription_Aggregate_Fields>;
  nodes: Array<Dw_AccountsSubscription>;
};

/** aggregate fields of "dw.AccountsSubscription" */
export type Dw_AccountsSubscription_Aggregate_Fields = {
  __typename?: 'dw_AccountsSubscription_aggregate_fields';
  avg?: Maybe<Dw_AccountsSubscription_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_AccountsSubscription_Max_Fields>;
  min?: Maybe<Dw_AccountsSubscription_Min_Fields>;
  stddev?: Maybe<Dw_AccountsSubscription_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_AccountsSubscription_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_AccountsSubscription_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_AccountsSubscription_Sum_Fields>;
  var_pop?: Maybe<Dw_AccountsSubscription_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_AccountsSubscription_Var_Samp_Fields>;
  variance?: Maybe<Dw_AccountsSubscription_Variance_Fields>;
};


/** aggregate fields of "dw.AccountsSubscription" */
export type Dw_AccountsSubscription_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_AccountsSubscription_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.AccountsSubscription" */
export type Dw_AccountsSubscription_Aggregate_Order_By = {
  avg?: Maybe<Dw_AccountsSubscription_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_AccountsSubscription_Max_Order_By>;
  min?: Maybe<Dw_AccountsSubscription_Min_Order_By>;
  stddev?: Maybe<Dw_AccountsSubscription_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_AccountsSubscription_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_AccountsSubscription_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_AccountsSubscription_Sum_Order_By>;
  var_pop?: Maybe<Dw_AccountsSubscription_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_AccountsSubscription_Var_Samp_Order_By>;
  variance?: Maybe<Dw_AccountsSubscription_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.AccountsSubscription" */
export type Dw_AccountsSubscription_Arr_Rel_Insert_Input = {
  data: Array<Dw_AccountsSubscription_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_AccountsSubscription_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_AccountsSubscription_Avg_Fields = {
  __typename?: 'dw_AccountsSubscription_avg_fields';
  Balance?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.AccountsSubscription" */
export type Dw_AccountsSubscription_Avg_Order_By = {
  Balance?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.AccountsSubscription". All fields are combined with a logical 'AND'. */
export type Dw_AccountsSubscription_Bool_Exp = {
  Account?: Maybe<Dw_Accounts_Bool_Exp>;
  AccountId?: Maybe<Uuid_Comparison_Exp>;
  Balance?: Maybe<Numeric_Comparison_Exp>;
  BalanceAsAt?: Maybe<Timestamp_Comparison_Exp>;
  CeasedOn?: Maybe<Timestamp_Comparison_Exp>;
  CommerceOn?: Maybe<Timestamp_Comparison_Exp>;
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  PaymentCustomerRef?: Maybe<String_Comparison_Exp>;
  Plan?: Maybe<Dw_Plans_Bool_Exp>;
  PlanId?: Maybe<Uuid_Comparison_Exp>;
  Status?: Maybe<Smallint_Comparison_Exp>;
  SubscriptionId?: Maybe<Uuid_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  _and?: Maybe<Array<Dw_AccountsSubscription_Bool_Exp>>;
  _not?: Maybe<Dw_AccountsSubscription_Bool_Exp>;
  _or?: Maybe<Array<Dw_AccountsSubscription_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.AccountsSubscription" */
export enum Dw_AccountsSubscription_Constraint {
  /** unique or primary key constraint */
  AccountssubscriptionPk = 'accountssubscription_pk',
  /** unique or primary key constraint */
  AccountssubscriptionSubscriptionidUindex = 'accountssubscription_subscriptionid_uindex'
}

/** input type for incrementing numeric columns in table "dw.AccountsSubscription" */
export type Dw_AccountsSubscription_Inc_Input = {
  Balance?: Maybe<Scalars['numeric']>;
  Status?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.AccountsSubscription" */
export type Dw_AccountsSubscription_Insert_Input = {
  Account?: Maybe<Dw_Accounts_Obj_Rel_Insert_Input>;
  AccountId?: Maybe<Scalars['uuid']>;
  Balance?: Maybe<Scalars['numeric']>;
  BalanceAsAt?: Maybe<Scalars['timestamp']>;
  CeasedOn?: Maybe<Scalars['timestamp']>;
  CommerceOn?: Maybe<Scalars['timestamp']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  PaymentCustomerRef?: Maybe<Scalars['String']>;
  Plan?: Maybe<Dw_Plans_Obj_Rel_Insert_Input>;
  PlanId?: Maybe<Scalars['uuid']>;
  Status?: Maybe<Scalars['smallint']>;
  SubscriptionId?: Maybe<Scalars['uuid']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Dw_AccountsSubscription_Max_Fields = {
  __typename?: 'dw_AccountsSubscription_max_fields';
  AccountId?: Maybe<Scalars['uuid']>;
  Balance?: Maybe<Scalars['numeric']>;
  BalanceAsAt?: Maybe<Scalars['timestamp']>;
  CeasedOn?: Maybe<Scalars['timestamp']>;
  CommerceOn?: Maybe<Scalars['timestamp']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  PaymentCustomerRef?: Maybe<Scalars['String']>;
  PlanId?: Maybe<Scalars['uuid']>;
  Status?: Maybe<Scalars['smallint']>;
  SubscriptionId?: Maybe<Scalars['uuid']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "dw.AccountsSubscription" */
export type Dw_AccountsSubscription_Max_Order_By = {
  AccountId?: Maybe<Order_By>;
  Balance?: Maybe<Order_By>;
  BalanceAsAt?: Maybe<Order_By>;
  CeasedOn?: Maybe<Order_By>;
  CommerceOn?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  PaymentCustomerRef?: Maybe<Order_By>;
  PlanId?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  SubscriptionId?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_AccountsSubscription_Min_Fields = {
  __typename?: 'dw_AccountsSubscription_min_fields';
  AccountId?: Maybe<Scalars['uuid']>;
  Balance?: Maybe<Scalars['numeric']>;
  BalanceAsAt?: Maybe<Scalars['timestamp']>;
  CeasedOn?: Maybe<Scalars['timestamp']>;
  CommerceOn?: Maybe<Scalars['timestamp']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  PaymentCustomerRef?: Maybe<Scalars['String']>;
  PlanId?: Maybe<Scalars['uuid']>;
  Status?: Maybe<Scalars['smallint']>;
  SubscriptionId?: Maybe<Scalars['uuid']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "dw.AccountsSubscription" */
export type Dw_AccountsSubscription_Min_Order_By = {
  AccountId?: Maybe<Order_By>;
  Balance?: Maybe<Order_By>;
  BalanceAsAt?: Maybe<Order_By>;
  CeasedOn?: Maybe<Order_By>;
  CommerceOn?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  PaymentCustomerRef?: Maybe<Order_By>;
  PlanId?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  SubscriptionId?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.AccountsSubscription" */
export type Dw_AccountsSubscription_Mutation_Response = {
  __typename?: 'dw_AccountsSubscription_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_AccountsSubscription>;
};

/** on conflict condition type for table "dw.AccountsSubscription" */
export type Dw_AccountsSubscription_On_Conflict = {
  constraint: Dw_AccountsSubscription_Constraint;
  update_columns?: Array<Dw_AccountsSubscription_Update_Column>;
  where?: Maybe<Dw_AccountsSubscription_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.AccountsSubscription". */
export type Dw_AccountsSubscription_Order_By = {
  Account?: Maybe<Dw_Accounts_Order_By>;
  AccountId?: Maybe<Order_By>;
  Balance?: Maybe<Order_By>;
  BalanceAsAt?: Maybe<Order_By>;
  CeasedOn?: Maybe<Order_By>;
  CommerceOn?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  PaymentCustomerRef?: Maybe<Order_By>;
  Plan?: Maybe<Dw_Plans_Order_By>;
  PlanId?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  SubscriptionId?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_AccountsSubscription */
export type Dw_AccountsSubscription_Pk_Columns_Input = {
  SubscriptionId: Scalars['uuid'];
};

/** select columns of table "dw.AccountsSubscription" */
export enum Dw_AccountsSubscription_Select_Column {
  /** column name */
  AccountId = 'AccountId',
  /** column name */
  Balance = 'Balance',
  /** column name */
  BalanceAsAt = 'BalanceAsAt',
  /** column name */
  CeasedOn = 'CeasedOn',
  /** column name */
  CommerceOn = 'CommerceOn',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  PaymentCustomerRef = 'PaymentCustomerRef',
  /** column name */
  PlanId = 'PlanId',
  /** column name */
  Status = 'Status',
  /** column name */
  SubscriptionId = 'SubscriptionId',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** input type for updating data in table "dw.AccountsSubscription" */
export type Dw_AccountsSubscription_Set_Input = {
  AccountId?: Maybe<Scalars['uuid']>;
  Balance?: Maybe<Scalars['numeric']>;
  BalanceAsAt?: Maybe<Scalars['timestamp']>;
  CeasedOn?: Maybe<Scalars['timestamp']>;
  CommerceOn?: Maybe<Scalars['timestamp']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  PaymentCustomerRef?: Maybe<Scalars['String']>;
  PlanId?: Maybe<Scalars['uuid']>;
  Status?: Maybe<Scalars['smallint']>;
  SubscriptionId?: Maybe<Scalars['uuid']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Dw_AccountsSubscription_Stddev_Fields = {
  __typename?: 'dw_AccountsSubscription_stddev_fields';
  Balance?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.AccountsSubscription" */
export type Dw_AccountsSubscription_Stddev_Order_By = {
  Balance?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_AccountsSubscription_Stddev_Pop_Fields = {
  __typename?: 'dw_AccountsSubscription_stddev_pop_fields';
  Balance?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.AccountsSubscription" */
export type Dw_AccountsSubscription_Stddev_Pop_Order_By = {
  Balance?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_AccountsSubscription_Stddev_Samp_Fields = {
  __typename?: 'dw_AccountsSubscription_stddev_samp_fields';
  Balance?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.AccountsSubscription" */
export type Dw_AccountsSubscription_Stddev_Samp_Order_By = {
  Balance?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_AccountsSubscription_Sum_Fields = {
  __typename?: 'dw_AccountsSubscription_sum_fields';
  Balance?: Maybe<Scalars['numeric']>;
  Status?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "dw.AccountsSubscription" */
export type Dw_AccountsSubscription_Sum_Order_By = {
  Balance?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** update columns of table "dw.AccountsSubscription" */
export enum Dw_AccountsSubscription_Update_Column {
  /** column name */
  AccountId = 'AccountId',
  /** column name */
  Balance = 'Balance',
  /** column name */
  BalanceAsAt = 'BalanceAsAt',
  /** column name */
  CeasedOn = 'CeasedOn',
  /** column name */
  CommerceOn = 'CommerceOn',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  PaymentCustomerRef = 'PaymentCustomerRef',
  /** column name */
  PlanId = 'PlanId',
  /** column name */
  Status = 'Status',
  /** column name */
  SubscriptionId = 'SubscriptionId',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** aggregate var_pop on columns */
export type Dw_AccountsSubscription_Var_Pop_Fields = {
  __typename?: 'dw_AccountsSubscription_var_pop_fields';
  Balance?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.AccountsSubscription" */
export type Dw_AccountsSubscription_Var_Pop_Order_By = {
  Balance?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_AccountsSubscription_Var_Samp_Fields = {
  __typename?: 'dw_AccountsSubscription_var_samp_fields';
  Balance?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.AccountsSubscription" */
export type Dw_AccountsSubscription_Var_Samp_Order_By = {
  Balance?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_AccountsSubscription_Variance_Fields = {
  __typename?: 'dw_AccountsSubscription_variance_fields';
  Balance?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.AccountsSubscription" */
export type Dw_AccountsSubscription_Variance_Order_By = {
  Balance?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregated selection of "dw.Accounts" */
export type Dw_Accounts_Aggregate = {
  __typename?: 'dw_Accounts_aggregate';
  aggregate?: Maybe<Dw_Accounts_Aggregate_Fields>;
  nodes: Array<Dw_Accounts>;
};

/** aggregate fields of "dw.Accounts" */
export type Dw_Accounts_Aggregate_Fields = {
  __typename?: 'dw_Accounts_aggregate_fields';
  avg?: Maybe<Dw_Accounts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_Accounts_Max_Fields>;
  min?: Maybe<Dw_Accounts_Min_Fields>;
  stddev?: Maybe<Dw_Accounts_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_Accounts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_Accounts_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_Accounts_Sum_Fields>;
  var_pop?: Maybe<Dw_Accounts_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_Accounts_Var_Samp_Fields>;
  variance?: Maybe<Dw_Accounts_Variance_Fields>;
};


/** aggregate fields of "dw.Accounts" */
export type Dw_Accounts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_Accounts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.Accounts" */
export type Dw_Accounts_Aggregate_Order_By = {
  avg?: Maybe<Dw_Accounts_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_Accounts_Max_Order_By>;
  min?: Maybe<Dw_Accounts_Min_Order_By>;
  stddev?: Maybe<Dw_Accounts_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_Accounts_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_Accounts_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_Accounts_Sum_Order_By>;
  var_pop?: Maybe<Dw_Accounts_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_Accounts_Var_Samp_Order_By>;
  variance?: Maybe<Dw_Accounts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.Accounts" */
export type Dw_Accounts_Arr_Rel_Insert_Input = {
  data: Array<Dw_Accounts_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_Accounts_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_Accounts_Avg_Fields = {
  __typename?: 'dw_Accounts_avg_fields';
  FinancialStartMonth?: Maybe<Scalars['Float']>;
  NumEmployees?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.Accounts" */
export type Dw_Accounts_Avg_Order_By = {
  FinancialStartMonth?: Maybe<Order_By>;
  NumEmployees?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.Accounts". All fields are combined with a logical 'AND'. */
export type Dw_Accounts_Bool_Exp = {
  AccMgrContactId?: Maybe<Uuid_Comparison_Exp>;
  AccountId?: Maybe<Uuid_Comparison_Exp>;
  AccountsAddresses?: Maybe<Dw_AccountsAddress_Bool_Exp>;
  AccountsSubscriptions?: Maybe<Dw_AccountsSubscription_Bool_Exp>;
  BillingEmailAddress?: Maybe<String_Comparison_Exp>;
  Contact?: Maybe<Dw_Contacts_Bool_Exp>;
  ContactsRoles?: Maybe<Dw_ContactsRole_Bool_Exp>;
  CountryCode?: Maybe<String_Comparison_Exp>;
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  CustomerType?: Maybe<String_Comparison_Exp>;
  Dashboards?: Maybe<Dw_Dashboards_Bool_Exp>;
  EstablishedOn?: Maybe<Timestamp_Comparison_Exp>;
  FinancialStartMonth?: Maybe<Smallint_Comparison_Exp>;
  Groups?: Maybe<Dw_Groups_Bool_Exp>;
  IndustryCode?: Maybe<String_Comparison_Exp>;
  Label?: Maybe<String_Comparison_Exp>;
  Notes?: Maybe<String_Comparison_Exp>;
  Notifications?: Maybe<Dw_Notifications_Bool_Exp>;
  NumEmployees?: Maybe<Int_Comparison_Exp>;
  ParentAccountId?: Maybe<Uuid_Comparison_Exp>;
  PrimaryContactId?: Maybe<Uuid_Comparison_Exp>;
  Projects?: Maybe<Dw_Projects_Bool_Exp>;
  Reports?: Maybe<Dw_Reports_Bool_Exp>;
  SectorId?: Maybe<Uuid_Comparison_Exp>;
  Sites?: Maybe<Dw_Sites_Bool_Exp>;
  Status?: Maybe<Smallint_Comparison_Exp>;
  TaxNumber?: Maybe<String_Comparison_Exp>;
  TimeZoneIana?: Maybe<String_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  WebsiteUrl?: Maybe<String_Comparison_Exp>;
  XeroContactId?: Maybe<Uuid_Comparison_Exp>;
  _and?: Maybe<Array<Dw_Accounts_Bool_Exp>>;
  _not?: Maybe<Dw_Accounts_Bool_Exp>;
  _or?: Maybe<Array<Dw_Accounts_Bool_Exp>>;
  contactByPrimarycontactid?: Maybe<Dw_Contacts_Bool_Exp>;
};

/** unique or primary key constraints on table "dw.Accounts" */
export enum Dw_Accounts_Constraint {
  /** unique or primary key constraint */
  AccountsAccountidUindex = 'accounts_accountid_uindex',
  /** unique or primary key constraint */
  AccountsPk = 'accounts_pk'
}

/** input type for incrementing numeric columns in table "dw.Accounts" */
export type Dw_Accounts_Inc_Input = {
  FinancialStartMonth?: Maybe<Scalars['smallint']>;
  NumEmployees?: Maybe<Scalars['Int']>;
  Status?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.Accounts" */
export type Dw_Accounts_Insert_Input = {
  AccMgrContactId?: Maybe<Scalars['uuid']>;
  AccountId?: Maybe<Scalars['uuid']>;
  AccountsAddresses?: Maybe<Dw_AccountsAddress_Arr_Rel_Insert_Input>;
  AccountsSubscriptions?: Maybe<Dw_AccountsSubscription_Arr_Rel_Insert_Input>;
  BillingEmailAddress?: Maybe<Scalars['String']>;
  Contact?: Maybe<Dw_Contacts_Obj_Rel_Insert_Input>;
  ContactsRoles?: Maybe<Dw_ContactsRole_Arr_Rel_Insert_Input>;
  CountryCode?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  CustomerType?: Maybe<Scalars['String']>;
  Dashboards?: Maybe<Dw_Dashboards_Arr_Rel_Insert_Input>;
  EstablishedOn?: Maybe<Scalars['timestamp']>;
  FinancialStartMonth?: Maybe<Scalars['smallint']>;
  Groups?: Maybe<Dw_Groups_Arr_Rel_Insert_Input>;
  IndustryCode?: Maybe<Scalars['String']>;
  Label?: Maybe<Scalars['String']>;
  Notes?: Maybe<Scalars['String']>;
  Notifications?: Maybe<Dw_Notifications_Arr_Rel_Insert_Input>;
  NumEmployees?: Maybe<Scalars['Int']>;
  ParentAccountId?: Maybe<Scalars['uuid']>;
  PrimaryContactId?: Maybe<Scalars['uuid']>;
  Projects?: Maybe<Dw_Projects_Arr_Rel_Insert_Input>;
  Reports?: Maybe<Dw_Reports_Arr_Rel_Insert_Input>;
  SectorId?: Maybe<Scalars['uuid']>;
  Sites?: Maybe<Dw_Sites_Arr_Rel_Insert_Input>;
  Status?: Maybe<Scalars['smallint']>;
  TaxNumber?: Maybe<Scalars['String']>;
  TimeZoneIana?: Maybe<Scalars['String']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  WebsiteUrl?: Maybe<Scalars['String']>;
  XeroContactId?: Maybe<Scalars['uuid']>;
  contactByPrimarycontactid?: Maybe<Dw_Contacts_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Dw_Accounts_Max_Fields = {
  __typename?: 'dw_Accounts_max_fields';
  AccMgrContactId?: Maybe<Scalars['uuid']>;
  AccountId?: Maybe<Scalars['uuid']>;
  BillingEmailAddress?: Maybe<Scalars['String']>;
  CountryCode?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  CustomerType?: Maybe<Scalars['String']>;
  EstablishedOn?: Maybe<Scalars['timestamp']>;
  FinancialStartMonth?: Maybe<Scalars['smallint']>;
  IndustryCode?: Maybe<Scalars['String']>;
  Label?: Maybe<Scalars['String']>;
  Notes?: Maybe<Scalars['String']>;
  NumEmployees?: Maybe<Scalars['Int']>;
  ParentAccountId?: Maybe<Scalars['uuid']>;
  PrimaryContactId?: Maybe<Scalars['uuid']>;
  SectorId?: Maybe<Scalars['uuid']>;
  Status?: Maybe<Scalars['smallint']>;
  TaxNumber?: Maybe<Scalars['String']>;
  TimeZoneIana?: Maybe<Scalars['String']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  WebsiteUrl?: Maybe<Scalars['String']>;
  XeroContactId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "dw.Accounts" */
export type Dw_Accounts_Max_Order_By = {
  AccMgrContactId?: Maybe<Order_By>;
  AccountId?: Maybe<Order_By>;
  BillingEmailAddress?: Maybe<Order_By>;
  CountryCode?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  CustomerType?: Maybe<Order_By>;
  EstablishedOn?: Maybe<Order_By>;
  FinancialStartMonth?: Maybe<Order_By>;
  IndustryCode?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  Notes?: Maybe<Order_By>;
  NumEmployees?: Maybe<Order_By>;
  ParentAccountId?: Maybe<Order_By>;
  PrimaryContactId?: Maybe<Order_By>;
  SectorId?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  TaxNumber?: Maybe<Order_By>;
  TimeZoneIana?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
  WebsiteUrl?: Maybe<Order_By>;
  XeroContactId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_Accounts_Min_Fields = {
  __typename?: 'dw_Accounts_min_fields';
  AccMgrContactId?: Maybe<Scalars['uuid']>;
  AccountId?: Maybe<Scalars['uuid']>;
  BillingEmailAddress?: Maybe<Scalars['String']>;
  CountryCode?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  CustomerType?: Maybe<Scalars['String']>;
  EstablishedOn?: Maybe<Scalars['timestamp']>;
  FinancialStartMonth?: Maybe<Scalars['smallint']>;
  IndustryCode?: Maybe<Scalars['String']>;
  Label?: Maybe<Scalars['String']>;
  Notes?: Maybe<Scalars['String']>;
  NumEmployees?: Maybe<Scalars['Int']>;
  ParentAccountId?: Maybe<Scalars['uuid']>;
  PrimaryContactId?: Maybe<Scalars['uuid']>;
  SectorId?: Maybe<Scalars['uuid']>;
  Status?: Maybe<Scalars['smallint']>;
  TaxNumber?: Maybe<Scalars['String']>;
  TimeZoneIana?: Maybe<Scalars['String']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  WebsiteUrl?: Maybe<Scalars['String']>;
  XeroContactId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "dw.Accounts" */
export type Dw_Accounts_Min_Order_By = {
  AccMgrContactId?: Maybe<Order_By>;
  AccountId?: Maybe<Order_By>;
  BillingEmailAddress?: Maybe<Order_By>;
  CountryCode?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  CustomerType?: Maybe<Order_By>;
  EstablishedOn?: Maybe<Order_By>;
  FinancialStartMonth?: Maybe<Order_By>;
  IndustryCode?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  Notes?: Maybe<Order_By>;
  NumEmployees?: Maybe<Order_By>;
  ParentAccountId?: Maybe<Order_By>;
  PrimaryContactId?: Maybe<Order_By>;
  SectorId?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  TaxNumber?: Maybe<Order_By>;
  TimeZoneIana?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
  WebsiteUrl?: Maybe<Order_By>;
  XeroContactId?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.Accounts" */
export type Dw_Accounts_Mutation_Response = {
  __typename?: 'dw_Accounts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_Accounts>;
};

/** input type for inserting object relation for remote table "dw.Accounts" */
export type Dw_Accounts_Obj_Rel_Insert_Input = {
  data: Dw_Accounts_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_Accounts_On_Conflict>;
};

/** on conflict condition type for table "dw.Accounts" */
export type Dw_Accounts_On_Conflict = {
  constraint: Dw_Accounts_Constraint;
  update_columns?: Array<Dw_Accounts_Update_Column>;
  where?: Maybe<Dw_Accounts_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.Accounts". */
export type Dw_Accounts_Order_By = {
  AccMgrContactId?: Maybe<Order_By>;
  AccountId?: Maybe<Order_By>;
  AccountsAddresses_aggregate?: Maybe<Dw_AccountsAddress_Aggregate_Order_By>;
  AccountsSubscriptions_aggregate?: Maybe<Dw_AccountsSubscription_Aggregate_Order_By>;
  BillingEmailAddress?: Maybe<Order_By>;
  Contact?: Maybe<Dw_Contacts_Order_By>;
  ContactsRoles_aggregate?: Maybe<Dw_ContactsRole_Aggregate_Order_By>;
  CountryCode?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  CustomerType?: Maybe<Order_By>;
  Dashboards_aggregate?: Maybe<Dw_Dashboards_Aggregate_Order_By>;
  EstablishedOn?: Maybe<Order_By>;
  FinancialStartMonth?: Maybe<Order_By>;
  Groups_aggregate?: Maybe<Dw_Groups_Aggregate_Order_By>;
  IndustryCode?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  Notes?: Maybe<Order_By>;
  Notifications_aggregate?: Maybe<Dw_Notifications_Aggregate_Order_By>;
  NumEmployees?: Maybe<Order_By>;
  ParentAccountId?: Maybe<Order_By>;
  PrimaryContactId?: Maybe<Order_By>;
  Projects_aggregate?: Maybe<Dw_Projects_Aggregate_Order_By>;
  Reports_aggregate?: Maybe<Dw_Reports_Aggregate_Order_By>;
  SectorId?: Maybe<Order_By>;
  Sites_aggregate?: Maybe<Dw_Sites_Aggregate_Order_By>;
  Status?: Maybe<Order_By>;
  TaxNumber?: Maybe<Order_By>;
  TimeZoneIana?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
  WebsiteUrl?: Maybe<Order_By>;
  XeroContactId?: Maybe<Order_By>;
  contactByPrimarycontactid?: Maybe<Dw_Contacts_Order_By>;
};

/** primary key columns input for table: dw_Accounts */
export type Dw_Accounts_Pk_Columns_Input = {
  AccountId: Scalars['uuid'];
};

/** select columns of table "dw.Accounts" */
export enum Dw_Accounts_Select_Column {
  /** column name */
  AccMgrContactId = 'AccMgrContactId',
  /** column name */
  AccountId = 'AccountId',
  /** column name */
  BillingEmailAddress = 'BillingEmailAddress',
  /** column name */
  CountryCode = 'CountryCode',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  CustomerType = 'CustomerType',
  /** column name */
  EstablishedOn = 'EstablishedOn',
  /** column name */
  FinancialStartMonth = 'FinancialStartMonth',
  /** column name */
  IndustryCode = 'IndustryCode',
  /** column name */
  Label = 'Label',
  /** column name */
  Notes = 'Notes',
  /** column name */
  NumEmployees = 'NumEmployees',
  /** column name */
  ParentAccountId = 'ParentAccountId',
  /** column name */
  PrimaryContactId = 'PrimaryContactId',
  /** column name */
  SectorId = 'SectorId',
  /** column name */
  Status = 'Status',
  /** column name */
  TaxNumber = 'TaxNumber',
  /** column name */
  TimeZoneIana = 'TimeZoneIana',
  /** column name */
  UpdatedOn = 'UpdatedOn',
  /** column name */
  WebsiteUrl = 'WebsiteUrl',
  /** column name */
  XeroContactId = 'XeroContactId'
}

/** input type for updating data in table "dw.Accounts" */
export type Dw_Accounts_Set_Input = {
  AccMgrContactId?: Maybe<Scalars['uuid']>;
  AccountId?: Maybe<Scalars['uuid']>;
  BillingEmailAddress?: Maybe<Scalars['String']>;
  CountryCode?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  CustomerType?: Maybe<Scalars['String']>;
  EstablishedOn?: Maybe<Scalars['timestamp']>;
  FinancialStartMonth?: Maybe<Scalars['smallint']>;
  IndustryCode?: Maybe<Scalars['String']>;
  Label?: Maybe<Scalars['String']>;
  Notes?: Maybe<Scalars['String']>;
  NumEmployees?: Maybe<Scalars['Int']>;
  ParentAccountId?: Maybe<Scalars['uuid']>;
  PrimaryContactId?: Maybe<Scalars['uuid']>;
  SectorId?: Maybe<Scalars['uuid']>;
  Status?: Maybe<Scalars['smallint']>;
  TaxNumber?: Maybe<Scalars['String']>;
  TimeZoneIana?: Maybe<Scalars['String']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  WebsiteUrl?: Maybe<Scalars['String']>;
  XeroContactId?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Dw_Accounts_Stddev_Fields = {
  __typename?: 'dw_Accounts_stddev_fields';
  FinancialStartMonth?: Maybe<Scalars['Float']>;
  NumEmployees?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.Accounts" */
export type Dw_Accounts_Stddev_Order_By = {
  FinancialStartMonth?: Maybe<Order_By>;
  NumEmployees?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_Accounts_Stddev_Pop_Fields = {
  __typename?: 'dw_Accounts_stddev_pop_fields';
  FinancialStartMonth?: Maybe<Scalars['Float']>;
  NumEmployees?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.Accounts" */
export type Dw_Accounts_Stddev_Pop_Order_By = {
  FinancialStartMonth?: Maybe<Order_By>;
  NumEmployees?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_Accounts_Stddev_Samp_Fields = {
  __typename?: 'dw_Accounts_stddev_samp_fields';
  FinancialStartMonth?: Maybe<Scalars['Float']>;
  NumEmployees?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.Accounts" */
export type Dw_Accounts_Stddev_Samp_Order_By = {
  FinancialStartMonth?: Maybe<Order_By>;
  NumEmployees?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_Accounts_Sum_Fields = {
  __typename?: 'dw_Accounts_sum_fields';
  FinancialStartMonth?: Maybe<Scalars['smallint']>;
  NumEmployees?: Maybe<Scalars['Int']>;
  Status?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "dw.Accounts" */
export type Dw_Accounts_Sum_Order_By = {
  FinancialStartMonth?: Maybe<Order_By>;
  NumEmployees?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** update columns of table "dw.Accounts" */
export enum Dw_Accounts_Update_Column {
  /** column name */
  AccMgrContactId = 'AccMgrContactId',
  /** column name */
  AccountId = 'AccountId',
  /** column name */
  BillingEmailAddress = 'BillingEmailAddress',
  /** column name */
  CountryCode = 'CountryCode',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  CustomerType = 'CustomerType',
  /** column name */
  EstablishedOn = 'EstablishedOn',
  /** column name */
  FinancialStartMonth = 'FinancialStartMonth',
  /** column name */
  IndustryCode = 'IndustryCode',
  /** column name */
  Label = 'Label',
  /** column name */
  Notes = 'Notes',
  /** column name */
  NumEmployees = 'NumEmployees',
  /** column name */
  ParentAccountId = 'ParentAccountId',
  /** column name */
  PrimaryContactId = 'PrimaryContactId',
  /** column name */
  SectorId = 'SectorId',
  /** column name */
  Status = 'Status',
  /** column name */
  TaxNumber = 'TaxNumber',
  /** column name */
  TimeZoneIana = 'TimeZoneIana',
  /** column name */
  UpdatedOn = 'UpdatedOn',
  /** column name */
  WebsiteUrl = 'WebsiteUrl',
  /** column name */
  XeroContactId = 'XeroContactId'
}

/** aggregate var_pop on columns */
export type Dw_Accounts_Var_Pop_Fields = {
  __typename?: 'dw_Accounts_var_pop_fields';
  FinancialStartMonth?: Maybe<Scalars['Float']>;
  NumEmployees?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.Accounts" */
export type Dw_Accounts_Var_Pop_Order_By = {
  FinancialStartMonth?: Maybe<Order_By>;
  NumEmployees?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_Accounts_Var_Samp_Fields = {
  __typename?: 'dw_Accounts_var_samp_fields';
  FinancialStartMonth?: Maybe<Scalars['Float']>;
  NumEmployees?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.Accounts" */
export type Dw_Accounts_Var_Samp_Order_By = {
  FinancialStartMonth?: Maybe<Order_By>;
  NumEmployees?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_Accounts_Variance_Fields = {
  __typename?: 'dw_Accounts_variance_fields';
  FinancialStartMonth?: Maybe<Scalars['Float']>;
  NumEmployees?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.Accounts" */
export type Dw_Accounts_Variance_Order_By = {
  FinancialStartMonth?: Maybe<Order_By>;
  NumEmployees?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** columns and relationships of "dw.Assessments" */
export type Dw_Assessments = {
  __typename?: 'dw_Assessments';
  AccountId: Scalars['uuid'];
  AssessmentId: Scalars['uuid'];
  Baseline: Scalars['Boolean'];
  ContactId: Scalars['uuid'];
  CreatedOn: Scalars['timestamp'];
  EmissionFactorId: Scalars['uuid'];
  EndDate: Scalars['timestamp'];
  FTE: Scalars['numeric'];
  Frequency: Scalars['smallint'];
  GrossRevenue: Scalars['numeric'];
  NormalisingFactor: Scalars['smallint'];
  Published: Scalars['Boolean'];
  StartDate: Scalars['timestamp'];
  UpdatedOn: Scalars['timestamp'];
};

/** aggregated selection of "dw.Assessments" */
export type Dw_Assessments_Aggregate = {
  __typename?: 'dw_Assessments_aggregate';
  aggregate?: Maybe<Dw_Assessments_Aggregate_Fields>;
  nodes: Array<Dw_Assessments>;
};

/** aggregate fields of "dw.Assessments" */
export type Dw_Assessments_Aggregate_Fields = {
  __typename?: 'dw_Assessments_aggregate_fields';
  avg?: Maybe<Dw_Assessments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_Assessments_Max_Fields>;
  min?: Maybe<Dw_Assessments_Min_Fields>;
  stddev?: Maybe<Dw_Assessments_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_Assessments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_Assessments_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_Assessments_Sum_Fields>;
  var_pop?: Maybe<Dw_Assessments_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_Assessments_Var_Samp_Fields>;
  variance?: Maybe<Dw_Assessments_Variance_Fields>;
};


/** aggregate fields of "dw.Assessments" */
export type Dw_Assessments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_Assessments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dw_Assessments_Avg_Fields = {
  __typename?: 'dw_Assessments_avg_fields';
  FTE?: Maybe<Scalars['Float']>;
  Frequency?: Maybe<Scalars['Float']>;
  GrossRevenue?: Maybe<Scalars['Float']>;
  NormalisingFactor?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dw.Assessments". All fields are combined with a logical 'AND'. */
export type Dw_Assessments_Bool_Exp = {
  AccountId?: Maybe<Uuid_Comparison_Exp>;
  AssessmentId?: Maybe<Uuid_Comparison_Exp>;
  Baseline?: Maybe<Boolean_Comparison_Exp>;
  ContactId?: Maybe<Uuid_Comparison_Exp>;
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  EmissionFactorId?: Maybe<Uuid_Comparison_Exp>;
  EndDate?: Maybe<Timestamp_Comparison_Exp>;
  FTE?: Maybe<Numeric_Comparison_Exp>;
  Frequency?: Maybe<Smallint_Comparison_Exp>;
  GrossRevenue?: Maybe<Numeric_Comparison_Exp>;
  NormalisingFactor?: Maybe<Smallint_Comparison_Exp>;
  Published?: Maybe<Boolean_Comparison_Exp>;
  StartDate?: Maybe<Timestamp_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  _and?: Maybe<Array<Dw_Assessments_Bool_Exp>>;
  _not?: Maybe<Dw_Assessments_Bool_Exp>;
  _or?: Maybe<Array<Dw_Assessments_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.Assessments" */
export enum Dw_Assessments_Constraint {
  /** unique or primary key constraint */
  AssessmentsPkey = 'Assessments_pkey'
}

/** input type for incrementing numeric columns in table "dw.Assessments" */
export type Dw_Assessments_Inc_Input = {
  FTE?: Maybe<Scalars['numeric']>;
  Frequency?: Maybe<Scalars['smallint']>;
  GrossRevenue?: Maybe<Scalars['numeric']>;
  NormalisingFactor?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.Assessments" */
export type Dw_Assessments_Insert_Input = {
  AccountId?: Maybe<Scalars['uuid']>;
  AssessmentId?: Maybe<Scalars['uuid']>;
  Baseline?: Maybe<Scalars['Boolean']>;
  ContactId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  EmissionFactorId?: Maybe<Scalars['uuid']>;
  EndDate?: Maybe<Scalars['timestamp']>;
  FTE?: Maybe<Scalars['numeric']>;
  Frequency?: Maybe<Scalars['smallint']>;
  GrossRevenue?: Maybe<Scalars['numeric']>;
  NormalisingFactor?: Maybe<Scalars['smallint']>;
  Published?: Maybe<Scalars['Boolean']>;
  StartDate?: Maybe<Scalars['timestamp']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Dw_Assessments_Max_Fields = {
  __typename?: 'dw_Assessments_max_fields';
  AccountId?: Maybe<Scalars['uuid']>;
  AssessmentId?: Maybe<Scalars['uuid']>;
  ContactId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  EmissionFactorId?: Maybe<Scalars['uuid']>;
  EndDate?: Maybe<Scalars['timestamp']>;
  FTE?: Maybe<Scalars['numeric']>;
  Frequency?: Maybe<Scalars['smallint']>;
  GrossRevenue?: Maybe<Scalars['numeric']>;
  NormalisingFactor?: Maybe<Scalars['smallint']>;
  StartDate?: Maybe<Scalars['timestamp']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Dw_Assessments_Min_Fields = {
  __typename?: 'dw_Assessments_min_fields';
  AccountId?: Maybe<Scalars['uuid']>;
  AssessmentId?: Maybe<Scalars['uuid']>;
  ContactId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  EmissionFactorId?: Maybe<Scalars['uuid']>;
  EndDate?: Maybe<Scalars['timestamp']>;
  FTE?: Maybe<Scalars['numeric']>;
  Frequency?: Maybe<Scalars['smallint']>;
  GrossRevenue?: Maybe<Scalars['numeric']>;
  NormalisingFactor?: Maybe<Scalars['smallint']>;
  StartDate?: Maybe<Scalars['timestamp']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "dw.Assessments" */
export type Dw_Assessments_Mutation_Response = {
  __typename?: 'dw_Assessments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_Assessments>;
};

/** on conflict condition type for table "dw.Assessments" */
export type Dw_Assessments_On_Conflict = {
  constraint: Dw_Assessments_Constraint;
  update_columns?: Array<Dw_Assessments_Update_Column>;
  where?: Maybe<Dw_Assessments_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.Assessments". */
export type Dw_Assessments_Order_By = {
  AccountId?: Maybe<Order_By>;
  AssessmentId?: Maybe<Order_By>;
  Baseline?: Maybe<Order_By>;
  ContactId?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  EmissionFactorId?: Maybe<Order_By>;
  EndDate?: Maybe<Order_By>;
  FTE?: Maybe<Order_By>;
  Frequency?: Maybe<Order_By>;
  GrossRevenue?: Maybe<Order_By>;
  NormalisingFactor?: Maybe<Order_By>;
  Published?: Maybe<Order_By>;
  StartDate?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_Assessments */
export type Dw_Assessments_Pk_Columns_Input = {
  AssessmentId: Scalars['uuid'];
};

/** select columns of table "dw.Assessments" */
export enum Dw_Assessments_Select_Column {
  /** column name */
  AccountId = 'AccountId',
  /** column name */
  AssessmentId = 'AssessmentId',
  /** column name */
  Baseline = 'Baseline',
  /** column name */
  ContactId = 'ContactId',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  EmissionFactorId = 'EmissionFactorId',
  /** column name */
  EndDate = 'EndDate',
  /** column name */
  Fte = 'FTE',
  /** column name */
  Frequency = 'Frequency',
  /** column name */
  GrossRevenue = 'GrossRevenue',
  /** column name */
  NormalisingFactor = 'NormalisingFactor',
  /** column name */
  Published = 'Published',
  /** column name */
  StartDate = 'StartDate',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** input type for updating data in table "dw.Assessments" */
export type Dw_Assessments_Set_Input = {
  AccountId?: Maybe<Scalars['uuid']>;
  AssessmentId?: Maybe<Scalars['uuid']>;
  Baseline?: Maybe<Scalars['Boolean']>;
  ContactId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  EmissionFactorId?: Maybe<Scalars['uuid']>;
  EndDate?: Maybe<Scalars['timestamp']>;
  FTE?: Maybe<Scalars['numeric']>;
  Frequency?: Maybe<Scalars['smallint']>;
  GrossRevenue?: Maybe<Scalars['numeric']>;
  NormalisingFactor?: Maybe<Scalars['smallint']>;
  Published?: Maybe<Scalars['Boolean']>;
  StartDate?: Maybe<Scalars['timestamp']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Dw_Assessments_Stddev_Fields = {
  __typename?: 'dw_Assessments_stddev_fields';
  FTE?: Maybe<Scalars['Float']>;
  Frequency?: Maybe<Scalars['Float']>;
  GrossRevenue?: Maybe<Scalars['Float']>;
  NormalisingFactor?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dw_Assessments_Stddev_Pop_Fields = {
  __typename?: 'dw_Assessments_stddev_pop_fields';
  FTE?: Maybe<Scalars['Float']>;
  Frequency?: Maybe<Scalars['Float']>;
  GrossRevenue?: Maybe<Scalars['Float']>;
  NormalisingFactor?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dw_Assessments_Stddev_Samp_Fields = {
  __typename?: 'dw_Assessments_stddev_samp_fields';
  FTE?: Maybe<Scalars['Float']>;
  Frequency?: Maybe<Scalars['Float']>;
  GrossRevenue?: Maybe<Scalars['Float']>;
  NormalisingFactor?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Dw_Assessments_Sum_Fields = {
  __typename?: 'dw_Assessments_sum_fields';
  FTE?: Maybe<Scalars['numeric']>;
  Frequency?: Maybe<Scalars['smallint']>;
  GrossRevenue?: Maybe<Scalars['numeric']>;
  NormalisingFactor?: Maybe<Scalars['smallint']>;
};

/** update columns of table "dw.Assessments" */
export enum Dw_Assessments_Update_Column {
  /** column name */
  AccountId = 'AccountId',
  /** column name */
  AssessmentId = 'AssessmentId',
  /** column name */
  Baseline = 'Baseline',
  /** column name */
  ContactId = 'ContactId',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  EmissionFactorId = 'EmissionFactorId',
  /** column name */
  EndDate = 'EndDate',
  /** column name */
  Fte = 'FTE',
  /** column name */
  Frequency = 'Frequency',
  /** column name */
  GrossRevenue = 'GrossRevenue',
  /** column name */
  NormalisingFactor = 'NormalisingFactor',
  /** column name */
  Published = 'Published',
  /** column name */
  StartDate = 'StartDate',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** aggregate var_pop on columns */
export type Dw_Assessments_Var_Pop_Fields = {
  __typename?: 'dw_Assessments_var_pop_fields';
  FTE?: Maybe<Scalars['Float']>;
  Frequency?: Maybe<Scalars['Float']>;
  GrossRevenue?: Maybe<Scalars['Float']>;
  NormalisingFactor?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dw_Assessments_Var_Samp_Fields = {
  __typename?: 'dw_Assessments_var_samp_fields';
  FTE?: Maybe<Scalars['Float']>;
  Frequency?: Maybe<Scalars['Float']>;
  GrossRevenue?: Maybe<Scalars['Float']>;
  NormalisingFactor?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dw_Assessments_Variance_Fields = {
  __typename?: 'dw_Assessments_variance_fields';
  FTE?: Maybe<Scalars['Float']>;
  Frequency?: Maybe<Scalars['Float']>;
  GrossRevenue?: Maybe<Scalars['Float']>;
  NormalisingFactor?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "dw.Baseline" */
export type Dw_Baseline = {
  __typename?: 'dw_Baseline';
  /** An object relationship */
  Channel: Dw_Channels;
  ChannelId: Scalars['uuid'];
  ConsumptionDate: Scalars['timestamp'];
  Value: Scalars['numeric'];
};

/** aggregated selection of "dw.Baseline" */
export type Dw_Baseline_Aggregate = {
  __typename?: 'dw_Baseline_aggregate';
  aggregate?: Maybe<Dw_Baseline_Aggregate_Fields>;
  nodes: Array<Dw_Baseline>;
};

/** aggregate fields of "dw.Baseline" */
export type Dw_Baseline_Aggregate_Fields = {
  __typename?: 'dw_Baseline_aggregate_fields';
  avg?: Maybe<Dw_Baseline_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_Baseline_Max_Fields>;
  min?: Maybe<Dw_Baseline_Min_Fields>;
  stddev?: Maybe<Dw_Baseline_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_Baseline_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_Baseline_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_Baseline_Sum_Fields>;
  var_pop?: Maybe<Dw_Baseline_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_Baseline_Var_Samp_Fields>;
  variance?: Maybe<Dw_Baseline_Variance_Fields>;
};


/** aggregate fields of "dw.Baseline" */
export type Dw_Baseline_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_Baseline_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.Baseline" */
export type Dw_Baseline_Aggregate_Order_By = {
  avg?: Maybe<Dw_Baseline_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_Baseline_Max_Order_By>;
  min?: Maybe<Dw_Baseline_Min_Order_By>;
  stddev?: Maybe<Dw_Baseline_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_Baseline_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_Baseline_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_Baseline_Sum_Order_By>;
  var_pop?: Maybe<Dw_Baseline_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_Baseline_Var_Samp_Order_By>;
  variance?: Maybe<Dw_Baseline_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.Baseline" */
export type Dw_Baseline_Arr_Rel_Insert_Input = {
  data: Array<Dw_Baseline_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_Baseline_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_Baseline_Avg_Fields = {
  __typename?: 'dw_Baseline_avg_fields';
  Value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.Baseline" */
export type Dw_Baseline_Avg_Order_By = {
  Value?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.Baseline". All fields are combined with a logical 'AND'. */
export type Dw_Baseline_Bool_Exp = {
  Channel?: Maybe<Dw_Channels_Bool_Exp>;
  ChannelId?: Maybe<Uuid_Comparison_Exp>;
  ConsumptionDate?: Maybe<Timestamp_Comparison_Exp>;
  Value?: Maybe<Numeric_Comparison_Exp>;
  _and?: Maybe<Array<Dw_Baseline_Bool_Exp>>;
  _not?: Maybe<Dw_Baseline_Bool_Exp>;
  _or?: Maybe<Array<Dw_Baseline_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.Baseline" */
export enum Dw_Baseline_Constraint {
  /** unique or primary key constraint */
  BaselineChannelidConsumptiondateUindex = 'baseline_channelid_consumptiondate_uindex',
  /** unique or primary key constraint */
  BaselinePk = 'baseline_pk'
}

/** input type for incrementing numeric columns in table "dw.Baseline" */
export type Dw_Baseline_Inc_Input = {
  Value?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "dw.Baseline" */
export type Dw_Baseline_Insert_Input = {
  Channel?: Maybe<Dw_Channels_Obj_Rel_Insert_Input>;
  ChannelId?: Maybe<Scalars['uuid']>;
  ConsumptionDate?: Maybe<Scalars['timestamp']>;
  Value?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Dw_Baseline_Max_Fields = {
  __typename?: 'dw_Baseline_max_fields';
  ChannelId?: Maybe<Scalars['uuid']>;
  ConsumptionDate?: Maybe<Scalars['timestamp']>;
  Value?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "dw.Baseline" */
export type Dw_Baseline_Max_Order_By = {
  ChannelId?: Maybe<Order_By>;
  ConsumptionDate?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_Baseline_Min_Fields = {
  __typename?: 'dw_Baseline_min_fields';
  ChannelId?: Maybe<Scalars['uuid']>;
  ConsumptionDate?: Maybe<Scalars['timestamp']>;
  Value?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "dw.Baseline" */
export type Dw_Baseline_Min_Order_By = {
  ChannelId?: Maybe<Order_By>;
  ConsumptionDate?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.Baseline" */
export type Dw_Baseline_Mutation_Response = {
  __typename?: 'dw_Baseline_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_Baseline>;
};

/** on conflict condition type for table "dw.Baseline" */
export type Dw_Baseline_On_Conflict = {
  constraint: Dw_Baseline_Constraint;
  update_columns?: Array<Dw_Baseline_Update_Column>;
  where?: Maybe<Dw_Baseline_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.Baseline". */
export type Dw_Baseline_Order_By = {
  Channel?: Maybe<Dw_Channels_Order_By>;
  ChannelId?: Maybe<Order_By>;
  ConsumptionDate?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_Baseline */
export type Dw_Baseline_Pk_Columns_Input = {
  ChannelId: Scalars['uuid'];
  ConsumptionDate: Scalars['timestamp'];
};

/** select columns of table "dw.Baseline" */
export enum Dw_Baseline_Select_Column {
  /** column name */
  ChannelId = 'ChannelId',
  /** column name */
  ConsumptionDate = 'ConsumptionDate',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "dw.Baseline" */
export type Dw_Baseline_Set_Input = {
  ChannelId?: Maybe<Scalars['uuid']>;
  ConsumptionDate?: Maybe<Scalars['timestamp']>;
  Value?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Dw_Baseline_Stddev_Fields = {
  __typename?: 'dw_Baseline_stddev_fields';
  Value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.Baseline" */
export type Dw_Baseline_Stddev_Order_By = {
  Value?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_Baseline_Stddev_Pop_Fields = {
  __typename?: 'dw_Baseline_stddev_pop_fields';
  Value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.Baseline" */
export type Dw_Baseline_Stddev_Pop_Order_By = {
  Value?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_Baseline_Stddev_Samp_Fields = {
  __typename?: 'dw_Baseline_stddev_samp_fields';
  Value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.Baseline" */
export type Dw_Baseline_Stddev_Samp_Order_By = {
  Value?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_Baseline_Sum_Fields = {
  __typename?: 'dw_Baseline_sum_fields';
  Value?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "dw.Baseline" */
export type Dw_Baseline_Sum_Order_By = {
  Value?: Maybe<Order_By>;
};

/** update columns of table "dw.Baseline" */
export enum Dw_Baseline_Update_Column {
  /** column name */
  ChannelId = 'ChannelId',
  /** column name */
  ConsumptionDate = 'ConsumptionDate',
  /** column name */
  Value = 'Value'
}

/** aggregate var_pop on columns */
export type Dw_Baseline_Var_Pop_Fields = {
  __typename?: 'dw_Baseline_var_pop_fields';
  Value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.Baseline" */
export type Dw_Baseline_Var_Pop_Order_By = {
  Value?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_Baseline_Var_Samp_Fields = {
  __typename?: 'dw_Baseline_var_samp_fields';
  Value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.Baseline" */
export type Dw_Baseline_Var_Samp_Order_By = {
  Value?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_Baseline_Variance_Fields = {
  __typename?: 'dw_Baseline_variance_fields';
  Value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.Baseline" */
export type Dw_Baseline_Variance_Order_By = {
  Value?: Maybe<Order_By>;
};

/** columns and relationships of "dw.BuildingDocuments" */
export type Dw_BuildingDocuments = {
  __typename?: 'dw_BuildingDocuments';
  /** An object relationship */
  Building: Dw_Buildings;
  BuildingId: Scalars['uuid'];
  DocumentId: Scalars['uuid'];
  FileName: Scalars['String'];
  Status: Scalars['smallint'];
  UploadDate: Scalars['timestamp'];
};

/** aggregated selection of "dw.BuildingDocuments" */
export type Dw_BuildingDocuments_Aggregate = {
  __typename?: 'dw_BuildingDocuments_aggregate';
  aggregate?: Maybe<Dw_BuildingDocuments_Aggregate_Fields>;
  nodes: Array<Dw_BuildingDocuments>;
};

/** aggregate fields of "dw.BuildingDocuments" */
export type Dw_BuildingDocuments_Aggregate_Fields = {
  __typename?: 'dw_BuildingDocuments_aggregate_fields';
  avg?: Maybe<Dw_BuildingDocuments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_BuildingDocuments_Max_Fields>;
  min?: Maybe<Dw_BuildingDocuments_Min_Fields>;
  stddev?: Maybe<Dw_BuildingDocuments_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_BuildingDocuments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_BuildingDocuments_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_BuildingDocuments_Sum_Fields>;
  var_pop?: Maybe<Dw_BuildingDocuments_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_BuildingDocuments_Var_Samp_Fields>;
  variance?: Maybe<Dw_BuildingDocuments_Variance_Fields>;
};


/** aggregate fields of "dw.BuildingDocuments" */
export type Dw_BuildingDocuments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_BuildingDocuments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.BuildingDocuments" */
export type Dw_BuildingDocuments_Aggregate_Order_By = {
  avg?: Maybe<Dw_BuildingDocuments_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_BuildingDocuments_Max_Order_By>;
  min?: Maybe<Dw_BuildingDocuments_Min_Order_By>;
  stddev?: Maybe<Dw_BuildingDocuments_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_BuildingDocuments_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_BuildingDocuments_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_BuildingDocuments_Sum_Order_By>;
  var_pop?: Maybe<Dw_BuildingDocuments_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_BuildingDocuments_Var_Samp_Order_By>;
  variance?: Maybe<Dw_BuildingDocuments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.BuildingDocuments" */
export type Dw_BuildingDocuments_Arr_Rel_Insert_Input = {
  data: Array<Dw_BuildingDocuments_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_BuildingDocuments_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_BuildingDocuments_Avg_Fields = {
  __typename?: 'dw_BuildingDocuments_avg_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.BuildingDocuments" */
export type Dw_BuildingDocuments_Avg_Order_By = {
  Status?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.BuildingDocuments". All fields are combined with a logical 'AND'. */
export type Dw_BuildingDocuments_Bool_Exp = {
  Building?: Maybe<Dw_Buildings_Bool_Exp>;
  BuildingId?: Maybe<Uuid_Comparison_Exp>;
  DocumentId?: Maybe<Uuid_Comparison_Exp>;
  FileName?: Maybe<String_Comparison_Exp>;
  Status?: Maybe<Smallint_Comparison_Exp>;
  UploadDate?: Maybe<Timestamp_Comparison_Exp>;
  _and?: Maybe<Array<Dw_BuildingDocuments_Bool_Exp>>;
  _not?: Maybe<Dw_BuildingDocuments_Bool_Exp>;
  _or?: Maybe<Array<Dw_BuildingDocuments_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.BuildingDocuments" */
export enum Dw_BuildingDocuments_Constraint {
  /** unique or primary key constraint */
  BuildingdocumentsCertificateidUindex = 'buildingdocuments_certificateid_uindex',
  /** unique or primary key constraint */
  BuildingdocumentsPk = 'buildingdocuments_pk'
}

/** input type for incrementing numeric columns in table "dw.BuildingDocuments" */
export type Dw_BuildingDocuments_Inc_Input = {
  Status?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.BuildingDocuments" */
export type Dw_BuildingDocuments_Insert_Input = {
  Building?: Maybe<Dw_Buildings_Obj_Rel_Insert_Input>;
  BuildingId?: Maybe<Scalars['uuid']>;
  DocumentId?: Maybe<Scalars['uuid']>;
  FileName?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['smallint']>;
  UploadDate?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Dw_BuildingDocuments_Max_Fields = {
  __typename?: 'dw_BuildingDocuments_max_fields';
  BuildingId?: Maybe<Scalars['uuid']>;
  DocumentId?: Maybe<Scalars['uuid']>;
  FileName?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['smallint']>;
  UploadDate?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "dw.BuildingDocuments" */
export type Dw_BuildingDocuments_Max_Order_By = {
  BuildingId?: Maybe<Order_By>;
  DocumentId?: Maybe<Order_By>;
  FileName?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UploadDate?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_BuildingDocuments_Min_Fields = {
  __typename?: 'dw_BuildingDocuments_min_fields';
  BuildingId?: Maybe<Scalars['uuid']>;
  DocumentId?: Maybe<Scalars['uuid']>;
  FileName?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['smallint']>;
  UploadDate?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "dw.BuildingDocuments" */
export type Dw_BuildingDocuments_Min_Order_By = {
  BuildingId?: Maybe<Order_By>;
  DocumentId?: Maybe<Order_By>;
  FileName?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UploadDate?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.BuildingDocuments" */
export type Dw_BuildingDocuments_Mutation_Response = {
  __typename?: 'dw_BuildingDocuments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_BuildingDocuments>;
};

/** on conflict condition type for table "dw.BuildingDocuments" */
export type Dw_BuildingDocuments_On_Conflict = {
  constraint: Dw_BuildingDocuments_Constraint;
  update_columns?: Array<Dw_BuildingDocuments_Update_Column>;
  where?: Maybe<Dw_BuildingDocuments_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.BuildingDocuments". */
export type Dw_BuildingDocuments_Order_By = {
  Building?: Maybe<Dw_Buildings_Order_By>;
  BuildingId?: Maybe<Order_By>;
  DocumentId?: Maybe<Order_By>;
  FileName?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UploadDate?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_BuildingDocuments */
export type Dw_BuildingDocuments_Pk_Columns_Input = {
  DocumentId: Scalars['uuid'];
};

/** select columns of table "dw.BuildingDocuments" */
export enum Dw_BuildingDocuments_Select_Column {
  /** column name */
  BuildingId = 'BuildingId',
  /** column name */
  DocumentId = 'DocumentId',
  /** column name */
  FileName = 'FileName',
  /** column name */
  Status = 'Status',
  /** column name */
  UploadDate = 'UploadDate'
}

/** input type for updating data in table "dw.BuildingDocuments" */
export type Dw_BuildingDocuments_Set_Input = {
  BuildingId?: Maybe<Scalars['uuid']>;
  DocumentId?: Maybe<Scalars['uuid']>;
  FileName?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['smallint']>;
  UploadDate?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Dw_BuildingDocuments_Stddev_Fields = {
  __typename?: 'dw_BuildingDocuments_stddev_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.BuildingDocuments" */
export type Dw_BuildingDocuments_Stddev_Order_By = {
  Status?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_BuildingDocuments_Stddev_Pop_Fields = {
  __typename?: 'dw_BuildingDocuments_stddev_pop_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.BuildingDocuments" */
export type Dw_BuildingDocuments_Stddev_Pop_Order_By = {
  Status?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_BuildingDocuments_Stddev_Samp_Fields = {
  __typename?: 'dw_BuildingDocuments_stddev_samp_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.BuildingDocuments" */
export type Dw_BuildingDocuments_Stddev_Samp_Order_By = {
  Status?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_BuildingDocuments_Sum_Fields = {
  __typename?: 'dw_BuildingDocuments_sum_fields';
  Status?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "dw.BuildingDocuments" */
export type Dw_BuildingDocuments_Sum_Order_By = {
  Status?: Maybe<Order_By>;
};

/** update columns of table "dw.BuildingDocuments" */
export enum Dw_BuildingDocuments_Update_Column {
  /** column name */
  BuildingId = 'BuildingId',
  /** column name */
  DocumentId = 'DocumentId',
  /** column name */
  FileName = 'FileName',
  /** column name */
  Status = 'Status',
  /** column name */
  UploadDate = 'UploadDate'
}

/** aggregate var_pop on columns */
export type Dw_BuildingDocuments_Var_Pop_Fields = {
  __typename?: 'dw_BuildingDocuments_var_pop_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.BuildingDocuments" */
export type Dw_BuildingDocuments_Var_Pop_Order_By = {
  Status?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_BuildingDocuments_Var_Samp_Fields = {
  __typename?: 'dw_BuildingDocuments_var_samp_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.BuildingDocuments" */
export type Dw_BuildingDocuments_Var_Samp_Order_By = {
  Status?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_BuildingDocuments_Variance_Fields = {
  __typename?: 'dw_BuildingDocuments_variance_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.BuildingDocuments" */
export type Dw_BuildingDocuments_Variance_Order_By = {
  Status?: Maybe<Order_By>;
};

/** columns and relationships of "dw.Buildings" */
export type Dw_Buildings = {
  __typename?: 'dw_Buildings';
  /** An array relationship */
  BuildingDocuments: Array<Dw_BuildingDocuments>;
  /** An aggregate relationship */
  BuildingDocuments_aggregate: Dw_BuildingDocuments_Aggregate;
  BuildingId: Scalars['uuid'];
  /** An array relationship */
  Channels: Array<Dw_Channels>;
  /** An aggregate relationship */
  Channels_aggregate: Dw_Channels_Aggregate;
  CoolingSystem?: Maybe<Scalars['Int']>;
  CreatedOn: Scalars['timestamp'];
  /** An array relationship */
  Dashboards: Array<Dw_Dashboards>;
  /** An aggregate relationship */
  Dashboards_aggregate: Dw_Dashboards_Aggregate;
  /** An array relationship */
  Feeds: Array<Dw_Feeds>;
  /** An aggregate relationship */
  Feeds_aggregate: Dw_Feeds_Aggregate;
  FunctionalUse?: Maybe<Scalars['Int']>;
  GfaSqm?: Maybe<Scalars['numeric']>;
  HeatingSystem?: Maybe<Scalars['Int']>;
  IsicCode?: Maybe<Scalars['String']>;
  Label?: Maybe<Scalars['String']>;
  /** An array relationship */
  Loads: Array<Dw_Loads>;
  /** An aggregate relationship */
  Loads_aggregate: Dw_Loads_Aggregate;
  Location?: Maybe<Scalars['String']>;
  /** An array relationship */
  MLNotificationGroups: Array<Dw_MlNotificationGroup>;
  /** An aggregate relationship */
  MLNotificationGroups_aggregate: Dw_MlNotificationGroup_Aggregate;
  NlaSqm?: Maybe<Scalars['numeric']>;
  /** An array relationship */
  Notifications: Array<Dw_Notifications>;
  /** An aggregate relationship */
  Notifications_aggregate: Dw_Notifications_Aggregate;
  /** An array relationship */
  Projects: Array<Dw_Projects>;
  /** An aggregate relationship */
  Projects_aggregate: Dw_Projects_Aggregate;
  RatingExpiry?: Maybe<Scalars['timestamp']>;
  RatingType?: Maybe<Scalars['Int']>;
  RatingValue?: Maybe<Scalars['Int']>;
  /** An object relationship */
  Site: Dw_Sites;
  SiteId: Scalars['uuid'];
  Status: Scalars['smallint'];
  UpdatedOn: Scalars['timestamp'];
  WeatherId?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "dw.Buildings" */
export type Dw_BuildingsBuildingDocumentsArgs = {
  distinct_on?: Maybe<Array<Dw_BuildingDocuments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_BuildingDocuments_Order_By>>;
  where?: Maybe<Dw_BuildingDocuments_Bool_Exp>;
};


/** columns and relationships of "dw.Buildings" */
export type Dw_BuildingsBuildingDocuments_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_BuildingDocuments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_BuildingDocuments_Order_By>>;
  where?: Maybe<Dw_BuildingDocuments_Bool_Exp>;
};


/** columns and relationships of "dw.Buildings" */
export type Dw_BuildingsChannelsArgs = {
  distinct_on?: Maybe<Array<Dw_Channels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Channels_Order_By>>;
  where?: Maybe<Dw_Channels_Bool_Exp>;
};


/** columns and relationships of "dw.Buildings" */
export type Dw_BuildingsChannels_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Channels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Channels_Order_By>>;
  where?: Maybe<Dw_Channels_Bool_Exp>;
};


/** columns and relationships of "dw.Buildings" */
export type Dw_BuildingsDashboardsArgs = {
  distinct_on?: Maybe<Array<Dw_Dashboards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Dashboards_Order_By>>;
  where?: Maybe<Dw_Dashboards_Bool_Exp>;
};


/** columns and relationships of "dw.Buildings" */
export type Dw_BuildingsDashboards_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Dashboards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Dashboards_Order_By>>;
  where?: Maybe<Dw_Dashboards_Bool_Exp>;
};


/** columns and relationships of "dw.Buildings" */
export type Dw_BuildingsFeedsArgs = {
  distinct_on?: Maybe<Array<Dw_Feeds_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Feeds_Order_By>>;
  where?: Maybe<Dw_Feeds_Bool_Exp>;
};


/** columns and relationships of "dw.Buildings" */
export type Dw_BuildingsFeeds_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Feeds_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Feeds_Order_By>>;
  where?: Maybe<Dw_Feeds_Bool_Exp>;
};


/** columns and relationships of "dw.Buildings" */
export type Dw_BuildingsLoadsArgs = {
  distinct_on?: Maybe<Array<Dw_Loads_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Loads_Order_By>>;
  where?: Maybe<Dw_Loads_Bool_Exp>;
};


/** columns and relationships of "dw.Buildings" */
export type Dw_BuildingsLoads_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Loads_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Loads_Order_By>>;
  where?: Maybe<Dw_Loads_Bool_Exp>;
};


/** columns and relationships of "dw.Buildings" */
export type Dw_BuildingsMlNotificationGroupsArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotificationGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotificationGroup_Order_By>>;
  where?: Maybe<Dw_MlNotificationGroup_Bool_Exp>;
};


/** columns and relationships of "dw.Buildings" */
export type Dw_BuildingsMlNotificationGroups_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotificationGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotificationGroup_Order_By>>;
  where?: Maybe<Dw_MlNotificationGroup_Bool_Exp>;
};


/** columns and relationships of "dw.Buildings" */
export type Dw_BuildingsNotificationsArgs = {
  distinct_on?: Maybe<Array<Dw_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Notifications_Order_By>>;
  where?: Maybe<Dw_Notifications_Bool_Exp>;
};


/** columns and relationships of "dw.Buildings" */
export type Dw_BuildingsNotifications_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Notifications_Order_By>>;
  where?: Maybe<Dw_Notifications_Bool_Exp>;
};


/** columns and relationships of "dw.Buildings" */
export type Dw_BuildingsProjectsArgs = {
  distinct_on?: Maybe<Array<Dw_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Projects_Order_By>>;
  where?: Maybe<Dw_Projects_Bool_Exp>;
};


/** columns and relationships of "dw.Buildings" */
export type Dw_BuildingsProjects_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Projects_Order_By>>;
  where?: Maybe<Dw_Projects_Bool_Exp>;
};

/** aggregated selection of "dw.Buildings" */
export type Dw_Buildings_Aggregate = {
  __typename?: 'dw_Buildings_aggregate';
  aggregate?: Maybe<Dw_Buildings_Aggregate_Fields>;
  nodes: Array<Dw_Buildings>;
};

/** aggregate fields of "dw.Buildings" */
export type Dw_Buildings_Aggregate_Fields = {
  __typename?: 'dw_Buildings_aggregate_fields';
  avg?: Maybe<Dw_Buildings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_Buildings_Max_Fields>;
  min?: Maybe<Dw_Buildings_Min_Fields>;
  stddev?: Maybe<Dw_Buildings_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_Buildings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_Buildings_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_Buildings_Sum_Fields>;
  var_pop?: Maybe<Dw_Buildings_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_Buildings_Var_Samp_Fields>;
  variance?: Maybe<Dw_Buildings_Variance_Fields>;
};


/** aggregate fields of "dw.Buildings" */
export type Dw_Buildings_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_Buildings_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.Buildings" */
export type Dw_Buildings_Aggregate_Order_By = {
  avg?: Maybe<Dw_Buildings_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_Buildings_Max_Order_By>;
  min?: Maybe<Dw_Buildings_Min_Order_By>;
  stddev?: Maybe<Dw_Buildings_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_Buildings_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_Buildings_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_Buildings_Sum_Order_By>;
  var_pop?: Maybe<Dw_Buildings_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_Buildings_Var_Samp_Order_By>;
  variance?: Maybe<Dw_Buildings_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.Buildings" */
export type Dw_Buildings_Arr_Rel_Insert_Input = {
  data: Array<Dw_Buildings_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_Buildings_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_Buildings_Avg_Fields = {
  __typename?: 'dw_Buildings_avg_fields';
  CoolingSystem?: Maybe<Scalars['Float']>;
  FunctionalUse?: Maybe<Scalars['Float']>;
  GfaSqm?: Maybe<Scalars['Float']>;
  HeatingSystem?: Maybe<Scalars['Float']>;
  NlaSqm?: Maybe<Scalars['Float']>;
  RatingType?: Maybe<Scalars['Float']>;
  RatingValue?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.Buildings" */
export type Dw_Buildings_Avg_Order_By = {
  CoolingSystem?: Maybe<Order_By>;
  FunctionalUse?: Maybe<Order_By>;
  GfaSqm?: Maybe<Order_By>;
  HeatingSystem?: Maybe<Order_By>;
  NlaSqm?: Maybe<Order_By>;
  RatingType?: Maybe<Order_By>;
  RatingValue?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.Buildings". All fields are combined with a logical 'AND'. */
export type Dw_Buildings_Bool_Exp = {
  BuildingDocuments?: Maybe<Dw_BuildingDocuments_Bool_Exp>;
  BuildingId?: Maybe<Uuid_Comparison_Exp>;
  Channels?: Maybe<Dw_Channels_Bool_Exp>;
  CoolingSystem?: Maybe<Int_Comparison_Exp>;
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  Dashboards?: Maybe<Dw_Dashboards_Bool_Exp>;
  Feeds?: Maybe<Dw_Feeds_Bool_Exp>;
  FunctionalUse?: Maybe<Int_Comparison_Exp>;
  GfaSqm?: Maybe<Numeric_Comparison_Exp>;
  HeatingSystem?: Maybe<Int_Comparison_Exp>;
  IsicCode?: Maybe<String_Comparison_Exp>;
  Label?: Maybe<String_Comparison_Exp>;
  Loads?: Maybe<Dw_Loads_Bool_Exp>;
  Location?: Maybe<String_Comparison_Exp>;
  MLNotificationGroups?: Maybe<Dw_MlNotificationGroup_Bool_Exp>;
  NlaSqm?: Maybe<Numeric_Comparison_Exp>;
  Notifications?: Maybe<Dw_Notifications_Bool_Exp>;
  Projects?: Maybe<Dw_Projects_Bool_Exp>;
  RatingExpiry?: Maybe<Timestamp_Comparison_Exp>;
  RatingType?: Maybe<Int_Comparison_Exp>;
  RatingValue?: Maybe<Int_Comparison_Exp>;
  Site?: Maybe<Dw_Sites_Bool_Exp>;
  SiteId?: Maybe<Uuid_Comparison_Exp>;
  Status?: Maybe<Smallint_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  WeatherId?: Maybe<Uuid_Comparison_Exp>;
  _and?: Maybe<Array<Dw_Buildings_Bool_Exp>>;
  _not?: Maybe<Dw_Buildings_Bool_Exp>;
  _or?: Maybe<Array<Dw_Buildings_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.Buildings" */
export enum Dw_Buildings_Constraint {
  /** unique or primary key constraint */
  BuildingsBuildingidUindex = 'buildings_buildingid_uindex',
  /** unique or primary key constraint */
  BuildingsPk = 'buildings_pk'
}

/** input type for incrementing numeric columns in table "dw.Buildings" */
export type Dw_Buildings_Inc_Input = {
  CoolingSystem?: Maybe<Scalars['Int']>;
  FunctionalUse?: Maybe<Scalars['Int']>;
  GfaSqm?: Maybe<Scalars['numeric']>;
  HeatingSystem?: Maybe<Scalars['Int']>;
  NlaSqm?: Maybe<Scalars['numeric']>;
  RatingType?: Maybe<Scalars['Int']>;
  RatingValue?: Maybe<Scalars['Int']>;
  Status?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.Buildings" */
export type Dw_Buildings_Insert_Input = {
  BuildingDocuments?: Maybe<Dw_BuildingDocuments_Arr_Rel_Insert_Input>;
  BuildingId?: Maybe<Scalars['uuid']>;
  Channels?: Maybe<Dw_Channels_Arr_Rel_Insert_Input>;
  CoolingSystem?: Maybe<Scalars['Int']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  Dashboards?: Maybe<Dw_Dashboards_Arr_Rel_Insert_Input>;
  Feeds?: Maybe<Dw_Feeds_Arr_Rel_Insert_Input>;
  FunctionalUse?: Maybe<Scalars['Int']>;
  GfaSqm?: Maybe<Scalars['numeric']>;
  HeatingSystem?: Maybe<Scalars['Int']>;
  IsicCode?: Maybe<Scalars['String']>;
  Label?: Maybe<Scalars['String']>;
  Loads?: Maybe<Dw_Loads_Arr_Rel_Insert_Input>;
  Location?: Maybe<Scalars['String']>;
  MLNotificationGroups?: Maybe<Dw_MlNotificationGroup_Arr_Rel_Insert_Input>;
  NlaSqm?: Maybe<Scalars['numeric']>;
  Notifications?: Maybe<Dw_Notifications_Arr_Rel_Insert_Input>;
  Projects?: Maybe<Dw_Projects_Arr_Rel_Insert_Input>;
  RatingExpiry?: Maybe<Scalars['timestamp']>;
  RatingType?: Maybe<Scalars['Int']>;
  RatingValue?: Maybe<Scalars['Int']>;
  Site?: Maybe<Dw_Sites_Obj_Rel_Insert_Input>;
  SiteId?: Maybe<Scalars['uuid']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  WeatherId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Dw_Buildings_Max_Fields = {
  __typename?: 'dw_Buildings_max_fields';
  BuildingId?: Maybe<Scalars['uuid']>;
  CoolingSystem?: Maybe<Scalars['Int']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  FunctionalUse?: Maybe<Scalars['Int']>;
  GfaSqm?: Maybe<Scalars['numeric']>;
  HeatingSystem?: Maybe<Scalars['Int']>;
  IsicCode?: Maybe<Scalars['String']>;
  Label?: Maybe<Scalars['String']>;
  Location?: Maybe<Scalars['String']>;
  NlaSqm?: Maybe<Scalars['numeric']>;
  RatingExpiry?: Maybe<Scalars['timestamp']>;
  RatingType?: Maybe<Scalars['Int']>;
  RatingValue?: Maybe<Scalars['Int']>;
  SiteId?: Maybe<Scalars['uuid']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  WeatherId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "dw.Buildings" */
export type Dw_Buildings_Max_Order_By = {
  BuildingId?: Maybe<Order_By>;
  CoolingSystem?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  FunctionalUse?: Maybe<Order_By>;
  GfaSqm?: Maybe<Order_By>;
  HeatingSystem?: Maybe<Order_By>;
  IsicCode?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  Location?: Maybe<Order_By>;
  NlaSqm?: Maybe<Order_By>;
  RatingExpiry?: Maybe<Order_By>;
  RatingType?: Maybe<Order_By>;
  RatingValue?: Maybe<Order_By>;
  SiteId?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
  WeatherId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_Buildings_Min_Fields = {
  __typename?: 'dw_Buildings_min_fields';
  BuildingId?: Maybe<Scalars['uuid']>;
  CoolingSystem?: Maybe<Scalars['Int']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  FunctionalUse?: Maybe<Scalars['Int']>;
  GfaSqm?: Maybe<Scalars['numeric']>;
  HeatingSystem?: Maybe<Scalars['Int']>;
  IsicCode?: Maybe<Scalars['String']>;
  Label?: Maybe<Scalars['String']>;
  Location?: Maybe<Scalars['String']>;
  NlaSqm?: Maybe<Scalars['numeric']>;
  RatingExpiry?: Maybe<Scalars['timestamp']>;
  RatingType?: Maybe<Scalars['Int']>;
  RatingValue?: Maybe<Scalars['Int']>;
  SiteId?: Maybe<Scalars['uuid']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  WeatherId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "dw.Buildings" */
export type Dw_Buildings_Min_Order_By = {
  BuildingId?: Maybe<Order_By>;
  CoolingSystem?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  FunctionalUse?: Maybe<Order_By>;
  GfaSqm?: Maybe<Order_By>;
  HeatingSystem?: Maybe<Order_By>;
  IsicCode?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  Location?: Maybe<Order_By>;
  NlaSqm?: Maybe<Order_By>;
  RatingExpiry?: Maybe<Order_By>;
  RatingType?: Maybe<Order_By>;
  RatingValue?: Maybe<Order_By>;
  SiteId?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
  WeatherId?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.Buildings" */
export type Dw_Buildings_Mutation_Response = {
  __typename?: 'dw_Buildings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_Buildings>;
};

/** input type for inserting object relation for remote table "dw.Buildings" */
export type Dw_Buildings_Obj_Rel_Insert_Input = {
  data: Dw_Buildings_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_Buildings_On_Conflict>;
};

/** on conflict condition type for table "dw.Buildings" */
export type Dw_Buildings_On_Conflict = {
  constraint: Dw_Buildings_Constraint;
  update_columns?: Array<Dw_Buildings_Update_Column>;
  where?: Maybe<Dw_Buildings_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.Buildings". */
export type Dw_Buildings_Order_By = {
  BuildingDocuments_aggregate?: Maybe<Dw_BuildingDocuments_Aggregate_Order_By>;
  BuildingId?: Maybe<Order_By>;
  Channels_aggregate?: Maybe<Dw_Channels_Aggregate_Order_By>;
  CoolingSystem?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  Dashboards_aggregate?: Maybe<Dw_Dashboards_Aggregate_Order_By>;
  Feeds_aggregate?: Maybe<Dw_Feeds_Aggregate_Order_By>;
  FunctionalUse?: Maybe<Order_By>;
  GfaSqm?: Maybe<Order_By>;
  HeatingSystem?: Maybe<Order_By>;
  IsicCode?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  Loads_aggregate?: Maybe<Dw_Loads_Aggregate_Order_By>;
  Location?: Maybe<Order_By>;
  MLNotificationGroups_aggregate?: Maybe<Dw_MlNotificationGroup_Aggregate_Order_By>;
  NlaSqm?: Maybe<Order_By>;
  Notifications_aggregate?: Maybe<Dw_Notifications_Aggregate_Order_By>;
  Projects_aggregate?: Maybe<Dw_Projects_Aggregate_Order_By>;
  RatingExpiry?: Maybe<Order_By>;
  RatingType?: Maybe<Order_By>;
  RatingValue?: Maybe<Order_By>;
  Site?: Maybe<Dw_Sites_Order_By>;
  SiteId?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
  WeatherId?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_Buildings */
export type Dw_Buildings_Pk_Columns_Input = {
  BuildingId: Scalars['uuid'];
};

/** select columns of table "dw.Buildings" */
export enum Dw_Buildings_Select_Column {
  /** column name */
  BuildingId = 'BuildingId',
  /** column name */
  CoolingSystem = 'CoolingSystem',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  FunctionalUse = 'FunctionalUse',
  /** column name */
  GfaSqm = 'GfaSqm',
  /** column name */
  HeatingSystem = 'HeatingSystem',
  /** column name */
  IsicCode = 'IsicCode',
  /** column name */
  Label = 'Label',
  /** column name */
  Location = 'Location',
  /** column name */
  NlaSqm = 'NlaSqm',
  /** column name */
  RatingExpiry = 'RatingExpiry',
  /** column name */
  RatingType = 'RatingType',
  /** column name */
  RatingValue = 'RatingValue',
  /** column name */
  SiteId = 'SiteId',
  /** column name */
  Status = 'Status',
  /** column name */
  UpdatedOn = 'UpdatedOn',
  /** column name */
  WeatherId = 'WeatherId'
}

/** input type for updating data in table "dw.Buildings" */
export type Dw_Buildings_Set_Input = {
  BuildingId?: Maybe<Scalars['uuid']>;
  CoolingSystem?: Maybe<Scalars['Int']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  FunctionalUse?: Maybe<Scalars['Int']>;
  GfaSqm?: Maybe<Scalars['numeric']>;
  HeatingSystem?: Maybe<Scalars['Int']>;
  IsicCode?: Maybe<Scalars['String']>;
  Label?: Maybe<Scalars['String']>;
  Location?: Maybe<Scalars['String']>;
  NlaSqm?: Maybe<Scalars['numeric']>;
  RatingExpiry?: Maybe<Scalars['timestamp']>;
  RatingType?: Maybe<Scalars['Int']>;
  RatingValue?: Maybe<Scalars['Int']>;
  SiteId?: Maybe<Scalars['uuid']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  WeatherId?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Dw_Buildings_Stddev_Fields = {
  __typename?: 'dw_Buildings_stddev_fields';
  CoolingSystem?: Maybe<Scalars['Float']>;
  FunctionalUse?: Maybe<Scalars['Float']>;
  GfaSqm?: Maybe<Scalars['Float']>;
  HeatingSystem?: Maybe<Scalars['Float']>;
  NlaSqm?: Maybe<Scalars['Float']>;
  RatingType?: Maybe<Scalars['Float']>;
  RatingValue?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.Buildings" */
export type Dw_Buildings_Stddev_Order_By = {
  CoolingSystem?: Maybe<Order_By>;
  FunctionalUse?: Maybe<Order_By>;
  GfaSqm?: Maybe<Order_By>;
  HeatingSystem?: Maybe<Order_By>;
  NlaSqm?: Maybe<Order_By>;
  RatingType?: Maybe<Order_By>;
  RatingValue?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_Buildings_Stddev_Pop_Fields = {
  __typename?: 'dw_Buildings_stddev_pop_fields';
  CoolingSystem?: Maybe<Scalars['Float']>;
  FunctionalUse?: Maybe<Scalars['Float']>;
  GfaSqm?: Maybe<Scalars['Float']>;
  HeatingSystem?: Maybe<Scalars['Float']>;
  NlaSqm?: Maybe<Scalars['Float']>;
  RatingType?: Maybe<Scalars['Float']>;
  RatingValue?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.Buildings" */
export type Dw_Buildings_Stddev_Pop_Order_By = {
  CoolingSystem?: Maybe<Order_By>;
  FunctionalUse?: Maybe<Order_By>;
  GfaSqm?: Maybe<Order_By>;
  HeatingSystem?: Maybe<Order_By>;
  NlaSqm?: Maybe<Order_By>;
  RatingType?: Maybe<Order_By>;
  RatingValue?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_Buildings_Stddev_Samp_Fields = {
  __typename?: 'dw_Buildings_stddev_samp_fields';
  CoolingSystem?: Maybe<Scalars['Float']>;
  FunctionalUse?: Maybe<Scalars['Float']>;
  GfaSqm?: Maybe<Scalars['Float']>;
  HeatingSystem?: Maybe<Scalars['Float']>;
  NlaSqm?: Maybe<Scalars['Float']>;
  RatingType?: Maybe<Scalars['Float']>;
  RatingValue?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.Buildings" */
export type Dw_Buildings_Stddev_Samp_Order_By = {
  CoolingSystem?: Maybe<Order_By>;
  FunctionalUse?: Maybe<Order_By>;
  GfaSqm?: Maybe<Order_By>;
  HeatingSystem?: Maybe<Order_By>;
  NlaSqm?: Maybe<Order_By>;
  RatingType?: Maybe<Order_By>;
  RatingValue?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_Buildings_Sum_Fields = {
  __typename?: 'dw_Buildings_sum_fields';
  CoolingSystem?: Maybe<Scalars['Int']>;
  FunctionalUse?: Maybe<Scalars['Int']>;
  GfaSqm?: Maybe<Scalars['numeric']>;
  HeatingSystem?: Maybe<Scalars['Int']>;
  NlaSqm?: Maybe<Scalars['numeric']>;
  RatingType?: Maybe<Scalars['Int']>;
  RatingValue?: Maybe<Scalars['Int']>;
  Status?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "dw.Buildings" */
export type Dw_Buildings_Sum_Order_By = {
  CoolingSystem?: Maybe<Order_By>;
  FunctionalUse?: Maybe<Order_By>;
  GfaSqm?: Maybe<Order_By>;
  HeatingSystem?: Maybe<Order_By>;
  NlaSqm?: Maybe<Order_By>;
  RatingType?: Maybe<Order_By>;
  RatingValue?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** update columns of table "dw.Buildings" */
export enum Dw_Buildings_Update_Column {
  /** column name */
  BuildingId = 'BuildingId',
  /** column name */
  CoolingSystem = 'CoolingSystem',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  FunctionalUse = 'FunctionalUse',
  /** column name */
  GfaSqm = 'GfaSqm',
  /** column name */
  HeatingSystem = 'HeatingSystem',
  /** column name */
  IsicCode = 'IsicCode',
  /** column name */
  Label = 'Label',
  /** column name */
  Location = 'Location',
  /** column name */
  NlaSqm = 'NlaSqm',
  /** column name */
  RatingExpiry = 'RatingExpiry',
  /** column name */
  RatingType = 'RatingType',
  /** column name */
  RatingValue = 'RatingValue',
  /** column name */
  SiteId = 'SiteId',
  /** column name */
  Status = 'Status',
  /** column name */
  UpdatedOn = 'UpdatedOn',
  /** column name */
  WeatherId = 'WeatherId'
}

/** aggregate var_pop on columns */
export type Dw_Buildings_Var_Pop_Fields = {
  __typename?: 'dw_Buildings_var_pop_fields';
  CoolingSystem?: Maybe<Scalars['Float']>;
  FunctionalUse?: Maybe<Scalars['Float']>;
  GfaSqm?: Maybe<Scalars['Float']>;
  HeatingSystem?: Maybe<Scalars['Float']>;
  NlaSqm?: Maybe<Scalars['Float']>;
  RatingType?: Maybe<Scalars['Float']>;
  RatingValue?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.Buildings" */
export type Dw_Buildings_Var_Pop_Order_By = {
  CoolingSystem?: Maybe<Order_By>;
  FunctionalUse?: Maybe<Order_By>;
  GfaSqm?: Maybe<Order_By>;
  HeatingSystem?: Maybe<Order_By>;
  NlaSqm?: Maybe<Order_By>;
  RatingType?: Maybe<Order_By>;
  RatingValue?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_Buildings_Var_Samp_Fields = {
  __typename?: 'dw_Buildings_var_samp_fields';
  CoolingSystem?: Maybe<Scalars['Float']>;
  FunctionalUse?: Maybe<Scalars['Float']>;
  GfaSqm?: Maybe<Scalars['Float']>;
  HeatingSystem?: Maybe<Scalars['Float']>;
  NlaSqm?: Maybe<Scalars['Float']>;
  RatingType?: Maybe<Scalars['Float']>;
  RatingValue?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.Buildings" */
export type Dw_Buildings_Var_Samp_Order_By = {
  CoolingSystem?: Maybe<Order_By>;
  FunctionalUse?: Maybe<Order_By>;
  GfaSqm?: Maybe<Order_By>;
  HeatingSystem?: Maybe<Order_By>;
  NlaSqm?: Maybe<Order_By>;
  RatingType?: Maybe<Order_By>;
  RatingValue?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_Buildings_Variance_Fields = {
  __typename?: 'dw_Buildings_variance_fields';
  CoolingSystem?: Maybe<Scalars['Float']>;
  FunctionalUse?: Maybe<Scalars['Float']>;
  GfaSqm?: Maybe<Scalars['Float']>;
  HeatingSystem?: Maybe<Scalars['Float']>;
  NlaSqm?: Maybe<Scalars['Float']>;
  RatingType?: Maybe<Scalars['Float']>;
  RatingValue?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.Buildings" */
export type Dw_Buildings_Variance_Order_By = {
  CoolingSystem?: Maybe<Order_By>;
  FunctionalUse?: Maybe<Order_By>;
  GfaSqm?: Maybe<Order_By>;
  HeatingSystem?: Maybe<Order_By>;
  NlaSqm?: Maybe<Order_By>;
  RatingType?: Maybe<Order_By>;
  RatingValue?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** columns and relationships of "dw.Channels" */
export type Dw_Channels = {
  __typename?: 'dw_Channels';
  /** An array relationship */
  Baselines: Array<Dw_Baseline>;
  /** An aggregate relationship */
  Baselines_aggregate: Dw_Baseline_Aggregate;
  /** An object relationship */
  Building?: Maybe<Dw_Buildings>;
  BuildingId?: Maybe<Scalars['uuid']>;
  ChannelId: Scalars['uuid'];
  ChannelKey?: Maybe<Scalars['String']>;
  /** An array relationship */
  ChannelsJobs: Array<Dw_ChannelsJobs>;
  /** An aggregate relationship */
  ChannelsJobs_aggregate: Dw_ChannelsJobs_Aggregate;
  /** An object relationship */
  ChannelsMapping: Dw_ChannelsMappings;
  /** An array relationship */
  ChannelsMappings: Array<Dw_ChannelsMappings>;
  /** An aggregate relationship */
  ChannelsMappings_aggregate: Dw_ChannelsMappings_Aggregate;
  /** An array relationship */
  ChannelsMetadata: Array<Dw_ChannelsMetadata>;
  /** An object relationship */
  ChannelsMetadataSingle?: Maybe<Dw_ChannelsMetadata>;
  /** An aggregate relationship */
  ChannelsMetadata_aggregate: Dw_ChannelsMetadata_Aggregate;
  /** An array relationship */
  ChannelsRules: Array<Dw_ChannelsRules>;
  /** An aggregate relationship */
  ChannelsRules_aggregate: Dw_ChannelsRules_Aggregate;
  /** An array relationship */
  Consumptions: Array<Dw_Consumption>;
  /** An aggregate relationship */
  Consumptions_aggregate: Dw_Consumption_Aggregate;
  CreatedOn: Scalars['timestamp'];
  EnableAlerts: Scalars['Boolean'];
  /** An object relationship */
  Feed?: Maybe<Dw_Feeds>;
  FeedId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  FeedsConfigBacNets: Array<Dw_FeedsConfigBacNet>;
  /** An aggregate relationship */
  FeedsConfigBacNets_aggregate: Dw_FeedsConfigBacNet_Aggregate;
  /** An object relationship */
  FeedsConfigChannel: Dw_FeedsConfigChannels;
  /** An array relationship */
  FeedsConfigChannels: Array<Dw_FeedsConfigChannels>;
  /** An aggregate relationship */
  FeedsConfigChannels_aggregate: Dw_FeedsConfigChannels_Aggregate;
  /** An array relationship */
  FeedsConfigModbuses: Array<Dw_FeedsConfigModbus>;
  /** An aggregate relationship */
  FeedsConfigModbuses_aggregate: Dw_FeedsConfigModbus_Aggregate;
  Formula?: Maybe<Scalars['String']>;
  IntervalFrequency?: Maybe<Scalars['String']>;
  IntervalFrequencyValue: Scalars['bigint'];
  IntervalMins: Scalars['Int'];
  Label: Scalars['String'];
  Level: Scalars['smallint'];
  /** An object relationship */
  Load: Dw_Loads;
  LoadId: Scalars['uuid'];
  /** An array relationship */
  MLNotificationForecasts: Array<Dw_MlNotificationForecasts>;
  /** An aggregate relationship */
  MLNotificationForecasts_aggregate: Dw_MlNotificationForecasts_Aggregate;
  /** An array relationship */
  MLNotifications: Array<Dw_MlNotifications>;
  /** An array relationship */
  MLNotificationsCorrelations: Array<Dw_MlNotificationsCorrelation>;
  /** An aggregate relationship */
  MLNotificationsCorrelations_aggregate: Dw_MlNotificationsCorrelation_Aggregate;
  /** An aggregate relationship */
  MLNotifications_aggregate: Dw_MlNotifications_Aggregate;
  MeterType?: Maybe<Scalars['String']>;
  NatureType: Scalars['smallint'];
  /** An array relationship */
  Notifications: Array<Dw_Notifications>;
  /** An aggregate relationship */
  Notifications_aggregate: Dw_Notifications_Aggregate;
  Parameter?: Maybe<Scalars['String']>;
  Reference?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['timestamp']>;
  Status: Scalars['smallint'];
  SummationType: Scalars['smallint'];
  TimeZone: Scalars['String'];
  Type: Scalars['smallint'];
  UnitOfMeasure?: Maybe<Scalars['String']>;
  UpdatedOn: Scalars['timestamp'];
  ValueType?: Maybe<Scalars['String']>;
  ValueTypeEnum?: Maybe<Scalars['smallint']>;
  /** An array relationship */
  channelsRulesBySourcechannelid: Array<Dw_ChannelsRules>;
  /** An aggregate relationship */
  channelsRulesBySourcechannelid_aggregate: Dw_ChannelsRules_Aggregate;
};


/** columns and relationships of "dw.Channels" */
export type Dw_ChannelsBaselinesArgs = {
  distinct_on?: Maybe<Array<Dw_Baseline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Baseline_Order_By>>;
  where?: Maybe<Dw_Baseline_Bool_Exp>;
};


/** columns and relationships of "dw.Channels" */
export type Dw_ChannelsBaselines_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Baseline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Baseline_Order_By>>;
  where?: Maybe<Dw_Baseline_Bool_Exp>;
};


/** columns and relationships of "dw.Channels" */
export type Dw_ChannelsChannelsJobsArgs = {
  distinct_on?: Maybe<Array<Dw_ChannelsJobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ChannelsJobs_Order_By>>;
  where?: Maybe<Dw_ChannelsJobs_Bool_Exp>;
};


/** columns and relationships of "dw.Channels" */
export type Dw_ChannelsChannelsJobs_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_ChannelsJobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ChannelsJobs_Order_By>>;
  where?: Maybe<Dw_ChannelsJobs_Bool_Exp>;
};


/** columns and relationships of "dw.Channels" */
export type Dw_ChannelsChannelsMappingsArgs = {
  distinct_on?: Maybe<Array<Dw_ChannelsMappings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ChannelsMappings_Order_By>>;
  where?: Maybe<Dw_ChannelsMappings_Bool_Exp>;
};


/** columns and relationships of "dw.Channels" */
export type Dw_ChannelsChannelsMappings_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_ChannelsMappings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ChannelsMappings_Order_By>>;
  where?: Maybe<Dw_ChannelsMappings_Bool_Exp>;
};


/** columns and relationships of "dw.Channels" */
export type Dw_ChannelsChannelsMetadataArgs = {
  distinct_on?: Maybe<Array<Dw_ChannelsMetadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ChannelsMetadata_Order_By>>;
  where?: Maybe<Dw_ChannelsMetadata_Bool_Exp>;
};


/** columns and relationships of "dw.Channels" */
export type Dw_ChannelsChannelsMetadata_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_ChannelsMetadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ChannelsMetadata_Order_By>>;
  where?: Maybe<Dw_ChannelsMetadata_Bool_Exp>;
};


/** columns and relationships of "dw.Channels" */
export type Dw_ChannelsChannelsRulesArgs = {
  distinct_on?: Maybe<Array<Dw_ChannelsRules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ChannelsRules_Order_By>>;
  where?: Maybe<Dw_ChannelsRules_Bool_Exp>;
};


/** columns and relationships of "dw.Channels" */
export type Dw_ChannelsChannelsRules_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_ChannelsRules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ChannelsRules_Order_By>>;
  where?: Maybe<Dw_ChannelsRules_Bool_Exp>;
};


/** columns and relationships of "dw.Channels" */
export type Dw_ChannelsConsumptionsArgs = {
  distinct_on?: Maybe<Array<Dw_Consumption_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Consumption_Order_By>>;
  where?: Maybe<Dw_Consumption_Bool_Exp>;
};


/** columns and relationships of "dw.Channels" */
export type Dw_ChannelsConsumptions_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Consumption_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Consumption_Order_By>>;
  where?: Maybe<Dw_Consumption_Bool_Exp>;
};


/** columns and relationships of "dw.Channels" */
export type Dw_ChannelsFeedsConfigBacNetsArgs = {
  distinct_on?: Maybe<Array<Dw_FeedsConfigBacNet_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_FeedsConfigBacNet_Order_By>>;
  where?: Maybe<Dw_FeedsConfigBacNet_Bool_Exp>;
};


/** columns and relationships of "dw.Channels" */
export type Dw_ChannelsFeedsConfigBacNets_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_FeedsConfigBacNet_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_FeedsConfigBacNet_Order_By>>;
  where?: Maybe<Dw_FeedsConfigBacNet_Bool_Exp>;
};


/** columns and relationships of "dw.Channels" */
export type Dw_ChannelsFeedsConfigChannelsArgs = {
  distinct_on?: Maybe<Array<Dw_FeedsConfigChannels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_FeedsConfigChannels_Order_By>>;
  where?: Maybe<Dw_FeedsConfigChannels_Bool_Exp>;
};


/** columns and relationships of "dw.Channels" */
export type Dw_ChannelsFeedsConfigChannels_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_FeedsConfigChannels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_FeedsConfigChannels_Order_By>>;
  where?: Maybe<Dw_FeedsConfigChannels_Bool_Exp>;
};


/** columns and relationships of "dw.Channels" */
export type Dw_ChannelsFeedsConfigModbusesArgs = {
  distinct_on?: Maybe<Array<Dw_FeedsConfigModbus_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_FeedsConfigModbus_Order_By>>;
  where?: Maybe<Dw_FeedsConfigModbus_Bool_Exp>;
};


/** columns and relationships of "dw.Channels" */
export type Dw_ChannelsFeedsConfigModbuses_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_FeedsConfigModbus_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_FeedsConfigModbus_Order_By>>;
  where?: Maybe<Dw_FeedsConfigModbus_Bool_Exp>;
};


/** columns and relationships of "dw.Channels" */
export type Dw_ChannelsMlNotificationForecastsArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotificationForecasts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotificationForecasts_Order_By>>;
  where?: Maybe<Dw_MlNotificationForecasts_Bool_Exp>;
};


/** columns and relationships of "dw.Channels" */
export type Dw_ChannelsMlNotificationForecasts_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotificationForecasts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotificationForecasts_Order_By>>;
  where?: Maybe<Dw_MlNotificationForecasts_Bool_Exp>;
};


/** columns and relationships of "dw.Channels" */
export type Dw_ChannelsMlNotificationsArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotifications_Order_By>>;
  where?: Maybe<Dw_MlNotifications_Bool_Exp>;
};


/** columns and relationships of "dw.Channels" */
export type Dw_ChannelsMlNotificationsCorrelationsArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotificationsCorrelation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotificationsCorrelation_Order_By>>;
  where?: Maybe<Dw_MlNotificationsCorrelation_Bool_Exp>;
};


/** columns and relationships of "dw.Channels" */
export type Dw_ChannelsMlNotificationsCorrelations_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotificationsCorrelation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotificationsCorrelation_Order_By>>;
  where?: Maybe<Dw_MlNotificationsCorrelation_Bool_Exp>;
};


/** columns and relationships of "dw.Channels" */
export type Dw_ChannelsMlNotifications_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotifications_Order_By>>;
  where?: Maybe<Dw_MlNotifications_Bool_Exp>;
};


/** columns and relationships of "dw.Channels" */
export type Dw_ChannelsNotificationsArgs = {
  distinct_on?: Maybe<Array<Dw_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Notifications_Order_By>>;
  where?: Maybe<Dw_Notifications_Bool_Exp>;
};


/** columns and relationships of "dw.Channels" */
export type Dw_ChannelsNotifications_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Notifications_Order_By>>;
  where?: Maybe<Dw_Notifications_Bool_Exp>;
};


/** columns and relationships of "dw.Channels" */
export type Dw_ChannelsChannelsRulesBySourcechannelidArgs = {
  distinct_on?: Maybe<Array<Dw_ChannelsRules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ChannelsRules_Order_By>>;
  where?: Maybe<Dw_ChannelsRules_Bool_Exp>;
};


/** columns and relationships of "dw.Channels" */
export type Dw_ChannelsChannelsRulesBySourcechannelid_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_ChannelsRules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ChannelsRules_Order_By>>;
  where?: Maybe<Dw_ChannelsRules_Bool_Exp>;
};

/** columns and relationships of "dw.ChannelsJobs" */
export type Dw_ChannelsJobs = {
  __typename?: 'dw_ChannelsJobs';
  /** An object relationship */
  Channel: Dw_Channels;
  ChannelId: Scalars['uuid'];
  Formula?: Maybe<Scalars['String']>;
  From: Scalars['timestamp'];
  JobId: Scalars['uuid'];
  LastActivity: Scalars['timestamp'];
  Logs?: Maybe<Scalars['json']>;
  StartedOn: Scalars['timestamp'];
  Status: Scalars['smallint'];
  To: Scalars['timestamp'];
};


/** columns and relationships of "dw.ChannelsJobs" */
export type Dw_ChannelsJobsLogsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "dw.ChannelsJobs" */
export type Dw_ChannelsJobs_Aggregate = {
  __typename?: 'dw_ChannelsJobs_aggregate';
  aggregate?: Maybe<Dw_ChannelsJobs_Aggregate_Fields>;
  nodes: Array<Dw_ChannelsJobs>;
};

/** aggregate fields of "dw.ChannelsJobs" */
export type Dw_ChannelsJobs_Aggregate_Fields = {
  __typename?: 'dw_ChannelsJobs_aggregate_fields';
  avg?: Maybe<Dw_ChannelsJobs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_ChannelsJobs_Max_Fields>;
  min?: Maybe<Dw_ChannelsJobs_Min_Fields>;
  stddev?: Maybe<Dw_ChannelsJobs_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_ChannelsJobs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_ChannelsJobs_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_ChannelsJobs_Sum_Fields>;
  var_pop?: Maybe<Dw_ChannelsJobs_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_ChannelsJobs_Var_Samp_Fields>;
  variance?: Maybe<Dw_ChannelsJobs_Variance_Fields>;
};


/** aggregate fields of "dw.ChannelsJobs" */
export type Dw_ChannelsJobs_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_ChannelsJobs_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.ChannelsJobs" */
export type Dw_ChannelsJobs_Aggregate_Order_By = {
  avg?: Maybe<Dw_ChannelsJobs_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_ChannelsJobs_Max_Order_By>;
  min?: Maybe<Dw_ChannelsJobs_Min_Order_By>;
  stddev?: Maybe<Dw_ChannelsJobs_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_ChannelsJobs_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_ChannelsJobs_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_ChannelsJobs_Sum_Order_By>;
  var_pop?: Maybe<Dw_ChannelsJobs_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_ChannelsJobs_Var_Samp_Order_By>;
  variance?: Maybe<Dw_ChannelsJobs_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.ChannelsJobs" */
export type Dw_ChannelsJobs_Arr_Rel_Insert_Input = {
  data: Array<Dw_ChannelsJobs_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_ChannelsJobs_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_ChannelsJobs_Avg_Fields = {
  __typename?: 'dw_ChannelsJobs_avg_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.ChannelsJobs" */
export type Dw_ChannelsJobs_Avg_Order_By = {
  Status?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.ChannelsJobs". All fields are combined with a logical 'AND'. */
export type Dw_ChannelsJobs_Bool_Exp = {
  Channel?: Maybe<Dw_Channels_Bool_Exp>;
  ChannelId?: Maybe<Uuid_Comparison_Exp>;
  Formula?: Maybe<String_Comparison_Exp>;
  From?: Maybe<Timestamp_Comparison_Exp>;
  JobId?: Maybe<Uuid_Comparison_Exp>;
  LastActivity?: Maybe<Timestamp_Comparison_Exp>;
  Logs?: Maybe<Json_Comparison_Exp>;
  StartedOn?: Maybe<Timestamp_Comparison_Exp>;
  Status?: Maybe<Smallint_Comparison_Exp>;
  To?: Maybe<Timestamp_Comparison_Exp>;
  _and?: Maybe<Array<Dw_ChannelsJobs_Bool_Exp>>;
  _not?: Maybe<Dw_ChannelsJobs_Bool_Exp>;
  _or?: Maybe<Array<Dw_ChannelsJobs_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.ChannelsJobs" */
export enum Dw_ChannelsJobs_Constraint {
  /** unique or primary key constraint */
  ChannelsjobsJobidUindex = 'channelsjobs_jobid_uindex',
  /** unique or primary key constraint */
  ChannelsjobsPk = 'channelsjobs_pk'
}

/** input type for incrementing numeric columns in table "dw.ChannelsJobs" */
export type Dw_ChannelsJobs_Inc_Input = {
  Status?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.ChannelsJobs" */
export type Dw_ChannelsJobs_Insert_Input = {
  Channel?: Maybe<Dw_Channels_Obj_Rel_Insert_Input>;
  ChannelId?: Maybe<Scalars['uuid']>;
  Formula?: Maybe<Scalars['String']>;
  From?: Maybe<Scalars['timestamp']>;
  JobId?: Maybe<Scalars['uuid']>;
  LastActivity?: Maybe<Scalars['timestamp']>;
  Logs?: Maybe<Scalars['json']>;
  StartedOn?: Maybe<Scalars['timestamp']>;
  Status?: Maybe<Scalars['smallint']>;
  To?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Dw_ChannelsJobs_Max_Fields = {
  __typename?: 'dw_ChannelsJobs_max_fields';
  ChannelId?: Maybe<Scalars['uuid']>;
  Formula?: Maybe<Scalars['String']>;
  From?: Maybe<Scalars['timestamp']>;
  JobId?: Maybe<Scalars['uuid']>;
  LastActivity?: Maybe<Scalars['timestamp']>;
  StartedOn?: Maybe<Scalars['timestamp']>;
  Status?: Maybe<Scalars['smallint']>;
  To?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "dw.ChannelsJobs" */
export type Dw_ChannelsJobs_Max_Order_By = {
  ChannelId?: Maybe<Order_By>;
  Formula?: Maybe<Order_By>;
  From?: Maybe<Order_By>;
  JobId?: Maybe<Order_By>;
  LastActivity?: Maybe<Order_By>;
  StartedOn?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  To?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_ChannelsJobs_Min_Fields = {
  __typename?: 'dw_ChannelsJobs_min_fields';
  ChannelId?: Maybe<Scalars['uuid']>;
  Formula?: Maybe<Scalars['String']>;
  From?: Maybe<Scalars['timestamp']>;
  JobId?: Maybe<Scalars['uuid']>;
  LastActivity?: Maybe<Scalars['timestamp']>;
  StartedOn?: Maybe<Scalars['timestamp']>;
  Status?: Maybe<Scalars['smallint']>;
  To?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "dw.ChannelsJobs" */
export type Dw_ChannelsJobs_Min_Order_By = {
  ChannelId?: Maybe<Order_By>;
  Formula?: Maybe<Order_By>;
  From?: Maybe<Order_By>;
  JobId?: Maybe<Order_By>;
  LastActivity?: Maybe<Order_By>;
  StartedOn?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  To?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.ChannelsJobs" */
export type Dw_ChannelsJobs_Mutation_Response = {
  __typename?: 'dw_ChannelsJobs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_ChannelsJobs>;
};

/** on conflict condition type for table "dw.ChannelsJobs" */
export type Dw_ChannelsJobs_On_Conflict = {
  constraint: Dw_ChannelsJobs_Constraint;
  update_columns?: Array<Dw_ChannelsJobs_Update_Column>;
  where?: Maybe<Dw_ChannelsJobs_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.ChannelsJobs". */
export type Dw_ChannelsJobs_Order_By = {
  Channel?: Maybe<Dw_Channels_Order_By>;
  ChannelId?: Maybe<Order_By>;
  Formula?: Maybe<Order_By>;
  From?: Maybe<Order_By>;
  JobId?: Maybe<Order_By>;
  LastActivity?: Maybe<Order_By>;
  Logs?: Maybe<Order_By>;
  StartedOn?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  To?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_ChannelsJobs */
export type Dw_ChannelsJobs_Pk_Columns_Input = {
  JobId: Scalars['uuid'];
};

/** select columns of table "dw.ChannelsJobs" */
export enum Dw_ChannelsJobs_Select_Column {
  /** column name */
  ChannelId = 'ChannelId',
  /** column name */
  Formula = 'Formula',
  /** column name */
  From = 'From',
  /** column name */
  JobId = 'JobId',
  /** column name */
  LastActivity = 'LastActivity',
  /** column name */
  Logs = 'Logs',
  /** column name */
  StartedOn = 'StartedOn',
  /** column name */
  Status = 'Status',
  /** column name */
  To = 'To'
}

/** input type for updating data in table "dw.ChannelsJobs" */
export type Dw_ChannelsJobs_Set_Input = {
  ChannelId?: Maybe<Scalars['uuid']>;
  Formula?: Maybe<Scalars['String']>;
  From?: Maybe<Scalars['timestamp']>;
  JobId?: Maybe<Scalars['uuid']>;
  LastActivity?: Maybe<Scalars['timestamp']>;
  Logs?: Maybe<Scalars['json']>;
  StartedOn?: Maybe<Scalars['timestamp']>;
  Status?: Maybe<Scalars['smallint']>;
  To?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Dw_ChannelsJobs_Stddev_Fields = {
  __typename?: 'dw_ChannelsJobs_stddev_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.ChannelsJobs" */
export type Dw_ChannelsJobs_Stddev_Order_By = {
  Status?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_ChannelsJobs_Stddev_Pop_Fields = {
  __typename?: 'dw_ChannelsJobs_stddev_pop_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.ChannelsJobs" */
export type Dw_ChannelsJobs_Stddev_Pop_Order_By = {
  Status?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_ChannelsJobs_Stddev_Samp_Fields = {
  __typename?: 'dw_ChannelsJobs_stddev_samp_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.ChannelsJobs" */
export type Dw_ChannelsJobs_Stddev_Samp_Order_By = {
  Status?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_ChannelsJobs_Sum_Fields = {
  __typename?: 'dw_ChannelsJobs_sum_fields';
  Status?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "dw.ChannelsJobs" */
export type Dw_ChannelsJobs_Sum_Order_By = {
  Status?: Maybe<Order_By>;
};

/** update columns of table "dw.ChannelsJobs" */
export enum Dw_ChannelsJobs_Update_Column {
  /** column name */
  ChannelId = 'ChannelId',
  /** column name */
  Formula = 'Formula',
  /** column name */
  From = 'From',
  /** column name */
  JobId = 'JobId',
  /** column name */
  LastActivity = 'LastActivity',
  /** column name */
  Logs = 'Logs',
  /** column name */
  StartedOn = 'StartedOn',
  /** column name */
  Status = 'Status',
  /** column name */
  To = 'To'
}

/** aggregate var_pop on columns */
export type Dw_ChannelsJobs_Var_Pop_Fields = {
  __typename?: 'dw_ChannelsJobs_var_pop_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.ChannelsJobs" */
export type Dw_ChannelsJobs_Var_Pop_Order_By = {
  Status?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_ChannelsJobs_Var_Samp_Fields = {
  __typename?: 'dw_ChannelsJobs_var_samp_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.ChannelsJobs" */
export type Dw_ChannelsJobs_Var_Samp_Order_By = {
  Status?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_ChannelsJobs_Variance_Fields = {
  __typename?: 'dw_ChannelsJobs_variance_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.ChannelsJobs" */
export type Dw_ChannelsJobs_Variance_Order_By = {
  Status?: Maybe<Order_By>;
};

/** columns and relationships of "dw.ChannelsMappings" */
export type Dw_ChannelsMappings = {
  __typename?: 'dw_ChannelsMappings';
  /** An object relationship */
  Channel: Dw_Channels;
  ChannelId: Scalars['uuid'];
  ExternalId: Scalars['String'];
  Reference: Scalars['String'];
};

/** aggregated selection of "dw.ChannelsMappings" */
export type Dw_ChannelsMappings_Aggregate = {
  __typename?: 'dw_ChannelsMappings_aggregate';
  aggregate?: Maybe<Dw_ChannelsMappings_Aggregate_Fields>;
  nodes: Array<Dw_ChannelsMappings>;
};

/** aggregate fields of "dw.ChannelsMappings" */
export type Dw_ChannelsMappings_Aggregate_Fields = {
  __typename?: 'dw_ChannelsMappings_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Dw_ChannelsMappings_Max_Fields>;
  min?: Maybe<Dw_ChannelsMappings_Min_Fields>;
};


/** aggregate fields of "dw.ChannelsMappings" */
export type Dw_ChannelsMappings_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_ChannelsMappings_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.ChannelsMappings" */
export type Dw_ChannelsMappings_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_ChannelsMappings_Max_Order_By>;
  min?: Maybe<Dw_ChannelsMappings_Min_Order_By>;
};

/** input type for inserting array relation for remote table "dw.ChannelsMappings" */
export type Dw_ChannelsMappings_Arr_Rel_Insert_Input = {
  data: Array<Dw_ChannelsMappings_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_ChannelsMappings_On_Conflict>;
};

/** Boolean expression to filter rows from the table "dw.ChannelsMappings". All fields are combined with a logical 'AND'. */
export type Dw_ChannelsMappings_Bool_Exp = {
  Channel?: Maybe<Dw_Channels_Bool_Exp>;
  ChannelId?: Maybe<Uuid_Comparison_Exp>;
  ExternalId?: Maybe<String_Comparison_Exp>;
  Reference?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Dw_ChannelsMappings_Bool_Exp>>;
  _not?: Maybe<Dw_ChannelsMappings_Bool_Exp>;
  _or?: Maybe<Array<Dw_ChannelsMappings_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.ChannelsMappings" */
export enum Dw_ChannelsMappings_Constraint {
  /** unique or primary key constraint */
  ChannelsmappingsChannelidUindex = 'channelsmappings_channelid_uindex',
  /** unique or primary key constraint */
  ChannelsmappingsPk = 'channelsmappings_pk'
}

/** input type for inserting data into table "dw.ChannelsMappings" */
export type Dw_ChannelsMappings_Insert_Input = {
  Channel?: Maybe<Dw_Channels_Obj_Rel_Insert_Input>;
  ChannelId?: Maybe<Scalars['uuid']>;
  ExternalId?: Maybe<Scalars['String']>;
  Reference?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Dw_ChannelsMappings_Max_Fields = {
  __typename?: 'dw_ChannelsMappings_max_fields';
  ChannelId?: Maybe<Scalars['uuid']>;
  ExternalId?: Maybe<Scalars['String']>;
  Reference?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "dw.ChannelsMappings" */
export type Dw_ChannelsMappings_Max_Order_By = {
  ChannelId?: Maybe<Order_By>;
  ExternalId?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_ChannelsMappings_Min_Fields = {
  __typename?: 'dw_ChannelsMappings_min_fields';
  ChannelId?: Maybe<Scalars['uuid']>;
  ExternalId?: Maybe<Scalars['String']>;
  Reference?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "dw.ChannelsMappings" */
export type Dw_ChannelsMappings_Min_Order_By = {
  ChannelId?: Maybe<Order_By>;
  ExternalId?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.ChannelsMappings" */
export type Dw_ChannelsMappings_Mutation_Response = {
  __typename?: 'dw_ChannelsMappings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_ChannelsMappings>;
};

/** input type for inserting object relation for remote table "dw.ChannelsMappings" */
export type Dw_ChannelsMappings_Obj_Rel_Insert_Input = {
  data: Dw_ChannelsMappings_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_ChannelsMappings_On_Conflict>;
};

/** on conflict condition type for table "dw.ChannelsMappings" */
export type Dw_ChannelsMappings_On_Conflict = {
  constraint: Dw_ChannelsMappings_Constraint;
  update_columns?: Array<Dw_ChannelsMappings_Update_Column>;
  where?: Maybe<Dw_ChannelsMappings_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.ChannelsMappings". */
export type Dw_ChannelsMappings_Order_By = {
  Channel?: Maybe<Dw_Channels_Order_By>;
  ChannelId?: Maybe<Order_By>;
  ExternalId?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_ChannelsMappings */
export type Dw_ChannelsMappings_Pk_Columns_Input = {
  ChannelId: Scalars['uuid'];
};

/** select columns of table "dw.ChannelsMappings" */
export enum Dw_ChannelsMappings_Select_Column {
  /** column name */
  ChannelId = 'ChannelId',
  /** column name */
  ExternalId = 'ExternalId',
  /** column name */
  Reference = 'Reference'
}

/** input type for updating data in table "dw.ChannelsMappings" */
export type Dw_ChannelsMappings_Set_Input = {
  ChannelId?: Maybe<Scalars['uuid']>;
  ExternalId?: Maybe<Scalars['String']>;
  Reference?: Maybe<Scalars['String']>;
};

/** update columns of table "dw.ChannelsMappings" */
export enum Dw_ChannelsMappings_Update_Column {
  /** column name */
  ChannelId = 'ChannelId',
  /** column name */
  ExternalId = 'ExternalId',
  /** column name */
  Reference = 'Reference'
}

/** columns and relationships of "dw.ChannelsMetadata" */
export type Dw_ChannelsMetadata = {
  __typename?: 'dw_ChannelsMetadata';
  BaselineDailyAverage?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  Channel: Dw_Channels;
  ChannelId: Scalars['uuid'];
  ConsumptionStartDate?: Maybe<Scalars['timestamp']>;
  CumulativeToYear?: Maybe<Scalars['numeric']>;
  DataStabilised: Scalars['Boolean'];
  LatestData?: Maybe<Scalars['timestamp']>;
  LatestDataUploaded?: Maybe<Scalars['timestamp']>;
  NegativeValuesPermissible: Scalars['Boolean'];
};

/** aggregated selection of "dw.ChannelsMetadata" */
export type Dw_ChannelsMetadata_Aggregate = {
  __typename?: 'dw_ChannelsMetadata_aggregate';
  aggregate?: Maybe<Dw_ChannelsMetadata_Aggregate_Fields>;
  nodes: Array<Dw_ChannelsMetadata>;
};

/** aggregate fields of "dw.ChannelsMetadata" */
export type Dw_ChannelsMetadata_Aggregate_Fields = {
  __typename?: 'dw_ChannelsMetadata_aggregate_fields';
  avg?: Maybe<Dw_ChannelsMetadata_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_ChannelsMetadata_Max_Fields>;
  min?: Maybe<Dw_ChannelsMetadata_Min_Fields>;
  stddev?: Maybe<Dw_ChannelsMetadata_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_ChannelsMetadata_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_ChannelsMetadata_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_ChannelsMetadata_Sum_Fields>;
  var_pop?: Maybe<Dw_ChannelsMetadata_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_ChannelsMetadata_Var_Samp_Fields>;
  variance?: Maybe<Dw_ChannelsMetadata_Variance_Fields>;
};


/** aggregate fields of "dw.ChannelsMetadata" */
export type Dw_ChannelsMetadata_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_ChannelsMetadata_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.ChannelsMetadata" */
export type Dw_ChannelsMetadata_Aggregate_Order_By = {
  avg?: Maybe<Dw_ChannelsMetadata_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_ChannelsMetadata_Max_Order_By>;
  min?: Maybe<Dw_ChannelsMetadata_Min_Order_By>;
  stddev?: Maybe<Dw_ChannelsMetadata_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_ChannelsMetadata_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_ChannelsMetadata_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_ChannelsMetadata_Sum_Order_By>;
  var_pop?: Maybe<Dw_ChannelsMetadata_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_ChannelsMetadata_Var_Samp_Order_By>;
  variance?: Maybe<Dw_ChannelsMetadata_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.ChannelsMetadata" */
export type Dw_ChannelsMetadata_Arr_Rel_Insert_Input = {
  data: Array<Dw_ChannelsMetadata_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_ChannelsMetadata_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_ChannelsMetadata_Avg_Fields = {
  __typename?: 'dw_ChannelsMetadata_avg_fields';
  BaselineDailyAverage?: Maybe<Scalars['Float']>;
  CumulativeToYear?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.ChannelsMetadata" */
export type Dw_ChannelsMetadata_Avg_Order_By = {
  BaselineDailyAverage?: Maybe<Order_By>;
  CumulativeToYear?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.ChannelsMetadata". All fields are combined with a logical 'AND'. */
export type Dw_ChannelsMetadata_Bool_Exp = {
  BaselineDailyAverage?: Maybe<Numeric_Comparison_Exp>;
  Channel?: Maybe<Dw_Channels_Bool_Exp>;
  ChannelId?: Maybe<Uuid_Comparison_Exp>;
  ConsumptionStartDate?: Maybe<Timestamp_Comparison_Exp>;
  CumulativeToYear?: Maybe<Numeric_Comparison_Exp>;
  DataStabilised?: Maybe<Boolean_Comparison_Exp>;
  LatestData?: Maybe<Timestamp_Comparison_Exp>;
  LatestDataUploaded?: Maybe<Timestamp_Comparison_Exp>;
  NegativeValuesPermissible?: Maybe<Boolean_Comparison_Exp>;
  _and?: Maybe<Array<Dw_ChannelsMetadata_Bool_Exp>>;
  _not?: Maybe<Dw_ChannelsMetadata_Bool_Exp>;
  _or?: Maybe<Array<Dw_ChannelsMetadata_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.ChannelsMetadata" */
export enum Dw_ChannelsMetadata_Constraint {
  /** unique or primary key constraint */
  ChannelsmetadataChannelidUindex = 'channelsmetadata_channelid_uindex',
  /** unique or primary key constraint */
  ChannelsmetadataPk = 'channelsmetadata_pk'
}

/** input type for incrementing numeric columns in table "dw.ChannelsMetadata" */
export type Dw_ChannelsMetadata_Inc_Input = {
  BaselineDailyAverage?: Maybe<Scalars['numeric']>;
  CumulativeToYear?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "dw.ChannelsMetadata" */
export type Dw_ChannelsMetadata_Insert_Input = {
  BaselineDailyAverage?: Maybe<Scalars['numeric']>;
  Channel?: Maybe<Dw_Channels_Obj_Rel_Insert_Input>;
  ChannelId?: Maybe<Scalars['uuid']>;
  ConsumptionStartDate?: Maybe<Scalars['timestamp']>;
  CumulativeToYear?: Maybe<Scalars['numeric']>;
  DataStabilised?: Maybe<Scalars['Boolean']>;
  LatestData?: Maybe<Scalars['timestamp']>;
  LatestDataUploaded?: Maybe<Scalars['timestamp']>;
  NegativeValuesPermissible?: Maybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Dw_ChannelsMetadata_Max_Fields = {
  __typename?: 'dw_ChannelsMetadata_max_fields';
  BaselineDailyAverage?: Maybe<Scalars['numeric']>;
  ChannelId?: Maybe<Scalars['uuid']>;
  ConsumptionStartDate?: Maybe<Scalars['timestamp']>;
  CumulativeToYear?: Maybe<Scalars['numeric']>;
  LatestData?: Maybe<Scalars['timestamp']>;
  LatestDataUploaded?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "dw.ChannelsMetadata" */
export type Dw_ChannelsMetadata_Max_Order_By = {
  BaselineDailyAverage?: Maybe<Order_By>;
  ChannelId?: Maybe<Order_By>;
  ConsumptionStartDate?: Maybe<Order_By>;
  CumulativeToYear?: Maybe<Order_By>;
  LatestData?: Maybe<Order_By>;
  LatestDataUploaded?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_ChannelsMetadata_Min_Fields = {
  __typename?: 'dw_ChannelsMetadata_min_fields';
  BaselineDailyAverage?: Maybe<Scalars['numeric']>;
  ChannelId?: Maybe<Scalars['uuid']>;
  ConsumptionStartDate?: Maybe<Scalars['timestamp']>;
  CumulativeToYear?: Maybe<Scalars['numeric']>;
  LatestData?: Maybe<Scalars['timestamp']>;
  LatestDataUploaded?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "dw.ChannelsMetadata" */
export type Dw_ChannelsMetadata_Min_Order_By = {
  BaselineDailyAverage?: Maybe<Order_By>;
  ChannelId?: Maybe<Order_By>;
  ConsumptionStartDate?: Maybe<Order_By>;
  CumulativeToYear?: Maybe<Order_By>;
  LatestData?: Maybe<Order_By>;
  LatestDataUploaded?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.ChannelsMetadata" */
export type Dw_ChannelsMetadata_Mutation_Response = {
  __typename?: 'dw_ChannelsMetadata_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_ChannelsMetadata>;
};

/** input type for inserting object relation for remote table "dw.ChannelsMetadata" */
export type Dw_ChannelsMetadata_Obj_Rel_Insert_Input = {
  data: Dw_ChannelsMetadata_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_ChannelsMetadata_On_Conflict>;
};

/** on conflict condition type for table "dw.ChannelsMetadata" */
export type Dw_ChannelsMetadata_On_Conflict = {
  constraint: Dw_ChannelsMetadata_Constraint;
  update_columns?: Array<Dw_ChannelsMetadata_Update_Column>;
  where?: Maybe<Dw_ChannelsMetadata_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.ChannelsMetadata". */
export type Dw_ChannelsMetadata_Order_By = {
  BaselineDailyAverage?: Maybe<Order_By>;
  Channel?: Maybe<Dw_Channels_Order_By>;
  ChannelId?: Maybe<Order_By>;
  ConsumptionStartDate?: Maybe<Order_By>;
  CumulativeToYear?: Maybe<Order_By>;
  DataStabilised?: Maybe<Order_By>;
  LatestData?: Maybe<Order_By>;
  LatestDataUploaded?: Maybe<Order_By>;
  NegativeValuesPermissible?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_ChannelsMetadata */
export type Dw_ChannelsMetadata_Pk_Columns_Input = {
  ChannelId: Scalars['uuid'];
};

/** select columns of table "dw.ChannelsMetadata" */
export enum Dw_ChannelsMetadata_Select_Column {
  /** column name */
  BaselineDailyAverage = 'BaselineDailyAverage',
  /** column name */
  ChannelId = 'ChannelId',
  /** column name */
  ConsumptionStartDate = 'ConsumptionStartDate',
  /** column name */
  CumulativeToYear = 'CumulativeToYear',
  /** column name */
  DataStabilised = 'DataStabilised',
  /** column name */
  LatestData = 'LatestData',
  /** column name */
  LatestDataUploaded = 'LatestDataUploaded',
  /** column name */
  NegativeValuesPermissible = 'NegativeValuesPermissible'
}

/** input type for updating data in table "dw.ChannelsMetadata" */
export type Dw_ChannelsMetadata_Set_Input = {
  BaselineDailyAverage?: Maybe<Scalars['numeric']>;
  ChannelId?: Maybe<Scalars['uuid']>;
  ConsumptionStartDate?: Maybe<Scalars['timestamp']>;
  CumulativeToYear?: Maybe<Scalars['numeric']>;
  DataStabilised?: Maybe<Scalars['Boolean']>;
  LatestData?: Maybe<Scalars['timestamp']>;
  LatestDataUploaded?: Maybe<Scalars['timestamp']>;
  NegativeValuesPermissible?: Maybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Dw_ChannelsMetadata_Stddev_Fields = {
  __typename?: 'dw_ChannelsMetadata_stddev_fields';
  BaselineDailyAverage?: Maybe<Scalars['Float']>;
  CumulativeToYear?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.ChannelsMetadata" */
export type Dw_ChannelsMetadata_Stddev_Order_By = {
  BaselineDailyAverage?: Maybe<Order_By>;
  CumulativeToYear?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_ChannelsMetadata_Stddev_Pop_Fields = {
  __typename?: 'dw_ChannelsMetadata_stddev_pop_fields';
  BaselineDailyAverage?: Maybe<Scalars['Float']>;
  CumulativeToYear?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.ChannelsMetadata" */
export type Dw_ChannelsMetadata_Stddev_Pop_Order_By = {
  BaselineDailyAverage?: Maybe<Order_By>;
  CumulativeToYear?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_ChannelsMetadata_Stddev_Samp_Fields = {
  __typename?: 'dw_ChannelsMetadata_stddev_samp_fields';
  BaselineDailyAverage?: Maybe<Scalars['Float']>;
  CumulativeToYear?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.ChannelsMetadata" */
export type Dw_ChannelsMetadata_Stddev_Samp_Order_By = {
  BaselineDailyAverage?: Maybe<Order_By>;
  CumulativeToYear?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_ChannelsMetadata_Sum_Fields = {
  __typename?: 'dw_ChannelsMetadata_sum_fields';
  BaselineDailyAverage?: Maybe<Scalars['numeric']>;
  CumulativeToYear?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "dw.ChannelsMetadata" */
export type Dw_ChannelsMetadata_Sum_Order_By = {
  BaselineDailyAverage?: Maybe<Order_By>;
  CumulativeToYear?: Maybe<Order_By>;
};

/** update columns of table "dw.ChannelsMetadata" */
export enum Dw_ChannelsMetadata_Update_Column {
  /** column name */
  BaselineDailyAverage = 'BaselineDailyAverage',
  /** column name */
  ChannelId = 'ChannelId',
  /** column name */
  ConsumptionStartDate = 'ConsumptionStartDate',
  /** column name */
  CumulativeToYear = 'CumulativeToYear',
  /** column name */
  DataStabilised = 'DataStabilised',
  /** column name */
  LatestData = 'LatestData',
  /** column name */
  LatestDataUploaded = 'LatestDataUploaded',
  /** column name */
  NegativeValuesPermissible = 'NegativeValuesPermissible'
}

/** aggregate var_pop on columns */
export type Dw_ChannelsMetadata_Var_Pop_Fields = {
  __typename?: 'dw_ChannelsMetadata_var_pop_fields';
  BaselineDailyAverage?: Maybe<Scalars['Float']>;
  CumulativeToYear?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.ChannelsMetadata" */
export type Dw_ChannelsMetadata_Var_Pop_Order_By = {
  BaselineDailyAverage?: Maybe<Order_By>;
  CumulativeToYear?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_ChannelsMetadata_Var_Samp_Fields = {
  __typename?: 'dw_ChannelsMetadata_var_samp_fields';
  BaselineDailyAverage?: Maybe<Scalars['Float']>;
  CumulativeToYear?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.ChannelsMetadata" */
export type Dw_ChannelsMetadata_Var_Samp_Order_By = {
  BaselineDailyAverage?: Maybe<Order_By>;
  CumulativeToYear?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_ChannelsMetadata_Variance_Fields = {
  __typename?: 'dw_ChannelsMetadata_variance_fields';
  BaselineDailyAverage?: Maybe<Scalars['Float']>;
  CumulativeToYear?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.ChannelsMetadata" */
export type Dw_ChannelsMetadata_Variance_Order_By = {
  BaselineDailyAverage?: Maybe<Order_By>;
  CumulativeToYear?: Maybe<Order_By>;
};

/** columns and relationships of "dw.ChannelsRules" */
export type Dw_ChannelsRules = {
  __typename?: 'dw_ChannelsRules';
  /** An object relationship */
  Channel: Dw_Channels;
  ChannelId: Scalars['uuid'];
  CreatedOn: Scalars['timestamp'];
  RuleId: Scalars['uuid'];
  SourceChannelId: Scalars['uuid'];
  SourceChannelKey: Scalars['String'];
  Status: Scalars['smallint'];
  UpdatedOn: Scalars['timestamp'];
  /** An object relationship */
  channelByChannelid: Dw_Channels;
};

/** aggregated selection of "dw.ChannelsRules" */
export type Dw_ChannelsRules_Aggregate = {
  __typename?: 'dw_ChannelsRules_aggregate';
  aggregate?: Maybe<Dw_ChannelsRules_Aggregate_Fields>;
  nodes: Array<Dw_ChannelsRules>;
};

/** aggregate fields of "dw.ChannelsRules" */
export type Dw_ChannelsRules_Aggregate_Fields = {
  __typename?: 'dw_ChannelsRules_aggregate_fields';
  avg?: Maybe<Dw_ChannelsRules_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_ChannelsRules_Max_Fields>;
  min?: Maybe<Dw_ChannelsRules_Min_Fields>;
  stddev?: Maybe<Dw_ChannelsRules_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_ChannelsRules_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_ChannelsRules_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_ChannelsRules_Sum_Fields>;
  var_pop?: Maybe<Dw_ChannelsRules_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_ChannelsRules_Var_Samp_Fields>;
  variance?: Maybe<Dw_ChannelsRules_Variance_Fields>;
};


/** aggregate fields of "dw.ChannelsRules" */
export type Dw_ChannelsRules_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_ChannelsRules_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.ChannelsRules" */
export type Dw_ChannelsRules_Aggregate_Order_By = {
  avg?: Maybe<Dw_ChannelsRules_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_ChannelsRules_Max_Order_By>;
  min?: Maybe<Dw_ChannelsRules_Min_Order_By>;
  stddev?: Maybe<Dw_ChannelsRules_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_ChannelsRules_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_ChannelsRules_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_ChannelsRules_Sum_Order_By>;
  var_pop?: Maybe<Dw_ChannelsRules_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_ChannelsRules_Var_Samp_Order_By>;
  variance?: Maybe<Dw_ChannelsRules_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.ChannelsRules" */
export type Dw_ChannelsRules_Arr_Rel_Insert_Input = {
  data: Array<Dw_ChannelsRules_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_ChannelsRules_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_ChannelsRules_Avg_Fields = {
  __typename?: 'dw_ChannelsRules_avg_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.ChannelsRules" */
export type Dw_ChannelsRules_Avg_Order_By = {
  Status?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.ChannelsRules". All fields are combined with a logical 'AND'. */
export type Dw_ChannelsRules_Bool_Exp = {
  Channel?: Maybe<Dw_Channels_Bool_Exp>;
  ChannelId?: Maybe<Uuid_Comparison_Exp>;
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  RuleId?: Maybe<Uuid_Comparison_Exp>;
  SourceChannelId?: Maybe<Uuid_Comparison_Exp>;
  SourceChannelKey?: Maybe<String_Comparison_Exp>;
  Status?: Maybe<Smallint_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  _and?: Maybe<Array<Dw_ChannelsRules_Bool_Exp>>;
  _not?: Maybe<Dw_ChannelsRules_Bool_Exp>;
  _or?: Maybe<Array<Dw_ChannelsRules_Bool_Exp>>;
  channelByChannelid?: Maybe<Dw_Channels_Bool_Exp>;
};

/** unique or primary key constraints on table "dw.ChannelsRules" */
export enum Dw_ChannelsRules_Constraint {
  /** unique or primary key constraint */
  ChannelsrulesPk = 'channelsrules_pk',
  /** unique or primary key constraint */
  ChannelsrulesRuleidUindex = 'channelsrules_ruleid_uindex'
}

/** input type for incrementing numeric columns in table "dw.ChannelsRules" */
export type Dw_ChannelsRules_Inc_Input = {
  Status?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.ChannelsRules" */
export type Dw_ChannelsRules_Insert_Input = {
  Channel?: Maybe<Dw_Channels_Obj_Rel_Insert_Input>;
  ChannelId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  RuleId?: Maybe<Scalars['uuid']>;
  SourceChannelId?: Maybe<Scalars['uuid']>;
  SourceChannelKey?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  channelByChannelid?: Maybe<Dw_Channels_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Dw_ChannelsRules_Max_Fields = {
  __typename?: 'dw_ChannelsRules_max_fields';
  ChannelId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  RuleId?: Maybe<Scalars['uuid']>;
  SourceChannelId?: Maybe<Scalars['uuid']>;
  SourceChannelKey?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "dw.ChannelsRules" */
export type Dw_ChannelsRules_Max_Order_By = {
  ChannelId?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  RuleId?: Maybe<Order_By>;
  SourceChannelId?: Maybe<Order_By>;
  SourceChannelKey?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_ChannelsRules_Min_Fields = {
  __typename?: 'dw_ChannelsRules_min_fields';
  ChannelId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  RuleId?: Maybe<Scalars['uuid']>;
  SourceChannelId?: Maybe<Scalars['uuid']>;
  SourceChannelKey?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "dw.ChannelsRules" */
export type Dw_ChannelsRules_Min_Order_By = {
  ChannelId?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  RuleId?: Maybe<Order_By>;
  SourceChannelId?: Maybe<Order_By>;
  SourceChannelKey?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.ChannelsRules" */
export type Dw_ChannelsRules_Mutation_Response = {
  __typename?: 'dw_ChannelsRules_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_ChannelsRules>;
};

/** on conflict condition type for table "dw.ChannelsRules" */
export type Dw_ChannelsRules_On_Conflict = {
  constraint: Dw_ChannelsRules_Constraint;
  update_columns?: Array<Dw_ChannelsRules_Update_Column>;
  where?: Maybe<Dw_ChannelsRules_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.ChannelsRules". */
export type Dw_ChannelsRules_Order_By = {
  Channel?: Maybe<Dw_Channels_Order_By>;
  ChannelId?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  RuleId?: Maybe<Order_By>;
  SourceChannelId?: Maybe<Order_By>;
  SourceChannelKey?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
  channelByChannelid?: Maybe<Dw_Channels_Order_By>;
};

/** primary key columns input for table: dw_ChannelsRules */
export type Dw_ChannelsRules_Pk_Columns_Input = {
  RuleId: Scalars['uuid'];
};

/** select columns of table "dw.ChannelsRules" */
export enum Dw_ChannelsRules_Select_Column {
  /** column name */
  ChannelId = 'ChannelId',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  RuleId = 'RuleId',
  /** column name */
  SourceChannelId = 'SourceChannelId',
  /** column name */
  SourceChannelKey = 'SourceChannelKey',
  /** column name */
  Status = 'Status',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** input type for updating data in table "dw.ChannelsRules" */
export type Dw_ChannelsRules_Set_Input = {
  ChannelId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  RuleId?: Maybe<Scalars['uuid']>;
  SourceChannelId?: Maybe<Scalars['uuid']>;
  SourceChannelKey?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Dw_ChannelsRules_Stddev_Fields = {
  __typename?: 'dw_ChannelsRules_stddev_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.ChannelsRules" */
export type Dw_ChannelsRules_Stddev_Order_By = {
  Status?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_ChannelsRules_Stddev_Pop_Fields = {
  __typename?: 'dw_ChannelsRules_stddev_pop_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.ChannelsRules" */
export type Dw_ChannelsRules_Stddev_Pop_Order_By = {
  Status?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_ChannelsRules_Stddev_Samp_Fields = {
  __typename?: 'dw_ChannelsRules_stddev_samp_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.ChannelsRules" */
export type Dw_ChannelsRules_Stddev_Samp_Order_By = {
  Status?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_ChannelsRules_Sum_Fields = {
  __typename?: 'dw_ChannelsRules_sum_fields';
  Status?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "dw.ChannelsRules" */
export type Dw_ChannelsRules_Sum_Order_By = {
  Status?: Maybe<Order_By>;
};

/** update columns of table "dw.ChannelsRules" */
export enum Dw_ChannelsRules_Update_Column {
  /** column name */
  ChannelId = 'ChannelId',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  RuleId = 'RuleId',
  /** column name */
  SourceChannelId = 'SourceChannelId',
  /** column name */
  SourceChannelKey = 'SourceChannelKey',
  /** column name */
  Status = 'Status',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** aggregate var_pop on columns */
export type Dw_ChannelsRules_Var_Pop_Fields = {
  __typename?: 'dw_ChannelsRules_var_pop_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.ChannelsRules" */
export type Dw_ChannelsRules_Var_Pop_Order_By = {
  Status?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_ChannelsRules_Var_Samp_Fields = {
  __typename?: 'dw_ChannelsRules_var_samp_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.ChannelsRules" */
export type Dw_ChannelsRules_Var_Samp_Order_By = {
  Status?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_ChannelsRules_Variance_Fields = {
  __typename?: 'dw_ChannelsRules_variance_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.ChannelsRules" */
export type Dw_ChannelsRules_Variance_Order_By = {
  Status?: Maybe<Order_By>;
};

/** aggregated selection of "dw.Channels" */
export type Dw_Channels_Aggregate = {
  __typename?: 'dw_Channels_aggregate';
  aggregate?: Maybe<Dw_Channels_Aggregate_Fields>;
  nodes: Array<Dw_Channels>;
};

/** aggregate fields of "dw.Channels" */
export type Dw_Channels_Aggregate_Fields = {
  __typename?: 'dw_Channels_aggregate_fields';
  avg?: Maybe<Dw_Channels_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_Channels_Max_Fields>;
  min?: Maybe<Dw_Channels_Min_Fields>;
  stddev?: Maybe<Dw_Channels_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_Channels_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_Channels_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_Channels_Sum_Fields>;
  var_pop?: Maybe<Dw_Channels_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_Channels_Var_Samp_Fields>;
  variance?: Maybe<Dw_Channels_Variance_Fields>;
};


/** aggregate fields of "dw.Channels" */
export type Dw_Channels_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_Channels_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.Channels" */
export type Dw_Channels_Aggregate_Order_By = {
  avg?: Maybe<Dw_Channels_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_Channels_Max_Order_By>;
  min?: Maybe<Dw_Channels_Min_Order_By>;
  stddev?: Maybe<Dw_Channels_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_Channels_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_Channels_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_Channels_Sum_Order_By>;
  var_pop?: Maybe<Dw_Channels_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_Channels_Var_Samp_Order_By>;
  variance?: Maybe<Dw_Channels_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.Channels" */
export type Dw_Channels_Arr_Rel_Insert_Input = {
  data: Array<Dw_Channels_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_Channels_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_Channels_Avg_Fields = {
  __typename?: 'dw_Channels_avg_fields';
  IntervalFrequencyValue?: Maybe<Scalars['Float']>;
  IntervalMins?: Maybe<Scalars['Float']>;
  Level?: Maybe<Scalars['Float']>;
  NatureType?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  SummationType?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  ValueTypeEnum?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.Channels" */
export type Dw_Channels_Avg_Order_By = {
  IntervalFrequencyValue?: Maybe<Order_By>;
  IntervalMins?: Maybe<Order_By>;
  Level?: Maybe<Order_By>;
  NatureType?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  SummationType?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  ValueTypeEnum?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.Channels". All fields are combined with a logical 'AND'. */
export type Dw_Channels_Bool_Exp = {
  Baselines?: Maybe<Dw_Baseline_Bool_Exp>;
  Building?: Maybe<Dw_Buildings_Bool_Exp>;
  BuildingId?: Maybe<Uuid_Comparison_Exp>;
  ChannelId?: Maybe<Uuid_Comparison_Exp>;
  ChannelKey?: Maybe<String_Comparison_Exp>;
  ChannelsJobs?: Maybe<Dw_ChannelsJobs_Bool_Exp>;
  ChannelsMapping?: Maybe<Dw_ChannelsMappings_Bool_Exp>;
  ChannelsMappings?: Maybe<Dw_ChannelsMappings_Bool_Exp>;
  ChannelsMetadata?: Maybe<Dw_ChannelsMetadata_Bool_Exp>;
  ChannelsMetadataSingle?: Maybe<Dw_ChannelsMetadata_Bool_Exp>;
  ChannelsRules?: Maybe<Dw_ChannelsRules_Bool_Exp>;
  Consumptions?: Maybe<Dw_Consumption_Bool_Exp>;
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  EnableAlerts?: Maybe<Boolean_Comparison_Exp>;
  Feed?: Maybe<Dw_Feeds_Bool_Exp>;
  FeedId?: Maybe<Uuid_Comparison_Exp>;
  FeedsConfigBacNets?: Maybe<Dw_FeedsConfigBacNet_Bool_Exp>;
  FeedsConfigChannel?: Maybe<Dw_FeedsConfigChannels_Bool_Exp>;
  FeedsConfigChannels?: Maybe<Dw_FeedsConfigChannels_Bool_Exp>;
  FeedsConfigModbuses?: Maybe<Dw_FeedsConfigModbus_Bool_Exp>;
  Formula?: Maybe<String_Comparison_Exp>;
  IntervalFrequency?: Maybe<String_Comparison_Exp>;
  IntervalFrequencyValue?: Maybe<Bigint_Comparison_Exp>;
  IntervalMins?: Maybe<Int_Comparison_Exp>;
  Label?: Maybe<String_Comparison_Exp>;
  Level?: Maybe<Smallint_Comparison_Exp>;
  Load?: Maybe<Dw_Loads_Bool_Exp>;
  LoadId?: Maybe<Uuid_Comparison_Exp>;
  MLNotificationForecasts?: Maybe<Dw_MlNotificationForecasts_Bool_Exp>;
  MLNotifications?: Maybe<Dw_MlNotifications_Bool_Exp>;
  MLNotificationsCorrelations?: Maybe<Dw_MlNotificationsCorrelation_Bool_Exp>;
  MeterType?: Maybe<String_Comparison_Exp>;
  NatureType?: Maybe<Smallint_Comparison_Exp>;
  Notifications?: Maybe<Dw_Notifications_Bool_Exp>;
  Parameter?: Maybe<String_Comparison_Exp>;
  Reference?: Maybe<String_Comparison_Exp>;
  StartDate?: Maybe<Timestamp_Comparison_Exp>;
  Status?: Maybe<Smallint_Comparison_Exp>;
  SummationType?: Maybe<Smallint_Comparison_Exp>;
  TimeZone?: Maybe<String_Comparison_Exp>;
  Type?: Maybe<Smallint_Comparison_Exp>;
  UnitOfMeasure?: Maybe<String_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  ValueType?: Maybe<String_Comparison_Exp>;
  ValueTypeEnum?: Maybe<Smallint_Comparison_Exp>;
  _and?: Maybe<Array<Dw_Channels_Bool_Exp>>;
  _not?: Maybe<Dw_Channels_Bool_Exp>;
  _or?: Maybe<Array<Dw_Channels_Bool_Exp>>;
  channelsRulesBySourcechannelid?: Maybe<Dw_ChannelsRules_Bool_Exp>;
};

/** unique or primary key constraints on table "dw.Channels" */
export enum Dw_Channels_Constraint {
  /** unique or primary key constraint */
  ChannelsChannelidUindex = 'channels_channelid_uindex',
  /** unique or primary key constraint */
  ChannelsChannelkeyPk = 'channels_channelkey_pk',
  /** unique or primary key constraint */
  ChannelsPk = 'channels_pk'
}

/** input type for incrementing numeric columns in table "dw.Channels" */
export type Dw_Channels_Inc_Input = {
  IntervalFrequencyValue?: Maybe<Scalars['bigint']>;
  IntervalMins?: Maybe<Scalars['Int']>;
  Level?: Maybe<Scalars['smallint']>;
  NatureType?: Maybe<Scalars['smallint']>;
  Status?: Maybe<Scalars['smallint']>;
  SummationType?: Maybe<Scalars['smallint']>;
  Type?: Maybe<Scalars['smallint']>;
  ValueTypeEnum?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.Channels" */
export type Dw_Channels_Insert_Input = {
  Baselines?: Maybe<Dw_Baseline_Arr_Rel_Insert_Input>;
  Building?: Maybe<Dw_Buildings_Obj_Rel_Insert_Input>;
  BuildingId?: Maybe<Scalars['uuid']>;
  ChannelId?: Maybe<Scalars['uuid']>;
  ChannelKey?: Maybe<Scalars['String']>;
  ChannelsJobs?: Maybe<Dw_ChannelsJobs_Arr_Rel_Insert_Input>;
  ChannelsMapping?: Maybe<Dw_ChannelsMappings_Obj_Rel_Insert_Input>;
  ChannelsMappings?: Maybe<Dw_ChannelsMappings_Arr_Rel_Insert_Input>;
  ChannelsMetadata?: Maybe<Dw_ChannelsMetadata_Arr_Rel_Insert_Input>;
  ChannelsMetadataSingle?: Maybe<Dw_ChannelsMetadata_Obj_Rel_Insert_Input>;
  ChannelsRules?: Maybe<Dw_ChannelsRules_Arr_Rel_Insert_Input>;
  Consumptions?: Maybe<Dw_Consumption_Arr_Rel_Insert_Input>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  EnableAlerts?: Maybe<Scalars['Boolean']>;
  Feed?: Maybe<Dw_Feeds_Obj_Rel_Insert_Input>;
  FeedId?: Maybe<Scalars['uuid']>;
  FeedsConfigBacNets?: Maybe<Dw_FeedsConfigBacNet_Arr_Rel_Insert_Input>;
  FeedsConfigChannel?: Maybe<Dw_FeedsConfigChannels_Obj_Rel_Insert_Input>;
  FeedsConfigChannels?: Maybe<Dw_FeedsConfigChannels_Arr_Rel_Insert_Input>;
  FeedsConfigModbuses?: Maybe<Dw_FeedsConfigModbus_Arr_Rel_Insert_Input>;
  Formula?: Maybe<Scalars['String']>;
  IntervalFrequency?: Maybe<Scalars['String']>;
  IntervalFrequencyValue?: Maybe<Scalars['bigint']>;
  IntervalMins?: Maybe<Scalars['Int']>;
  Label?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['smallint']>;
  Load?: Maybe<Dw_Loads_Obj_Rel_Insert_Input>;
  LoadId?: Maybe<Scalars['uuid']>;
  MLNotificationForecasts?: Maybe<Dw_MlNotificationForecasts_Arr_Rel_Insert_Input>;
  MLNotifications?: Maybe<Dw_MlNotifications_Arr_Rel_Insert_Input>;
  MLNotificationsCorrelations?: Maybe<Dw_MlNotificationsCorrelation_Arr_Rel_Insert_Input>;
  MeterType?: Maybe<Scalars['String']>;
  NatureType?: Maybe<Scalars['smallint']>;
  Notifications?: Maybe<Dw_Notifications_Arr_Rel_Insert_Input>;
  Parameter?: Maybe<Scalars['String']>;
  Reference?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['timestamp']>;
  Status?: Maybe<Scalars['smallint']>;
  SummationType?: Maybe<Scalars['smallint']>;
  TimeZone?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['smallint']>;
  UnitOfMeasure?: Maybe<Scalars['String']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  ValueType?: Maybe<Scalars['String']>;
  ValueTypeEnum?: Maybe<Scalars['smallint']>;
  channelsRulesBySourcechannelid?: Maybe<Dw_ChannelsRules_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Dw_Channels_Max_Fields = {
  __typename?: 'dw_Channels_max_fields';
  BuildingId?: Maybe<Scalars['uuid']>;
  ChannelId?: Maybe<Scalars['uuid']>;
  ChannelKey?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  FeedId?: Maybe<Scalars['uuid']>;
  Formula?: Maybe<Scalars['String']>;
  IntervalFrequency?: Maybe<Scalars['String']>;
  IntervalFrequencyValue?: Maybe<Scalars['bigint']>;
  IntervalMins?: Maybe<Scalars['Int']>;
  Label?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['smallint']>;
  LoadId?: Maybe<Scalars['uuid']>;
  MeterType?: Maybe<Scalars['String']>;
  NatureType?: Maybe<Scalars['smallint']>;
  Parameter?: Maybe<Scalars['String']>;
  Reference?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['timestamp']>;
  Status?: Maybe<Scalars['smallint']>;
  SummationType?: Maybe<Scalars['smallint']>;
  TimeZone?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['smallint']>;
  UnitOfMeasure?: Maybe<Scalars['String']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  ValueType?: Maybe<Scalars['String']>;
  ValueTypeEnum?: Maybe<Scalars['smallint']>;
};

/** order by max() on columns of table "dw.Channels" */
export type Dw_Channels_Max_Order_By = {
  BuildingId?: Maybe<Order_By>;
  ChannelId?: Maybe<Order_By>;
  ChannelKey?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  FeedId?: Maybe<Order_By>;
  Formula?: Maybe<Order_By>;
  IntervalFrequency?: Maybe<Order_By>;
  IntervalFrequencyValue?: Maybe<Order_By>;
  IntervalMins?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  Level?: Maybe<Order_By>;
  LoadId?: Maybe<Order_By>;
  MeterType?: Maybe<Order_By>;
  NatureType?: Maybe<Order_By>;
  Parameter?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  StartDate?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  SummationType?: Maybe<Order_By>;
  TimeZone?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  UnitOfMeasure?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
  ValueType?: Maybe<Order_By>;
  ValueTypeEnum?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_Channels_Min_Fields = {
  __typename?: 'dw_Channels_min_fields';
  BuildingId?: Maybe<Scalars['uuid']>;
  ChannelId?: Maybe<Scalars['uuid']>;
  ChannelKey?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  FeedId?: Maybe<Scalars['uuid']>;
  Formula?: Maybe<Scalars['String']>;
  IntervalFrequency?: Maybe<Scalars['String']>;
  IntervalFrequencyValue?: Maybe<Scalars['bigint']>;
  IntervalMins?: Maybe<Scalars['Int']>;
  Label?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['smallint']>;
  LoadId?: Maybe<Scalars['uuid']>;
  MeterType?: Maybe<Scalars['String']>;
  NatureType?: Maybe<Scalars['smallint']>;
  Parameter?: Maybe<Scalars['String']>;
  Reference?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['timestamp']>;
  Status?: Maybe<Scalars['smallint']>;
  SummationType?: Maybe<Scalars['smallint']>;
  TimeZone?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['smallint']>;
  UnitOfMeasure?: Maybe<Scalars['String']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  ValueType?: Maybe<Scalars['String']>;
  ValueTypeEnum?: Maybe<Scalars['smallint']>;
};

/** order by min() on columns of table "dw.Channels" */
export type Dw_Channels_Min_Order_By = {
  BuildingId?: Maybe<Order_By>;
  ChannelId?: Maybe<Order_By>;
  ChannelKey?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  FeedId?: Maybe<Order_By>;
  Formula?: Maybe<Order_By>;
  IntervalFrequency?: Maybe<Order_By>;
  IntervalFrequencyValue?: Maybe<Order_By>;
  IntervalMins?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  Level?: Maybe<Order_By>;
  LoadId?: Maybe<Order_By>;
  MeterType?: Maybe<Order_By>;
  NatureType?: Maybe<Order_By>;
  Parameter?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  StartDate?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  SummationType?: Maybe<Order_By>;
  TimeZone?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  UnitOfMeasure?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
  ValueType?: Maybe<Order_By>;
  ValueTypeEnum?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.Channels" */
export type Dw_Channels_Mutation_Response = {
  __typename?: 'dw_Channels_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_Channels>;
};

/** input type for inserting object relation for remote table "dw.Channels" */
export type Dw_Channels_Obj_Rel_Insert_Input = {
  data: Dw_Channels_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_Channels_On_Conflict>;
};

/** on conflict condition type for table "dw.Channels" */
export type Dw_Channels_On_Conflict = {
  constraint: Dw_Channels_Constraint;
  update_columns?: Array<Dw_Channels_Update_Column>;
  where?: Maybe<Dw_Channels_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.Channels". */
export type Dw_Channels_Order_By = {
  Baselines_aggregate?: Maybe<Dw_Baseline_Aggregate_Order_By>;
  Building?: Maybe<Dw_Buildings_Order_By>;
  BuildingId?: Maybe<Order_By>;
  ChannelId?: Maybe<Order_By>;
  ChannelKey?: Maybe<Order_By>;
  ChannelsJobs_aggregate?: Maybe<Dw_ChannelsJobs_Aggregate_Order_By>;
  ChannelsMapping?: Maybe<Dw_ChannelsMappings_Order_By>;
  ChannelsMappings_aggregate?: Maybe<Dw_ChannelsMappings_Aggregate_Order_By>;
  ChannelsMetadataSingle?: Maybe<Dw_ChannelsMetadata_Order_By>;
  ChannelsMetadata_aggregate?: Maybe<Dw_ChannelsMetadata_Aggregate_Order_By>;
  ChannelsRules_aggregate?: Maybe<Dw_ChannelsRules_Aggregate_Order_By>;
  Consumptions_aggregate?: Maybe<Dw_Consumption_Aggregate_Order_By>;
  CreatedOn?: Maybe<Order_By>;
  EnableAlerts?: Maybe<Order_By>;
  Feed?: Maybe<Dw_Feeds_Order_By>;
  FeedId?: Maybe<Order_By>;
  FeedsConfigBacNets_aggregate?: Maybe<Dw_FeedsConfigBacNet_Aggregate_Order_By>;
  FeedsConfigChannel?: Maybe<Dw_FeedsConfigChannels_Order_By>;
  FeedsConfigChannels_aggregate?: Maybe<Dw_FeedsConfigChannels_Aggregate_Order_By>;
  FeedsConfigModbuses_aggregate?: Maybe<Dw_FeedsConfigModbus_Aggregate_Order_By>;
  Formula?: Maybe<Order_By>;
  IntervalFrequency?: Maybe<Order_By>;
  IntervalFrequencyValue?: Maybe<Order_By>;
  IntervalMins?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  Level?: Maybe<Order_By>;
  Load?: Maybe<Dw_Loads_Order_By>;
  LoadId?: Maybe<Order_By>;
  MLNotificationForecasts_aggregate?: Maybe<Dw_MlNotificationForecasts_Aggregate_Order_By>;
  MLNotificationsCorrelations_aggregate?: Maybe<Dw_MlNotificationsCorrelation_Aggregate_Order_By>;
  MLNotifications_aggregate?: Maybe<Dw_MlNotifications_Aggregate_Order_By>;
  MeterType?: Maybe<Order_By>;
  NatureType?: Maybe<Order_By>;
  Notifications_aggregate?: Maybe<Dw_Notifications_Aggregate_Order_By>;
  Parameter?: Maybe<Order_By>;
  Reference?: Maybe<Order_By>;
  StartDate?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  SummationType?: Maybe<Order_By>;
  TimeZone?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  UnitOfMeasure?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
  ValueType?: Maybe<Order_By>;
  ValueTypeEnum?: Maybe<Order_By>;
  channelsRulesBySourcechannelid_aggregate?: Maybe<Dw_ChannelsRules_Aggregate_Order_By>;
};

/** primary key columns input for table: dw_Channels */
export type Dw_Channels_Pk_Columns_Input = {
  ChannelId: Scalars['uuid'];
};

/** select columns of table "dw.Channels" */
export enum Dw_Channels_Select_Column {
  /** column name */
  BuildingId = 'BuildingId',
  /** column name */
  ChannelId = 'ChannelId',
  /** column name */
  ChannelKey = 'ChannelKey',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  EnableAlerts = 'EnableAlerts',
  /** column name */
  FeedId = 'FeedId',
  /** column name */
  Formula = 'Formula',
  /** column name */
  IntervalFrequency = 'IntervalFrequency',
  /** column name */
  IntervalFrequencyValue = 'IntervalFrequencyValue',
  /** column name */
  IntervalMins = 'IntervalMins',
  /** column name */
  Label = 'Label',
  /** column name */
  Level = 'Level',
  /** column name */
  LoadId = 'LoadId',
  /** column name */
  MeterType = 'MeterType',
  /** column name */
  NatureType = 'NatureType',
  /** column name */
  Parameter = 'Parameter',
  /** column name */
  Reference = 'Reference',
  /** column name */
  StartDate = 'StartDate',
  /** column name */
  Status = 'Status',
  /** column name */
  SummationType = 'SummationType',
  /** column name */
  TimeZone = 'TimeZone',
  /** column name */
  Type = 'Type',
  /** column name */
  UnitOfMeasure = 'UnitOfMeasure',
  /** column name */
  UpdatedOn = 'UpdatedOn',
  /** column name */
  ValueType = 'ValueType',
  /** column name */
  ValueTypeEnum = 'ValueTypeEnum'
}

/** input type for updating data in table "dw.Channels" */
export type Dw_Channels_Set_Input = {
  BuildingId?: Maybe<Scalars['uuid']>;
  ChannelId?: Maybe<Scalars['uuid']>;
  ChannelKey?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  EnableAlerts?: Maybe<Scalars['Boolean']>;
  FeedId?: Maybe<Scalars['uuid']>;
  Formula?: Maybe<Scalars['String']>;
  IntervalFrequency?: Maybe<Scalars['String']>;
  IntervalFrequencyValue?: Maybe<Scalars['bigint']>;
  IntervalMins?: Maybe<Scalars['Int']>;
  Label?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['smallint']>;
  LoadId?: Maybe<Scalars['uuid']>;
  MeterType?: Maybe<Scalars['String']>;
  NatureType?: Maybe<Scalars['smallint']>;
  Parameter?: Maybe<Scalars['String']>;
  Reference?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['timestamp']>;
  Status?: Maybe<Scalars['smallint']>;
  SummationType?: Maybe<Scalars['smallint']>;
  TimeZone?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['smallint']>;
  UnitOfMeasure?: Maybe<Scalars['String']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  ValueType?: Maybe<Scalars['String']>;
  ValueTypeEnum?: Maybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type Dw_Channels_Stddev_Fields = {
  __typename?: 'dw_Channels_stddev_fields';
  IntervalFrequencyValue?: Maybe<Scalars['Float']>;
  IntervalMins?: Maybe<Scalars['Float']>;
  Level?: Maybe<Scalars['Float']>;
  NatureType?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  SummationType?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  ValueTypeEnum?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.Channels" */
export type Dw_Channels_Stddev_Order_By = {
  IntervalFrequencyValue?: Maybe<Order_By>;
  IntervalMins?: Maybe<Order_By>;
  Level?: Maybe<Order_By>;
  NatureType?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  SummationType?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  ValueTypeEnum?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_Channels_Stddev_Pop_Fields = {
  __typename?: 'dw_Channels_stddev_pop_fields';
  IntervalFrequencyValue?: Maybe<Scalars['Float']>;
  IntervalMins?: Maybe<Scalars['Float']>;
  Level?: Maybe<Scalars['Float']>;
  NatureType?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  SummationType?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  ValueTypeEnum?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.Channels" */
export type Dw_Channels_Stddev_Pop_Order_By = {
  IntervalFrequencyValue?: Maybe<Order_By>;
  IntervalMins?: Maybe<Order_By>;
  Level?: Maybe<Order_By>;
  NatureType?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  SummationType?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  ValueTypeEnum?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_Channels_Stddev_Samp_Fields = {
  __typename?: 'dw_Channels_stddev_samp_fields';
  IntervalFrequencyValue?: Maybe<Scalars['Float']>;
  IntervalMins?: Maybe<Scalars['Float']>;
  Level?: Maybe<Scalars['Float']>;
  NatureType?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  SummationType?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  ValueTypeEnum?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.Channels" */
export type Dw_Channels_Stddev_Samp_Order_By = {
  IntervalFrequencyValue?: Maybe<Order_By>;
  IntervalMins?: Maybe<Order_By>;
  Level?: Maybe<Order_By>;
  NatureType?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  SummationType?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  ValueTypeEnum?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_Channels_Sum_Fields = {
  __typename?: 'dw_Channels_sum_fields';
  IntervalFrequencyValue?: Maybe<Scalars['bigint']>;
  IntervalMins?: Maybe<Scalars['Int']>;
  Level?: Maybe<Scalars['smallint']>;
  NatureType?: Maybe<Scalars['smallint']>;
  Status?: Maybe<Scalars['smallint']>;
  SummationType?: Maybe<Scalars['smallint']>;
  Type?: Maybe<Scalars['smallint']>;
  ValueTypeEnum?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "dw.Channels" */
export type Dw_Channels_Sum_Order_By = {
  IntervalFrequencyValue?: Maybe<Order_By>;
  IntervalMins?: Maybe<Order_By>;
  Level?: Maybe<Order_By>;
  NatureType?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  SummationType?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  ValueTypeEnum?: Maybe<Order_By>;
};

/** update columns of table "dw.Channels" */
export enum Dw_Channels_Update_Column {
  /** column name */
  BuildingId = 'BuildingId',
  /** column name */
  ChannelId = 'ChannelId',
  /** column name */
  ChannelKey = 'ChannelKey',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  EnableAlerts = 'EnableAlerts',
  /** column name */
  FeedId = 'FeedId',
  /** column name */
  Formula = 'Formula',
  /** column name */
  IntervalFrequency = 'IntervalFrequency',
  /** column name */
  IntervalFrequencyValue = 'IntervalFrequencyValue',
  /** column name */
  IntervalMins = 'IntervalMins',
  /** column name */
  Label = 'Label',
  /** column name */
  Level = 'Level',
  /** column name */
  LoadId = 'LoadId',
  /** column name */
  MeterType = 'MeterType',
  /** column name */
  NatureType = 'NatureType',
  /** column name */
  Parameter = 'Parameter',
  /** column name */
  Reference = 'Reference',
  /** column name */
  StartDate = 'StartDate',
  /** column name */
  Status = 'Status',
  /** column name */
  SummationType = 'SummationType',
  /** column name */
  TimeZone = 'TimeZone',
  /** column name */
  Type = 'Type',
  /** column name */
  UnitOfMeasure = 'UnitOfMeasure',
  /** column name */
  UpdatedOn = 'UpdatedOn',
  /** column name */
  ValueType = 'ValueType',
  /** column name */
  ValueTypeEnum = 'ValueTypeEnum'
}

/** aggregate var_pop on columns */
export type Dw_Channels_Var_Pop_Fields = {
  __typename?: 'dw_Channels_var_pop_fields';
  IntervalFrequencyValue?: Maybe<Scalars['Float']>;
  IntervalMins?: Maybe<Scalars['Float']>;
  Level?: Maybe<Scalars['Float']>;
  NatureType?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  SummationType?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  ValueTypeEnum?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.Channels" */
export type Dw_Channels_Var_Pop_Order_By = {
  IntervalFrequencyValue?: Maybe<Order_By>;
  IntervalMins?: Maybe<Order_By>;
  Level?: Maybe<Order_By>;
  NatureType?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  SummationType?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  ValueTypeEnum?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_Channels_Var_Samp_Fields = {
  __typename?: 'dw_Channels_var_samp_fields';
  IntervalFrequencyValue?: Maybe<Scalars['Float']>;
  IntervalMins?: Maybe<Scalars['Float']>;
  Level?: Maybe<Scalars['Float']>;
  NatureType?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  SummationType?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  ValueTypeEnum?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.Channels" */
export type Dw_Channels_Var_Samp_Order_By = {
  IntervalFrequencyValue?: Maybe<Order_By>;
  IntervalMins?: Maybe<Order_By>;
  Level?: Maybe<Order_By>;
  NatureType?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  SummationType?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  ValueTypeEnum?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_Channels_Variance_Fields = {
  __typename?: 'dw_Channels_variance_fields';
  IntervalFrequencyValue?: Maybe<Scalars['Float']>;
  IntervalMins?: Maybe<Scalars['Float']>;
  Level?: Maybe<Scalars['Float']>;
  NatureType?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  SummationType?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  ValueTypeEnum?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.Channels" */
export type Dw_Channels_Variance_Order_By = {
  IntervalFrequencyValue?: Maybe<Order_By>;
  IntervalMins?: Maybe<Order_By>;
  Level?: Maybe<Order_By>;
  NatureType?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  SummationType?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  ValueTypeEnum?: Maybe<Order_By>;
};

/** columns and relationships of "dw.Consumption" */
export type Dw_Consumption = {
  __typename?: 'dw_Consumption';
  /** An object relationship */
  Channel: Dw_Channels;
  ChannelId: Scalars['uuid'];
  ConsumptionDate: Scalars['timestamp'];
  Corrected: Scalars['numeric'];
  Raw: Scalars['numeric'];
  ReadingType: Scalars['smallint'];
  UpdatedOn: Scalars['timestamp'];
};

/** columns and relationships of "dw.ConsumptionJobs" */
export type Dw_ConsumptionJobs = {
  __typename?: 'dw_ConsumptionJobs';
  CustomId?: Maybe<Scalars['uuid']>;
  File: Scalars['String'];
  JobId: Scalars['uuid'];
  LastActivity: Scalars['timestamp'];
  Logs: Scalars['json'];
  Size: Scalars['bigint'];
  Status: Scalars['smallint'];
  Type: Scalars['smallint'];
};


/** columns and relationships of "dw.ConsumptionJobs" */
export type Dw_ConsumptionJobsLogsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "dw.ConsumptionJobs" */
export type Dw_ConsumptionJobs_Aggregate = {
  __typename?: 'dw_ConsumptionJobs_aggregate';
  aggregate?: Maybe<Dw_ConsumptionJobs_Aggregate_Fields>;
  nodes: Array<Dw_ConsumptionJobs>;
};

/** aggregate fields of "dw.ConsumptionJobs" */
export type Dw_ConsumptionJobs_Aggregate_Fields = {
  __typename?: 'dw_ConsumptionJobs_aggregate_fields';
  avg?: Maybe<Dw_ConsumptionJobs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_ConsumptionJobs_Max_Fields>;
  min?: Maybe<Dw_ConsumptionJobs_Min_Fields>;
  stddev?: Maybe<Dw_ConsumptionJobs_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_ConsumptionJobs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_ConsumptionJobs_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_ConsumptionJobs_Sum_Fields>;
  var_pop?: Maybe<Dw_ConsumptionJobs_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_ConsumptionJobs_Var_Samp_Fields>;
  variance?: Maybe<Dw_ConsumptionJobs_Variance_Fields>;
};


/** aggregate fields of "dw.ConsumptionJobs" */
export type Dw_ConsumptionJobs_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_ConsumptionJobs_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dw_ConsumptionJobs_Avg_Fields = {
  __typename?: 'dw_ConsumptionJobs_avg_fields';
  Size?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dw.ConsumptionJobs". All fields are combined with a logical 'AND'. */
export type Dw_ConsumptionJobs_Bool_Exp = {
  CustomId?: Maybe<Uuid_Comparison_Exp>;
  File?: Maybe<String_Comparison_Exp>;
  JobId?: Maybe<Uuid_Comparison_Exp>;
  LastActivity?: Maybe<Timestamp_Comparison_Exp>;
  Logs?: Maybe<Json_Comparison_Exp>;
  Size?: Maybe<Bigint_Comparison_Exp>;
  Status?: Maybe<Smallint_Comparison_Exp>;
  Type?: Maybe<Smallint_Comparison_Exp>;
  _and?: Maybe<Array<Dw_ConsumptionJobs_Bool_Exp>>;
  _not?: Maybe<Dw_ConsumptionJobs_Bool_Exp>;
  _or?: Maybe<Array<Dw_ConsumptionJobs_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.ConsumptionJobs" */
export enum Dw_ConsumptionJobs_Constraint {
  /** unique or primary key constraint */
  ConsumptionjobsJobidUindex = 'consumptionjobs_jobid_uindex',
  /** unique or primary key constraint */
  ConsumptionjobsPk = 'consumptionjobs_pk'
}

/** input type for incrementing numeric columns in table "dw.ConsumptionJobs" */
export type Dw_ConsumptionJobs_Inc_Input = {
  Size?: Maybe<Scalars['bigint']>;
  Status?: Maybe<Scalars['smallint']>;
  Type?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.ConsumptionJobs" */
export type Dw_ConsumptionJobs_Insert_Input = {
  CustomId?: Maybe<Scalars['uuid']>;
  File?: Maybe<Scalars['String']>;
  JobId?: Maybe<Scalars['uuid']>;
  LastActivity?: Maybe<Scalars['timestamp']>;
  Logs?: Maybe<Scalars['json']>;
  Size?: Maybe<Scalars['bigint']>;
  Status?: Maybe<Scalars['smallint']>;
  Type?: Maybe<Scalars['smallint']>;
};

/** aggregate max on columns */
export type Dw_ConsumptionJobs_Max_Fields = {
  __typename?: 'dw_ConsumptionJobs_max_fields';
  CustomId?: Maybe<Scalars['uuid']>;
  File?: Maybe<Scalars['String']>;
  JobId?: Maybe<Scalars['uuid']>;
  LastActivity?: Maybe<Scalars['timestamp']>;
  Size?: Maybe<Scalars['bigint']>;
  Status?: Maybe<Scalars['smallint']>;
  Type?: Maybe<Scalars['smallint']>;
};

/** aggregate min on columns */
export type Dw_ConsumptionJobs_Min_Fields = {
  __typename?: 'dw_ConsumptionJobs_min_fields';
  CustomId?: Maybe<Scalars['uuid']>;
  File?: Maybe<Scalars['String']>;
  JobId?: Maybe<Scalars['uuid']>;
  LastActivity?: Maybe<Scalars['timestamp']>;
  Size?: Maybe<Scalars['bigint']>;
  Status?: Maybe<Scalars['smallint']>;
  Type?: Maybe<Scalars['smallint']>;
};

/** response of any mutation on the table "dw.ConsumptionJobs" */
export type Dw_ConsumptionJobs_Mutation_Response = {
  __typename?: 'dw_ConsumptionJobs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_ConsumptionJobs>;
};

/** on conflict condition type for table "dw.ConsumptionJobs" */
export type Dw_ConsumptionJobs_On_Conflict = {
  constraint: Dw_ConsumptionJobs_Constraint;
  update_columns?: Array<Dw_ConsumptionJobs_Update_Column>;
  where?: Maybe<Dw_ConsumptionJobs_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.ConsumptionJobs". */
export type Dw_ConsumptionJobs_Order_By = {
  CustomId?: Maybe<Order_By>;
  File?: Maybe<Order_By>;
  JobId?: Maybe<Order_By>;
  LastActivity?: Maybe<Order_By>;
  Logs?: Maybe<Order_By>;
  Size?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_ConsumptionJobs */
export type Dw_ConsumptionJobs_Pk_Columns_Input = {
  JobId: Scalars['uuid'];
};

/** select columns of table "dw.ConsumptionJobs" */
export enum Dw_ConsumptionJobs_Select_Column {
  /** column name */
  CustomId = 'CustomId',
  /** column name */
  File = 'File',
  /** column name */
  JobId = 'JobId',
  /** column name */
  LastActivity = 'LastActivity',
  /** column name */
  Logs = 'Logs',
  /** column name */
  Size = 'Size',
  /** column name */
  Status = 'Status',
  /** column name */
  Type = 'Type'
}

/** input type for updating data in table "dw.ConsumptionJobs" */
export type Dw_ConsumptionJobs_Set_Input = {
  CustomId?: Maybe<Scalars['uuid']>;
  File?: Maybe<Scalars['String']>;
  JobId?: Maybe<Scalars['uuid']>;
  LastActivity?: Maybe<Scalars['timestamp']>;
  Logs?: Maybe<Scalars['json']>;
  Size?: Maybe<Scalars['bigint']>;
  Status?: Maybe<Scalars['smallint']>;
  Type?: Maybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type Dw_ConsumptionJobs_Stddev_Fields = {
  __typename?: 'dw_ConsumptionJobs_stddev_fields';
  Size?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dw_ConsumptionJobs_Stddev_Pop_Fields = {
  __typename?: 'dw_ConsumptionJobs_stddev_pop_fields';
  Size?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dw_ConsumptionJobs_Stddev_Samp_Fields = {
  __typename?: 'dw_ConsumptionJobs_stddev_samp_fields';
  Size?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Dw_ConsumptionJobs_Sum_Fields = {
  __typename?: 'dw_ConsumptionJobs_sum_fields';
  Size?: Maybe<Scalars['bigint']>;
  Status?: Maybe<Scalars['smallint']>;
  Type?: Maybe<Scalars['smallint']>;
};

/** update columns of table "dw.ConsumptionJobs" */
export enum Dw_ConsumptionJobs_Update_Column {
  /** column name */
  CustomId = 'CustomId',
  /** column name */
  File = 'File',
  /** column name */
  JobId = 'JobId',
  /** column name */
  LastActivity = 'LastActivity',
  /** column name */
  Logs = 'Logs',
  /** column name */
  Size = 'Size',
  /** column name */
  Status = 'Status',
  /** column name */
  Type = 'Type'
}

/** aggregate var_pop on columns */
export type Dw_ConsumptionJobs_Var_Pop_Fields = {
  __typename?: 'dw_ConsumptionJobs_var_pop_fields';
  Size?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dw_ConsumptionJobs_Var_Samp_Fields = {
  __typename?: 'dw_ConsumptionJobs_var_samp_fields';
  Size?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dw_ConsumptionJobs_Variance_Fields = {
  __typename?: 'dw_ConsumptionJobs_variance_fields';
  Size?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "dw.Consumption" */
export type Dw_Consumption_Aggregate = {
  __typename?: 'dw_Consumption_aggregate';
  aggregate?: Maybe<Dw_Consumption_Aggregate_Fields>;
  nodes: Array<Dw_Consumption>;
};

/** aggregate fields of "dw.Consumption" */
export type Dw_Consumption_Aggregate_Fields = {
  __typename?: 'dw_Consumption_aggregate_fields';
  avg?: Maybe<Dw_Consumption_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_Consumption_Max_Fields>;
  min?: Maybe<Dw_Consumption_Min_Fields>;
  stddev?: Maybe<Dw_Consumption_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_Consumption_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_Consumption_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_Consumption_Sum_Fields>;
  var_pop?: Maybe<Dw_Consumption_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_Consumption_Var_Samp_Fields>;
  variance?: Maybe<Dw_Consumption_Variance_Fields>;
};


/** aggregate fields of "dw.Consumption" */
export type Dw_Consumption_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_Consumption_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.Consumption" */
export type Dw_Consumption_Aggregate_Order_By = {
  avg?: Maybe<Dw_Consumption_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_Consumption_Max_Order_By>;
  min?: Maybe<Dw_Consumption_Min_Order_By>;
  stddev?: Maybe<Dw_Consumption_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_Consumption_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_Consumption_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_Consumption_Sum_Order_By>;
  var_pop?: Maybe<Dw_Consumption_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_Consumption_Var_Samp_Order_By>;
  variance?: Maybe<Dw_Consumption_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.Consumption" */
export type Dw_Consumption_Arr_Rel_Insert_Input = {
  data: Array<Dw_Consumption_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_Consumption_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_Consumption_Avg_Fields = {
  __typename?: 'dw_Consumption_avg_fields';
  Corrected?: Maybe<Scalars['Float']>;
  Raw?: Maybe<Scalars['Float']>;
  ReadingType?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.Consumption" */
export type Dw_Consumption_Avg_Order_By = {
  Corrected?: Maybe<Order_By>;
  Raw?: Maybe<Order_By>;
  ReadingType?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.Consumption". All fields are combined with a logical 'AND'. */
export type Dw_Consumption_Bool_Exp = {
  Channel?: Maybe<Dw_Channels_Bool_Exp>;
  ChannelId?: Maybe<Uuid_Comparison_Exp>;
  ConsumptionDate?: Maybe<Timestamp_Comparison_Exp>;
  Corrected?: Maybe<Numeric_Comparison_Exp>;
  Raw?: Maybe<Numeric_Comparison_Exp>;
  ReadingType?: Maybe<Smallint_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  _and?: Maybe<Array<Dw_Consumption_Bool_Exp>>;
  _not?: Maybe<Dw_Consumption_Bool_Exp>;
  _or?: Maybe<Array<Dw_Consumption_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.Consumption" */
export enum Dw_Consumption_Constraint {
  /** unique or primary key constraint */
  ConsumptionNewChannelidConsumptiondateUindex = 'consumption_new_channelid_consumptiondate_uindex',
  /** unique or primary key constraint */
  ConsumptionNewPk = 'consumption_new_pk',
  /** unique or primary key constraint */
  ConsumptionNewUnique = 'consumption_new_unique'
}

/** input type for incrementing numeric columns in table "dw.Consumption" */
export type Dw_Consumption_Inc_Input = {
  Corrected?: Maybe<Scalars['numeric']>;
  Raw?: Maybe<Scalars['numeric']>;
  ReadingType?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.Consumption" */
export type Dw_Consumption_Insert_Input = {
  Channel?: Maybe<Dw_Channels_Obj_Rel_Insert_Input>;
  ChannelId?: Maybe<Scalars['uuid']>;
  ConsumptionDate?: Maybe<Scalars['timestamp']>;
  Corrected?: Maybe<Scalars['numeric']>;
  Raw?: Maybe<Scalars['numeric']>;
  ReadingType?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Dw_Consumption_Max_Fields = {
  __typename?: 'dw_Consumption_max_fields';
  ChannelId?: Maybe<Scalars['uuid']>;
  ConsumptionDate?: Maybe<Scalars['timestamp']>;
  Corrected?: Maybe<Scalars['numeric']>;
  Raw?: Maybe<Scalars['numeric']>;
  ReadingType?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "dw.Consumption" */
export type Dw_Consumption_Max_Order_By = {
  ChannelId?: Maybe<Order_By>;
  ConsumptionDate?: Maybe<Order_By>;
  Corrected?: Maybe<Order_By>;
  Raw?: Maybe<Order_By>;
  ReadingType?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_Consumption_Min_Fields = {
  __typename?: 'dw_Consumption_min_fields';
  ChannelId?: Maybe<Scalars['uuid']>;
  ConsumptionDate?: Maybe<Scalars['timestamp']>;
  Corrected?: Maybe<Scalars['numeric']>;
  Raw?: Maybe<Scalars['numeric']>;
  ReadingType?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "dw.Consumption" */
export type Dw_Consumption_Min_Order_By = {
  ChannelId?: Maybe<Order_By>;
  ConsumptionDate?: Maybe<Order_By>;
  Corrected?: Maybe<Order_By>;
  Raw?: Maybe<Order_By>;
  ReadingType?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.Consumption" */
export type Dw_Consumption_Mutation_Response = {
  __typename?: 'dw_Consumption_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_Consumption>;
};

/** on conflict condition type for table "dw.Consumption" */
export type Dw_Consumption_On_Conflict = {
  constraint: Dw_Consumption_Constraint;
  update_columns?: Array<Dw_Consumption_Update_Column>;
  where?: Maybe<Dw_Consumption_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.Consumption". */
export type Dw_Consumption_Order_By = {
  Channel?: Maybe<Dw_Channels_Order_By>;
  ChannelId?: Maybe<Order_By>;
  ConsumptionDate?: Maybe<Order_By>;
  Corrected?: Maybe<Order_By>;
  Raw?: Maybe<Order_By>;
  ReadingType?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_Consumption */
export type Dw_Consumption_Pk_Columns_Input = {
  ChannelId: Scalars['uuid'];
  ConsumptionDate: Scalars['timestamp'];
};

/** select columns of table "dw.Consumption" */
export enum Dw_Consumption_Select_Column {
  /** column name */
  ChannelId = 'ChannelId',
  /** column name */
  ConsumptionDate = 'ConsumptionDate',
  /** column name */
  Corrected = 'Corrected',
  /** column name */
  Raw = 'Raw',
  /** column name */
  ReadingType = 'ReadingType',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** input type for updating data in table "dw.Consumption" */
export type Dw_Consumption_Set_Input = {
  ChannelId?: Maybe<Scalars['uuid']>;
  ConsumptionDate?: Maybe<Scalars['timestamp']>;
  Corrected?: Maybe<Scalars['numeric']>;
  Raw?: Maybe<Scalars['numeric']>;
  ReadingType?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Dw_Consumption_Stddev_Fields = {
  __typename?: 'dw_Consumption_stddev_fields';
  Corrected?: Maybe<Scalars['Float']>;
  Raw?: Maybe<Scalars['Float']>;
  ReadingType?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.Consumption" */
export type Dw_Consumption_Stddev_Order_By = {
  Corrected?: Maybe<Order_By>;
  Raw?: Maybe<Order_By>;
  ReadingType?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_Consumption_Stddev_Pop_Fields = {
  __typename?: 'dw_Consumption_stddev_pop_fields';
  Corrected?: Maybe<Scalars['Float']>;
  Raw?: Maybe<Scalars['Float']>;
  ReadingType?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.Consumption" */
export type Dw_Consumption_Stddev_Pop_Order_By = {
  Corrected?: Maybe<Order_By>;
  Raw?: Maybe<Order_By>;
  ReadingType?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_Consumption_Stddev_Samp_Fields = {
  __typename?: 'dw_Consumption_stddev_samp_fields';
  Corrected?: Maybe<Scalars['Float']>;
  Raw?: Maybe<Scalars['Float']>;
  ReadingType?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.Consumption" */
export type Dw_Consumption_Stddev_Samp_Order_By = {
  Corrected?: Maybe<Order_By>;
  Raw?: Maybe<Order_By>;
  ReadingType?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_Consumption_Sum_Fields = {
  __typename?: 'dw_Consumption_sum_fields';
  Corrected?: Maybe<Scalars['numeric']>;
  Raw?: Maybe<Scalars['numeric']>;
  ReadingType?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "dw.Consumption" */
export type Dw_Consumption_Sum_Order_By = {
  Corrected?: Maybe<Order_By>;
  Raw?: Maybe<Order_By>;
  ReadingType?: Maybe<Order_By>;
};

/** update columns of table "dw.Consumption" */
export enum Dw_Consumption_Update_Column {
  /** column name */
  ChannelId = 'ChannelId',
  /** column name */
  ConsumptionDate = 'ConsumptionDate',
  /** column name */
  Corrected = 'Corrected',
  /** column name */
  Raw = 'Raw',
  /** column name */
  ReadingType = 'ReadingType',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** aggregate var_pop on columns */
export type Dw_Consumption_Var_Pop_Fields = {
  __typename?: 'dw_Consumption_var_pop_fields';
  Corrected?: Maybe<Scalars['Float']>;
  Raw?: Maybe<Scalars['Float']>;
  ReadingType?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.Consumption" */
export type Dw_Consumption_Var_Pop_Order_By = {
  Corrected?: Maybe<Order_By>;
  Raw?: Maybe<Order_By>;
  ReadingType?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_Consumption_Var_Samp_Fields = {
  __typename?: 'dw_Consumption_var_samp_fields';
  Corrected?: Maybe<Scalars['Float']>;
  Raw?: Maybe<Scalars['Float']>;
  ReadingType?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.Consumption" */
export type Dw_Consumption_Var_Samp_Order_By = {
  Corrected?: Maybe<Order_By>;
  Raw?: Maybe<Order_By>;
  ReadingType?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_Consumption_Variance_Fields = {
  __typename?: 'dw_Consumption_variance_fields';
  Corrected?: Maybe<Scalars['Float']>;
  Raw?: Maybe<Scalars['Float']>;
  ReadingType?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.Consumption" */
export type Dw_Consumption_Variance_Order_By = {
  Corrected?: Maybe<Order_By>;
  Raw?: Maybe<Order_By>;
  ReadingType?: Maybe<Order_By>;
};

/** columns and relationships of "dw.Contacts" */
export type Dw_Contacts = {
  __typename?: 'dw_Contacts';
  /** An array relationship */
  Accounts: Array<Dw_Accounts>;
  /** An aggregate relationship */
  Accounts_aggregate: Dw_Accounts_Aggregate;
  ContactId: Scalars['uuid'];
  /** An array relationship */
  ContactsRoles: Array<Dw_ContactsRole>;
  /** An aggregate relationship */
  ContactsRoles_aggregate: Dw_ContactsRole_Aggregate;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DisplayName: Scalars['String'];
  EmailAddress: Scalars['String'];
  FirstName: Scalars['String'];
  /** An array relationship */
  Histories: Array<Dw_History>;
  /** An aggregate relationship */
  Histories_aggregate: Dw_History_Aggregate;
  InvalidAttempts: Scalars['Int'];
  InvalidExpiry: Scalars['timestamp'];
  LastName: Scalars['String'];
  /** An array relationship */
  MLNotificationGroups: Array<Dw_MlNotificationGroup>;
  /** An aggregate relationship */
  MLNotificationGroups_aggregate: Dw_MlNotificationGroup_Aggregate;
  /** An array relationship */
  MLNotifications: Array<Dw_MlNotifications>;
  /** An array relationship */
  MLNotificationsMetadata: Array<Dw_MlNotificationsMetadata>;
  /** An aggregate relationship */
  MLNotificationsMetadata_aggregate: Dw_MlNotificationsMetadata_Aggregate;
  /** An aggregate relationship */
  MLNotifications_aggregate: Dw_MlNotifications_Aggregate;
  MfaSecret?: Maybe<Scalars['String']>;
  Mobile?: Maybe<Scalars['String']>;
  /** An array relationship */
  NotificationsLogs: Array<Dw_NotificationsLog>;
  /** An aggregate relationship */
  NotificationsLogs_aggregate: Dw_NotificationsLog_Aggregate;
  /** An array relationship */
  NotificationsVisibilities: Array<Dw_NotificationsVisibility>;
  /** An aggregate relationship */
  NotificationsVisibilities_aggregate: Dw_NotificationsVisibility_Aggregate;
  Password: Scalars['String'];
  Phone?: Maybe<Scalars['String']>;
  /** An array relationship */
  Projects: Array<Dw_Projects>;
  /** An aggregate relationship */
  Projects_aggregate: Dw_Projects_Aggregate;
  ResetCode?: Maybe<Scalars['String']>;
  ResetExpiry: Scalars['timestamp'];
  Salt: Scalars['String'];
  Salutation: Scalars['String'];
  /** An array relationship */
  Sessions: Array<Dw_Sessions>;
  /** An aggregate relationship */
  Sessions_aggregate: Dw_Sessions_Aggregate;
  Status: Scalars['smallint'];
  Type: Scalars['smallint'];
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  /** An array relationship */
  accountsByAccmgrcontactid: Array<Dw_Accounts>;
  /** An aggregate relationship */
  accountsByAccmgrcontactid_aggregate: Dw_Accounts_Aggregate;
  /** An array relationship */
  mLNotificationsMetadataByReportercontactid: Array<Dw_MlNotificationsMetadata>;
  /** An aggregate relationship */
  mLNotificationsMetadataByReportercontactid_aggregate: Dw_MlNotificationsMetadata_Aggregate;
  /** An array relationship */
  projectsByReportercontactid: Array<Dw_Projects>;
  /** An aggregate relationship */
  projectsByReportercontactid_aggregate: Dw_Projects_Aggregate;
};


/** columns and relationships of "dw.Contacts" */
export type Dw_ContactsAccountsArgs = {
  distinct_on?: Maybe<Array<Dw_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Accounts_Order_By>>;
  where?: Maybe<Dw_Accounts_Bool_Exp>;
};


/** columns and relationships of "dw.Contacts" */
export type Dw_ContactsAccounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Accounts_Order_By>>;
  where?: Maybe<Dw_Accounts_Bool_Exp>;
};


/** columns and relationships of "dw.Contacts" */
export type Dw_ContactsContactsRolesArgs = {
  distinct_on?: Maybe<Array<Dw_ContactsRole_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ContactsRole_Order_By>>;
  where?: Maybe<Dw_ContactsRole_Bool_Exp>;
};


/** columns and relationships of "dw.Contacts" */
export type Dw_ContactsContactsRoles_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_ContactsRole_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ContactsRole_Order_By>>;
  where?: Maybe<Dw_ContactsRole_Bool_Exp>;
};


/** columns and relationships of "dw.Contacts" */
export type Dw_ContactsHistoriesArgs = {
  distinct_on?: Maybe<Array<Dw_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_History_Order_By>>;
  where?: Maybe<Dw_History_Bool_Exp>;
};


/** columns and relationships of "dw.Contacts" */
export type Dw_ContactsHistories_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_History_Order_By>>;
  where?: Maybe<Dw_History_Bool_Exp>;
};


/** columns and relationships of "dw.Contacts" */
export type Dw_ContactsMlNotificationGroupsArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotificationGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotificationGroup_Order_By>>;
  where?: Maybe<Dw_MlNotificationGroup_Bool_Exp>;
};


/** columns and relationships of "dw.Contacts" */
export type Dw_ContactsMlNotificationGroups_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotificationGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotificationGroup_Order_By>>;
  where?: Maybe<Dw_MlNotificationGroup_Bool_Exp>;
};


/** columns and relationships of "dw.Contacts" */
export type Dw_ContactsMlNotificationsArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotifications_Order_By>>;
  where?: Maybe<Dw_MlNotifications_Bool_Exp>;
};


/** columns and relationships of "dw.Contacts" */
export type Dw_ContactsMlNotificationsMetadataArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotificationsMetadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotificationsMetadata_Order_By>>;
  where?: Maybe<Dw_MlNotificationsMetadata_Bool_Exp>;
};


/** columns and relationships of "dw.Contacts" */
export type Dw_ContactsMlNotificationsMetadata_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotificationsMetadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotificationsMetadata_Order_By>>;
  where?: Maybe<Dw_MlNotificationsMetadata_Bool_Exp>;
};


/** columns and relationships of "dw.Contacts" */
export type Dw_ContactsMlNotifications_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotifications_Order_By>>;
  where?: Maybe<Dw_MlNotifications_Bool_Exp>;
};


/** columns and relationships of "dw.Contacts" */
export type Dw_ContactsNotificationsLogsArgs = {
  distinct_on?: Maybe<Array<Dw_NotificationsLog_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_NotificationsLog_Order_By>>;
  where?: Maybe<Dw_NotificationsLog_Bool_Exp>;
};


/** columns and relationships of "dw.Contacts" */
export type Dw_ContactsNotificationsLogs_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_NotificationsLog_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_NotificationsLog_Order_By>>;
  where?: Maybe<Dw_NotificationsLog_Bool_Exp>;
};


/** columns and relationships of "dw.Contacts" */
export type Dw_ContactsNotificationsVisibilitiesArgs = {
  distinct_on?: Maybe<Array<Dw_NotificationsVisibility_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_NotificationsVisibility_Order_By>>;
  where?: Maybe<Dw_NotificationsVisibility_Bool_Exp>;
};


/** columns and relationships of "dw.Contacts" */
export type Dw_ContactsNotificationsVisibilities_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_NotificationsVisibility_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_NotificationsVisibility_Order_By>>;
  where?: Maybe<Dw_NotificationsVisibility_Bool_Exp>;
};


/** columns and relationships of "dw.Contacts" */
export type Dw_ContactsProjectsArgs = {
  distinct_on?: Maybe<Array<Dw_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Projects_Order_By>>;
  where?: Maybe<Dw_Projects_Bool_Exp>;
};


/** columns and relationships of "dw.Contacts" */
export type Dw_ContactsProjects_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Projects_Order_By>>;
  where?: Maybe<Dw_Projects_Bool_Exp>;
};


/** columns and relationships of "dw.Contacts" */
export type Dw_ContactsSessionsArgs = {
  distinct_on?: Maybe<Array<Dw_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Sessions_Order_By>>;
  where?: Maybe<Dw_Sessions_Bool_Exp>;
};


/** columns and relationships of "dw.Contacts" */
export type Dw_ContactsSessions_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Sessions_Order_By>>;
  where?: Maybe<Dw_Sessions_Bool_Exp>;
};


/** columns and relationships of "dw.Contacts" */
export type Dw_ContactsAccountsByAccmgrcontactidArgs = {
  distinct_on?: Maybe<Array<Dw_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Accounts_Order_By>>;
  where?: Maybe<Dw_Accounts_Bool_Exp>;
};


/** columns and relationships of "dw.Contacts" */
export type Dw_ContactsAccountsByAccmgrcontactid_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Accounts_Order_By>>;
  where?: Maybe<Dw_Accounts_Bool_Exp>;
};


/** columns and relationships of "dw.Contacts" */
export type Dw_ContactsMlNotificationsMetadataByReportercontactidArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotificationsMetadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotificationsMetadata_Order_By>>;
  where?: Maybe<Dw_MlNotificationsMetadata_Bool_Exp>;
};


/** columns and relationships of "dw.Contacts" */
export type Dw_ContactsMlNotificationsMetadataByReportercontactid_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotificationsMetadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotificationsMetadata_Order_By>>;
  where?: Maybe<Dw_MlNotificationsMetadata_Bool_Exp>;
};


/** columns and relationships of "dw.Contacts" */
export type Dw_ContactsProjectsByReportercontactidArgs = {
  distinct_on?: Maybe<Array<Dw_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Projects_Order_By>>;
  where?: Maybe<Dw_Projects_Bool_Exp>;
};


/** columns and relationships of "dw.Contacts" */
export type Dw_ContactsProjectsByReportercontactid_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Projects_Order_By>>;
  where?: Maybe<Dw_Projects_Bool_Exp>;
};

/** columns and relationships of "dw.ContactsRole" */
export type Dw_ContactsRole = {
  __typename?: 'dw_ContactsRole';
  /** An object relationship */
  Account: Dw_Accounts;
  AccountId: Scalars['uuid'];
  /** An object relationship */
  Contact: Dw_Contacts;
  ContactId: Scalars['uuid'];
  CreatedOn: Scalars['timestamp'];
  EmailAddress: Scalars['String'];
  Features: Scalars['Int'];
  Role: Scalars['smallint'];
  Status: Scalars['smallint'];
  UpdatedOn: Scalars['timestamp'];
};

/** aggregated selection of "dw.ContactsRole" */
export type Dw_ContactsRole_Aggregate = {
  __typename?: 'dw_ContactsRole_aggregate';
  aggregate?: Maybe<Dw_ContactsRole_Aggregate_Fields>;
  nodes: Array<Dw_ContactsRole>;
};

/** aggregate fields of "dw.ContactsRole" */
export type Dw_ContactsRole_Aggregate_Fields = {
  __typename?: 'dw_ContactsRole_aggregate_fields';
  avg?: Maybe<Dw_ContactsRole_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_ContactsRole_Max_Fields>;
  min?: Maybe<Dw_ContactsRole_Min_Fields>;
  stddev?: Maybe<Dw_ContactsRole_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_ContactsRole_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_ContactsRole_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_ContactsRole_Sum_Fields>;
  var_pop?: Maybe<Dw_ContactsRole_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_ContactsRole_Var_Samp_Fields>;
  variance?: Maybe<Dw_ContactsRole_Variance_Fields>;
};


/** aggregate fields of "dw.ContactsRole" */
export type Dw_ContactsRole_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_ContactsRole_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.ContactsRole" */
export type Dw_ContactsRole_Aggregate_Order_By = {
  avg?: Maybe<Dw_ContactsRole_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_ContactsRole_Max_Order_By>;
  min?: Maybe<Dw_ContactsRole_Min_Order_By>;
  stddev?: Maybe<Dw_ContactsRole_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_ContactsRole_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_ContactsRole_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_ContactsRole_Sum_Order_By>;
  var_pop?: Maybe<Dw_ContactsRole_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_ContactsRole_Var_Samp_Order_By>;
  variance?: Maybe<Dw_ContactsRole_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.ContactsRole" */
export type Dw_ContactsRole_Arr_Rel_Insert_Input = {
  data: Array<Dw_ContactsRole_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_ContactsRole_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_ContactsRole_Avg_Fields = {
  __typename?: 'dw_ContactsRole_avg_fields';
  Features?: Maybe<Scalars['Float']>;
  Role?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.ContactsRole" */
export type Dw_ContactsRole_Avg_Order_By = {
  Features?: Maybe<Order_By>;
  Role?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.ContactsRole". All fields are combined with a logical 'AND'. */
export type Dw_ContactsRole_Bool_Exp = {
  Account?: Maybe<Dw_Accounts_Bool_Exp>;
  AccountId?: Maybe<Uuid_Comparison_Exp>;
  Contact?: Maybe<Dw_Contacts_Bool_Exp>;
  ContactId?: Maybe<Uuid_Comparison_Exp>;
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  EmailAddress?: Maybe<String_Comparison_Exp>;
  Features?: Maybe<Int_Comparison_Exp>;
  Role?: Maybe<Smallint_Comparison_Exp>;
  Status?: Maybe<Smallint_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  _and?: Maybe<Array<Dw_ContactsRole_Bool_Exp>>;
  _not?: Maybe<Dw_ContactsRole_Bool_Exp>;
  _or?: Maybe<Array<Dw_ContactsRole_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.ContactsRole" */
export enum Dw_ContactsRole_Constraint {
  /** unique or primary key constraint */
  ContactsrolePk = 'contactsrole_pk'
}

/** input type for incrementing numeric columns in table "dw.ContactsRole" */
export type Dw_ContactsRole_Inc_Input = {
  Features?: Maybe<Scalars['Int']>;
  Role?: Maybe<Scalars['smallint']>;
  Status?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.ContactsRole" */
export type Dw_ContactsRole_Insert_Input = {
  Account?: Maybe<Dw_Accounts_Obj_Rel_Insert_Input>;
  AccountId?: Maybe<Scalars['uuid']>;
  Contact?: Maybe<Dw_Contacts_Obj_Rel_Insert_Input>;
  ContactId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  EmailAddress?: Maybe<Scalars['String']>;
  Features?: Maybe<Scalars['Int']>;
  Role?: Maybe<Scalars['smallint']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Dw_ContactsRole_Max_Fields = {
  __typename?: 'dw_ContactsRole_max_fields';
  AccountId?: Maybe<Scalars['uuid']>;
  ContactId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  EmailAddress?: Maybe<Scalars['String']>;
  Features?: Maybe<Scalars['Int']>;
  Role?: Maybe<Scalars['smallint']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "dw.ContactsRole" */
export type Dw_ContactsRole_Max_Order_By = {
  AccountId?: Maybe<Order_By>;
  ContactId?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  EmailAddress?: Maybe<Order_By>;
  Features?: Maybe<Order_By>;
  Role?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_ContactsRole_Min_Fields = {
  __typename?: 'dw_ContactsRole_min_fields';
  AccountId?: Maybe<Scalars['uuid']>;
  ContactId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  EmailAddress?: Maybe<Scalars['String']>;
  Features?: Maybe<Scalars['Int']>;
  Role?: Maybe<Scalars['smallint']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "dw.ContactsRole" */
export type Dw_ContactsRole_Min_Order_By = {
  AccountId?: Maybe<Order_By>;
  ContactId?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  EmailAddress?: Maybe<Order_By>;
  Features?: Maybe<Order_By>;
  Role?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.ContactsRole" */
export type Dw_ContactsRole_Mutation_Response = {
  __typename?: 'dw_ContactsRole_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_ContactsRole>;
};

/** on conflict condition type for table "dw.ContactsRole" */
export type Dw_ContactsRole_On_Conflict = {
  constraint: Dw_ContactsRole_Constraint;
  update_columns?: Array<Dw_ContactsRole_Update_Column>;
  where?: Maybe<Dw_ContactsRole_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.ContactsRole". */
export type Dw_ContactsRole_Order_By = {
  Account?: Maybe<Dw_Accounts_Order_By>;
  AccountId?: Maybe<Order_By>;
  Contact?: Maybe<Dw_Contacts_Order_By>;
  ContactId?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  EmailAddress?: Maybe<Order_By>;
  Features?: Maybe<Order_By>;
  Role?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** select columns of table "dw.ContactsRole" */
export enum Dw_ContactsRole_Select_Column {
  /** column name */
  AccountId = 'AccountId',
  /** column name */
  ContactId = 'ContactId',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  EmailAddress = 'EmailAddress',
  /** column name */
  Features = 'Features',
  /** column name */
  Role = 'Role',
  /** column name */
  Status = 'Status',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** input type for updating data in table "dw.ContactsRole" */
export type Dw_ContactsRole_Set_Input = {
  AccountId?: Maybe<Scalars['uuid']>;
  ContactId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  EmailAddress?: Maybe<Scalars['String']>;
  Features?: Maybe<Scalars['Int']>;
  Role?: Maybe<Scalars['smallint']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Dw_ContactsRole_Stddev_Fields = {
  __typename?: 'dw_ContactsRole_stddev_fields';
  Features?: Maybe<Scalars['Float']>;
  Role?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.ContactsRole" */
export type Dw_ContactsRole_Stddev_Order_By = {
  Features?: Maybe<Order_By>;
  Role?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_ContactsRole_Stddev_Pop_Fields = {
  __typename?: 'dw_ContactsRole_stddev_pop_fields';
  Features?: Maybe<Scalars['Float']>;
  Role?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.ContactsRole" */
export type Dw_ContactsRole_Stddev_Pop_Order_By = {
  Features?: Maybe<Order_By>;
  Role?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_ContactsRole_Stddev_Samp_Fields = {
  __typename?: 'dw_ContactsRole_stddev_samp_fields';
  Features?: Maybe<Scalars['Float']>;
  Role?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.ContactsRole" */
export type Dw_ContactsRole_Stddev_Samp_Order_By = {
  Features?: Maybe<Order_By>;
  Role?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_ContactsRole_Sum_Fields = {
  __typename?: 'dw_ContactsRole_sum_fields';
  Features?: Maybe<Scalars['Int']>;
  Role?: Maybe<Scalars['smallint']>;
  Status?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "dw.ContactsRole" */
export type Dw_ContactsRole_Sum_Order_By = {
  Features?: Maybe<Order_By>;
  Role?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** update columns of table "dw.ContactsRole" */
export enum Dw_ContactsRole_Update_Column {
  /** column name */
  AccountId = 'AccountId',
  /** column name */
  ContactId = 'ContactId',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  EmailAddress = 'EmailAddress',
  /** column name */
  Features = 'Features',
  /** column name */
  Role = 'Role',
  /** column name */
  Status = 'Status',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** aggregate var_pop on columns */
export type Dw_ContactsRole_Var_Pop_Fields = {
  __typename?: 'dw_ContactsRole_var_pop_fields';
  Features?: Maybe<Scalars['Float']>;
  Role?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.ContactsRole" */
export type Dw_ContactsRole_Var_Pop_Order_By = {
  Features?: Maybe<Order_By>;
  Role?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_ContactsRole_Var_Samp_Fields = {
  __typename?: 'dw_ContactsRole_var_samp_fields';
  Features?: Maybe<Scalars['Float']>;
  Role?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.ContactsRole" */
export type Dw_ContactsRole_Var_Samp_Order_By = {
  Features?: Maybe<Order_By>;
  Role?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_ContactsRole_Variance_Fields = {
  __typename?: 'dw_ContactsRole_variance_fields';
  Features?: Maybe<Scalars['Float']>;
  Role?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.ContactsRole" */
export type Dw_ContactsRole_Variance_Order_By = {
  Features?: Maybe<Order_By>;
  Role?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregated selection of "dw.Contacts" */
export type Dw_Contacts_Aggregate = {
  __typename?: 'dw_Contacts_aggregate';
  aggregate?: Maybe<Dw_Contacts_Aggregate_Fields>;
  nodes: Array<Dw_Contacts>;
};

/** aggregate fields of "dw.Contacts" */
export type Dw_Contacts_Aggregate_Fields = {
  __typename?: 'dw_Contacts_aggregate_fields';
  avg?: Maybe<Dw_Contacts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_Contacts_Max_Fields>;
  min?: Maybe<Dw_Contacts_Min_Fields>;
  stddev?: Maybe<Dw_Contacts_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_Contacts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_Contacts_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_Contacts_Sum_Fields>;
  var_pop?: Maybe<Dw_Contacts_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_Contacts_Var_Samp_Fields>;
  variance?: Maybe<Dw_Contacts_Variance_Fields>;
};


/** aggregate fields of "dw.Contacts" */
export type Dw_Contacts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_Contacts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dw_Contacts_Avg_Fields = {
  __typename?: 'dw_Contacts_avg_fields';
  InvalidAttempts?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dw.Contacts". All fields are combined with a logical 'AND'. */
export type Dw_Contacts_Bool_Exp = {
  Accounts?: Maybe<Dw_Accounts_Bool_Exp>;
  ContactId?: Maybe<Uuid_Comparison_Exp>;
  ContactsRoles?: Maybe<Dw_ContactsRole_Bool_Exp>;
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  DisplayName?: Maybe<String_Comparison_Exp>;
  EmailAddress?: Maybe<String_Comparison_Exp>;
  FirstName?: Maybe<String_Comparison_Exp>;
  Histories?: Maybe<Dw_History_Bool_Exp>;
  InvalidAttempts?: Maybe<Int_Comparison_Exp>;
  InvalidExpiry?: Maybe<Timestamp_Comparison_Exp>;
  LastName?: Maybe<String_Comparison_Exp>;
  MLNotificationGroups?: Maybe<Dw_MlNotificationGroup_Bool_Exp>;
  MLNotifications?: Maybe<Dw_MlNotifications_Bool_Exp>;
  MLNotificationsMetadata?: Maybe<Dw_MlNotificationsMetadata_Bool_Exp>;
  MfaSecret?: Maybe<String_Comparison_Exp>;
  Mobile?: Maybe<String_Comparison_Exp>;
  NotificationsLogs?: Maybe<Dw_NotificationsLog_Bool_Exp>;
  NotificationsVisibilities?: Maybe<Dw_NotificationsVisibility_Bool_Exp>;
  Password?: Maybe<String_Comparison_Exp>;
  Phone?: Maybe<String_Comparison_Exp>;
  Projects?: Maybe<Dw_Projects_Bool_Exp>;
  ResetCode?: Maybe<String_Comparison_Exp>;
  ResetExpiry?: Maybe<Timestamp_Comparison_Exp>;
  Salt?: Maybe<String_Comparison_Exp>;
  Salutation?: Maybe<String_Comparison_Exp>;
  Sessions?: Maybe<Dw_Sessions_Bool_Exp>;
  Status?: Maybe<Smallint_Comparison_Exp>;
  Type?: Maybe<Smallint_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  _and?: Maybe<Array<Dw_Contacts_Bool_Exp>>;
  _not?: Maybe<Dw_Contacts_Bool_Exp>;
  _or?: Maybe<Array<Dw_Contacts_Bool_Exp>>;
  accountsByAccmgrcontactid?: Maybe<Dw_Accounts_Bool_Exp>;
  mLNotificationsMetadataByReportercontactid?: Maybe<Dw_MlNotificationsMetadata_Bool_Exp>;
  projectsByReportercontactid?: Maybe<Dw_Projects_Bool_Exp>;
};

/** unique or primary key constraints on table "dw.Contacts" */
export enum Dw_Contacts_Constraint {
  /** unique or primary key constraint */
  UsersPk = 'users_pk',
  /** unique or primary key constraint */
  UsersUseridUindex = 'users_userid_uindex'
}

/** input type for incrementing numeric columns in table "dw.Contacts" */
export type Dw_Contacts_Inc_Input = {
  InvalidAttempts?: Maybe<Scalars['Int']>;
  Status?: Maybe<Scalars['smallint']>;
  Type?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.Contacts" */
export type Dw_Contacts_Insert_Input = {
  Accounts?: Maybe<Dw_Accounts_Arr_Rel_Insert_Input>;
  ContactId?: Maybe<Scalars['uuid']>;
  ContactsRoles?: Maybe<Dw_ContactsRole_Arr_Rel_Insert_Input>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DisplayName?: Maybe<Scalars['String']>;
  EmailAddress?: Maybe<Scalars['String']>;
  FirstName?: Maybe<Scalars['String']>;
  Histories?: Maybe<Dw_History_Arr_Rel_Insert_Input>;
  InvalidAttempts?: Maybe<Scalars['Int']>;
  InvalidExpiry?: Maybe<Scalars['timestamp']>;
  LastName?: Maybe<Scalars['String']>;
  MLNotificationGroups?: Maybe<Dw_MlNotificationGroup_Arr_Rel_Insert_Input>;
  MLNotifications?: Maybe<Dw_MlNotifications_Arr_Rel_Insert_Input>;
  MLNotificationsMetadata?: Maybe<Dw_MlNotificationsMetadata_Arr_Rel_Insert_Input>;
  MfaSecret?: Maybe<Scalars['String']>;
  Mobile?: Maybe<Scalars['String']>;
  NotificationsLogs?: Maybe<Dw_NotificationsLog_Arr_Rel_Insert_Input>;
  NotificationsVisibilities?: Maybe<Dw_NotificationsVisibility_Arr_Rel_Insert_Input>;
  Password?: Maybe<Scalars['String']>;
  Phone?: Maybe<Scalars['String']>;
  Projects?: Maybe<Dw_Projects_Arr_Rel_Insert_Input>;
  ResetCode?: Maybe<Scalars['String']>;
  ResetExpiry?: Maybe<Scalars['timestamp']>;
  Salt?: Maybe<Scalars['String']>;
  Salutation?: Maybe<Scalars['String']>;
  Sessions?: Maybe<Dw_Sessions_Arr_Rel_Insert_Input>;
  Status?: Maybe<Scalars['smallint']>;
  Type?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  accountsByAccmgrcontactid?: Maybe<Dw_Accounts_Arr_Rel_Insert_Input>;
  mLNotificationsMetadataByReportercontactid?: Maybe<Dw_MlNotificationsMetadata_Arr_Rel_Insert_Input>;
  projectsByReportercontactid?: Maybe<Dw_Projects_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Dw_Contacts_Max_Fields = {
  __typename?: 'dw_Contacts_max_fields';
  ContactId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DisplayName?: Maybe<Scalars['String']>;
  EmailAddress?: Maybe<Scalars['String']>;
  FirstName?: Maybe<Scalars['String']>;
  InvalidAttempts?: Maybe<Scalars['Int']>;
  InvalidExpiry?: Maybe<Scalars['timestamp']>;
  LastName?: Maybe<Scalars['String']>;
  MfaSecret?: Maybe<Scalars['String']>;
  Mobile?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  Phone?: Maybe<Scalars['String']>;
  ResetCode?: Maybe<Scalars['String']>;
  ResetExpiry?: Maybe<Scalars['timestamp']>;
  Salt?: Maybe<Scalars['String']>;
  Salutation?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['smallint']>;
  Type?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Dw_Contacts_Min_Fields = {
  __typename?: 'dw_Contacts_min_fields';
  ContactId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DisplayName?: Maybe<Scalars['String']>;
  EmailAddress?: Maybe<Scalars['String']>;
  FirstName?: Maybe<Scalars['String']>;
  InvalidAttempts?: Maybe<Scalars['Int']>;
  InvalidExpiry?: Maybe<Scalars['timestamp']>;
  LastName?: Maybe<Scalars['String']>;
  MfaSecret?: Maybe<Scalars['String']>;
  Mobile?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  Phone?: Maybe<Scalars['String']>;
  ResetCode?: Maybe<Scalars['String']>;
  ResetExpiry?: Maybe<Scalars['timestamp']>;
  Salt?: Maybe<Scalars['String']>;
  Salutation?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['smallint']>;
  Type?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "dw.Contacts" */
export type Dw_Contacts_Mutation_Response = {
  __typename?: 'dw_Contacts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_Contacts>;
};

/** input type for inserting object relation for remote table "dw.Contacts" */
export type Dw_Contacts_Obj_Rel_Insert_Input = {
  data: Dw_Contacts_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_Contacts_On_Conflict>;
};

/** on conflict condition type for table "dw.Contacts" */
export type Dw_Contacts_On_Conflict = {
  constraint: Dw_Contacts_Constraint;
  update_columns?: Array<Dw_Contacts_Update_Column>;
  where?: Maybe<Dw_Contacts_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.Contacts". */
export type Dw_Contacts_Order_By = {
  Accounts_aggregate?: Maybe<Dw_Accounts_Aggregate_Order_By>;
  ContactId?: Maybe<Order_By>;
  ContactsRoles_aggregate?: Maybe<Dw_ContactsRole_Aggregate_Order_By>;
  CreatedOn?: Maybe<Order_By>;
  DisplayName?: Maybe<Order_By>;
  EmailAddress?: Maybe<Order_By>;
  FirstName?: Maybe<Order_By>;
  Histories_aggregate?: Maybe<Dw_History_Aggregate_Order_By>;
  InvalidAttempts?: Maybe<Order_By>;
  InvalidExpiry?: Maybe<Order_By>;
  LastName?: Maybe<Order_By>;
  MLNotificationGroups_aggregate?: Maybe<Dw_MlNotificationGroup_Aggregate_Order_By>;
  MLNotificationsMetadata_aggregate?: Maybe<Dw_MlNotificationsMetadata_Aggregate_Order_By>;
  MLNotifications_aggregate?: Maybe<Dw_MlNotifications_Aggregate_Order_By>;
  MfaSecret?: Maybe<Order_By>;
  Mobile?: Maybe<Order_By>;
  NotificationsLogs_aggregate?: Maybe<Dw_NotificationsLog_Aggregate_Order_By>;
  NotificationsVisibilities_aggregate?: Maybe<Dw_NotificationsVisibility_Aggregate_Order_By>;
  Password?: Maybe<Order_By>;
  Phone?: Maybe<Order_By>;
  Projects_aggregate?: Maybe<Dw_Projects_Aggregate_Order_By>;
  ResetCode?: Maybe<Order_By>;
  ResetExpiry?: Maybe<Order_By>;
  Salt?: Maybe<Order_By>;
  Salutation?: Maybe<Order_By>;
  Sessions_aggregate?: Maybe<Dw_Sessions_Aggregate_Order_By>;
  Status?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
  accountsByAccmgrcontactid_aggregate?: Maybe<Dw_Accounts_Aggregate_Order_By>;
  mLNotificationsMetadataByReportercontactid_aggregate?: Maybe<Dw_MlNotificationsMetadata_Aggregate_Order_By>;
  projectsByReportercontactid_aggregate?: Maybe<Dw_Projects_Aggregate_Order_By>;
};

/** primary key columns input for table: dw_Contacts */
export type Dw_Contacts_Pk_Columns_Input = {
  ContactId: Scalars['uuid'];
};

/** select columns of table "dw.Contacts" */
export enum Dw_Contacts_Select_Column {
  /** column name */
  ContactId = 'ContactId',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  DisplayName = 'DisplayName',
  /** column name */
  EmailAddress = 'EmailAddress',
  /** column name */
  FirstName = 'FirstName',
  /** column name */
  InvalidAttempts = 'InvalidAttempts',
  /** column name */
  InvalidExpiry = 'InvalidExpiry',
  /** column name */
  LastName = 'LastName',
  /** column name */
  MfaSecret = 'MfaSecret',
  /** column name */
  Mobile = 'Mobile',
  /** column name */
  Password = 'Password',
  /** column name */
  Phone = 'Phone',
  /** column name */
  ResetCode = 'ResetCode',
  /** column name */
  ResetExpiry = 'ResetExpiry',
  /** column name */
  Salt = 'Salt',
  /** column name */
  Salutation = 'Salutation',
  /** column name */
  Status = 'Status',
  /** column name */
  Type = 'Type',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** input type for updating data in table "dw.Contacts" */
export type Dw_Contacts_Set_Input = {
  ContactId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DisplayName?: Maybe<Scalars['String']>;
  EmailAddress?: Maybe<Scalars['String']>;
  FirstName?: Maybe<Scalars['String']>;
  InvalidAttempts?: Maybe<Scalars['Int']>;
  InvalidExpiry?: Maybe<Scalars['timestamp']>;
  LastName?: Maybe<Scalars['String']>;
  MfaSecret?: Maybe<Scalars['String']>;
  Mobile?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  Phone?: Maybe<Scalars['String']>;
  ResetCode?: Maybe<Scalars['String']>;
  ResetExpiry?: Maybe<Scalars['timestamp']>;
  Salt?: Maybe<Scalars['String']>;
  Salutation?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['smallint']>;
  Type?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Dw_Contacts_Stddev_Fields = {
  __typename?: 'dw_Contacts_stddev_fields';
  InvalidAttempts?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dw_Contacts_Stddev_Pop_Fields = {
  __typename?: 'dw_Contacts_stddev_pop_fields';
  InvalidAttempts?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dw_Contacts_Stddev_Samp_Fields = {
  __typename?: 'dw_Contacts_stddev_samp_fields';
  InvalidAttempts?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Dw_Contacts_Sum_Fields = {
  __typename?: 'dw_Contacts_sum_fields';
  InvalidAttempts?: Maybe<Scalars['Int']>;
  Status?: Maybe<Scalars['smallint']>;
  Type?: Maybe<Scalars['smallint']>;
};

/** update columns of table "dw.Contacts" */
export enum Dw_Contacts_Update_Column {
  /** column name */
  ContactId = 'ContactId',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  DisplayName = 'DisplayName',
  /** column name */
  EmailAddress = 'EmailAddress',
  /** column name */
  FirstName = 'FirstName',
  /** column name */
  InvalidAttempts = 'InvalidAttempts',
  /** column name */
  InvalidExpiry = 'InvalidExpiry',
  /** column name */
  LastName = 'LastName',
  /** column name */
  MfaSecret = 'MfaSecret',
  /** column name */
  Mobile = 'Mobile',
  /** column name */
  Password = 'Password',
  /** column name */
  Phone = 'Phone',
  /** column name */
  ResetCode = 'ResetCode',
  /** column name */
  ResetExpiry = 'ResetExpiry',
  /** column name */
  Salt = 'Salt',
  /** column name */
  Salutation = 'Salutation',
  /** column name */
  Status = 'Status',
  /** column name */
  Type = 'Type',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** aggregate var_pop on columns */
export type Dw_Contacts_Var_Pop_Fields = {
  __typename?: 'dw_Contacts_var_pop_fields';
  InvalidAttempts?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dw_Contacts_Var_Samp_Fields = {
  __typename?: 'dw_Contacts_var_samp_fields';
  InvalidAttempts?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dw_Contacts_Variance_Fields = {
  __typename?: 'dw_Contacts_variance_fields';
  InvalidAttempts?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "dw.Dashboards" */
export type Dw_Dashboards = {
  __typename?: 'dw_Dashboards';
  /** An object relationship */
  Account: Dw_Accounts;
  AccountId: Scalars['uuid'];
  BackgroundColor: Scalars['_int4'];
  BackgroundImageL?: Maybe<Scalars['String']>;
  BackgroundImageP?: Maybe<Scalars['String']>;
  BackgroundOpacity?: Maybe<Scalars['Int']>;
  /** An object relationship */
  Building?: Maybe<Dw_Buildings>;
  BuildingId?: Maybe<Scalars['uuid']>;
  CarbonColor?: Maybe<Scalars['_int4']>;
  Code: Scalars['String'];
  CreatedOn: Scalars['timestamp'];
  DashboardId: Scalars['uuid'];
  /** An array relationship */
  DashboardsContents: Array<Dw_DashboardsContent>;
  /** An aggregate relationship */
  DashboardsContents_aggregate: Dw_DashboardsContent_Aggregate;
  ElectricityColor: Scalars['_int4'];
  FooterLeftImageL?: Maybe<Scalars['String']>;
  FooterLeftImageP?: Maybe<Scalars['String']>;
  FooterRightImageL?: Maybe<Scalars['String']>;
  FooterRightImageP?: Maybe<Scalars['String']>;
  GasColor: Scalars['_int4'];
  HasBackground: Scalars['Boolean'];
  Label: Scalars['String'];
  ShowCarbon?: Maybe<Scalars['Boolean']>;
  ShowElectricity?: Maybe<Scalars['Boolean']>;
  ShowGas?: Maybe<Scalars['Boolean']>;
  ShowWaste?: Maybe<Scalars['Boolean']>;
  ShowWater?: Maybe<Scalars['Boolean']>;
  ShowWeather: Scalars['Boolean'];
  Status: Scalars['smallint'];
  StructureId?: Maybe<Scalars['uuid']>;
  UpdatedOn: Scalars['timestamp'];
  WasteColor?: Maybe<Scalars['_int4']>;
  WaterColor: Scalars['_int4'];
  WeatherColor: Scalars['_int4'];
  WeatherId?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "dw.Dashboards" */
export type Dw_DashboardsDashboardsContentsArgs = {
  distinct_on?: Maybe<Array<Dw_DashboardsContent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_DashboardsContent_Order_By>>;
  where?: Maybe<Dw_DashboardsContent_Bool_Exp>;
};


/** columns and relationships of "dw.Dashboards" */
export type Dw_DashboardsDashboardsContents_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_DashboardsContent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_DashboardsContent_Order_By>>;
  where?: Maybe<Dw_DashboardsContent_Bool_Exp>;
};

/** columns and relationships of "dw.DashboardsContent" */
export type Dw_DashboardsContent = {
  __typename?: 'dw_DashboardsContent';
  Body?: Maybe<Scalars['String']>;
  Color: Scalars['_int4'];
  ContentId: Scalars['uuid'];
  CreatedOn: Scalars['timestamp'];
  /** An object relationship */
  Dashboard: Dw_Dashboards;
  DashboardId: Scalars['uuid'];
  /** An array relationship */
  DashboardsMetrics: Array<Dw_DashboardsMetric>;
  /** An aggregate relationship */
  DashboardsMetrics_aggregate: Dw_DashboardsMetric_Aggregate;
  Heading: Scalars['String'];
  Points: Scalars['_text'];
  UpdatedOn: Scalars['timestamp'];
};


/** columns and relationships of "dw.DashboardsContent" */
export type Dw_DashboardsContentDashboardsMetricsArgs = {
  distinct_on?: Maybe<Array<Dw_DashboardsMetric_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_DashboardsMetric_Order_By>>;
  where?: Maybe<Dw_DashboardsMetric_Bool_Exp>;
};


/** columns and relationships of "dw.DashboardsContent" */
export type Dw_DashboardsContentDashboardsMetrics_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_DashboardsMetric_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_DashboardsMetric_Order_By>>;
  where?: Maybe<Dw_DashboardsMetric_Bool_Exp>;
};

/** aggregated selection of "dw.DashboardsContent" */
export type Dw_DashboardsContent_Aggregate = {
  __typename?: 'dw_DashboardsContent_aggregate';
  aggregate?: Maybe<Dw_DashboardsContent_Aggregate_Fields>;
  nodes: Array<Dw_DashboardsContent>;
};

/** aggregate fields of "dw.DashboardsContent" */
export type Dw_DashboardsContent_Aggregate_Fields = {
  __typename?: 'dw_DashboardsContent_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Dw_DashboardsContent_Max_Fields>;
  min?: Maybe<Dw_DashboardsContent_Min_Fields>;
};


/** aggregate fields of "dw.DashboardsContent" */
export type Dw_DashboardsContent_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_DashboardsContent_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.DashboardsContent" */
export type Dw_DashboardsContent_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_DashboardsContent_Max_Order_By>;
  min?: Maybe<Dw_DashboardsContent_Min_Order_By>;
};

/** input type for inserting array relation for remote table "dw.DashboardsContent" */
export type Dw_DashboardsContent_Arr_Rel_Insert_Input = {
  data: Array<Dw_DashboardsContent_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_DashboardsContent_On_Conflict>;
};

/** Boolean expression to filter rows from the table "dw.DashboardsContent". All fields are combined with a logical 'AND'. */
export type Dw_DashboardsContent_Bool_Exp = {
  Body?: Maybe<String_Comparison_Exp>;
  Color?: Maybe<_Int4_Comparison_Exp>;
  ContentId?: Maybe<Uuid_Comparison_Exp>;
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  Dashboard?: Maybe<Dw_Dashboards_Bool_Exp>;
  DashboardId?: Maybe<Uuid_Comparison_Exp>;
  DashboardsMetrics?: Maybe<Dw_DashboardsMetric_Bool_Exp>;
  Heading?: Maybe<String_Comparison_Exp>;
  Points?: Maybe<_Text_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  _and?: Maybe<Array<Dw_DashboardsContent_Bool_Exp>>;
  _not?: Maybe<Dw_DashboardsContent_Bool_Exp>;
  _or?: Maybe<Array<Dw_DashboardsContent_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.DashboardsContent" */
export enum Dw_DashboardsContent_Constraint {
  /** unique or primary key constraint */
  DashboardscontentContentidUindex = 'dashboardscontent_contentid_uindex',
  /** unique or primary key constraint */
  DashboardscontentPk = 'dashboardscontent_pk'
}

/** input type for inserting data into table "dw.DashboardsContent" */
export type Dw_DashboardsContent_Insert_Input = {
  Body?: Maybe<Scalars['String']>;
  Color?: Maybe<Scalars['_int4']>;
  ContentId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  Dashboard?: Maybe<Dw_Dashboards_Obj_Rel_Insert_Input>;
  DashboardId?: Maybe<Scalars['uuid']>;
  DashboardsMetrics?: Maybe<Dw_DashboardsMetric_Arr_Rel_Insert_Input>;
  Heading?: Maybe<Scalars['String']>;
  Points?: Maybe<Scalars['_text']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Dw_DashboardsContent_Max_Fields = {
  __typename?: 'dw_DashboardsContent_max_fields';
  Body?: Maybe<Scalars['String']>;
  ContentId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DashboardId?: Maybe<Scalars['uuid']>;
  Heading?: Maybe<Scalars['String']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "dw.DashboardsContent" */
export type Dw_DashboardsContent_Max_Order_By = {
  Body?: Maybe<Order_By>;
  ContentId?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  DashboardId?: Maybe<Order_By>;
  Heading?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_DashboardsContent_Min_Fields = {
  __typename?: 'dw_DashboardsContent_min_fields';
  Body?: Maybe<Scalars['String']>;
  ContentId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DashboardId?: Maybe<Scalars['uuid']>;
  Heading?: Maybe<Scalars['String']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "dw.DashboardsContent" */
export type Dw_DashboardsContent_Min_Order_By = {
  Body?: Maybe<Order_By>;
  ContentId?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  DashboardId?: Maybe<Order_By>;
  Heading?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.DashboardsContent" */
export type Dw_DashboardsContent_Mutation_Response = {
  __typename?: 'dw_DashboardsContent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_DashboardsContent>;
};

/** input type for inserting object relation for remote table "dw.DashboardsContent" */
export type Dw_DashboardsContent_Obj_Rel_Insert_Input = {
  data: Dw_DashboardsContent_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_DashboardsContent_On_Conflict>;
};

/** on conflict condition type for table "dw.DashboardsContent" */
export type Dw_DashboardsContent_On_Conflict = {
  constraint: Dw_DashboardsContent_Constraint;
  update_columns?: Array<Dw_DashboardsContent_Update_Column>;
  where?: Maybe<Dw_DashboardsContent_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.DashboardsContent". */
export type Dw_DashboardsContent_Order_By = {
  Body?: Maybe<Order_By>;
  Color?: Maybe<Order_By>;
  ContentId?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  Dashboard?: Maybe<Dw_Dashboards_Order_By>;
  DashboardId?: Maybe<Order_By>;
  DashboardsMetrics_aggregate?: Maybe<Dw_DashboardsMetric_Aggregate_Order_By>;
  Heading?: Maybe<Order_By>;
  Points?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_DashboardsContent */
export type Dw_DashboardsContent_Pk_Columns_Input = {
  ContentId: Scalars['uuid'];
};

/** select columns of table "dw.DashboardsContent" */
export enum Dw_DashboardsContent_Select_Column {
  /** column name */
  Body = 'Body',
  /** column name */
  Color = 'Color',
  /** column name */
  ContentId = 'ContentId',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  DashboardId = 'DashboardId',
  /** column name */
  Heading = 'Heading',
  /** column name */
  Points = 'Points',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** input type for updating data in table "dw.DashboardsContent" */
export type Dw_DashboardsContent_Set_Input = {
  Body?: Maybe<Scalars['String']>;
  Color?: Maybe<Scalars['_int4']>;
  ContentId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DashboardId?: Maybe<Scalars['uuid']>;
  Heading?: Maybe<Scalars['String']>;
  Points?: Maybe<Scalars['_text']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "dw.DashboardsContent" */
export enum Dw_DashboardsContent_Update_Column {
  /** column name */
  Body = 'Body',
  /** column name */
  Color = 'Color',
  /** column name */
  ContentId = 'ContentId',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  DashboardId = 'DashboardId',
  /** column name */
  Heading = 'Heading',
  /** column name */
  Points = 'Points',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** columns and relationships of "dw.DashboardsMetric" */
export type Dw_DashboardsMetric = {
  __typename?: 'dw_DashboardsMetric';
  ContentId: Scalars['uuid'];
  /** An object relationship */
  DashboardsContent: Dw_DashboardsContent;
  Heading: Scalars['String'];
  Icon?: Maybe<Scalars['String']>;
  MetricId: Scalars['uuid'];
  Text?: Maybe<Scalars['String']>;
  Tiled: Scalars['Boolean'];
  Value: Scalars['Int'];
};

/** aggregated selection of "dw.DashboardsMetric" */
export type Dw_DashboardsMetric_Aggregate = {
  __typename?: 'dw_DashboardsMetric_aggregate';
  aggregate?: Maybe<Dw_DashboardsMetric_Aggregate_Fields>;
  nodes: Array<Dw_DashboardsMetric>;
};

/** aggregate fields of "dw.DashboardsMetric" */
export type Dw_DashboardsMetric_Aggregate_Fields = {
  __typename?: 'dw_DashboardsMetric_aggregate_fields';
  avg?: Maybe<Dw_DashboardsMetric_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_DashboardsMetric_Max_Fields>;
  min?: Maybe<Dw_DashboardsMetric_Min_Fields>;
  stddev?: Maybe<Dw_DashboardsMetric_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_DashboardsMetric_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_DashboardsMetric_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_DashboardsMetric_Sum_Fields>;
  var_pop?: Maybe<Dw_DashboardsMetric_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_DashboardsMetric_Var_Samp_Fields>;
  variance?: Maybe<Dw_DashboardsMetric_Variance_Fields>;
};


/** aggregate fields of "dw.DashboardsMetric" */
export type Dw_DashboardsMetric_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_DashboardsMetric_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.DashboardsMetric" */
export type Dw_DashboardsMetric_Aggregate_Order_By = {
  avg?: Maybe<Dw_DashboardsMetric_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_DashboardsMetric_Max_Order_By>;
  min?: Maybe<Dw_DashboardsMetric_Min_Order_By>;
  stddev?: Maybe<Dw_DashboardsMetric_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_DashboardsMetric_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_DashboardsMetric_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_DashboardsMetric_Sum_Order_By>;
  var_pop?: Maybe<Dw_DashboardsMetric_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_DashboardsMetric_Var_Samp_Order_By>;
  variance?: Maybe<Dw_DashboardsMetric_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.DashboardsMetric" */
export type Dw_DashboardsMetric_Arr_Rel_Insert_Input = {
  data: Array<Dw_DashboardsMetric_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_DashboardsMetric_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_DashboardsMetric_Avg_Fields = {
  __typename?: 'dw_DashboardsMetric_avg_fields';
  Value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.DashboardsMetric" */
export type Dw_DashboardsMetric_Avg_Order_By = {
  Value?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.DashboardsMetric". All fields are combined with a logical 'AND'. */
export type Dw_DashboardsMetric_Bool_Exp = {
  ContentId?: Maybe<Uuid_Comparison_Exp>;
  DashboardsContent?: Maybe<Dw_DashboardsContent_Bool_Exp>;
  Heading?: Maybe<String_Comparison_Exp>;
  Icon?: Maybe<String_Comparison_Exp>;
  MetricId?: Maybe<Uuid_Comparison_Exp>;
  Text?: Maybe<String_Comparison_Exp>;
  Tiled?: Maybe<Boolean_Comparison_Exp>;
  Value?: Maybe<Int_Comparison_Exp>;
  _and?: Maybe<Array<Dw_DashboardsMetric_Bool_Exp>>;
  _not?: Maybe<Dw_DashboardsMetric_Bool_Exp>;
  _or?: Maybe<Array<Dw_DashboardsMetric_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.DashboardsMetric" */
export enum Dw_DashboardsMetric_Constraint {
  /** unique or primary key constraint */
  DashboardsmetricMetricidUindex = 'dashboardsmetric_metricid_uindex',
  /** unique or primary key constraint */
  DashboardsmetricPk = 'dashboardsmetric_pk'
}

/** input type for incrementing numeric columns in table "dw.DashboardsMetric" */
export type Dw_DashboardsMetric_Inc_Input = {
  Value?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "dw.DashboardsMetric" */
export type Dw_DashboardsMetric_Insert_Input = {
  ContentId?: Maybe<Scalars['uuid']>;
  DashboardsContent?: Maybe<Dw_DashboardsContent_Obj_Rel_Insert_Input>;
  Heading?: Maybe<Scalars['String']>;
  Icon?: Maybe<Scalars['String']>;
  MetricId?: Maybe<Scalars['uuid']>;
  Text?: Maybe<Scalars['String']>;
  Tiled?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Dw_DashboardsMetric_Max_Fields = {
  __typename?: 'dw_DashboardsMetric_max_fields';
  ContentId?: Maybe<Scalars['uuid']>;
  Heading?: Maybe<Scalars['String']>;
  Icon?: Maybe<Scalars['String']>;
  MetricId?: Maybe<Scalars['uuid']>;
  Text?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "dw.DashboardsMetric" */
export type Dw_DashboardsMetric_Max_Order_By = {
  ContentId?: Maybe<Order_By>;
  Heading?: Maybe<Order_By>;
  Icon?: Maybe<Order_By>;
  MetricId?: Maybe<Order_By>;
  Text?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_DashboardsMetric_Min_Fields = {
  __typename?: 'dw_DashboardsMetric_min_fields';
  ContentId?: Maybe<Scalars['uuid']>;
  Heading?: Maybe<Scalars['String']>;
  Icon?: Maybe<Scalars['String']>;
  MetricId?: Maybe<Scalars['uuid']>;
  Text?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "dw.DashboardsMetric" */
export type Dw_DashboardsMetric_Min_Order_By = {
  ContentId?: Maybe<Order_By>;
  Heading?: Maybe<Order_By>;
  Icon?: Maybe<Order_By>;
  MetricId?: Maybe<Order_By>;
  Text?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.DashboardsMetric" */
export type Dw_DashboardsMetric_Mutation_Response = {
  __typename?: 'dw_DashboardsMetric_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_DashboardsMetric>;
};

/** on conflict condition type for table "dw.DashboardsMetric" */
export type Dw_DashboardsMetric_On_Conflict = {
  constraint: Dw_DashboardsMetric_Constraint;
  update_columns?: Array<Dw_DashboardsMetric_Update_Column>;
  where?: Maybe<Dw_DashboardsMetric_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.DashboardsMetric". */
export type Dw_DashboardsMetric_Order_By = {
  ContentId?: Maybe<Order_By>;
  DashboardsContent?: Maybe<Dw_DashboardsContent_Order_By>;
  Heading?: Maybe<Order_By>;
  Icon?: Maybe<Order_By>;
  MetricId?: Maybe<Order_By>;
  Text?: Maybe<Order_By>;
  Tiled?: Maybe<Order_By>;
  Value?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_DashboardsMetric */
export type Dw_DashboardsMetric_Pk_Columns_Input = {
  MetricId: Scalars['uuid'];
};

/** select columns of table "dw.DashboardsMetric" */
export enum Dw_DashboardsMetric_Select_Column {
  /** column name */
  ContentId = 'ContentId',
  /** column name */
  Heading = 'Heading',
  /** column name */
  Icon = 'Icon',
  /** column name */
  MetricId = 'MetricId',
  /** column name */
  Text = 'Text',
  /** column name */
  Tiled = 'Tiled',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "dw.DashboardsMetric" */
export type Dw_DashboardsMetric_Set_Input = {
  ContentId?: Maybe<Scalars['uuid']>;
  Heading?: Maybe<Scalars['String']>;
  Icon?: Maybe<Scalars['String']>;
  MetricId?: Maybe<Scalars['uuid']>;
  Text?: Maybe<Scalars['String']>;
  Tiled?: Maybe<Scalars['Boolean']>;
  Value?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Dw_DashboardsMetric_Stddev_Fields = {
  __typename?: 'dw_DashboardsMetric_stddev_fields';
  Value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.DashboardsMetric" */
export type Dw_DashboardsMetric_Stddev_Order_By = {
  Value?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_DashboardsMetric_Stddev_Pop_Fields = {
  __typename?: 'dw_DashboardsMetric_stddev_pop_fields';
  Value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.DashboardsMetric" */
export type Dw_DashboardsMetric_Stddev_Pop_Order_By = {
  Value?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_DashboardsMetric_Stddev_Samp_Fields = {
  __typename?: 'dw_DashboardsMetric_stddev_samp_fields';
  Value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.DashboardsMetric" */
export type Dw_DashboardsMetric_Stddev_Samp_Order_By = {
  Value?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_DashboardsMetric_Sum_Fields = {
  __typename?: 'dw_DashboardsMetric_sum_fields';
  Value?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "dw.DashboardsMetric" */
export type Dw_DashboardsMetric_Sum_Order_By = {
  Value?: Maybe<Order_By>;
};

/** update columns of table "dw.DashboardsMetric" */
export enum Dw_DashboardsMetric_Update_Column {
  /** column name */
  ContentId = 'ContentId',
  /** column name */
  Heading = 'Heading',
  /** column name */
  Icon = 'Icon',
  /** column name */
  MetricId = 'MetricId',
  /** column name */
  Text = 'Text',
  /** column name */
  Tiled = 'Tiled',
  /** column name */
  Value = 'Value'
}

/** aggregate var_pop on columns */
export type Dw_DashboardsMetric_Var_Pop_Fields = {
  __typename?: 'dw_DashboardsMetric_var_pop_fields';
  Value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.DashboardsMetric" */
export type Dw_DashboardsMetric_Var_Pop_Order_By = {
  Value?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_DashboardsMetric_Var_Samp_Fields = {
  __typename?: 'dw_DashboardsMetric_var_samp_fields';
  Value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.DashboardsMetric" */
export type Dw_DashboardsMetric_Var_Samp_Order_By = {
  Value?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_DashboardsMetric_Variance_Fields = {
  __typename?: 'dw_DashboardsMetric_variance_fields';
  Value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.DashboardsMetric" */
export type Dw_DashboardsMetric_Variance_Order_By = {
  Value?: Maybe<Order_By>;
};

/** aggregated selection of "dw.Dashboards" */
export type Dw_Dashboards_Aggregate = {
  __typename?: 'dw_Dashboards_aggregate';
  aggregate?: Maybe<Dw_Dashboards_Aggregate_Fields>;
  nodes: Array<Dw_Dashboards>;
};

/** aggregate fields of "dw.Dashboards" */
export type Dw_Dashboards_Aggregate_Fields = {
  __typename?: 'dw_Dashboards_aggregate_fields';
  avg?: Maybe<Dw_Dashboards_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_Dashboards_Max_Fields>;
  min?: Maybe<Dw_Dashboards_Min_Fields>;
  stddev?: Maybe<Dw_Dashboards_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_Dashboards_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_Dashboards_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_Dashboards_Sum_Fields>;
  var_pop?: Maybe<Dw_Dashboards_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_Dashboards_Var_Samp_Fields>;
  variance?: Maybe<Dw_Dashboards_Variance_Fields>;
};


/** aggregate fields of "dw.Dashboards" */
export type Dw_Dashboards_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_Dashboards_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.Dashboards" */
export type Dw_Dashboards_Aggregate_Order_By = {
  avg?: Maybe<Dw_Dashboards_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_Dashboards_Max_Order_By>;
  min?: Maybe<Dw_Dashboards_Min_Order_By>;
  stddev?: Maybe<Dw_Dashboards_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_Dashboards_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_Dashboards_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_Dashboards_Sum_Order_By>;
  var_pop?: Maybe<Dw_Dashboards_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_Dashboards_Var_Samp_Order_By>;
  variance?: Maybe<Dw_Dashboards_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.Dashboards" */
export type Dw_Dashboards_Arr_Rel_Insert_Input = {
  data: Array<Dw_Dashboards_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_Dashboards_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_Dashboards_Avg_Fields = {
  __typename?: 'dw_Dashboards_avg_fields';
  BackgroundOpacity?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.Dashboards" */
export type Dw_Dashboards_Avg_Order_By = {
  BackgroundOpacity?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.Dashboards". All fields are combined with a logical 'AND'. */
export type Dw_Dashboards_Bool_Exp = {
  Account?: Maybe<Dw_Accounts_Bool_Exp>;
  AccountId?: Maybe<Uuid_Comparison_Exp>;
  BackgroundColor?: Maybe<_Int4_Comparison_Exp>;
  BackgroundImageL?: Maybe<String_Comparison_Exp>;
  BackgroundImageP?: Maybe<String_Comparison_Exp>;
  BackgroundOpacity?: Maybe<Int_Comparison_Exp>;
  Building?: Maybe<Dw_Buildings_Bool_Exp>;
  BuildingId?: Maybe<Uuid_Comparison_Exp>;
  CarbonColor?: Maybe<_Int4_Comparison_Exp>;
  Code?: Maybe<String_Comparison_Exp>;
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  DashboardId?: Maybe<Uuid_Comparison_Exp>;
  DashboardsContents?: Maybe<Dw_DashboardsContent_Bool_Exp>;
  ElectricityColor?: Maybe<_Int4_Comparison_Exp>;
  FooterLeftImageL?: Maybe<String_Comparison_Exp>;
  FooterLeftImageP?: Maybe<String_Comparison_Exp>;
  FooterRightImageL?: Maybe<String_Comparison_Exp>;
  FooterRightImageP?: Maybe<String_Comparison_Exp>;
  GasColor?: Maybe<_Int4_Comparison_Exp>;
  HasBackground?: Maybe<Boolean_Comparison_Exp>;
  Label?: Maybe<String_Comparison_Exp>;
  ShowCarbon?: Maybe<Boolean_Comparison_Exp>;
  ShowElectricity?: Maybe<Boolean_Comparison_Exp>;
  ShowGas?: Maybe<Boolean_Comparison_Exp>;
  ShowWaste?: Maybe<Boolean_Comparison_Exp>;
  ShowWater?: Maybe<Boolean_Comparison_Exp>;
  ShowWeather?: Maybe<Boolean_Comparison_Exp>;
  Status?: Maybe<Smallint_Comparison_Exp>;
  StructureId?: Maybe<Uuid_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  WasteColor?: Maybe<_Int4_Comparison_Exp>;
  WaterColor?: Maybe<_Int4_Comparison_Exp>;
  WeatherColor?: Maybe<_Int4_Comparison_Exp>;
  WeatherId?: Maybe<Uuid_Comparison_Exp>;
  _and?: Maybe<Array<Dw_Dashboards_Bool_Exp>>;
  _not?: Maybe<Dw_Dashboards_Bool_Exp>;
  _or?: Maybe<Array<Dw_Dashboards_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.Dashboards" */
export enum Dw_Dashboards_Constraint {
  /** unique or primary key constraint */
  DashboardsCodeUindex = 'dashboards_code_uindex',
  /** unique or primary key constraint */
  DashboardsDashboardidUindex = 'dashboards_dashboardid_uindex',
  /** unique or primary key constraint */
  DashboardsPk = 'dashboards_pk'
}

/** input type for incrementing numeric columns in table "dw.Dashboards" */
export type Dw_Dashboards_Inc_Input = {
  BackgroundOpacity?: Maybe<Scalars['Int']>;
  Status?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.Dashboards" */
export type Dw_Dashboards_Insert_Input = {
  Account?: Maybe<Dw_Accounts_Obj_Rel_Insert_Input>;
  AccountId?: Maybe<Scalars['uuid']>;
  BackgroundColor?: Maybe<Scalars['_int4']>;
  BackgroundImageL?: Maybe<Scalars['String']>;
  BackgroundImageP?: Maybe<Scalars['String']>;
  BackgroundOpacity?: Maybe<Scalars['Int']>;
  Building?: Maybe<Dw_Buildings_Obj_Rel_Insert_Input>;
  BuildingId?: Maybe<Scalars['uuid']>;
  CarbonColor?: Maybe<Scalars['_int4']>;
  Code?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DashboardId?: Maybe<Scalars['uuid']>;
  DashboardsContents?: Maybe<Dw_DashboardsContent_Arr_Rel_Insert_Input>;
  ElectricityColor?: Maybe<Scalars['_int4']>;
  FooterLeftImageL?: Maybe<Scalars['String']>;
  FooterLeftImageP?: Maybe<Scalars['String']>;
  FooterRightImageL?: Maybe<Scalars['String']>;
  FooterRightImageP?: Maybe<Scalars['String']>;
  GasColor?: Maybe<Scalars['_int4']>;
  HasBackground?: Maybe<Scalars['Boolean']>;
  Label?: Maybe<Scalars['String']>;
  ShowCarbon?: Maybe<Scalars['Boolean']>;
  ShowElectricity?: Maybe<Scalars['Boolean']>;
  ShowGas?: Maybe<Scalars['Boolean']>;
  ShowWaste?: Maybe<Scalars['Boolean']>;
  ShowWater?: Maybe<Scalars['Boolean']>;
  ShowWeather?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Scalars['smallint']>;
  StructureId?: Maybe<Scalars['uuid']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  WasteColor?: Maybe<Scalars['_int4']>;
  WaterColor?: Maybe<Scalars['_int4']>;
  WeatherColor?: Maybe<Scalars['_int4']>;
  WeatherId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Dw_Dashboards_Max_Fields = {
  __typename?: 'dw_Dashboards_max_fields';
  AccountId?: Maybe<Scalars['uuid']>;
  BackgroundImageL?: Maybe<Scalars['String']>;
  BackgroundImageP?: Maybe<Scalars['String']>;
  BackgroundOpacity?: Maybe<Scalars['Int']>;
  BuildingId?: Maybe<Scalars['uuid']>;
  Code?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DashboardId?: Maybe<Scalars['uuid']>;
  FooterLeftImageL?: Maybe<Scalars['String']>;
  FooterLeftImageP?: Maybe<Scalars['String']>;
  FooterRightImageL?: Maybe<Scalars['String']>;
  FooterRightImageP?: Maybe<Scalars['String']>;
  Label?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['smallint']>;
  StructureId?: Maybe<Scalars['uuid']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  WeatherId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "dw.Dashboards" */
export type Dw_Dashboards_Max_Order_By = {
  AccountId?: Maybe<Order_By>;
  BackgroundImageL?: Maybe<Order_By>;
  BackgroundImageP?: Maybe<Order_By>;
  BackgroundOpacity?: Maybe<Order_By>;
  BuildingId?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  DashboardId?: Maybe<Order_By>;
  FooterLeftImageL?: Maybe<Order_By>;
  FooterLeftImageP?: Maybe<Order_By>;
  FooterRightImageL?: Maybe<Order_By>;
  FooterRightImageP?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  StructureId?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
  WeatherId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_Dashboards_Min_Fields = {
  __typename?: 'dw_Dashboards_min_fields';
  AccountId?: Maybe<Scalars['uuid']>;
  BackgroundImageL?: Maybe<Scalars['String']>;
  BackgroundImageP?: Maybe<Scalars['String']>;
  BackgroundOpacity?: Maybe<Scalars['Int']>;
  BuildingId?: Maybe<Scalars['uuid']>;
  Code?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DashboardId?: Maybe<Scalars['uuid']>;
  FooterLeftImageL?: Maybe<Scalars['String']>;
  FooterLeftImageP?: Maybe<Scalars['String']>;
  FooterRightImageL?: Maybe<Scalars['String']>;
  FooterRightImageP?: Maybe<Scalars['String']>;
  Label?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['smallint']>;
  StructureId?: Maybe<Scalars['uuid']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  WeatherId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "dw.Dashboards" */
export type Dw_Dashboards_Min_Order_By = {
  AccountId?: Maybe<Order_By>;
  BackgroundImageL?: Maybe<Order_By>;
  BackgroundImageP?: Maybe<Order_By>;
  BackgroundOpacity?: Maybe<Order_By>;
  BuildingId?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  DashboardId?: Maybe<Order_By>;
  FooterLeftImageL?: Maybe<Order_By>;
  FooterLeftImageP?: Maybe<Order_By>;
  FooterRightImageL?: Maybe<Order_By>;
  FooterRightImageP?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  StructureId?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
  WeatherId?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.Dashboards" */
export type Dw_Dashboards_Mutation_Response = {
  __typename?: 'dw_Dashboards_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_Dashboards>;
};

/** input type for inserting object relation for remote table "dw.Dashboards" */
export type Dw_Dashboards_Obj_Rel_Insert_Input = {
  data: Dw_Dashboards_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_Dashboards_On_Conflict>;
};

/** on conflict condition type for table "dw.Dashboards" */
export type Dw_Dashboards_On_Conflict = {
  constraint: Dw_Dashboards_Constraint;
  update_columns?: Array<Dw_Dashboards_Update_Column>;
  where?: Maybe<Dw_Dashboards_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.Dashboards". */
export type Dw_Dashboards_Order_By = {
  Account?: Maybe<Dw_Accounts_Order_By>;
  AccountId?: Maybe<Order_By>;
  BackgroundColor?: Maybe<Order_By>;
  BackgroundImageL?: Maybe<Order_By>;
  BackgroundImageP?: Maybe<Order_By>;
  BackgroundOpacity?: Maybe<Order_By>;
  Building?: Maybe<Dw_Buildings_Order_By>;
  BuildingId?: Maybe<Order_By>;
  CarbonColor?: Maybe<Order_By>;
  Code?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  DashboardId?: Maybe<Order_By>;
  DashboardsContents_aggregate?: Maybe<Dw_DashboardsContent_Aggregate_Order_By>;
  ElectricityColor?: Maybe<Order_By>;
  FooterLeftImageL?: Maybe<Order_By>;
  FooterLeftImageP?: Maybe<Order_By>;
  FooterRightImageL?: Maybe<Order_By>;
  FooterRightImageP?: Maybe<Order_By>;
  GasColor?: Maybe<Order_By>;
  HasBackground?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  ShowCarbon?: Maybe<Order_By>;
  ShowElectricity?: Maybe<Order_By>;
  ShowGas?: Maybe<Order_By>;
  ShowWaste?: Maybe<Order_By>;
  ShowWater?: Maybe<Order_By>;
  ShowWeather?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  StructureId?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
  WasteColor?: Maybe<Order_By>;
  WaterColor?: Maybe<Order_By>;
  WeatherColor?: Maybe<Order_By>;
  WeatherId?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_Dashboards */
export type Dw_Dashboards_Pk_Columns_Input = {
  DashboardId: Scalars['uuid'];
};

/** select columns of table "dw.Dashboards" */
export enum Dw_Dashboards_Select_Column {
  /** column name */
  AccountId = 'AccountId',
  /** column name */
  BackgroundColor = 'BackgroundColor',
  /** column name */
  BackgroundImageL = 'BackgroundImageL',
  /** column name */
  BackgroundImageP = 'BackgroundImageP',
  /** column name */
  BackgroundOpacity = 'BackgroundOpacity',
  /** column name */
  BuildingId = 'BuildingId',
  /** column name */
  CarbonColor = 'CarbonColor',
  /** column name */
  Code = 'Code',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  DashboardId = 'DashboardId',
  /** column name */
  ElectricityColor = 'ElectricityColor',
  /** column name */
  FooterLeftImageL = 'FooterLeftImageL',
  /** column name */
  FooterLeftImageP = 'FooterLeftImageP',
  /** column name */
  FooterRightImageL = 'FooterRightImageL',
  /** column name */
  FooterRightImageP = 'FooterRightImageP',
  /** column name */
  GasColor = 'GasColor',
  /** column name */
  HasBackground = 'HasBackground',
  /** column name */
  Label = 'Label',
  /** column name */
  ShowCarbon = 'ShowCarbon',
  /** column name */
  ShowElectricity = 'ShowElectricity',
  /** column name */
  ShowGas = 'ShowGas',
  /** column name */
  ShowWaste = 'ShowWaste',
  /** column name */
  ShowWater = 'ShowWater',
  /** column name */
  ShowWeather = 'ShowWeather',
  /** column name */
  Status = 'Status',
  /** column name */
  StructureId = 'StructureId',
  /** column name */
  UpdatedOn = 'UpdatedOn',
  /** column name */
  WasteColor = 'WasteColor',
  /** column name */
  WaterColor = 'WaterColor',
  /** column name */
  WeatherColor = 'WeatherColor',
  /** column name */
  WeatherId = 'WeatherId'
}

/** input type for updating data in table "dw.Dashboards" */
export type Dw_Dashboards_Set_Input = {
  AccountId?: Maybe<Scalars['uuid']>;
  BackgroundColor?: Maybe<Scalars['_int4']>;
  BackgroundImageL?: Maybe<Scalars['String']>;
  BackgroundImageP?: Maybe<Scalars['String']>;
  BackgroundOpacity?: Maybe<Scalars['Int']>;
  BuildingId?: Maybe<Scalars['uuid']>;
  CarbonColor?: Maybe<Scalars['_int4']>;
  Code?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DashboardId?: Maybe<Scalars['uuid']>;
  ElectricityColor?: Maybe<Scalars['_int4']>;
  FooterLeftImageL?: Maybe<Scalars['String']>;
  FooterLeftImageP?: Maybe<Scalars['String']>;
  FooterRightImageL?: Maybe<Scalars['String']>;
  FooterRightImageP?: Maybe<Scalars['String']>;
  GasColor?: Maybe<Scalars['_int4']>;
  HasBackground?: Maybe<Scalars['Boolean']>;
  Label?: Maybe<Scalars['String']>;
  ShowCarbon?: Maybe<Scalars['Boolean']>;
  ShowElectricity?: Maybe<Scalars['Boolean']>;
  ShowGas?: Maybe<Scalars['Boolean']>;
  ShowWaste?: Maybe<Scalars['Boolean']>;
  ShowWater?: Maybe<Scalars['Boolean']>;
  ShowWeather?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Scalars['smallint']>;
  StructureId?: Maybe<Scalars['uuid']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  WasteColor?: Maybe<Scalars['_int4']>;
  WaterColor?: Maybe<Scalars['_int4']>;
  WeatherColor?: Maybe<Scalars['_int4']>;
  WeatherId?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Dw_Dashboards_Stddev_Fields = {
  __typename?: 'dw_Dashboards_stddev_fields';
  BackgroundOpacity?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.Dashboards" */
export type Dw_Dashboards_Stddev_Order_By = {
  BackgroundOpacity?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_Dashboards_Stddev_Pop_Fields = {
  __typename?: 'dw_Dashboards_stddev_pop_fields';
  BackgroundOpacity?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.Dashboards" */
export type Dw_Dashboards_Stddev_Pop_Order_By = {
  BackgroundOpacity?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_Dashboards_Stddev_Samp_Fields = {
  __typename?: 'dw_Dashboards_stddev_samp_fields';
  BackgroundOpacity?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.Dashboards" */
export type Dw_Dashboards_Stddev_Samp_Order_By = {
  BackgroundOpacity?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_Dashboards_Sum_Fields = {
  __typename?: 'dw_Dashboards_sum_fields';
  BackgroundOpacity?: Maybe<Scalars['Int']>;
  Status?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "dw.Dashboards" */
export type Dw_Dashboards_Sum_Order_By = {
  BackgroundOpacity?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** update columns of table "dw.Dashboards" */
export enum Dw_Dashboards_Update_Column {
  /** column name */
  AccountId = 'AccountId',
  /** column name */
  BackgroundColor = 'BackgroundColor',
  /** column name */
  BackgroundImageL = 'BackgroundImageL',
  /** column name */
  BackgroundImageP = 'BackgroundImageP',
  /** column name */
  BackgroundOpacity = 'BackgroundOpacity',
  /** column name */
  BuildingId = 'BuildingId',
  /** column name */
  CarbonColor = 'CarbonColor',
  /** column name */
  Code = 'Code',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  DashboardId = 'DashboardId',
  /** column name */
  ElectricityColor = 'ElectricityColor',
  /** column name */
  FooterLeftImageL = 'FooterLeftImageL',
  /** column name */
  FooterLeftImageP = 'FooterLeftImageP',
  /** column name */
  FooterRightImageL = 'FooterRightImageL',
  /** column name */
  FooterRightImageP = 'FooterRightImageP',
  /** column name */
  GasColor = 'GasColor',
  /** column name */
  HasBackground = 'HasBackground',
  /** column name */
  Label = 'Label',
  /** column name */
  ShowCarbon = 'ShowCarbon',
  /** column name */
  ShowElectricity = 'ShowElectricity',
  /** column name */
  ShowGas = 'ShowGas',
  /** column name */
  ShowWaste = 'ShowWaste',
  /** column name */
  ShowWater = 'ShowWater',
  /** column name */
  ShowWeather = 'ShowWeather',
  /** column name */
  Status = 'Status',
  /** column name */
  StructureId = 'StructureId',
  /** column name */
  UpdatedOn = 'UpdatedOn',
  /** column name */
  WasteColor = 'WasteColor',
  /** column name */
  WaterColor = 'WaterColor',
  /** column name */
  WeatherColor = 'WeatherColor',
  /** column name */
  WeatherId = 'WeatherId'
}

/** aggregate var_pop on columns */
export type Dw_Dashboards_Var_Pop_Fields = {
  __typename?: 'dw_Dashboards_var_pop_fields';
  BackgroundOpacity?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.Dashboards" */
export type Dw_Dashboards_Var_Pop_Order_By = {
  BackgroundOpacity?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_Dashboards_Var_Samp_Fields = {
  __typename?: 'dw_Dashboards_var_samp_fields';
  BackgroundOpacity?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.Dashboards" */
export type Dw_Dashboards_Var_Samp_Order_By = {
  BackgroundOpacity?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_Dashboards_Variance_Fields = {
  __typename?: 'dw_Dashboards_variance_fields';
  BackgroundOpacity?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.Dashboards" */
export type Dw_Dashboards_Variance_Order_By = {
  BackgroundOpacity?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** columns and relationships of "dw.Documents" */
export type Dw_Documents = {
  __typename?: 'dw_Documents';
  AccountId: Scalars['uuid'];
  ContactId: Scalars['uuid'];
  CreatedOn: Scalars['timestamp'];
  DocumentId: Scalars['uuid'];
  FileName: Scalars['String'];
  Size: Scalars['Int'];
  UpdatedOn: Scalars['timestamp'];
};

/** aggregated selection of "dw.Documents" */
export type Dw_Documents_Aggregate = {
  __typename?: 'dw_Documents_aggregate';
  aggregate?: Maybe<Dw_Documents_Aggregate_Fields>;
  nodes: Array<Dw_Documents>;
};

/** aggregate fields of "dw.Documents" */
export type Dw_Documents_Aggregate_Fields = {
  __typename?: 'dw_Documents_aggregate_fields';
  avg?: Maybe<Dw_Documents_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_Documents_Max_Fields>;
  min?: Maybe<Dw_Documents_Min_Fields>;
  stddev?: Maybe<Dw_Documents_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_Documents_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_Documents_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_Documents_Sum_Fields>;
  var_pop?: Maybe<Dw_Documents_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_Documents_Var_Samp_Fields>;
  variance?: Maybe<Dw_Documents_Variance_Fields>;
};


/** aggregate fields of "dw.Documents" */
export type Dw_Documents_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_Documents_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dw_Documents_Avg_Fields = {
  __typename?: 'dw_Documents_avg_fields';
  Size?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dw.Documents". All fields are combined with a logical 'AND'. */
export type Dw_Documents_Bool_Exp = {
  AccountId?: Maybe<Uuid_Comparison_Exp>;
  ContactId?: Maybe<Uuid_Comparison_Exp>;
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  DocumentId?: Maybe<Uuid_Comparison_Exp>;
  FileName?: Maybe<String_Comparison_Exp>;
  Size?: Maybe<Int_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  _and?: Maybe<Array<Dw_Documents_Bool_Exp>>;
  _not?: Maybe<Dw_Documents_Bool_Exp>;
  _or?: Maybe<Array<Dw_Documents_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.Documents" */
export enum Dw_Documents_Constraint {
  /** unique or primary key constraint */
  DocumentsDocumentidUindex = 'documents_documentid_uindex',
  /** unique or primary key constraint */
  DocumentsPk = 'documents_pk'
}

/** input type for incrementing numeric columns in table "dw.Documents" */
export type Dw_Documents_Inc_Input = {
  Size?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "dw.Documents" */
export type Dw_Documents_Insert_Input = {
  AccountId?: Maybe<Scalars['uuid']>;
  ContactId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DocumentId?: Maybe<Scalars['uuid']>;
  FileName?: Maybe<Scalars['String']>;
  Size?: Maybe<Scalars['Int']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Dw_Documents_Max_Fields = {
  __typename?: 'dw_Documents_max_fields';
  AccountId?: Maybe<Scalars['uuid']>;
  ContactId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DocumentId?: Maybe<Scalars['uuid']>;
  FileName?: Maybe<Scalars['String']>;
  Size?: Maybe<Scalars['Int']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Dw_Documents_Min_Fields = {
  __typename?: 'dw_Documents_min_fields';
  AccountId?: Maybe<Scalars['uuid']>;
  ContactId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DocumentId?: Maybe<Scalars['uuid']>;
  FileName?: Maybe<Scalars['String']>;
  Size?: Maybe<Scalars['Int']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "dw.Documents" */
export type Dw_Documents_Mutation_Response = {
  __typename?: 'dw_Documents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_Documents>;
};

/** on conflict condition type for table "dw.Documents" */
export type Dw_Documents_On_Conflict = {
  constraint: Dw_Documents_Constraint;
  update_columns?: Array<Dw_Documents_Update_Column>;
  where?: Maybe<Dw_Documents_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.Documents". */
export type Dw_Documents_Order_By = {
  AccountId?: Maybe<Order_By>;
  ContactId?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  DocumentId?: Maybe<Order_By>;
  FileName?: Maybe<Order_By>;
  Size?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_Documents */
export type Dw_Documents_Pk_Columns_Input = {
  DocumentId: Scalars['uuid'];
};

/** select columns of table "dw.Documents" */
export enum Dw_Documents_Select_Column {
  /** column name */
  AccountId = 'AccountId',
  /** column name */
  ContactId = 'ContactId',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  DocumentId = 'DocumentId',
  /** column name */
  FileName = 'FileName',
  /** column name */
  Size = 'Size',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** input type for updating data in table "dw.Documents" */
export type Dw_Documents_Set_Input = {
  AccountId?: Maybe<Scalars['uuid']>;
  ContactId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DocumentId?: Maybe<Scalars['uuid']>;
  FileName?: Maybe<Scalars['String']>;
  Size?: Maybe<Scalars['Int']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Dw_Documents_Stddev_Fields = {
  __typename?: 'dw_Documents_stddev_fields';
  Size?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dw_Documents_Stddev_Pop_Fields = {
  __typename?: 'dw_Documents_stddev_pop_fields';
  Size?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dw_Documents_Stddev_Samp_Fields = {
  __typename?: 'dw_Documents_stddev_samp_fields';
  Size?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Dw_Documents_Sum_Fields = {
  __typename?: 'dw_Documents_sum_fields';
  Size?: Maybe<Scalars['Int']>;
};

/** update columns of table "dw.Documents" */
export enum Dw_Documents_Update_Column {
  /** column name */
  AccountId = 'AccountId',
  /** column name */
  ContactId = 'ContactId',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  DocumentId = 'DocumentId',
  /** column name */
  FileName = 'FileName',
  /** column name */
  Size = 'Size',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** aggregate var_pop on columns */
export type Dw_Documents_Var_Pop_Fields = {
  __typename?: 'dw_Documents_var_pop_fields';
  Size?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dw_Documents_Var_Samp_Fields = {
  __typename?: 'dw_Documents_var_samp_fields';
  Size?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dw_Documents_Variance_Fields = {
  __typename?: 'dw_Documents_variance_fields';
  Size?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "dw.EmissionFactorCategories" */
export type Dw_EmissionFactorCategories = {
  __typename?: 'dw_EmissionFactorCategories';
  CategoryId: Scalars['uuid'];
  CreatedOn: Scalars['timestamp'];
  /** An array relationship */
  EmissionFactorSources: Array<Dw_EmissionFactorSource>;
  /** An aggregate relationship */
  EmissionFactorSources_aggregate: Dw_EmissionFactorSource_Aggregate;
  Label: Scalars['String'];
  Scope: Scalars['smallint'];
  Sequential: Scalars['Int'];
  UpdatedOn: Scalars['timestamp'];
};


/** columns and relationships of "dw.EmissionFactorCategories" */
export type Dw_EmissionFactorCategoriesEmissionFactorSourcesArgs = {
  distinct_on?: Maybe<Array<Dw_EmissionFactorSource_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_EmissionFactorSource_Order_By>>;
  where?: Maybe<Dw_EmissionFactorSource_Bool_Exp>;
};


/** columns and relationships of "dw.EmissionFactorCategories" */
export type Dw_EmissionFactorCategoriesEmissionFactorSources_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_EmissionFactorSource_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_EmissionFactorSource_Order_By>>;
  where?: Maybe<Dw_EmissionFactorSource_Bool_Exp>;
};

/** aggregated selection of "dw.EmissionFactorCategories" */
export type Dw_EmissionFactorCategories_Aggregate = {
  __typename?: 'dw_EmissionFactorCategories_aggregate';
  aggregate?: Maybe<Dw_EmissionFactorCategories_Aggregate_Fields>;
  nodes: Array<Dw_EmissionFactorCategories>;
};

/** aggregate fields of "dw.EmissionFactorCategories" */
export type Dw_EmissionFactorCategories_Aggregate_Fields = {
  __typename?: 'dw_EmissionFactorCategories_aggregate_fields';
  avg?: Maybe<Dw_EmissionFactorCategories_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_EmissionFactorCategories_Max_Fields>;
  min?: Maybe<Dw_EmissionFactorCategories_Min_Fields>;
  stddev?: Maybe<Dw_EmissionFactorCategories_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_EmissionFactorCategories_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_EmissionFactorCategories_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_EmissionFactorCategories_Sum_Fields>;
  var_pop?: Maybe<Dw_EmissionFactorCategories_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_EmissionFactorCategories_Var_Samp_Fields>;
  variance?: Maybe<Dw_EmissionFactorCategories_Variance_Fields>;
};


/** aggregate fields of "dw.EmissionFactorCategories" */
export type Dw_EmissionFactorCategories_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_EmissionFactorCategories_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dw_EmissionFactorCategories_Avg_Fields = {
  __typename?: 'dw_EmissionFactorCategories_avg_fields';
  Scope?: Maybe<Scalars['Float']>;
  Sequential?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dw.EmissionFactorCategories". All fields are combined with a logical 'AND'. */
export type Dw_EmissionFactorCategories_Bool_Exp = {
  CategoryId?: Maybe<Uuid_Comparison_Exp>;
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  EmissionFactorSources?: Maybe<Dw_EmissionFactorSource_Bool_Exp>;
  Label?: Maybe<String_Comparison_Exp>;
  Scope?: Maybe<Smallint_Comparison_Exp>;
  Sequential?: Maybe<Int_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  _and?: Maybe<Array<Dw_EmissionFactorCategories_Bool_Exp>>;
  _not?: Maybe<Dw_EmissionFactorCategories_Bool_Exp>;
  _or?: Maybe<Array<Dw_EmissionFactorCategories_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.EmissionFactorCategories" */
export enum Dw_EmissionFactorCategories_Constraint {
  /** unique or primary key constraint */
  EmissionFactorCategoriesPkey = 'EmissionFactorCategories_pkey'
}

/** input type for incrementing numeric columns in table "dw.EmissionFactorCategories" */
export type Dw_EmissionFactorCategories_Inc_Input = {
  Scope?: Maybe<Scalars['smallint']>;
  Sequential?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "dw.EmissionFactorCategories" */
export type Dw_EmissionFactorCategories_Insert_Input = {
  CategoryId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  EmissionFactorSources?: Maybe<Dw_EmissionFactorSource_Arr_Rel_Insert_Input>;
  Label?: Maybe<Scalars['String']>;
  Scope?: Maybe<Scalars['smallint']>;
  Sequential?: Maybe<Scalars['Int']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Dw_EmissionFactorCategories_Max_Fields = {
  __typename?: 'dw_EmissionFactorCategories_max_fields';
  CategoryId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  Label?: Maybe<Scalars['String']>;
  Scope?: Maybe<Scalars['smallint']>;
  Sequential?: Maybe<Scalars['Int']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Dw_EmissionFactorCategories_Min_Fields = {
  __typename?: 'dw_EmissionFactorCategories_min_fields';
  CategoryId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  Label?: Maybe<Scalars['String']>;
  Scope?: Maybe<Scalars['smallint']>;
  Sequential?: Maybe<Scalars['Int']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "dw.EmissionFactorCategories" */
export type Dw_EmissionFactorCategories_Mutation_Response = {
  __typename?: 'dw_EmissionFactorCategories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_EmissionFactorCategories>;
};

/** input type for inserting object relation for remote table "dw.EmissionFactorCategories" */
export type Dw_EmissionFactorCategories_Obj_Rel_Insert_Input = {
  data: Dw_EmissionFactorCategories_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_EmissionFactorCategories_On_Conflict>;
};

/** on conflict condition type for table "dw.EmissionFactorCategories" */
export type Dw_EmissionFactorCategories_On_Conflict = {
  constraint: Dw_EmissionFactorCategories_Constraint;
  update_columns?: Array<Dw_EmissionFactorCategories_Update_Column>;
  where?: Maybe<Dw_EmissionFactorCategories_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.EmissionFactorCategories". */
export type Dw_EmissionFactorCategories_Order_By = {
  CategoryId?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  EmissionFactorSources_aggregate?: Maybe<Dw_EmissionFactorSource_Aggregate_Order_By>;
  Label?: Maybe<Order_By>;
  Scope?: Maybe<Order_By>;
  Sequential?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_EmissionFactorCategories */
export type Dw_EmissionFactorCategories_Pk_Columns_Input = {
  CategoryId: Scalars['uuid'];
};

/** select columns of table "dw.EmissionFactorCategories" */
export enum Dw_EmissionFactorCategories_Select_Column {
  /** column name */
  CategoryId = 'CategoryId',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Label = 'Label',
  /** column name */
  Scope = 'Scope',
  /** column name */
  Sequential = 'Sequential',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** input type for updating data in table "dw.EmissionFactorCategories" */
export type Dw_EmissionFactorCategories_Set_Input = {
  CategoryId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  Label?: Maybe<Scalars['String']>;
  Scope?: Maybe<Scalars['smallint']>;
  Sequential?: Maybe<Scalars['Int']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Dw_EmissionFactorCategories_Stddev_Fields = {
  __typename?: 'dw_EmissionFactorCategories_stddev_fields';
  Scope?: Maybe<Scalars['Float']>;
  Sequential?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dw_EmissionFactorCategories_Stddev_Pop_Fields = {
  __typename?: 'dw_EmissionFactorCategories_stddev_pop_fields';
  Scope?: Maybe<Scalars['Float']>;
  Sequential?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dw_EmissionFactorCategories_Stddev_Samp_Fields = {
  __typename?: 'dw_EmissionFactorCategories_stddev_samp_fields';
  Scope?: Maybe<Scalars['Float']>;
  Sequential?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Dw_EmissionFactorCategories_Sum_Fields = {
  __typename?: 'dw_EmissionFactorCategories_sum_fields';
  Scope?: Maybe<Scalars['smallint']>;
  Sequential?: Maybe<Scalars['Int']>;
};

/** update columns of table "dw.EmissionFactorCategories" */
export enum Dw_EmissionFactorCategories_Update_Column {
  /** column name */
  CategoryId = 'CategoryId',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Label = 'Label',
  /** column name */
  Scope = 'Scope',
  /** column name */
  Sequential = 'Sequential',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** aggregate var_pop on columns */
export type Dw_EmissionFactorCategories_Var_Pop_Fields = {
  __typename?: 'dw_EmissionFactorCategories_var_pop_fields';
  Scope?: Maybe<Scalars['Float']>;
  Sequential?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dw_EmissionFactorCategories_Var_Samp_Fields = {
  __typename?: 'dw_EmissionFactorCategories_var_samp_fields';
  Scope?: Maybe<Scalars['Float']>;
  Sequential?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dw_EmissionFactorCategories_Variance_Fields = {
  __typename?: 'dw_EmissionFactorCategories_variance_fields';
  Scope?: Maybe<Scalars['Float']>;
  Sequential?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "dw.EmissionFactorSource" */
export type Dw_EmissionFactorSource = {
  __typename?: 'dw_EmissionFactorSource';
  CarbonDioxide?: Maybe<Scalars['numeric']>;
  CategoryId: Scalars['uuid'];
  Description?: Maybe<Scalars['String']>;
  /** An object relationship */
  EmissionFactor: Dw_EmissionFactors;
  /** An object relationship */
  EmissionFactorCategory: Dw_EmissionFactorCategories;
  EmissionFactorId: Scalars['uuid'];
  /** An object relationship */
  EmissionFactorSource?: Maybe<Dw_EmissionFactorSource>;
  /** An array relationship */
  EmissionFactorSources: Array<Dw_EmissionFactorSource>;
  /** An aggregate relationship */
  EmissionFactorSources_aggregate: Dw_EmissionFactorSource_Aggregate;
  EmissionType: Scalars['smallint'];
  Hydrofluorocarbons?: Maybe<Scalars['numeric']>;
  Label: Scalars['String'];
  Methane?: Maybe<Scalars['numeric']>;
  NitrogenTrifluoride?: Maybe<Scalars['numeric']>;
  NitrousOxide?: Maybe<Scalars['numeric']>;
  ParentId?: Maybe<Scalars['uuid']>;
  Perfluorocarbons?: Maybe<Scalars['numeric']>;
  SourceId: Scalars['uuid'];
  Sulphur?: Maybe<Scalars['numeric']>;
  Total?: Maybe<Scalars['numeric']>;
  Unit: Scalars['String'];
};


/** columns and relationships of "dw.EmissionFactorSource" */
export type Dw_EmissionFactorSourceEmissionFactorSourcesArgs = {
  distinct_on?: Maybe<Array<Dw_EmissionFactorSource_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_EmissionFactorSource_Order_By>>;
  where?: Maybe<Dw_EmissionFactorSource_Bool_Exp>;
};


/** columns and relationships of "dw.EmissionFactorSource" */
export type Dw_EmissionFactorSourceEmissionFactorSources_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_EmissionFactorSource_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_EmissionFactorSource_Order_By>>;
  where?: Maybe<Dw_EmissionFactorSource_Bool_Exp>;
};

/** aggregated selection of "dw.EmissionFactorSource" */
export type Dw_EmissionFactorSource_Aggregate = {
  __typename?: 'dw_EmissionFactorSource_aggregate';
  aggregate?: Maybe<Dw_EmissionFactorSource_Aggregate_Fields>;
  nodes: Array<Dw_EmissionFactorSource>;
};

/** aggregate fields of "dw.EmissionFactorSource" */
export type Dw_EmissionFactorSource_Aggregate_Fields = {
  __typename?: 'dw_EmissionFactorSource_aggregate_fields';
  avg?: Maybe<Dw_EmissionFactorSource_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_EmissionFactorSource_Max_Fields>;
  min?: Maybe<Dw_EmissionFactorSource_Min_Fields>;
  stddev?: Maybe<Dw_EmissionFactorSource_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_EmissionFactorSource_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_EmissionFactorSource_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_EmissionFactorSource_Sum_Fields>;
  var_pop?: Maybe<Dw_EmissionFactorSource_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_EmissionFactorSource_Var_Samp_Fields>;
  variance?: Maybe<Dw_EmissionFactorSource_Variance_Fields>;
};


/** aggregate fields of "dw.EmissionFactorSource" */
export type Dw_EmissionFactorSource_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_EmissionFactorSource_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.EmissionFactorSource" */
export type Dw_EmissionFactorSource_Aggregate_Order_By = {
  avg?: Maybe<Dw_EmissionFactorSource_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_EmissionFactorSource_Max_Order_By>;
  min?: Maybe<Dw_EmissionFactorSource_Min_Order_By>;
  stddev?: Maybe<Dw_EmissionFactorSource_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_EmissionFactorSource_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_EmissionFactorSource_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_EmissionFactorSource_Sum_Order_By>;
  var_pop?: Maybe<Dw_EmissionFactorSource_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_EmissionFactorSource_Var_Samp_Order_By>;
  variance?: Maybe<Dw_EmissionFactorSource_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.EmissionFactorSource" */
export type Dw_EmissionFactorSource_Arr_Rel_Insert_Input = {
  data: Array<Dw_EmissionFactorSource_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_EmissionFactorSource_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_EmissionFactorSource_Avg_Fields = {
  __typename?: 'dw_EmissionFactorSource_avg_fields';
  CarbonDioxide?: Maybe<Scalars['Float']>;
  EmissionType?: Maybe<Scalars['Float']>;
  Hydrofluorocarbons?: Maybe<Scalars['Float']>;
  Methane?: Maybe<Scalars['Float']>;
  NitrogenTrifluoride?: Maybe<Scalars['Float']>;
  NitrousOxide?: Maybe<Scalars['Float']>;
  Perfluorocarbons?: Maybe<Scalars['Float']>;
  Sulphur?: Maybe<Scalars['Float']>;
  Total?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.EmissionFactorSource" */
export type Dw_EmissionFactorSource_Avg_Order_By = {
  CarbonDioxide?: Maybe<Order_By>;
  EmissionType?: Maybe<Order_By>;
  Hydrofluorocarbons?: Maybe<Order_By>;
  Methane?: Maybe<Order_By>;
  NitrogenTrifluoride?: Maybe<Order_By>;
  NitrousOxide?: Maybe<Order_By>;
  Perfluorocarbons?: Maybe<Order_By>;
  Sulphur?: Maybe<Order_By>;
  Total?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.EmissionFactorSource". All fields are combined with a logical 'AND'. */
export type Dw_EmissionFactorSource_Bool_Exp = {
  CarbonDioxide?: Maybe<Numeric_Comparison_Exp>;
  CategoryId?: Maybe<Uuid_Comparison_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  EmissionFactor?: Maybe<Dw_EmissionFactors_Bool_Exp>;
  EmissionFactorCategory?: Maybe<Dw_EmissionFactorCategories_Bool_Exp>;
  EmissionFactorId?: Maybe<Uuid_Comparison_Exp>;
  EmissionFactorSource?: Maybe<Dw_EmissionFactorSource_Bool_Exp>;
  EmissionFactorSources?: Maybe<Dw_EmissionFactorSource_Bool_Exp>;
  EmissionType?: Maybe<Smallint_Comparison_Exp>;
  Hydrofluorocarbons?: Maybe<Numeric_Comparison_Exp>;
  Label?: Maybe<String_Comparison_Exp>;
  Methane?: Maybe<Numeric_Comparison_Exp>;
  NitrogenTrifluoride?: Maybe<Numeric_Comparison_Exp>;
  NitrousOxide?: Maybe<Numeric_Comparison_Exp>;
  ParentId?: Maybe<Uuid_Comparison_Exp>;
  Perfluorocarbons?: Maybe<Numeric_Comparison_Exp>;
  SourceId?: Maybe<Uuid_Comparison_Exp>;
  Sulphur?: Maybe<Numeric_Comparison_Exp>;
  Total?: Maybe<Numeric_Comparison_Exp>;
  Unit?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Dw_EmissionFactorSource_Bool_Exp>>;
  _not?: Maybe<Dw_EmissionFactorSource_Bool_Exp>;
  _or?: Maybe<Array<Dw_EmissionFactorSource_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.EmissionFactorSource" */
export enum Dw_EmissionFactorSource_Constraint {
  /** unique or primary key constraint */
  EmissionFactorSourcePkey = 'EmissionFactorSource_pkey'
}

/** input type for incrementing numeric columns in table "dw.EmissionFactorSource" */
export type Dw_EmissionFactorSource_Inc_Input = {
  CarbonDioxide?: Maybe<Scalars['numeric']>;
  EmissionType?: Maybe<Scalars['smallint']>;
  Hydrofluorocarbons?: Maybe<Scalars['numeric']>;
  Methane?: Maybe<Scalars['numeric']>;
  NitrogenTrifluoride?: Maybe<Scalars['numeric']>;
  NitrousOxide?: Maybe<Scalars['numeric']>;
  Perfluorocarbons?: Maybe<Scalars['numeric']>;
  Sulphur?: Maybe<Scalars['numeric']>;
  Total?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "dw.EmissionFactorSource" */
export type Dw_EmissionFactorSource_Insert_Input = {
  CarbonDioxide?: Maybe<Scalars['numeric']>;
  CategoryId?: Maybe<Scalars['uuid']>;
  Description?: Maybe<Scalars['String']>;
  EmissionFactor?: Maybe<Dw_EmissionFactors_Obj_Rel_Insert_Input>;
  EmissionFactorCategory?: Maybe<Dw_EmissionFactorCategories_Obj_Rel_Insert_Input>;
  EmissionFactorId?: Maybe<Scalars['uuid']>;
  EmissionFactorSource?: Maybe<Dw_EmissionFactorSource_Obj_Rel_Insert_Input>;
  EmissionFactorSources?: Maybe<Dw_EmissionFactorSource_Arr_Rel_Insert_Input>;
  EmissionType?: Maybe<Scalars['smallint']>;
  Hydrofluorocarbons?: Maybe<Scalars['numeric']>;
  Label?: Maybe<Scalars['String']>;
  Methane?: Maybe<Scalars['numeric']>;
  NitrogenTrifluoride?: Maybe<Scalars['numeric']>;
  NitrousOxide?: Maybe<Scalars['numeric']>;
  ParentId?: Maybe<Scalars['uuid']>;
  Perfluorocarbons?: Maybe<Scalars['numeric']>;
  SourceId?: Maybe<Scalars['uuid']>;
  Sulphur?: Maybe<Scalars['numeric']>;
  Total?: Maybe<Scalars['numeric']>;
  Unit?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Dw_EmissionFactorSource_Max_Fields = {
  __typename?: 'dw_EmissionFactorSource_max_fields';
  CarbonDioxide?: Maybe<Scalars['numeric']>;
  CategoryId?: Maybe<Scalars['uuid']>;
  Description?: Maybe<Scalars['String']>;
  EmissionFactorId?: Maybe<Scalars['uuid']>;
  EmissionType?: Maybe<Scalars['smallint']>;
  Hydrofluorocarbons?: Maybe<Scalars['numeric']>;
  Label?: Maybe<Scalars['String']>;
  Methane?: Maybe<Scalars['numeric']>;
  NitrogenTrifluoride?: Maybe<Scalars['numeric']>;
  NitrousOxide?: Maybe<Scalars['numeric']>;
  ParentId?: Maybe<Scalars['uuid']>;
  Perfluorocarbons?: Maybe<Scalars['numeric']>;
  SourceId?: Maybe<Scalars['uuid']>;
  Sulphur?: Maybe<Scalars['numeric']>;
  Total?: Maybe<Scalars['numeric']>;
  Unit?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "dw.EmissionFactorSource" */
export type Dw_EmissionFactorSource_Max_Order_By = {
  CarbonDioxide?: Maybe<Order_By>;
  CategoryId?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  EmissionFactorId?: Maybe<Order_By>;
  EmissionType?: Maybe<Order_By>;
  Hydrofluorocarbons?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  Methane?: Maybe<Order_By>;
  NitrogenTrifluoride?: Maybe<Order_By>;
  NitrousOxide?: Maybe<Order_By>;
  ParentId?: Maybe<Order_By>;
  Perfluorocarbons?: Maybe<Order_By>;
  SourceId?: Maybe<Order_By>;
  Sulphur?: Maybe<Order_By>;
  Total?: Maybe<Order_By>;
  Unit?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_EmissionFactorSource_Min_Fields = {
  __typename?: 'dw_EmissionFactorSource_min_fields';
  CarbonDioxide?: Maybe<Scalars['numeric']>;
  CategoryId?: Maybe<Scalars['uuid']>;
  Description?: Maybe<Scalars['String']>;
  EmissionFactorId?: Maybe<Scalars['uuid']>;
  EmissionType?: Maybe<Scalars['smallint']>;
  Hydrofluorocarbons?: Maybe<Scalars['numeric']>;
  Label?: Maybe<Scalars['String']>;
  Methane?: Maybe<Scalars['numeric']>;
  NitrogenTrifluoride?: Maybe<Scalars['numeric']>;
  NitrousOxide?: Maybe<Scalars['numeric']>;
  ParentId?: Maybe<Scalars['uuid']>;
  Perfluorocarbons?: Maybe<Scalars['numeric']>;
  SourceId?: Maybe<Scalars['uuid']>;
  Sulphur?: Maybe<Scalars['numeric']>;
  Total?: Maybe<Scalars['numeric']>;
  Unit?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "dw.EmissionFactorSource" */
export type Dw_EmissionFactorSource_Min_Order_By = {
  CarbonDioxide?: Maybe<Order_By>;
  CategoryId?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  EmissionFactorId?: Maybe<Order_By>;
  EmissionType?: Maybe<Order_By>;
  Hydrofluorocarbons?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  Methane?: Maybe<Order_By>;
  NitrogenTrifluoride?: Maybe<Order_By>;
  NitrousOxide?: Maybe<Order_By>;
  ParentId?: Maybe<Order_By>;
  Perfluorocarbons?: Maybe<Order_By>;
  SourceId?: Maybe<Order_By>;
  Sulphur?: Maybe<Order_By>;
  Total?: Maybe<Order_By>;
  Unit?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.EmissionFactorSource" */
export type Dw_EmissionFactorSource_Mutation_Response = {
  __typename?: 'dw_EmissionFactorSource_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_EmissionFactorSource>;
};

/** input type for inserting object relation for remote table "dw.EmissionFactorSource" */
export type Dw_EmissionFactorSource_Obj_Rel_Insert_Input = {
  data: Dw_EmissionFactorSource_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_EmissionFactorSource_On_Conflict>;
};

/** on conflict condition type for table "dw.EmissionFactorSource" */
export type Dw_EmissionFactorSource_On_Conflict = {
  constraint: Dw_EmissionFactorSource_Constraint;
  update_columns?: Array<Dw_EmissionFactorSource_Update_Column>;
  where?: Maybe<Dw_EmissionFactorSource_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.EmissionFactorSource". */
export type Dw_EmissionFactorSource_Order_By = {
  CarbonDioxide?: Maybe<Order_By>;
  CategoryId?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  EmissionFactor?: Maybe<Dw_EmissionFactors_Order_By>;
  EmissionFactorCategory?: Maybe<Dw_EmissionFactorCategories_Order_By>;
  EmissionFactorId?: Maybe<Order_By>;
  EmissionFactorSource?: Maybe<Dw_EmissionFactorSource_Order_By>;
  EmissionFactorSources_aggregate?: Maybe<Dw_EmissionFactorSource_Aggregate_Order_By>;
  EmissionType?: Maybe<Order_By>;
  Hydrofluorocarbons?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  Methane?: Maybe<Order_By>;
  NitrogenTrifluoride?: Maybe<Order_By>;
  NitrousOxide?: Maybe<Order_By>;
  ParentId?: Maybe<Order_By>;
  Perfluorocarbons?: Maybe<Order_By>;
  SourceId?: Maybe<Order_By>;
  Sulphur?: Maybe<Order_By>;
  Total?: Maybe<Order_By>;
  Unit?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_EmissionFactorSource */
export type Dw_EmissionFactorSource_Pk_Columns_Input = {
  SourceId: Scalars['uuid'];
};

/** select columns of table "dw.EmissionFactorSource" */
export enum Dw_EmissionFactorSource_Select_Column {
  /** column name */
  CarbonDioxide = 'CarbonDioxide',
  /** column name */
  CategoryId = 'CategoryId',
  /** column name */
  Description = 'Description',
  /** column name */
  EmissionFactorId = 'EmissionFactorId',
  /** column name */
  EmissionType = 'EmissionType',
  /** column name */
  Hydrofluorocarbons = 'Hydrofluorocarbons',
  /** column name */
  Label = 'Label',
  /** column name */
  Methane = 'Methane',
  /** column name */
  NitrogenTrifluoride = 'NitrogenTrifluoride',
  /** column name */
  NitrousOxide = 'NitrousOxide',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  Perfluorocarbons = 'Perfluorocarbons',
  /** column name */
  SourceId = 'SourceId',
  /** column name */
  Sulphur = 'Sulphur',
  /** column name */
  Total = 'Total',
  /** column name */
  Unit = 'Unit'
}

/** input type for updating data in table "dw.EmissionFactorSource" */
export type Dw_EmissionFactorSource_Set_Input = {
  CarbonDioxide?: Maybe<Scalars['numeric']>;
  CategoryId?: Maybe<Scalars['uuid']>;
  Description?: Maybe<Scalars['String']>;
  EmissionFactorId?: Maybe<Scalars['uuid']>;
  EmissionType?: Maybe<Scalars['smallint']>;
  Hydrofluorocarbons?: Maybe<Scalars['numeric']>;
  Label?: Maybe<Scalars['String']>;
  Methane?: Maybe<Scalars['numeric']>;
  NitrogenTrifluoride?: Maybe<Scalars['numeric']>;
  NitrousOxide?: Maybe<Scalars['numeric']>;
  ParentId?: Maybe<Scalars['uuid']>;
  Perfluorocarbons?: Maybe<Scalars['numeric']>;
  SourceId?: Maybe<Scalars['uuid']>;
  Sulphur?: Maybe<Scalars['numeric']>;
  Total?: Maybe<Scalars['numeric']>;
  Unit?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Dw_EmissionFactorSource_Stddev_Fields = {
  __typename?: 'dw_EmissionFactorSource_stddev_fields';
  CarbonDioxide?: Maybe<Scalars['Float']>;
  EmissionType?: Maybe<Scalars['Float']>;
  Hydrofluorocarbons?: Maybe<Scalars['Float']>;
  Methane?: Maybe<Scalars['Float']>;
  NitrogenTrifluoride?: Maybe<Scalars['Float']>;
  NitrousOxide?: Maybe<Scalars['Float']>;
  Perfluorocarbons?: Maybe<Scalars['Float']>;
  Sulphur?: Maybe<Scalars['Float']>;
  Total?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.EmissionFactorSource" */
export type Dw_EmissionFactorSource_Stddev_Order_By = {
  CarbonDioxide?: Maybe<Order_By>;
  EmissionType?: Maybe<Order_By>;
  Hydrofluorocarbons?: Maybe<Order_By>;
  Methane?: Maybe<Order_By>;
  NitrogenTrifluoride?: Maybe<Order_By>;
  NitrousOxide?: Maybe<Order_By>;
  Perfluorocarbons?: Maybe<Order_By>;
  Sulphur?: Maybe<Order_By>;
  Total?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_EmissionFactorSource_Stddev_Pop_Fields = {
  __typename?: 'dw_EmissionFactorSource_stddev_pop_fields';
  CarbonDioxide?: Maybe<Scalars['Float']>;
  EmissionType?: Maybe<Scalars['Float']>;
  Hydrofluorocarbons?: Maybe<Scalars['Float']>;
  Methane?: Maybe<Scalars['Float']>;
  NitrogenTrifluoride?: Maybe<Scalars['Float']>;
  NitrousOxide?: Maybe<Scalars['Float']>;
  Perfluorocarbons?: Maybe<Scalars['Float']>;
  Sulphur?: Maybe<Scalars['Float']>;
  Total?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.EmissionFactorSource" */
export type Dw_EmissionFactorSource_Stddev_Pop_Order_By = {
  CarbonDioxide?: Maybe<Order_By>;
  EmissionType?: Maybe<Order_By>;
  Hydrofluorocarbons?: Maybe<Order_By>;
  Methane?: Maybe<Order_By>;
  NitrogenTrifluoride?: Maybe<Order_By>;
  NitrousOxide?: Maybe<Order_By>;
  Perfluorocarbons?: Maybe<Order_By>;
  Sulphur?: Maybe<Order_By>;
  Total?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_EmissionFactorSource_Stddev_Samp_Fields = {
  __typename?: 'dw_EmissionFactorSource_stddev_samp_fields';
  CarbonDioxide?: Maybe<Scalars['Float']>;
  EmissionType?: Maybe<Scalars['Float']>;
  Hydrofluorocarbons?: Maybe<Scalars['Float']>;
  Methane?: Maybe<Scalars['Float']>;
  NitrogenTrifluoride?: Maybe<Scalars['Float']>;
  NitrousOxide?: Maybe<Scalars['Float']>;
  Perfluorocarbons?: Maybe<Scalars['Float']>;
  Sulphur?: Maybe<Scalars['Float']>;
  Total?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.EmissionFactorSource" */
export type Dw_EmissionFactorSource_Stddev_Samp_Order_By = {
  CarbonDioxide?: Maybe<Order_By>;
  EmissionType?: Maybe<Order_By>;
  Hydrofluorocarbons?: Maybe<Order_By>;
  Methane?: Maybe<Order_By>;
  NitrogenTrifluoride?: Maybe<Order_By>;
  NitrousOxide?: Maybe<Order_By>;
  Perfluorocarbons?: Maybe<Order_By>;
  Sulphur?: Maybe<Order_By>;
  Total?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_EmissionFactorSource_Sum_Fields = {
  __typename?: 'dw_EmissionFactorSource_sum_fields';
  CarbonDioxide?: Maybe<Scalars['numeric']>;
  EmissionType?: Maybe<Scalars['smallint']>;
  Hydrofluorocarbons?: Maybe<Scalars['numeric']>;
  Methane?: Maybe<Scalars['numeric']>;
  NitrogenTrifluoride?: Maybe<Scalars['numeric']>;
  NitrousOxide?: Maybe<Scalars['numeric']>;
  Perfluorocarbons?: Maybe<Scalars['numeric']>;
  Sulphur?: Maybe<Scalars['numeric']>;
  Total?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "dw.EmissionFactorSource" */
export type Dw_EmissionFactorSource_Sum_Order_By = {
  CarbonDioxide?: Maybe<Order_By>;
  EmissionType?: Maybe<Order_By>;
  Hydrofluorocarbons?: Maybe<Order_By>;
  Methane?: Maybe<Order_By>;
  NitrogenTrifluoride?: Maybe<Order_By>;
  NitrousOxide?: Maybe<Order_By>;
  Perfluorocarbons?: Maybe<Order_By>;
  Sulphur?: Maybe<Order_By>;
  Total?: Maybe<Order_By>;
};

/** update columns of table "dw.EmissionFactorSource" */
export enum Dw_EmissionFactorSource_Update_Column {
  /** column name */
  CarbonDioxide = 'CarbonDioxide',
  /** column name */
  CategoryId = 'CategoryId',
  /** column name */
  Description = 'Description',
  /** column name */
  EmissionFactorId = 'EmissionFactorId',
  /** column name */
  EmissionType = 'EmissionType',
  /** column name */
  Hydrofluorocarbons = 'Hydrofluorocarbons',
  /** column name */
  Label = 'Label',
  /** column name */
  Methane = 'Methane',
  /** column name */
  NitrogenTrifluoride = 'NitrogenTrifluoride',
  /** column name */
  NitrousOxide = 'NitrousOxide',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  Perfluorocarbons = 'Perfluorocarbons',
  /** column name */
  SourceId = 'SourceId',
  /** column name */
  Sulphur = 'Sulphur',
  /** column name */
  Total = 'Total',
  /** column name */
  Unit = 'Unit'
}

/** aggregate var_pop on columns */
export type Dw_EmissionFactorSource_Var_Pop_Fields = {
  __typename?: 'dw_EmissionFactorSource_var_pop_fields';
  CarbonDioxide?: Maybe<Scalars['Float']>;
  EmissionType?: Maybe<Scalars['Float']>;
  Hydrofluorocarbons?: Maybe<Scalars['Float']>;
  Methane?: Maybe<Scalars['Float']>;
  NitrogenTrifluoride?: Maybe<Scalars['Float']>;
  NitrousOxide?: Maybe<Scalars['Float']>;
  Perfluorocarbons?: Maybe<Scalars['Float']>;
  Sulphur?: Maybe<Scalars['Float']>;
  Total?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.EmissionFactorSource" */
export type Dw_EmissionFactorSource_Var_Pop_Order_By = {
  CarbonDioxide?: Maybe<Order_By>;
  EmissionType?: Maybe<Order_By>;
  Hydrofluorocarbons?: Maybe<Order_By>;
  Methane?: Maybe<Order_By>;
  NitrogenTrifluoride?: Maybe<Order_By>;
  NitrousOxide?: Maybe<Order_By>;
  Perfluorocarbons?: Maybe<Order_By>;
  Sulphur?: Maybe<Order_By>;
  Total?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_EmissionFactorSource_Var_Samp_Fields = {
  __typename?: 'dw_EmissionFactorSource_var_samp_fields';
  CarbonDioxide?: Maybe<Scalars['Float']>;
  EmissionType?: Maybe<Scalars['Float']>;
  Hydrofluorocarbons?: Maybe<Scalars['Float']>;
  Methane?: Maybe<Scalars['Float']>;
  NitrogenTrifluoride?: Maybe<Scalars['Float']>;
  NitrousOxide?: Maybe<Scalars['Float']>;
  Perfluorocarbons?: Maybe<Scalars['Float']>;
  Sulphur?: Maybe<Scalars['Float']>;
  Total?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.EmissionFactorSource" */
export type Dw_EmissionFactorSource_Var_Samp_Order_By = {
  CarbonDioxide?: Maybe<Order_By>;
  EmissionType?: Maybe<Order_By>;
  Hydrofluorocarbons?: Maybe<Order_By>;
  Methane?: Maybe<Order_By>;
  NitrogenTrifluoride?: Maybe<Order_By>;
  NitrousOxide?: Maybe<Order_By>;
  Perfluorocarbons?: Maybe<Order_By>;
  Sulphur?: Maybe<Order_By>;
  Total?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_EmissionFactorSource_Variance_Fields = {
  __typename?: 'dw_EmissionFactorSource_variance_fields';
  CarbonDioxide?: Maybe<Scalars['Float']>;
  EmissionType?: Maybe<Scalars['Float']>;
  Hydrofluorocarbons?: Maybe<Scalars['Float']>;
  Methane?: Maybe<Scalars['Float']>;
  NitrogenTrifluoride?: Maybe<Scalars['Float']>;
  NitrousOxide?: Maybe<Scalars['Float']>;
  Perfluorocarbons?: Maybe<Scalars['Float']>;
  Sulphur?: Maybe<Scalars['Float']>;
  Total?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.EmissionFactorSource" */
export type Dw_EmissionFactorSource_Variance_Order_By = {
  CarbonDioxide?: Maybe<Order_By>;
  EmissionType?: Maybe<Order_By>;
  Hydrofluorocarbons?: Maybe<Order_By>;
  Methane?: Maybe<Order_By>;
  NitrogenTrifluoride?: Maybe<Order_By>;
  NitrousOxide?: Maybe<Order_By>;
  Perfluorocarbons?: Maybe<Order_By>;
  Sulphur?: Maybe<Order_By>;
  Total?: Maybe<Order_By>;
};

/** columns and relationships of "dw.EmissionFactors" */
export type Dw_EmissionFactors = {
  __typename?: 'dw_EmissionFactors';
  CountryCode: Scalars['bpchar'];
  CreatedOn: Scalars['timestamp'];
  Description: Scalars['String'];
  EmissionFactorId: Scalars['uuid'];
  /** An array relationship */
  EmissionFactorSources: Array<Dw_EmissionFactorSource>;
  /** An aggregate relationship */
  EmissionFactorSources_aggregate: Dw_EmissionFactorSource_Aggregate;
  Label: Scalars['String'];
  PublishedOn: Scalars['timestamp'];
  Source: Scalars['String'];
  UpdatedOn: Scalars['timestamp'];
  Url: Scalars['String'];
  Version: Scalars['String'];
  Year: Scalars['smallint'];
};


/** columns and relationships of "dw.EmissionFactors" */
export type Dw_EmissionFactorsEmissionFactorSourcesArgs = {
  distinct_on?: Maybe<Array<Dw_EmissionFactorSource_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_EmissionFactorSource_Order_By>>;
  where?: Maybe<Dw_EmissionFactorSource_Bool_Exp>;
};


/** columns and relationships of "dw.EmissionFactors" */
export type Dw_EmissionFactorsEmissionFactorSources_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_EmissionFactorSource_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_EmissionFactorSource_Order_By>>;
  where?: Maybe<Dw_EmissionFactorSource_Bool_Exp>;
};

/** aggregated selection of "dw.EmissionFactors" */
export type Dw_EmissionFactors_Aggregate = {
  __typename?: 'dw_EmissionFactors_aggregate';
  aggregate?: Maybe<Dw_EmissionFactors_Aggregate_Fields>;
  nodes: Array<Dw_EmissionFactors>;
};

/** aggregate fields of "dw.EmissionFactors" */
export type Dw_EmissionFactors_Aggregate_Fields = {
  __typename?: 'dw_EmissionFactors_aggregate_fields';
  avg?: Maybe<Dw_EmissionFactors_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_EmissionFactors_Max_Fields>;
  min?: Maybe<Dw_EmissionFactors_Min_Fields>;
  stddev?: Maybe<Dw_EmissionFactors_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_EmissionFactors_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_EmissionFactors_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_EmissionFactors_Sum_Fields>;
  var_pop?: Maybe<Dw_EmissionFactors_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_EmissionFactors_Var_Samp_Fields>;
  variance?: Maybe<Dw_EmissionFactors_Variance_Fields>;
};


/** aggregate fields of "dw.EmissionFactors" */
export type Dw_EmissionFactors_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_EmissionFactors_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dw_EmissionFactors_Avg_Fields = {
  __typename?: 'dw_EmissionFactors_avg_fields';
  Year?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dw.EmissionFactors". All fields are combined with a logical 'AND'. */
export type Dw_EmissionFactors_Bool_Exp = {
  CountryCode?: Maybe<Bpchar_Comparison_Exp>;
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  EmissionFactorId?: Maybe<Uuid_Comparison_Exp>;
  EmissionFactorSources?: Maybe<Dw_EmissionFactorSource_Bool_Exp>;
  Label?: Maybe<String_Comparison_Exp>;
  PublishedOn?: Maybe<Timestamp_Comparison_Exp>;
  Source?: Maybe<String_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  Url?: Maybe<String_Comparison_Exp>;
  Version?: Maybe<String_Comparison_Exp>;
  Year?: Maybe<Smallint_Comparison_Exp>;
  _and?: Maybe<Array<Dw_EmissionFactors_Bool_Exp>>;
  _not?: Maybe<Dw_EmissionFactors_Bool_Exp>;
  _or?: Maybe<Array<Dw_EmissionFactors_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.EmissionFactors" */
export enum Dw_EmissionFactors_Constraint {
  /** unique or primary key constraint */
  EmissionFactorsPkey = 'EmissionFactors_pkey'
}

/** input type for incrementing numeric columns in table "dw.EmissionFactors" */
export type Dw_EmissionFactors_Inc_Input = {
  Year?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.EmissionFactors" */
export type Dw_EmissionFactors_Insert_Input = {
  CountryCode?: Maybe<Scalars['bpchar']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  Description?: Maybe<Scalars['String']>;
  EmissionFactorId?: Maybe<Scalars['uuid']>;
  EmissionFactorSources?: Maybe<Dw_EmissionFactorSource_Arr_Rel_Insert_Input>;
  Label?: Maybe<Scalars['String']>;
  PublishedOn?: Maybe<Scalars['timestamp']>;
  Source?: Maybe<Scalars['String']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  Url?: Maybe<Scalars['String']>;
  Version?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['smallint']>;
};

/** aggregate max on columns */
export type Dw_EmissionFactors_Max_Fields = {
  __typename?: 'dw_EmissionFactors_max_fields';
  CountryCode?: Maybe<Scalars['bpchar']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  Description?: Maybe<Scalars['String']>;
  EmissionFactorId?: Maybe<Scalars['uuid']>;
  Label?: Maybe<Scalars['String']>;
  PublishedOn?: Maybe<Scalars['timestamp']>;
  Source?: Maybe<Scalars['String']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  Url?: Maybe<Scalars['String']>;
  Version?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['smallint']>;
};

/** aggregate min on columns */
export type Dw_EmissionFactors_Min_Fields = {
  __typename?: 'dw_EmissionFactors_min_fields';
  CountryCode?: Maybe<Scalars['bpchar']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  Description?: Maybe<Scalars['String']>;
  EmissionFactorId?: Maybe<Scalars['uuid']>;
  Label?: Maybe<Scalars['String']>;
  PublishedOn?: Maybe<Scalars['timestamp']>;
  Source?: Maybe<Scalars['String']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  Url?: Maybe<Scalars['String']>;
  Version?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['smallint']>;
};

/** response of any mutation on the table "dw.EmissionFactors" */
export type Dw_EmissionFactors_Mutation_Response = {
  __typename?: 'dw_EmissionFactors_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_EmissionFactors>;
};

/** input type for inserting object relation for remote table "dw.EmissionFactors" */
export type Dw_EmissionFactors_Obj_Rel_Insert_Input = {
  data: Dw_EmissionFactors_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_EmissionFactors_On_Conflict>;
};

/** on conflict condition type for table "dw.EmissionFactors" */
export type Dw_EmissionFactors_On_Conflict = {
  constraint: Dw_EmissionFactors_Constraint;
  update_columns?: Array<Dw_EmissionFactors_Update_Column>;
  where?: Maybe<Dw_EmissionFactors_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.EmissionFactors". */
export type Dw_EmissionFactors_Order_By = {
  CountryCode?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  EmissionFactorId?: Maybe<Order_By>;
  EmissionFactorSources_aggregate?: Maybe<Dw_EmissionFactorSource_Aggregate_Order_By>;
  Label?: Maybe<Order_By>;
  PublishedOn?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
  Url?: Maybe<Order_By>;
  Version?: Maybe<Order_By>;
  Year?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_EmissionFactors */
export type Dw_EmissionFactors_Pk_Columns_Input = {
  EmissionFactorId: Scalars['uuid'];
};

/** select columns of table "dw.EmissionFactors" */
export enum Dw_EmissionFactors_Select_Column {
  /** column name */
  CountryCode = 'CountryCode',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Description = 'Description',
  /** column name */
  EmissionFactorId = 'EmissionFactorId',
  /** column name */
  Label = 'Label',
  /** column name */
  PublishedOn = 'PublishedOn',
  /** column name */
  Source = 'Source',
  /** column name */
  UpdatedOn = 'UpdatedOn',
  /** column name */
  Url = 'Url',
  /** column name */
  Version = 'Version',
  /** column name */
  Year = 'Year'
}

/** input type for updating data in table "dw.EmissionFactors" */
export type Dw_EmissionFactors_Set_Input = {
  CountryCode?: Maybe<Scalars['bpchar']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  Description?: Maybe<Scalars['String']>;
  EmissionFactorId?: Maybe<Scalars['uuid']>;
  Label?: Maybe<Scalars['String']>;
  PublishedOn?: Maybe<Scalars['timestamp']>;
  Source?: Maybe<Scalars['String']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  Url?: Maybe<Scalars['String']>;
  Version?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type Dw_EmissionFactors_Stddev_Fields = {
  __typename?: 'dw_EmissionFactors_stddev_fields';
  Year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dw_EmissionFactors_Stddev_Pop_Fields = {
  __typename?: 'dw_EmissionFactors_stddev_pop_fields';
  Year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dw_EmissionFactors_Stddev_Samp_Fields = {
  __typename?: 'dw_EmissionFactors_stddev_samp_fields';
  Year?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Dw_EmissionFactors_Sum_Fields = {
  __typename?: 'dw_EmissionFactors_sum_fields';
  Year?: Maybe<Scalars['smallint']>;
};

/** update columns of table "dw.EmissionFactors" */
export enum Dw_EmissionFactors_Update_Column {
  /** column name */
  CountryCode = 'CountryCode',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Description = 'Description',
  /** column name */
  EmissionFactorId = 'EmissionFactorId',
  /** column name */
  Label = 'Label',
  /** column name */
  PublishedOn = 'PublishedOn',
  /** column name */
  Source = 'Source',
  /** column name */
  UpdatedOn = 'UpdatedOn',
  /** column name */
  Url = 'Url',
  /** column name */
  Version = 'Version',
  /** column name */
  Year = 'Year'
}

/** aggregate var_pop on columns */
export type Dw_EmissionFactors_Var_Pop_Fields = {
  __typename?: 'dw_EmissionFactors_var_pop_fields';
  Year?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dw_EmissionFactors_Var_Samp_Fields = {
  __typename?: 'dw_EmissionFactors_var_samp_fields';
  Year?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dw_EmissionFactors_Variance_Fields = {
  __typename?: 'dw_EmissionFactors_variance_fields';
  Year?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "dw.Feeds" */
export type Dw_Feeds = {
  __typename?: 'dw_Feeds';
  ActivatedOn?: Maybe<Scalars['timestamp']>;
  BatteryDailyUsage?: Maybe<Scalars['numeric']>;
  BatteryPercentage?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  Building?: Maybe<Dw_Buildings>;
  BuildingId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  Channels: Array<Dw_Channels>;
  /** An aggregate relationship */
  Channels_aggregate: Dw_Channels_Aggregate;
  CreatedOn: Scalars['timestamp'];
  FeedId: Scalars['uuid'];
  /** An array relationship */
  FeedsConfigBacNets: Array<Dw_FeedsConfigBacNet>;
  /** An aggregate relationship */
  FeedsConfigBacNets_aggregate: Dw_FeedsConfigBacNet_Aggregate;
  /** An array relationship */
  FeedsConfigModbuses: Array<Dw_FeedsConfigModbus>;
  /** An aggregate relationship */
  FeedsConfigModbuses_aggregate: Dw_FeedsConfigModbus_Aggregate;
  /** An array relationship */
  FeedsLogs: Array<Dw_FeedsLogs>;
  /** An aggregate relationship */
  FeedsLogs_aggregate: Dw_FeedsLogs_Aggregate;
  /** An object relationship */
  FeedsType?: Maybe<Dw_FeedsTypes>;
  IntervalEmitFrequency: Scalars['smallint'];
  Label: Scalars['String'];
  LastSync?: Maybe<Scalars['timestamp']>;
  /** An array relationship */
  Loads: Array<Dw_Loads>;
  /** An aggregate relationship */
  Loads_aggregate: Dw_Loads_Aggregate;
  Notes?: Maybe<Scalars['String']>;
  Password: Scalars['String'];
  ProductionMode: Scalars['Boolean'];
  RadioQuality?: Maybe<Scalars['Int']>;
  SerialNumber: Scalars['String'];
  SimCard: Scalars['String'];
  Status: Scalars['smallint'];
  Temperature?: Maybe<Scalars['numeric']>;
  TypeId?: Maybe<Scalars['uuid']>;
  UpdatedOn: Scalars['timestamp'];
  Url?: Maybe<Scalars['String']>;
  UserName?: Maybe<Scalars['String']>;
};


/** columns and relationships of "dw.Feeds" */
export type Dw_FeedsChannelsArgs = {
  distinct_on?: Maybe<Array<Dw_Channels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Channels_Order_By>>;
  where?: Maybe<Dw_Channels_Bool_Exp>;
};


/** columns and relationships of "dw.Feeds" */
export type Dw_FeedsChannels_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Channels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Channels_Order_By>>;
  where?: Maybe<Dw_Channels_Bool_Exp>;
};


/** columns and relationships of "dw.Feeds" */
export type Dw_FeedsFeedsConfigBacNetsArgs = {
  distinct_on?: Maybe<Array<Dw_FeedsConfigBacNet_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_FeedsConfigBacNet_Order_By>>;
  where?: Maybe<Dw_FeedsConfigBacNet_Bool_Exp>;
};


/** columns and relationships of "dw.Feeds" */
export type Dw_FeedsFeedsConfigBacNets_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_FeedsConfigBacNet_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_FeedsConfigBacNet_Order_By>>;
  where?: Maybe<Dw_FeedsConfigBacNet_Bool_Exp>;
};


/** columns and relationships of "dw.Feeds" */
export type Dw_FeedsFeedsConfigModbusesArgs = {
  distinct_on?: Maybe<Array<Dw_FeedsConfigModbus_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_FeedsConfigModbus_Order_By>>;
  where?: Maybe<Dw_FeedsConfigModbus_Bool_Exp>;
};


/** columns and relationships of "dw.Feeds" */
export type Dw_FeedsFeedsConfigModbuses_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_FeedsConfigModbus_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_FeedsConfigModbus_Order_By>>;
  where?: Maybe<Dw_FeedsConfigModbus_Bool_Exp>;
};


/** columns and relationships of "dw.Feeds" */
export type Dw_FeedsFeedsLogsArgs = {
  distinct_on?: Maybe<Array<Dw_FeedsLogs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_FeedsLogs_Order_By>>;
  where?: Maybe<Dw_FeedsLogs_Bool_Exp>;
};


/** columns and relationships of "dw.Feeds" */
export type Dw_FeedsFeedsLogs_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_FeedsLogs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_FeedsLogs_Order_By>>;
  where?: Maybe<Dw_FeedsLogs_Bool_Exp>;
};


/** columns and relationships of "dw.Feeds" */
export type Dw_FeedsLoadsArgs = {
  distinct_on?: Maybe<Array<Dw_Loads_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Loads_Order_By>>;
  where?: Maybe<Dw_Loads_Bool_Exp>;
};


/** columns and relationships of "dw.Feeds" */
export type Dw_FeedsLoads_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Loads_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Loads_Order_By>>;
  where?: Maybe<Dw_Loads_Bool_Exp>;
};

/** columns and relationships of "dw.FeedsConfigBacNet" */
export type Dw_FeedsConfigBacNet = {
  __typename?: 'dw_FeedsConfigBacNet';
  CalculateDelta?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  Channel: Dw_Channels;
  ChannelId: Scalars['uuid'];
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DeviceId?: Maybe<Scalars['String']>;
  /** An object relationship */
  Feed: Dw_Feeds;
  FeedId: Scalars['uuid'];
  ObjectId?: Maybe<Scalars['String']>;
  ObjectInstance?: Maybe<Scalars['Int']>;
  ReadFrequencySeconds?: Maybe<Scalars['Int']>;
  Status: Scalars['smallint'];
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregated selection of "dw.FeedsConfigBacNet" */
export type Dw_FeedsConfigBacNet_Aggregate = {
  __typename?: 'dw_FeedsConfigBacNet_aggregate';
  aggregate?: Maybe<Dw_FeedsConfigBacNet_Aggregate_Fields>;
  nodes: Array<Dw_FeedsConfigBacNet>;
};

/** aggregate fields of "dw.FeedsConfigBacNet" */
export type Dw_FeedsConfigBacNet_Aggregate_Fields = {
  __typename?: 'dw_FeedsConfigBacNet_aggregate_fields';
  avg?: Maybe<Dw_FeedsConfigBacNet_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_FeedsConfigBacNet_Max_Fields>;
  min?: Maybe<Dw_FeedsConfigBacNet_Min_Fields>;
  stddev?: Maybe<Dw_FeedsConfigBacNet_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_FeedsConfigBacNet_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_FeedsConfigBacNet_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_FeedsConfigBacNet_Sum_Fields>;
  var_pop?: Maybe<Dw_FeedsConfigBacNet_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_FeedsConfigBacNet_Var_Samp_Fields>;
  variance?: Maybe<Dw_FeedsConfigBacNet_Variance_Fields>;
};


/** aggregate fields of "dw.FeedsConfigBacNet" */
export type Dw_FeedsConfigBacNet_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_FeedsConfigBacNet_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.FeedsConfigBacNet" */
export type Dw_FeedsConfigBacNet_Aggregate_Order_By = {
  avg?: Maybe<Dw_FeedsConfigBacNet_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_FeedsConfigBacNet_Max_Order_By>;
  min?: Maybe<Dw_FeedsConfigBacNet_Min_Order_By>;
  stddev?: Maybe<Dw_FeedsConfigBacNet_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_FeedsConfigBacNet_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_FeedsConfigBacNet_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_FeedsConfigBacNet_Sum_Order_By>;
  var_pop?: Maybe<Dw_FeedsConfigBacNet_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_FeedsConfigBacNet_Var_Samp_Order_By>;
  variance?: Maybe<Dw_FeedsConfigBacNet_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.FeedsConfigBacNet" */
export type Dw_FeedsConfigBacNet_Arr_Rel_Insert_Input = {
  data: Array<Dw_FeedsConfigBacNet_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_FeedsConfigBacNet_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_FeedsConfigBacNet_Avg_Fields = {
  __typename?: 'dw_FeedsConfigBacNet_avg_fields';
  ObjectInstance?: Maybe<Scalars['Float']>;
  ReadFrequencySeconds?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.FeedsConfigBacNet" */
export type Dw_FeedsConfigBacNet_Avg_Order_By = {
  ObjectInstance?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.FeedsConfigBacNet". All fields are combined with a logical 'AND'. */
export type Dw_FeedsConfigBacNet_Bool_Exp = {
  CalculateDelta?: Maybe<Boolean_Comparison_Exp>;
  Channel?: Maybe<Dw_Channels_Bool_Exp>;
  ChannelId?: Maybe<Uuid_Comparison_Exp>;
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  DeviceId?: Maybe<String_Comparison_Exp>;
  Feed?: Maybe<Dw_Feeds_Bool_Exp>;
  FeedId?: Maybe<Uuid_Comparison_Exp>;
  ObjectId?: Maybe<String_Comparison_Exp>;
  ObjectInstance?: Maybe<Int_Comparison_Exp>;
  ReadFrequencySeconds?: Maybe<Int_Comparison_Exp>;
  Status?: Maybe<Smallint_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  _and?: Maybe<Array<Dw_FeedsConfigBacNet_Bool_Exp>>;
  _not?: Maybe<Dw_FeedsConfigBacNet_Bool_Exp>;
  _or?: Maybe<Array<Dw_FeedsConfigBacNet_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.FeedsConfigBacNet" */
export enum Dw_FeedsConfigBacNet_Constraint {
  /** unique or primary key constraint */
  FeedsconfigbacnetPk = 'feedsconfigbacnet_pk'
}

/** input type for incrementing numeric columns in table "dw.FeedsConfigBacNet" */
export type Dw_FeedsConfigBacNet_Inc_Input = {
  ObjectInstance?: Maybe<Scalars['Int']>;
  ReadFrequencySeconds?: Maybe<Scalars['Int']>;
  Status?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.FeedsConfigBacNet" */
export type Dw_FeedsConfigBacNet_Insert_Input = {
  CalculateDelta?: Maybe<Scalars['Boolean']>;
  Channel?: Maybe<Dw_Channels_Obj_Rel_Insert_Input>;
  ChannelId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DeviceId?: Maybe<Scalars['String']>;
  Feed?: Maybe<Dw_Feeds_Obj_Rel_Insert_Input>;
  FeedId?: Maybe<Scalars['uuid']>;
  ObjectId?: Maybe<Scalars['String']>;
  ObjectInstance?: Maybe<Scalars['Int']>;
  ReadFrequencySeconds?: Maybe<Scalars['Int']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Dw_FeedsConfigBacNet_Max_Fields = {
  __typename?: 'dw_FeedsConfigBacNet_max_fields';
  ChannelId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DeviceId?: Maybe<Scalars['String']>;
  FeedId?: Maybe<Scalars['uuid']>;
  ObjectId?: Maybe<Scalars['String']>;
  ObjectInstance?: Maybe<Scalars['Int']>;
  ReadFrequencySeconds?: Maybe<Scalars['Int']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "dw.FeedsConfigBacNet" */
export type Dw_FeedsConfigBacNet_Max_Order_By = {
  ChannelId?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  DeviceId?: Maybe<Order_By>;
  FeedId?: Maybe<Order_By>;
  ObjectId?: Maybe<Order_By>;
  ObjectInstance?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_FeedsConfigBacNet_Min_Fields = {
  __typename?: 'dw_FeedsConfigBacNet_min_fields';
  ChannelId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DeviceId?: Maybe<Scalars['String']>;
  FeedId?: Maybe<Scalars['uuid']>;
  ObjectId?: Maybe<Scalars['String']>;
  ObjectInstance?: Maybe<Scalars['Int']>;
  ReadFrequencySeconds?: Maybe<Scalars['Int']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "dw.FeedsConfigBacNet" */
export type Dw_FeedsConfigBacNet_Min_Order_By = {
  ChannelId?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  DeviceId?: Maybe<Order_By>;
  FeedId?: Maybe<Order_By>;
  ObjectId?: Maybe<Order_By>;
  ObjectInstance?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.FeedsConfigBacNet" */
export type Dw_FeedsConfigBacNet_Mutation_Response = {
  __typename?: 'dw_FeedsConfigBacNet_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_FeedsConfigBacNet>;
};

/** on conflict condition type for table "dw.FeedsConfigBacNet" */
export type Dw_FeedsConfigBacNet_On_Conflict = {
  constraint: Dw_FeedsConfigBacNet_Constraint;
  update_columns?: Array<Dw_FeedsConfigBacNet_Update_Column>;
  where?: Maybe<Dw_FeedsConfigBacNet_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.FeedsConfigBacNet". */
export type Dw_FeedsConfigBacNet_Order_By = {
  CalculateDelta?: Maybe<Order_By>;
  Channel?: Maybe<Dw_Channels_Order_By>;
  ChannelId?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  DeviceId?: Maybe<Order_By>;
  Feed?: Maybe<Dw_Feeds_Order_By>;
  FeedId?: Maybe<Order_By>;
  ObjectId?: Maybe<Order_By>;
  ObjectInstance?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_FeedsConfigBacNet */
export type Dw_FeedsConfigBacNet_Pk_Columns_Input = {
  ChannelId: Scalars['uuid'];
  FeedId: Scalars['uuid'];
};

/** select columns of table "dw.FeedsConfigBacNet" */
export enum Dw_FeedsConfigBacNet_Select_Column {
  /** column name */
  CalculateDelta = 'CalculateDelta',
  /** column name */
  ChannelId = 'ChannelId',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  DeviceId = 'DeviceId',
  /** column name */
  FeedId = 'FeedId',
  /** column name */
  ObjectId = 'ObjectId',
  /** column name */
  ObjectInstance = 'ObjectInstance',
  /** column name */
  ReadFrequencySeconds = 'ReadFrequencySeconds',
  /** column name */
  Status = 'Status',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** input type for updating data in table "dw.FeedsConfigBacNet" */
export type Dw_FeedsConfigBacNet_Set_Input = {
  CalculateDelta?: Maybe<Scalars['Boolean']>;
  ChannelId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DeviceId?: Maybe<Scalars['String']>;
  FeedId?: Maybe<Scalars['uuid']>;
  ObjectId?: Maybe<Scalars['String']>;
  ObjectInstance?: Maybe<Scalars['Int']>;
  ReadFrequencySeconds?: Maybe<Scalars['Int']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Dw_FeedsConfigBacNet_Stddev_Fields = {
  __typename?: 'dw_FeedsConfigBacNet_stddev_fields';
  ObjectInstance?: Maybe<Scalars['Float']>;
  ReadFrequencySeconds?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.FeedsConfigBacNet" */
export type Dw_FeedsConfigBacNet_Stddev_Order_By = {
  ObjectInstance?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_FeedsConfigBacNet_Stddev_Pop_Fields = {
  __typename?: 'dw_FeedsConfigBacNet_stddev_pop_fields';
  ObjectInstance?: Maybe<Scalars['Float']>;
  ReadFrequencySeconds?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.FeedsConfigBacNet" */
export type Dw_FeedsConfigBacNet_Stddev_Pop_Order_By = {
  ObjectInstance?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_FeedsConfigBacNet_Stddev_Samp_Fields = {
  __typename?: 'dw_FeedsConfigBacNet_stddev_samp_fields';
  ObjectInstance?: Maybe<Scalars['Float']>;
  ReadFrequencySeconds?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.FeedsConfigBacNet" */
export type Dw_FeedsConfigBacNet_Stddev_Samp_Order_By = {
  ObjectInstance?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_FeedsConfigBacNet_Sum_Fields = {
  __typename?: 'dw_FeedsConfigBacNet_sum_fields';
  ObjectInstance?: Maybe<Scalars['Int']>;
  ReadFrequencySeconds?: Maybe<Scalars['Int']>;
  Status?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "dw.FeedsConfigBacNet" */
export type Dw_FeedsConfigBacNet_Sum_Order_By = {
  ObjectInstance?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** update columns of table "dw.FeedsConfigBacNet" */
export enum Dw_FeedsConfigBacNet_Update_Column {
  /** column name */
  CalculateDelta = 'CalculateDelta',
  /** column name */
  ChannelId = 'ChannelId',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  DeviceId = 'DeviceId',
  /** column name */
  FeedId = 'FeedId',
  /** column name */
  ObjectId = 'ObjectId',
  /** column name */
  ObjectInstance = 'ObjectInstance',
  /** column name */
  ReadFrequencySeconds = 'ReadFrequencySeconds',
  /** column name */
  Status = 'Status',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** aggregate var_pop on columns */
export type Dw_FeedsConfigBacNet_Var_Pop_Fields = {
  __typename?: 'dw_FeedsConfigBacNet_var_pop_fields';
  ObjectInstance?: Maybe<Scalars['Float']>;
  ReadFrequencySeconds?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.FeedsConfigBacNet" */
export type Dw_FeedsConfigBacNet_Var_Pop_Order_By = {
  ObjectInstance?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_FeedsConfigBacNet_Var_Samp_Fields = {
  __typename?: 'dw_FeedsConfigBacNet_var_samp_fields';
  ObjectInstance?: Maybe<Scalars['Float']>;
  ReadFrequencySeconds?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.FeedsConfigBacNet" */
export type Dw_FeedsConfigBacNet_Var_Samp_Order_By = {
  ObjectInstance?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_FeedsConfigBacNet_Variance_Fields = {
  __typename?: 'dw_FeedsConfigBacNet_variance_fields';
  ObjectInstance?: Maybe<Scalars['Float']>;
  ReadFrequencySeconds?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.FeedsConfigBacNet" */
export type Dw_FeedsConfigBacNet_Variance_Order_By = {
  ObjectInstance?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** columns and relationships of "dw.FeedsConfigChannels" */
export type Dw_FeedsConfigChannels = {
  __typename?: 'dw_FeedsConfigChannels';
  CalculateDelta?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  Channel: Dw_Channels;
  ChannelId: Scalars['uuid'];
  CreatedOn: Scalars['timestamp'];
  DeviceAddress: Scalars['numeric'];
  IsBigEndian?: Maybe<Scalars['Boolean']>;
  ReadFrequencySeconds: Scalars['Int'];
  RegisterAddress: Scalars['Int'];
  RegisterCount: Scalars['Int'];
  RtuBaudRate?: Maybe<Scalars['Int']>;
  RtuDataBits?: Maybe<Scalars['Int']>;
  RtuParity?: Maybe<Scalars['String']>;
  RtuSerialPort?: Maybe<Scalars['String']>;
  RtuStopBits?: Maybe<Scalars['String']>;
  ScalingFactor: Scalars['numeric'];
  Status?: Maybe<Scalars['String']>;
  TcpIpAddress?: Maybe<Scalars['String']>;
  TcpPort?: Maybe<Scalars['Int']>;
  UpdatedOn: Scalars['timestamp'];
};

/** aggregated selection of "dw.FeedsConfigChannels" */
export type Dw_FeedsConfigChannels_Aggregate = {
  __typename?: 'dw_FeedsConfigChannels_aggregate';
  aggregate?: Maybe<Dw_FeedsConfigChannels_Aggregate_Fields>;
  nodes: Array<Dw_FeedsConfigChannels>;
};

/** aggregate fields of "dw.FeedsConfigChannels" */
export type Dw_FeedsConfigChannels_Aggregate_Fields = {
  __typename?: 'dw_FeedsConfigChannels_aggregate_fields';
  avg?: Maybe<Dw_FeedsConfigChannels_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_FeedsConfigChannels_Max_Fields>;
  min?: Maybe<Dw_FeedsConfigChannels_Min_Fields>;
  stddev?: Maybe<Dw_FeedsConfigChannels_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_FeedsConfigChannels_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_FeedsConfigChannels_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_FeedsConfigChannels_Sum_Fields>;
  var_pop?: Maybe<Dw_FeedsConfigChannels_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_FeedsConfigChannels_Var_Samp_Fields>;
  variance?: Maybe<Dw_FeedsConfigChannels_Variance_Fields>;
};


/** aggregate fields of "dw.FeedsConfigChannels" */
export type Dw_FeedsConfigChannels_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_FeedsConfigChannels_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.FeedsConfigChannels" */
export type Dw_FeedsConfigChannels_Aggregate_Order_By = {
  avg?: Maybe<Dw_FeedsConfigChannels_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_FeedsConfigChannels_Max_Order_By>;
  min?: Maybe<Dw_FeedsConfigChannels_Min_Order_By>;
  stddev?: Maybe<Dw_FeedsConfigChannels_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_FeedsConfigChannels_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_FeedsConfigChannels_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_FeedsConfigChannels_Sum_Order_By>;
  var_pop?: Maybe<Dw_FeedsConfigChannels_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_FeedsConfigChannels_Var_Samp_Order_By>;
  variance?: Maybe<Dw_FeedsConfigChannels_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.FeedsConfigChannels" */
export type Dw_FeedsConfigChannels_Arr_Rel_Insert_Input = {
  data: Array<Dw_FeedsConfigChannels_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_FeedsConfigChannels_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_FeedsConfigChannels_Avg_Fields = {
  __typename?: 'dw_FeedsConfigChannels_avg_fields';
  DeviceAddress?: Maybe<Scalars['Float']>;
  ReadFrequencySeconds?: Maybe<Scalars['Float']>;
  RegisterAddress?: Maybe<Scalars['Float']>;
  RegisterCount?: Maybe<Scalars['Float']>;
  RtuBaudRate?: Maybe<Scalars['Float']>;
  RtuDataBits?: Maybe<Scalars['Float']>;
  ScalingFactor?: Maybe<Scalars['Float']>;
  TcpPort?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.FeedsConfigChannels" */
export type Dw_FeedsConfigChannels_Avg_Order_By = {
  DeviceAddress?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  RegisterAddress?: Maybe<Order_By>;
  RegisterCount?: Maybe<Order_By>;
  RtuBaudRate?: Maybe<Order_By>;
  RtuDataBits?: Maybe<Order_By>;
  ScalingFactor?: Maybe<Order_By>;
  TcpPort?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.FeedsConfigChannels". All fields are combined with a logical 'AND'. */
export type Dw_FeedsConfigChannels_Bool_Exp = {
  CalculateDelta?: Maybe<Boolean_Comparison_Exp>;
  Channel?: Maybe<Dw_Channels_Bool_Exp>;
  ChannelId?: Maybe<Uuid_Comparison_Exp>;
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  DeviceAddress?: Maybe<Numeric_Comparison_Exp>;
  IsBigEndian?: Maybe<Boolean_Comparison_Exp>;
  ReadFrequencySeconds?: Maybe<Int_Comparison_Exp>;
  RegisterAddress?: Maybe<Int_Comparison_Exp>;
  RegisterCount?: Maybe<Int_Comparison_Exp>;
  RtuBaudRate?: Maybe<Int_Comparison_Exp>;
  RtuDataBits?: Maybe<Int_Comparison_Exp>;
  RtuParity?: Maybe<String_Comparison_Exp>;
  RtuSerialPort?: Maybe<String_Comparison_Exp>;
  RtuStopBits?: Maybe<String_Comparison_Exp>;
  ScalingFactor?: Maybe<Numeric_Comparison_Exp>;
  Status?: Maybe<String_Comparison_Exp>;
  TcpIpAddress?: Maybe<String_Comparison_Exp>;
  TcpPort?: Maybe<Int_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  _and?: Maybe<Array<Dw_FeedsConfigChannels_Bool_Exp>>;
  _not?: Maybe<Dw_FeedsConfigChannels_Bool_Exp>;
  _or?: Maybe<Array<Dw_FeedsConfigChannels_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.FeedsConfigChannels" */
export enum Dw_FeedsConfigChannels_Constraint {
  /** unique or primary key constraint */
  FeedsconfigchannelsPk = 'feedsconfigchannels_pk'
}

/** input type for incrementing numeric columns in table "dw.FeedsConfigChannels" */
export type Dw_FeedsConfigChannels_Inc_Input = {
  DeviceAddress?: Maybe<Scalars['numeric']>;
  ReadFrequencySeconds?: Maybe<Scalars['Int']>;
  RegisterAddress?: Maybe<Scalars['Int']>;
  RegisterCount?: Maybe<Scalars['Int']>;
  RtuBaudRate?: Maybe<Scalars['Int']>;
  RtuDataBits?: Maybe<Scalars['Int']>;
  ScalingFactor?: Maybe<Scalars['numeric']>;
  TcpPort?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "dw.FeedsConfigChannels" */
export type Dw_FeedsConfigChannels_Insert_Input = {
  CalculateDelta?: Maybe<Scalars['Boolean']>;
  Channel?: Maybe<Dw_Channels_Obj_Rel_Insert_Input>;
  ChannelId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DeviceAddress?: Maybe<Scalars['numeric']>;
  IsBigEndian?: Maybe<Scalars['Boolean']>;
  ReadFrequencySeconds?: Maybe<Scalars['Int']>;
  RegisterAddress?: Maybe<Scalars['Int']>;
  RegisterCount?: Maybe<Scalars['Int']>;
  RtuBaudRate?: Maybe<Scalars['Int']>;
  RtuDataBits?: Maybe<Scalars['Int']>;
  RtuParity?: Maybe<Scalars['String']>;
  RtuSerialPort?: Maybe<Scalars['String']>;
  RtuStopBits?: Maybe<Scalars['String']>;
  ScalingFactor?: Maybe<Scalars['numeric']>;
  Status?: Maybe<Scalars['String']>;
  TcpIpAddress?: Maybe<Scalars['String']>;
  TcpPort?: Maybe<Scalars['Int']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Dw_FeedsConfigChannels_Max_Fields = {
  __typename?: 'dw_FeedsConfigChannels_max_fields';
  ChannelId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DeviceAddress?: Maybe<Scalars['numeric']>;
  ReadFrequencySeconds?: Maybe<Scalars['Int']>;
  RegisterAddress?: Maybe<Scalars['Int']>;
  RegisterCount?: Maybe<Scalars['Int']>;
  RtuBaudRate?: Maybe<Scalars['Int']>;
  RtuDataBits?: Maybe<Scalars['Int']>;
  RtuParity?: Maybe<Scalars['String']>;
  RtuSerialPort?: Maybe<Scalars['String']>;
  RtuStopBits?: Maybe<Scalars['String']>;
  ScalingFactor?: Maybe<Scalars['numeric']>;
  Status?: Maybe<Scalars['String']>;
  TcpIpAddress?: Maybe<Scalars['String']>;
  TcpPort?: Maybe<Scalars['Int']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "dw.FeedsConfigChannels" */
export type Dw_FeedsConfigChannels_Max_Order_By = {
  ChannelId?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  DeviceAddress?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  RegisterAddress?: Maybe<Order_By>;
  RegisterCount?: Maybe<Order_By>;
  RtuBaudRate?: Maybe<Order_By>;
  RtuDataBits?: Maybe<Order_By>;
  RtuParity?: Maybe<Order_By>;
  RtuSerialPort?: Maybe<Order_By>;
  RtuStopBits?: Maybe<Order_By>;
  ScalingFactor?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  TcpIpAddress?: Maybe<Order_By>;
  TcpPort?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_FeedsConfigChannels_Min_Fields = {
  __typename?: 'dw_FeedsConfigChannels_min_fields';
  ChannelId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DeviceAddress?: Maybe<Scalars['numeric']>;
  ReadFrequencySeconds?: Maybe<Scalars['Int']>;
  RegisterAddress?: Maybe<Scalars['Int']>;
  RegisterCount?: Maybe<Scalars['Int']>;
  RtuBaudRate?: Maybe<Scalars['Int']>;
  RtuDataBits?: Maybe<Scalars['Int']>;
  RtuParity?: Maybe<Scalars['String']>;
  RtuSerialPort?: Maybe<Scalars['String']>;
  RtuStopBits?: Maybe<Scalars['String']>;
  ScalingFactor?: Maybe<Scalars['numeric']>;
  Status?: Maybe<Scalars['String']>;
  TcpIpAddress?: Maybe<Scalars['String']>;
  TcpPort?: Maybe<Scalars['Int']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "dw.FeedsConfigChannels" */
export type Dw_FeedsConfigChannels_Min_Order_By = {
  ChannelId?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  DeviceAddress?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  RegisterAddress?: Maybe<Order_By>;
  RegisterCount?: Maybe<Order_By>;
  RtuBaudRate?: Maybe<Order_By>;
  RtuDataBits?: Maybe<Order_By>;
  RtuParity?: Maybe<Order_By>;
  RtuSerialPort?: Maybe<Order_By>;
  RtuStopBits?: Maybe<Order_By>;
  ScalingFactor?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  TcpIpAddress?: Maybe<Order_By>;
  TcpPort?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.FeedsConfigChannels" */
export type Dw_FeedsConfigChannels_Mutation_Response = {
  __typename?: 'dw_FeedsConfigChannels_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_FeedsConfigChannels>;
};

/** input type for inserting object relation for remote table "dw.FeedsConfigChannels" */
export type Dw_FeedsConfigChannels_Obj_Rel_Insert_Input = {
  data: Dw_FeedsConfigChannels_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_FeedsConfigChannels_On_Conflict>;
};

/** on conflict condition type for table "dw.FeedsConfigChannels" */
export type Dw_FeedsConfigChannels_On_Conflict = {
  constraint: Dw_FeedsConfigChannels_Constraint;
  update_columns?: Array<Dw_FeedsConfigChannels_Update_Column>;
  where?: Maybe<Dw_FeedsConfigChannels_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.FeedsConfigChannels". */
export type Dw_FeedsConfigChannels_Order_By = {
  CalculateDelta?: Maybe<Order_By>;
  Channel?: Maybe<Dw_Channels_Order_By>;
  ChannelId?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  DeviceAddress?: Maybe<Order_By>;
  IsBigEndian?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  RegisterAddress?: Maybe<Order_By>;
  RegisterCount?: Maybe<Order_By>;
  RtuBaudRate?: Maybe<Order_By>;
  RtuDataBits?: Maybe<Order_By>;
  RtuParity?: Maybe<Order_By>;
  RtuSerialPort?: Maybe<Order_By>;
  RtuStopBits?: Maybe<Order_By>;
  ScalingFactor?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  TcpIpAddress?: Maybe<Order_By>;
  TcpPort?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_FeedsConfigChannels */
export type Dw_FeedsConfigChannels_Pk_Columns_Input = {
  ChannelId: Scalars['uuid'];
};

/** select columns of table "dw.FeedsConfigChannels" */
export enum Dw_FeedsConfigChannels_Select_Column {
  /** column name */
  CalculateDelta = 'CalculateDelta',
  /** column name */
  ChannelId = 'ChannelId',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  DeviceAddress = 'DeviceAddress',
  /** column name */
  IsBigEndian = 'IsBigEndian',
  /** column name */
  ReadFrequencySeconds = 'ReadFrequencySeconds',
  /** column name */
  RegisterAddress = 'RegisterAddress',
  /** column name */
  RegisterCount = 'RegisterCount',
  /** column name */
  RtuBaudRate = 'RtuBaudRate',
  /** column name */
  RtuDataBits = 'RtuDataBits',
  /** column name */
  RtuParity = 'RtuParity',
  /** column name */
  RtuSerialPort = 'RtuSerialPort',
  /** column name */
  RtuStopBits = 'RtuStopBits',
  /** column name */
  ScalingFactor = 'ScalingFactor',
  /** column name */
  Status = 'Status',
  /** column name */
  TcpIpAddress = 'TcpIpAddress',
  /** column name */
  TcpPort = 'TcpPort',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** input type for updating data in table "dw.FeedsConfigChannels" */
export type Dw_FeedsConfigChannels_Set_Input = {
  CalculateDelta?: Maybe<Scalars['Boolean']>;
  ChannelId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DeviceAddress?: Maybe<Scalars['numeric']>;
  IsBigEndian?: Maybe<Scalars['Boolean']>;
  ReadFrequencySeconds?: Maybe<Scalars['Int']>;
  RegisterAddress?: Maybe<Scalars['Int']>;
  RegisterCount?: Maybe<Scalars['Int']>;
  RtuBaudRate?: Maybe<Scalars['Int']>;
  RtuDataBits?: Maybe<Scalars['Int']>;
  RtuParity?: Maybe<Scalars['String']>;
  RtuSerialPort?: Maybe<Scalars['String']>;
  RtuStopBits?: Maybe<Scalars['String']>;
  ScalingFactor?: Maybe<Scalars['numeric']>;
  Status?: Maybe<Scalars['String']>;
  TcpIpAddress?: Maybe<Scalars['String']>;
  TcpPort?: Maybe<Scalars['Int']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Dw_FeedsConfigChannels_Stddev_Fields = {
  __typename?: 'dw_FeedsConfigChannels_stddev_fields';
  DeviceAddress?: Maybe<Scalars['Float']>;
  ReadFrequencySeconds?: Maybe<Scalars['Float']>;
  RegisterAddress?: Maybe<Scalars['Float']>;
  RegisterCount?: Maybe<Scalars['Float']>;
  RtuBaudRate?: Maybe<Scalars['Float']>;
  RtuDataBits?: Maybe<Scalars['Float']>;
  ScalingFactor?: Maybe<Scalars['Float']>;
  TcpPort?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.FeedsConfigChannels" */
export type Dw_FeedsConfigChannels_Stddev_Order_By = {
  DeviceAddress?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  RegisterAddress?: Maybe<Order_By>;
  RegisterCount?: Maybe<Order_By>;
  RtuBaudRate?: Maybe<Order_By>;
  RtuDataBits?: Maybe<Order_By>;
  ScalingFactor?: Maybe<Order_By>;
  TcpPort?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_FeedsConfigChannels_Stddev_Pop_Fields = {
  __typename?: 'dw_FeedsConfigChannels_stddev_pop_fields';
  DeviceAddress?: Maybe<Scalars['Float']>;
  ReadFrequencySeconds?: Maybe<Scalars['Float']>;
  RegisterAddress?: Maybe<Scalars['Float']>;
  RegisterCount?: Maybe<Scalars['Float']>;
  RtuBaudRate?: Maybe<Scalars['Float']>;
  RtuDataBits?: Maybe<Scalars['Float']>;
  ScalingFactor?: Maybe<Scalars['Float']>;
  TcpPort?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.FeedsConfigChannels" */
export type Dw_FeedsConfigChannels_Stddev_Pop_Order_By = {
  DeviceAddress?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  RegisterAddress?: Maybe<Order_By>;
  RegisterCount?: Maybe<Order_By>;
  RtuBaudRate?: Maybe<Order_By>;
  RtuDataBits?: Maybe<Order_By>;
  ScalingFactor?: Maybe<Order_By>;
  TcpPort?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_FeedsConfigChannels_Stddev_Samp_Fields = {
  __typename?: 'dw_FeedsConfigChannels_stddev_samp_fields';
  DeviceAddress?: Maybe<Scalars['Float']>;
  ReadFrequencySeconds?: Maybe<Scalars['Float']>;
  RegisterAddress?: Maybe<Scalars['Float']>;
  RegisterCount?: Maybe<Scalars['Float']>;
  RtuBaudRate?: Maybe<Scalars['Float']>;
  RtuDataBits?: Maybe<Scalars['Float']>;
  ScalingFactor?: Maybe<Scalars['Float']>;
  TcpPort?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.FeedsConfigChannels" */
export type Dw_FeedsConfigChannels_Stddev_Samp_Order_By = {
  DeviceAddress?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  RegisterAddress?: Maybe<Order_By>;
  RegisterCount?: Maybe<Order_By>;
  RtuBaudRate?: Maybe<Order_By>;
  RtuDataBits?: Maybe<Order_By>;
  ScalingFactor?: Maybe<Order_By>;
  TcpPort?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_FeedsConfigChannels_Sum_Fields = {
  __typename?: 'dw_FeedsConfigChannels_sum_fields';
  DeviceAddress?: Maybe<Scalars['numeric']>;
  ReadFrequencySeconds?: Maybe<Scalars['Int']>;
  RegisterAddress?: Maybe<Scalars['Int']>;
  RegisterCount?: Maybe<Scalars['Int']>;
  RtuBaudRate?: Maybe<Scalars['Int']>;
  RtuDataBits?: Maybe<Scalars['Int']>;
  ScalingFactor?: Maybe<Scalars['numeric']>;
  TcpPort?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "dw.FeedsConfigChannels" */
export type Dw_FeedsConfigChannels_Sum_Order_By = {
  DeviceAddress?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  RegisterAddress?: Maybe<Order_By>;
  RegisterCount?: Maybe<Order_By>;
  RtuBaudRate?: Maybe<Order_By>;
  RtuDataBits?: Maybe<Order_By>;
  ScalingFactor?: Maybe<Order_By>;
  TcpPort?: Maybe<Order_By>;
};

/** update columns of table "dw.FeedsConfigChannels" */
export enum Dw_FeedsConfigChannels_Update_Column {
  /** column name */
  CalculateDelta = 'CalculateDelta',
  /** column name */
  ChannelId = 'ChannelId',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  DeviceAddress = 'DeviceAddress',
  /** column name */
  IsBigEndian = 'IsBigEndian',
  /** column name */
  ReadFrequencySeconds = 'ReadFrequencySeconds',
  /** column name */
  RegisterAddress = 'RegisterAddress',
  /** column name */
  RegisterCount = 'RegisterCount',
  /** column name */
  RtuBaudRate = 'RtuBaudRate',
  /** column name */
  RtuDataBits = 'RtuDataBits',
  /** column name */
  RtuParity = 'RtuParity',
  /** column name */
  RtuSerialPort = 'RtuSerialPort',
  /** column name */
  RtuStopBits = 'RtuStopBits',
  /** column name */
  ScalingFactor = 'ScalingFactor',
  /** column name */
  Status = 'Status',
  /** column name */
  TcpIpAddress = 'TcpIpAddress',
  /** column name */
  TcpPort = 'TcpPort',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** aggregate var_pop on columns */
export type Dw_FeedsConfigChannels_Var_Pop_Fields = {
  __typename?: 'dw_FeedsConfigChannels_var_pop_fields';
  DeviceAddress?: Maybe<Scalars['Float']>;
  ReadFrequencySeconds?: Maybe<Scalars['Float']>;
  RegisterAddress?: Maybe<Scalars['Float']>;
  RegisterCount?: Maybe<Scalars['Float']>;
  RtuBaudRate?: Maybe<Scalars['Float']>;
  RtuDataBits?: Maybe<Scalars['Float']>;
  ScalingFactor?: Maybe<Scalars['Float']>;
  TcpPort?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.FeedsConfigChannels" */
export type Dw_FeedsConfigChannels_Var_Pop_Order_By = {
  DeviceAddress?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  RegisterAddress?: Maybe<Order_By>;
  RegisterCount?: Maybe<Order_By>;
  RtuBaudRate?: Maybe<Order_By>;
  RtuDataBits?: Maybe<Order_By>;
  ScalingFactor?: Maybe<Order_By>;
  TcpPort?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_FeedsConfigChannels_Var_Samp_Fields = {
  __typename?: 'dw_FeedsConfigChannels_var_samp_fields';
  DeviceAddress?: Maybe<Scalars['Float']>;
  ReadFrequencySeconds?: Maybe<Scalars['Float']>;
  RegisterAddress?: Maybe<Scalars['Float']>;
  RegisterCount?: Maybe<Scalars['Float']>;
  RtuBaudRate?: Maybe<Scalars['Float']>;
  RtuDataBits?: Maybe<Scalars['Float']>;
  ScalingFactor?: Maybe<Scalars['Float']>;
  TcpPort?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.FeedsConfigChannels" */
export type Dw_FeedsConfigChannels_Var_Samp_Order_By = {
  DeviceAddress?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  RegisterAddress?: Maybe<Order_By>;
  RegisterCount?: Maybe<Order_By>;
  RtuBaudRate?: Maybe<Order_By>;
  RtuDataBits?: Maybe<Order_By>;
  ScalingFactor?: Maybe<Order_By>;
  TcpPort?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_FeedsConfigChannels_Variance_Fields = {
  __typename?: 'dw_FeedsConfigChannels_variance_fields';
  DeviceAddress?: Maybe<Scalars['Float']>;
  ReadFrequencySeconds?: Maybe<Scalars['Float']>;
  RegisterAddress?: Maybe<Scalars['Float']>;
  RegisterCount?: Maybe<Scalars['Float']>;
  RtuBaudRate?: Maybe<Scalars['Float']>;
  RtuDataBits?: Maybe<Scalars['Float']>;
  ScalingFactor?: Maybe<Scalars['Float']>;
  TcpPort?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.FeedsConfigChannels" */
export type Dw_FeedsConfigChannels_Variance_Order_By = {
  DeviceAddress?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  RegisterAddress?: Maybe<Order_By>;
  RegisterCount?: Maybe<Order_By>;
  RtuBaudRate?: Maybe<Order_By>;
  RtuDataBits?: Maybe<Order_By>;
  ScalingFactor?: Maybe<Order_By>;
  TcpPort?: Maybe<Order_By>;
};

/** columns and relationships of "dw.FeedsConfigModbus" */
export type Dw_FeedsConfigModbus = {
  __typename?: 'dw_FeedsConfigModbus';
  CalculateDelta: Scalars['Boolean'];
  /** An object relationship */
  Channel: Dw_Channels;
  ChannelId: Scalars['uuid'];
  CreatedOn: Scalars['timestamp'];
  DeviceAddress: Scalars['numeric'];
  /** An object relationship */
  Feed: Dw_Feeds;
  FeedId: Scalars['uuid'];
  IsBigEndian: Scalars['Boolean'];
  ReadFrequencySeconds: Scalars['Int'];
  RegisterAddress: Scalars['Int'];
  RegisterCount: Scalars['Int'];
  RegisterType: Scalars['smallint'];
  RtuBaudRate?: Maybe<Scalars['Int']>;
  RtuDataBits?: Maybe<Scalars['Int']>;
  RtuParity?: Maybe<Scalars['String']>;
  RtuSerialPort?: Maybe<Scalars['String']>;
  RtuStopBits?: Maybe<Scalars['String']>;
  ScalingFactor: Scalars['numeric'];
  Status: Scalars['smallint'];
  TcpIpAddress?: Maybe<Scalars['String']>;
  TcpPort?: Maybe<Scalars['Int']>;
  UpdatedOn: Scalars['timestamp'];
};

/** aggregated selection of "dw.FeedsConfigModbus" */
export type Dw_FeedsConfigModbus_Aggregate = {
  __typename?: 'dw_FeedsConfigModbus_aggregate';
  aggregate?: Maybe<Dw_FeedsConfigModbus_Aggregate_Fields>;
  nodes: Array<Dw_FeedsConfigModbus>;
};

/** aggregate fields of "dw.FeedsConfigModbus" */
export type Dw_FeedsConfigModbus_Aggregate_Fields = {
  __typename?: 'dw_FeedsConfigModbus_aggregate_fields';
  avg?: Maybe<Dw_FeedsConfigModbus_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_FeedsConfigModbus_Max_Fields>;
  min?: Maybe<Dw_FeedsConfigModbus_Min_Fields>;
  stddev?: Maybe<Dw_FeedsConfigModbus_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_FeedsConfigModbus_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_FeedsConfigModbus_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_FeedsConfigModbus_Sum_Fields>;
  var_pop?: Maybe<Dw_FeedsConfigModbus_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_FeedsConfigModbus_Var_Samp_Fields>;
  variance?: Maybe<Dw_FeedsConfigModbus_Variance_Fields>;
};


/** aggregate fields of "dw.FeedsConfigModbus" */
export type Dw_FeedsConfigModbus_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_FeedsConfigModbus_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.FeedsConfigModbus" */
export type Dw_FeedsConfigModbus_Aggregate_Order_By = {
  avg?: Maybe<Dw_FeedsConfigModbus_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_FeedsConfigModbus_Max_Order_By>;
  min?: Maybe<Dw_FeedsConfigModbus_Min_Order_By>;
  stddev?: Maybe<Dw_FeedsConfigModbus_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_FeedsConfigModbus_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_FeedsConfigModbus_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_FeedsConfigModbus_Sum_Order_By>;
  var_pop?: Maybe<Dw_FeedsConfigModbus_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_FeedsConfigModbus_Var_Samp_Order_By>;
  variance?: Maybe<Dw_FeedsConfigModbus_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.FeedsConfigModbus" */
export type Dw_FeedsConfigModbus_Arr_Rel_Insert_Input = {
  data: Array<Dw_FeedsConfigModbus_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_FeedsConfigModbus_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_FeedsConfigModbus_Avg_Fields = {
  __typename?: 'dw_FeedsConfigModbus_avg_fields';
  DeviceAddress?: Maybe<Scalars['Float']>;
  ReadFrequencySeconds?: Maybe<Scalars['Float']>;
  RegisterAddress?: Maybe<Scalars['Float']>;
  RegisterCount?: Maybe<Scalars['Float']>;
  RegisterType?: Maybe<Scalars['Float']>;
  RtuBaudRate?: Maybe<Scalars['Float']>;
  RtuDataBits?: Maybe<Scalars['Float']>;
  ScalingFactor?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  TcpPort?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.FeedsConfigModbus" */
export type Dw_FeedsConfigModbus_Avg_Order_By = {
  DeviceAddress?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  RegisterAddress?: Maybe<Order_By>;
  RegisterCount?: Maybe<Order_By>;
  RegisterType?: Maybe<Order_By>;
  RtuBaudRate?: Maybe<Order_By>;
  RtuDataBits?: Maybe<Order_By>;
  ScalingFactor?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  TcpPort?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.FeedsConfigModbus". All fields are combined with a logical 'AND'. */
export type Dw_FeedsConfigModbus_Bool_Exp = {
  CalculateDelta?: Maybe<Boolean_Comparison_Exp>;
  Channel?: Maybe<Dw_Channels_Bool_Exp>;
  ChannelId?: Maybe<Uuid_Comparison_Exp>;
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  DeviceAddress?: Maybe<Numeric_Comparison_Exp>;
  Feed?: Maybe<Dw_Feeds_Bool_Exp>;
  FeedId?: Maybe<Uuid_Comparison_Exp>;
  IsBigEndian?: Maybe<Boolean_Comparison_Exp>;
  ReadFrequencySeconds?: Maybe<Int_Comparison_Exp>;
  RegisterAddress?: Maybe<Int_Comparison_Exp>;
  RegisterCount?: Maybe<Int_Comparison_Exp>;
  RegisterType?: Maybe<Smallint_Comparison_Exp>;
  RtuBaudRate?: Maybe<Int_Comparison_Exp>;
  RtuDataBits?: Maybe<Int_Comparison_Exp>;
  RtuParity?: Maybe<String_Comparison_Exp>;
  RtuSerialPort?: Maybe<String_Comparison_Exp>;
  RtuStopBits?: Maybe<String_Comparison_Exp>;
  ScalingFactor?: Maybe<Numeric_Comparison_Exp>;
  Status?: Maybe<Smallint_Comparison_Exp>;
  TcpIpAddress?: Maybe<String_Comparison_Exp>;
  TcpPort?: Maybe<Int_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  _and?: Maybe<Array<Dw_FeedsConfigModbus_Bool_Exp>>;
  _not?: Maybe<Dw_FeedsConfigModbus_Bool_Exp>;
  _or?: Maybe<Array<Dw_FeedsConfigModbus_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.FeedsConfigModbus" */
export enum Dw_FeedsConfigModbus_Constraint {
  /** unique or primary key constraint */
  FeedsconfigmodbusPk = 'feedsconfigmodbus_pk'
}

/** input type for incrementing numeric columns in table "dw.FeedsConfigModbus" */
export type Dw_FeedsConfigModbus_Inc_Input = {
  DeviceAddress?: Maybe<Scalars['numeric']>;
  ReadFrequencySeconds?: Maybe<Scalars['Int']>;
  RegisterAddress?: Maybe<Scalars['Int']>;
  RegisterCount?: Maybe<Scalars['Int']>;
  RegisterType?: Maybe<Scalars['smallint']>;
  RtuBaudRate?: Maybe<Scalars['Int']>;
  RtuDataBits?: Maybe<Scalars['Int']>;
  ScalingFactor?: Maybe<Scalars['numeric']>;
  Status?: Maybe<Scalars['smallint']>;
  TcpPort?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "dw.FeedsConfigModbus" */
export type Dw_FeedsConfigModbus_Insert_Input = {
  CalculateDelta?: Maybe<Scalars['Boolean']>;
  Channel?: Maybe<Dw_Channels_Obj_Rel_Insert_Input>;
  ChannelId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DeviceAddress?: Maybe<Scalars['numeric']>;
  Feed?: Maybe<Dw_Feeds_Obj_Rel_Insert_Input>;
  FeedId?: Maybe<Scalars['uuid']>;
  IsBigEndian?: Maybe<Scalars['Boolean']>;
  ReadFrequencySeconds?: Maybe<Scalars['Int']>;
  RegisterAddress?: Maybe<Scalars['Int']>;
  RegisterCount?: Maybe<Scalars['Int']>;
  RegisterType?: Maybe<Scalars['smallint']>;
  RtuBaudRate?: Maybe<Scalars['Int']>;
  RtuDataBits?: Maybe<Scalars['Int']>;
  RtuParity?: Maybe<Scalars['String']>;
  RtuSerialPort?: Maybe<Scalars['String']>;
  RtuStopBits?: Maybe<Scalars['String']>;
  ScalingFactor?: Maybe<Scalars['numeric']>;
  Status?: Maybe<Scalars['smallint']>;
  TcpIpAddress?: Maybe<Scalars['String']>;
  TcpPort?: Maybe<Scalars['Int']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Dw_FeedsConfigModbus_Max_Fields = {
  __typename?: 'dw_FeedsConfigModbus_max_fields';
  ChannelId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DeviceAddress?: Maybe<Scalars['numeric']>;
  FeedId?: Maybe<Scalars['uuid']>;
  ReadFrequencySeconds?: Maybe<Scalars['Int']>;
  RegisterAddress?: Maybe<Scalars['Int']>;
  RegisterCount?: Maybe<Scalars['Int']>;
  RegisterType?: Maybe<Scalars['smallint']>;
  RtuBaudRate?: Maybe<Scalars['Int']>;
  RtuDataBits?: Maybe<Scalars['Int']>;
  RtuParity?: Maybe<Scalars['String']>;
  RtuSerialPort?: Maybe<Scalars['String']>;
  RtuStopBits?: Maybe<Scalars['String']>;
  ScalingFactor?: Maybe<Scalars['numeric']>;
  Status?: Maybe<Scalars['smallint']>;
  TcpIpAddress?: Maybe<Scalars['String']>;
  TcpPort?: Maybe<Scalars['Int']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "dw.FeedsConfigModbus" */
export type Dw_FeedsConfigModbus_Max_Order_By = {
  ChannelId?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  DeviceAddress?: Maybe<Order_By>;
  FeedId?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  RegisterAddress?: Maybe<Order_By>;
  RegisterCount?: Maybe<Order_By>;
  RegisterType?: Maybe<Order_By>;
  RtuBaudRate?: Maybe<Order_By>;
  RtuDataBits?: Maybe<Order_By>;
  RtuParity?: Maybe<Order_By>;
  RtuSerialPort?: Maybe<Order_By>;
  RtuStopBits?: Maybe<Order_By>;
  ScalingFactor?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  TcpIpAddress?: Maybe<Order_By>;
  TcpPort?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_FeedsConfigModbus_Min_Fields = {
  __typename?: 'dw_FeedsConfigModbus_min_fields';
  ChannelId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DeviceAddress?: Maybe<Scalars['numeric']>;
  FeedId?: Maybe<Scalars['uuid']>;
  ReadFrequencySeconds?: Maybe<Scalars['Int']>;
  RegisterAddress?: Maybe<Scalars['Int']>;
  RegisterCount?: Maybe<Scalars['Int']>;
  RegisterType?: Maybe<Scalars['smallint']>;
  RtuBaudRate?: Maybe<Scalars['Int']>;
  RtuDataBits?: Maybe<Scalars['Int']>;
  RtuParity?: Maybe<Scalars['String']>;
  RtuSerialPort?: Maybe<Scalars['String']>;
  RtuStopBits?: Maybe<Scalars['String']>;
  ScalingFactor?: Maybe<Scalars['numeric']>;
  Status?: Maybe<Scalars['smallint']>;
  TcpIpAddress?: Maybe<Scalars['String']>;
  TcpPort?: Maybe<Scalars['Int']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "dw.FeedsConfigModbus" */
export type Dw_FeedsConfigModbus_Min_Order_By = {
  ChannelId?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  DeviceAddress?: Maybe<Order_By>;
  FeedId?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  RegisterAddress?: Maybe<Order_By>;
  RegisterCount?: Maybe<Order_By>;
  RegisterType?: Maybe<Order_By>;
  RtuBaudRate?: Maybe<Order_By>;
  RtuDataBits?: Maybe<Order_By>;
  RtuParity?: Maybe<Order_By>;
  RtuSerialPort?: Maybe<Order_By>;
  RtuStopBits?: Maybe<Order_By>;
  ScalingFactor?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  TcpIpAddress?: Maybe<Order_By>;
  TcpPort?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.FeedsConfigModbus" */
export type Dw_FeedsConfigModbus_Mutation_Response = {
  __typename?: 'dw_FeedsConfigModbus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_FeedsConfigModbus>;
};

/** on conflict condition type for table "dw.FeedsConfigModbus" */
export type Dw_FeedsConfigModbus_On_Conflict = {
  constraint: Dw_FeedsConfigModbus_Constraint;
  update_columns?: Array<Dw_FeedsConfigModbus_Update_Column>;
  where?: Maybe<Dw_FeedsConfigModbus_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.FeedsConfigModbus". */
export type Dw_FeedsConfigModbus_Order_By = {
  CalculateDelta?: Maybe<Order_By>;
  Channel?: Maybe<Dw_Channels_Order_By>;
  ChannelId?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  DeviceAddress?: Maybe<Order_By>;
  Feed?: Maybe<Dw_Feeds_Order_By>;
  FeedId?: Maybe<Order_By>;
  IsBigEndian?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  RegisterAddress?: Maybe<Order_By>;
  RegisterCount?: Maybe<Order_By>;
  RegisterType?: Maybe<Order_By>;
  RtuBaudRate?: Maybe<Order_By>;
  RtuDataBits?: Maybe<Order_By>;
  RtuParity?: Maybe<Order_By>;
  RtuSerialPort?: Maybe<Order_By>;
  RtuStopBits?: Maybe<Order_By>;
  ScalingFactor?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  TcpIpAddress?: Maybe<Order_By>;
  TcpPort?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_FeedsConfigModbus */
export type Dw_FeedsConfigModbus_Pk_Columns_Input = {
  ChannelId: Scalars['uuid'];
  FeedId: Scalars['uuid'];
};

/** select columns of table "dw.FeedsConfigModbus" */
export enum Dw_FeedsConfigModbus_Select_Column {
  /** column name */
  CalculateDelta = 'CalculateDelta',
  /** column name */
  ChannelId = 'ChannelId',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  DeviceAddress = 'DeviceAddress',
  /** column name */
  FeedId = 'FeedId',
  /** column name */
  IsBigEndian = 'IsBigEndian',
  /** column name */
  ReadFrequencySeconds = 'ReadFrequencySeconds',
  /** column name */
  RegisterAddress = 'RegisterAddress',
  /** column name */
  RegisterCount = 'RegisterCount',
  /** column name */
  RegisterType = 'RegisterType',
  /** column name */
  RtuBaudRate = 'RtuBaudRate',
  /** column name */
  RtuDataBits = 'RtuDataBits',
  /** column name */
  RtuParity = 'RtuParity',
  /** column name */
  RtuSerialPort = 'RtuSerialPort',
  /** column name */
  RtuStopBits = 'RtuStopBits',
  /** column name */
  ScalingFactor = 'ScalingFactor',
  /** column name */
  Status = 'Status',
  /** column name */
  TcpIpAddress = 'TcpIpAddress',
  /** column name */
  TcpPort = 'TcpPort',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** input type for updating data in table "dw.FeedsConfigModbus" */
export type Dw_FeedsConfigModbus_Set_Input = {
  CalculateDelta?: Maybe<Scalars['Boolean']>;
  ChannelId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DeviceAddress?: Maybe<Scalars['numeric']>;
  FeedId?: Maybe<Scalars['uuid']>;
  IsBigEndian?: Maybe<Scalars['Boolean']>;
  ReadFrequencySeconds?: Maybe<Scalars['Int']>;
  RegisterAddress?: Maybe<Scalars['Int']>;
  RegisterCount?: Maybe<Scalars['Int']>;
  RegisterType?: Maybe<Scalars['smallint']>;
  RtuBaudRate?: Maybe<Scalars['Int']>;
  RtuDataBits?: Maybe<Scalars['Int']>;
  RtuParity?: Maybe<Scalars['String']>;
  RtuSerialPort?: Maybe<Scalars['String']>;
  RtuStopBits?: Maybe<Scalars['String']>;
  ScalingFactor?: Maybe<Scalars['numeric']>;
  Status?: Maybe<Scalars['smallint']>;
  TcpIpAddress?: Maybe<Scalars['String']>;
  TcpPort?: Maybe<Scalars['Int']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Dw_FeedsConfigModbus_Stddev_Fields = {
  __typename?: 'dw_FeedsConfigModbus_stddev_fields';
  DeviceAddress?: Maybe<Scalars['Float']>;
  ReadFrequencySeconds?: Maybe<Scalars['Float']>;
  RegisterAddress?: Maybe<Scalars['Float']>;
  RegisterCount?: Maybe<Scalars['Float']>;
  RegisterType?: Maybe<Scalars['Float']>;
  RtuBaudRate?: Maybe<Scalars['Float']>;
  RtuDataBits?: Maybe<Scalars['Float']>;
  ScalingFactor?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  TcpPort?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.FeedsConfigModbus" */
export type Dw_FeedsConfigModbus_Stddev_Order_By = {
  DeviceAddress?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  RegisterAddress?: Maybe<Order_By>;
  RegisterCount?: Maybe<Order_By>;
  RegisterType?: Maybe<Order_By>;
  RtuBaudRate?: Maybe<Order_By>;
  RtuDataBits?: Maybe<Order_By>;
  ScalingFactor?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  TcpPort?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_FeedsConfigModbus_Stddev_Pop_Fields = {
  __typename?: 'dw_FeedsConfigModbus_stddev_pop_fields';
  DeviceAddress?: Maybe<Scalars['Float']>;
  ReadFrequencySeconds?: Maybe<Scalars['Float']>;
  RegisterAddress?: Maybe<Scalars['Float']>;
  RegisterCount?: Maybe<Scalars['Float']>;
  RegisterType?: Maybe<Scalars['Float']>;
  RtuBaudRate?: Maybe<Scalars['Float']>;
  RtuDataBits?: Maybe<Scalars['Float']>;
  ScalingFactor?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  TcpPort?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.FeedsConfigModbus" */
export type Dw_FeedsConfigModbus_Stddev_Pop_Order_By = {
  DeviceAddress?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  RegisterAddress?: Maybe<Order_By>;
  RegisterCount?: Maybe<Order_By>;
  RegisterType?: Maybe<Order_By>;
  RtuBaudRate?: Maybe<Order_By>;
  RtuDataBits?: Maybe<Order_By>;
  ScalingFactor?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  TcpPort?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_FeedsConfigModbus_Stddev_Samp_Fields = {
  __typename?: 'dw_FeedsConfigModbus_stddev_samp_fields';
  DeviceAddress?: Maybe<Scalars['Float']>;
  ReadFrequencySeconds?: Maybe<Scalars['Float']>;
  RegisterAddress?: Maybe<Scalars['Float']>;
  RegisterCount?: Maybe<Scalars['Float']>;
  RegisterType?: Maybe<Scalars['Float']>;
  RtuBaudRate?: Maybe<Scalars['Float']>;
  RtuDataBits?: Maybe<Scalars['Float']>;
  ScalingFactor?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  TcpPort?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.FeedsConfigModbus" */
export type Dw_FeedsConfigModbus_Stddev_Samp_Order_By = {
  DeviceAddress?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  RegisterAddress?: Maybe<Order_By>;
  RegisterCount?: Maybe<Order_By>;
  RegisterType?: Maybe<Order_By>;
  RtuBaudRate?: Maybe<Order_By>;
  RtuDataBits?: Maybe<Order_By>;
  ScalingFactor?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  TcpPort?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_FeedsConfigModbus_Sum_Fields = {
  __typename?: 'dw_FeedsConfigModbus_sum_fields';
  DeviceAddress?: Maybe<Scalars['numeric']>;
  ReadFrequencySeconds?: Maybe<Scalars['Int']>;
  RegisterAddress?: Maybe<Scalars['Int']>;
  RegisterCount?: Maybe<Scalars['Int']>;
  RegisterType?: Maybe<Scalars['smallint']>;
  RtuBaudRate?: Maybe<Scalars['Int']>;
  RtuDataBits?: Maybe<Scalars['Int']>;
  ScalingFactor?: Maybe<Scalars['numeric']>;
  Status?: Maybe<Scalars['smallint']>;
  TcpPort?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "dw.FeedsConfigModbus" */
export type Dw_FeedsConfigModbus_Sum_Order_By = {
  DeviceAddress?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  RegisterAddress?: Maybe<Order_By>;
  RegisterCount?: Maybe<Order_By>;
  RegisterType?: Maybe<Order_By>;
  RtuBaudRate?: Maybe<Order_By>;
  RtuDataBits?: Maybe<Order_By>;
  ScalingFactor?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  TcpPort?: Maybe<Order_By>;
};

/** update columns of table "dw.FeedsConfigModbus" */
export enum Dw_FeedsConfigModbus_Update_Column {
  /** column name */
  CalculateDelta = 'CalculateDelta',
  /** column name */
  ChannelId = 'ChannelId',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  DeviceAddress = 'DeviceAddress',
  /** column name */
  FeedId = 'FeedId',
  /** column name */
  IsBigEndian = 'IsBigEndian',
  /** column name */
  ReadFrequencySeconds = 'ReadFrequencySeconds',
  /** column name */
  RegisterAddress = 'RegisterAddress',
  /** column name */
  RegisterCount = 'RegisterCount',
  /** column name */
  RegisterType = 'RegisterType',
  /** column name */
  RtuBaudRate = 'RtuBaudRate',
  /** column name */
  RtuDataBits = 'RtuDataBits',
  /** column name */
  RtuParity = 'RtuParity',
  /** column name */
  RtuSerialPort = 'RtuSerialPort',
  /** column name */
  RtuStopBits = 'RtuStopBits',
  /** column name */
  ScalingFactor = 'ScalingFactor',
  /** column name */
  Status = 'Status',
  /** column name */
  TcpIpAddress = 'TcpIpAddress',
  /** column name */
  TcpPort = 'TcpPort',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** aggregate var_pop on columns */
export type Dw_FeedsConfigModbus_Var_Pop_Fields = {
  __typename?: 'dw_FeedsConfigModbus_var_pop_fields';
  DeviceAddress?: Maybe<Scalars['Float']>;
  ReadFrequencySeconds?: Maybe<Scalars['Float']>;
  RegisterAddress?: Maybe<Scalars['Float']>;
  RegisterCount?: Maybe<Scalars['Float']>;
  RegisterType?: Maybe<Scalars['Float']>;
  RtuBaudRate?: Maybe<Scalars['Float']>;
  RtuDataBits?: Maybe<Scalars['Float']>;
  ScalingFactor?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  TcpPort?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.FeedsConfigModbus" */
export type Dw_FeedsConfigModbus_Var_Pop_Order_By = {
  DeviceAddress?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  RegisterAddress?: Maybe<Order_By>;
  RegisterCount?: Maybe<Order_By>;
  RegisterType?: Maybe<Order_By>;
  RtuBaudRate?: Maybe<Order_By>;
  RtuDataBits?: Maybe<Order_By>;
  ScalingFactor?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  TcpPort?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_FeedsConfigModbus_Var_Samp_Fields = {
  __typename?: 'dw_FeedsConfigModbus_var_samp_fields';
  DeviceAddress?: Maybe<Scalars['Float']>;
  ReadFrequencySeconds?: Maybe<Scalars['Float']>;
  RegisterAddress?: Maybe<Scalars['Float']>;
  RegisterCount?: Maybe<Scalars['Float']>;
  RegisterType?: Maybe<Scalars['Float']>;
  RtuBaudRate?: Maybe<Scalars['Float']>;
  RtuDataBits?: Maybe<Scalars['Float']>;
  ScalingFactor?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  TcpPort?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.FeedsConfigModbus" */
export type Dw_FeedsConfigModbus_Var_Samp_Order_By = {
  DeviceAddress?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  RegisterAddress?: Maybe<Order_By>;
  RegisterCount?: Maybe<Order_By>;
  RegisterType?: Maybe<Order_By>;
  RtuBaudRate?: Maybe<Order_By>;
  RtuDataBits?: Maybe<Order_By>;
  ScalingFactor?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  TcpPort?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_FeedsConfigModbus_Variance_Fields = {
  __typename?: 'dw_FeedsConfigModbus_variance_fields';
  DeviceAddress?: Maybe<Scalars['Float']>;
  ReadFrequencySeconds?: Maybe<Scalars['Float']>;
  RegisterAddress?: Maybe<Scalars['Float']>;
  RegisterCount?: Maybe<Scalars['Float']>;
  RegisterType?: Maybe<Scalars['Float']>;
  RtuBaudRate?: Maybe<Scalars['Float']>;
  RtuDataBits?: Maybe<Scalars['Float']>;
  ScalingFactor?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  TcpPort?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.FeedsConfigModbus" */
export type Dw_FeedsConfigModbus_Variance_Order_By = {
  DeviceAddress?: Maybe<Order_By>;
  ReadFrequencySeconds?: Maybe<Order_By>;
  RegisterAddress?: Maybe<Order_By>;
  RegisterCount?: Maybe<Order_By>;
  RegisterType?: Maybe<Order_By>;
  RtuBaudRate?: Maybe<Order_By>;
  RtuDataBits?: Maybe<Order_By>;
  ScalingFactor?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  TcpPort?: Maybe<Order_By>;
};

/** columns and relationships of "dw.FeedsLogs" */
export type Dw_FeedsLogs = {
  __typename?: 'dw_FeedsLogs';
  Event: Scalars['timestamp'];
  EventId: Scalars['uuid'];
  /** An object relationship */
  Feed: Dw_Feeds;
  FeedId: Scalars['uuid'];
  Log: Scalars['String'];
  Severity: Scalars['smallint'];
};

/** aggregated selection of "dw.FeedsLogs" */
export type Dw_FeedsLogs_Aggregate = {
  __typename?: 'dw_FeedsLogs_aggregate';
  aggregate?: Maybe<Dw_FeedsLogs_Aggregate_Fields>;
  nodes: Array<Dw_FeedsLogs>;
};

/** aggregate fields of "dw.FeedsLogs" */
export type Dw_FeedsLogs_Aggregate_Fields = {
  __typename?: 'dw_FeedsLogs_aggregate_fields';
  avg?: Maybe<Dw_FeedsLogs_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_FeedsLogs_Max_Fields>;
  min?: Maybe<Dw_FeedsLogs_Min_Fields>;
  stddev?: Maybe<Dw_FeedsLogs_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_FeedsLogs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_FeedsLogs_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_FeedsLogs_Sum_Fields>;
  var_pop?: Maybe<Dw_FeedsLogs_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_FeedsLogs_Var_Samp_Fields>;
  variance?: Maybe<Dw_FeedsLogs_Variance_Fields>;
};


/** aggregate fields of "dw.FeedsLogs" */
export type Dw_FeedsLogs_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_FeedsLogs_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.FeedsLogs" */
export type Dw_FeedsLogs_Aggregate_Order_By = {
  avg?: Maybe<Dw_FeedsLogs_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_FeedsLogs_Max_Order_By>;
  min?: Maybe<Dw_FeedsLogs_Min_Order_By>;
  stddev?: Maybe<Dw_FeedsLogs_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_FeedsLogs_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_FeedsLogs_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_FeedsLogs_Sum_Order_By>;
  var_pop?: Maybe<Dw_FeedsLogs_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_FeedsLogs_Var_Samp_Order_By>;
  variance?: Maybe<Dw_FeedsLogs_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.FeedsLogs" */
export type Dw_FeedsLogs_Arr_Rel_Insert_Input = {
  data: Array<Dw_FeedsLogs_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_FeedsLogs_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_FeedsLogs_Avg_Fields = {
  __typename?: 'dw_FeedsLogs_avg_fields';
  Severity?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.FeedsLogs" */
export type Dw_FeedsLogs_Avg_Order_By = {
  Severity?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.FeedsLogs". All fields are combined with a logical 'AND'. */
export type Dw_FeedsLogs_Bool_Exp = {
  Event?: Maybe<Timestamp_Comparison_Exp>;
  EventId?: Maybe<Uuid_Comparison_Exp>;
  Feed?: Maybe<Dw_Feeds_Bool_Exp>;
  FeedId?: Maybe<Uuid_Comparison_Exp>;
  Log?: Maybe<String_Comparison_Exp>;
  Severity?: Maybe<Smallint_Comparison_Exp>;
  _and?: Maybe<Array<Dw_FeedsLogs_Bool_Exp>>;
  _not?: Maybe<Dw_FeedsLogs_Bool_Exp>;
  _or?: Maybe<Array<Dw_FeedsLogs_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.FeedsLogs" */
export enum Dw_FeedsLogs_Constraint {
  /** unique or primary key constraint */
  FeedslogsPk = 'feedslogs_pk'
}

/** input type for incrementing numeric columns in table "dw.FeedsLogs" */
export type Dw_FeedsLogs_Inc_Input = {
  Severity?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.FeedsLogs" */
export type Dw_FeedsLogs_Insert_Input = {
  Event?: Maybe<Scalars['timestamp']>;
  EventId?: Maybe<Scalars['uuid']>;
  Feed?: Maybe<Dw_Feeds_Obj_Rel_Insert_Input>;
  FeedId?: Maybe<Scalars['uuid']>;
  Log?: Maybe<Scalars['String']>;
  Severity?: Maybe<Scalars['smallint']>;
};

/** aggregate max on columns */
export type Dw_FeedsLogs_Max_Fields = {
  __typename?: 'dw_FeedsLogs_max_fields';
  Event?: Maybe<Scalars['timestamp']>;
  EventId?: Maybe<Scalars['uuid']>;
  FeedId?: Maybe<Scalars['uuid']>;
  Log?: Maybe<Scalars['String']>;
  Severity?: Maybe<Scalars['smallint']>;
};

/** order by max() on columns of table "dw.FeedsLogs" */
export type Dw_FeedsLogs_Max_Order_By = {
  Event?: Maybe<Order_By>;
  EventId?: Maybe<Order_By>;
  FeedId?: Maybe<Order_By>;
  Log?: Maybe<Order_By>;
  Severity?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_FeedsLogs_Min_Fields = {
  __typename?: 'dw_FeedsLogs_min_fields';
  Event?: Maybe<Scalars['timestamp']>;
  EventId?: Maybe<Scalars['uuid']>;
  FeedId?: Maybe<Scalars['uuid']>;
  Log?: Maybe<Scalars['String']>;
  Severity?: Maybe<Scalars['smallint']>;
};

/** order by min() on columns of table "dw.FeedsLogs" */
export type Dw_FeedsLogs_Min_Order_By = {
  Event?: Maybe<Order_By>;
  EventId?: Maybe<Order_By>;
  FeedId?: Maybe<Order_By>;
  Log?: Maybe<Order_By>;
  Severity?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.FeedsLogs" */
export type Dw_FeedsLogs_Mutation_Response = {
  __typename?: 'dw_FeedsLogs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_FeedsLogs>;
};

/** on conflict condition type for table "dw.FeedsLogs" */
export type Dw_FeedsLogs_On_Conflict = {
  constraint: Dw_FeedsLogs_Constraint;
  update_columns?: Array<Dw_FeedsLogs_Update_Column>;
  where?: Maybe<Dw_FeedsLogs_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.FeedsLogs". */
export type Dw_FeedsLogs_Order_By = {
  Event?: Maybe<Order_By>;
  EventId?: Maybe<Order_By>;
  Feed?: Maybe<Dw_Feeds_Order_By>;
  FeedId?: Maybe<Order_By>;
  Log?: Maybe<Order_By>;
  Severity?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_FeedsLogs */
export type Dw_FeedsLogs_Pk_Columns_Input = {
  EventId: Scalars['uuid'];
};

/** select columns of table "dw.FeedsLogs" */
export enum Dw_FeedsLogs_Select_Column {
  /** column name */
  Event = 'Event',
  /** column name */
  EventId = 'EventId',
  /** column name */
  FeedId = 'FeedId',
  /** column name */
  Log = 'Log',
  /** column name */
  Severity = 'Severity'
}

/** input type for updating data in table "dw.FeedsLogs" */
export type Dw_FeedsLogs_Set_Input = {
  Event?: Maybe<Scalars['timestamp']>;
  EventId?: Maybe<Scalars['uuid']>;
  FeedId?: Maybe<Scalars['uuid']>;
  Log?: Maybe<Scalars['String']>;
  Severity?: Maybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type Dw_FeedsLogs_Stddev_Fields = {
  __typename?: 'dw_FeedsLogs_stddev_fields';
  Severity?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.FeedsLogs" */
export type Dw_FeedsLogs_Stddev_Order_By = {
  Severity?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_FeedsLogs_Stddev_Pop_Fields = {
  __typename?: 'dw_FeedsLogs_stddev_pop_fields';
  Severity?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.FeedsLogs" */
export type Dw_FeedsLogs_Stddev_Pop_Order_By = {
  Severity?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_FeedsLogs_Stddev_Samp_Fields = {
  __typename?: 'dw_FeedsLogs_stddev_samp_fields';
  Severity?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.FeedsLogs" */
export type Dw_FeedsLogs_Stddev_Samp_Order_By = {
  Severity?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_FeedsLogs_Sum_Fields = {
  __typename?: 'dw_FeedsLogs_sum_fields';
  Severity?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "dw.FeedsLogs" */
export type Dw_FeedsLogs_Sum_Order_By = {
  Severity?: Maybe<Order_By>;
};

/** update columns of table "dw.FeedsLogs" */
export enum Dw_FeedsLogs_Update_Column {
  /** column name */
  Event = 'Event',
  /** column name */
  EventId = 'EventId',
  /** column name */
  FeedId = 'FeedId',
  /** column name */
  Log = 'Log',
  /** column name */
  Severity = 'Severity'
}

/** aggregate var_pop on columns */
export type Dw_FeedsLogs_Var_Pop_Fields = {
  __typename?: 'dw_FeedsLogs_var_pop_fields';
  Severity?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.FeedsLogs" */
export type Dw_FeedsLogs_Var_Pop_Order_By = {
  Severity?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_FeedsLogs_Var_Samp_Fields = {
  __typename?: 'dw_FeedsLogs_var_samp_fields';
  Severity?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.FeedsLogs" */
export type Dw_FeedsLogs_Var_Samp_Order_By = {
  Severity?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_FeedsLogs_Variance_Fields = {
  __typename?: 'dw_FeedsLogs_variance_fields';
  Severity?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.FeedsLogs" */
export type Dw_FeedsLogs_Variance_Order_By = {
  Severity?: Maybe<Order_By>;
};

/** columns and relationships of "dw.FeedsTypes" */
export type Dw_FeedsTypes = {
  __typename?: 'dw_FeedsTypes';
  /** An array relationship */
  Feeds: Array<Dw_Feeds>;
  /** An aggregate relationship */
  Feeds_aggregate: Dw_Feeds_Aggregate;
  Label: Scalars['String'];
  TypeId: Scalars['uuid'];
  UpdatedOn: Scalars['timestamp'];
};


/** columns and relationships of "dw.FeedsTypes" */
export type Dw_FeedsTypesFeedsArgs = {
  distinct_on?: Maybe<Array<Dw_Feeds_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Feeds_Order_By>>;
  where?: Maybe<Dw_Feeds_Bool_Exp>;
};


/** columns and relationships of "dw.FeedsTypes" */
export type Dw_FeedsTypesFeeds_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Feeds_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Feeds_Order_By>>;
  where?: Maybe<Dw_Feeds_Bool_Exp>;
};

/** aggregated selection of "dw.FeedsTypes" */
export type Dw_FeedsTypes_Aggregate = {
  __typename?: 'dw_FeedsTypes_aggregate';
  aggregate?: Maybe<Dw_FeedsTypes_Aggregate_Fields>;
  nodes: Array<Dw_FeedsTypes>;
};

/** aggregate fields of "dw.FeedsTypes" */
export type Dw_FeedsTypes_Aggregate_Fields = {
  __typename?: 'dw_FeedsTypes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Dw_FeedsTypes_Max_Fields>;
  min?: Maybe<Dw_FeedsTypes_Min_Fields>;
};


/** aggregate fields of "dw.FeedsTypes" */
export type Dw_FeedsTypes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_FeedsTypes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "dw.FeedsTypes". All fields are combined with a logical 'AND'. */
export type Dw_FeedsTypes_Bool_Exp = {
  Feeds?: Maybe<Dw_Feeds_Bool_Exp>;
  Label?: Maybe<String_Comparison_Exp>;
  TypeId?: Maybe<Uuid_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  _and?: Maybe<Array<Dw_FeedsTypes_Bool_Exp>>;
  _not?: Maybe<Dw_FeedsTypes_Bool_Exp>;
  _or?: Maybe<Array<Dw_FeedsTypes_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.FeedsTypes" */
export enum Dw_FeedsTypes_Constraint {
  /** unique or primary key constraint */
  FeedstypesPk = 'feedstypes_pk',
  /** unique or primary key constraint */
  FeedstypesTypeidUindex = 'feedstypes_typeid_uindex'
}

/** input type for inserting data into table "dw.FeedsTypes" */
export type Dw_FeedsTypes_Insert_Input = {
  Feeds?: Maybe<Dw_Feeds_Arr_Rel_Insert_Input>;
  Label?: Maybe<Scalars['String']>;
  TypeId?: Maybe<Scalars['uuid']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Dw_FeedsTypes_Max_Fields = {
  __typename?: 'dw_FeedsTypes_max_fields';
  Label?: Maybe<Scalars['String']>;
  TypeId?: Maybe<Scalars['uuid']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Dw_FeedsTypes_Min_Fields = {
  __typename?: 'dw_FeedsTypes_min_fields';
  Label?: Maybe<Scalars['String']>;
  TypeId?: Maybe<Scalars['uuid']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "dw.FeedsTypes" */
export type Dw_FeedsTypes_Mutation_Response = {
  __typename?: 'dw_FeedsTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_FeedsTypes>;
};

/** input type for inserting object relation for remote table "dw.FeedsTypes" */
export type Dw_FeedsTypes_Obj_Rel_Insert_Input = {
  data: Dw_FeedsTypes_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_FeedsTypes_On_Conflict>;
};

/** on conflict condition type for table "dw.FeedsTypes" */
export type Dw_FeedsTypes_On_Conflict = {
  constraint: Dw_FeedsTypes_Constraint;
  update_columns?: Array<Dw_FeedsTypes_Update_Column>;
  where?: Maybe<Dw_FeedsTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.FeedsTypes". */
export type Dw_FeedsTypes_Order_By = {
  Feeds_aggregate?: Maybe<Dw_Feeds_Aggregate_Order_By>;
  Label?: Maybe<Order_By>;
  TypeId?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_FeedsTypes */
export type Dw_FeedsTypes_Pk_Columns_Input = {
  TypeId: Scalars['uuid'];
};

/** select columns of table "dw.FeedsTypes" */
export enum Dw_FeedsTypes_Select_Column {
  /** column name */
  Label = 'Label',
  /** column name */
  TypeId = 'TypeId',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** input type for updating data in table "dw.FeedsTypes" */
export type Dw_FeedsTypes_Set_Input = {
  Label?: Maybe<Scalars['String']>;
  TypeId?: Maybe<Scalars['uuid']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "dw.FeedsTypes" */
export enum Dw_FeedsTypes_Update_Column {
  /** column name */
  Label = 'Label',
  /** column name */
  TypeId = 'TypeId',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** aggregated selection of "dw.Feeds" */
export type Dw_Feeds_Aggregate = {
  __typename?: 'dw_Feeds_aggregate';
  aggregate?: Maybe<Dw_Feeds_Aggregate_Fields>;
  nodes: Array<Dw_Feeds>;
};

/** aggregate fields of "dw.Feeds" */
export type Dw_Feeds_Aggregate_Fields = {
  __typename?: 'dw_Feeds_aggregate_fields';
  avg?: Maybe<Dw_Feeds_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_Feeds_Max_Fields>;
  min?: Maybe<Dw_Feeds_Min_Fields>;
  stddev?: Maybe<Dw_Feeds_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_Feeds_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_Feeds_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_Feeds_Sum_Fields>;
  var_pop?: Maybe<Dw_Feeds_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_Feeds_Var_Samp_Fields>;
  variance?: Maybe<Dw_Feeds_Variance_Fields>;
};


/** aggregate fields of "dw.Feeds" */
export type Dw_Feeds_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_Feeds_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.Feeds" */
export type Dw_Feeds_Aggregate_Order_By = {
  avg?: Maybe<Dw_Feeds_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_Feeds_Max_Order_By>;
  min?: Maybe<Dw_Feeds_Min_Order_By>;
  stddev?: Maybe<Dw_Feeds_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_Feeds_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_Feeds_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_Feeds_Sum_Order_By>;
  var_pop?: Maybe<Dw_Feeds_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_Feeds_Var_Samp_Order_By>;
  variance?: Maybe<Dw_Feeds_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.Feeds" */
export type Dw_Feeds_Arr_Rel_Insert_Input = {
  data: Array<Dw_Feeds_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_Feeds_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_Feeds_Avg_Fields = {
  __typename?: 'dw_Feeds_avg_fields';
  BatteryDailyUsage?: Maybe<Scalars['Float']>;
  BatteryPercentage?: Maybe<Scalars['Float']>;
  IntervalEmitFrequency?: Maybe<Scalars['Float']>;
  RadioQuality?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  Temperature?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.Feeds" */
export type Dw_Feeds_Avg_Order_By = {
  BatteryDailyUsage?: Maybe<Order_By>;
  BatteryPercentage?: Maybe<Order_By>;
  IntervalEmitFrequency?: Maybe<Order_By>;
  RadioQuality?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  Temperature?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.Feeds". All fields are combined with a logical 'AND'. */
export type Dw_Feeds_Bool_Exp = {
  ActivatedOn?: Maybe<Timestamp_Comparison_Exp>;
  BatteryDailyUsage?: Maybe<Numeric_Comparison_Exp>;
  BatteryPercentage?: Maybe<Numeric_Comparison_Exp>;
  Building?: Maybe<Dw_Buildings_Bool_Exp>;
  BuildingId?: Maybe<Uuid_Comparison_Exp>;
  Channels?: Maybe<Dw_Channels_Bool_Exp>;
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  FeedId?: Maybe<Uuid_Comparison_Exp>;
  FeedsConfigBacNets?: Maybe<Dw_FeedsConfigBacNet_Bool_Exp>;
  FeedsConfigModbuses?: Maybe<Dw_FeedsConfigModbus_Bool_Exp>;
  FeedsLogs?: Maybe<Dw_FeedsLogs_Bool_Exp>;
  FeedsType?: Maybe<Dw_FeedsTypes_Bool_Exp>;
  IntervalEmitFrequency?: Maybe<Smallint_Comparison_Exp>;
  Label?: Maybe<String_Comparison_Exp>;
  LastSync?: Maybe<Timestamp_Comparison_Exp>;
  Loads?: Maybe<Dw_Loads_Bool_Exp>;
  Notes?: Maybe<String_Comparison_Exp>;
  Password?: Maybe<String_Comparison_Exp>;
  ProductionMode?: Maybe<Boolean_Comparison_Exp>;
  RadioQuality?: Maybe<Int_Comparison_Exp>;
  SerialNumber?: Maybe<String_Comparison_Exp>;
  SimCard?: Maybe<String_Comparison_Exp>;
  Status?: Maybe<Smallint_Comparison_Exp>;
  Temperature?: Maybe<Numeric_Comparison_Exp>;
  TypeId?: Maybe<Uuid_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  Url?: Maybe<String_Comparison_Exp>;
  UserName?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Dw_Feeds_Bool_Exp>>;
  _not?: Maybe<Dw_Feeds_Bool_Exp>;
  _or?: Maybe<Array<Dw_Feeds_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.Feeds" */
export enum Dw_Feeds_Constraint {
  /** unique or primary key constraint */
  LoggersLoggeridUindex = 'loggers_loggerid_uindex',
  /** unique or primary key constraint */
  LoggersPk = 'loggers_pk'
}

/** input type for incrementing numeric columns in table "dw.Feeds" */
export type Dw_Feeds_Inc_Input = {
  BatteryDailyUsage?: Maybe<Scalars['numeric']>;
  BatteryPercentage?: Maybe<Scalars['numeric']>;
  IntervalEmitFrequency?: Maybe<Scalars['smallint']>;
  RadioQuality?: Maybe<Scalars['Int']>;
  Status?: Maybe<Scalars['smallint']>;
  Temperature?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "dw.Feeds" */
export type Dw_Feeds_Insert_Input = {
  ActivatedOn?: Maybe<Scalars['timestamp']>;
  BatteryDailyUsage?: Maybe<Scalars['numeric']>;
  BatteryPercentage?: Maybe<Scalars['numeric']>;
  Building?: Maybe<Dw_Buildings_Obj_Rel_Insert_Input>;
  BuildingId?: Maybe<Scalars['uuid']>;
  Channels?: Maybe<Dw_Channels_Arr_Rel_Insert_Input>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  FeedId?: Maybe<Scalars['uuid']>;
  FeedsConfigBacNets?: Maybe<Dw_FeedsConfigBacNet_Arr_Rel_Insert_Input>;
  FeedsConfigModbuses?: Maybe<Dw_FeedsConfigModbus_Arr_Rel_Insert_Input>;
  FeedsLogs?: Maybe<Dw_FeedsLogs_Arr_Rel_Insert_Input>;
  FeedsType?: Maybe<Dw_FeedsTypes_Obj_Rel_Insert_Input>;
  IntervalEmitFrequency?: Maybe<Scalars['smallint']>;
  Label?: Maybe<Scalars['String']>;
  LastSync?: Maybe<Scalars['timestamp']>;
  Loads?: Maybe<Dw_Loads_Arr_Rel_Insert_Input>;
  Notes?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  ProductionMode?: Maybe<Scalars['Boolean']>;
  RadioQuality?: Maybe<Scalars['Int']>;
  SerialNumber?: Maybe<Scalars['String']>;
  SimCard?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['smallint']>;
  Temperature?: Maybe<Scalars['numeric']>;
  TypeId?: Maybe<Scalars['uuid']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  Url?: Maybe<Scalars['String']>;
  UserName?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Dw_Feeds_Max_Fields = {
  __typename?: 'dw_Feeds_max_fields';
  ActivatedOn?: Maybe<Scalars['timestamp']>;
  BatteryDailyUsage?: Maybe<Scalars['numeric']>;
  BatteryPercentage?: Maybe<Scalars['numeric']>;
  BuildingId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  FeedId?: Maybe<Scalars['uuid']>;
  IntervalEmitFrequency?: Maybe<Scalars['smallint']>;
  Label?: Maybe<Scalars['String']>;
  LastSync?: Maybe<Scalars['timestamp']>;
  Notes?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  RadioQuality?: Maybe<Scalars['Int']>;
  SerialNumber?: Maybe<Scalars['String']>;
  SimCard?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['smallint']>;
  Temperature?: Maybe<Scalars['numeric']>;
  TypeId?: Maybe<Scalars['uuid']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  Url?: Maybe<Scalars['String']>;
  UserName?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "dw.Feeds" */
export type Dw_Feeds_Max_Order_By = {
  ActivatedOn?: Maybe<Order_By>;
  BatteryDailyUsage?: Maybe<Order_By>;
  BatteryPercentage?: Maybe<Order_By>;
  BuildingId?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  FeedId?: Maybe<Order_By>;
  IntervalEmitFrequency?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  LastSync?: Maybe<Order_By>;
  Notes?: Maybe<Order_By>;
  Password?: Maybe<Order_By>;
  RadioQuality?: Maybe<Order_By>;
  SerialNumber?: Maybe<Order_By>;
  SimCard?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  Temperature?: Maybe<Order_By>;
  TypeId?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
  Url?: Maybe<Order_By>;
  UserName?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_Feeds_Min_Fields = {
  __typename?: 'dw_Feeds_min_fields';
  ActivatedOn?: Maybe<Scalars['timestamp']>;
  BatteryDailyUsage?: Maybe<Scalars['numeric']>;
  BatteryPercentage?: Maybe<Scalars['numeric']>;
  BuildingId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  FeedId?: Maybe<Scalars['uuid']>;
  IntervalEmitFrequency?: Maybe<Scalars['smallint']>;
  Label?: Maybe<Scalars['String']>;
  LastSync?: Maybe<Scalars['timestamp']>;
  Notes?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  RadioQuality?: Maybe<Scalars['Int']>;
  SerialNumber?: Maybe<Scalars['String']>;
  SimCard?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['smallint']>;
  Temperature?: Maybe<Scalars['numeric']>;
  TypeId?: Maybe<Scalars['uuid']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  Url?: Maybe<Scalars['String']>;
  UserName?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "dw.Feeds" */
export type Dw_Feeds_Min_Order_By = {
  ActivatedOn?: Maybe<Order_By>;
  BatteryDailyUsage?: Maybe<Order_By>;
  BatteryPercentage?: Maybe<Order_By>;
  BuildingId?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  FeedId?: Maybe<Order_By>;
  IntervalEmitFrequency?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  LastSync?: Maybe<Order_By>;
  Notes?: Maybe<Order_By>;
  Password?: Maybe<Order_By>;
  RadioQuality?: Maybe<Order_By>;
  SerialNumber?: Maybe<Order_By>;
  SimCard?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  Temperature?: Maybe<Order_By>;
  TypeId?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
  Url?: Maybe<Order_By>;
  UserName?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.Feeds" */
export type Dw_Feeds_Mutation_Response = {
  __typename?: 'dw_Feeds_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_Feeds>;
};

/** input type for inserting object relation for remote table "dw.Feeds" */
export type Dw_Feeds_Obj_Rel_Insert_Input = {
  data: Dw_Feeds_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_Feeds_On_Conflict>;
};

/** on conflict condition type for table "dw.Feeds" */
export type Dw_Feeds_On_Conflict = {
  constraint: Dw_Feeds_Constraint;
  update_columns?: Array<Dw_Feeds_Update_Column>;
  where?: Maybe<Dw_Feeds_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.Feeds". */
export type Dw_Feeds_Order_By = {
  ActivatedOn?: Maybe<Order_By>;
  BatteryDailyUsage?: Maybe<Order_By>;
  BatteryPercentage?: Maybe<Order_By>;
  Building?: Maybe<Dw_Buildings_Order_By>;
  BuildingId?: Maybe<Order_By>;
  Channels_aggregate?: Maybe<Dw_Channels_Aggregate_Order_By>;
  CreatedOn?: Maybe<Order_By>;
  FeedId?: Maybe<Order_By>;
  FeedsConfigBacNets_aggregate?: Maybe<Dw_FeedsConfigBacNet_Aggregate_Order_By>;
  FeedsConfigModbuses_aggregate?: Maybe<Dw_FeedsConfigModbus_Aggregate_Order_By>;
  FeedsLogs_aggregate?: Maybe<Dw_FeedsLogs_Aggregate_Order_By>;
  FeedsType?: Maybe<Dw_FeedsTypes_Order_By>;
  IntervalEmitFrequency?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  LastSync?: Maybe<Order_By>;
  Loads_aggregate?: Maybe<Dw_Loads_Aggregate_Order_By>;
  Notes?: Maybe<Order_By>;
  Password?: Maybe<Order_By>;
  ProductionMode?: Maybe<Order_By>;
  RadioQuality?: Maybe<Order_By>;
  SerialNumber?: Maybe<Order_By>;
  SimCard?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  Temperature?: Maybe<Order_By>;
  TypeId?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
  Url?: Maybe<Order_By>;
  UserName?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_Feeds */
export type Dw_Feeds_Pk_Columns_Input = {
  FeedId: Scalars['uuid'];
};

/** select columns of table "dw.Feeds" */
export enum Dw_Feeds_Select_Column {
  /** column name */
  ActivatedOn = 'ActivatedOn',
  /** column name */
  BatteryDailyUsage = 'BatteryDailyUsage',
  /** column name */
  BatteryPercentage = 'BatteryPercentage',
  /** column name */
  BuildingId = 'BuildingId',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  FeedId = 'FeedId',
  /** column name */
  IntervalEmitFrequency = 'IntervalEmitFrequency',
  /** column name */
  Label = 'Label',
  /** column name */
  LastSync = 'LastSync',
  /** column name */
  Notes = 'Notes',
  /** column name */
  Password = 'Password',
  /** column name */
  ProductionMode = 'ProductionMode',
  /** column name */
  RadioQuality = 'RadioQuality',
  /** column name */
  SerialNumber = 'SerialNumber',
  /** column name */
  SimCard = 'SimCard',
  /** column name */
  Status = 'Status',
  /** column name */
  Temperature = 'Temperature',
  /** column name */
  TypeId = 'TypeId',
  /** column name */
  UpdatedOn = 'UpdatedOn',
  /** column name */
  Url = 'Url',
  /** column name */
  UserName = 'UserName'
}

/** input type for updating data in table "dw.Feeds" */
export type Dw_Feeds_Set_Input = {
  ActivatedOn?: Maybe<Scalars['timestamp']>;
  BatteryDailyUsage?: Maybe<Scalars['numeric']>;
  BatteryPercentage?: Maybe<Scalars['numeric']>;
  BuildingId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  FeedId?: Maybe<Scalars['uuid']>;
  IntervalEmitFrequency?: Maybe<Scalars['smallint']>;
  Label?: Maybe<Scalars['String']>;
  LastSync?: Maybe<Scalars['timestamp']>;
  Notes?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  ProductionMode?: Maybe<Scalars['Boolean']>;
  RadioQuality?: Maybe<Scalars['Int']>;
  SerialNumber?: Maybe<Scalars['String']>;
  SimCard?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['smallint']>;
  Temperature?: Maybe<Scalars['numeric']>;
  TypeId?: Maybe<Scalars['uuid']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  Url?: Maybe<Scalars['String']>;
  UserName?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Dw_Feeds_Stddev_Fields = {
  __typename?: 'dw_Feeds_stddev_fields';
  BatteryDailyUsage?: Maybe<Scalars['Float']>;
  BatteryPercentage?: Maybe<Scalars['Float']>;
  IntervalEmitFrequency?: Maybe<Scalars['Float']>;
  RadioQuality?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  Temperature?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.Feeds" */
export type Dw_Feeds_Stddev_Order_By = {
  BatteryDailyUsage?: Maybe<Order_By>;
  BatteryPercentage?: Maybe<Order_By>;
  IntervalEmitFrequency?: Maybe<Order_By>;
  RadioQuality?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  Temperature?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_Feeds_Stddev_Pop_Fields = {
  __typename?: 'dw_Feeds_stddev_pop_fields';
  BatteryDailyUsage?: Maybe<Scalars['Float']>;
  BatteryPercentage?: Maybe<Scalars['Float']>;
  IntervalEmitFrequency?: Maybe<Scalars['Float']>;
  RadioQuality?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  Temperature?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.Feeds" */
export type Dw_Feeds_Stddev_Pop_Order_By = {
  BatteryDailyUsage?: Maybe<Order_By>;
  BatteryPercentage?: Maybe<Order_By>;
  IntervalEmitFrequency?: Maybe<Order_By>;
  RadioQuality?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  Temperature?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_Feeds_Stddev_Samp_Fields = {
  __typename?: 'dw_Feeds_stddev_samp_fields';
  BatteryDailyUsage?: Maybe<Scalars['Float']>;
  BatteryPercentage?: Maybe<Scalars['Float']>;
  IntervalEmitFrequency?: Maybe<Scalars['Float']>;
  RadioQuality?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  Temperature?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.Feeds" */
export type Dw_Feeds_Stddev_Samp_Order_By = {
  BatteryDailyUsage?: Maybe<Order_By>;
  BatteryPercentage?: Maybe<Order_By>;
  IntervalEmitFrequency?: Maybe<Order_By>;
  RadioQuality?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  Temperature?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_Feeds_Sum_Fields = {
  __typename?: 'dw_Feeds_sum_fields';
  BatteryDailyUsage?: Maybe<Scalars['numeric']>;
  BatteryPercentage?: Maybe<Scalars['numeric']>;
  IntervalEmitFrequency?: Maybe<Scalars['smallint']>;
  RadioQuality?: Maybe<Scalars['Int']>;
  Status?: Maybe<Scalars['smallint']>;
  Temperature?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "dw.Feeds" */
export type Dw_Feeds_Sum_Order_By = {
  BatteryDailyUsage?: Maybe<Order_By>;
  BatteryPercentage?: Maybe<Order_By>;
  IntervalEmitFrequency?: Maybe<Order_By>;
  RadioQuality?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  Temperature?: Maybe<Order_By>;
};

/** update columns of table "dw.Feeds" */
export enum Dw_Feeds_Update_Column {
  /** column name */
  ActivatedOn = 'ActivatedOn',
  /** column name */
  BatteryDailyUsage = 'BatteryDailyUsage',
  /** column name */
  BatteryPercentage = 'BatteryPercentage',
  /** column name */
  BuildingId = 'BuildingId',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  FeedId = 'FeedId',
  /** column name */
  IntervalEmitFrequency = 'IntervalEmitFrequency',
  /** column name */
  Label = 'Label',
  /** column name */
  LastSync = 'LastSync',
  /** column name */
  Notes = 'Notes',
  /** column name */
  Password = 'Password',
  /** column name */
  ProductionMode = 'ProductionMode',
  /** column name */
  RadioQuality = 'RadioQuality',
  /** column name */
  SerialNumber = 'SerialNumber',
  /** column name */
  SimCard = 'SimCard',
  /** column name */
  Status = 'Status',
  /** column name */
  Temperature = 'Temperature',
  /** column name */
  TypeId = 'TypeId',
  /** column name */
  UpdatedOn = 'UpdatedOn',
  /** column name */
  Url = 'Url',
  /** column name */
  UserName = 'UserName'
}

/** aggregate var_pop on columns */
export type Dw_Feeds_Var_Pop_Fields = {
  __typename?: 'dw_Feeds_var_pop_fields';
  BatteryDailyUsage?: Maybe<Scalars['Float']>;
  BatteryPercentage?: Maybe<Scalars['Float']>;
  IntervalEmitFrequency?: Maybe<Scalars['Float']>;
  RadioQuality?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  Temperature?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.Feeds" */
export type Dw_Feeds_Var_Pop_Order_By = {
  BatteryDailyUsage?: Maybe<Order_By>;
  BatteryPercentage?: Maybe<Order_By>;
  IntervalEmitFrequency?: Maybe<Order_By>;
  RadioQuality?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  Temperature?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_Feeds_Var_Samp_Fields = {
  __typename?: 'dw_Feeds_var_samp_fields';
  BatteryDailyUsage?: Maybe<Scalars['Float']>;
  BatteryPercentage?: Maybe<Scalars['Float']>;
  IntervalEmitFrequency?: Maybe<Scalars['Float']>;
  RadioQuality?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  Temperature?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.Feeds" */
export type Dw_Feeds_Var_Samp_Order_By = {
  BatteryDailyUsage?: Maybe<Order_By>;
  BatteryPercentage?: Maybe<Order_By>;
  IntervalEmitFrequency?: Maybe<Order_By>;
  RadioQuality?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  Temperature?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_Feeds_Variance_Fields = {
  __typename?: 'dw_Feeds_variance_fields';
  BatteryDailyUsage?: Maybe<Scalars['Float']>;
  BatteryPercentage?: Maybe<Scalars['Float']>;
  IntervalEmitFrequency?: Maybe<Scalars['Float']>;
  RadioQuality?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  Temperature?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.Feeds" */
export type Dw_Feeds_Variance_Order_By = {
  BatteryDailyUsage?: Maybe<Order_By>;
  BatteryPercentage?: Maybe<Order_By>;
  IntervalEmitFrequency?: Maybe<Order_By>;
  RadioQuality?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  Temperature?: Maybe<Order_By>;
};

/** columns and relationships of "dw.Groups" */
export type Dw_Groups = {
  __typename?: 'dw_Groups';
  /** An object relationship */
  Account: Dw_Accounts;
  AccountId: Scalars['uuid'];
  CreatedOn: Scalars['timestamp'];
  GroupId: Scalars['uuid'];
  /** An array relationship */
  GroupsSites: Array<Dw_GroupsSites>;
  /** An aggregate relationship */
  GroupsSites_aggregate: Dw_GroupsSites_Aggregate;
  Label: Scalars['String'];
  /** An array relationship */
  Sites: Array<Dw_Sites>;
  /** An aggregate relationship */
  Sites_aggregate: Dw_Sites_Aggregate;
  Status: Scalars['smallint'];
  UpdatedOn: Scalars['timestamp'];
};


/** columns and relationships of "dw.Groups" */
export type Dw_GroupsGroupsSitesArgs = {
  distinct_on?: Maybe<Array<Dw_GroupsSites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_GroupsSites_Order_By>>;
  where?: Maybe<Dw_GroupsSites_Bool_Exp>;
};


/** columns and relationships of "dw.Groups" */
export type Dw_GroupsGroupsSites_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_GroupsSites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_GroupsSites_Order_By>>;
  where?: Maybe<Dw_GroupsSites_Bool_Exp>;
};


/** columns and relationships of "dw.Groups" */
export type Dw_GroupsSitesArgs = {
  distinct_on?: Maybe<Array<Dw_Sites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Sites_Order_By>>;
  where?: Maybe<Dw_Sites_Bool_Exp>;
};


/** columns and relationships of "dw.Groups" */
export type Dw_GroupsSites_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Sites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Sites_Order_By>>;
  where?: Maybe<Dw_Sites_Bool_Exp>;
};

/** columns and relationships of "dw.GroupsSites" */
export type Dw_GroupsSites = {
  __typename?: 'dw_GroupsSites';
  CreatedOn: Scalars['timestamp'];
  /** An object relationship */
  Group: Dw_Groups;
  GroupId: Scalars['uuid'];
  /** An object relationship */
  Site: Dw_Sites;
  SiteId: Scalars['uuid'];
  Status: Scalars['smallint'];
  UpdatedOn: Scalars['timestamp'];
};

/** aggregated selection of "dw.GroupsSites" */
export type Dw_GroupsSites_Aggregate = {
  __typename?: 'dw_GroupsSites_aggregate';
  aggregate?: Maybe<Dw_GroupsSites_Aggregate_Fields>;
  nodes: Array<Dw_GroupsSites>;
};

/** aggregate fields of "dw.GroupsSites" */
export type Dw_GroupsSites_Aggregate_Fields = {
  __typename?: 'dw_GroupsSites_aggregate_fields';
  avg?: Maybe<Dw_GroupsSites_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_GroupsSites_Max_Fields>;
  min?: Maybe<Dw_GroupsSites_Min_Fields>;
  stddev?: Maybe<Dw_GroupsSites_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_GroupsSites_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_GroupsSites_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_GroupsSites_Sum_Fields>;
  var_pop?: Maybe<Dw_GroupsSites_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_GroupsSites_Var_Samp_Fields>;
  variance?: Maybe<Dw_GroupsSites_Variance_Fields>;
};


/** aggregate fields of "dw.GroupsSites" */
export type Dw_GroupsSites_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_GroupsSites_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.GroupsSites" */
export type Dw_GroupsSites_Aggregate_Order_By = {
  avg?: Maybe<Dw_GroupsSites_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_GroupsSites_Max_Order_By>;
  min?: Maybe<Dw_GroupsSites_Min_Order_By>;
  stddev?: Maybe<Dw_GroupsSites_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_GroupsSites_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_GroupsSites_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_GroupsSites_Sum_Order_By>;
  var_pop?: Maybe<Dw_GroupsSites_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_GroupsSites_Var_Samp_Order_By>;
  variance?: Maybe<Dw_GroupsSites_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.GroupsSites" */
export type Dw_GroupsSites_Arr_Rel_Insert_Input = {
  data: Array<Dw_GroupsSites_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_GroupsSites_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_GroupsSites_Avg_Fields = {
  __typename?: 'dw_GroupsSites_avg_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.GroupsSites" */
export type Dw_GroupsSites_Avg_Order_By = {
  Status?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.GroupsSites". All fields are combined with a logical 'AND'. */
export type Dw_GroupsSites_Bool_Exp = {
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  Group?: Maybe<Dw_Groups_Bool_Exp>;
  GroupId?: Maybe<Uuid_Comparison_Exp>;
  Site?: Maybe<Dw_Sites_Bool_Exp>;
  SiteId?: Maybe<Uuid_Comparison_Exp>;
  Status?: Maybe<Smallint_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  _and?: Maybe<Array<Dw_GroupsSites_Bool_Exp>>;
  _not?: Maybe<Dw_GroupsSites_Bool_Exp>;
  _or?: Maybe<Array<Dw_GroupsSites_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.GroupsSites" */
export enum Dw_GroupsSites_Constraint {
  /** unique or primary key constraint */
  GroupssitesPk_2 = 'groupssites_pk_2'
}

/** input type for incrementing numeric columns in table "dw.GroupsSites" */
export type Dw_GroupsSites_Inc_Input = {
  Status?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.GroupsSites" */
export type Dw_GroupsSites_Insert_Input = {
  CreatedOn?: Maybe<Scalars['timestamp']>;
  Group?: Maybe<Dw_Groups_Obj_Rel_Insert_Input>;
  GroupId?: Maybe<Scalars['uuid']>;
  Site?: Maybe<Dw_Sites_Obj_Rel_Insert_Input>;
  SiteId?: Maybe<Scalars['uuid']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Dw_GroupsSites_Max_Fields = {
  __typename?: 'dw_GroupsSites_max_fields';
  CreatedOn?: Maybe<Scalars['timestamp']>;
  GroupId?: Maybe<Scalars['uuid']>;
  SiteId?: Maybe<Scalars['uuid']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "dw.GroupsSites" */
export type Dw_GroupsSites_Max_Order_By = {
  CreatedOn?: Maybe<Order_By>;
  GroupId?: Maybe<Order_By>;
  SiteId?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_GroupsSites_Min_Fields = {
  __typename?: 'dw_GroupsSites_min_fields';
  CreatedOn?: Maybe<Scalars['timestamp']>;
  GroupId?: Maybe<Scalars['uuid']>;
  SiteId?: Maybe<Scalars['uuid']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "dw.GroupsSites" */
export type Dw_GroupsSites_Min_Order_By = {
  CreatedOn?: Maybe<Order_By>;
  GroupId?: Maybe<Order_By>;
  SiteId?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.GroupsSites" */
export type Dw_GroupsSites_Mutation_Response = {
  __typename?: 'dw_GroupsSites_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_GroupsSites>;
};

/** on conflict condition type for table "dw.GroupsSites" */
export type Dw_GroupsSites_On_Conflict = {
  constraint: Dw_GroupsSites_Constraint;
  update_columns?: Array<Dw_GroupsSites_Update_Column>;
  where?: Maybe<Dw_GroupsSites_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.GroupsSites". */
export type Dw_GroupsSites_Order_By = {
  CreatedOn?: Maybe<Order_By>;
  Group?: Maybe<Dw_Groups_Order_By>;
  GroupId?: Maybe<Order_By>;
  Site?: Maybe<Dw_Sites_Order_By>;
  SiteId?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_GroupsSites */
export type Dw_GroupsSites_Pk_Columns_Input = {
  GroupId: Scalars['uuid'];
  SiteId: Scalars['uuid'];
};

/** select columns of table "dw.GroupsSites" */
export enum Dw_GroupsSites_Select_Column {
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  GroupId = 'GroupId',
  /** column name */
  SiteId = 'SiteId',
  /** column name */
  Status = 'Status',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** input type for updating data in table "dw.GroupsSites" */
export type Dw_GroupsSites_Set_Input = {
  CreatedOn?: Maybe<Scalars['timestamp']>;
  GroupId?: Maybe<Scalars['uuid']>;
  SiteId?: Maybe<Scalars['uuid']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Dw_GroupsSites_Stddev_Fields = {
  __typename?: 'dw_GroupsSites_stddev_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.GroupsSites" */
export type Dw_GroupsSites_Stddev_Order_By = {
  Status?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_GroupsSites_Stddev_Pop_Fields = {
  __typename?: 'dw_GroupsSites_stddev_pop_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.GroupsSites" */
export type Dw_GroupsSites_Stddev_Pop_Order_By = {
  Status?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_GroupsSites_Stddev_Samp_Fields = {
  __typename?: 'dw_GroupsSites_stddev_samp_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.GroupsSites" */
export type Dw_GroupsSites_Stddev_Samp_Order_By = {
  Status?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_GroupsSites_Sum_Fields = {
  __typename?: 'dw_GroupsSites_sum_fields';
  Status?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "dw.GroupsSites" */
export type Dw_GroupsSites_Sum_Order_By = {
  Status?: Maybe<Order_By>;
};

/** update columns of table "dw.GroupsSites" */
export enum Dw_GroupsSites_Update_Column {
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  GroupId = 'GroupId',
  /** column name */
  SiteId = 'SiteId',
  /** column name */
  Status = 'Status',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** aggregate var_pop on columns */
export type Dw_GroupsSites_Var_Pop_Fields = {
  __typename?: 'dw_GroupsSites_var_pop_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.GroupsSites" */
export type Dw_GroupsSites_Var_Pop_Order_By = {
  Status?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_GroupsSites_Var_Samp_Fields = {
  __typename?: 'dw_GroupsSites_var_samp_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.GroupsSites" */
export type Dw_GroupsSites_Var_Samp_Order_By = {
  Status?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_GroupsSites_Variance_Fields = {
  __typename?: 'dw_GroupsSites_variance_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.GroupsSites" */
export type Dw_GroupsSites_Variance_Order_By = {
  Status?: Maybe<Order_By>;
};

/** aggregated selection of "dw.Groups" */
export type Dw_Groups_Aggregate = {
  __typename?: 'dw_Groups_aggregate';
  aggregate?: Maybe<Dw_Groups_Aggregate_Fields>;
  nodes: Array<Dw_Groups>;
};

/** aggregate fields of "dw.Groups" */
export type Dw_Groups_Aggregate_Fields = {
  __typename?: 'dw_Groups_aggregate_fields';
  avg?: Maybe<Dw_Groups_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_Groups_Max_Fields>;
  min?: Maybe<Dw_Groups_Min_Fields>;
  stddev?: Maybe<Dw_Groups_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_Groups_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_Groups_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_Groups_Sum_Fields>;
  var_pop?: Maybe<Dw_Groups_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_Groups_Var_Samp_Fields>;
  variance?: Maybe<Dw_Groups_Variance_Fields>;
};


/** aggregate fields of "dw.Groups" */
export type Dw_Groups_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_Groups_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.Groups" */
export type Dw_Groups_Aggregate_Order_By = {
  avg?: Maybe<Dw_Groups_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_Groups_Max_Order_By>;
  min?: Maybe<Dw_Groups_Min_Order_By>;
  stddev?: Maybe<Dw_Groups_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_Groups_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_Groups_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_Groups_Sum_Order_By>;
  var_pop?: Maybe<Dw_Groups_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_Groups_Var_Samp_Order_By>;
  variance?: Maybe<Dw_Groups_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.Groups" */
export type Dw_Groups_Arr_Rel_Insert_Input = {
  data: Array<Dw_Groups_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_Groups_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_Groups_Avg_Fields = {
  __typename?: 'dw_Groups_avg_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.Groups" */
export type Dw_Groups_Avg_Order_By = {
  Status?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.Groups". All fields are combined with a logical 'AND'. */
export type Dw_Groups_Bool_Exp = {
  Account?: Maybe<Dw_Accounts_Bool_Exp>;
  AccountId?: Maybe<Uuid_Comparison_Exp>;
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  GroupId?: Maybe<Uuid_Comparison_Exp>;
  GroupsSites?: Maybe<Dw_GroupsSites_Bool_Exp>;
  Label?: Maybe<String_Comparison_Exp>;
  Sites?: Maybe<Dw_Sites_Bool_Exp>;
  Status?: Maybe<Smallint_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  _and?: Maybe<Array<Dw_Groups_Bool_Exp>>;
  _not?: Maybe<Dw_Groups_Bool_Exp>;
  _or?: Maybe<Array<Dw_Groups_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.Groups" */
export enum Dw_Groups_Constraint {
  /** unique or primary key constraint */
  GroupsGroupidUindex = 'groups_groupid_uindex',
  /** unique or primary key constraint */
  GroupsPk = 'groups_pk'
}

/** input type for incrementing numeric columns in table "dw.Groups" */
export type Dw_Groups_Inc_Input = {
  Status?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.Groups" */
export type Dw_Groups_Insert_Input = {
  Account?: Maybe<Dw_Accounts_Obj_Rel_Insert_Input>;
  AccountId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  GroupId?: Maybe<Scalars['uuid']>;
  GroupsSites?: Maybe<Dw_GroupsSites_Arr_Rel_Insert_Input>;
  Label?: Maybe<Scalars['String']>;
  Sites?: Maybe<Dw_Sites_Arr_Rel_Insert_Input>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Dw_Groups_Max_Fields = {
  __typename?: 'dw_Groups_max_fields';
  AccountId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  GroupId?: Maybe<Scalars['uuid']>;
  Label?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "dw.Groups" */
export type Dw_Groups_Max_Order_By = {
  AccountId?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  GroupId?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_Groups_Min_Fields = {
  __typename?: 'dw_Groups_min_fields';
  AccountId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  GroupId?: Maybe<Scalars['uuid']>;
  Label?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "dw.Groups" */
export type Dw_Groups_Min_Order_By = {
  AccountId?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  GroupId?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.Groups" */
export type Dw_Groups_Mutation_Response = {
  __typename?: 'dw_Groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_Groups>;
};

/** input type for inserting object relation for remote table "dw.Groups" */
export type Dw_Groups_Obj_Rel_Insert_Input = {
  data: Dw_Groups_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_Groups_On_Conflict>;
};

/** on conflict condition type for table "dw.Groups" */
export type Dw_Groups_On_Conflict = {
  constraint: Dw_Groups_Constraint;
  update_columns?: Array<Dw_Groups_Update_Column>;
  where?: Maybe<Dw_Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.Groups". */
export type Dw_Groups_Order_By = {
  Account?: Maybe<Dw_Accounts_Order_By>;
  AccountId?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  GroupId?: Maybe<Order_By>;
  GroupsSites_aggregate?: Maybe<Dw_GroupsSites_Aggregate_Order_By>;
  Label?: Maybe<Order_By>;
  Sites_aggregate?: Maybe<Dw_Sites_Aggregate_Order_By>;
  Status?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_Groups */
export type Dw_Groups_Pk_Columns_Input = {
  GroupId: Scalars['uuid'];
};

/** select columns of table "dw.Groups" */
export enum Dw_Groups_Select_Column {
  /** column name */
  AccountId = 'AccountId',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  GroupId = 'GroupId',
  /** column name */
  Label = 'Label',
  /** column name */
  Status = 'Status',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** input type for updating data in table "dw.Groups" */
export type Dw_Groups_Set_Input = {
  AccountId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  GroupId?: Maybe<Scalars['uuid']>;
  Label?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Dw_Groups_Stddev_Fields = {
  __typename?: 'dw_Groups_stddev_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.Groups" */
export type Dw_Groups_Stddev_Order_By = {
  Status?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_Groups_Stddev_Pop_Fields = {
  __typename?: 'dw_Groups_stddev_pop_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.Groups" */
export type Dw_Groups_Stddev_Pop_Order_By = {
  Status?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_Groups_Stddev_Samp_Fields = {
  __typename?: 'dw_Groups_stddev_samp_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.Groups" */
export type Dw_Groups_Stddev_Samp_Order_By = {
  Status?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_Groups_Sum_Fields = {
  __typename?: 'dw_Groups_sum_fields';
  Status?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "dw.Groups" */
export type Dw_Groups_Sum_Order_By = {
  Status?: Maybe<Order_By>;
};

/** update columns of table "dw.Groups" */
export enum Dw_Groups_Update_Column {
  /** column name */
  AccountId = 'AccountId',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  GroupId = 'GroupId',
  /** column name */
  Label = 'Label',
  /** column name */
  Status = 'Status',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** aggregate var_pop on columns */
export type Dw_Groups_Var_Pop_Fields = {
  __typename?: 'dw_Groups_var_pop_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.Groups" */
export type Dw_Groups_Var_Pop_Order_By = {
  Status?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_Groups_Var_Samp_Fields = {
  __typename?: 'dw_Groups_var_samp_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.Groups" */
export type Dw_Groups_Var_Samp_Order_By = {
  Status?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_Groups_Variance_Fields = {
  __typename?: 'dw_Groups_variance_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.Groups" */
export type Dw_Groups_Variance_Order_By = {
  Status?: Maybe<Order_By>;
};

/** columns and relationships of "dw.History" */
export type Dw_History = {
  __typename?: 'dw_History';
  /** An object relationship */
  Contact: Dw_Contacts;
  ContactId: Scalars['uuid'];
  Description: Scalars['String'];
  HistoryId: Scalars['uuid'];
  OccuredOn: Scalars['timestamp'];
  SourceId: Scalars['uuid'];
  SourceType: Scalars['smallint'];
};

/** aggregated selection of "dw.History" */
export type Dw_History_Aggregate = {
  __typename?: 'dw_History_aggregate';
  aggregate?: Maybe<Dw_History_Aggregate_Fields>;
  nodes: Array<Dw_History>;
};

/** aggregate fields of "dw.History" */
export type Dw_History_Aggregate_Fields = {
  __typename?: 'dw_History_aggregate_fields';
  avg?: Maybe<Dw_History_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_History_Max_Fields>;
  min?: Maybe<Dw_History_Min_Fields>;
  stddev?: Maybe<Dw_History_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_History_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_History_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_History_Sum_Fields>;
  var_pop?: Maybe<Dw_History_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_History_Var_Samp_Fields>;
  variance?: Maybe<Dw_History_Variance_Fields>;
};


/** aggregate fields of "dw.History" */
export type Dw_History_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_History_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.History" */
export type Dw_History_Aggregate_Order_By = {
  avg?: Maybe<Dw_History_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_History_Max_Order_By>;
  min?: Maybe<Dw_History_Min_Order_By>;
  stddev?: Maybe<Dw_History_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_History_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_History_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_History_Sum_Order_By>;
  var_pop?: Maybe<Dw_History_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_History_Var_Samp_Order_By>;
  variance?: Maybe<Dw_History_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.History" */
export type Dw_History_Arr_Rel_Insert_Input = {
  data: Array<Dw_History_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_History_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_History_Avg_Fields = {
  __typename?: 'dw_History_avg_fields';
  SourceType?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.History" */
export type Dw_History_Avg_Order_By = {
  SourceType?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.History". All fields are combined with a logical 'AND'. */
export type Dw_History_Bool_Exp = {
  Contact?: Maybe<Dw_Contacts_Bool_Exp>;
  ContactId?: Maybe<Uuid_Comparison_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  HistoryId?: Maybe<Uuid_Comparison_Exp>;
  OccuredOn?: Maybe<Timestamp_Comparison_Exp>;
  SourceId?: Maybe<Uuid_Comparison_Exp>;
  SourceType?: Maybe<Smallint_Comparison_Exp>;
  _and?: Maybe<Array<Dw_History_Bool_Exp>>;
  _not?: Maybe<Dw_History_Bool_Exp>;
  _or?: Maybe<Array<Dw_History_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.History" */
export enum Dw_History_Constraint {
  /** unique or primary key constraint */
  HistoryHistoryidUindex = 'history_historyid_uindex',
  /** unique or primary key constraint */
  HistoryPk = 'history_pk'
}

/** input type for incrementing numeric columns in table "dw.History" */
export type Dw_History_Inc_Input = {
  SourceType?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.History" */
export type Dw_History_Insert_Input = {
  Contact?: Maybe<Dw_Contacts_Obj_Rel_Insert_Input>;
  ContactId?: Maybe<Scalars['uuid']>;
  Description?: Maybe<Scalars['String']>;
  HistoryId?: Maybe<Scalars['uuid']>;
  OccuredOn?: Maybe<Scalars['timestamp']>;
  SourceId?: Maybe<Scalars['uuid']>;
  SourceType?: Maybe<Scalars['smallint']>;
};

/** aggregate max on columns */
export type Dw_History_Max_Fields = {
  __typename?: 'dw_History_max_fields';
  ContactId?: Maybe<Scalars['uuid']>;
  Description?: Maybe<Scalars['String']>;
  HistoryId?: Maybe<Scalars['uuid']>;
  OccuredOn?: Maybe<Scalars['timestamp']>;
  SourceId?: Maybe<Scalars['uuid']>;
  SourceType?: Maybe<Scalars['smallint']>;
};

/** order by max() on columns of table "dw.History" */
export type Dw_History_Max_Order_By = {
  ContactId?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  HistoryId?: Maybe<Order_By>;
  OccuredOn?: Maybe<Order_By>;
  SourceId?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_History_Min_Fields = {
  __typename?: 'dw_History_min_fields';
  ContactId?: Maybe<Scalars['uuid']>;
  Description?: Maybe<Scalars['String']>;
  HistoryId?: Maybe<Scalars['uuid']>;
  OccuredOn?: Maybe<Scalars['timestamp']>;
  SourceId?: Maybe<Scalars['uuid']>;
  SourceType?: Maybe<Scalars['smallint']>;
};

/** order by min() on columns of table "dw.History" */
export type Dw_History_Min_Order_By = {
  ContactId?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  HistoryId?: Maybe<Order_By>;
  OccuredOn?: Maybe<Order_By>;
  SourceId?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.History" */
export type Dw_History_Mutation_Response = {
  __typename?: 'dw_History_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_History>;
};

/** on conflict condition type for table "dw.History" */
export type Dw_History_On_Conflict = {
  constraint: Dw_History_Constraint;
  update_columns?: Array<Dw_History_Update_Column>;
  where?: Maybe<Dw_History_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.History". */
export type Dw_History_Order_By = {
  Contact?: Maybe<Dw_Contacts_Order_By>;
  ContactId?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  HistoryId?: Maybe<Order_By>;
  OccuredOn?: Maybe<Order_By>;
  SourceId?: Maybe<Order_By>;
  SourceType?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_History */
export type Dw_History_Pk_Columns_Input = {
  HistoryId: Scalars['uuid'];
  SourceId: Scalars['uuid'];
};

/** select columns of table "dw.History" */
export enum Dw_History_Select_Column {
  /** column name */
  ContactId = 'ContactId',
  /** column name */
  Description = 'Description',
  /** column name */
  HistoryId = 'HistoryId',
  /** column name */
  OccuredOn = 'OccuredOn',
  /** column name */
  SourceId = 'SourceId',
  /** column name */
  SourceType = 'SourceType'
}

/** input type for updating data in table "dw.History" */
export type Dw_History_Set_Input = {
  ContactId?: Maybe<Scalars['uuid']>;
  Description?: Maybe<Scalars['String']>;
  HistoryId?: Maybe<Scalars['uuid']>;
  OccuredOn?: Maybe<Scalars['timestamp']>;
  SourceId?: Maybe<Scalars['uuid']>;
  SourceType?: Maybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type Dw_History_Stddev_Fields = {
  __typename?: 'dw_History_stddev_fields';
  SourceType?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.History" */
export type Dw_History_Stddev_Order_By = {
  SourceType?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_History_Stddev_Pop_Fields = {
  __typename?: 'dw_History_stddev_pop_fields';
  SourceType?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.History" */
export type Dw_History_Stddev_Pop_Order_By = {
  SourceType?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_History_Stddev_Samp_Fields = {
  __typename?: 'dw_History_stddev_samp_fields';
  SourceType?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.History" */
export type Dw_History_Stddev_Samp_Order_By = {
  SourceType?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_History_Sum_Fields = {
  __typename?: 'dw_History_sum_fields';
  SourceType?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "dw.History" */
export type Dw_History_Sum_Order_By = {
  SourceType?: Maybe<Order_By>;
};

/** update columns of table "dw.History" */
export enum Dw_History_Update_Column {
  /** column name */
  ContactId = 'ContactId',
  /** column name */
  Description = 'Description',
  /** column name */
  HistoryId = 'HistoryId',
  /** column name */
  OccuredOn = 'OccuredOn',
  /** column name */
  SourceId = 'SourceId',
  /** column name */
  SourceType = 'SourceType'
}

/** aggregate var_pop on columns */
export type Dw_History_Var_Pop_Fields = {
  __typename?: 'dw_History_var_pop_fields';
  SourceType?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.History" */
export type Dw_History_Var_Pop_Order_By = {
  SourceType?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_History_Var_Samp_Fields = {
  __typename?: 'dw_History_var_samp_fields';
  SourceType?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.History" */
export type Dw_History_Var_Samp_Order_By = {
  SourceType?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_History_Variance_Fields = {
  __typename?: 'dw_History_variance_fields';
  SourceType?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.History" */
export type Dw_History_Variance_Order_By = {
  SourceType?: Maybe<Order_By>;
};

/** columns and relationships of "dw.LoadDocuments" */
export type Dw_LoadDocuments = {
  __typename?: 'dw_LoadDocuments';
  DocumentId: Scalars['uuid'];
  FileName: Scalars['String'];
  /** An object relationship */
  Load: Dw_Loads;
  LoadId: Scalars['uuid'];
  Status: Scalars['smallint'];
  UploadDate: Scalars['timestamp'];
};

/** aggregated selection of "dw.LoadDocuments" */
export type Dw_LoadDocuments_Aggregate = {
  __typename?: 'dw_LoadDocuments_aggregate';
  aggregate?: Maybe<Dw_LoadDocuments_Aggregate_Fields>;
  nodes: Array<Dw_LoadDocuments>;
};

/** aggregate fields of "dw.LoadDocuments" */
export type Dw_LoadDocuments_Aggregate_Fields = {
  __typename?: 'dw_LoadDocuments_aggregate_fields';
  avg?: Maybe<Dw_LoadDocuments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_LoadDocuments_Max_Fields>;
  min?: Maybe<Dw_LoadDocuments_Min_Fields>;
  stddev?: Maybe<Dw_LoadDocuments_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_LoadDocuments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_LoadDocuments_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_LoadDocuments_Sum_Fields>;
  var_pop?: Maybe<Dw_LoadDocuments_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_LoadDocuments_Var_Samp_Fields>;
  variance?: Maybe<Dw_LoadDocuments_Variance_Fields>;
};


/** aggregate fields of "dw.LoadDocuments" */
export type Dw_LoadDocuments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_LoadDocuments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.LoadDocuments" */
export type Dw_LoadDocuments_Aggregate_Order_By = {
  avg?: Maybe<Dw_LoadDocuments_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_LoadDocuments_Max_Order_By>;
  min?: Maybe<Dw_LoadDocuments_Min_Order_By>;
  stddev?: Maybe<Dw_LoadDocuments_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_LoadDocuments_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_LoadDocuments_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_LoadDocuments_Sum_Order_By>;
  var_pop?: Maybe<Dw_LoadDocuments_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_LoadDocuments_Var_Samp_Order_By>;
  variance?: Maybe<Dw_LoadDocuments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.LoadDocuments" */
export type Dw_LoadDocuments_Arr_Rel_Insert_Input = {
  data: Array<Dw_LoadDocuments_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_LoadDocuments_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_LoadDocuments_Avg_Fields = {
  __typename?: 'dw_LoadDocuments_avg_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.LoadDocuments" */
export type Dw_LoadDocuments_Avg_Order_By = {
  Status?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.LoadDocuments". All fields are combined with a logical 'AND'. */
export type Dw_LoadDocuments_Bool_Exp = {
  DocumentId?: Maybe<Uuid_Comparison_Exp>;
  FileName?: Maybe<String_Comparison_Exp>;
  Load?: Maybe<Dw_Loads_Bool_Exp>;
  LoadId?: Maybe<Uuid_Comparison_Exp>;
  Status?: Maybe<Smallint_Comparison_Exp>;
  UploadDate?: Maybe<Timestamp_Comparison_Exp>;
  _and?: Maybe<Array<Dw_LoadDocuments_Bool_Exp>>;
  _not?: Maybe<Dw_LoadDocuments_Bool_Exp>;
  _or?: Maybe<Array<Dw_LoadDocuments_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.LoadDocuments" */
export enum Dw_LoadDocuments_Constraint {
  /** unique or primary key constraint */
  LoaddocumentsCertificateidUindex = 'loaddocuments_certificateid_uindex',
  /** unique or primary key constraint */
  LoaddocumentsPk = 'loaddocuments_pk'
}

/** input type for incrementing numeric columns in table "dw.LoadDocuments" */
export type Dw_LoadDocuments_Inc_Input = {
  Status?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.LoadDocuments" */
export type Dw_LoadDocuments_Insert_Input = {
  DocumentId?: Maybe<Scalars['uuid']>;
  FileName?: Maybe<Scalars['String']>;
  Load?: Maybe<Dw_Loads_Obj_Rel_Insert_Input>;
  LoadId?: Maybe<Scalars['uuid']>;
  Status?: Maybe<Scalars['smallint']>;
  UploadDate?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Dw_LoadDocuments_Max_Fields = {
  __typename?: 'dw_LoadDocuments_max_fields';
  DocumentId?: Maybe<Scalars['uuid']>;
  FileName?: Maybe<Scalars['String']>;
  LoadId?: Maybe<Scalars['uuid']>;
  Status?: Maybe<Scalars['smallint']>;
  UploadDate?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "dw.LoadDocuments" */
export type Dw_LoadDocuments_Max_Order_By = {
  DocumentId?: Maybe<Order_By>;
  FileName?: Maybe<Order_By>;
  LoadId?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UploadDate?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_LoadDocuments_Min_Fields = {
  __typename?: 'dw_LoadDocuments_min_fields';
  DocumentId?: Maybe<Scalars['uuid']>;
  FileName?: Maybe<Scalars['String']>;
  LoadId?: Maybe<Scalars['uuid']>;
  Status?: Maybe<Scalars['smallint']>;
  UploadDate?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "dw.LoadDocuments" */
export type Dw_LoadDocuments_Min_Order_By = {
  DocumentId?: Maybe<Order_By>;
  FileName?: Maybe<Order_By>;
  LoadId?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UploadDate?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.LoadDocuments" */
export type Dw_LoadDocuments_Mutation_Response = {
  __typename?: 'dw_LoadDocuments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_LoadDocuments>;
};

/** on conflict condition type for table "dw.LoadDocuments" */
export type Dw_LoadDocuments_On_Conflict = {
  constraint: Dw_LoadDocuments_Constraint;
  update_columns?: Array<Dw_LoadDocuments_Update_Column>;
  where?: Maybe<Dw_LoadDocuments_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.LoadDocuments". */
export type Dw_LoadDocuments_Order_By = {
  DocumentId?: Maybe<Order_By>;
  FileName?: Maybe<Order_By>;
  Load?: Maybe<Dw_Loads_Order_By>;
  LoadId?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UploadDate?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_LoadDocuments */
export type Dw_LoadDocuments_Pk_Columns_Input = {
  DocumentId: Scalars['uuid'];
};

/** select columns of table "dw.LoadDocuments" */
export enum Dw_LoadDocuments_Select_Column {
  /** column name */
  DocumentId = 'DocumentId',
  /** column name */
  FileName = 'FileName',
  /** column name */
  LoadId = 'LoadId',
  /** column name */
  Status = 'Status',
  /** column name */
  UploadDate = 'UploadDate'
}

/** input type for updating data in table "dw.LoadDocuments" */
export type Dw_LoadDocuments_Set_Input = {
  DocumentId?: Maybe<Scalars['uuid']>;
  FileName?: Maybe<Scalars['String']>;
  LoadId?: Maybe<Scalars['uuid']>;
  Status?: Maybe<Scalars['smallint']>;
  UploadDate?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Dw_LoadDocuments_Stddev_Fields = {
  __typename?: 'dw_LoadDocuments_stddev_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.LoadDocuments" */
export type Dw_LoadDocuments_Stddev_Order_By = {
  Status?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_LoadDocuments_Stddev_Pop_Fields = {
  __typename?: 'dw_LoadDocuments_stddev_pop_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.LoadDocuments" */
export type Dw_LoadDocuments_Stddev_Pop_Order_By = {
  Status?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_LoadDocuments_Stddev_Samp_Fields = {
  __typename?: 'dw_LoadDocuments_stddev_samp_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.LoadDocuments" */
export type Dw_LoadDocuments_Stddev_Samp_Order_By = {
  Status?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_LoadDocuments_Sum_Fields = {
  __typename?: 'dw_LoadDocuments_sum_fields';
  Status?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "dw.LoadDocuments" */
export type Dw_LoadDocuments_Sum_Order_By = {
  Status?: Maybe<Order_By>;
};

/** update columns of table "dw.LoadDocuments" */
export enum Dw_LoadDocuments_Update_Column {
  /** column name */
  DocumentId = 'DocumentId',
  /** column name */
  FileName = 'FileName',
  /** column name */
  LoadId = 'LoadId',
  /** column name */
  Status = 'Status',
  /** column name */
  UploadDate = 'UploadDate'
}

/** aggregate var_pop on columns */
export type Dw_LoadDocuments_Var_Pop_Fields = {
  __typename?: 'dw_LoadDocuments_var_pop_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.LoadDocuments" */
export type Dw_LoadDocuments_Var_Pop_Order_By = {
  Status?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_LoadDocuments_Var_Samp_Fields = {
  __typename?: 'dw_LoadDocuments_var_samp_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.LoadDocuments" */
export type Dw_LoadDocuments_Var_Samp_Order_By = {
  Status?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_LoadDocuments_Variance_Fields = {
  __typename?: 'dw_LoadDocuments_variance_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.LoadDocuments" */
export type Dw_LoadDocuments_Variance_Order_By = {
  Status?: Maybe<Order_By>;
};

/** columns and relationships of "dw.Loads" */
export type Dw_Loads = {
  __typename?: 'dw_Loads';
  BreakerRating?: Maybe<Scalars['String']>;
  /** An object relationship */
  Building: Dw_Buildings;
  BuildingId: Scalars['uuid'];
  /** An array relationship */
  Channels: Array<Dw_Channels>;
  /** An aggregate relationship */
  Channels_aggregate: Dw_Channels_Aggregate;
  CommunicationType?: Maybe<Scalars['String']>;
  CreatedOn: Scalars['timestamp'];
  CtRating: Scalars['Int'];
  CtSupplied?: Maybe<Scalars['String']>;
  CtType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  /** An object relationship */
  Feed?: Maybe<Dw_Feeds>;
  FeedId?: Maybe<Scalars['uuid']>;
  IcpNumber?: Maybe<Scalars['String']>;
  Label: Scalars['String'];
  /** An array relationship */
  LoadDocuments: Array<Dw_LoadDocuments>;
  /** An aggregate relationship */
  LoadDocuments_aggregate: Dw_LoadDocuments_Aggregate;
  LoadId: Scalars['uuid'];
  /** An object relationship */
  LoadsService?: Maybe<Dw_LoadsService>;
  /** An object relationship */
  LoadsType?: Maybe<Dw_LoadsType>;
  MainIncomer: Scalars['Boolean'];
  MeterName?: Maybe<Scalars['String']>;
  MeterSerialNumber?: Maybe<Scalars['String']>;
  MeterType?: Maybe<Scalars['String']>;
  ModbusId: Scalars['Int'];
  NabersRating: Scalars['smallint'];
  NegotiatedCapacity: Scalars['Int'];
  /** An array relationship */
  Notifications: Array<Dw_Notifications>;
  /** An aggregate relationship */
  Notifications_aggregate: Dw_Notifications_Aggregate;
  /** An object relationship */
  ParentLoad?: Maybe<Dw_Loads>;
  ParentLoadId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  Projects: Array<Dw_Projects>;
  /** An aggregate relationship */
  Projects_aggregate: Dw_Projects_Aggregate;
  ReferenceValue?: Maybe<Scalars['String']>;
  ServiceId?: Maybe<Scalars['uuid']>;
  ShortLabel?: Maybe<Scalars['String']>;
  Status: Scalars['smallint'];
  SubLoadConsumption: Scalars['numeric'];
  SubLoadConsumptionDate?: Maybe<Scalars['timestamp']>;
  SubLoadState: Scalars['Int'];
  TenantId?: Maybe<Scalars['uuid']>;
  TenantLabel?: Maybe<Scalars['String']>;
  Tier: Scalars['Int'];
  TransformerCapacity: Scalars['Int'];
  TypeId?: Maybe<Scalars['uuid']>;
  UpdatedOn: Scalars['timestamp'];
  UtilityType: Scalars['smallint'];
};


/** columns and relationships of "dw.Loads" */
export type Dw_LoadsChannelsArgs = {
  distinct_on?: Maybe<Array<Dw_Channels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Channels_Order_By>>;
  where?: Maybe<Dw_Channels_Bool_Exp>;
};


/** columns and relationships of "dw.Loads" */
export type Dw_LoadsChannels_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Channels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Channels_Order_By>>;
  where?: Maybe<Dw_Channels_Bool_Exp>;
};


/** columns and relationships of "dw.Loads" */
export type Dw_LoadsLoadDocumentsArgs = {
  distinct_on?: Maybe<Array<Dw_LoadDocuments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_LoadDocuments_Order_By>>;
  where?: Maybe<Dw_LoadDocuments_Bool_Exp>;
};


/** columns and relationships of "dw.Loads" */
export type Dw_LoadsLoadDocuments_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_LoadDocuments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_LoadDocuments_Order_By>>;
  where?: Maybe<Dw_LoadDocuments_Bool_Exp>;
};


/** columns and relationships of "dw.Loads" */
export type Dw_LoadsNotificationsArgs = {
  distinct_on?: Maybe<Array<Dw_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Notifications_Order_By>>;
  where?: Maybe<Dw_Notifications_Bool_Exp>;
};


/** columns and relationships of "dw.Loads" */
export type Dw_LoadsNotifications_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Notifications_Order_By>>;
  where?: Maybe<Dw_Notifications_Bool_Exp>;
};


/** columns and relationships of "dw.Loads" */
export type Dw_LoadsProjectsArgs = {
  distinct_on?: Maybe<Array<Dw_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Projects_Order_By>>;
  where?: Maybe<Dw_Projects_Bool_Exp>;
};


/** columns and relationships of "dw.Loads" */
export type Dw_LoadsProjects_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Projects_Order_By>>;
  where?: Maybe<Dw_Projects_Bool_Exp>;
};

/** columns and relationships of "dw.LoadsService" */
export type Dw_LoadsService = {
  __typename?: 'dw_LoadsService';
  CreatedOn: Scalars['timestamp'];
  Label: Scalars['String'];
  /** An array relationship */
  Loads: Array<Dw_Loads>;
  /** An aggregate relationship */
  Loads_aggregate: Dw_Loads_Aggregate;
  ServiceId: Scalars['uuid'];
  Status: Scalars['smallint'];
  UpdatedOn: Scalars['timestamp'];
};


/** columns and relationships of "dw.LoadsService" */
export type Dw_LoadsServiceLoadsArgs = {
  distinct_on?: Maybe<Array<Dw_Loads_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Loads_Order_By>>;
  where?: Maybe<Dw_Loads_Bool_Exp>;
};


/** columns and relationships of "dw.LoadsService" */
export type Dw_LoadsServiceLoads_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Loads_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Loads_Order_By>>;
  where?: Maybe<Dw_Loads_Bool_Exp>;
};

/** aggregated selection of "dw.LoadsService" */
export type Dw_LoadsService_Aggregate = {
  __typename?: 'dw_LoadsService_aggregate';
  aggregate?: Maybe<Dw_LoadsService_Aggregate_Fields>;
  nodes: Array<Dw_LoadsService>;
};

/** aggregate fields of "dw.LoadsService" */
export type Dw_LoadsService_Aggregate_Fields = {
  __typename?: 'dw_LoadsService_aggregate_fields';
  avg?: Maybe<Dw_LoadsService_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_LoadsService_Max_Fields>;
  min?: Maybe<Dw_LoadsService_Min_Fields>;
  stddev?: Maybe<Dw_LoadsService_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_LoadsService_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_LoadsService_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_LoadsService_Sum_Fields>;
  var_pop?: Maybe<Dw_LoadsService_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_LoadsService_Var_Samp_Fields>;
  variance?: Maybe<Dw_LoadsService_Variance_Fields>;
};


/** aggregate fields of "dw.LoadsService" */
export type Dw_LoadsService_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_LoadsService_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dw_LoadsService_Avg_Fields = {
  __typename?: 'dw_LoadsService_avg_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dw.LoadsService". All fields are combined with a logical 'AND'. */
export type Dw_LoadsService_Bool_Exp = {
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  Label?: Maybe<String_Comparison_Exp>;
  Loads?: Maybe<Dw_Loads_Bool_Exp>;
  ServiceId?: Maybe<Uuid_Comparison_Exp>;
  Status?: Maybe<Smallint_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  _and?: Maybe<Array<Dw_LoadsService_Bool_Exp>>;
  _not?: Maybe<Dw_LoadsService_Bool_Exp>;
  _or?: Maybe<Array<Dw_LoadsService_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.LoadsService" */
export enum Dw_LoadsService_Constraint {
  /** unique or primary key constraint */
  LoadsservicePk = 'loadsservice_pk',
  /** unique or primary key constraint */
  LoadsserviceServiceidUindex = 'loadsservice_serviceid_uindex'
}

/** input type for incrementing numeric columns in table "dw.LoadsService" */
export type Dw_LoadsService_Inc_Input = {
  Status?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.LoadsService" */
export type Dw_LoadsService_Insert_Input = {
  CreatedOn?: Maybe<Scalars['timestamp']>;
  Label?: Maybe<Scalars['String']>;
  Loads?: Maybe<Dw_Loads_Arr_Rel_Insert_Input>;
  ServiceId?: Maybe<Scalars['uuid']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Dw_LoadsService_Max_Fields = {
  __typename?: 'dw_LoadsService_max_fields';
  CreatedOn?: Maybe<Scalars['timestamp']>;
  Label?: Maybe<Scalars['String']>;
  ServiceId?: Maybe<Scalars['uuid']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Dw_LoadsService_Min_Fields = {
  __typename?: 'dw_LoadsService_min_fields';
  CreatedOn?: Maybe<Scalars['timestamp']>;
  Label?: Maybe<Scalars['String']>;
  ServiceId?: Maybe<Scalars['uuid']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "dw.LoadsService" */
export type Dw_LoadsService_Mutation_Response = {
  __typename?: 'dw_LoadsService_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_LoadsService>;
};

/** input type for inserting object relation for remote table "dw.LoadsService" */
export type Dw_LoadsService_Obj_Rel_Insert_Input = {
  data: Dw_LoadsService_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_LoadsService_On_Conflict>;
};

/** on conflict condition type for table "dw.LoadsService" */
export type Dw_LoadsService_On_Conflict = {
  constraint: Dw_LoadsService_Constraint;
  update_columns?: Array<Dw_LoadsService_Update_Column>;
  where?: Maybe<Dw_LoadsService_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.LoadsService". */
export type Dw_LoadsService_Order_By = {
  CreatedOn?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  Loads_aggregate?: Maybe<Dw_Loads_Aggregate_Order_By>;
  ServiceId?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_LoadsService */
export type Dw_LoadsService_Pk_Columns_Input = {
  ServiceId: Scalars['uuid'];
};

/** select columns of table "dw.LoadsService" */
export enum Dw_LoadsService_Select_Column {
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Label = 'Label',
  /** column name */
  ServiceId = 'ServiceId',
  /** column name */
  Status = 'Status',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** input type for updating data in table "dw.LoadsService" */
export type Dw_LoadsService_Set_Input = {
  CreatedOn?: Maybe<Scalars['timestamp']>;
  Label?: Maybe<Scalars['String']>;
  ServiceId?: Maybe<Scalars['uuid']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Dw_LoadsService_Stddev_Fields = {
  __typename?: 'dw_LoadsService_stddev_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dw_LoadsService_Stddev_Pop_Fields = {
  __typename?: 'dw_LoadsService_stddev_pop_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dw_LoadsService_Stddev_Samp_Fields = {
  __typename?: 'dw_LoadsService_stddev_samp_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Dw_LoadsService_Sum_Fields = {
  __typename?: 'dw_LoadsService_sum_fields';
  Status?: Maybe<Scalars['smallint']>;
};

/** update columns of table "dw.LoadsService" */
export enum Dw_LoadsService_Update_Column {
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Label = 'Label',
  /** column name */
  ServiceId = 'ServiceId',
  /** column name */
  Status = 'Status',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** aggregate var_pop on columns */
export type Dw_LoadsService_Var_Pop_Fields = {
  __typename?: 'dw_LoadsService_var_pop_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dw_LoadsService_Var_Samp_Fields = {
  __typename?: 'dw_LoadsService_var_samp_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dw_LoadsService_Variance_Fields = {
  __typename?: 'dw_LoadsService_variance_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "dw.LoadsType" */
export type Dw_LoadsType = {
  __typename?: 'dw_LoadsType';
  CreatedOn: Scalars['timestamp'];
  Label: Scalars['String'];
  /** An array relationship */
  Loads: Array<Dw_Loads>;
  /** An aggregate relationship */
  Loads_aggregate: Dw_Loads_Aggregate;
  Status: Scalars['smallint'];
  TypeId: Scalars['uuid'];
  UpdatedOn: Scalars['timestamp'];
};


/** columns and relationships of "dw.LoadsType" */
export type Dw_LoadsTypeLoadsArgs = {
  distinct_on?: Maybe<Array<Dw_Loads_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Loads_Order_By>>;
  where?: Maybe<Dw_Loads_Bool_Exp>;
};


/** columns and relationships of "dw.LoadsType" */
export type Dw_LoadsTypeLoads_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Loads_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Loads_Order_By>>;
  where?: Maybe<Dw_Loads_Bool_Exp>;
};

/** aggregated selection of "dw.LoadsType" */
export type Dw_LoadsType_Aggregate = {
  __typename?: 'dw_LoadsType_aggregate';
  aggregate?: Maybe<Dw_LoadsType_Aggregate_Fields>;
  nodes: Array<Dw_LoadsType>;
};

/** aggregate fields of "dw.LoadsType" */
export type Dw_LoadsType_Aggregate_Fields = {
  __typename?: 'dw_LoadsType_aggregate_fields';
  avg?: Maybe<Dw_LoadsType_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_LoadsType_Max_Fields>;
  min?: Maybe<Dw_LoadsType_Min_Fields>;
  stddev?: Maybe<Dw_LoadsType_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_LoadsType_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_LoadsType_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_LoadsType_Sum_Fields>;
  var_pop?: Maybe<Dw_LoadsType_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_LoadsType_Var_Samp_Fields>;
  variance?: Maybe<Dw_LoadsType_Variance_Fields>;
};


/** aggregate fields of "dw.LoadsType" */
export type Dw_LoadsType_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_LoadsType_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dw_LoadsType_Avg_Fields = {
  __typename?: 'dw_LoadsType_avg_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dw.LoadsType". All fields are combined with a logical 'AND'. */
export type Dw_LoadsType_Bool_Exp = {
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  Label?: Maybe<String_Comparison_Exp>;
  Loads?: Maybe<Dw_Loads_Bool_Exp>;
  Status?: Maybe<Smallint_Comparison_Exp>;
  TypeId?: Maybe<Uuid_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  _and?: Maybe<Array<Dw_LoadsType_Bool_Exp>>;
  _not?: Maybe<Dw_LoadsType_Bool_Exp>;
  _or?: Maybe<Array<Dw_LoadsType_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.LoadsType" */
export enum Dw_LoadsType_Constraint {
  /** unique or primary key constraint */
  LoadstypePk = 'loadstype_pk',
  /** unique or primary key constraint */
  LoadstypeTypeidUindex = 'loadstype_typeid_uindex'
}

/** input type for incrementing numeric columns in table "dw.LoadsType" */
export type Dw_LoadsType_Inc_Input = {
  Status?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.LoadsType" */
export type Dw_LoadsType_Insert_Input = {
  CreatedOn?: Maybe<Scalars['timestamp']>;
  Label?: Maybe<Scalars['String']>;
  Loads?: Maybe<Dw_Loads_Arr_Rel_Insert_Input>;
  Status?: Maybe<Scalars['smallint']>;
  TypeId?: Maybe<Scalars['uuid']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Dw_LoadsType_Max_Fields = {
  __typename?: 'dw_LoadsType_max_fields';
  CreatedOn?: Maybe<Scalars['timestamp']>;
  Label?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['smallint']>;
  TypeId?: Maybe<Scalars['uuid']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Dw_LoadsType_Min_Fields = {
  __typename?: 'dw_LoadsType_min_fields';
  CreatedOn?: Maybe<Scalars['timestamp']>;
  Label?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['smallint']>;
  TypeId?: Maybe<Scalars['uuid']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "dw.LoadsType" */
export type Dw_LoadsType_Mutation_Response = {
  __typename?: 'dw_LoadsType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_LoadsType>;
};

/** input type for inserting object relation for remote table "dw.LoadsType" */
export type Dw_LoadsType_Obj_Rel_Insert_Input = {
  data: Dw_LoadsType_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_LoadsType_On_Conflict>;
};

/** on conflict condition type for table "dw.LoadsType" */
export type Dw_LoadsType_On_Conflict = {
  constraint: Dw_LoadsType_Constraint;
  update_columns?: Array<Dw_LoadsType_Update_Column>;
  where?: Maybe<Dw_LoadsType_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.LoadsType". */
export type Dw_LoadsType_Order_By = {
  CreatedOn?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  Loads_aggregate?: Maybe<Dw_Loads_Aggregate_Order_By>;
  Status?: Maybe<Order_By>;
  TypeId?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_LoadsType */
export type Dw_LoadsType_Pk_Columns_Input = {
  TypeId: Scalars['uuid'];
};

/** select columns of table "dw.LoadsType" */
export enum Dw_LoadsType_Select_Column {
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Label = 'Label',
  /** column name */
  Status = 'Status',
  /** column name */
  TypeId = 'TypeId',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** input type for updating data in table "dw.LoadsType" */
export type Dw_LoadsType_Set_Input = {
  CreatedOn?: Maybe<Scalars['timestamp']>;
  Label?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['smallint']>;
  TypeId?: Maybe<Scalars['uuid']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Dw_LoadsType_Stddev_Fields = {
  __typename?: 'dw_LoadsType_stddev_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dw_LoadsType_Stddev_Pop_Fields = {
  __typename?: 'dw_LoadsType_stddev_pop_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dw_LoadsType_Stddev_Samp_Fields = {
  __typename?: 'dw_LoadsType_stddev_samp_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Dw_LoadsType_Sum_Fields = {
  __typename?: 'dw_LoadsType_sum_fields';
  Status?: Maybe<Scalars['smallint']>;
};

/** update columns of table "dw.LoadsType" */
export enum Dw_LoadsType_Update_Column {
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Label = 'Label',
  /** column name */
  Status = 'Status',
  /** column name */
  TypeId = 'TypeId',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** aggregate var_pop on columns */
export type Dw_LoadsType_Var_Pop_Fields = {
  __typename?: 'dw_LoadsType_var_pop_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dw_LoadsType_Var_Samp_Fields = {
  __typename?: 'dw_LoadsType_var_samp_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dw_LoadsType_Variance_Fields = {
  __typename?: 'dw_LoadsType_variance_fields';
  Status?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "dw.Loads" */
export type Dw_Loads_Aggregate = {
  __typename?: 'dw_Loads_aggregate';
  aggregate?: Maybe<Dw_Loads_Aggregate_Fields>;
  nodes: Array<Dw_Loads>;
};

/** aggregate fields of "dw.Loads" */
export type Dw_Loads_Aggregate_Fields = {
  __typename?: 'dw_Loads_aggregate_fields';
  avg?: Maybe<Dw_Loads_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_Loads_Max_Fields>;
  min?: Maybe<Dw_Loads_Min_Fields>;
  stddev?: Maybe<Dw_Loads_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_Loads_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_Loads_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_Loads_Sum_Fields>;
  var_pop?: Maybe<Dw_Loads_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_Loads_Var_Samp_Fields>;
  variance?: Maybe<Dw_Loads_Variance_Fields>;
};


/** aggregate fields of "dw.Loads" */
export type Dw_Loads_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_Loads_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.Loads" */
export type Dw_Loads_Aggregate_Order_By = {
  avg?: Maybe<Dw_Loads_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_Loads_Max_Order_By>;
  min?: Maybe<Dw_Loads_Min_Order_By>;
  stddev?: Maybe<Dw_Loads_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_Loads_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_Loads_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_Loads_Sum_Order_By>;
  var_pop?: Maybe<Dw_Loads_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_Loads_Var_Samp_Order_By>;
  variance?: Maybe<Dw_Loads_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.Loads" */
export type Dw_Loads_Arr_Rel_Insert_Input = {
  data: Array<Dw_Loads_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_Loads_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_Loads_Avg_Fields = {
  __typename?: 'dw_Loads_avg_fields';
  CtRating?: Maybe<Scalars['Float']>;
  ModbusId?: Maybe<Scalars['Float']>;
  NabersRating?: Maybe<Scalars['Float']>;
  NegotiatedCapacity?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  SubLoadConsumption?: Maybe<Scalars['Float']>;
  SubLoadState?: Maybe<Scalars['Float']>;
  Tier?: Maybe<Scalars['Float']>;
  TransformerCapacity?: Maybe<Scalars['Float']>;
  UtilityType?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.Loads" */
export type Dw_Loads_Avg_Order_By = {
  CtRating?: Maybe<Order_By>;
  ModbusId?: Maybe<Order_By>;
  NabersRating?: Maybe<Order_By>;
  NegotiatedCapacity?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  SubLoadConsumption?: Maybe<Order_By>;
  SubLoadState?: Maybe<Order_By>;
  Tier?: Maybe<Order_By>;
  TransformerCapacity?: Maybe<Order_By>;
  UtilityType?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.Loads". All fields are combined with a logical 'AND'. */
export type Dw_Loads_Bool_Exp = {
  BreakerRating?: Maybe<String_Comparison_Exp>;
  Building?: Maybe<Dw_Buildings_Bool_Exp>;
  BuildingId?: Maybe<Uuid_Comparison_Exp>;
  Channels?: Maybe<Dw_Channels_Bool_Exp>;
  CommunicationType?: Maybe<String_Comparison_Exp>;
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  CtRating?: Maybe<Int_Comparison_Exp>;
  CtSupplied?: Maybe<String_Comparison_Exp>;
  CtType?: Maybe<String_Comparison_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  Feed?: Maybe<Dw_Feeds_Bool_Exp>;
  FeedId?: Maybe<Uuid_Comparison_Exp>;
  IcpNumber?: Maybe<String_Comparison_Exp>;
  Label?: Maybe<String_Comparison_Exp>;
  LoadDocuments?: Maybe<Dw_LoadDocuments_Bool_Exp>;
  LoadId?: Maybe<Uuid_Comparison_Exp>;
  LoadsService?: Maybe<Dw_LoadsService_Bool_Exp>;
  LoadsType?: Maybe<Dw_LoadsType_Bool_Exp>;
  MainIncomer?: Maybe<Boolean_Comparison_Exp>;
  MeterName?: Maybe<String_Comparison_Exp>;
  MeterSerialNumber?: Maybe<String_Comparison_Exp>;
  MeterType?: Maybe<String_Comparison_Exp>;
  ModbusId?: Maybe<Int_Comparison_Exp>;
  NabersRating?: Maybe<Smallint_Comparison_Exp>;
  NegotiatedCapacity?: Maybe<Int_Comparison_Exp>;
  Notifications?: Maybe<Dw_Notifications_Bool_Exp>;
  ParentLoad?: Maybe<Dw_Loads_Bool_Exp>;
  ParentLoadId?: Maybe<Uuid_Comparison_Exp>;
  Projects?: Maybe<Dw_Projects_Bool_Exp>;
  ReferenceValue?: Maybe<String_Comparison_Exp>;
  ServiceId?: Maybe<Uuid_Comparison_Exp>;
  ShortLabel?: Maybe<String_Comparison_Exp>;
  Status?: Maybe<Smallint_Comparison_Exp>;
  SubLoadConsumption?: Maybe<Numeric_Comparison_Exp>;
  SubLoadConsumptionDate?: Maybe<Timestamp_Comparison_Exp>;
  SubLoadState?: Maybe<Int_Comparison_Exp>;
  TenantId?: Maybe<Uuid_Comparison_Exp>;
  TenantLabel?: Maybe<String_Comparison_Exp>;
  Tier?: Maybe<Int_Comparison_Exp>;
  TransformerCapacity?: Maybe<Int_Comparison_Exp>;
  TypeId?: Maybe<Uuid_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  UtilityType?: Maybe<Smallint_Comparison_Exp>;
  _and?: Maybe<Array<Dw_Loads_Bool_Exp>>;
  _not?: Maybe<Dw_Loads_Bool_Exp>;
  _or?: Maybe<Array<Dw_Loads_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.Loads" */
export enum Dw_Loads_Constraint {
  /** unique or primary key constraint */
  LoadsLoadidUindex = 'loads_loadid_uindex',
  /** unique or primary key constraint */
  LoadsPk = 'loads_pk'
}

/** input type for incrementing numeric columns in table "dw.Loads" */
export type Dw_Loads_Inc_Input = {
  CtRating?: Maybe<Scalars['Int']>;
  ModbusId?: Maybe<Scalars['Int']>;
  NabersRating?: Maybe<Scalars['smallint']>;
  NegotiatedCapacity?: Maybe<Scalars['Int']>;
  Status?: Maybe<Scalars['smallint']>;
  SubLoadConsumption?: Maybe<Scalars['numeric']>;
  SubLoadState?: Maybe<Scalars['Int']>;
  Tier?: Maybe<Scalars['Int']>;
  TransformerCapacity?: Maybe<Scalars['Int']>;
  UtilityType?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.Loads" */
export type Dw_Loads_Insert_Input = {
  BreakerRating?: Maybe<Scalars['String']>;
  Building?: Maybe<Dw_Buildings_Obj_Rel_Insert_Input>;
  BuildingId?: Maybe<Scalars['uuid']>;
  Channels?: Maybe<Dw_Channels_Arr_Rel_Insert_Input>;
  CommunicationType?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  CtRating?: Maybe<Scalars['Int']>;
  CtSupplied?: Maybe<Scalars['String']>;
  CtType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Feed?: Maybe<Dw_Feeds_Obj_Rel_Insert_Input>;
  FeedId?: Maybe<Scalars['uuid']>;
  IcpNumber?: Maybe<Scalars['String']>;
  Label?: Maybe<Scalars['String']>;
  LoadDocuments?: Maybe<Dw_LoadDocuments_Arr_Rel_Insert_Input>;
  LoadId?: Maybe<Scalars['uuid']>;
  LoadsService?: Maybe<Dw_LoadsService_Obj_Rel_Insert_Input>;
  LoadsType?: Maybe<Dw_LoadsType_Obj_Rel_Insert_Input>;
  MainIncomer?: Maybe<Scalars['Boolean']>;
  MeterName?: Maybe<Scalars['String']>;
  MeterSerialNumber?: Maybe<Scalars['String']>;
  MeterType?: Maybe<Scalars['String']>;
  ModbusId?: Maybe<Scalars['Int']>;
  NabersRating?: Maybe<Scalars['smallint']>;
  NegotiatedCapacity?: Maybe<Scalars['Int']>;
  Notifications?: Maybe<Dw_Notifications_Arr_Rel_Insert_Input>;
  ParentLoad?: Maybe<Dw_Loads_Obj_Rel_Insert_Input>;
  ParentLoadId?: Maybe<Scalars['uuid']>;
  Projects?: Maybe<Dw_Projects_Arr_Rel_Insert_Input>;
  ReferenceValue?: Maybe<Scalars['String']>;
  ServiceId?: Maybe<Scalars['uuid']>;
  ShortLabel?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['smallint']>;
  SubLoadConsumption?: Maybe<Scalars['numeric']>;
  SubLoadConsumptionDate?: Maybe<Scalars['timestamp']>;
  SubLoadState?: Maybe<Scalars['Int']>;
  TenantId?: Maybe<Scalars['uuid']>;
  TenantLabel?: Maybe<Scalars['String']>;
  Tier?: Maybe<Scalars['Int']>;
  TransformerCapacity?: Maybe<Scalars['Int']>;
  TypeId?: Maybe<Scalars['uuid']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  UtilityType?: Maybe<Scalars['smallint']>;
};

/** aggregate max on columns */
export type Dw_Loads_Max_Fields = {
  __typename?: 'dw_Loads_max_fields';
  BreakerRating?: Maybe<Scalars['String']>;
  BuildingId?: Maybe<Scalars['uuid']>;
  CommunicationType?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  CtRating?: Maybe<Scalars['Int']>;
  CtSupplied?: Maybe<Scalars['String']>;
  CtType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  FeedId?: Maybe<Scalars['uuid']>;
  IcpNumber?: Maybe<Scalars['String']>;
  Label?: Maybe<Scalars['String']>;
  LoadId?: Maybe<Scalars['uuid']>;
  MeterName?: Maybe<Scalars['String']>;
  MeterSerialNumber?: Maybe<Scalars['String']>;
  MeterType?: Maybe<Scalars['String']>;
  ModbusId?: Maybe<Scalars['Int']>;
  NabersRating?: Maybe<Scalars['smallint']>;
  NegotiatedCapacity?: Maybe<Scalars['Int']>;
  ParentLoadId?: Maybe<Scalars['uuid']>;
  ReferenceValue?: Maybe<Scalars['String']>;
  ServiceId?: Maybe<Scalars['uuid']>;
  ShortLabel?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['smallint']>;
  SubLoadConsumption?: Maybe<Scalars['numeric']>;
  SubLoadConsumptionDate?: Maybe<Scalars['timestamp']>;
  SubLoadState?: Maybe<Scalars['Int']>;
  TenantId?: Maybe<Scalars['uuid']>;
  TenantLabel?: Maybe<Scalars['String']>;
  Tier?: Maybe<Scalars['Int']>;
  TransformerCapacity?: Maybe<Scalars['Int']>;
  TypeId?: Maybe<Scalars['uuid']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  UtilityType?: Maybe<Scalars['smallint']>;
};

/** order by max() on columns of table "dw.Loads" */
export type Dw_Loads_Max_Order_By = {
  BreakerRating?: Maybe<Order_By>;
  BuildingId?: Maybe<Order_By>;
  CommunicationType?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  CtRating?: Maybe<Order_By>;
  CtSupplied?: Maybe<Order_By>;
  CtType?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  FeedId?: Maybe<Order_By>;
  IcpNumber?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  LoadId?: Maybe<Order_By>;
  MeterName?: Maybe<Order_By>;
  MeterSerialNumber?: Maybe<Order_By>;
  MeterType?: Maybe<Order_By>;
  ModbusId?: Maybe<Order_By>;
  NabersRating?: Maybe<Order_By>;
  NegotiatedCapacity?: Maybe<Order_By>;
  ParentLoadId?: Maybe<Order_By>;
  ReferenceValue?: Maybe<Order_By>;
  ServiceId?: Maybe<Order_By>;
  ShortLabel?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  SubLoadConsumption?: Maybe<Order_By>;
  SubLoadConsumptionDate?: Maybe<Order_By>;
  SubLoadState?: Maybe<Order_By>;
  TenantId?: Maybe<Order_By>;
  TenantLabel?: Maybe<Order_By>;
  Tier?: Maybe<Order_By>;
  TransformerCapacity?: Maybe<Order_By>;
  TypeId?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
  UtilityType?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_Loads_Min_Fields = {
  __typename?: 'dw_Loads_min_fields';
  BreakerRating?: Maybe<Scalars['String']>;
  BuildingId?: Maybe<Scalars['uuid']>;
  CommunicationType?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  CtRating?: Maybe<Scalars['Int']>;
  CtSupplied?: Maybe<Scalars['String']>;
  CtType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  FeedId?: Maybe<Scalars['uuid']>;
  IcpNumber?: Maybe<Scalars['String']>;
  Label?: Maybe<Scalars['String']>;
  LoadId?: Maybe<Scalars['uuid']>;
  MeterName?: Maybe<Scalars['String']>;
  MeterSerialNumber?: Maybe<Scalars['String']>;
  MeterType?: Maybe<Scalars['String']>;
  ModbusId?: Maybe<Scalars['Int']>;
  NabersRating?: Maybe<Scalars['smallint']>;
  NegotiatedCapacity?: Maybe<Scalars['Int']>;
  ParentLoadId?: Maybe<Scalars['uuid']>;
  ReferenceValue?: Maybe<Scalars['String']>;
  ServiceId?: Maybe<Scalars['uuid']>;
  ShortLabel?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['smallint']>;
  SubLoadConsumption?: Maybe<Scalars['numeric']>;
  SubLoadConsumptionDate?: Maybe<Scalars['timestamp']>;
  SubLoadState?: Maybe<Scalars['Int']>;
  TenantId?: Maybe<Scalars['uuid']>;
  TenantLabel?: Maybe<Scalars['String']>;
  Tier?: Maybe<Scalars['Int']>;
  TransformerCapacity?: Maybe<Scalars['Int']>;
  TypeId?: Maybe<Scalars['uuid']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  UtilityType?: Maybe<Scalars['smallint']>;
};

/** order by min() on columns of table "dw.Loads" */
export type Dw_Loads_Min_Order_By = {
  BreakerRating?: Maybe<Order_By>;
  BuildingId?: Maybe<Order_By>;
  CommunicationType?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  CtRating?: Maybe<Order_By>;
  CtSupplied?: Maybe<Order_By>;
  CtType?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  FeedId?: Maybe<Order_By>;
  IcpNumber?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  LoadId?: Maybe<Order_By>;
  MeterName?: Maybe<Order_By>;
  MeterSerialNumber?: Maybe<Order_By>;
  MeterType?: Maybe<Order_By>;
  ModbusId?: Maybe<Order_By>;
  NabersRating?: Maybe<Order_By>;
  NegotiatedCapacity?: Maybe<Order_By>;
  ParentLoadId?: Maybe<Order_By>;
  ReferenceValue?: Maybe<Order_By>;
  ServiceId?: Maybe<Order_By>;
  ShortLabel?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  SubLoadConsumption?: Maybe<Order_By>;
  SubLoadConsumptionDate?: Maybe<Order_By>;
  SubLoadState?: Maybe<Order_By>;
  TenantId?: Maybe<Order_By>;
  TenantLabel?: Maybe<Order_By>;
  Tier?: Maybe<Order_By>;
  TransformerCapacity?: Maybe<Order_By>;
  TypeId?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
  UtilityType?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.Loads" */
export type Dw_Loads_Mutation_Response = {
  __typename?: 'dw_Loads_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_Loads>;
};

/** input type for inserting object relation for remote table "dw.Loads" */
export type Dw_Loads_Obj_Rel_Insert_Input = {
  data: Dw_Loads_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_Loads_On_Conflict>;
};

/** on conflict condition type for table "dw.Loads" */
export type Dw_Loads_On_Conflict = {
  constraint: Dw_Loads_Constraint;
  update_columns?: Array<Dw_Loads_Update_Column>;
  where?: Maybe<Dw_Loads_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.Loads". */
export type Dw_Loads_Order_By = {
  BreakerRating?: Maybe<Order_By>;
  Building?: Maybe<Dw_Buildings_Order_By>;
  BuildingId?: Maybe<Order_By>;
  Channels_aggregate?: Maybe<Dw_Channels_Aggregate_Order_By>;
  CommunicationType?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  CtRating?: Maybe<Order_By>;
  CtSupplied?: Maybe<Order_By>;
  CtType?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  Feed?: Maybe<Dw_Feeds_Order_By>;
  FeedId?: Maybe<Order_By>;
  IcpNumber?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  LoadDocuments_aggregate?: Maybe<Dw_LoadDocuments_Aggregate_Order_By>;
  LoadId?: Maybe<Order_By>;
  LoadsService?: Maybe<Dw_LoadsService_Order_By>;
  LoadsType?: Maybe<Dw_LoadsType_Order_By>;
  MainIncomer?: Maybe<Order_By>;
  MeterName?: Maybe<Order_By>;
  MeterSerialNumber?: Maybe<Order_By>;
  MeterType?: Maybe<Order_By>;
  ModbusId?: Maybe<Order_By>;
  NabersRating?: Maybe<Order_By>;
  NegotiatedCapacity?: Maybe<Order_By>;
  Notifications_aggregate?: Maybe<Dw_Notifications_Aggregate_Order_By>;
  ParentLoad?: Maybe<Dw_Loads_Order_By>;
  ParentLoadId?: Maybe<Order_By>;
  Projects_aggregate?: Maybe<Dw_Projects_Aggregate_Order_By>;
  ReferenceValue?: Maybe<Order_By>;
  ServiceId?: Maybe<Order_By>;
  ShortLabel?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  SubLoadConsumption?: Maybe<Order_By>;
  SubLoadConsumptionDate?: Maybe<Order_By>;
  SubLoadState?: Maybe<Order_By>;
  TenantId?: Maybe<Order_By>;
  TenantLabel?: Maybe<Order_By>;
  Tier?: Maybe<Order_By>;
  TransformerCapacity?: Maybe<Order_By>;
  TypeId?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
  UtilityType?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_Loads */
export type Dw_Loads_Pk_Columns_Input = {
  LoadId: Scalars['uuid'];
};

/** select columns of table "dw.Loads" */
export enum Dw_Loads_Select_Column {
  /** column name */
  BreakerRating = 'BreakerRating',
  /** column name */
  BuildingId = 'BuildingId',
  /** column name */
  CommunicationType = 'CommunicationType',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  CtRating = 'CtRating',
  /** column name */
  CtSupplied = 'CtSupplied',
  /** column name */
  CtType = 'CtType',
  /** column name */
  Description = 'Description',
  /** column name */
  FeedId = 'FeedId',
  /** column name */
  IcpNumber = 'IcpNumber',
  /** column name */
  Label = 'Label',
  /** column name */
  LoadId = 'LoadId',
  /** column name */
  MainIncomer = 'MainIncomer',
  /** column name */
  MeterName = 'MeterName',
  /** column name */
  MeterSerialNumber = 'MeterSerialNumber',
  /** column name */
  MeterType = 'MeterType',
  /** column name */
  ModbusId = 'ModbusId',
  /** column name */
  NabersRating = 'NabersRating',
  /** column name */
  NegotiatedCapacity = 'NegotiatedCapacity',
  /** column name */
  ParentLoadId = 'ParentLoadId',
  /** column name */
  ReferenceValue = 'ReferenceValue',
  /** column name */
  ServiceId = 'ServiceId',
  /** column name */
  ShortLabel = 'ShortLabel',
  /** column name */
  Status = 'Status',
  /** column name */
  SubLoadConsumption = 'SubLoadConsumption',
  /** column name */
  SubLoadConsumptionDate = 'SubLoadConsumptionDate',
  /** column name */
  SubLoadState = 'SubLoadState',
  /** column name */
  TenantId = 'TenantId',
  /** column name */
  TenantLabel = 'TenantLabel',
  /** column name */
  Tier = 'Tier',
  /** column name */
  TransformerCapacity = 'TransformerCapacity',
  /** column name */
  TypeId = 'TypeId',
  /** column name */
  UpdatedOn = 'UpdatedOn',
  /** column name */
  UtilityType = 'UtilityType'
}

/** input type for updating data in table "dw.Loads" */
export type Dw_Loads_Set_Input = {
  BreakerRating?: Maybe<Scalars['String']>;
  BuildingId?: Maybe<Scalars['uuid']>;
  CommunicationType?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  CtRating?: Maybe<Scalars['Int']>;
  CtSupplied?: Maybe<Scalars['String']>;
  CtType?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  FeedId?: Maybe<Scalars['uuid']>;
  IcpNumber?: Maybe<Scalars['String']>;
  Label?: Maybe<Scalars['String']>;
  LoadId?: Maybe<Scalars['uuid']>;
  MainIncomer?: Maybe<Scalars['Boolean']>;
  MeterName?: Maybe<Scalars['String']>;
  MeterSerialNumber?: Maybe<Scalars['String']>;
  MeterType?: Maybe<Scalars['String']>;
  ModbusId?: Maybe<Scalars['Int']>;
  NabersRating?: Maybe<Scalars['smallint']>;
  NegotiatedCapacity?: Maybe<Scalars['Int']>;
  ParentLoadId?: Maybe<Scalars['uuid']>;
  ReferenceValue?: Maybe<Scalars['String']>;
  ServiceId?: Maybe<Scalars['uuid']>;
  ShortLabel?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['smallint']>;
  SubLoadConsumption?: Maybe<Scalars['numeric']>;
  SubLoadConsumptionDate?: Maybe<Scalars['timestamp']>;
  SubLoadState?: Maybe<Scalars['Int']>;
  TenantId?: Maybe<Scalars['uuid']>;
  TenantLabel?: Maybe<Scalars['String']>;
  Tier?: Maybe<Scalars['Int']>;
  TransformerCapacity?: Maybe<Scalars['Int']>;
  TypeId?: Maybe<Scalars['uuid']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  UtilityType?: Maybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type Dw_Loads_Stddev_Fields = {
  __typename?: 'dw_Loads_stddev_fields';
  CtRating?: Maybe<Scalars['Float']>;
  ModbusId?: Maybe<Scalars['Float']>;
  NabersRating?: Maybe<Scalars['Float']>;
  NegotiatedCapacity?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  SubLoadConsumption?: Maybe<Scalars['Float']>;
  SubLoadState?: Maybe<Scalars['Float']>;
  Tier?: Maybe<Scalars['Float']>;
  TransformerCapacity?: Maybe<Scalars['Float']>;
  UtilityType?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.Loads" */
export type Dw_Loads_Stddev_Order_By = {
  CtRating?: Maybe<Order_By>;
  ModbusId?: Maybe<Order_By>;
  NabersRating?: Maybe<Order_By>;
  NegotiatedCapacity?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  SubLoadConsumption?: Maybe<Order_By>;
  SubLoadState?: Maybe<Order_By>;
  Tier?: Maybe<Order_By>;
  TransformerCapacity?: Maybe<Order_By>;
  UtilityType?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_Loads_Stddev_Pop_Fields = {
  __typename?: 'dw_Loads_stddev_pop_fields';
  CtRating?: Maybe<Scalars['Float']>;
  ModbusId?: Maybe<Scalars['Float']>;
  NabersRating?: Maybe<Scalars['Float']>;
  NegotiatedCapacity?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  SubLoadConsumption?: Maybe<Scalars['Float']>;
  SubLoadState?: Maybe<Scalars['Float']>;
  Tier?: Maybe<Scalars['Float']>;
  TransformerCapacity?: Maybe<Scalars['Float']>;
  UtilityType?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.Loads" */
export type Dw_Loads_Stddev_Pop_Order_By = {
  CtRating?: Maybe<Order_By>;
  ModbusId?: Maybe<Order_By>;
  NabersRating?: Maybe<Order_By>;
  NegotiatedCapacity?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  SubLoadConsumption?: Maybe<Order_By>;
  SubLoadState?: Maybe<Order_By>;
  Tier?: Maybe<Order_By>;
  TransformerCapacity?: Maybe<Order_By>;
  UtilityType?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_Loads_Stddev_Samp_Fields = {
  __typename?: 'dw_Loads_stddev_samp_fields';
  CtRating?: Maybe<Scalars['Float']>;
  ModbusId?: Maybe<Scalars['Float']>;
  NabersRating?: Maybe<Scalars['Float']>;
  NegotiatedCapacity?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  SubLoadConsumption?: Maybe<Scalars['Float']>;
  SubLoadState?: Maybe<Scalars['Float']>;
  Tier?: Maybe<Scalars['Float']>;
  TransformerCapacity?: Maybe<Scalars['Float']>;
  UtilityType?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.Loads" */
export type Dw_Loads_Stddev_Samp_Order_By = {
  CtRating?: Maybe<Order_By>;
  ModbusId?: Maybe<Order_By>;
  NabersRating?: Maybe<Order_By>;
  NegotiatedCapacity?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  SubLoadConsumption?: Maybe<Order_By>;
  SubLoadState?: Maybe<Order_By>;
  Tier?: Maybe<Order_By>;
  TransformerCapacity?: Maybe<Order_By>;
  UtilityType?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_Loads_Sum_Fields = {
  __typename?: 'dw_Loads_sum_fields';
  CtRating?: Maybe<Scalars['Int']>;
  ModbusId?: Maybe<Scalars['Int']>;
  NabersRating?: Maybe<Scalars['smallint']>;
  NegotiatedCapacity?: Maybe<Scalars['Int']>;
  Status?: Maybe<Scalars['smallint']>;
  SubLoadConsumption?: Maybe<Scalars['numeric']>;
  SubLoadState?: Maybe<Scalars['Int']>;
  Tier?: Maybe<Scalars['Int']>;
  TransformerCapacity?: Maybe<Scalars['Int']>;
  UtilityType?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "dw.Loads" */
export type Dw_Loads_Sum_Order_By = {
  CtRating?: Maybe<Order_By>;
  ModbusId?: Maybe<Order_By>;
  NabersRating?: Maybe<Order_By>;
  NegotiatedCapacity?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  SubLoadConsumption?: Maybe<Order_By>;
  SubLoadState?: Maybe<Order_By>;
  Tier?: Maybe<Order_By>;
  TransformerCapacity?: Maybe<Order_By>;
  UtilityType?: Maybe<Order_By>;
};

/** update columns of table "dw.Loads" */
export enum Dw_Loads_Update_Column {
  /** column name */
  BreakerRating = 'BreakerRating',
  /** column name */
  BuildingId = 'BuildingId',
  /** column name */
  CommunicationType = 'CommunicationType',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  CtRating = 'CtRating',
  /** column name */
  CtSupplied = 'CtSupplied',
  /** column name */
  CtType = 'CtType',
  /** column name */
  Description = 'Description',
  /** column name */
  FeedId = 'FeedId',
  /** column name */
  IcpNumber = 'IcpNumber',
  /** column name */
  Label = 'Label',
  /** column name */
  LoadId = 'LoadId',
  /** column name */
  MainIncomer = 'MainIncomer',
  /** column name */
  MeterName = 'MeterName',
  /** column name */
  MeterSerialNumber = 'MeterSerialNumber',
  /** column name */
  MeterType = 'MeterType',
  /** column name */
  ModbusId = 'ModbusId',
  /** column name */
  NabersRating = 'NabersRating',
  /** column name */
  NegotiatedCapacity = 'NegotiatedCapacity',
  /** column name */
  ParentLoadId = 'ParentLoadId',
  /** column name */
  ReferenceValue = 'ReferenceValue',
  /** column name */
  ServiceId = 'ServiceId',
  /** column name */
  ShortLabel = 'ShortLabel',
  /** column name */
  Status = 'Status',
  /** column name */
  SubLoadConsumption = 'SubLoadConsumption',
  /** column name */
  SubLoadConsumptionDate = 'SubLoadConsumptionDate',
  /** column name */
  SubLoadState = 'SubLoadState',
  /** column name */
  TenantId = 'TenantId',
  /** column name */
  TenantLabel = 'TenantLabel',
  /** column name */
  Tier = 'Tier',
  /** column name */
  TransformerCapacity = 'TransformerCapacity',
  /** column name */
  TypeId = 'TypeId',
  /** column name */
  UpdatedOn = 'UpdatedOn',
  /** column name */
  UtilityType = 'UtilityType'
}

/** aggregate var_pop on columns */
export type Dw_Loads_Var_Pop_Fields = {
  __typename?: 'dw_Loads_var_pop_fields';
  CtRating?: Maybe<Scalars['Float']>;
  ModbusId?: Maybe<Scalars['Float']>;
  NabersRating?: Maybe<Scalars['Float']>;
  NegotiatedCapacity?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  SubLoadConsumption?: Maybe<Scalars['Float']>;
  SubLoadState?: Maybe<Scalars['Float']>;
  Tier?: Maybe<Scalars['Float']>;
  TransformerCapacity?: Maybe<Scalars['Float']>;
  UtilityType?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.Loads" */
export type Dw_Loads_Var_Pop_Order_By = {
  CtRating?: Maybe<Order_By>;
  ModbusId?: Maybe<Order_By>;
  NabersRating?: Maybe<Order_By>;
  NegotiatedCapacity?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  SubLoadConsumption?: Maybe<Order_By>;
  SubLoadState?: Maybe<Order_By>;
  Tier?: Maybe<Order_By>;
  TransformerCapacity?: Maybe<Order_By>;
  UtilityType?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_Loads_Var_Samp_Fields = {
  __typename?: 'dw_Loads_var_samp_fields';
  CtRating?: Maybe<Scalars['Float']>;
  ModbusId?: Maybe<Scalars['Float']>;
  NabersRating?: Maybe<Scalars['Float']>;
  NegotiatedCapacity?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  SubLoadConsumption?: Maybe<Scalars['Float']>;
  SubLoadState?: Maybe<Scalars['Float']>;
  Tier?: Maybe<Scalars['Float']>;
  TransformerCapacity?: Maybe<Scalars['Float']>;
  UtilityType?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.Loads" */
export type Dw_Loads_Var_Samp_Order_By = {
  CtRating?: Maybe<Order_By>;
  ModbusId?: Maybe<Order_By>;
  NabersRating?: Maybe<Order_By>;
  NegotiatedCapacity?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  SubLoadConsumption?: Maybe<Order_By>;
  SubLoadState?: Maybe<Order_By>;
  Tier?: Maybe<Order_By>;
  TransformerCapacity?: Maybe<Order_By>;
  UtilityType?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_Loads_Variance_Fields = {
  __typename?: 'dw_Loads_variance_fields';
  CtRating?: Maybe<Scalars['Float']>;
  ModbusId?: Maybe<Scalars['Float']>;
  NabersRating?: Maybe<Scalars['Float']>;
  NegotiatedCapacity?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  SubLoadConsumption?: Maybe<Scalars['Float']>;
  SubLoadState?: Maybe<Scalars['Float']>;
  Tier?: Maybe<Scalars['Float']>;
  TransformerCapacity?: Maybe<Scalars['Float']>;
  UtilityType?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.Loads" */
export type Dw_Loads_Variance_Order_By = {
  CtRating?: Maybe<Order_By>;
  ModbusId?: Maybe<Order_By>;
  NabersRating?: Maybe<Order_By>;
  NegotiatedCapacity?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  SubLoadConsumption?: Maybe<Order_By>;
  SubLoadState?: Maybe<Order_By>;
  Tier?: Maybe<Order_By>;
  TransformerCapacity?: Maybe<Order_By>;
  UtilityType?: Maybe<Order_By>;
};

/** columns and relationships of "dw.MLModelsMetadata" */
export type Dw_MlModelsMetadata = {
  __typename?: 'dw_MLModelsMetadata';
  AlgorithmType: Scalars['String'];
  /** An array relationship */
  MLNotificationForecasts: Array<Dw_MlNotificationForecasts>;
  /** An aggregate relationship */
  MLNotificationForecasts_aggregate: Dw_MlNotificationForecasts_Aggregate;
  ModelId: Scalars['uuid'];
  ModelS3Url: Scalars['String'];
  TrainingDate: Scalars['timestamp'];
  TrainingWindowEndDate: Scalars['timestamp'];
  TrainingWindowStartDate: Scalars['timestamp'];
};


/** columns and relationships of "dw.MLModelsMetadata" */
export type Dw_MlModelsMetadataMlNotificationForecastsArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotificationForecasts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotificationForecasts_Order_By>>;
  where?: Maybe<Dw_MlNotificationForecasts_Bool_Exp>;
};


/** columns and relationships of "dw.MLModelsMetadata" */
export type Dw_MlModelsMetadataMlNotificationForecasts_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotificationForecasts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotificationForecasts_Order_By>>;
  where?: Maybe<Dw_MlNotificationForecasts_Bool_Exp>;
};

/** aggregated selection of "dw.MLModelsMetadata" */
export type Dw_MlModelsMetadata_Aggregate = {
  __typename?: 'dw_MLModelsMetadata_aggregate';
  aggregate?: Maybe<Dw_MlModelsMetadata_Aggregate_Fields>;
  nodes: Array<Dw_MlModelsMetadata>;
};

/** aggregate fields of "dw.MLModelsMetadata" */
export type Dw_MlModelsMetadata_Aggregate_Fields = {
  __typename?: 'dw_MLModelsMetadata_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Dw_MlModelsMetadata_Max_Fields>;
  min?: Maybe<Dw_MlModelsMetadata_Min_Fields>;
};


/** aggregate fields of "dw.MLModelsMetadata" */
export type Dw_MlModelsMetadata_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_MlModelsMetadata_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "dw.MLModelsMetadata". All fields are combined with a logical 'AND'. */
export type Dw_MlModelsMetadata_Bool_Exp = {
  AlgorithmType?: Maybe<String_Comparison_Exp>;
  MLNotificationForecasts?: Maybe<Dw_MlNotificationForecasts_Bool_Exp>;
  ModelId?: Maybe<Uuid_Comparison_Exp>;
  ModelS3Url?: Maybe<String_Comparison_Exp>;
  TrainingDate?: Maybe<Timestamp_Comparison_Exp>;
  TrainingWindowEndDate?: Maybe<Timestamp_Comparison_Exp>;
  TrainingWindowStartDate?: Maybe<Timestamp_Comparison_Exp>;
  _and?: Maybe<Array<Dw_MlModelsMetadata_Bool_Exp>>;
  _not?: Maybe<Dw_MlModelsMetadata_Bool_Exp>;
  _or?: Maybe<Array<Dw_MlModelsMetadata_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.MLModelsMetadata" */
export enum Dw_MlModelsMetadata_Constraint {
  /** unique or primary key constraint */
  MlmodelsmetadataPk = 'mlmodelsmetadata_pk',
  /** unique or primary key constraint */
  MlmodelsmetadataUnique = 'mlmodelsmetadata_unique'
}

/** input type for inserting data into table "dw.MLModelsMetadata" */
export type Dw_MlModelsMetadata_Insert_Input = {
  AlgorithmType?: Maybe<Scalars['String']>;
  MLNotificationForecasts?: Maybe<Dw_MlNotificationForecasts_Arr_Rel_Insert_Input>;
  ModelId?: Maybe<Scalars['uuid']>;
  ModelS3Url?: Maybe<Scalars['String']>;
  TrainingDate?: Maybe<Scalars['timestamp']>;
  TrainingWindowEndDate?: Maybe<Scalars['timestamp']>;
  TrainingWindowStartDate?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Dw_MlModelsMetadata_Max_Fields = {
  __typename?: 'dw_MLModelsMetadata_max_fields';
  AlgorithmType?: Maybe<Scalars['String']>;
  ModelId?: Maybe<Scalars['uuid']>;
  ModelS3Url?: Maybe<Scalars['String']>;
  TrainingDate?: Maybe<Scalars['timestamp']>;
  TrainingWindowEndDate?: Maybe<Scalars['timestamp']>;
  TrainingWindowStartDate?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Dw_MlModelsMetadata_Min_Fields = {
  __typename?: 'dw_MLModelsMetadata_min_fields';
  AlgorithmType?: Maybe<Scalars['String']>;
  ModelId?: Maybe<Scalars['uuid']>;
  ModelS3Url?: Maybe<Scalars['String']>;
  TrainingDate?: Maybe<Scalars['timestamp']>;
  TrainingWindowEndDate?: Maybe<Scalars['timestamp']>;
  TrainingWindowStartDate?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "dw.MLModelsMetadata" */
export type Dw_MlModelsMetadata_Mutation_Response = {
  __typename?: 'dw_MLModelsMetadata_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_MlModelsMetadata>;
};

/** input type for inserting object relation for remote table "dw.MLModelsMetadata" */
export type Dw_MlModelsMetadata_Obj_Rel_Insert_Input = {
  data: Dw_MlModelsMetadata_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_MlModelsMetadata_On_Conflict>;
};

/** on conflict condition type for table "dw.MLModelsMetadata" */
export type Dw_MlModelsMetadata_On_Conflict = {
  constraint: Dw_MlModelsMetadata_Constraint;
  update_columns?: Array<Dw_MlModelsMetadata_Update_Column>;
  where?: Maybe<Dw_MlModelsMetadata_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.MLModelsMetadata". */
export type Dw_MlModelsMetadata_Order_By = {
  AlgorithmType?: Maybe<Order_By>;
  MLNotificationForecasts_aggregate?: Maybe<Dw_MlNotificationForecasts_Aggregate_Order_By>;
  ModelId?: Maybe<Order_By>;
  ModelS3Url?: Maybe<Order_By>;
  TrainingDate?: Maybe<Order_By>;
  TrainingWindowEndDate?: Maybe<Order_By>;
  TrainingWindowStartDate?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_MLModelsMetadata */
export type Dw_MlModelsMetadata_Pk_Columns_Input = {
  ModelId: Scalars['uuid'];
};

/** select columns of table "dw.MLModelsMetadata" */
export enum Dw_MlModelsMetadata_Select_Column {
  /** column name */
  AlgorithmType = 'AlgorithmType',
  /** column name */
  ModelId = 'ModelId',
  /** column name */
  ModelS3Url = 'ModelS3Url',
  /** column name */
  TrainingDate = 'TrainingDate',
  /** column name */
  TrainingWindowEndDate = 'TrainingWindowEndDate',
  /** column name */
  TrainingWindowStartDate = 'TrainingWindowStartDate'
}

/** input type for updating data in table "dw.MLModelsMetadata" */
export type Dw_MlModelsMetadata_Set_Input = {
  AlgorithmType?: Maybe<Scalars['String']>;
  ModelId?: Maybe<Scalars['uuid']>;
  ModelS3Url?: Maybe<Scalars['String']>;
  TrainingDate?: Maybe<Scalars['timestamp']>;
  TrainingWindowEndDate?: Maybe<Scalars['timestamp']>;
  TrainingWindowStartDate?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "dw.MLModelsMetadata" */
export enum Dw_MlModelsMetadata_Update_Column {
  /** column name */
  AlgorithmType = 'AlgorithmType',
  /** column name */
  ModelId = 'ModelId',
  /** column name */
  ModelS3Url = 'ModelS3Url',
  /** column name */
  TrainingDate = 'TrainingDate',
  /** column name */
  TrainingWindowEndDate = 'TrainingWindowEndDate',
  /** column name */
  TrainingWindowStartDate = 'TrainingWindowStartDate'
}

/** columns and relationships of "dw.MLNotificationForecasts" */
export type Dw_MlNotificationForecasts = {
  __typename?: 'dw_MLNotificationForecasts';
  /** An object relationship */
  Channel: Dw_Channels;
  ChannelId: Scalars['uuid'];
  ForecastDate: Scalars['timestamp'];
  Forecasted: Scalars['numeric'];
  /** An object relationship */
  MLModelsMetadatum: Dw_MlModelsMetadata;
  ModelId: Scalars['uuid'];
  UpdatedOn: Scalars['timestamp'];
};

/** aggregated selection of "dw.MLNotificationForecasts" */
export type Dw_MlNotificationForecasts_Aggregate = {
  __typename?: 'dw_MLNotificationForecasts_aggregate';
  aggregate?: Maybe<Dw_MlNotificationForecasts_Aggregate_Fields>;
  nodes: Array<Dw_MlNotificationForecasts>;
};

/** aggregate fields of "dw.MLNotificationForecasts" */
export type Dw_MlNotificationForecasts_Aggregate_Fields = {
  __typename?: 'dw_MLNotificationForecasts_aggregate_fields';
  avg?: Maybe<Dw_MlNotificationForecasts_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_MlNotificationForecasts_Max_Fields>;
  min?: Maybe<Dw_MlNotificationForecasts_Min_Fields>;
  stddev?: Maybe<Dw_MlNotificationForecasts_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_MlNotificationForecasts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_MlNotificationForecasts_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_MlNotificationForecasts_Sum_Fields>;
  var_pop?: Maybe<Dw_MlNotificationForecasts_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_MlNotificationForecasts_Var_Samp_Fields>;
  variance?: Maybe<Dw_MlNotificationForecasts_Variance_Fields>;
};


/** aggregate fields of "dw.MLNotificationForecasts" */
export type Dw_MlNotificationForecasts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_MlNotificationForecasts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.MLNotificationForecasts" */
export type Dw_MlNotificationForecasts_Aggregate_Order_By = {
  avg?: Maybe<Dw_MlNotificationForecasts_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_MlNotificationForecasts_Max_Order_By>;
  min?: Maybe<Dw_MlNotificationForecasts_Min_Order_By>;
  stddev?: Maybe<Dw_MlNotificationForecasts_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_MlNotificationForecasts_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_MlNotificationForecasts_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_MlNotificationForecasts_Sum_Order_By>;
  var_pop?: Maybe<Dw_MlNotificationForecasts_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_MlNotificationForecasts_Var_Samp_Order_By>;
  variance?: Maybe<Dw_MlNotificationForecasts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.MLNotificationForecasts" */
export type Dw_MlNotificationForecasts_Arr_Rel_Insert_Input = {
  data: Array<Dw_MlNotificationForecasts_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_MlNotificationForecasts_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_MlNotificationForecasts_Avg_Fields = {
  __typename?: 'dw_MLNotificationForecasts_avg_fields';
  Forecasted?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.MLNotificationForecasts" */
export type Dw_MlNotificationForecasts_Avg_Order_By = {
  Forecasted?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.MLNotificationForecasts". All fields are combined with a logical 'AND'. */
export type Dw_MlNotificationForecasts_Bool_Exp = {
  Channel?: Maybe<Dw_Channels_Bool_Exp>;
  ChannelId?: Maybe<Uuid_Comparison_Exp>;
  ForecastDate?: Maybe<Timestamp_Comparison_Exp>;
  Forecasted?: Maybe<Numeric_Comparison_Exp>;
  MLModelsMetadatum?: Maybe<Dw_MlModelsMetadata_Bool_Exp>;
  ModelId?: Maybe<Uuid_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  _and?: Maybe<Array<Dw_MlNotificationForecasts_Bool_Exp>>;
  _not?: Maybe<Dw_MlNotificationForecasts_Bool_Exp>;
  _or?: Maybe<Array<Dw_MlNotificationForecasts_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.MLNotificationForecasts" */
export enum Dw_MlNotificationForecasts_Constraint {
  /** unique or primary key constraint */
  MlnotificationforecastsUnique = 'mlnotificationforecasts_unique'
}

/** input type for incrementing numeric columns in table "dw.MLNotificationForecasts" */
export type Dw_MlNotificationForecasts_Inc_Input = {
  Forecasted?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "dw.MLNotificationForecasts" */
export type Dw_MlNotificationForecasts_Insert_Input = {
  Channel?: Maybe<Dw_Channels_Obj_Rel_Insert_Input>;
  ChannelId?: Maybe<Scalars['uuid']>;
  ForecastDate?: Maybe<Scalars['timestamp']>;
  Forecasted?: Maybe<Scalars['numeric']>;
  MLModelsMetadatum?: Maybe<Dw_MlModelsMetadata_Obj_Rel_Insert_Input>;
  ModelId?: Maybe<Scalars['uuid']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Dw_MlNotificationForecasts_Max_Fields = {
  __typename?: 'dw_MLNotificationForecasts_max_fields';
  ChannelId?: Maybe<Scalars['uuid']>;
  ForecastDate?: Maybe<Scalars['timestamp']>;
  Forecasted?: Maybe<Scalars['numeric']>;
  ModelId?: Maybe<Scalars['uuid']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "dw.MLNotificationForecasts" */
export type Dw_MlNotificationForecasts_Max_Order_By = {
  ChannelId?: Maybe<Order_By>;
  ForecastDate?: Maybe<Order_By>;
  Forecasted?: Maybe<Order_By>;
  ModelId?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_MlNotificationForecasts_Min_Fields = {
  __typename?: 'dw_MLNotificationForecasts_min_fields';
  ChannelId?: Maybe<Scalars['uuid']>;
  ForecastDate?: Maybe<Scalars['timestamp']>;
  Forecasted?: Maybe<Scalars['numeric']>;
  ModelId?: Maybe<Scalars['uuid']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "dw.MLNotificationForecasts" */
export type Dw_MlNotificationForecasts_Min_Order_By = {
  ChannelId?: Maybe<Order_By>;
  ForecastDate?: Maybe<Order_By>;
  Forecasted?: Maybe<Order_By>;
  ModelId?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.MLNotificationForecasts" */
export type Dw_MlNotificationForecasts_Mutation_Response = {
  __typename?: 'dw_MLNotificationForecasts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_MlNotificationForecasts>;
};

/** on conflict condition type for table "dw.MLNotificationForecasts" */
export type Dw_MlNotificationForecasts_On_Conflict = {
  constraint: Dw_MlNotificationForecasts_Constraint;
  update_columns?: Array<Dw_MlNotificationForecasts_Update_Column>;
  where?: Maybe<Dw_MlNotificationForecasts_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.MLNotificationForecasts". */
export type Dw_MlNotificationForecasts_Order_By = {
  Channel?: Maybe<Dw_Channels_Order_By>;
  ChannelId?: Maybe<Order_By>;
  ForecastDate?: Maybe<Order_By>;
  Forecasted?: Maybe<Order_By>;
  MLModelsMetadatum?: Maybe<Dw_MlModelsMetadata_Order_By>;
  ModelId?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** select columns of table "dw.MLNotificationForecasts" */
export enum Dw_MlNotificationForecasts_Select_Column {
  /** column name */
  ChannelId = 'ChannelId',
  /** column name */
  ForecastDate = 'ForecastDate',
  /** column name */
  Forecasted = 'Forecasted',
  /** column name */
  ModelId = 'ModelId',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** input type for updating data in table "dw.MLNotificationForecasts" */
export type Dw_MlNotificationForecasts_Set_Input = {
  ChannelId?: Maybe<Scalars['uuid']>;
  ForecastDate?: Maybe<Scalars['timestamp']>;
  Forecasted?: Maybe<Scalars['numeric']>;
  ModelId?: Maybe<Scalars['uuid']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Dw_MlNotificationForecasts_Stddev_Fields = {
  __typename?: 'dw_MLNotificationForecasts_stddev_fields';
  Forecasted?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.MLNotificationForecasts" */
export type Dw_MlNotificationForecasts_Stddev_Order_By = {
  Forecasted?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_MlNotificationForecasts_Stddev_Pop_Fields = {
  __typename?: 'dw_MLNotificationForecasts_stddev_pop_fields';
  Forecasted?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.MLNotificationForecasts" */
export type Dw_MlNotificationForecasts_Stddev_Pop_Order_By = {
  Forecasted?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_MlNotificationForecasts_Stddev_Samp_Fields = {
  __typename?: 'dw_MLNotificationForecasts_stddev_samp_fields';
  Forecasted?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.MLNotificationForecasts" */
export type Dw_MlNotificationForecasts_Stddev_Samp_Order_By = {
  Forecasted?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_MlNotificationForecasts_Sum_Fields = {
  __typename?: 'dw_MLNotificationForecasts_sum_fields';
  Forecasted?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "dw.MLNotificationForecasts" */
export type Dw_MlNotificationForecasts_Sum_Order_By = {
  Forecasted?: Maybe<Order_By>;
};

/** update columns of table "dw.MLNotificationForecasts" */
export enum Dw_MlNotificationForecasts_Update_Column {
  /** column name */
  ChannelId = 'ChannelId',
  /** column name */
  ForecastDate = 'ForecastDate',
  /** column name */
  Forecasted = 'Forecasted',
  /** column name */
  ModelId = 'ModelId',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** aggregate var_pop on columns */
export type Dw_MlNotificationForecasts_Var_Pop_Fields = {
  __typename?: 'dw_MLNotificationForecasts_var_pop_fields';
  Forecasted?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.MLNotificationForecasts" */
export type Dw_MlNotificationForecasts_Var_Pop_Order_By = {
  Forecasted?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_MlNotificationForecasts_Var_Samp_Fields = {
  __typename?: 'dw_MLNotificationForecasts_var_samp_fields';
  Forecasted?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.MLNotificationForecasts" */
export type Dw_MlNotificationForecasts_Var_Samp_Order_By = {
  Forecasted?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_MlNotificationForecasts_Variance_Fields = {
  __typename?: 'dw_MLNotificationForecasts_variance_fields';
  Forecasted?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.MLNotificationForecasts" */
export type Dw_MlNotificationForecasts_Variance_Order_By = {
  Forecasted?: Maybe<Order_By>;
};

/** columns and relationships of "dw.MLNotificationGroup" */
export type Dw_MlNotificationGroup = {
  __typename?: 'dw_MLNotificationGroup';
  AlertType: Scalars['String'];
  /** An object relationship */
  Building: Dw_Buildings;
  BuildingId: Scalars['uuid'];
  /** An object relationship */
  Contact?: Maybe<Dw_Contacts>;
  ContactId?: Maybe<Scalars['uuid']>;
  CreatedOn: Scalars['timestamp'];
  EndDate: Scalars['timestamp'];
  /** An array relationship */
  MLNotifications: Array<Dw_MlNotifications>;
  /** An object relationship */
  MLNotificationsMetadatum: Dw_MlNotificationsMetadata;
  /** An aggregate relationship */
  MLNotifications_aggregate: Dw_MlNotifications_Aggregate;
  NotificationGroupId: Scalars['uuid'];
  OnShift: Scalars['Boolean'];
  Priority: Scalars['Int'];
  QualificationStatus: Scalars['smallint'];
  RootCause?: Maybe<Scalars['String']>;
  SplitId: Scalars['Int'];
  StartDate: Scalars['timestamp'];
  Summary: Scalars['String'];
  UpdatedOn: Scalars['timestamp'];
};


/** columns and relationships of "dw.MLNotificationGroup" */
export type Dw_MlNotificationGroupMlNotificationsArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotifications_Order_By>>;
  where?: Maybe<Dw_MlNotifications_Bool_Exp>;
};


/** columns and relationships of "dw.MLNotificationGroup" */
export type Dw_MlNotificationGroupMlNotifications_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotifications_Order_By>>;
  where?: Maybe<Dw_MlNotifications_Bool_Exp>;
};

/** aggregated selection of "dw.MLNotificationGroup" */
export type Dw_MlNotificationGroup_Aggregate = {
  __typename?: 'dw_MLNotificationGroup_aggregate';
  aggregate?: Maybe<Dw_MlNotificationGroup_Aggregate_Fields>;
  nodes: Array<Dw_MlNotificationGroup>;
};

/** aggregate fields of "dw.MLNotificationGroup" */
export type Dw_MlNotificationGroup_Aggregate_Fields = {
  __typename?: 'dw_MLNotificationGroup_aggregate_fields';
  avg?: Maybe<Dw_MlNotificationGroup_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_MlNotificationGroup_Max_Fields>;
  min?: Maybe<Dw_MlNotificationGroup_Min_Fields>;
  stddev?: Maybe<Dw_MlNotificationGroup_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_MlNotificationGroup_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_MlNotificationGroup_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_MlNotificationGroup_Sum_Fields>;
  var_pop?: Maybe<Dw_MlNotificationGroup_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_MlNotificationGroup_Var_Samp_Fields>;
  variance?: Maybe<Dw_MlNotificationGroup_Variance_Fields>;
};


/** aggregate fields of "dw.MLNotificationGroup" */
export type Dw_MlNotificationGroup_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_MlNotificationGroup_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.MLNotificationGroup" */
export type Dw_MlNotificationGroup_Aggregate_Order_By = {
  avg?: Maybe<Dw_MlNotificationGroup_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_MlNotificationGroup_Max_Order_By>;
  min?: Maybe<Dw_MlNotificationGroup_Min_Order_By>;
  stddev?: Maybe<Dw_MlNotificationGroup_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_MlNotificationGroup_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_MlNotificationGroup_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_MlNotificationGroup_Sum_Order_By>;
  var_pop?: Maybe<Dw_MlNotificationGroup_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_MlNotificationGroup_Var_Samp_Order_By>;
  variance?: Maybe<Dw_MlNotificationGroup_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.MLNotificationGroup" */
export type Dw_MlNotificationGroup_Arr_Rel_Insert_Input = {
  data: Array<Dw_MlNotificationGroup_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_MlNotificationGroup_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_MlNotificationGroup_Avg_Fields = {
  __typename?: 'dw_MLNotificationGroup_avg_fields';
  Priority?: Maybe<Scalars['Float']>;
  QualificationStatus?: Maybe<Scalars['Float']>;
  SplitId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.MLNotificationGroup" */
export type Dw_MlNotificationGroup_Avg_Order_By = {
  Priority?: Maybe<Order_By>;
  QualificationStatus?: Maybe<Order_By>;
  SplitId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.MLNotificationGroup". All fields are combined with a logical 'AND'. */
export type Dw_MlNotificationGroup_Bool_Exp = {
  AlertType?: Maybe<String_Comparison_Exp>;
  Building?: Maybe<Dw_Buildings_Bool_Exp>;
  BuildingId?: Maybe<Uuid_Comparison_Exp>;
  Contact?: Maybe<Dw_Contacts_Bool_Exp>;
  ContactId?: Maybe<Uuid_Comparison_Exp>;
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  EndDate?: Maybe<Timestamp_Comparison_Exp>;
  MLNotifications?: Maybe<Dw_MlNotifications_Bool_Exp>;
  MLNotificationsMetadatum?: Maybe<Dw_MlNotificationsMetadata_Bool_Exp>;
  NotificationGroupId?: Maybe<Uuid_Comparison_Exp>;
  OnShift?: Maybe<Boolean_Comparison_Exp>;
  Priority?: Maybe<Int_Comparison_Exp>;
  QualificationStatus?: Maybe<Smallint_Comparison_Exp>;
  RootCause?: Maybe<String_Comparison_Exp>;
  SplitId?: Maybe<Int_Comparison_Exp>;
  StartDate?: Maybe<Timestamp_Comparison_Exp>;
  Summary?: Maybe<String_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  _and?: Maybe<Array<Dw_MlNotificationGroup_Bool_Exp>>;
  _not?: Maybe<Dw_MlNotificationGroup_Bool_Exp>;
  _or?: Maybe<Array<Dw_MlNotificationGroup_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.MLNotificationGroup" */
export enum Dw_MlNotificationGroup_Constraint {
  /** unique or primary key constraint */
  MlnotificationgroupPk = 'mlnotificationgroup_pk',
  /** unique or primary key constraint */
  MlnotificationgroupUnique = 'mlnotificationgroup_unique'
}

/** input type for incrementing numeric columns in table "dw.MLNotificationGroup" */
export type Dw_MlNotificationGroup_Inc_Input = {
  Priority?: Maybe<Scalars['Int']>;
  QualificationStatus?: Maybe<Scalars['smallint']>;
  SplitId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "dw.MLNotificationGroup" */
export type Dw_MlNotificationGroup_Insert_Input = {
  AlertType?: Maybe<Scalars['String']>;
  Building?: Maybe<Dw_Buildings_Obj_Rel_Insert_Input>;
  BuildingId?: Maybe<Scalars['uuid']>;
  Contact?: Maybe<Dw_Contacts_Obj_Rel_Insert_Input>;
  ContactId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  EndDate?: Maybe<Scalars['timestamp']>;
  MLNotifications?: Maybe<Dw_MlNotifications_Arr_Rel_Insert_Input>;
  MLNotificationsMetadatum?: Maybe<Dw_MlNotificationsMetadata_Obj_Rel_Insert_Input>;
  NotificationGroupId?: Maybe<Scalars['uuid']>;
  OnShift?: Maybe<Scalars['Boolean']>;
  Priority?: Maybe<Scalars['Int']>;
  QualificationStatus?: Maybe<Scalars['smallint']>;
  RootCause?: Maybe<Scalars['String']>;
  SplitId?: Maybe<Scalars['Int']>;
  StartDate?: Maybe<Scalars['timestamp']>;
  Summary?: Maybe<Scalars['String']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Dw_MlNotificationGroup_Max_Fields = {
  __typename?: 'dw_MLNotificationGroup_max_fields';
  AlertType?: Maybe<Scalars['String']>;
  BuildingId?: Maybe<Scalars['uuid']>;
  ContactId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  EndDate?: Maybe<Scalars['timestamp']>;
  NotificationGroupId?: Maybe<Scalars['uuid']>;
  Priority?: Maybe<Scalars['Int']>;
  QualificationStatus?: Maybe<Scalars['smallint']>;
  RootCause?: Maybe<Scalars['String']>;
  SplitId?: Maybe<Scalars['Int']>;
  StartDate?: Maybe<Scalars['timestamp']>;
  Summary?: Maybe<Scalars['String']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "dw.MLNotificationGroup" */
export type Dw_MlNotificationGroup_Max_Order_By = {
  AlertType?: Maybe<Order_By>;
  BuildingId?: Maybe<Order_By>;
  ContactId?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  EndDate?: Maybe<Order_By>;
  NotificationGroupId?: Maybe<Order_By>;
  Priority?: Maybe<Order_By>;
  QualificationStatus?: Maybe<Order_By>;
  RootCause?: Maybe<Order_By>;
  SplitId?: Maybe<Order_By>;
  StartDate?: Maybe<Order_By>;
  Summary?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_MlNotificationGroup_Min_Fields = {
  __typename?: 'dw_MLNotificationGroup_min_fields';
  AlertType?: Maybe<Scalars['String']>;
  BuildingId?: Maybe<Scalars['uuid']>;
  ContactId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  EndDate?: Maybe<Scalars['timestamp']>;
  NotificationGroupId?: Maybe<Scalars['uuid']>;
  Priority?: Maybe<Scalars['Int']>;
  QualificationStatus?: Maybe<Scalars['smallint']>;
  RootCause?: Maybe<Scalars['String']>;
  SplitId?: Maybe<Scalars['Int']>;
  StartDate?: Maybe<Scalars['timestamp']>;
  Summary?: Maybe<Scalars['String']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "dw.MLNotificationGroup" */
export type Dw_MlNotificationGroup_Min_Order_By = {
  AlertType?: Maybe<Order_By>;
  BuildingId?: Maybe<Order_By>;
  ContactId?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  EndDate?: Maybe<Order_By>;
  NotificationGroupId?: Maybe<Order_By>;
  Priority?: Maybe<Order_By>;
  QualificationStatus?: Maybe<Order_By>;
  RootCause?: Maybe<Order_By>;
  SplitId?: Maybe<Order_By>;
  StartDate?: Maybe<Order_By>;
  Summary?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.MLNotificationGroup" */
export type Dw_MlNotificationGroup_Mutation_Response = {
  __typename?: 'dw_MLNotificationGroup_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_MlNotificationGroup>;
};

/** input type for inserting object relation for remote table "dw.MLNotificationGroup" */
export type Dw_MlNotificationGroup_Obj_Rel_Insert_Input = {
  data: Dw_MlNotificationGroup_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_MlNotificationGroup_On_Conflict>;
};

/** on conflict condition type for table "dw.MLNotificationGroup" */
export type Dw_MlNotificationGroup_On_Conflict = {
  constraint: Dw_MlNotificationGroup_Constraint;
  update_columns?: Array<Dw_MlNotificationGroup_Update_Column>;
  where?: Maybe<Dw_MlNotificationGroup_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.MLNotificationGroup". */
export type Dw_MlNotificationGroup_Order_By = {
  AlertType?: Maybe<Order_By>;
  Building?: Maybe<Dw_Buildings_Order_By>;
  BuildingId?: Maybe<Order_By>;
  Contact?: Maybe<Dw_Contacts_Order_By>;
  ContactId?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  EndDate?: Maybe<Order_By>;
  MLNotificationsMetadatum?: Maybe<Dw_MlNotificationsMetadata_Order_By>;
  MLNotifications_aggregate?: Maybe<Dw_MlNotifications_Aggregate_Order_By>;
  NotificationGroupId?: Maybe<Order_By>;
  OnShift?: Maybe<Order_By>;
  Priority?: Maybe<Order_By>;
  QualificationStatus?: Maybe<Order_By>;
  RootCause?: Maybe<Order_By>;
  SplitId?: Maybe<Order_By>;
  StartDate?: Maybe<Order_By>;
  Summary?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_MLNotificationGroup */
export type Dw_MlNotificationGroup_Pk_Columns_Input = {
  NotificationGroupId: Scalars['uuid'];
};

/** select columns of table "dw.MLNotificationGroup" */
export enum Dw_MlNotificationGroup_Select_Column {
  /** column name */
  AlertType = 'AlertType',
  /** column name */
  BuildingId = 'BuildingId',
  /** column name */
  ContactId = 'ContactId',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  EndDate = 'EndDate',
  /** column name */
  NotificationGroupId = 'NotificationGroupId',
  /** column name */
  OnShift = 'OnShift',
  /** column name */
  Priority = 'Priority',
  /** column name */
  QualificationStatus = 'QualificationStatus',
  /** column name */
  RootCause = 'RootCause',
  /** column name */
  SplitId = 'SplitId',
  /** column name */
  StartDate = 'StartDate',
  /** column name */
  Summary = 'Summary',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** input type for updating data in table "dw.MLNotificationGroup" */
export type Dw_MlNotificationGroup_Set_Input = {
  AlertType?: Maybe<Scalars['String']>;
  BuildingId?: Maybe<Scalars['uuid']>;
  ContactId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  EndDate?: Maybe<Scalars['timestamp']>;
  NotificationGroupId?: Maybe<Scalars['uuid']>;
  OnShift?: Maybe<Scalars['Boolean']>;
  Priority?: Maybe<Scalars['Int']>;
  QualificationStatus?: Maybe<Scalars['smallint']>;
  RootCause?: Maybe<Scalars['String']>;
  SplitId?: Maybe<Scalars['Int']>;
  StartDate?: Maybe<Scalars['timestamp']>;
  Summary?: Maybe<Scalars['String']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Dw_MlNotificationGroup_Stddev_Fields = {
  __typename?: 'dw_MLNotificationGroup_stddev_fields';
  Priority?: Maybe<Scalars['Float']>;
  QualificationStatus?: Maybe<Scalars['Float']>;
  SplitId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.MLNotificationGroup" */
export type Dw_MlNotificationGroup_Stddev_Order_By = {
  Priority?: Maybe<Order_By>;
  QualificationStatus?: Maybe<Order_By>;
  SplitId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_MlNotificationGroup_Stddev_Pop_Fields = {
  __typename?: 'dw_MLNotificationGroup_stddev_pop_fields';
  Priority?: Maybe<Scalars['Float']>;
  QualificationStatus?: Maybe<Scalars['Float']>;
  SplitId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.MLNotificationGroup" */
export type Dw_MlNotificationGroup_Stddev_Pop_Order_By = {
  Priority?: Maybe<Order_By>;
  QualificationStatus?: Maybe<Order_By>;
  SplitId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_MlNotificationGroup_Stddev_Samp_Fields = {
  __typename?: 'dw_MLNotificationGroup_stddev_samp_fields';
  Priority?: Maybe<Scalars['Float']>;
  QualificationStatus?: Maybe<Scalars['Float']>;
  SplitId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.MLNotificationGroup" */
export type Dw_MlNotificationGroup_Stddev_Samp_Order_By = {
  Priority?: Maybe<Order_By>;
  QualificationStatus?: Maybe<Order_By>;
  SplitId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_MlNotificationGroup_Sum_Fields = {
  __typename?: 'dw_MLNotificationGroup_sum_fields';
  Priority?: Maybe<Scalars['Int']>;
  QualificationStatus?: Maybe<Scalars['smallint']>;
  SplitId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "dw.MLNotificationGroup" */
export type Dw_MlNotificationGroup_Sum_Order_By = {
  Priority?: Maybe<Order_By>;
  QualificationStatus?: Maybe<Order_By>;
  SplitId?: Maybe<Order_By>;
};

/** update columns of table "dw.MLNotificationGroup" */
export enum Dw_MlNotificationGroup_Update_Column {
  /** column name */
  AlertType = 'AlertType',
  /** column name */
  BuildingId = 'BuildingId',
  /** column name */
  ContactId = 'ContactId',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  EndDate = 'EndDate',
  /** column name */
  NotificationGroupId = 'NotificationGroupId',
  /** column name */
  OnShift = 'OnShift',
  /** column name */
  Priority = 'Priority',
  /** column name */
  QualificationStatus = 'QualificationStatus',
  /** column name */
  RootCause = 'RootCause',
  /** column name */
  SplitId = 'SplitId',
  /** column name */
  StartDate = 'StartDate',
  /** column name */
  Summary = 'Summary',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** aggregate var_pop on columns */
export type Dw_MlNotificationGroup_Var_Pop_Fields = {
  __typename?: 'dw_MLNotificationGroup_var_pop_fields';
  Priority?: Maybe<Scalars['Float']>;
  QualificationStatus?: Maybe<Scalars['Float']>;
  SplitId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.MLNotificationGroup" */
export type Dw_MlNotificationGroup_Var_Pop_Order_By = {
  Priority?: Maybe<Order_By>;
  QualificationStatus?: Maybe<Order_By>;
  SplitId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_MlNotificationGroup_Var_Samp_Fields = {
  __typename?: 'dw_MLNotificationGroup_var_samp_fields';
  Priority?: Maybe<Scalars['Float']>;
  QualificationStatus?: Maybe<Scalars['Float']>;
  SplitId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.MLNotificationGroup" */
export type Dw_MlNotificationGroup_Var_Samp_Order_By = {
  Priority?: Maybe<Order_By>;
  QualificationStatus?: Maybe<Order_By>;
  SplitId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_MlNotificationGroup_Variance_Fields = {
  __typename?: 'dw_MLNotificationGroup_variance_fields';
  Priority?: Maybe<Scalars['Float']>;
  QualificationStatus?: Maybe<Scalars['Float']>;
  SplitId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.MLNotificationGroup" */
export type Dw_MlNotificationGroup_Variance_Order_By = {
  Priority?: Maybe<Order_By>;
  QualificationStatus?: Maybe<Order_By>;
  SplitId?: Maybe<Order_By>;
};

/** columns and relationships of "dw.MLNotifications" */
export type Dw_MlNotifications = {
  __typename?: 'dw_MLNotifications';
  ActualUsage: Scalars['numeric'];
  AlertType: Scalars['String'];
  /** An object relationship */
  Channel: Dw_Channels;
  ChannelId: Scalars['uuid'];
  ConfirmedRootCause?: Maybe<Scalars['String']>;
  /** An object relationship */
  Contact?: Maybe<Dw_Contacts>;
  ContactId?: Maybe<Scalars['uuid']>;
  ContextualSummary: Scalars['String'];
  CreatedOn: Scalars['timestamp'];
  EquivalentAnnualisedActualUsage: Scalars['numeric'];
  EquivalentAnnualisedC02: Scalars['numeric'];
  EquivalentAnnualisedCost: Scalars['numeric'];
  EquivalentAnnualisedExpectedUsage: Scalars['numeric'];
  EquivalentC02: Scalars['numeric'];
  EquivalentCost: Scalars['numeric'];
  ExpectedUsage: Scalars['numeric'];
  /** An object relationship */
  MLNotificationGroup?: Maybe<Dw_MlNotificationGroup>;
  /** An array relationship */
  MLNotificationsCorrelations: Array<Dw_MlNotificationsCorrelation>;
  /** An aggregate relationship */
  MLNotificationsCorrelations_aggregate: Dw_MlNotificationsCorrelation_Aggregate;
  NotificationEndDate: Scalars['timestamp'];
  NotificationGroupId?: Maybe<Scalars['uuid']>;
  NotificationId: Scalars['uuid'];
  NotificationStartDate: Scalars['timestamp'];
  Priority: Scalars['smallint'];
  QualificationStatus: Scalars['smallint'];
  UpdatedOn: Scalars['timestamp'];
  UsageThreshold: Scalars['numeric'];
};


/** columns and relationships of "dw.MLNotifications" */
export type Dw_MlNotificationsMlNotificationsCorrelationsArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotificationsCorrelation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotificationsCorrelation_Order_By>>;
  where?: Maybe<Dw_MlNotificationsCorrelation_Bool_Exp>;
};


/** columns and relationships of "dw.MLNotifications" */
export type Dw_MlNotificationsMlNotificationsCorrelations_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotificationsCorrelation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotificationsCorrelation_Order_By>>;
  where?: Maybe<Dw_MlNotificationsCorrelation_Bool_Exp>;
};

/** columns and relationships of "dw.MLNotificationsCorrelation" */
export type Dw_MlNotificationsCorrelation = {
  __typename?: 'dw_MLNotificationsCorrelation';
  /** An object relationship */
  Channel: Dw_Channels;
  ChannelId: Scalars['uuid'];
  CorrelationCoefficientCurrent: Scalars['numeric'];
  CorrelationCoefficientDelta: Scalars['numeric'];
  CorrelationCoefficientExpected: Scalars['numeric'];
  CreatedOn: Scalars['timestamp'];
  /** An object relationship */
  MLNotification: Dw_MlNotifications;
  NotificationId: Scalars['uuid'];
  NotificationsCorrelationId: Scalars['uuid'];
  Order: Scalars['smallint'];
  UpdatedOn: Scalars['timestamp'];
};

/** aggregated selection of "dw.MLNotificationsCorrelation" */
export type Dw_MlNotificationsCorrelation_Aggregate = {
  __typename?: 'dw_MLNotificationsCorrelation_aggregate';
  aggregate?: Maybe<Dw_MlNotificationsCorrelation_Aggregate_Fields>;
  nodes: Array<Dw_MlNotificationsCorrelation>;
};

/** aggregate fields of "dw.MLNotificationsCorrelation" */
export type Dw_MlNotificationsCorrelation_Aggregate_Fields = {
  __typename?: 'dw_MLNotificationsCorrelation_aggregate_fields';
  avg?: Maybe<Dw_MlNotificationsCorrelation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_MlNotificationsCorrelation_Max_Fields>;
  min?: Maybe<Dw_MlNotificationsCorrelation_Min_Fields>;
  stddev?: Maybe<Dw_MlNotificationsCorrelation_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_MlNotificationsCorrelation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_MlNotificationsCorrelation_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_MlNotificationsCorrelation_Sum_Fields>;
  var_pop?: Maybe<Dw_MlNotificationsCorrelation_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_MlNotificationsCorrelation_Var_Samp_Fields>;
  variance?: Maybe<Dw_MlNotificationsCorrelation_Variance_Fields>;
};


/** aggregate fields of "dw.MLNotificationsCorrelation" */
export type Dw_MlNotificationsCorrelation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_MlNotificationsCorrelation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.MLNotificationsCorrelation" */
export type Dw_MlNotificationsCorrelation_Aggregate_Order_By = {
  avg?: Maybe<Dw_MlNotificationsCorrelation_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_MlNotificationsCorrelation_Max_Order_By>;
  min?: Maybe<Dw_MlNotificationsCorrelation_Min_Order_By>;
  stddev?: Maybe<Dw_MlNotificationsCorrelation_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_MlNotificationsCorrelation_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_MlNotificationsCorrelation_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_MlNotificationsCorrelation_Sum_Order_By>;
  var_pop?: Maybe<Dw_MlNotificationsCorrelation_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_MlNotificationsCorrelation_Var_Samp_Order_By>;
  variance?: Maybe<Dw_MlNotificationsCorrelation_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.MLNotificationsCorrelation" */
export type Dw_MlNotificationsCorrelation_Arr_Rel_Insert_Input = {
  data: Array<Dw_MlNotificationsCorrelation_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_MlNotificationsCorrelation_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_MlNotificationsCorrelation_Avg_Fields = {
  __typename?: 'dw_MLNotificationsCorrelation_avg_fields';
  CorrelationCoefficientCurrent?: Maybe<Scalars['Float']>;
  CorrelationCoefficientDelta?: Maybe<Scalars['Float']>;
  CorrelationCoefficientExpected?: Maybe<Scalars['Float']>;
  Order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.MLNotificationsCorrelation" */
export type Dw_MlNotificationsCorrelation_Avg_Order_By = {
  CorrelationCoefficientCurrent?: Maybe<Order_By>;
  CorrelationCoefficientDelta?: Maybe<Order_By>;
  CorrelationCoefficientExpected?: Maybe<Order_By>;
  Order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.MLNotificationsCorrelation". All fields are combined with a logical 'AND'. */
export type Dw_MlNotificationsCorrelation_Bool_Exp = {
  Channel?: Maybe<Dw_Channels_Bool_Exp>;
  ChannelId?: Maybe<Uuid_Comparison_Exp>;
  CorrelationCoefficientCurrent?: Maybe<Numeric_Comparison_Exp>;
  CorrelationCoefficientDelta?: Maybe<Numeric_Comparison_Exp>;
  CorrelationCoefficientExpected?: Maybe<Numeric_Comparison_Exp>;
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  MLNotification?: Maybe<Dw_MlNotifications_Bool_Exp>;
  NotificationId?: Maybe<Uuid_Comparison_Exp>;
  NotificationsCorrelationId?: Maybe<Uuid_Comparison_Exp>;
  Order?: Maybe<Smallint_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  _and?: Maybe<Array<Dw_MlNotificationsCorrelation_Bool_Exp>>;
  _not?: Maybe<Dw_MlNotificationsCorrelation_Bool_Exp>;
  _or?: Maybe<Array<Dw_MlNotificationsCorrelation_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.MLNotificationsCorrelation" */
export enum Dw_MlNotificationsCorrelation_Constraint {
  /** unique or primary key constraint */
  MlnotificationscorrelationPk = 'mlnotificationscorrelation_pk'
}

/** input type for incrementing numeric columns in table "dw.MLNotificationsCorrelation" */
export type Dw_MlNotificationsCorrelation_Inc_Input = {
  CorrelationCoefficientCurrent?: Maybe<Scalars['numeric']>;
  CorrelationCoefficientDelta?: Maybe<Scalars['numeric']>;
  CorrelationCoefficientExpected?: Maybe<Scalars['numeric']>;
  Order?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.MLNotificationsCorrelation" */
export type Dw_MlNotificationsCorrelation_Insert_Input = {
  Channel?: Maybe<Dw_Channels_Obj_Rel_Insert_Input>;
  ChannelId?: Maybe<Scalars['uuid']>;
  CorrelationCoefficientCurrent?: Maybe<Scalars['numeric']>;
  CorrelationCoefficientDelta?: Maybe<Scalars['numeric']>;
  CorrelationCoefficientExpected?: Maybe<Scalars['numeric']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  MLNotification?: Maybe<Dw_MlNotifications_Obj_Rel_Insert_Input>;
  NotificationId?: Maybe<Scalars['uuid']>;
  NotificationsCorrelationId?: Maybe<Scalars['uuid']>;
  Order?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Dw_MlNotificationsCorrelation_Max_Fields = {
  __typename?: 'dw_MLNotificationsCorrelation_max_fields';
  ChannelId?: Maybe<Scalars['uuid']>;
  CorrelationCoefficientCurrent?: Maybe<Scalars['numeric']>;
  CorrelationCoefficientDelta?: Maybe<Scalars['numeric']>;
  CorrelationCoefficientExpected?: Maybe<Scalars['numeric']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  NotificationId?: Maybe<Scalars['uuid']>;
  NotificationsCorrelationId?: Maybe<Scalars['uuid']>;
  Order?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "dw.MLNotificationsCorrelation" */
export type Dw_MlNotificationsCorrelation_Max_Order_By = {
  ChannelId?: Maybe<Order_By>;
  CorrelationCoefficientCurrent?: Maybe<Order_By>;
  CorrelationCoefficientDelta?: Maybe<Order_By>;
  CorrelationCoefficientExpected?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  NotificationId?: Maybe<Order_By>;
  NotificationsCorrelationId?: Maybe<Order_By>;
  Order?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_MlNotificationsCorrelation_Min_Fields = {
  __typename?: 'dw_MLNotificationsCorrelation_min_fields';
  ChannelId?: Maybe<Scalars['uuid']>;
  CorrelationCoefficientCurrent?: Maybe<Scalars['numeric']>;
  CorrelationCoefficientDelta?: Maybe<Scalars['numeric']>;
  CorrelationCoefficientExpected?: Maybe<Scalars['numeric']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  NotificationId?: Maybe<Scalars['uuid']>;
  NotificationsCorrelationId?: Maybe<Scalars['uuid']>;
  Order?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "dw.MLNotificationsCorrelation" */
export type Dw_MlNotificationsCorrelation_Min_Order_By = {
  ChannelId?: Maybe<Order_By>;
  CorrelationCoefficientCurrent?: Maybe<Order_By>;
  CorrelationCoefficientDelta?: Maybe<Order_By>;
  CorrelationCoefficientExpected?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  NotificationId?: Maybe<Order_By>;
  NotificationsCorrelationId?: Maybe<Order_By>;
  Order?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.MLNotificationsCorrelation" */
export type Dw_MlNotificationsCorrelation_Mutation_Response = {
  __typename?: 'dw_MLNotificationsCorrelation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_MlNotificationsCorrelation>;
};

/** on conflict condition type for table "dw.MLNotificationsCorrelation" */
export type Dw_MlNotificationsCorrelation_On_Conflict = {
  constraint: Dw_MlNotificationsCorrelation_Constraint;
  update_columns?: Array<Dw_MlNotificationsCorrelation_Update_Column>;
  where?: Maybe<Dw_MlNotificationsCorrelation_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.MLNotificationsCorrelation". */
export type Dw_MlNotificationsCorrelation_Order_By = {
  Channel?: Maybe<Dw_Channels_Order_By>;
  ChannelId?: Maybe<Order_By>;
  CorrelationCoefficientCurrent?: Maybe<Order_By>;
  CorrelationCoefficientDelta?: Maybe<Order_By>;
  CorrelationCoefficientExpected?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  MLNotification?: Maybe<Dw_MlNotifications_Order_By>;
  NotificationId?: Maybe<Order_By>;
  NotificationsCorrelationId?: Maybe<Order_By>;
  Order?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_MLNotificationsCorrelation */
export type Dw_MlNotificationsCorrelation_Pk_Columns_Input = {
  NotificationsCorrelationId: Scalars['uuid'];
};

/** select columns of table "dw.MLNotificationsCorrelation" */
export enum Dw_MlNotificationsCorrelation_Select_Column {
  /** column name */
  ChannelId = 'ChannelId',
  /** column name */
  CorrelationCoefficientCurrent = 'CorrelationCoefficientCurrent',
  /** column name */
  CorrelationCoefficientDelta = 'CorrelationCoefficientDelta',
  /** column name */
  CorrelationCoefficientExpected = 'CorrelationCoefficientExpected',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  NotificationId = 'NotificationId',
  /** column name */
  NotificationsCorrelationId = 'NotificationsCorrelationId',
  /** column name */
  Order = 'Order',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** input type for updating data in table "dw.MLNotificationsCorrelation" */
export type Dw_MlNotificationsCorrelation_Set_Input = {
  ChannelId?: Maybe<Scalars['uuid']>;
  CorrelationCoefficientCurrent?: Maybe<Scalars['numeric']>;
  CorrelationCoefficientDelta?: Maybe<Scalars['numeric']>;
  CorrelationCoefficientExpected?: Maybe<Scalars['numeric']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  NotificationId?: Maybe<Scalars['uuid']>;
  NotificationsCorrelationId?: Maybe<Scalars['uuid']>;
  Order?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Dw_MlNotificationsCorrelation_Stddev_Fields = {
  __typename?: 'dw_MLNotificationsCorrelation_stddev_fields';
  CorrelationCoefficientCurrent?: Maybe<Scalars['Float']>;
  CorrelationCoefficientDelta?: Maybe<Scalars['Float']>;
  CorrelationCoefficientExpected?: Maybe<Scalars['Float']>;
  Order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.MLNotificationsCorrelation" */
export type Dw_MlNotificationsCorrelation_Stddev_Order_By = {
  CorrelationCoefficientCurrent?: Maybe<Order_By>;
  CorrelationCoefficientDelta?: Maybe<Order_By>;
  CorrelationCoefficientExpected?: Maybe<Order_By>;
  Order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_MlNotificationsCorrelation_Stddev_Pop_Fields = {
  __typename?: 'dw_MLNotificationsCorrelation_stddev_pop_fields';
  CorrelationCoefficientCurrent?: Maybe<Scalars['Float']>;
  CorrelationCoefficientDelta?: Maybe<Scalars['Float']>;
  CorrelationCoefficientExpected?: Maybe<Scalars['Float']>;
  Order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.MLNotificationsCorrelation" */
export type Dw_MlNotificationsCorrelation_Stddev_Pop_Order_By = {
  CorrelationCoefficientCurrent?: Maybe<Order_By>;
  CorrelationCoefficientDelta?: Maybe<Order_By>;
  CorrelationCoefficientExpected?: Maybe<Order_By>;
  Order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_MlNotificationsCorrelation_Stddev_Samp_Fields = {
  __typename?: 'dw_MLNotificationsCorrelation_stddev_samp_fields';
  CorrelationCoefficientCurrent?: Maybe<Scalars['Float']>;
  CorrelationCoefficientDelta?: Maybe<Scalars['Float']>;
  CorrelationCoefficientExpected?: Maybe<Scalars['Float']>;
  Order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.MLNotificationsCorrelation" */
export type Dw_MlNotificationsCorrelation_Stddev_Samp_Order_By = {
  CorrelationCoefficientCurrent?: Maybe<Order_By>;
  CorrelationCoefficientDelta?: Maybe<Order_By>;
  CorrelationCoefficientExpected?: Maybe<Order_By>;
  Order?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_MlNotificationsCorrelation_Sum_Fields = {
  __typename?: 'dw_MLNotificationsCorrelation_sum_fields';
  CorrelationCoefficientCurrent?: Maybe<Scalars['numeric']>;
  CorrelationCoefficientDelta?: Maybe<Scalars['numeric']>;
  CorrelationCoefficientExpected?: Maybe<Scalars['numeric']>;
  Order?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "dw.MLNotificationsCorrelation" */
export type Dw_MlNotificationsCorrelation_Sum_Order_By = {
  CorrelationCoefficientCurrent?: Maybe<Order_By>;
  CorrelationCoefficientDelta?: Maybe<Order_By>;
  CorrelationCoefficientExpected?: Maybe<Order_By>;
  Order?: Maybe<Order_By>;
};

/** update columns of table "dw.MLNotificationsCorrelation" */
export enum Dw_MlNotificationsCorrelation_Update_Column {
  /** column name */
  ChannelId = 'ChannelId',
  /** column name */
  CorrelationCoefficientCurrent = 'CorrelationCoefficientCurrent',
  /** column name */
  CorrelationCoefficientDelta = 'CorrelationCoefficientDelta',
  /** column name */
  CorrelationCoefficientExpected = 'CorrelationCoefficientExpected',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  NotificationId = 'NotificationId',
  /** column name */
  NotificationsCorrelationId = 'NotificationsCorrelationId',
  /** column name */
  Order = 'Order',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** aggregate var_pop on columns */
export type Dw_MlNotificationsCorrelation_Var_Pop_Fields = {
  __typename?: 'dw_MLNotificationsCorrelation_var_pop_fields';
  CorrelationCoefficientCurrent?: Maybe<Scalars['Float']>;
  CorrelationCoefficientDelta?: Maybe<Scalars['Float']>;
  CorrelationCoefficientExpected?: Maybe<Scalars['Float']>;
  Order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.MLNotificationsCorrelation" */
export type Dw_MlNotificationsCorrelation_Var_Pop_Order_By = {
  CorrelationCoefficientCurrent?: Maybe<Order_By>;
  CorrelationCoefficientDelta?: Maybe<Order_By>;
  CorrelationCoefficientExpected?: Maybe<Order_By>;
  Order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_MlNotificationsCorrelation_Var_Samp_Fields = {
  __typename?: 'dw_MLNotificationsCorrelation_var_samp_fields';
  CorrelationCoefficientCurrent?: Maybe<Scalars['Float']>;
  CorrelationCoefficientDelta?: Maybe<Scalars['Float']>;
  CorrelationCoefficientExpected?: Maybe<Scalars['Float']>;
  Order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.MLNotificationsCorrelation" */
export type Dw_MlNotificationsCorrelation_Var_Samp_Order_By = {
  CorrelationCoefficientCurrent?: Maybe<Order_By>;
  CorrelationCoefficientDelta?: Maybe<Order_By>;
  CorrelationCoefficientExpected?: Maybe<Order_By>;
  Order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_MlNotificationsCorrelation_Variance_Fields = {
  __typename?: 'dw_MLNotificationsCorrelation_variance_fields';
  CorrelationCoefficientCurrent?: Maybe<Scalars['Float']>;
  CorrelationCoefficientDelta?: Maybe<Scalars['Float']>;
  CorrelationCoefficientExpected?: Maybe<Scalars['Float']>;
  Order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.MLNotificationsCorrelation" */
export type Dw_MlNotificationsCorrelation_Variance_Order_By = {
  CorrelationCoefficientCurrent?: Maybe<Order_By>;
  CorrelationCoefficientDelta?: Maybe<Order_By>;
  CorrelationCoefficientExpected?: Maybe<Order_By>;
  Order?: Maybe<Order_By>;
};

/** columns and relationships of "dw.MLNotificationsMetadata" */
export type Dw_MlNotificationsMetadata = {
  __typename?: 'dw_MLNotificationsMetadata';
  Action: Scalars['smallint'];
  AssigneeContactId?: Maybe<Scalars['uuid']>;
  Comments: Scalars['json'];
  /** An object relationship */
  Contact?: Maybe<Dw_Contacts>;
  CreatedOn: Scalars['timestamp'];
  DueDate: Scalars['timestamp'];
  /** An object relationship */
  MLNotificationGroup: Dw_MlNotificationGroup;
  NotificationGroupId: Scalars['uuid'];
  NotificationId?: Maybe<Scalars['uuid']>;
  Pinned: Scalars['Boolean'];
  ReporterContactId?: Maybe<Scalars['uuid']>;
  UpdatedOn: Scalars['timestamp'];
  /** An object relationship */
  contactByAssigneecontactid?: Maybe<Dw_Contacts>;
};


/** columns and relationships of "dw.MLNotificationsMetadata" */
export type Dw_MlNotificationsMetadataCommentsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "dw.MLNotificationsMetadata" */
export type Dw_MlNotificationsMetadata_Aggregate = {
  __typename?: 'dw_MLNotificationsMetadata_aggregate';
  aggregate?: Maybe<Dw_MlNotificationsMetadata_Aggregate_Fields>;
  nodes: Array<Dw_MlNotificationsMetadata>;
};

/** aggregate fields of "dw.MLNotificationsMetadata" */
export type Dw_MlNotificationsMetadata_Aggregate_Fields = {
  __typename?: 'dw_MLNotificationsMetadata_aggregate_fields';
  avg?: Maybe<Dw_MlNotificationsMetadata_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_MlNotificationsMetadata_Max_Fields>;
  min?: Maybe<Dw_MlNotificationsMetadata_Min_Fields>;
  stddev?: Maybe<Dw_MlNotificationsMetadata_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_MlNotificationsMetadata_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_MlNotificationsMetadata_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_MlNotificationsMetadata_Sum_Fields>;
  var_pop?: Maybe<Dw_MlNotificationsMetadata_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_MlNotificationsMetadata_Var_Samp_Fields>;
  variance?: Maybe<Dw_MlNotificationsMetadata_Variance_Fields>;
};


/** aggregate fields of "dw.MLNotificationsMetadata" */
export type Dw_MlNotificationsMetadata_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_MlNotificationsMetadata_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.MLNotificationsMetadata" */
export type Dw_MlNotificationsMetadata_Aggregate_Order_By = {
  avg?: Maybe<Dw_MlNotificationsMetadata_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_MlNotificationsMetadata_Max_Order_By>;
  min?: Maybe<Dw_MlNotificationsMetadata_Min_Order_By>;
  stddev?: Maybe<Dw_MlNotificationsMetadata_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_MlNotificationsMetadata_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_MlNotificationsMetadata_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_MlNotificationsMetadata_Sum_Order_By>;
  var_pop?: Maybe<Dw_MlNotificationsMetadata_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_MlNotificationsMetadata_Var_Samp_Order_By>;
  variance?: Maybe<Dw_MlNotificationsMetadata_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.MLNotificationsMetadata" */
export type Dw_MlNotificationsMetadata_Arr_Rel_Insert_Input = {
  data: Array<Dw_MlNotificationsMetadata_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_MlNotificationsMetadata_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_MlNotificationsMetadata_Avg_Fields = {
  __typename?: 'dw_MLNotificationsMetadata_avg_fields';
  Action?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.MLNotificationsMetadata" */
export type Dw_MlNotificationsMetadata_Avg_Order_By = {
  Action?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.MLNotificationsMetadata". All fields are combined with a logical 'AND'. */
export type Dw_MlNotificationsMetadata_Bool_Exp = {
  Action?: Maybe<Smallint_Comparison_Exp>;
  AssigneeContactId?: Maybe<Uuid_Comparison_Exp>;
  Comments?: Maybe<Json_Comparison_Exp>;
  Contact?: Maybe<Dw_Contacts_Bool_Exp>;
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  DueDate?: Maybe<Timestamp_Comparison_Exp>;
  MLNotificationGroup?: Maybe<Dw_MlNotificationGroup_Bool_Exp>;
  NotificationGroupId?: Maybe<Uuid_Comparison_Exp>;
  NotificationId?: Maybe<Uuid_Comparison_Exp>;
  Pinned?: Maybe<Boolean_Comparison_Exp>;
  ReporterContactId?: Maybe<Uuid_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  _and?: Maybe<Array<Dw_MlNotificationsMetadata_Bool_Exp>>;
  _not?: Maybe<Dw_MlNotificationsMetadata_Bool_Exp>;
  _or?: Maybe<Array<Dw_MlNotificationsMetadata_Bool_Exp>>;
  contactByAssigneecontactid?: Maybe<Dw_Contacts_Bool_Exp>;
};

/** unique or primary key constraints on table "dw.MLNotificationsMetadata" */
export enum Dw_MlNotificationsMetadata_Constraint {
  /** unique or primary key constraint */
  MlnotificationsmetadataNotificationidUindex = 'mlnotificationsmetadata_notificationid_uindex',
  /** unique or primary key constraint */
  MlnotificationsmetadataPk = 'mlnotificationsmetadata_pk'
}

/** input type for incrementing numeric columns in table "dw.MLNotificationsMetadata" */
export type Dw_MlNotificationsMetadata_Inc_Input = {
  Action?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.MLNotificationsMetadata" */
export type Dw_MlNotificationsMetadata_Insert_Input = {
  Action?: Maybe<Scalars['smallint']>;
  AssigneeContactId?: Maybe<Scalars['uuid']>;
  Comments?: Maybe<Scalars['json']>;
  Contact?: Maybe<Dw_Contacts_Obj_Rel_Insert_Input>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DueDate?: Maybe<Scalars['timestamp']>;
  MLNotificationGroup?: Maybe<Dw_MlNotificationGroup_Obj_Rel_Insert_Input>;
  NotificationGroupId?: Maybe<Scalars['uuid']>;
  NotificationId?: Maybe<Scalars['uuid']>;
  Pinned?: Maybe<Scalars['Boolean']>;
  ReporterContactId?: Maybe<Scalars['uuid']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  contactByAssigneecontactid?: Maybe<Dw_Contacts_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Dw_MlNotificationsMetadata_Max_Fields = {
  __typename?: 'dw_MLNotificationsMetadata_max_fields';
  Action?: Maybe<Scalars['smallint']>;
  AssigneeContactId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DueDate?: Maybe<Scalars['timestamp']>;
  NotificationGroupId?: Maybe<Scalars['uuid']>;
  NotificationId?: Maybe<Scalars['uuid']>;
  ReporterContactId?: Maybe<Scalars['uuid']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "dw.MLNotificationsMetadata" */
export type Dw_MlNotificationsMetadata_Max_Order_By = {
  Action?: Maybe<Order_By>;
  AssigneeContactId?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  DueDate?: Maybe<Order_By>;
  NotificationGroupId?: Maybe<Order_By>;
  NotificationId?: Maybe<Order_By>;
  ReporterContactId?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_MlNotificationsMetadata_Min_Fields = {
  __typename?: 'dw_MLNotificationsMetadata_min_fields';
  Action?: Maybe<Scalars['smallint']>;
  AssigneeContactId?: Maybe<Scalars['uuid']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DueDate?: Maybe<Scalars['timestamp']>;
  NotificationGroupId?: Maybe<Scalars['uuid']>;
  NotificationId?: Maybe<Scalars['uuid']>;
  ReporterContactId?: Maybe<Scalars['uuid']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "dw.MLNotificationsMetadata" */
export type Dw_MlNotificationsMetadata_Min_Order_By = {
  Action?: Maybe<Order_By>;
  AssigneeContactId?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  DueDate?: Maybe<Order_By>;
  NotificationGroupId?: Maybe<Order_By>;
  NotificationId?: Maybe<Order_By>;
  ReporterContactId?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.MLNotificationsMetadata" */
export type Dw_MlNotificationsMetadata_Mutation_Response = {
  __typename?: 'dw_MLNotificationsMetadata_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_MlNotificationsMetadata>;
};

/** input type for inserting object relation for remote table "dw.MLNotificationsMetadata" */
export type Dw_MlNotificationsMetadata_Obj_Rel_Insert_Input = {
  data: Dw_MlNotificationsMetadata_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_MlNotificationsMetadata_On_Conflict>;
};

/** on conflict condition type for table "dw.MLNotificationsMetadata" */
export type Dw_MlNotificationsMetadata_On_Conflict = {
  constraint: Dw_MlNotificationsMetadata_Constraint;
  update_columns?: Array<Dw_MlNotificationsMetadata_Update_Column>;
  where?: Maybe<Dw_MlNotificationsMetadata_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.MLNotificationsMetadata". */
export type Dw_MlNotificationsMetadata_Order_By = {
  Action?: Maybe<Order_By>;
  AssigneeContactId?: Maybe<Order_By>;
  Comments?: Maybe<Order_By>;
  Contact?: Maybe<Dw_Contacts_Order_By>;
  CreatedOn?: Maybe<Order_By>;
  DueDate?: Maybe<Order_By>;
  MLNotificationGroup?: Maybe<Dw_MlNotificationGroup_Order_By>;
  NotificationGroupId?: Maybe<Order_By>;
  NotificationId?: Maybe<Order_By>;
  Pinned?: Maybe<Order_By>;
  ReporterContactId?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
  contactByAssigneecontactid?: Maybe<Dw_Contacts_Order_By>;
};

/** primary key columns input for table: dw_MLNotificationsMetadata */
export type Dw_MlNotificationsMetadata_Pk_Columns_Input = {
  NotificationGroupId: Scalars['uuid'];
};

/** select columns of table "dw.MLNotificationsMetadata" */
export enum Dw_MlNotificationsMetadata_Select_Column {
  /** column name */
  Action = 'Action',
  /** column name */
  AssigneeContactId = 'AssigneeContactId',
  /** column name */
  Comments = 'Comments',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  DueDate = 'DueDate',
  /** column name */
  NotificationGroupId = 'NotificationGroupId',
  /** column name */
  NotificationId = 'NotificationId',
  /** column name */
  Pinned = 'Pinned',
  /** column name */
  ReporterContactId = 'ReporterContactId',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** input type for updating data in table "dw.MLNotificationsMetadata" */
export type Dw_MlNotificationsMetadata_Set_Input = {
  Action?: Maybe<Scalars['smallint']>;
  AssigneeContactId?: Maybe<Scalars['uuid']>;
  Comments?: Maybe<Scalars['json']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DueDate?: Maybe<Scalars['timestamp']>;
  NotificationGroupId?: Maybe<Scalars['uuid']>;
  NotificationId?: Maybe<Scalars['uuid']>;
  Pinned?: Maybe<Scalars['Boolean']>;
  ReporterContactId?: Maybe<Scalars['uuid']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Dw_MlNotificationsMetadata_Stddev_Fields = {
  __typename?: 'dw_MLNotificationsMetadata_stddev_fields';
  Action?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.MLNotificationsMetadata" */
export type Dw_MlNotificationsMetadata_Stddev_Order_By = {
  Action?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_MlNotificationsMetadata_Stddev_Pop_Fields = {
  __typename?: 'dw_MLNotificationsMetadata_stddev_pop_fields';
  Action?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.MLNotificationsMetadata" */
export type Dw_MlNotificationsMetadata_Stddev_Pop_Order_By = {
  Action?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_MlNotificationsMetadata_Stddev_Samp_Fields = {
  __typename?: 'dw_MLNotificationsMetadata_stddev_samp_fields';
  Action?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.MLNotificationsMetadata" */
export type Dw_MlNotificationsMetadata_Stddev_Samp_Order_By = {
  Action?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_MlNotificationsMetadata_Sum_Fields = {
  __typename?: 'dw_MLNotificationsMetadata_sum_fields';
  Action?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "dw.MLNotificationsMetadata" */
export type Dw_MlNotificationsMetadata_Sum_Order_By = {
  Action?: Maybe<Order_By>;
};

/** update columns of table "dw.MLNotificationsMetadata" */
export enum Dw_MlNotificationsMetadata_Update_Column {
  /** column name */
  Action = 'Action',
  /** column name */
  AssigneeContactId = 'AssigneeContactId',
  /** column name */
  Comments = 'Comments',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  DueDate = 'DueDate',
  /** column name */
  NotificationGroupId = 'NotificationGroupId',
  /** column name */
  NotificationId = 'NotificationId',
  /** column name */
  Pinned = 'Pinned',
  /** column name */
  ReporterContactId = 'ReporterContactId',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** aggregate var_pop on columns */
export type Dw_MlNotificationsMetadata_Var_Pop_Fields = {
  __typename?: 'dw_MLNotificationsMetadata_var_pop_fields';
  Action?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.MLNotificationsMetadata" */
export type Dw_MlNotificationsMetadata_Var_Pop_Order_By = {
  Action?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_MlNotificationsMetadata_Var_Samp_Fields = {
  __typename?: 'dw_MLNotificationsMetadata_var_samp_fields';
  Action?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.MLNotificationsMetadata" */
export type Dw_MlNotificationsMetadata_Var_Samp_Order_By = {
  Action?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_MlNotificationsMetadata_Variance_Fields = {
  __typename?: 'dw_MLNotificationsMetadata_variance_fields';
  Action?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.MLNotificationsMetadata" */
export type Dw_MlNotificationsMetadata_Variance_Order_By = {
  Action?: Maybe<Order_By>;
};

/** aggregated selection of "dw.MLNotifications" */
export type Dw_MlNotifications_Aggregate = {
  __typename?: 'dw_MLNotifications_aggregate';
  aggregate?: Maybe<Dw_MlNotifications_Aggregate_Fields>;
  nodes: Array<Dw_MlNotifications>;
};

/** aggregate fields of "dw.MLNotifications" */
export type Dw_MlNotifications_Aggregate_Fields = {
  __typename?: 'dw_MLNotifications_aggregate_fields';
  avg?: Maybe<Dw_MlNotifications_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_MlNotifications_Max_Fields>;
  min?: Maybe<Dw_MlNotifications_Min_Fields>;
  stddev?: Maybe<Dw_MlNotifications_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_MlNotifications_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_MlNotifications_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_MlNotifications_Sum_Fields>;
  var_pop?: Maybe<Dw_MlNotifications_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_MlNotifications_Var_Samp_Fields>;
  variance?: Maybe<Dw_MlNotifications_Variance_Fields>;
};


/** aggregate fields of "dw.MLNotifications" */
export type Dw_MlNotifications_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_MlNotifications_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.MLNotifications" */
export type Dw_MlNotifications_Aggregate_Order_By = {
  avg?: Maybe<Dw_MlNotifications_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_MlNotifications_Max_Order_By>;
  min?: Maybe<Dw_MlNotifications_Min_Order_By>;
  stddev?: Maybe<Dw_MlNotifications_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_MlNotifications_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_MlNotifications_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_MlNotifications_Sum_Order_By>;
  var_pop?: Maybe<Dw_MlNotifications_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_MlNotifications_Var_Samp_Order_By>;
  variance?: Maybe<Dw_MlNotifications_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.MLNotifications" */
export type Dw_MlNotifications_Arr_Rel_Insert_Input = {
  data: Array<Dw_MlNotifications_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_MlNotifications_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_MlNotifications_Avg_Fields = {
  __typename?: 'dw_MLNotifications_avg_fields';
  ActualUsage?: Maybe<Scalars['Float']>;
  EquivalentAnnualisedActualUsage?: Maybe<Scalars['Float']>;
  EquivalentAnnualisedC02?: Maybe<Scalars['Float']>;
  EquivalentAnnualisedCost?: Maybe<Scalars['Float']>;
  EquivalentAnnualisedExpectedUsage?: Maybe<Scalars['Float']>;
  EquivalentC02?: Maybe<Scalars['Float']>;
  EquivalentCost?: Maybe<Scalars['Float']>;
  ExpectedUsage?: Maybe<Scalars['Float']>;
  Priority?: Maybe<Scalars['Float']>;
  QualificationStatus?: Maybe<Scalars['Float']>;
  UsageThreshold?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.MLNotifications" */
export type Dw_MlNotifications_Avg_Order_By = {
  ActualUsage?: Maybe<Order_By>;
  EquivalentAnnualisedActualUsage?: Maybe<Order_By>;
  EquivalentAnnualisedC02?: Maybe<Order_By>;
  EquivalentAnnualisedCost?: Maybe<Order_By>;
  EquivalentAnnualisedExpectedUsage?: Maybe<Order_By>;
  EquivalentC02?: Maybe<Order_By>;
  EquivalentCost?: Maybe<Order_By>;
  ExpectedUsage?: Maybe<Order_By>;
  Priority?: Maybe<Order_By>;
  QualificationStatus?: Maybe<Order_By>;
  UsageThreshold?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.MLNotifications". All fields are combined with a logical 'AND'. */
export type Dw_MlNotifications_Bool_Exp = {
  ActualUsage?: Maybe<Numeric_Comparison_Exp>;
  AlertType?: Maybe<String_Comparison_Exp>;
  Channel?: Maybe<Dw_Channels_Bool_Exp>;
  ChannelId?: Maybe<Uuid_Comparison_Exp>;
  ConfirmedRootCause?: Maybe<String_Comparison_Exp>;
  Contact?: Maybe<Dw_Contacts_Bool_Exp>;
  ContactId?: Maybe<Uuid_Comparison_Exp>;
  ContextualSummary?: Maybe<String_Comparison_Exp>;
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  EquivalentAnnualisedActualUsage?: Maybe<Numeric_Comparison_Exp>;
  EquivalentAnnualisedC02?: Maybe<Numeric_Comparison_Exp>;
  EquivalentAnnualisedCost?: Maybe<Numeric_Comparison_Exp>;
  EquivalentAnnualisedExpectedUsage?: Maybe<Numeric_Comparison_Exp>;
  EquivalentC02?: Maybe<Numeric_Comparison_Exp>;
  EquivalentCost?: Maybe<Numeric_Comparison_Exp>;
  ExpectedUsage?: Maybe<Numeric_Comparison_Exp>;
  MLNotificationGroup?: Maybe<Dw_MlNotificationGroup_Bool_Exp>;
  MLNotificationsCorrelations?: Maybe<Dw_MlNotificationsCorrelation_Bool_Exp>;
  NotificationEndDate?: Maybe<Timestamp_Comparison_Exp>;
  NotificationGroupId?: Maybe<Uuid_Comparison_Exp>;
  NotificationId?: Maybe<Uuid_Comparison_Exp>;
  NotificationStartDate?: Maybe<Timestamp_Comparison_Exp>;
  Priority?: Maybe<Smallint_Comparison_Exp>;
  QualificationStatus?: Maybe<Smallint_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  UsageThreshold?: Maybe<Numeric_Comparison_Exp>;
  _and?: Maybe<Array<Dw_MlNotifications_Bool_Exp>>;
  _not?: Maybe<Dw_MlNotifications_Bool_Exp>;
  _or?: Maybe<Array<Dw_MlNotifications_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.MLNotifications" */
export enum Dw_MlNotifications_Constraint {
  /** unique or primary key constraint */
  MlnotificationsPk = 'mlnotifications_pk',
  /** unique or primary key constraint */
  MlnotificationsUnique = 'mlnotifications_unique'
}

/** input type for incrementing numeric columns in table "dw.MLNotifications" */
export type Dw_MlNotifications_Inc_Input = {
  ActualUsage?: Maybe<Scalars['numeric']>;
  EquivalentAnnualisedActualUsage?: Maybe<Scalars['numeric']>;
  EquivalentAnnualisedC02?: Maybe<Scalars['numeric']>;
  EquivalentAnnualisedCost?: Maybe<Scalars['numeric']>;
  EquivalentAnnualisedExpectedUsage?: Maybe<Scalars['numeric']>;
  EquivalentC02?: Maybe<Scalars['numeric']>;
  EquivalentCost?: Maybe<Scalars['numeric']>;
  ExpectedUsage?: Maybe<Scalars['numeric']>;
  Priority?: Maybe<Scalars['smallint']>;
  QualificationStatus?: Maybe<Scalars['smallint']>;
  UsageThreshold?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "dw.MLNotifications" */
export type Dw_MlNotifications_Insert_Input = {
  ActualUsage?: Maybe<Scalars['numeric']>;
  AlertType?: Maybe<Scalars['String']>;
  Channel?: Maybe<Dw_Channels_Obj_Rel_Insert_Input>;
  ChannelId?: Maybe<Scalars['uuid']>;
  ConfirmedRootCause?: Maybe<Scalars['String']>;
  Contact?: Maybe<Dw_Contacts_Obj_Rel_Insert_Input>;
  ContactId?: Maybe<Scalars['uuid']>;
  ContextualSummary?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  EquivalentAnnualisedActualUsage?: Maybe<Scalars['numeric']>;
  EquivalentAnnualisedC02?: Maybe<Scalars['numeric']>;
  EquivalentAnnualisedCost?: Maybe<Scalars['numeric']>;
  EquivalentAnnualisedExpectedUsage?: Maybe<Scalars['numeric']>;
  EquivalentC02?: Maybe<Scalars['numeric']>;
  EquivalentCost?: Maybe<Scalars['numeric']>;
  ExpectedUsage?: Maybe<Scalars['numeric']>;
  MLNotificationGroup?: Maybe<Dw_MlNotificationGroup_Obj_Rel_Insert_Input>;
  MLNotificationsCorrelations?: Maybe<Dw_MlNotificationsCorrelation_Arr_Rel_Insert_Input>;
  NotificationEndDate?: Maybe<Scalars['timestamp']>;
  NotificationGroupId?: Maybe<Scalars['uuid']>;
  NotificationId?: Maybe<Scalars['uuid']>;
  NotificationStartDate?: Maybe<Scalars['timestamp']>;
  Priority?: Maybe<Scalars['smallint']>;
  QualificationStatus?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  UsageThreshold?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Dw_MlNotifications_Max_Fields = {
  __typename?: 'dw_MLNotifications_max_fields';
  ActualUsage?: Maybe<Scalars['numeric']>;
  AlertType?: Maybe<Scalars['String']>;
  ChannelId?: Maybe<Scalars['uuid']>;
  ConfirmedRootCause?: Maybe<Scalars['String']>;
  ContactId?: Maybe<Scalars['uuid']>;
  ContextualSummary?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  EquivalentAnnualisedActualUsage?: Maybe<Scalars['numeric']>;
  EquivalentAnnualisedC02?: Maybe<Scalars['numeric']>;
  EquivalentAnnualisedCost?: Maybe<Scalars['numeric']>;
  EquivalentAnnualisedExpectedUsage?: Maybe<Scalars['numeric']>;
  EquivalentC02?: Maybe<Scalars['numeric']>;
  EquivalentCost?: Maybe<Scalars['numeric']>;
  ExpectedUsage?: Maybe<Scalars['numeric']>;
  NotificationEndDate?: Maybe<Scalars['timestamp']>;
  NotificationGroupId?: Maybe<Scalars['uuid']>;
  NotificationId?: Maybe<Scalars['uuid']>;
  NotificationStartDate?: Maybe<Scalars['timestamp']>;
  Priority?: Maybe<Scalars['smallint']>;
  QualificationStatus?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  UsageThreshold?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "dw.MLNotifications" */
export type Dw_MlNotifications_Max_Order_By = {
  ActualUsage?: Maybe<Order_By>;
  AlertType?: Maybe<Order_By>;
  ChannelId?: Maybe<Order_By>;
  ConfirmedRootCause?: Maybe<Order_By>;
  ContactId?: Maybe<Order_By>;
  ContextualSummary?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  EquivalentAnnualisedActualUsage?: Maybe<Order_By>;
  EquivalentAnnualisedC02?: Maybe<Order_By>;
  EquivalentAnnualisedCost?: Maybe<Order_By>;
  EquivalentAnnualisedExpectedUsage?: Maybe<Order_By>;
  EquivalentC02?: Maybe<Order_By>;
  EquivalentCost?: Maybe<Order_By>;
  ExpectedUsage?: Maybe<Order_By>;
  NotificationEndDate?: Maybe<Order_By>;
  NotificationGroupId?: Maybe<Order_By>;
  NotificationId?: Maybe<Order_By>;
  NotificationStartDate?: Maybe<Order_By>;
  Priority?: Maybe<Order_By>;
  QualificationStatus?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
  UsageThreshold?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_MlNotifications_Min_Fields = {
  __typename?: 'dw_MLNotifications_min_fields';
  ActualUsage?: Maybe<Scalars['numeric']>;
  AlertType?: Maybe<Scalars['String']>;
  ChannelId?: Maybe<Scalars['uuid']>;
  ConfirmedRootCause?: Maybe<Scalars['String']>;
  ContactId?: Maybe<Scalars['uuid']>;
  ContextualSummary?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  EquivalentAnnualisedActualUsage?: Maybe<Scalars['numeric']>;
  EquivalentAnnualisedC02?: Maybe<Scalars['numeric']>;
  EquivalentAnnualisedCost?: Maybe<Scalars['numeric']>;
  EquivalentAnnualisedExpectedUsage?: Maybe<Scalars['numeric']>;
  EquivalentC02?: Maybe<Scalars['numeric']>;
  EquivalentCost?: Maybe<Scalars['numeric']>;
  ExpectedUsage?: Maybe<Scalars['numeric']>;
  NotificationEndDate?: Maybe<Scalars['timestamp']>;
  NotificationGroupId?: Maybe<Scalars['uuid']>;
  NotificationId?: Maybe<Scalars['uuid']>;
  NotificationStartDate?: Maybe<Scalars['timestamp']>;
  Priority?: Maybe<Scalars['smallint']>;
  QualificationStatus?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  UsageThreshold?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "dw.MLNotifications" */
export type Dw_MlNotifications_Min_Order_By = {
  ActualUsage?: Maybe<Order_By>;
  AlertType?: Maybe<Order_By>;
  ChannelId?: Maybe<Order_By>;
  ConfirmedRootCause?: Maybe<Order_By>;
  ContactId?: Maybe<Order_By>;
  ContextualSummary?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  EquivalentAnnualisedActualUsage?: Maybe<Order_By>;
  EquivalentAnnualisedC02?: Maybe<Order_By>;
  EquivalentAnnualisedCost?: Maybe<Order_By>;
  EquivalentAnnualisedExpectedUsage?: Maybe<Order_By>;
  EquivalentC02?: Maybe<Order_By>;
  EquivalentCost?: Maybe<Order_By>;
  ExpectedUsage?: Maybe<Order_By>;
  NotificationEndDate?: Maybe<Order_By>;
  NotificationGroupId?: Maybe<Order_By>;
  NotificationId?: Maybe<Order_By>;
  NotificationStartDate?: Maybe<Order_By>;
  Priority?: Maybe<Order_By>;
  QualificationStatus?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
  UsageThreshold?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.MLNotifications" */
export type Dw_MlNotifications_Mutation_Response = {
  __typename?: 'dw_MLNotifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_MlNotifications>;
};

/** input type for inserting object relation for remote table "dw.MLNotifications" */
export type Dw_MlNotifications_Obj_Rel_Insert_Input = {
  data: Dw_MlNotifications_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_MlNotifications_On_Conflict>;
};

/** on conflict condition type for table "dw.MLNotifications" */
export type Dw_MlNotifications_On_Conflict = {
  constraint: Dw_MlNotifications_Constraint;
  update_columns?: Array<Dw_MlNotifications_Update_Column>;
  where?: Maybe<Dw_MlNotifications_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.MLNotifications". */
export type Dw_MlNotifications_Order_By = {
  ActualUsage?: Maybe<Order_By>;
  AlertType?: Maybe<Order_By>;
  Channel?: Maybe<Dw_Channels_Order_By>;
  ChannelId?: Maybe<Order_By>;
  ConfirmedRootCause?: Maybe<Order_By>;
  Contact?: Maybe<Dw_Contacts_Order_By>;
  ContactId?: Maybe<Order_By>;
  ContextualSummary?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  EquivalentAnnualisedActualUsage?: Maybe<Order_By>;
  EquivalentAnnualisedC02?: Maybe<Order_By>;
  EquivalentAnnualisedCost?: Maybe<Order_By>;
  EquivalentAnnualisedExpectedUsage?: Maybe<Order_By>;
  EquivalentC02?: Maybe<Order_By>;
  EquivalentCost?: Maybe<Order_By>;
  ExpectedUsage?: Maybe<Order_By>;
  MLNotificationGroup?: Maybe<Dw_MlNotificationGroup_Order_By>;
  MLNotificationsCorrelations_aggregate?: Maybe<Dw_MlNotificationsCorrelation_Aggregate_Order_By>;
  NotificationEndDate?: Maybe<Order_By>;
  NotificationGroupId?: Maybe<Order_By>;
  NotificationId?: Maybe<Order_By>;
  NotificationStartDate?: Maybe<Order_By>;
  Priority?: Maybe<Order_By>;
  QualificationStatus?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
  UsageThreshold?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_MLNotifications */
export type Dw_MlNotifications_Pk_Columns_Input = {
  NotificationId: Scalars['uuid'];
};

/** select columns of table "dw.MLNotifications" */
export enum Dw_MlNotifications_Select_Column {
  /** column name */
  ActualUsage = 'ActualUsage',
  /** column name */
  AlertType = 'AlertType',
  /** column name */
  ChannelId = 'ChannelId',
  /** column name */
  ConfirmedRootCause = 'ConfirmedRootCause',
  /** column name */
  ContactId = 'ContactId',
  /** column name */
  ContextualSummary = 'ContextualSummary',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  EquivalentAnnualisedActualUsage = 'EquivalentAnnualisedActualUsage',
  /** column name */
  EquivalentAnnualisedC02 = 'EquivalentAnnualisedC02',
  /** column name */
  EquivalentAnnualisedCost = 'EquivalentAnnualisedCost',
  /** column name */
  EquivalentAnnualisedExpectedUsage = 'EquivalentAnnualisedExpectedUsage',
  /** column name */
  EquivalentC02 = 'EquivalentC02',
  /** column name */
  EquivalentCost = 'EquivalentCost',
  /** column name */
  ExpectedUsage = 'ExpectedUsage',
  /** column name */
  NotificationEndDate = 'NotificationEndDate',
  /** column name */
  NotificationGroupId = 'NotificationGroupId',
  /** column name */
  NotificationId = 'NotificationId',
  /** column name */
  NotificationStartDate = 'NotificationStartDate',
  /** column name */
  Priority = 'Priority',
  /** column name */
  QualificationStatus = 'QualificationStatus',
  /** column name */
  UpdatedOn = 'UpdatedOn',
  /** column name */
  UsageThreshold = 'UsageThreshold'
}

/** input type for updating data in table "dw.MLNotifications" */
export type Dw_MlNotifications_Set_Input = {
  ActualUsage?: Maybe<Scalars['numeric']>;
  AlertType?: Maybe<Scalars['String']>;
  ChannelId?: Maybe<Scalars['uuid']>;
  ConfirmedRootCause?: Maybe<Scalars['String']>;
  ContactId?: Maybe<Scalars['uuid']>;
  ContextualSummary?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  EquivalentAnnualisedActualUsage?: Maybe<Scalars['numeric']>;
  EquivalentAnnualisedC02?: Maybe<Scalars['numeric']>;
  EquivalentAnnualisedCost?: Maybe<Scalars['numeric']>;
  EquivalentAnnualisedExpectedUsage?: Maybe<Scalars['numeric']>;
  EquivalentC02?: Maybe<Scalars['numeric']>;
  EquivalentCost?: Maybe<Scalars['numeric']>;
  ExpectedUsage?: Maybe<Scalars['numeric']>;
  NotificationEndDate?: Maybe<Scalars['timestamp']>;
  NotificationGroupId?: Maybe<Scalars['uuid']>;
  NotificationId?: Maybe<Scalars['uuid']>;
  NotificationStartDate?: Maybe<Scalars['timestamp']>;
  Priority?: Maybe<Scalars['smallint']>;
  QualificationStatus?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  UsageThreshold?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Dw_MlNotifications_Stddev_Fields = {
  __typename?: 'dw_MLNotifications_stddev_fields';
  ActualUsage?: Maybe<Scalars['Float']>;
  EquivalentAnnualisedActualUsage?: Maybe<Scalars['Float']>;
  EquivalentAnnualisedC02?: Maybe<Scalars['Float']>;
  EquivalentAnnualisedCost?: Maybe<Scalars['Float']>;
  EquivalentAnnualisedExpectedUsage?: Maybe<Scalars['Float']>;
  EquivalentC02?: Maybe<Scalars['Float']>;
  EquivalentCost?: Maybe<Scalars['Float']>;
  ExpectedUsage?: Maybe<Scalars['Float']>;
  Priority?: Maybe<Scalars['Float']>;
  QualificationStatus?: Maybe<Scalars['Float']>;
  UsageThreshold?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.MLNotifications" */
export type Dw_MlNotifications_Stddev_Order_By = {
  ActualUsage?: Maybe<Order_By>;
  EquivalentAnnualisedActualUsage?: Maybe<Order_By>;
  EquivalentAnnualisedC02?: Maybe<Order_By>;
  EquivalentAnnualisedCost?: Maybe<Order_By>;
  EquivalentAnnualisedExpectedUsage?: Maybe<Order_By>;
  EquivalentC02?: Maybe<Order_By>;
  EquivalentCost?: Maybe<Order_By>;
  ExpectedUsage?: Maybe<Order_By>;
  Priority?: Maybe<Order_By>;
  QualificationStatus?: Maybe<Order_By>;
  UsageThreshold?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_MlNotifications_Stddev_Pop_Fields = {
  __typename?: 'dw_MLNotifications_stddev_pop_fields';
  ActualUsage?: Maybe<Scalars['Float']>;
  EquivalentAnnualisedActualUsage?: Maybe<Scalars['Float']>;
  EquivalentAnnualisedC02?: Maybe<Scalars['Float']>;
  EquivalentAnnualisedCost?: Maybe<Scalars['Float']>;
  EquivalentAnnualisedExpectedUsage?: Maybe<Scalars['Float']>;
  EquivalentC02?: Maybe<Scalars['Float']>;
  EquivalentCost?: Maybe<Scalars['Float']>;
  ExpectedUsage?: Maybe<Scalars['Float']>;
  Priority?: Maybe<Scalars['Float']>;
  QualificationStatus?: Maybe<Scalars['Float']>;
  UsageThreshold?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.MLNotifications" */
export type Dw_MlNotifications_Stddev_Pop_Order_By = {
  ActualUsage?: Maybe<Order_By>;
  EquivalentAnnualisedActualUsage?: Maybe<Order_By>;
  EquivalentAnnualisedC02?: Maybe<Order_By>;
  EquivalentAnnualisedCost?: Maybe<Order_By>;
  EquivalentAnnualisedExpectedUsage?: Maybe<Order_By>;
  EquivalentC02?: Maybe<Order_By>;
  EquivalentCost?: Maybe<Order_By>;
  ExpectedUsage?: Maybe<Order_By>;
  Priority?: Maybe<Order_By>;
  QualificationStatus?: Maybe<Order_By>;
  UsageThreshold?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_MlNotifications_Stddev_Samp_Fields = {
  __typename?: 'dw_MLNotifications_stddev_samp_fields';
  ActualUsage?: Maybe<Scalars['Float']>;
  EquivalentAnnualisedActualUsage?: Maybe<Scalars['Float']>;
  EquivalentAnnualisedC02?: Maybe<Scalars['Float']>;
  EquivalentAnnualisedCost?: Maybe<Scalars['Float']>;
  EquivalentAnnualisedExpectedUsage?: Maybe<Scalars['Float']>;
  EquivalentC02?: Maybe<Scalars['Float']>;
  EquivalentCost?: Maybe<Scalars['Float']>;
  ExpectedUsage?: Maybe<Scalars['Float']>;
  Priority?: Maybe<Scalars['Float']>;
  QualificationStatus?: Maybe<Scalars['Float']>;
  UsageThreshold?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.MLNotifications" */
export type Dw_MlNotifications_Stddev_Samp_Order_By = {
  ActualUsage?: Maybe<Order_By>;
  EquivalentAnnualisedActualUsage?: Maybe<Order_By>;
  EquivalentAnnualisedC02?: Maybe<Order_By>;
  EquivalentAnnualisedCost?: Maybe<Order_By>;
  EquivalentAnnualisedExpectedUsage?: Maybe<Order_By>;
  EquivalentC02?: Maybe<Order_By>;
  EquivalentCost?: Maybe<Order_By>;
  ExpectedUsage?: Maybe<Order_By>;
  Priority?: Maybe<Order_By>;
  QualificationStatus?: Maybe<Order_By>;
  UsageThreshold?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_MlNotifications_Sum_Fields = {
  __typename?: 'dw_MLNotifications_sum_fields';
  ActualUsage?: Maybe<Scalars['numeric']>;
  EquivalentAnnualisedActualUsage?: Maybe<Scalars['numeric']>;
  EquivalentAnnualisedC02?: Maybe<Scalars['numeric']>;
  EquivalentAnnualisedCost?: Maybe<Scalars['numeric']>;
  EquivalentAnnualisedExpectedUsage?: Maybe<Scalars['numeric']>;
  EquivalentC02?: Maybe<Scalars['numeric']>;
  EquivalentCost?: Maybe<Scalars['numeric']>;
  ExpectedUsage?: Maybe<Scalars['numeric']>;
  Priority?: Maybe<Scalars['smallint']>;
  QualificationStatus?: Maybe<Scalars['smallint']>;
  UsageThreshold?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "dw.MLNotifications" */
export type Dw_MlNotifications_Sum_Order_By = {
  ActualUsage?: Maybe<Order_By>;
  EquivalentAnnualisedActualUsage?: Maybe<Order_By>;
  EquivalentAnnualisedC02?: Maybe<Order_By>;
  EquivalentAnnualisedCost?: Maybe<Order_By>;
  EquivalentAnnualisedExpectedUsage?: Maybe<Order_By>;
  EquivalentC02?: Maybe<Order_By>;
  EquivalentCost?: Maybe<Order_By>;
  ExpectedUsage?: Maybe<Order_By>;
  Priority?: Maybe<Order_By>;
  QualificationStatus?: Maybe<Order_By>;
  UsageThreshold?: Maybe<Order_By>;
};

/** update columns of table "dw.MLNotifications" */
export enum Dw_MlNotifications_Update_Column {
  /** column name */
  ActualUsage = 'ActualUsage',
  /** column name */
  AlertType = 'AlertType',
  /** column name */
  ChannelId = 'ChannelId',
  /** column name */
  ConfirmedRootCause = 'ConfirmedRootCause',
  /** column name */
  ContactId = 'ContactId',
  /** column name */
  ContextualSummary = 'ContextualSummary',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  EquivalentAnnualisedActualUsage = 'EquivalentAnnualisedActualUsage',
  /** column name */
  EquivalentAnnualisedC02 = 'EquivalentAnnualisedC02',
  /** column name */
  EquivalentAnnualisedCost = 'EquivalentAnnualisedCost',
  /** column name */
  EquivalentAnnualisedExpectedUsage = 'EquivalentAnnualisedExpectedUsage',
  /** column name */
  EquivalentC02 = 'EquivalentC02',
  /** column name */
  EquivalentCost = 'EquivalentCost',
  /** column name */
  ExpectedUsage = 'ExpectedUsage',
  /** column name */
  NotificationEndDate = 'NotificationEndDate',
  /** column name */
  NotificationGroupId = 'NotificationGroupId',
  /** column name */
  NotificationId = 'NotificationId',
  /** column name */
  NotificationStartDate = 'NotificationStartDate',
  /** column name */
  Priority = 'Priority',
  /** column name */
  QualificationStatus = 'QualificationStatus',
  /** column name */
  UpdatedOn = 'UpdatedOn',
  /** column name */
  UsageThreshold = 'UsageThreshold'
}

/** aggregate var_pop on columns */
export type Dw_MlNotifications_Var_Pop_Fields = {
  __typename?: 'dw_MLNotifications_var_pop_fields';
  ActualUsage?: Maybe<Scalars['Float']>;
  EquivalentAnnualisedActualUsage?: Maybe<Scalars['Float']>;
  EquivalentAnnualisedC02?: Maybe<Scalars['Float']>;
  EquivalentAnnualisedCost?: Maybe<Scalars['Float']>;
  EquivalentAnnualisedExpectedUsage?: Maybe<Scalars['Float']>;
  EquivalentC02?: Maybe<Scalars['Float']>;
  EquivalentCost?: Maybe<Scalars['Float']>;
  ExpectedUsage?: Maybe<Scalars['Float']>;
  Priority?: Maybe<Scalars['Float']>;
  QualificationStatus?: Maybe<Scalars['Float']>;
  UsageThreshold?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.MLNotifications" */
export type Dw_MlNotifications_Var_Pop_Order_By = {
  ActualUsage?: Maybe<Order_By>;
  EquivalentAnnualisedActualUsage?: Maybe<Order_By>;
  EquivalentAnnualisedC02?: Maybe<Order_By>;
  EquivalentAnnualisedCost?: Maybe<Order_By>;
  EquivalentAnnualisedExpectedUsage?: Maybe<Order_By>;
  EquivalentC02?: Maybe<Order_By>;
  EquivalentCost?: Maybe<Order_By>;
  ExpectedUsage?: Maybe<Order_By>;
  Priority?: Maybe<Order_By>;
  QualificationStatus?: Maybe<Order_By>;
  UsageThreshold?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_MlNotifications_Var_Samp_Fields = {
  __typename?: 'dw_MLNotifications_var_samp_fields';
  ActualUsage?: Maybe<Scalars['Float']>;
  EquivalentAnnualisedActualUsage?: Maybe<Scalars['Float']>;
  EquivalentAnnualisedC02?: Maybe<Scalars['Float']>;
  EquivalentAnnualisedCost?: Maybe<Scalars['Float']>;
  EquivalentAnnualisedExpectedUsage?: Maybe<Scalars['Float']>;
  EquivalentC02?: Maybe<Scalars['Float']>;
  EquivalentCost?: Maybe<Scalars['Float']>;
  ExpectedUsage?: Maybe<Scalars['Float']>;
  Priority?: Maybe<Scalars['Float']>;
  QualificationStatus?: Maybe<Scalars['Float']>;
  UsageThreshold?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.MLNotifications" */
export type Dw_MlNotifications_Var_Samp_Order_By = {
  ActualUsage?: Maybe<Order_By>;
  EquivalentAnnualisedActualUsage?: Maybe<Order_By>;
  EquivalentAnnualisedC02?: Maybe<Order_By>;
  EquivalentAnnualisedCost?: Maybe<Order_By>;
  EquivalentAnnualisedExpectedUsage?: Maybe<Order_By>;
  EquivalentC02?: Maybe<Order_By>;
  EquivalentCost?: Maybe<Order_By>;
  ExpectedUsage?: Maybe<Order_By>;
  Priority?: Maybe<Order_By>;
  QualificationStatus?: Maybe<Order_By>;
  UsageThreshold?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_MlNotifications_Variance_Fields = {
  __typename?: 'dw_MLNotifications_variance_fields';
  ActualUsage?: Maybe<Scalars['Float']>;
  EquivalentAnnualisedActualUsage?: Maybe<Scalars['Float']>;
  EquivalentAnnualisedC02?: Maybe<Scalars['Float']>;
  EquivalentAnnualisedCost?: Maybe<Scalars['Float']>;
  EquivalentAnnualisedExpectedUsage?: Maybe<Scalars['Float']>;
  EquivalentC02?: Maybe<Scalars['Float']>;
  EquivalentCost?: Maybe<Scalars['Float']>;
  ExpectedUsage?: Maybe<Scalars['Float']>;
  Priority?: Maybe<Scalars['Float']>;
  QualificationStatus?: Maybe<Scalars['Float']>;
  UsageThreshold?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.MLNotifications" */
export type Dw_MlNotifications_Variance_Order_By = {
  ActualUsage?: Maybe<Order_By>;
  EquivalentAnnualisedActualUsage?: Maybe<Order_By>;
  EquivalentAnnualisedC02?: Maybe<Order_By>;
  EquivalentAnnualisedCost?: Maybe<Order_By>;
  EquivalentAnnualisedExpectedUsage?: Maybe<Order_By>;
  EquivalentC02?: Maybe<Order_By>;
  EquivalentCost?: Maybe<Order_By>;
  ExpectedUsage?: Maybe<Order_By>;
  Priority?: Maybe<Order_By>;
  QualificationStatus?: Maybe<Order_By>;
  UsageThreshold?: Maybe<Order_By>;
};

/** columns and relationships of "dw.Notifications" */
export type Dw_Notifications = {
  __typename?: 'dw_Notifications';
  /** An object relationship */
  Account: Dw_Accounts;
  AccountId: Scalars['uuid'];
  Assessment?: Maybe<Scalars['String']>;
  Assignee?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  Building: Dw_Buildings;
  BuildingId: Scalars['uuid'];
  /** An object relationship */
  Channel: Dw_Channels;
  ChannelId: Scalars['uuid'];
  /** An object relationship */
  Contact?: Maybe<Dw_Contacts>;
  Cost?: Maybe<Scalars['numeric']>;
  CreatedOn: Scalars['timestamp'];
  DueDate?: Maybe<Scalars['timestamp']>;
  Emissions?: Maybe<Scalars['numeric']>;
  Label: Scalars['String'];
  /** An object relationship */
  Load: Dw_Loads;
  LoadId: Scalars['uuid'];
  NotificationId: Scalars['uuid'];
  /** An array relationship */
  NotificationsEvents: Array<Dw_NotificationsEvent>;
  /** An aggregate relationship */
  NotificationsEvents_aggregate: Dw_NotificationsEvent_Aggregate;
  /** An array relationship */
  NotificationsLogs: Array<Dw_NotificationsLog>;
  /** An aggregate relationship */
  NotificationsLogs_aggregate: Dw_NotificationsLog_Aggregate;
  /** An array relationship */
  NotificationsVisibilities: Array<Dw_NotificationsVisibility>;
  /** An aggregate relationship */
  NotificationsVisibilities_aggregate: Dw_NotificationsVisibility_Aggregate;
  Reviewer?: Maybe<Scalars['uuid']>;
  RootCause: Scalars['smallint'];
  Status: Scalars['smallint'];
  Type?: Maybe<Scalars['smallint']>;
  UpdatedOn: Scalars['timestamp'];
  Usage?: Maybe<Scalars['numeric']>;
  WindowEnd: Scalars['timestamp'];
  WindowStart: Scalars['timestamp'];
  /** An object relationship */
  contactByReviewer?: Maybe<Dw_Contacts>;
};


/** columns and relationships of "dw.Notifications" */
export type Dw_NotificationsNotificationsEventsArgs = {
  distinct_on?: Maybe<Array<Dw_NotificationsEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_NotificationsEvent_Order_By>>;
  where?: Maybe<Dw_NotificationsEvent_Bool_Exp>;
};


/** columns and relationships of "dw.Notifications" */
export type Dw_NotificationsNotificationsEvents_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_NotificationsEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_NotificationsEvent_Order_By>>;
  where?: Maybe<Dw_NotificationsEvent_Bool_Exp>;
};


/** columns and relationships of "dw.Notifications" */
export type Dw_NotificationsNotificationsLogsArgs = {
  distinct_on?: Maybe<Array<Dw_NotificationsLog_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_NotificationsLog_Order_By>>;
  where?: Maybe<Dw_NotificationsLog_Bool_Exp>;
};


/** columns and relationships of "dw.Notifications" */
export type Dw_NotificationsNotificationsLogs_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_NotificationsLog_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_NotificationsLog_Order_By>>;
  where?: Maybe<Dw_NotificationsLog_Bool_Exp>;
};


/** columns and relationships of "dw.Notifications" */
export type Dw_NotificationsNotificationsVisibilitiesArgs = {
  distinct_on?: Maybe<Array<Dw_NotificationsVisibility_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_NotificationsVisibility_Order_By>>;
  where?: Maybe<Dw_NotificationsVisibility_Bool_Exp>;
};


/** columns and relationships of "dw.Notifications" */
export type Dw_NotificationsNotificationsVisibilities_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_NotificationsVisibility_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_NotificationsVisibility_Order_By>>;
  where?: Maybe<Dw_NotificationsVisibility_Bool_Exp>;
};

/** columns and relationships of "dw.NotificationsEvent" */
export type Dw_NotificationsEvent = {
  __typename?: 'dw_NotificationsEvent';
  Consumption: Scalars['float8'];
  Event: Scalars['timestamp'];
  EventId: Scalars['uuid'];
  /** An object relationship */
  Notification: Dw_Notifications;
  NotificationId: Scalars['uuid'];
  PValue: Scalars['numeric'];
  Score: Scalars['numeric'];
  Status: Scalars['smallint'];
};

/** aggregated selection of "dw.NotificationsEvent" */
export type Dw_NotificationsEvent_Aggregate = {
  __typename?: 'dw_NotificationsEvent_aggregate';
  aggregate?: Maybe<Dw_NotificationsEvent_Aggregate_Fields>;
  nodes: Array<Dw_NotificationsEvent>;
};

/** aggregate fields of "dw.NotificationsEvent" */
export type Dw_NotificationsEvent_Aggregate_Fields = {
  __typename?: 'dw_NotificationsEvent_aggregate_fields';
  avg?: Maybe<Dw_NotificationsEvent_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_NotificationsEvent_Max_Fields>;
  min?: Maybe<Dw_NotificationsEvent_Min_Fields>;
  stddev?: Maybe<Dw_NotificationsEvent_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_NotificationsEvent_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_NotificationsEvent_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_NotificationsEvent_Sum_Fields>;
  var_pop?: Maybe<Dw_NotificationsEvent_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_NotificationsEvent_Var_Samp_Fields>;
  variance?: Maybe<Dw_NotificationsEvent_Variance_Fields>;
};


/** aggregate fields of "dw.NotificationsEvent" */
export type Dw_NotificationsEvent_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_NotificationsEvent_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.NotificationsEvent" */
export type Dw_NotificationsEvent_Aggregate_Order_By = {
  avg?: Maybe<Dw_NotificationsEvent_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_NotificationsEvent_Max_Order_By>;
  min?: Maybe<Dw_NotificationsEvent_Min_Order_By>;
  stddev?: Maybe<Dw_NotificationsEvent_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_NotificationsEvent_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_NotificationsEvent_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_NotificationsEvent_Sum_Order_By>;
  var_pop?: Maybe<Dw_NotificationsEvent_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_NotificationsEvent_Var_Samp_Order_By>;
  variance?: Maybe<Dw_NotificationsEvent_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.NotificationsEvent" */
export type Dw_NotificationsEvent_Arr_Rel_Insert_Input = {
  data: Array<Dw_NotificationsEvent_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_NotificationsEvent_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_NotificationsEvent_Avg_Fields = {
  __typename?: 'dw_NotificationsEvent_avg_fields';
  Consumption?: Maybe<Scalars['Float']>;
  PValue?: Maybe<Scalars['Float']>;
  Score?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.NotificationsEvent" */
export type Dw_NotificationsEvent_Avg_Order_By = {
  Consumption?: Maybe<Order_By>;
  PValue?: Maybe<Order_By>;
  Score?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.NotificationsEvent". All fields are combined with a logical 'AND'. */
export type Dw_NotificationsEvent_Bool_Exp = {
  Consumption?: Maybe<Float8_Comparison_Exp>;
  Event?: Maybe<Timestamp_Comparison_Exp>;
  EventId?: Maybe<Uuid_Comparison_Exp>;
  Notification?: Maybe<Dw_Notifications_Bool_Exp>;
  NotificationId?: Maybe<Uuid_Comparison_Exp>;
  PValue?: Maybe<Numeric_Comparison_Exp>;
  Score?: Maybe<Numeric_Comparison_Exp>;
  Status?: Maybe<Smallint_Comparison_Exp>;
  _and?: Maybe<Array<Dw_NotificationsEvent_Bool_Exp>>;
  _not?: Maybe<Dw_NotificationsEvent_Bool_Exp>;
  _or?: Maybe<Array<Dw_NotificationsEvent_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.NotificationsEvent" */
export enum Dw_NotificationsEvent_Constraint {
  /** unique or primary key constraint */
  NotificationseventEventidUindex = 'notificationsevent_eventid_uindex',
  /** unique or primary key constraint */
  NotificationseventPk = 'notificationsevent_pk'
}

/** input type for incrementing numeric columns in table "dw.NotificationsEvent" */
export type Dw_NotificationsEvent_Inc_Input = {
  Consumption?: Maybe<Scalars['float8']>;
  PValue?: Maybe<Scalars['numeric']>;
  Score?: Maybe<Scalars['numeric']>;
  Status?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.NotificationsEvent" */
export type Dw_NotificationsEvent_Insert_Input = {
  Consumption?: Maybe<Scalars['float8']>;
  Event?: Maybe<Scalars['timestamp']>;
  EventId?: Maybe<Scalars['uuid']>;
  Notification?: Maybe<Dw_Notifications_Obj_Rel_Insert_Input>;
  NotificationId?: Maybe<Scalars['uuid']>;
  PValue?: Maybe<Scalars['numeric']>;
  Score?: Maybe<Scalars['numeric']>;
  Status?: Maybe<Scalars['smallint']>;
};

/** aggregate max on columns */
export type Dw_NotificationsEvent_Max_Fields = {
  __typename?: 'dw_NotificationsEvent_max_fields';
  Consumption?: Maybe<Scalars['float8']>;
  Event?: Maybe<Scalars['timestamp']>;
  EventId?: Maybe<Scalars['uuid']>;
  NotificationId?: Maybe<Scalars['uuid']>;
  PValue?: Maybe<Scalars['numeric']>;
  Score?: Maybe<Scalars['numeric']>;
  Status?: Maybe<Scalars['smallint']>;
};

/** order by max() on columns of table "dw.NotificationsEvent" */
export type Dw_NotificationsEvent_Max_Order_By = {
  Consumption?: Maybe<Order_By>;
  Event?: Maybe<Order_By>;
  EventId?: Maybe<Order_By>;
  NotificationId?: Maybe<Order_By>;
  PValue?: Maybe<Order_By>;
  Score?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_NotificationsEvent_Min_Fields = {
  __typename?: 'dw_NotificationsEvent_min_fields';
  Consumption?: Maybe<Scalars['float8']>;
  Event?: Maybe<Scalars['timestamp']>;
  EventId?: Maybe<Scalars['uuid']>;
  NotificationId?: Maybe<Scalars['uuid']>;
  PValue?: Maybe<Scalars['numeric']>;
  Score?: Maybe<Scalars['numeric']>;
  Status?: Maybe<Scalars['smallint']>;
};

/** order by min() on columns of table "dw.NotificationsEvent" */
export type Dw_NotificationsEvent_Min_Order_By = {
  Consumption?: Maybe<Order_By>;
  Event?: Maybe<Order_By>;
  EventId?: Maybe<Order_By>;
  NotificationId?: Maybe<Order_By>;
  PValue?: Maybe<Order_By>;
  Score?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.NotificationsEvent" */
export type Dw_NotificationsEvent_Mutation_Response = {
  __typename?: 'dw_NotificationsEvent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_NotificationsEvent>;
};

/** on conflict condition type for table "dw.NotificationsEvent" */
export type Dw_NotificationsEvent_On_Conflict = {
  constraint: Dw_NotificationsEvent_Constraint;
  update_columns?: Array<Dw_NotificationsEvent_Update_Column>;
  where?: Maybe<Dw_NotificationsEvent_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.NotificationsEvent". */
export type Dw_NotificationsEvent_Order_By = {
  Consumption?: Maybe<Order_By>;
  Event?: Maybe<Order_By>;
  EventId?: Maybe<Order_By>;
  Notification?: Maybe<Dw_Notifications_Order_By>;
  NotificationId?: Maybe<Order_By>;
  PValue?: Maybe<Order_By>;
  Score?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_NotificationsEvent */
export type Dw_NotificationsEvent_Pk_Columns_Input = {
  EventId: Scalars['uuid'];
};

/** select columns of table "dw.NotificationsEvent" */
export enum Dw_NotificationsEvent_Select_Column {
  /** column name */
  Consumption = 'Consumption',
  /** column name */
  Event = 'Event',
  /** column name */
  EventId = 'EventId',
  /** column name */
  NotificationId = 'NotificationId',
  /** column name */
  PValue = 'PValue',
  /** column name */
  Score = 'Score',
  /** column name */
  Status = 'Status'
}

/** input type for updating data in table "dw.NotificationsEvent" */
export type Dw_NotificationsEvent_Set_Input = {
  Consumption?: Maybe<Scalars['float8']>;
  Event?: Maybe<Scalars['timestamp']>;
  EventId?: Maybe<Scalars['uuid']>;
  NotificationId?: Maybe<Scalars['uuid']>;
  PValue?: Maybe<Scalars['numeric']>;
  Score?: Maybe<Scalars['numeric']>;
  Status?: Maybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type Dw_NotificationsEvent_Stddev_Fields = {
  __typename?: 'dw_NotificationsEvent_stddev_fields';
  Consumption?: Maybe<Scalars['Float']>;
  PValue?: Maybe<Scalars['Float']>;
  Score?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.NotificationsEvent" */
export type Dw_NotificationsEvent_Stddev_Order_By = {
  Consumption?: Maybe<Order_By>;
  PValue?: Maybe<Order_By>;
  Score?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_NotificationsEvent_Stddev_Pop_Fields = {
  __typename?: 'dw_NotificationsEvent_stddev_pop_fields';
  Consumption?: Maybe<Scalars['Float']>;
  PValue?: Maybe<Scalars['Float']>;
  Score?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.NotificationsEvent" */
export type Dw_NotificationsEvent_Stddev_Pop_Order_By = {
  Consumption?: Maybe<Order_By>;
  PValue?: Maybe<Order_By>;
  Score?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_NotificationsEvent_Stddev_Samp_Fields = {
  __typename?: 'dw_NotificationsEvent_stddev_samp_fields';
  Consumption?: Maybe<Scalars['Float']>;
  PValue?: Maybe<Scalars['Float']>;
  Score?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.NotificationsEvent" */
export type Dw_NotificationsEvent_Stddev_Samp_Order_By = {
  Consumption?: Maybe<Order_By>;
  PValue?: Maybe<Order_By>;
  Score?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_NotificationsEvent_Sum_Fields = {
  __typename?: 'dw_NotificationsEvent_sum_fields';
  Consumption?: Maybe<Scalars['float8']>;
  PValue?: Maybe<Scalars['numeric']>;
  Score?: Maybe<Scalars['numeric']>;
  Status?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "dw.NotificationsEvent" */
export type Dw_NotificationsEvent_Sum_Order_By = {
  Consumption?: Maybe<Order_By>;
  PValue?: Maybe<Order_By>;
  Score?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** update columns of table "dw.NotificationsEvent" */
export enum Dw_NotificationsEvent_Update_Column {
  /** column name */
  Consumption = 'Consumption',
  /** column name */
  Event = 'Event',
  /** column name */
  EventId = 'EventId',
  /** column name */
  NotificationId = 'NotificationId',
  /** column name */
  PValue = 'PValue',
  /** column name */
  Score = 'Score',
  /** column name */
  Status = 'Status'
}

/** aggregate var_pop on columns */
export type Dw_NotificationsEvent_Var_Pop_Fields = {
  __typename?: 'dw_NotificationsEvent_var_pop_fields';
  Consumption?: Maybe<Scalars['Float']>;
  PValue?: Maybe<Scalars['Float']>;
  Score?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.NotificationsEvent" */
export type Dw_NotificationsEvent_Var_Pop_Order_By = {
  Consumption?: Maybe<Order_By>;
  PValue?: Maybe<Order_By>;
  Score?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_NotificationsEvent_Var_Samp_Fields = {
  __typename?: 'dw_NotificationsEvent_var_samp_fields';
  Consumption?: Maybe<Scalars['Float']>;
  PValue?: Maybe<Scalars['Float']>;
  Score?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.NotificationsEvent" */
export type Dw_NotificationsEvent_Var_Samp_Order_By = {
  Consumption?: Maybe<Order_By>;
  PValue?: Maybe<Order_By>;
  Score?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_NotificationsEvent_Variance_Fields = {
  __typename?: 'dw_NotificationsEvent_variance_fields';
  Consumption?: Maybe<Scalars['Float']>;
  PValue?: Maybe<Scalars['Float']>;
  Score?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.NotificationsEvent" */
export type Dw_NotificationsEvent_Variance_Order_By = {
  Consumption?: Maybe<Order_By>;
  PValue?: Maybe<Order_By>;
  Score?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** columns and relationships of "dw.NotificationsLog" */
export type Dw_NotificationsLog = {
  __typename?: 'dw_NotificationsLog';
  /** An object relationship */
  Contact?: Maybe<Dw_Contacts>;
  ContactId?: Maybe<Scalars['uuid']>;
  Event: Scalars['timestamp'];
  LogId: Scalars['uuid'];
  Message: Scalars['String'];
  /** An object relationship */
  Notification: Dw_Notifications;
  NotificationId: Scalars['uuid'];
  Type: Scalars['smallint'];
};

/** aggregated selection of "dw.NotificationsLog" */
export type Dw_NotificationsLog_Aggregate = {
  __typename?: 'dw_NotificationsLog_aggregate';
  aggregate?: Maybe<Dw_NotificationsLog_Aggregate_Fields>;
  nodes: Array<Dw_NotificationsLog>;
};

/** aggregate fields of "dw.NotificationsLog" */
export type Dw_NotificationsLog_Aggregate_Fields = {
  __typename?: 'dw_NotificationsLog_aggregate_fields';
  avg?: Maybe<Dw_NotificationsLog_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_NotificationsLog_Max_Fields>;
  min?: Maybe<Dw_NotificationsLog_Min_Fields>;
  stddev?: Maybe<Dw_NotificationsLog_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_NotificationsLog_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_NotificationsLog_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_NotificationsLog_Sum_Fields>;
  var_pop?: Maybe<Dw_NotificationsLog_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_NotificationsLog_Var_Samp_Fields>;
  variance?: Maybe<Dw_NotificationsLog_Variance_Fields>;
};


/** aggregate fields of "dw.NotificationsLog" */
export type Dw_NotificationsLog_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_NotificationsLog_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.NotificationsLog" */
export type Dw_NotificationsLog_Aggregate_Order_By = {
  avg?: Maybe<Dw_NotificationsLog_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_NotificationsLog_Max_Order_By>;
  min?: Maybe<Dw_NotificationsLog_Min_Order_By>;
  stddev?: Maybe<Dw_NotificationsLog_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_NotificationsLog_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_NotificationsLog_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_NotificationsLog_Sum_Order_By>;
  var_pop?: Maybe<Dw_NotificationsLog_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_NotificationsLog_Var_Samp_Order_By>;
  variance?: Maybe<Dw_NotificationsLog_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.NotificationsLog" */
export type Dw_NotificationsLog_Arr_Rel_Insert_Input = {
  data: Array<Dw_NotificationsLog_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_NotificationsLog_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_NotificationsLog_Avg_Fields = {
  __typename?: 'dw_NotificationsLog_avg_fields';
  Type?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.NotificationsLog" */
export type Dw_NotificationsLog_Avg_Order_By = {
  Type?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.NotificationsLog". All fields are combined with a logical 'AND'. */
export type Dw_NotificationsLog_Bool_Exp = {
  Contact?: Maybe<Dw_Contacts_Bool_Exp>;
  ContactId?: Maybe<Uuid_Comparison_Exp>;
  Event?: Maybe<Timestamp_Comparison_Exp>;
  LogId?: Maybe<Uuid_Comparison_Exp>;
  Message?: Maybe<String_Comparison_Exp>;
  Notification?: Maybe<Dw_Notifications_Bool_Exp>;
  NotificationId?: Maybe<Uuid_Comparison_Exp>;
  Type?: Maybe<Smallint_Comparison_Exp>;
  _and?: Maybe<Array<Dw_NotificationsLog_Bool_Exp>>;
  _not?: Maybe<Dw_NotificationsLog_Bool_Exp>;
  _or?: Maybe<Array<Dw_NotificationsLog_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.NotificationsLog" */
export enum Dw_NotificationsLog_Constraint {
  /** unique or primary key constraint */
  NotificationslogLogidUindex = 'notificationslog_logid_uindex',
  /** unique or primary key constraint */
  NotificationslogPk = 'notificationslog_pk'
}

/** input type for incrementing numeric columns in table "dw.NotificationsLog" */
export type Dw_NotificationsLog_Inc_Input = {
  Type?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.NotificationsLog" */
export type Dw_NotificationsLog_Insert_Input = {
  Contact?: Maybe<Dw_Contacts_Obj_Rel_Insert_Input>;
  ContactId?: Maybe<Scalars['uuid']>;
  Event?: Maybe<Scalars['timestamp']>;
  LogId?: Maybe<Scalars['uuid']>;
  Message?: Maybe<Scalars['String']>;
  Notification?: Maybe<Dw_Notifications_Obj_Rel_Insert_Input>;
  NotificationId?: Maybe<Scalars['uuid']>;
  Type?: Maybe<Scalars['smallint']>;
};

/** aggregate max on columns */
export type Dw_NotificationsLog_Max_Fields = {
  __typename?: 'dw_NotificationsLog_max_fields';
  ContactId?: Maybe<Scalars['uuid']>;
  Event?: Maybe<Scalars['timestamp']>;
  LogId?: Maybe<Scalars['uuid']>;
  Message?: Maybe<Scalars['String']>;
  NotificationId?: Maybe<Scalars['uuid']>;
  Type?: Maybe<Scalars['smallint']>;
};

/** order by max() on columns of table "dw.NotificationsLog" */
export type Dw_NotificationsLog_Max_Order_By = {
  ContactId?: Maybe<Order_By>;
  Event?: Maybe<Order_By>;
  LogId?: Maybe<Order_By>;
  Message?: Maybe<Order_By>;
  NotificationId?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_NotificationsLog_Min_Fields = {
  __typename?: 'dw_NotificationsLog_min_fields';
  ContactId?: Maybe<Scalars['uuid']>;
  Event?: Maybe<Scalars['timestamp']>;
  LogId?: Maybe<Scalars['uuid']>;
  Message?: Maybe<Scalars['String']>;
  NotificationId?: Maybe<Scalars['uuid']>;
  Type?: Maybe<Scalars['smallint']>;
};

/** order by min() on columns of table "dw.NotificationsLog" */
export type Dw_NotificationsLog_Min_Order_By = {
  ContactId?: Maybe<Order_By>;
  Event?: Maybe<Order_By>;
  LogId?: Maybe<Order_By>;
  Message?: Maybe<Order_By>;
  NotificationId?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.NotificationsLog" */
export type Dw_NotificationsLog_Mutation_Response = {
  __typename?: 'dw_NotificationsLog_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_NotificationsLog>;
};

/** on conflict condition type for table "dw.NotificationsLog" */
export type Dw_NotificationsLog_On_Conflict = {
  constraint: Dw_NotificationsLog_Constraint;
  update_columns?: Array<Dw_NotificationsLog_Update_Column>;
  where?: Maybe<Dw_NotificationsLog_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.NotificationsLog". */
export type Dw_NotificationsLog_Order_By = {
  Contact?: Maybe<Dw_Contacts_Order_By>;
  ContactId?: Maybe<Order_By>;
  Event?: Maybe<Order_By>;
  LogId?: Maybe<Order_By>;
  Message?: Maybe<Order_By>;
  Notification?: Maybe<Dw_Notifications_Order_By>;
  NotificationId?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_NotificationsLog */
export type Dw_NotificationsLog_Pk_Columns_Input = {
  LogId: Scalars['uuid'];
};

/** select columns of table "dw.NotificationsLog" */
export enum Dw_NotificationsLog_Select_Column {
  /** column name */
  ContactId = 'ContactId',
  /** column name */
  Event = 'Event',
  /** column name */
  LogId = 'LogId',
  /** column name */
  Message = 'Message',
  /** column name */
  NotificationId = 'NotificationId',
  /** column name */
  Type = 'Type'
}

/** input type for updating data in table "dw.NotificationsLog" */
export type Dw_NotificationsLog_Set_Input = {
  ContactId?: Maybe<Scalars['uuid']>;
  Event?: Maybe<Scalars['timestamp']>;
  LogId?: Maybe<Scalars['uuid']>;
  Message?: Maybe<Scalars['String']>;
  NotificationId?: Maybe<Scalars['uuid']>;
  Type?: Maybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type Dw_NotificationsLog_Stddev_Fields = {
  __typename?: 'dw_NotificationsLog_stddev_fields';
  Type?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.NotificationsLog" */
export type Dw_NotificationsLog_Stddev_Order_By = {
  Type?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_NotificationsLog_Stddev_Pop_Fields = {
  __typename?: 'dw_NotificationsLog_stddev_pop_fields';
  Type?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.NotificationsLog" */
export type Dw_NotificationsLog_Stddev_Pop_Order_By = {
  Type?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_NotificationsLog_Stddev_Samp_Fields = {
  __typename?: 'dw_NotificationsLog_stddev_samp_fields';
  Type?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.NotificationsLog" */
export type Dw_NotificationsLog_Stddev_Samp_Order_By = {
  Type?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_NotificationsLog_Sum_Fields = {
  __typename?: 'dw_NotificationsLog_sum_fields';
  Type?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "dw.NotificationsLog" */
export type Dw_NotificationsLog_Sum_Order_By = {
  Type?: Maybe<Order_By>;
};

/** update columns of table "dw.NotificationsLog" */
export enum Dw_NotificationsLog_Update_Column {
  /** column name */
  ContactId = 'ContactId',
  /** column name */
  Event = 'Event',
  /** column name */
  LogId = 'LogId',
  /** column name */
  Message = 'Message',
  /** column name */
  NotificationId = 'NotificationId',
  /** column name */
  Type = 'Type'
}

/** aggregate var_pop on columns */
export type Dw_NotificationsLog_Var_Pop_Fields = {
  __typename?: 'dw_NotificationsLog_var_pop_fields';
  Type?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.NotificationsLog" */
export type Dw_NotificationsLog_Var_Pop_Order_By = {
  Type?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_NotificationsLog_Var_Samp_Fields = {
  __typename?: 'dw_NotificationsLog_var_samp_fields';
  Type?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.NotificationsLog" */
export type Dw_NotificationsLog_Var_Samp_Order_By = {
  Type?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_NotificationsLog_Variance_Fields = {
  __typename?: 'dw_NotificationsLog_variance_fields';
  Type?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.NotificationsLog" */
export type Dw_NotificationsLog_Variance_Order_By = {
  Type?: Maybe<Order_By>;
};

/** columns and relationships of "dw.NotificationsVisibility" */
export type Dw_NotificationsVisibility = {
  __typename?: 'dw_NotificationsVisibility';
  /** An object relationship */
  Contact: Dw_Contacts;
  ContactId: Scalars['uuid'];
  /** An object relationship */
  Notification: Dw_Notifications;
  NotificationId: Scalars['uuid'];
  ViewedOn: Scalars['timestamp'];
};

/** aggregated selection of "dw.NotificationsVisibility" */
export type Dw_NotificationsVisibility_Aggregate = {
  __typename?: 'dw_NotificationsVisibility_aggregate';
  aggregate?: Maybe<Dw_NotificationsVisibility_Aggregate_Fields>;
  nodes: Array<Dw_NotificationsVisibility>;
};

/** aggregate fields of "dw.NotificationsVisibility" */
export type Dw_NotificationsVisibility_Aggregate_Fields = {
  __typename?: 'dw_NotificationsVisibility_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Dw_NotificationsVisibility_Max_Fields>;
  min?: Maybe<Dw_NotificationsVisibility_Min_Fields>;
};


/** aggregate fields of "dw.NotificationsVisibility" */
export type Dw_NotificationsVisibility_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_NotificationsVisibility_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.NotificationsVisibility" */
export type Dw_NotificationsVisibility_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_NotificationsVisibility_Max_Order_By>;
  min?: Maybe<Dw_NotificationsVisibility_Min_Order_By>;
};

/** input type for inserting array relation for remote table "dw.NotificationsVisibility" */
export type Dw_NotificationsVisibility_Arr_Rel_Insert_Input = {
  data: Array<Dw_NotificationsVisibility_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_NotificationsVisibility_On_Conflict>;
};

/** Boolean expression to filter rows from the table "dw.NotificationsVisibility". All fields are combined with a logical 'AND'. */
export type Dw_NotificationsVisibility_Bool_Exp = {
  Contact?: Maybe<Dw_Contacts_Bool_Exp>;
  ContactId?: Maybe<Uuid_Comparison_Exp>;
  Notification?: Maybe<Dw_Notifications_Bool_Exp>;
  NotificationId?: Maybe<Uuid_Comparison_Exp>;
  ViewedOn?: Maybe<Timestamp_Comparison_Exp>;
  _and?: Maybe<Array<Dw_NotificationsVisibility_Bool_Exp>>;
  _not?: Maybe<Dw_NotificationsVisibility_Bool_Exp>;
  _or?: Maybe<Array<Dw_NotificationsVisibility_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.NotificationsVisibility" */
export enum Dw_NotificationsVisibility_Constraint {
  /** unique or primary key constraint */
  NotificationsvisibilityPk = 'notificationsvisibility_pk'
}

/** input type for inserting data into table "dw.NotificationsVisibility" */
export type Dw_NotificationsVisibility_Insert_Input = {
  Contact?: Maybe<Dw_Contacts_Obj_Rel_Insert_Input>;
  ContactId?: Maybe<Scalars['uuid']>;
  Notification?: Maybe<Dw_Notifications_Obj_Rel_Insert_Input>;
  NotificationId?: Maybe<Scalars['uuid']>;
  ViewedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Dw_NotificationsVisibility_Max_Fields = {
  __typename?: 'dw_NotificationsVisibility_max_fields';
  ContactId?: Maybe<Scalars['uuid']>;
  NotificationId?: Maybe<Scalars['uuid']>;
  ViewedOn?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "dw.NotificationsVisibility" */
export type Dw_NotificationsVisibility_Max_Order_By = {
  ContactId?: Maybe<Order_By>;
  NotificationId?: Maybe<Order_By>;
  ViewedOn?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_NotificationsVisibility_Min_Fields = {
  __typename?: 'dw_NotificationsVisibility_min_fields';
  ContactId?: Maybe<Scalars['uuid']>;
  NotificationId?: Maybe<Scalars['uuid']>;
  ViewedOn?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "dw.NotificationsVisibility" */
export type Dw_NotificationsVisibility_Min_Order_By = {
  ContactId?: Maybe<Order_By>;
  NotificationId?: Maybe<Order_By>;
  ViewedOn?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.NotificationsVisibility" */
export type Dw_NotificationsVisibility_Mutation_Response = {
  __typename?: 'dw_NotificationsVisibility_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_NotificationsVisibility>;
};

/** on conflict condition type for table "dw.NotificationsVisibility" */
export type Dw_NotificationsVisibility_On_Conflict = {
  constraint: Dw_NotificationsVisibility_Constraint;
  update_columns?: Array<Dw_NotificationsVisibility_Update_Column>;
  where?: Maybe<Dw_NotificationsVisibility_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.NotificationsVisibility". */
export type Dw_NotificationsVisibility_Order_By = {
  Contact?: Maybe<Dw_Contacts_Order_By>;
  ContactId?: Maybe<Order_By>;
  Notification?: Maybe<Dw_Notifications_Order_By>;
  NotificationId?: Maybe<Order_By>;
  ViewedOn?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_NotificationsVisibility */
export type Dw_NotificationsVisibility_Pk_Columns_Input = {
  ContactId: Scalars['uuid'];
  NotificationId: Scalars['uuid'];
};

/** select columns of table "dw.NotificationsVisibility" */
export enum Dw_NotificationsVisibility_Select_Column {
  /** column name */
  ContactId = 'ContactId',
  /** column name */
  NotificationId = 'NotificationId',
  /** column name */
  ViewedOn = 'ViewedOn'
}

/** input type for updating data in table "dw.NotificationsVisibility" */
export type Dw_NotificationsVisibility_Set_Input = {
  ContactId?: Maybe<Scalars['uuid']>;
  NotificationId?: Maybe<Scalars['uuid']>;
  ViewedOn?: Maybe<Scalars['timestamp']>;
};

/** update columns of table "dw.NotificationsVisibility" */
export enum Dw_NotificationsVisibility_Update_Column {
  /** column name */
  ContactId = 'ContactId',
  /** column name */
  NotificationId = 'NotificationId',
  /** column name */
  ViewedOn = 'ViewedOn'
}

/** aggregated selection of "dw.Notifications" */
export type Dw_Notifications_Aggregate = {
  __typename?: 'dw_Notifications_aggregate';
  aggregate?: Maybe<Dw_Notifications_Aggregate_Fields>;
  nodes: Array<Dw_Notifications>;
};

/** aggregate fields of "dw.Notifications" */
export type Dw_Notifications_Aggregate_Fields = {
  __typename?: 'dw_Notifications_aggregate_fields';
  avg?: Maybe<Dw_Notifications_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_Notifications_Max_Fields>;
  min?: Maybe<Dw_Notifications_Min_Fields>;
  stddev?: Maybe<Dw_Notifications_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_Notifications_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_Notifications_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_Notifications_Sum_Fields>;
  var_pop?: Maybe<Dw_Notifications_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_Notifications_Var_Samp_Fields>;
  variance?: Maybe<Dw_Notifications_Variance_Fields>;
};


/** aggregate fields of "dw.Notifications" */
export type Dw_Notifications_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_Notifications_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.Notifications" */
export type Dw_Notifications_Aggregate_Order_By = {
  avg?: Maybe<Dw_Notifications_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_Notifications_Max_Order_By>;
  min?: Maybe<Dw_Notifications_Min_Order_By>;
  stddev?: Maybe<Dw_Notifications_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_Notifications_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_Notifications_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_Notifications_Sum_Order_By>;
  var_pop?: Maybe<Dw_Notifications_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_Notifications_Var_Samp_Order_By>;
  variance?: Maybe<Dw_Notifications_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.Notifications" */
export type Dw_Notifications_Arr_Rel_Insert_Input = {
  data: Array<Dw_Notifications_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_Notifications_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_Notifications_Avg_Fields = {
  __typename?: 'dw_Notifications_avg_fields';
  Cost?: Maybe<Scalars['Float']>;
  Emissions?: Maybe<Scalars['Float']>;
  RootCause?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  Usage?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.Notifications" */
export type Dw_Notifications_Avg_Order_By = {
  Cost?: Maybe<Order_By>;
  Emissions?: Maybe<Order_By>;
  RootCause?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Usage?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.Notifications". All fields are combined with a logical 'AND'. */
export type Dw_Notifications_Bool_Exp = {
  Account?: Maybe<Dw_Accounts_Bool_Exp>;
  AccountId?: Maybe<Uuid_Comparison_Exp>;
  Assessment?: Maybe<String_Comparison_Exp>;
  Assignee?: Maybe<Uuid_Comparison_Exp>;
  Building?: Maybe<Dw_Buildings_Bool_Exp>;
  BuildingId?: Maybe<Uuid_Comparison_Exp>;
  Channel?: Maybe<Dw_Channels_Bool_Exp>;
  ChannelId?: Maybe<Uuid_Comparison_Exp>;
  Contact?: Maybe<Dw_Contacts_Bool_Exp>;
  Cost?: Maybe<Numeric_Comparison_Exp>;
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  DueDate?: Maybe<Timestamp_Comparison_Exp>;
  Emissions?: Maybe<Numeric_Comparison_Exp>;
  Label?: Maybe<String_Comparison_Exp>;
  Load?: Maybe<Dw_Loads_Bool_Exp>;
  LoadId?: Maybe<Uuid_Comparison_Exp>;
  NotificationId?: Maybe<Uuid_Comparison_Exp>;
  NotificationsEvents?: Maybe<Dw_NotificationsEvent_Bool_Exp>;
  NotificationsLogs?: Maybe<Dw_NotificationsLog_Bool_Exp>;
  NotificationsVisibilities?: Maybe<Dw_NotificationsVisibility_Bool_Exp>;
  Reviewer?: Maybe<Uuid_Comparison_Exp>;
  RootCause?: Maybe<Smallint_Comparison_Exp>;
  Status?: Maybe<Smallint_Comparison_Exp>;
  Type?: Maybe<Smallint_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  Usage?: Maybe<Numeric_Comparison_Exp>;
  WindowEnd?: Maybe<Timestamp_Comparison_Exp>;
  WindowStart?: Maybe<Timestamp_Comparison_Exp>;
  _and?: Maybe<Array<Dw_Notifications_Bool_Exp>>;
  _not?: Maybe<Dw_Notifications_Bool_Exp>;
  _or?: Maybe<Array<Dw_Notifications_Bool_Exp>>;
  contactByReviewer?: Maybe<Dw_Contacts_Bool_Exp>;
};

/** unique or primary key constraints on table "dw.Notifications" */
export enum Dw_Notifications_Constraint {
  /** unique or primary key constraint */
  NotificationsNotificationidUindex = 'notifications_notificationid_uindex',
  /** unique or primary key constraint */
  NotificationsPk = 'notifications_pk'
}

/** input type for incrementing numeric columns in table "dw.Notifications" */
export type Dw_Notifications_Inc_Input = {
  Cost?: Maybe<Scalars['numeric']>;
  Emissions?: Maybe<Scalars['numeric']>;
  RootCause?: Maybe<Scalars['smallint']>;
  Status?: Maybe<Scalars['smallint']>;
  Type?: Maybe<Scalars['smallint']>;
  Usage?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "dw.Notifications" */
export type Dw_Notifications_Insert_Input = {
  Account?: Maybe<Dw_Accounts_Obj_Rel_Insert_Input>;
  AccountId?: Maybe<Scalars['uuid']>;
  Assessment?: Maybe<Scalars['String']>;
  Assignee?: Maybe<Scalars['uuid']>;
  Building?: Maybe<Dw_Buildings_Obj_Rel_Insert_Input>;
  BuildingId?: Maybe<Scalars['uuid']>;
  Channel?: Maybe<Dw_Channels_Obj_Rel_Insert_Input>;
  ChannelId?: Maybe<Scalars['uuid']>;
  Contact?: Maybe<Dw_Contacts_Obj_Rel_Insert_Input>;
  Cost?: Maybe<Scalars['numeric']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DueDate?: Maybe<Scalars['timestamp']>;
  Emissions?: Maybe<Scalars['numeric']>;
  Label?: Maybe<Scalars['String']>;
  Load?: Maybe<Dw_Loads_Obj_Rel_Insert_Input>;
  LoadId?: Maybe<Scalars['uuid']>;
  NotificationId?: Maybe<Scalars['uuid']>;
  NotificationsEvents?: Maybe<Dw_NotificationsEvent_Arr_Rel_Insert_Input>;
  NotificationsLogs?: Maybe<Dw_NotificationsLog_Arr_Rel_Insert_Input>;
  NotificationsVisibilities?: Maybe<Dw_NotificationsVisibility_Arr_Rel_Insert_Input>;
  Reviewer?: Maybe<Scalars['uuid']>;
  RootCause?: Maybe<Scalars['smallint']>;
  Status?: Maybe<Scalars['smallint']>;
  Type?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  Usage?: Maybe<Scalars['numeric']>;
  WindowEnd?: Maybe<Scalars['timestamp']>;
  WindowStart?: Maybe<Scalars['timestamp']>;
  contactByReviewer?: Maybe<Dw_Contacts_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Dw_Notifications_Max_Fields = {
  __typename?: 'dw_Notifications_max_fields';
  AccountId?: Maybe<Scalars['uuid']>;
  Assessment?: Maybe<Scalars['String']>;
  Assignee?: Maybe<Scalars['uuid']>;
  BuildingId?: Maybe<Scalars['uuid']>;
  ChannelId?: Maybe<Scalars['uuid']>;
  Cost?: Maybe<Scalars['numeric']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DueDate?: Maybe<Scalars['timestamp']>;
  Emissions?: Maybe<Scalars['numeric']>;
  Label?: Maybe<Scalars['String']>;
  LoadId?: Maybe<Scalars['uuid']>;
  NotificationId?: Maybe<Scalars['uuid']>;
  Reviewer?: Maybe<Scalars['uuid']>;
  RootCause?: Maybe<Scalars['smallint']>;
  Status?: Maybe<Scalars['smallint']>;
  Type?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  Usage?: Maybe<Scalars['numeric']>;
  WindowEnd?: Maybe<Scalars['timestamp']>;
  WindowStart?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "dw.Notifications" */
export type Dw_Notifications_Max_Order_By = {
  AccountId?: Maybe<Order_By>;
  Assessment?: Maybe<Order_By>;
  Assignee?: Maybe<Order_By>;
  BuildingId?: Maybe<Order_By>;
  ChannelId?: Maybe<Order_By>;
  Cost?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  DueDate?: Maybe<Order_By>;
  Emissions?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  LoadId?: Maybe<Order_By>;
  NotificationId?: Maybe<Order_By>;
  Reviewer?: Maybe<Order_By>;
  RootCause?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
  Usage?: Maybe<Order_By>;
  WindowEnd?: Maybe<Order_By>;
  WindowStart?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_Notifications_Min_Fields = {
  __typename?: 'dw_Notifications_min_fields';
  AccountId?: Maybe<Scalars['uuid']>;
  Assessment?: Maybe<Scalars['String']>;
  Assignee?: Maybe<Scalars['uuid']>;
  BuildingId?: Maybe<Scalars['uuid']>;
  ChannelId?: Maybe<Scalars['uuid']>;
  Cost?: Maybe<Scalars['numeric']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DueDate?: Maybe<Scalars['timestamp']>;
  Emissions?: Maybe<Scalars['numeric']>;
  Label?: Maybe<Scalars['String']>;
  LoadId?: Maybe<Scalars['uuid']>;
  NotificationId?: Maybe<Scalars['uuid']>;
  Reviewer?: Maybe<Scalars['uuid']>;
  RootCause?: Maybe<Scalars['smallint']>;
  Status?: Maybe<Scalars['smallint']>;
  Type?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  Usage?: Maybe<Scalars['numeric']>;
  WindowEnd?: Maybe<Scalars['timestamp']>;
  WindowStart?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "dw.Notifications" */
export type Dw_Notifications_Min_Order_By = {
  AccountId?: Maybe<Order_By>;
  Assessment?: Maybe<Order_By>;
  Assignee?: Maybe<Order_By>;
  BuildingId?: Maybe<Order_By>;
  ChannelId?: Maybe<Order_By>;
  Cost?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  DueDate?: Maybe<Order_By>;
  Emissions?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  LoadId?: Maybe<Order_By>;
  NotificationId?: Maybe<Order_By>;
  Reviewer?: Maybe<Order_By>;
  RootCause?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
  Usage?: Maybe<Order_By>;
  WindowEnd?: Maybe<Order_By>;
  WindowStart?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.Notifications" */
export type Dw_Notifications_Mutation_Response = {
  __typename?: 'dw_Notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_Notifications>;
};

/** input type for inserting object relation for remote table "dw.Notifications" */
export type Dw_Notifications_Obj_Rel_Insert_Input = {
  data: Dw_Notifications_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_Notifications_On_Conflict>;
};

/** on conflict condition type for table "dw.Notifications" */
export type Dw_Notifications_On_Conflict = {
  constraint: Dw_Notifications_Constraint;
  update_columns?: Array<Dw_Notifications_Update_Column>;
  where?: Maybe<Dw_Notifications_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.Notifications". */
export type Dw_Notifications_Order_By = {
  Account?: Maybe<Dw_Accounts_Order_By>;
  AccountId?: Maybe<Order_By>;
  Assessment?: Maybe<Order_By>;
  Assignee?: Maybe<Order_By>;
  Building?: Maybe<Dw_Buildings_Order_By>;
  BuildingId?: Maybe<Order_By>;
  Channel?: Maybe<Dw_Channels_Order_By>;
  ChannelId?: Maybe<Order_By>;
  Contact?: Maybe<Dw_Contacts_Order_By>;
  Cost?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  DueDate?: Maybe<Order_By>;
  Emissions?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  Load?: Maybe<Dw_Loads_Order_By>;
  LoadId?: Maybe<Order_By>;
  NotificationId?: Maybe<Order_By>;
  NotificationsEvents_aggregate?: Maybe<Dw_NotificationsEvent_Aggregate_Order_By>;
  NotificationsLogs_aggregate?: Maybe<Dw_NotificationsLog_Aggregate_Order_By>;
  NotificationsVisibilities_aggregate?: Maybe<Dw_NotificationsVisibility_Aggregate_Order_By>;
  Reviewer?: Maybe<Order_By>;
  RootCause?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
  Usage?: Maybe<Order_By>;
  WindowEnd?: Maybe<Order_By>;
  WindowStart?: Maybe<Order_By>;
  contactByReviewer?: Maybe<Dw_Contacts_Order_By>;
};

/** primary key columns input for table: dw_Notifications */
export type Dw_Notifications_Pk_Columns_Input = {
  NotificationId: Scalars['uuid'];
};

/** select columns of table "dw.Notifications" */
export enum Dw_Notifications_Select_Column {
  /** column name */
  AccountId = 'AccountId',
  /** column name */
  Assessment = 'Assessment',
  /** column name */
  Assignee = 'Assignee',
  /** column name */
  BuildingId = 'BuildingId',
  /** column name */
  ChannelId = 'ChannelId',
  /** column name */
  Cost = 'Cost',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  DueDate = 'DueDate',
  /** column name */
  Emissions = 'Emissions',
  /** column name */
  Label = 'Label',
  /** column name */
  LoadId = 'LoadId',
  /** column name */
  NotificationId = 'NotificationId',
  /** column name */
  Reviewer = 'Reviewer',
  /** column name */
  RootCause = 'RootCause',
  /** column name */
  Status = 'Status',
  /** column name */
  Type = 'Type',
  /** column name */
  UpdatedOn = 'UpdatedOn',
  /** column name */
  Usage = 'Usage',
  /** column name */
  WindowEnd = 'WindowEnd',
  /** column name */
  WindowStart = 'WindowStart'
}

/** input type for updating data in table "dw.Notifications" */
export type Dw_Notifications_Set_Input = {
  AccountId?: Maybe<Scalars['uuid']>;
  Assessment?: Maybe<Scalars['String']>;
  Assignee?: Maybe<Scalars['uuid']>;
  BuildingId?: Maybe<Scalars['uuid']>;
  ChannelId?: Maybe<Scalars['uuid']>;
  Cost?: Maybe<Scalars['numeric']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  DueDate?: Maybe<Scalars['timestamp']>;
  Emissions?: Maybe<Scalars['numeric']>;
  Label?: Maybe<Scalars['String']>;
  LoadId?: Maybe<Scalars['uuid']>;
  NotificationId?: Maybe<Scalars['uuid']>;
  Reviewer?: Maybe<Scalars['uuid']>;
  RootCause?: Maybe<Scalars['smallint']>;
  Status?: Maybe<Scalars['smallint']>;
  Type?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  Usage?: Maybe<Scalars['numeric']>;
  WindowEnd?: Maybe<Scalars['timestamp']>;
  WindowStart?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Dw_Notifications_Stddev_Fields = {
  __typename?: 'dw_Notifications_stddev_fields';
  Cost?: Maybe<Scalars['Float']>;
  Emissions?: Maybe<Scalars['Float']>;
  RootCause?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  Usage?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.Notifications" */
export type Dw_Notifications_Stddev_Order_By = {
  Cost?: Maybe<Order_By>;
  Emissions?: Maybe<Order_By>;
  RootCause?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Usage?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_Notifications_Stddev_Pop_Fields = {
  __typename?: 'dw_Notifications_stddev_pop_fields';
  Cost?: Maybe<Scalars['Float']>;
  Emissions?: Maybe<Scalars['Float']>;
  RootCause?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  Usage?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.Notifications" */
export type Dw_Notifications_Stddev_Pop_Order_By = {
  Cost?: Maybe<Order_By>;
  Emissions?: Maybe<Order_By>;
  RootCause?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Usage?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_Notifications_Stddev_Samp_Fields = {
  __typename?: 'dw_Notifications_stddev_samp_fields';
  Cost?: Maybe<Scalars['Float']>;
  Emissions?: Maybe<Scalars['Float']>;
  RootCause?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  Usage?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.Notifications" */
export type Dw_Notifications_Stddev_Samp_Order_By = {
  Cost?: Maybe<Order_By>;
  Emissions?: Maybe<Order_By>;
  RootCause?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Usage?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_Notifications_Sum_Fields = {
  __typename?: 'dw_Notifications_sum_fields';
  Cost?: Maybe<Scalars['numeric']>;
  Emissions?: Maybe<Scalars['numeric']>;
  RootCause?: Maybe<Scalars['smallint']>;
  Status?: Maybe<Scalars['smallint']>;
  Type?: Maybe<Scalars['smallint']>;
  Usage?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "dw.Notifications" */
export type Dw_Notifications_Sum_Order_By = {
  Cost?: Maybe<Order_By>;
  Emissions?: Maybe<Order_By>;
  RootCause?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Usage?: Maybe<Order_By>;
};

/** update columns of table "dw.Notifications" */
export enum Dw_Notifications_Update_Column {
  /** column name */
  AccountId = 'AccountId',
  /** column name */
  Assessment = 'Assessment',
  /** column name */
  Assignee = 'Assignee',
  /** column name */
  BuildingId = 'BuildingId',
  /** column name */
  ChannelId = 'ChannelId',
  /** column name */
  Cost = 'Cost',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  DueDate = 'DueDate',
  /** column name */
  Emissions = 'Emissions',
  /** column name */
  Label = 'Label',
  /** column name */
  LoadId = 'LoadId',
  /** column name */
  NotificationId = 'NotificationId',
  /** column name */
  Reviewer = 'Reviewer',
  /** column name */
  RootCause = 'RootCause',
  /** column name */
  Status = 'Status',
  /** column name */
  Type = 'Type',
  /** column name */
  UpdatedOn = 'UpdatedOn',
  /** column name */
  Usage = 'Usage',
  /** column name */
  WindowEnd = 'WindowEnd',
  /** column name */
  WindowStart = 'WindowStart'
}

/** aggregate var_pop on columns */
export type Dw_Notifications_Var_Pop_Fields = {
  __typename?: 'dw_Notifications_var_pop_fields';
  Cost?: Maybe<Scalars['Float']>;
  Emissions?: Maybe<Scalars['Float']>;
  RootCause?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  Usage?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.Notifications" */
export type Dw_Notifications_Var_Pop_Order_By = {
  Cost?: Maybe<Order_By>;
  Emissions?: Maybe<Order_By>;
  RootCause?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Usage?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_Notifications_Var_Samp_Fields = {
  __typename?: 'dw_Notifications_var_samp_fields';
  Cost?: Maybe<Scalars['Float']>;
  Emissions?: Maybe<Scalars['Float']>;
  RootCause?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  Usage?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.Notifications" */
export type Dw_Notifications_Var_Samp_Order_By = {
  Cost?: Maybe<Order_By>;
  Emissions?: Maybe<Order_By>;
  RootCause?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Usage?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_Notifications_Variance_Fields = {
  __typename?: 'dw_Notifications_variance_fields';
  Cost?: Maybe<Scalars['Float']>;
  Emissions?: Maybe<Scalars['Float']>;
  RootCause?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
  Usage?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.Notifications" */
export type Dw_Notifications_Variance_Order_By = {
  Cost?: Maybe<Order_By>;
  Emissions?: Maybe<Order_By>;
  RootCause?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  Usage?: Maybe<Order_By>;
};

/** columns and relationships of "dw.Plans" */
export type Dw_Plans = {
  __typename?: 'dw_Plans';
  Amount: Scalars['numeric'];
  BillingMethod: Scalars['smallint'];
  CreatedOn: Scalars['timestamp'];
  Description: Scalars['String'];
  Label: Scalars['String'];
  PlanId: Scalars['uuid'];
  Type: Scalars['smallint'];
  UpdatedOn: Scalars['timestamp'];
  XeroAccCode?: Maybe<Scalars['String']>;
  XeroReference?: Maybe<Scalars['String']>;
  XeroTaxType?: Maybe<Scalars['String']>;
};

/** aggregated selection of "dw.Plans" */
export type Dw_Plans_Aggregate = {
  __typename?: 'dw_Plans_aggregate';
  aggregate?: Maybe<Dw_Plans_Aggregate_Fields>;
  nodes: Array<Dw_Plans>;
};

/** aggregate fields of "dw.Plans" */
export type Dw_Plans_Aggregate_Fields = {
  __typename?: 'dw_Plans_aggregate_fields';
  avg?: Maybe<Dw_Plans_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_Plans_Max_Fields>;
  min?: Maybe<Dw_Plans_Min_Fields>;
  stddev?: Maybe<Dw_Plans_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_Plans_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_Plans_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_Plans_Sum_Fields>;
  var_pop?: Maybe<Dw_Plans_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_Plans_Var_Samp_Fields>;
  variance?: Maybe<Dw_Plans_Variance_Fields>;
};


/** aggregate fields of "dw.Plans" */
export type Dw_Plans_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_Plans_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dw_Plans_Avg_Fields = {
  __typename?: 'dw_Plans_avg_fields';
  Amount?: Maybe<Scalars['Float']>;
  BillingMethod?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dw.Plans". All fields are combined with a logical 'AND'. */
export type Dw_Plans_Bool_Exp = {
  Amount?: Maybe<Numeric_Comparison_Exp>;
  BillingMethod?: Maybe<Smallint_Comparison_Exp>;
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  Label?: Maybe<String_Comparison_Exp>;
  PlanId?: Maybe<Uuid_Comparison_Exp>;
  Type?: Maybe<Smallint_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  XeroAccCode?: Maybe<String_Comparison_Exp>;
  XeroReference?: Maybe<String_Comparison_Exp>;
  XeroTaxType?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Dw_Plans_Bool_Exp>>;
  _not?: Maybe<Dw_Plans_Bool_Exp>;
  _or?: Maybe<Array<Dw_Plans_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.Plans" */
export enum Dw_Plans_Constraint {
  /** unique or primary key constraint */
  PlansPk = 'plans_pk',
  /** unique or primary key constraint */
  PlansPlanidUindex = 'plans_planid_uindex'
}

/** input type for incrementing numeric columns in table "dw.Plans" */
export type Dw_Plans_Inc_Input = {
  Amount?: Maybe<Scalars['numeric']>;
  BillingMethod?: Maybe<Scalars['smallint']>;
  Type?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.Plans" */
export type Dw_Plans_Insert_Input = {
  Amount?: Maybe<Scalars['numeric']>;
  BillingMethod?: Maybe<Scalars['smallint']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  Description?: Maybe<Scalars['String']>;
  Label?: Maybe<Scalars['String']>;
  PlanId?: Maybe<Scalars['uuid']>;
  Type?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  XeroAccCode?: Maybe<Scalars['String']>;
  XeroReference?: Maybe<Scalars['String']>;
  XeroTaxType?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Dw_Plans_Max_Fields = {
  __typename?: 'dw_Plans_max_fields';
  Amount?: Maybe<Scalars['numeric']>;
  BillingMethod?: Maybe<Scalars['smallint']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  Description?: Maybe<Scalars['String']>;
  Label?: Maybe<Scalars['String']>;
  PlanId?: Maybe<Scalars['uuid']>;
  Type?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  XeroAccCode?: Maybe<Scalars['String']>;
  XeroReference?: Maybe<Scalars['String']>;
  XeroTaxType?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Dw_Plans_Min_Fields = {
  __typename?: 'dw_Plans_min_fields';
  Amount?: Maybe<Scalars['numeric']>;
  BillingMethod?: Maybe<Scalars['smallint']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  Description?: Maybe<Scalars['String']>;
  Label?: Maybe<Scalars['String']>;
  PlanId?: Maybe<Scalars['uuid']>;
  Type?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  XeroAccCode?: Maybe<Scalars['String']>;
  XeroReference?: Maybe<Scalars['String']>;
  XeroTaxType?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "dw.Plans" */
export type Dw_Plans_Mutation_Response = {
  __typename?: 'dw_Plans_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_Plans>;
};

/** input type for inserting object relation for remote table "dw.Plans" */
export type Dw_Plans_Obj_Rel_Insert_Input = {
  data: Dw_Plans_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_Plans_On_Conflict>;
};

/** on conflict condition type for table "dw.Plans" */
export type Dw_Plans_On_Conflict = {
  constraint: Dw_Plans_Constraint;
  update_columns?: Array<Dw_Plans_Update_Column>;
  where?: Maybe<Dw_Plans_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.Plans". */
export type Dw_Plans_Order_By = {
  Amount?: Maybe<Order_By>;
  BillingMethod?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  PlanId?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
  XeroAccCode?: Maybe<Order_By>;
  XeroReference?: Maybe<Order_By>;
  XeroTaxType?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_Plans */
export type Dw_Plans_Pk_Columns_Input = {
  PlanId: Scalars['uuid'];
};

/** select columns of table "dw.Plans" */
export enum Dw_Plans_Select_Column {
  /** column name */
  Amount = 'Amount',
  /** column name */
  BillingMethod = 'BillingMethod',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Description = 'Description',
  /** column name */
  Label = 'Label',
  /** column name */
  PlanId = 'PlanId',
  /** column name */
  Type = 'Type',
  /** column name */
  UpdatedOn = 'UpdatedOn',
  /** column name */
  XeroAccCode = 'XeroAccCode',
  /** column name */
  XeroReference = 'XeroReference',
  /** column name */
  XeroTaxType = 'XeroTaxType'
}

/** input type for updating data in table "dw.Plans" */
export type Dw_Plans_Set_Input = {
  Amount?: Maybe<Scalars['numeric']>;
  BillingMethod?: Maybe<Scalars['smallint']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  Description?: Maybe<Scalars['String']>;
  Label?: Maybe<Scalars['String']>;
  PlanId?: Maybe<Scalars['uuid']>;
  Type?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  XeroAccCode?: Maybe<Scalars['String']>;
  XeroReference?: Maybe<Scalars['String']>;
  XeroTaxType?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Dw_Plans_Stddev_Fields = {
  __typename?: 'dw_Plans_stddev_fields';
  Amount?: Maybe<Scalars['Float']>;
  BillingMethod?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dw_Plans_Stddev_Pop_Fields = {
  __typename?: 'dw_Plans_stddev_pop_fields';
  Amount?: Maybe<Scalars['Float']>;
  BillingMethod?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dw_Plans_Stddev_Samp_Fields = {
  __typename?: 'dw_Plans_stddev_samp_fields';
  Amount?: Maybe<Scalars['Float']>;
  BillingMethod?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Dw_Plans_Sum_Fields = {
  __typename?: 'dw_Plans_sum_fields';
  Amount?: Maybe<Scalars['numeric']>;
  BillingMethod?: Maybe<Scalars['smallint']>;
  Type?: Maybe<Scalars['smallint']>;
};

/** update columns of table "dw.Plans" */
export enum Dw_Plans_Update_Column {
  /** column name */
  Amount = 'Amount',
  /** column name */
  BillingMethod = 'BillingMethod',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Description = 'Description',
  /** column name */
  Label = 'Label',
  /** column name */
  PlanId = 'PlanId',
  /** column name */
  Type = 'Type',
  /** column name */
  UpdatedOn = 'UpdatedOn',
  /** column name */
  XeroAccCode = 'XeroAccCode',
  /** column name */
  XeroReference = 'XeroReference',
  /** column name */
  XeroTaxType = 'XeroTaxType'
}

/** aggregate var_pop on columns */
export type Dw_Plans_Var_Pop_Fields = {
  __typename?: 'dw_Plans_var_pop_fields';
  Amount?: Maybe<Scalars['Float']>;
  BillingMethod?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dw_Plans_Var_Samp_Fields = {
  __typename?: 'dw_Plans_var_samp_fields';
  Amount?: Maybe<Scalars['Float']>;
  BillingMethod?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dw_Plans_Variance_Fields = {
  __typename?: 'dw_Plans_variance_fields';
  Amount?: Maybe<Scalars['Float']>;
  BillingMethod?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "dw.Projects" */
export type Dw_Projects = {
  __typename?: 'dw_Projects';
  /** An object relationship */
  Account: Dw_Accounts;
  AccountId: Scalars['uuid'];
  AnnualAdditionalSavings: Scalars['numeric'];
  AnnualKwhSavings: Scalars['numeric'];
  AnnualSavings: Scalars['numeric'];
  AssigneeContactId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  Building?: Maybe<Dw_Buildings>;
  BuildingId?: Maybe<Scalars['uuid']>;
  Comments: Scalars['String'];
  /** An object relationship */
  Contact?: Maybe<Dw_Contacts>;
  Cost: Scalars['numeric'];
  CostThusFar: Scalars['numeric'];
  CreatedOn: Scalars['timestamp'];
  CumulativeKwhSavings: Scalars['numeric'];
  Description: Scalars['String'];
  ImplementationDate?: Maybe<Scalars['timestamp']>;
  Label: Scalars['String'];
  /** An object relationship */
  Load?: Maybe<Dw_Loads>;
  LoadId?: Maybe<Scalars['uuid']>;
  NotifiedOn: Scalars['timestamp'];
  Priority?: Maybe<Scalars['smallint']>;
  ProjectId: Scalars['uuid'];
  ReporterContactId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  Site?: Maybe<Dw_Sites>;
  SiteId?: Maybe<Scalars['uuid']>;
  StartDate?: Maybe<Scalars['timestamp']>;
  Status: Scalars['smallint'];
  UnitCost: Scalars['numeric'];
  UpdatedOn: Scalars['timestamp'];
  /** An object relationship */
  contactByAssigneecontactid?: Maybe<Dw_Contacts>;
};

/** aggregated selection of "dw.Projects" */
export type Dw_Projects_Aggregate = {
  __typename?: 'dw_Projects_aggregate';
  aggregate?: Maybe<Dw_Projects_Aggregate_Fields>;
  nodes: Array<Dw_Projects>;
};

/** aggregate fields of "dw.Projects" */
export type Dw_Projects_Aggregate_Fields = {
  __typename?: 'dw_Projects_aggregate_fields';
  avg?: Maybe<Dw_Projects_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_Projects_Max_Fields>;
  min?: Maybe<Dw_Projects_Min_Fields>;
  stddev?: Maybe<Dw_Projects_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_Projects_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_Projects_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_Projects_Sum_Fields>;
  var_pop?: Maybe<Dw_Projects_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_Projects_Var_Samp_Fields>;
  variance?: Maybe<Dw_Projects_Variance_Fields>;
};


/** aggregate fields of "dw.Projects" */
export type Dw_Projects_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_Projects_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.Projects" */
export type Dw_Projects_Aggregate_Order_By = {
  avg?: Maybe<Dw_Projects_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_Projects_Max_Order_By>;
  min?: Maybe<Dw_Projects_Min_Order_By>;
  stddev?: Maybe<Dw_Projects_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_Projects_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_Projects_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_Projects_Sum_Order_By>;
  var_pop?: Maybe<Dw_Projects_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_Projects_Var_Samp_Order_By>;
  variance?: Maybe<Dw_Projects_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.Projects" */
export type Dw_Projects_Arr_Rel_Insert_Input = {
  data: Array<Dw_Projects_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_Projects_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_Projects_Avg_Fields = {
  __typename?: 'dw_Projects_avg_fields';
  AnnualAdditionalSavings?: Maybe<Scalars['Float']>;
  AnnualKwhSavings?: Maybe<Scalars['Float']>;
  AnnualSavings?: Maybe<Scalars['Float']>;
  Cost?: Maybe<Scalars['Float']>;
  CostThusFar?: Maybe<Scalars['Float']>;
  CumulativeKwhSavings?: Maybe<Scalars['Float']>;
  Priority?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.Projects" */
export type Dw_Projects_Avg_Order_By = {
  AnnualAdditionalSavings?: Maybe<Order_By>;
  AnnualKwhSavings?: Maybe<Order_By>;
  AnnualSavings?: Maybe<Order_By>;
  Cost?: Maybe<Order_By>;
  CostThusFar?: Maybe<Order_By>;
  CumulativeKwhSavings?: Maybe<Order_By>;
  Priority?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UnitCost?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.Projects". All fields are combined with a logical 'AND'. */
export type Dw_Projects_Bool_Exp = {
  Account?: Maybe<Dw_Accounts_Bool_Exp>;
  AccountId?: Maybe<Uuid_Comparison_Exp>;
  AnnualAdditionalSavings?: Maybe<Numeric_Comparison_Exp>;
  AnnualKwhSavings?: Maybe<Numeric_Comparison_Exp>;
  AnnualSavings?: Maybe<Numeric_Comparison_Exp>;
  AssigneeContactId?: Maybe<Uuid_Comparison_Exp>;
  Building?: Maybe<Dw_Buildings_Bool_Exp>;
  BuildingId?: Maybe<Uuid_Comparison_Exp>;
  Comments?: Maybe<String_Comparison_Exp>;
  Contact?: Maybe<Dw_Contacts_Bool_Exp>;
  Cost?: Maybe<Numeric_Comparison_Exp>;
  CostThusFar?: Maybe<Numeric_Comparison_Exp>;
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  CumulativeKwhSavings?: Maybe<Numeric_Comparison_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  ImplementationDate?: Maybe<Timestamp_Comparison_Exp>;
  Label?: Maybe<String_Comparison_Exp>;
  Load?: Maybe<Dw_Loads_Bool_Exp>;
  LoadId?: Maybe<Uuid_Comparison_Exp>;
  NotifiedOn?: Maybe<Timestamp_Comparison_Exp>;
  Priority?: Maybe<Smallint_Comparison_Exp>;
  ProjectId?: Maybe<Uuid_Comparison_Exp>;
  ReporterContactId?: Maybe<Uuid_Comparison_Exp>;
  Site?: Maybe<Dw_Sites_Bool_Exp>;
  SiteId?: Maybe<Uuid_Comparison_Exp>;
  StartDate?: Maybe<Timestamp_Comparison_Exp>;
  Status?: Maybe<Smallint_Comparison_Exp>;
  UnitCost?: Maybe<Numeric_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  _and?: Maybe<Array<Dw_Projects_Bool_Exp>>;
  _not?: Maybe<Dw_Projects_Bool_Exp>;
  _or?: Maybe<Array<Dw_Projects_Bool_Exp>>;
  contactByAssigneecontactid?: Maybe<Dw_Contacts_Bool_Exp>;
};

/** unique or primary key constraints on table "dw.Projects" */
export enum Dw_Projects_Constraint {
  /** unique or primary key constraint */
  ProjectsPk = 'projects_pk',
  /** unique or primary key constraint */
  ProjectsProjectidUindex = 'projects_projectid_uindex'
}

/** input type for incrementing numeric columns in table "dw.Projects" */
export type Dw_Projects_Inc_Input = {
  AnnualAdditionalSavings?: Maybe<Scalars['numeric']>;
  AnnualKwhSavings?: Maybe<Scalars['numeric']>;
  AnnualSavings?: Maybe<Scalars['numeric']>;
  Cost?: Maybe<Scalars['numeric']>;
  CostThusFar?: Maybe<Scalars['numeric']>;
  CumulativeKwhSavings?: Maybe<Scalars['numeric']>;
  Priority?: Maybe<Scalars['smallint']>;
  Status?: Maybe<Scalars['smallint']>;
  UnitCost?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "dw.Projects" */
export type Dw_Projects_Insert_Input = {
  Account?: Maybe<Dw_Accounts_Obj_Rel_Insert_Input>;
  AccountId?: Maybe<Scalars['uuid']>;
  AnnualAdditionalSavings?: Maybe<Scalars['numeric']>;
  AnnualKwhSavings?: Maybe<Scalars['numeric']>;
  AnnualSavings?: Maybe<Scalars['numeric']>;
  AssigneeContactId?: Maybe<Scalars['uuid']>;
  Building?: Maybe<Dw_Buildings_Obj_Rel_Insert_Input>;
  BuildingId?: Maybe<Scalars['uuid']>;
  Comments?: Maybe<Scalars['String']>;
  Contact?: Maybe<Dw_Contacts_Obj_Rel_Insert_Input>;
  Cost?: Maybe<Scalars['numeric']>;
  CostThusFar?: Maybe<Scalars['numeric']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  CumulativeKwhSavings?: Maybe<Scalars['numeric']>;
  Description?: Maybe<Scalars['String']>;
  ImplementationDate?: Maybe<Scalars['timestamp']>;
  Label?: Maybe<Scalars['String']>;
  Load?: Maybe<Dw_Loads_Obj_Rel_Insert_Input>;
  LoadId?: Maybe<Scalars['uuid']>;
  NotifiedOn?: Maybe<Scalars['timestamp']>;
  Priority?: Maybe<Scalars['smallint']>;
  ProjectId?: Maybe<Scalars['uuid']>;
  ReporterContactId?: Maybe<Scalars['uuid']>;
  Site?: Maybe<Dw_Sites_Obj_Rel_Insert_Input>;
  SiteId?: Maybe<Scalars['uuid']>;
  StartDate?: Maybe<Scalars['timestamp']>;
  Status?: Maybe<Scalars['smallint']>;
  UnitCost?: Maybe<Scalars['numeric']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  contactByAssigneecontactid?: Maybe<Dw_Contacts_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Dw_Projects_Max_Fields = {
  __typename?: 'dw_Projects_max_fields';
  AccountId?: Maybe<Scalars['uuid']>;
  AnnualAdditionalSavings?: Maybe<Scalars['numeric']>;
  AnnualKwhSavings?: Maybe<Scalars['numeric']>;
  AnnualSavings?: Maybe<Scalars['numeric']>;
  AssigneeContactId?: Maybe<Scalars['uuid']>;
  BuildingId?: Maybe<Scalars['uuid']>;
  Comments?: Maybe<Scalars['String']>;
  Cost?: Maybe<Scalars['numeric']>;
  CostThusFar?: Maybe<Scalars['numeric']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  CumulativeKwhSavings?: Maybe<Scalars['numeric']>;
  Description?: Maybe<Scalars['String']>;
  ImplementationDate?: Maybe<Scalars['timestamp']>;
  Label?: Maybe<Scalars['String']>;
  LoadId?: Maybe<Scalars['uuid']>;
  NotifiedOn?: Maybe<Scalars['timestamp']>;
  Priority?: Maybe<Scalars['smallint']>;
  ProjectId?: Maybe<Scalars['uuid']>;
  ReporterContactId?: Maybe<Scalars['uuid']>;
  SiteId?: Maybe<Scalars['uuid']>;
  StartDate?: Maybe<Scalars['timestamp']>;
  Status?: Maybe<Scalars['smallint']>;
  UnitCost?: Maybe<Scalars['numeric']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "dw.Projects" */
export type Dw_Projects_Max_Order_By = {
  AccountId?: Maybe<Order_By>;
  AnnualAdditionalSavings?: Maybe<Order_By>;
  AnnualKwhSavings?: Maybe<Order_By>;
  AnnualSavings?: Maybe<Order_By>;
  AssigneeContactId?: Maybe<Order_By>;
  BuildingId?: Maybe<Order_By>;
  Comments?: Maybe<Order_By>;
  Cost?: Maybe<Order_By>;
  CostThusFar?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  CumulativeKwhSavings?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  ImplementationDate?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  LoadId?: Maybe<Order_By>;
  NotifiedOn?: Maybe<Order_By>;
  Priority?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  ReporterContactId?: Maybe<Order_By>;
  SiteId?: Maybe<Order_By>;
  StartDate?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UnitCost?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_Projects_Min_Fields = {
  __typename?: 'dw_Projects_min_fields';
  AccountId?: Maybe<Scalars['uuid']>;
  AnnualAdditionalSavings?: Maybe<Scalars['numeric']>;
  AnnualKwhSavings?: Maybe<Scalars['numeric']>;
  AnnualSavings?: Maybe<Scalars['numeric']>;
  AssigneeContactId?: Maybe<Scalars['uuid']>;
  BuildingId?: Maybe<Scalars['uuid']>;
  Comments?: Maybe<Scalars['String']>;
  Cost?: Maybe<Scalars['numeric']>;
  CostThusFar?: Maybe<Scalars['numeric']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  CumulativeKwhSavings?: Maybe<Scalars['numeric']>;
  Description?: Maybe<Scalars['String']>;
  ImplementationDate?: Maybe<Scalars['timestamp']>;
  Label?: Maybe<Scalars['String']>;
  LoadId?: Maybe<Scalars['uuid']>;
  NotifiedOn?: Maybe<Scalars['timestamp']>;
  Priority?: Maybe<Scalars['smallint']>;
  ProjectId?: Maybe<Scalars['uuid']>;
  ReporterContactId?: Maybe<Scalars['uuid']>;
  SiteId?: Maybe<Scalars['uuid']>;
  StartDate?: Maybe<Scalars['timestamp']>;
  Status?: Maybe<Scalars['smallint']>;
  UnitCost?: Maybe<Scalars['numeric']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "dw.Projects" */
export type Dw_Projects_Min_Order_By = {
  AccountId?: Maybe<Order_By>;
  AnnualAdditionalSavings?: Maybe<Order_By>;
  AnnualKwhSavings?: Maybe<Order_By>;
  AnnualSavings?: Maybe<Order_By>;
  AssigneeContactId?: Maybe<Order_By>;
  BuildingId?: Maybe<Order_By>;
  Comments?: Maybe<Order_By>;
  Cost?: Maybe<Order_By>;
  CostThusFar?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  CumulativeKwhSavings?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  ImplementationDate?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  LoadId?: Maybe<Order_By>;
  NotifiedOn?: Maybe<Order_By>;
  Priority?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  ReporterContactId?: Maybe<Order_By>;
  SiteId?: Maybe<Order_By>;
  StartDate?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UnitCost?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.Projects" */
export type Dw_Projects_Mutation_Response = {
  __typename?: 'dw_Projects_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_Projects>;
};

/** on conflict condition type for table "dw.Projects" */
export type Dw_Projects_On_Conflict = {
  constraint: Dw_Projects_Constraint;
  update_columns?: Array<Dw_Projects_Update_Column>;
  where?: Maybe<Dw_Projects_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.Projects". */
export type Dw_Projects_Order_By = {
  Account?: Maybe<Dw_Accounts_Order_By>;
  AccountId?: Maybe<Order_By>;
  AnnualAdditionalSavings?: Maybe<Order_By>;
  AnnualKwhSavings?: Maybe<Order_By>;
  AnnualSavings?: Maybe<Order_By>;
  AssigneeContactId?: Maybe<Order_By>;
  Building?: Maybe<Dw_Buildings_Order_By>;
  BuildingId?: Maybe<Order_By>;
  Comments?: Maybe<Order_By>;
  Contact?: Maybe<Dw_Contacts_Order_By>;
  Cost?: Maybe<Order_By>;
  CostThusFar?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  CumulativeKwhSavings?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  ImplementationDate?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  Load?: Maybe<Dw_Loads_Order_By>;
  LoadId?: Maybe<Order_By>;
  NotifiedOn?: Maybe<Order_By>;
  Priority?: Maybe<Order_By>;
  ProjectId?: Maybe<Order_By>;
  ReporterContactId?: Maybe<Order_By>;
  Site?: Maybe<Dw_Sites_Order_By>;
  SiteId?: Maybe<Order_By>;
  StartDate?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UnitCost?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
  contactByAssigneecontactid?: Maybe<Dw_Contacts_Order_By>;
};

/** primary key columns input for table: dw_Projects */
export type Dw_Projects_Pk_Columns_Input = {
  ProjectId: Scalars['uuid'];
};

/** select columns of table "dw.Projects" */
export enum Dw_Projects_Select_Column {
  /** column name */
  AccountId = 'AccountId',
  /** column name */
  AnnualAdditionalSavings = 'AnnualAdditionalSavings',
  /** column name */
  AnnualKwhSavings = 'AnnualKwhSavings',
  /** column name */
  AnnualSavings = 'AnnualSavings',
  /** column name */
  AssigneeContactId = 'AssigneeContactId',
  /** column name */
  BuildingId = 'BuildingId',
  /** column name */
  Comments = 'Comments',
  /** column name */
  Cost = 'Cost',
  /** column name */
  CostThusFar = 'CostThusFar',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  CumulativeKwhSavings = 'CumulativeKwhSavings',
  /** column name */
  Description = 'Description',
  /** column name */
  ImplementationDate = 'ImplementationDate',
  /** column name */
  Label = 'Label',
  /** column name */
  LoadId = 'LoadId',
  /** column name */
  NotifiedOn = 'NotifiedOn',
  /** column name */
  Priority = 'Priority',
  /** column name */
  ProjectId = 'ProjectId',
  /** column name */
  ReporterContactId = 'ReporterContactId',
  /** column name */
  SiteId = 'SiteId',
  /** column name */
  StartDate = 'StartDate',
  /** column name */
  Status = 'Status',
  /** column name */
  UnitCost = 'UnitCost',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** input type for updating data in table "dw.Projects" */
export type Dw_Projects_Set_Input = {
  AccountId?: Maybe<Scalars['uuid']>;
  AnnualAdditionalSavings?: Maybe<Scalars['numeric']>;
  AnnualKwhSavings?: Maybe<Scalars['numeric']>;
  AnnualSavings?: Maybe<Scalars['numeric']>;
  AssigneeContactId?: Maybe<Scalars['uuid']>;
  BuildingId?: Maybe<Scalars['uuid']>;
  Comments?: Maybe<Scalars['String']>;
  Cost?: Maybe<Scalars['numeric']>;
  CostThusFar?: Maybe<Scalars['numeric']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  CumulativeKwhSavings?: Maybe<Scalars['numeric']>;
  Description?: Maybe<Scalars['String']>;
  ImplementationDate?: Maybe<Scalars['timestamp']>;
  Label?: Maybe<Scalars['String']>;
  LoadId?: Maybe<Scalars['uuid']>;
  NotifiedOn?: Maybe<Scalars['timestamp']>;
  Priority?: Maybe<Scalars['smallint']>;
  ProjectId?: Maybe<Scalars['uuid']>;
  ReporterContactId?: Maybe<Scalars['uuid']>;
  SiteId?: Maybe<Scalars['uuid']>;
  StartDate?: Maybe<Scalars['timestamp']>;
  Status?: Maybe<Scalars['smallint']>;
  UnitCost?: Maybe<Scalars['numeric']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Dw_Projects_Stddev_Fields = {
  __typename?: 'dw_Projects_stddev_fields';
  AnnualAdditionalSavings?: Maybe<Scalars['Float']>;
  AnnualKwhSavings?: Maybe<Scalars['Float']>;
  AnnualSavings?: Maybe<Scalars['Float']>;
  Cost?: Maybe<Scalars['Float']>;
  CostThusFar?: Maybe<Scalars['Float']>;
  CumulativeKwhSavings?: Maybe<Scalars['Float']>;
  Priority?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.Projects" */
export type Dw_Projects_Stddev_Order_By = {
  AnnualAdditionalSavings?: Maybe<Order_By>;
  AnnualKwhSavings?: Maybe<Order_By>;
  AnnualSavings?: Maybe<Order_By>;
  Cost?: Maybe<Order_By>;
  CostThusFar?: Maybe<Order_By>;
  CumulativeKwhSavings?: Maybe<Order_By>;
  Priority?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UnitCost?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_Projects_Stddev_Pop_Fields = {
  __typename?: 'dw_Projects_stddev_pop_fields';
  AnnualAdditionalSavings?: Maybe<Scalars['Float']>;
  AnnualKwhSavings?: Maybe<Scalars['Float']>;
  AnnualSavings?: Maybe<Scalars['Float']>;
  Cost?: Maybe<Scalars['Float']>;
  CostThusFar?: Maybe<Scalars['Float']>;
  CumulativeKwhSavings?: Maybe<Scalars['Float']>;
  Priority?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.Projects" */
export type Dw_Projects_Stddev_Pop_Order_By = {
  AnnualAdditionalSavings?: Maybe<Order_By>;
  AnnualKwhSavings?: Maybe<Order_By>;
  AnnualSavings?: Maybe<Order_By>;
  Cost?: Maybe<Order_By>;
  CostThusFar?: Maybe<Order_By>;
  CumulativeKwhSavings?: Maybe<Order_By>;
  Priority?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UnitCost?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_Projects_Stddev_Samp_Fields = {
  __typename?: 'dw_Projects_stddev_samp_fields';
  AnnualAdditionalSavings?: Maybe<Scalars['Float']>;
  AnnualKwhSavings?: Maybe<Scalars['Float']>;
  AnnualSavings?: Maybe<Scalars['Float']>;
  Cost?: Maybe<Scalars['Float']>;
  CostThusFar?: Maybe<Scalars['Float']>;
  CumulativeKwhSavings?: Maybe<Scalars['Float']>;
  Priority?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.Projects" */
export type Dw_Projects_Stddev_Samp_Order_By = {
  AnnualAdditionalSavings?: Maybe<Order_By>;
  AnnualKwhSavings?: Maybe<Order_By>;
  AnnualSavings?: Maybe<Order_By>;
  Cost?: Maybe<Order_By>;
  CostThusFar?: Maybe<Order_By>;
  CumulativeKwhSavings?: Maybe<Order_By>;
  Priority?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UnitCost?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_Projects_Sum_Fields = {
  __typename?: 'dw_Projects_sum_fields';
  AnnualAdditionalSavings?: Maybe<Scalars['numeric']>;
  AnnualKwhSavings?: Maybe<Scalars['numeric']>;
  AnnualSavings?: Maybe<Scalars['numeric']>;
  Cost?: Maybe<Scalars['numeric']>;
  CostThusFar?: Maybe<Scalars['numeric']>;
  CumulativeKwhSavings?: Maybe<Scalars['numeric']>;
  Priority?: Maybe<Scalars['smallint']>;
  Status?: Maybe<Scalars['smallint']>;
  UnitCost?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "dw.Projects" */
export type Dw_Projects_Sum_Order_By = {
  AnnualAdditionalSavings?: Maybe<Order_By>;
  AnnualKwhSavings?: Maybe<Order_By>;
  AnnualSavings?: Maybe<Order_By>;
  Cost?: Maybe<Order_By>;
  CostThusFar?: Maybe<Order_By>;
  CumulativeKwhSavings?: Maybe<Order_By>;
  Priority?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UnitCost?: Maybe<Order_By>;
};

/** update columns of table "dw.Projects" */
export enum Dw_Projects_Update_Column {
  /** column name */
  AccountId = 'AccountId',
  /** column name */
  AnnualAdditionalSavings = 'AnnualAdditionalSavings',
  /** column name */
  AnnualKwhSavings = 'AnnualKwhSavings',
  /** column name */
  AnnualSavings = 'AnnualSavings',
  /** column name */
  AssigneeContactId = 'AssigneeContactId',
  /** column name */
  BuildingId = 'BuildingId',
  /** column name */
  Comments = 'Comments',
  /** column name */
  Cost = 'Cost',
  /** column name */
  CostThusFar = 'CostThusFar',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  CumulativeKwhSavings = 'CumulativeKwhSavings',
  /** column name */
  Description = 'Description',
  /** column name */
  ImplementationDate = 'ImplementationDate',
  /** column name */
  Label = 'Label',
  /** column name */
  LoadId = 'LoadId',
  /** column name */
  NotifiedOn = 'NotifiedOn',
  /** column name */
  Priority = 'Priority',
  /** column name */
  ProjectId = 'ProjectId',
  /** column name */
  ReporterContactId = 'ReporterContactId',
  /** column name */
  SiteId = 'SiteId',
  /** column name */
  StartDate = 'StartDate',
  /** column name */
  Status = 'Status',
  /** column name */
  UnitCost = 'UnitCost',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** aggregate var_pop on columns */
export type Dw_Projects_Var_Pop_Fields = {
  __typename?: 'dw_Projects_var_pop_fields';
  AnnualAdditionalSavings?: Maybe<Scalars['Float']>;
  AnnualKwhSavings?: Maybe<Scalars['Float']>;
  AnnualSavings?: Maybe<Scalars['Float']>;
  Cost?: Maybe<Scalars['Float']>;
  CostThusFar?: Maybe<Scalars['Float']>;
  CumulativeKwhSavings?: Maybe<Scalars['Float']>;
  Priority?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.Projects" */
export type Dw_Projects_Var_Pop_Order_By = {
  AnnualAdditionalSavings?: Maybe<Order_By>;
  AnnualKwhSavings?: Maybe<Order_By>;
  AnnualSavings?: Maybe<Order_By>;
  Cost?: Maybe<Order_By>;
  CostThusFar?: Maybe<Order_By>;
  CumulativeKwhSavings?: Maybe<Order_By>;
  Priority?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UnitCost?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_Projects_Var_Samp_Fields = {
  __typename?: 'dw_Projects_var_samp_fields';
  AnnualAdditionalSavings?: Maybe<Scalars['Float']>;
  AnnualKwhSavings?: Maybe<Scalars['Float']>;
  AnnualSavings?: Maybe<Scalars['Float']>;
  Cost?: Maybe<Scalars['Float']>;
  CostThusFar?: Maybe<Scalars['Float']>;
  CumulativeKwhSavings?: Maybe<Scalars['Float']>;
  Priority?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.Projects" */
export type Dw_Projects_Var_Samp_Order_By = {
  AnnualAdditionalSavings?: Maybe<Order_By>;
  AnnualKwhSavings?: Maybe<Order_By>;
  AnnualSavings?: Maybe<Order_By>;
  Cost?: Maybe<Order_By>;
  CostThusFar?: Maybe<Order_By>;
  CumulativeKwhSavings?: Maybe<Order_By>;
  Priority?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UnitCost?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_Projects_Variance_Fields = {
  __typename?: 'dw_Projects_variance_fields';
  AnnualAdditionalSavings?: Maybe<Scalars['Float']>;
  AnnualKwhSavings?: Maybe<Scalars['Float']>;
  AnnualSavings?: Maybe<Scalars['Float']>;
  Cost?: Maybe<Scalars['Float']>;
  CostThusFar?: Maybe<Scalars['Float']>;
  CumulativeKwhSavings?: Maybe<Scalars['Float']>;
  Priority?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.Projects" */
export type Dw_Projects_Variance_Order_By = {
  AnnualAdditionalSavings?: Maybe<Order_By>;
  AnnualKwhSavings?: Maybe<Order_By>;
  AnnualSavings?: Maybe<Order_By>;
  Cost?: Maybe<Order_By>;
  CostThusFar?: Maybe<Order_By>;
  CumulativeKwhSavings?: Maybe<Order_By>;
  Priority?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UnitCost?: Maybe<Order_By>;
};

/** columns and relationships of "dw.Reports" */
export type Dw_Reports = {
  __typename?: 'dw_Reports';
  /** An object relationship */
  Account: Dw_Accounts;
  AccountId: Scalars['uuid'];
  BaselineMode: Scalars['smallint'];
  CreatedOn: Scalars['timestamp'];
  NotificationFrequency: Scalars['smallint'];
  ReportId: Scalars['uuid'];
  StartDate: Scalars['timestamp'];
  TargetElectricity: Scalars['numeric'];
  TargetGas: Scalars['numeric'];
  TargetWater: Scalars['numeric'];
  Type: Scalars['smallint'];
  UpdatedOn: Scalars['timestamp'];
};

/** aggregated selection of "dw.Reports" */
export type Dw_Reports_Aggregate = {
  __typename?: 'dw_Reports_aggregate';
  aggregate?: Maybe<Dw_Reports_Aggregate_Fields>;
  nodes: Array<Dw_Reports>;
};

/** aggregate fields of "dw.Reports" */
export type Dw_Reports_Aggregate_Fields = {
  __typename?: 'dw_Reports_aggregate_fields';
  avg?: Maybe<Dw_Reports_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_Reports_Max_Fields>;
  min?: Maybe<Dw_Reports_Min_Fields>;
  stddev?: Maybe<Dw_Reports_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_Reports_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_Reports_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_Reports_Sum_Fields>;
  var_pop?: Maybe<Dw_Reports_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_Reports_Var_Samp_Fields>;
  variance?: Maybe<Dw_Reports_Variance_Fields>;
};


/** aggregate fields of "dw.Reports" */
export type Dw_Reports_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_Reports_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.Reports" */
export type Dw_Reports_Aggregate_Order_By = {
  avg?: Maybe<Dw_Reports_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_Reports_Max_Order_By>;
  min?: Maybe<Dw_Reports_Min_Order_By>;
  stddev?: Maybe<Dw_Reports_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_Reports_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_Reports_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_Reports_Sum_Order_By>;
  var_pop?: Maybe<Dw_Reports_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_Reports_Var_Samp_Order_By>;
  variance?: Maybe<Dw_Reports_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.Reports" */
export type Dw_Reports_Arr_Rel_Insert_Input = {
  data: Array<Dw_Reports_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_Reports_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_Reports_Avg_Fields = {
  __typename?: 'dw_Reports_avg_fields';
  BaselineMode?: Maybe<Scalars['Float']>;
  NotificationFrequency?: Maybe<Scalars['Float']>;
  TargetElectricity?: Maybe<Scalars['Float']>;
  TargetGas?: Maybe<Scalars['Float']>;
  TargetWater?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.Reports" */
export type Dw_Reports_Avg_Order_By = {
  BaselineMode?: Maybe<Order_By>;
  NotificationFrequency?: Maybe<Order_By>;
  TargetElectricity?: Maybe<Order_By>;
  TargetGas?: Maybe<Order_By>;
  TargetWater?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.Reports". All fields are combined with a logical 'AND'. */
export type Dw_Reports_Bool_Exp = {
  Account?: Maybe<Dw_Accounts_Bool_Exp>;
  AccountId?: Maybe<Uuid_Comparison_Exp>;
  BaselineMode?: Maybe<Smallint_Comparison_Exp>;
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  NotificationFrequency?: Maybe<Smallint_Comparison_Exp>;
  ReportId?: Maybe<Uuid_Comparison_Exp>;
  StartDate?: Maybe<Timestamp_Comparison_Exp>;
  TargetElectricity?: Maybe<Numeric_Comparison_Exp>;
  TargetGas?: Maybe<Numeric_Comparison_Exp>;
  TargetWater?: Maybe<Numeric_Comparison_Exp>;
  Type?: Maybe<Smallint_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  _and?: Maybe<Array<Dw_Reports_Bool_Exp>>;
  _not?: Maybe<Dw_Reports_Bool_Exp>;
  _or?: Maybe<Array<Dw_Reports_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.Reports" */
export enum Dw_Reports_Constraint {
  /** unique or primary key constraint */
  ReportsPk = 'reports_pk',
  /** unique or primary key constraint */
  ReportsReportidUindex = 'reports_reportid_uindex'
}

/** input type for incrementing numeric columns in table "dw.Reports" */
export type Dw_Reports_Inc_Input = {
  BaselineMode?: Maybe<Scalars['smallint']>;
  NotificationFrequency?: Maybe<Scalars['smallint']>;
  TargetElectricity?: Maybe<Scalars['numeric']>;
  TargetGas?: Maybe<Scalars['numeric']>;
  TargetWater?: Maybe<Scalars['numeric']>;
  Type?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.Reports" */
export type Dw_Reports_Insert_Input = {
  Account?: Maybe<Dw_Accounts_Obj_Rel_Insert_Input>;
  AccountId?: Maybe<Scalars['uuid']>;
  BaselineMode?: Maybe<Scalars['smallint']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  NotificationFrequency?: Maybe<Scalars['smallint']>;
  ReportId?: Maybe<Scalars['uuid']>;
  StartDate?: Maybe<Scalars['timestamp']>;
  TargetElectricity?: Maybe<Scalars['numeric']>;
  TargetGas?: Maybe<Scalars['numeric']>;
  TargetWater?: Maybe<Scalars['numeric']>;
  Type?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Dw_Reports_Max_Fields = {
  __typename?: 'dw_Reports_max_fields';
  AccountId?: Maybe<Scalars['uuid']>;
  BaselineMode?: Maybe<Scalars['smallint']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  NotificationFrequency?: Maybe<Scalars['smallint']>;
  ReportId?: Maybe<Scalars['uuid']>;
  StartDate?: Maybe<Scalars['timestamp']>;
  TargetElectricity?: Maybe<Scalars['numeric']>;
  TargetGas?: Maybe<Scalars['numeric']>;
  TargetWater?: Maybe<Scalars['numeric']>;
  Type?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "dw.Reports" */
export type Dw_Reports_Max_Order_By = {
  AccountId?: Maybe<Order_By>;
  BaselineMode?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  NotificationFrequency?: Maybe<Order_By>;
  ReportId?: Maybe<Order_By>;
  StartDate?: Maybe<Order_By>;
  TargetElectricity?: Maybe<Order_By>;
  TargetGas?: Maybe<Order_By>;
  TargetWater?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_Reports_Min_Fields = {
  __typename?: 'dw_Reports_min_fields';
  AccountId?: Maybe<Scalars['uuid']>;
  BaselineMode?: Maybe<Scalars['smallint']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  NotificationFrequency?: Maybe<Scalars['smallint']>;
  ReportId?: Maybe<Scalars['uuid']>;
  StartDate?: Maybe<Scalars['timestamp']>;
  TargetElectricity?: Maybe<Scalars['numeric']>;
  TargetGas?: Maybe<Scalars['numeric']>;
  TargetWater?: Maybe<Scalars['numeric']>;
  Type?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "dw.Reports" */
export type Dw_Reports_Min_Order_By = {
  AccountId?: Maybe<Order_By>;
  BaselineMode?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  NotificationFrequency?: Maybe<Order_By>;
  ReportId?: Maybe<Order_By>;
  StartDate?: Maybe<Order_By>;
  TargetElectricity?: Maybe<Order_By>;
  TargetGas?: Maybe<Order_By>;
  TargetWater?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.Reports" */
export type Dw_Reports_Mutation_Response = {
  __typename?: 'dw_Reports_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_Reports>;
};

/** on conflict condition type for table "dw.Reports" */
export type Dw_Reports_On_Conflict = {
  constraint: Dw_Reports_Constraint;
  update_columns?: Array<Dw_Reports_Update_Column>;
  where?: Maybe<Dw_Reports_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.Reports". */
export type Dw_Reports_Order_By = {
  Account?: Maybe<Dw_Accounts_Order_By>;
  AccountId?: Maybe<Order_By>;
  BaselineMode?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  NotificationFrequency?: Maybe<Order_By>;
  ReportId?: Maybe<Order_By>;
  StartDate?: Maybe<Order_By>;
  TargetElectricity?: Maybe<Order_By>;
  TargetGas?: Maybe<Order_By>;
  TargetWater?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_Reports */
export type Dw_Reports_Pk_Columns_Input = {
  ReportId: Scalars['uuid'];
};

/** select columns of table "dw.Reports" */
export enum Dw_Reports_Select_Column {
  /** column name */
  AccountId = 'AccountId',
  /** column name */
  BaselineMode = 'BaselineMode',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  NotificationFrequency = 'NotificationFrequency',
  /** column name */
  ReportId = 'ReportId',
  /** column name */
  StartDate = 'StartDate',
  /** column name */
  TargetElectricity = 'TargetElectricity',
  /** column name */
  TargetGas = 'TargetGas',
  /** column name */
  TargetWater = 'TargetWater',
  /** column name */
  Type = 'Type',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** input type for updating data in table "dw.Reports" */
export type Dw_Reports_Set_Input = {
  AccountId?: Maybe<Scalars['uuid']>;
  BaselineMode?: Maybe<Scalars['smallint']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  NotificationFrequency?: Maybe<Scalars['smallint']>;
  ReportId?: Maybe<Scalars['uuid']>;
  StartDate?: Maybe<Scalars['timestamp']>;
  TargetElectricity?: Maybe<Scalars['numeric']>;
  TargetGas?: Maybe<Scalars['numeric']>;
  TargetWater?: Maybe<Scalars['numeric']>;
  Type?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Dw_Reports_Stddev_Fields = {
  __typename?: 'dw_Reports_stddev_fields';
  BaselineMode?: Maybe<Scalars['Float']>;
  NotificationFrequency?: Maybe<Scalars['Float']>;
  TargetElectricity?: Maybe<Scalars['Float']>;
  TargetGas?: Maybe<Scalars['Float']>;
  TargetWater?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.Reports" */
export type Dw_Reports_Stddev_Order_By = {
  BaselineMode?: Maybe<Order_By>;
  NotificationFrequency?: Maybe<Order_By>;
  TargetElectricity?: Maybe<Order_By>;
  TargetGas?: Maybe<Order_By>;
  TargetWater?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_Reports_Stddev_Pop_Fields = {
  __typename?: 'dw_Reports_stddev_pop_fields';
  BaselineMode?: Maybe<Scalars['Float']>;
  NotificationFrequency?: Maybe<Scalars['Float']>;
  TargetElectricity?: Maybe<Scalars['Float']>;
  TargetGas?: Maybe<Scalars['Float']>;
  TargetWater?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.Reports" */
export type Dw_Reports_Stddev_Pop_Order_By = {
  BaselineMode?: Maybe<Order_By>;
  NotificationFrequency?: Maybe<Order_By>;
  TargetElectricity?: Maybe<Order_By>;
  TargetGas?: Maybe<Order_By>;
  TargetWater?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_Reports_Stddev_Samp_Fields = {
  __typename?: 'dw_Reports_stddev_samp_fields';
  BaselineMode?: Maybe<Scalars['Float']>;
  NotificationFrequency?: Maybe<Scalars['Float']>;
  TargetElectricity?: Maybe<Scalars['Float']>;
  TargetGas?: Maybe<Scalars['Float']>;
  TargetWater?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.Reports" */
export type Dw_Reports_Stddev_Samp_Order_By = {
  BaselineMode?: Maybe<Order_By>;
  NotificationFrequency?: Maybe<Order_By>;
  TargetElectricity?: Maybe<Order_By>;
  TargetGas?: Maybe<Order_By>;
  TargetWater?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_Reports_Sum_Fields = {
  __typename?: 'dw_Reports_sum_fields';
  BaselineMode?: Maybe<Scalars['smallint']>;
  NotificationFrequency?: Maybe<Scalars['smallint']>;
  TargetElectricity?: Maybe<Scalars['numeric']>;
  TargetGas?: Maybe<Scalars['numeric']>;
  TargetWater?: Maybe<Scalars['numeric']>;
  Type?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "dw.Reports" */
export type Dw_Reports_Sum_Order_By = {
  BaselineMode?: Maybe<Order_By>;
  NotificationFrequency?: Maybe<Order_By>;
  TargetElectricity?: Maybe<Order_By>;
  TargetGas?: Maybe<Order_By>;
  TargetWater?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
};

/** update columns of table "dw.Reports" */
export enum Dw_Reports_Update_Column {
  /** column name */
  AccountId = 'AccountId',
  /** column name */
  BaselineMode = 'BaselineMode',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  NotificationFrequency = 'NotificationFrequency',
  /** column name */
  ReportId = 'ReportId',
  /** column name */
  StartDate = 'StartDate',
  /** column name */
  TargetElectricity = 'TargetElectricity',
  /** column name */
  TargetGas = 'TargetGas',
  /** column name */
  TargetWater = 'TargetWater',
  /** column name */
  Type = 'Type',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** aggregate var_pop on columns */
export type Dw_Reports_Var_Pop_Fields = {
  __typename?: 'dw_Reports_var_pop_fields';
  BaselineMode?: Maybe<Scalars['Float']>;
  NotificationFrequency?: Maybe<Scalars['Float']>;
  TargetElectricity?: Maybe<Scalars['Float']>;
  TargetGas?: Maybe<Scalars['Float']>;
  TargetWater?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.Reports" */
export type Dw_Reports_Var_Pop_Order_By = {
  BaselineMode?: Maybe<Order_By>;
  NotificationFrequency?: Maybe<Order_By>;
  TargetElectricity?: Maybe<Order_By>;
  TargetGas?: Maybe<Order_By>;
  TargetWater?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_Reports_Var_Samp_Fields = {
  __typename?: 'dw_Reports_var_samp_fields';
  BaselineMode?: Maybe<Scalars['Float']>;
  NotificationFrequency?: Maybe<Scalars['Float']>;
  TargetElectricity?: Maybe<Scalars['Float']>;
  TargetGas?: Maybe<Scalars['Float']>;
  TargetWater?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.Reports" */
export type Dw_Reports_Var_Samp_Order_By = {
  BaselineMode?: Maybe<Order_By>;
  NotificationFrequency?: Maybe<Order_By>;
  TargetElectricity?: Maybe<Order_By>;
  TargetGas?: Maybe<Order_By>;
  TargetWater?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_Reports_Variance_Fields = {
  __typename?: 'dw_Reports_variance_fields';
  BaselineMode?: Maybe<Scalars['Float']>;
  NotificationFrequency?: Maybe<Scalars['Float']>;
  TargetElectricity?: Maybe<Scalars['Float']>;
  TargetGas?: Maybe<Scalars['Float']>;
  TargetWater?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.Reports" */
export type Dw_Reports_Variance_Order_By = {
  BaselineMode?: Maybe<Order_By>;
  NotificationFrequency?: Maybe<Order_By>;
  TargetElectricity?: Maybe<Order_By>;
  TargetGas?: Maybe<Order_By>;
  TargetWater?: Maybe<Order_By>;
  Type?: Maybe<Order_By>;
};

/** columns and relationships of "dw.Sessions" */
export type Dw_Sessions = {
  __typename?: 'dw_Sessions';
  AuthorisedOn: Scalars['timestamp'];
  City?: Maybe<Scalars['String']>;
  /** An object relationship */
  Contact: Dw_Contacts;
  ContactId: Scalars['uuid'];
  Country?: Maybe<Scalars['String']>;
  Device?: Maybe<Scalars['String']>;
  IPAddress?: Maybe<Scalars['String']>;
  LastActivity: Scalars['timestamp'];
  Location?: Maybe<Scalars['String']>;
  SessionId: Scalars['uuid'];
  Source: Scalars['smallint'];
};

/** aggregated selection of "dw.Sessions" */
export type Dw_Sessions_Aggregate = {
  __typename?: 'dw_Sessions_aggregate';
  aggregate?: Maybe<Dw_Sessions_Aggregate_Fields>;
  nodes: Array<Dw_Sessions>;
};

/** aggregate fields of "dw.Sessions" */
export type Dw_Sessions_Aggregate_Fields = {
  __typename?: 'dw_Sessions_aggregate_fields';
  avg?: Maybe<Dw_Sessions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_Sessions_Max_Fields>;
  min?: Maybe<Dw_Sessions_Min_Fields>;
  stddev?: Maybe<Dw_Sessions_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_Sessions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_Sessions_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_Sessions_Sum_Fields>;
  var_pop?: Maybe<Dw_Sessions_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_Sessions_Var_Samp_Fields>;
  variance?: Maybe<Dw_Sessions_Variance_Fields>;
};


/** aggregate fields of "dw.Sessions" */
export type Dw_Sessions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_Sessions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.Sessions" */
export type Dw_Sessions_Aggregate_Order_By = {
  avg?: Maybe<Dw_Sessions_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_Sessions_Max_Order_By>;
  min?: Maybe<Dw_Sessions_Min_Order_By>;
  stddev?: Maybe<Dw_Sessions_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_Sessions_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_Sessions_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_Sessions_Sum_Order_By>;
  var_pop?: Maybe<Dw_Sessions_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_Sessions_Var_Samp_Order_By>;
  variance?: Maybe<Dw_Sessions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.Sessions" */
export type Dw_Sessions_Arr_Rel_Insert_Input = {
  data: Array<Dw_Sessions_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_Sessions_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_Sessions_Avg_Fields = {
  __typename?: 'dw_Sessions_avg_fields';
  Source?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.Sessions" */
export type Dw_Sessions_Avg_Order_By = {
  Source?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.Sessions". All fields are combined with a logical 'AND'. */
export type Dw_Sessions_Bool_Exp = {
  AuthorisedOn?: Maybe<Timestamp_Comparison_Exp>;
  City?: Maybe<String_Comparison_Exp>;
  Contact?: Maybe<Dw_Contacts_Bool_Exp>;
  ContactId?: Maybe<Uuid_Comparison_Exp>;
  Country?: Maybe<String_Comparison_Exp>;
  Device?: Maybe<String_Comparison_Exp>;
  IPAddress?: Maybe<String_Comparison_Exp>;
  LastActivity?: Maybe<Timestamp_Comparison_Exp>;
  Location?: Maybe<String_Comparison_Exp>;
  SessionId?: Maybe<Uuid_Comparison_Exp>;
  Source?: Maybe<Smallint_Comparison_Exp>;
  _and?: Maybe<Array<Dw_Sessions_Bool_Exp>>;
  _not?: Maybe<Dw_Sessions_Bool_Exp>;
  _or?: Maybe<Array<Dw_Sessions_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.Sessions" */
export enum Dw_Sessions_Constraint {
  /** unique or primary key constraint */
  SessionsPk = 'sessions_pk',
  /** unique or primary key constraint */
  SessionsSessionidUindex = 'sessions_sessionid_uindex'
}

/** input type for incrementing numeric columns in table "dw.Sessions" */
export type Dw_Sessions_Inc_Input = {
  Source?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.Sessions" */
export type Dw_Sessions_Insert_Input = {
  AuthorisedOn?: Maybe<Scalars['timestamp']>;
  City?: Maybe<Scalars['String']>;
  Contact?: Maybe<Dw_Contacts_Obj_Rel_Insert_Input>;
  ContactId?: Maybe<Scalars['uuid']>;
  Country?: Maybe<Scalars['String']>;
  Device?: Maybe<Scalars['String']>;
  IPAddress?: Maybe<Scalars['String']>;
  LastActivity?: Maybe<Scalars['timestamp']>;
  Location?: Maybe<Scalars['String']>;
  SessionId?: Maybe<Scalars['uuid']>;
  Source?: Maybe<Scalars['smallint']>;
};

/** aggregate max on columns */
export type Dw_Sessions_Max_Fields = {
  __typename?: 'dw_Sessions_max_fields';
  AuthorisedOn?: Maybe<Scalars['timestamp']>;
  City?: Maybe<Scalars['String']>;
  ContactId?: Maybe<Scalars['uuid']>;
  Country?: Maybe<Scalars['String']>;
  Device?: Maybe<Scalars['String']>;
  IPAddress?: Maybe<Scalars['String']>;
  LastActivity?: Maybe<Scalars['timestamp']>;
  Location?: Maybe<Scalars['String']>;
  SessionId?: Maybe<Scalars['uuid']>;
  Source?: Maybe<Scalars['smallint']>;
};

/** order by max() on columns of table "dw.Sessions" */
export type Dw_Sessions_Max_Order_By = {
  AuthorisedOn?: Maybe<Order_By>;
  City?: Maybe<Order_By>;
  ContactId?: Maybe<Order_By>;
  Country?: Maybe<Order_By>;
  Device?: Maybe<Order_By>;
  IPAddress?: Maybe<Order_By>;
  LastActivity?: Maybe<Order_By>;
  Location?: Maybe<Order_By>;
  SessionId?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_Sessions_Min_Fields = {
  __typename?: 'dw_Sessions_min_fields';
  AuthorisedOn?: Maybe<Scalars['timestamp']>;
  City?: Maybe<Scalars['String']>;
  ContactId?: Maybe<Scalars['uuid']>;
  Country?: Maybe<Scalars['String']>;
  Device?: Maybe<Scalars['String']>;
  IPAddress?: Maybe<Scalars['String']>;
  LastActivity?: Maybe<Scalars['timestamp']>;
  Location?: Maybe<Scalars['String']>;
  SessionId?: Maybe<Scalars['uuid']>;
  Source?: Maybe<Scalars['smallint']>;
};

/** order by min() on columns of table "dw.Sessions" */
export type Dw_Sessions_Min_Order_By = {
  AuthorisedOn?: Maybe<Order_By>;
  City?: Maybe<Order_By>;
  ContactId?: Maybe<Order_By>;
  Country?: Maybe<Order_By>;
  Device?: Maybe<Order_By>;
  IPAddress?: Maybe<Order_By>;
  LastActivity?: Maybe<Order_By>;
  Location?: Maybe<Order_By>;
  SessionId?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.Sessions" */
export type Dw_Sessions_Mutation_Response = {
  __typename?: 'dw_Sessions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_Sessions>;
};

/** on conflict condition type for table "dw.Sessions" */
export type Dw_Sessions_On_Conflict = {
  constraint: Dw_Sessions_Constraint;
  update_columns?: Array<Dw_Sessions_Update_Column>;
  where?: Maybe<Dw_Sessions_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.Sessions". */
export type Dw_Sessions_Order_By = {
  AuthorisedOn?: Maybe<Order_By>;
  City?: Maybe<Order_By>;
  Contact?: Maybe<Dw_Contacts_Order_By>;
  ContactId?: Maybe<Order_By>;
  Country?: Maybe<Order_By>;
  Device?: Maybe<Order_By>;
  IPAddress?: Maybe<Order_By>;
  LastActivity?: Maybe<Order_By>;
  Location?: Maybe<Order_By>;
  SessionId?: Maybe<Order_By>;
  Source?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_Sessions */
export type Dw_Sessions_Pk_Columns_Input = {
  SessionId: Scalars['uuid'];
};

/** select columns of table "dw.Sessions" */
export enum Dw_Sessions_Select_Column {
  /** column name */
  AuthorisedOn = 'AuthorisedOn',
  /** column name */
  City = 'City',
  /** column name */
  ContactId = 'ContactId',
  /** column name */
  Country = 'Country',
  /** column name */
  Device = 'Device',
  /** column name */
  IpAddress = 'IPAddress',
  /** column name */
  LastActivity = 'LastActivity',
  /** column name */
  Location = 'Location',
  /** column name */
  SessionId = 'SessionId',
  /** column name */
  Source = 'Source'
}

/** input type for updating data in table "dw.Sessions" */
export type Dw_Sessions_Set_Input = {
  AuthorisedOn?: Maybe<Scalars['timestamp']>;
  City?: Maybe<Scalars['String']>;
  ContactId?: Maybe<Scalars['uuid']>;
  Country?: Maybe<Scalars['String']>;
  Device?: Maybe<Scalars['String']>;
  IPAddress?: Maybe<Scalars['String']>;
  LastActivity?: Maybe<Scalars['timestamp']>;
  Location?: Maybe<Scalars['String']>;
  SessionId?: Maybe<Scalars['uuid']>;
  Source?: Maybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type Dw_Sessions_Stddev_Fields = {
  __typename?: 'dw_Sessions_stddev_fields';
  Source?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.Sessions" */
export type Dw_Sessions_Stddev_Order_By = {
  Source?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_Sessions_Stddev_Pop_Fields = {
  __typename?: 'dw_Sessions_stddev_pop_fields';
  Source?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.Sessions" */
export type Dw_Sessions_Stddev_Pop_Order_By = {
  Source?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_Sessions_Stddev_Samp_Fields = {
  __typename?: 'dw_Sessions_stddev_samp_fields';
  Source?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.Sessions" */
export type Dw_Sessions_Stddev_Samp_Order_By = {
  Source?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_Sessions_Sum_Fields = {
  __typename?: 'dw_Sessions_sum_fields';
  Source?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "dw.Sessions" */
export type Dw_Sessions_Sum_Order_By = {
  Source?: Maybe<Order_By>;
};

/** update columns of table "dw.Sessions" */
export enum Dw_Sessions_Update_Column {
  /** column name */
  AuthorisedOn = 'AuthorisedOn',
  /** column name */
  City = 'City',
  /** column name */
  ContactId = 'ContactId',
  /** column name */
  Country = 'Country',
  /** column name */
  Device = 'Device',
  /** column name */
  IpAddress = 'IPAddress',
  /** column name */
  LastActivity = 'LastActivity',
  /** column name */
  Location = 'Location',
  /** column name */
  SessionId = 'SessionId',
  /** column name */
  Source = 'Source'
}

/** aggregate var_pop on columns */
export type Dw_Sessions_Var_Pop_Fields = {
  __typename?: 'dw_Sessions_var_pop_fields';
  Source?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.Sessions" */
export type Dw_Sessions_Var_Pop_Order_By = {
  Source?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_Sessions_Var_Samp_Fields = {
  __typename?: 'dw_Sessions_var_samp_fields';
  Source?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.Sessions" */
export type Dw_Sessions_Var_Samp_Order_By = {
  Source?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_Sessions_Variance_Fields = {
  __typename?: 'dw_Sessions_variance_fields';
  Source?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.Sessions" */
export type Dw_Sessions_Variance_Order_By = {
  Source?: Maybe<Order_By>;
};

/** columns and relationships of "dw.Sites" */
export type Dw_Sites = {
  __typename?: 'dw_Sites';
  /** An object relationship */
  Account: Dw_Accounts;
  AccountId: Scalars['uuid'];
  /** An array relationship */
  Buildings: Array<Dw_Buildings>;
  /** An aggregate relationship */
  Buildings_aggregate: Dw_Buildings_Aggregate;
  City?: Maybe<Scalars['String']>;
  CreatedOn: Scalars['timestamp'];
  Ecms: Scalars['Int'];
  EcmsDate?: Maybe<Scalars['timestamp']>;
  EcmsState: Scalars['Int'];
  /** An object relationship */
  Group?: Maybe<Dw_Groups>;
  GroupId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  GroupsSites: Array<Dw_GroupsSites>;
  /** An aggregate relationship */
  GroupsSites_aggregate: Dw_GroupsSites_Aggregate;
  InstallerContactId?: Maybe<Scalars['uuid']>;
  Label: Scalars['String'];
  Line1?: Maybe<Scalars['String']>;
  Line2?: Maybe<Scalars['String']>;
  /** An array relationship */
  Projects: Array<Dw_Projects>;
  /** An aggregate relationship */
  Projects_aggregate: Dw_Projects_Aggregate;
  SiteContactId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  SiteCustomerContact?: Maybe<Dw_Contacts>;
  SiteId: Scalars['uuid'];
  /** An object relationship */
  SiteInstallerContact?: Maybe<Dw_Contacts>;
  Status: Scalars['smallint'];
  UpdatedOn: Scalars['timestamp'];
};


/** columns and relationships of "dw.Sites" */
export type Dw_SitesBuildingsArgs = {
  distinct_on?: Maybe<Array<Dw_Buildings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Buildings_Order_By>>;
  where?: Maybe<Dw_Buildings_Bool_Exp>;
};


/** columns and relationships of "dw.Sites" */
export type Dw_SitesBuildings_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Buildings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Buildings_Order_By>>;
  where?: Maybe<Dw_Buildings_Bool_Exp>;
};


/** columns and relationships of "dw.Sites" */
export type Dw_SitesGroupsSitesArgs = {
  distinct_on?: Maybe<Array<Dw_GroupsSites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_GroupsSites_Order_By>>;
  where?: Maybe<Dw_GroupsSites_Bool_Exp>;
};


/** columns and relationships of "dw.Sites" */
export type Dw_SitesGroupsSites_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_GroupsSites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_GroupsSites_Order_By>>;
  where?: Maybe<Dw_GroupsSites_Bool_Exp>;
};


/** columns and relationships of "dw.Sites" */
export type Dw_SitesProjectsArgs = {
  distinct_on?: Maybe<Array<Dw_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Projects_Order_By>>;
  where?: Maybe<Dw_Projects_Bool_Exp>;
};


/** columns and relationships of "dw.Sites" */
export type Dw_SitesProjects_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Projects_Order_By>>;
  where?: Maybe<Dw_Projects_Bool_Exp>;
};

/** aggregated selection of "dw.Sites" */
export type Dw_Sites_Aggregate = {
  __typename?: 'dw_Sites_aggregate';
  aggregate?: Maybe<Dw_Sites_Aggregate_Fields>;
  nodes: Array<Dw_Sites>;
};

/** aggregate fields of "dw.Sites" */
export type Dw_Sites_Aggregate_Fields = {
  __typename?: 'dw_Sites_aggregate_fields';
  avg?: Maybe<Dw_Sites_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_Sites_Max_Fields>;
  min?: Maybe<Dw_Sites_Min_Fields>;
  stddev?: Maybe<Dw_Sites_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_Sites_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_Sites_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_Sites_Sum_Fields>;
  var_pop?: Maybe<Dw_Sites_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_Sites_Var_Samp_Fields>;
  variance?: Maybe<Dw_Sites_Variance_Fields>;
};


/** aggregate fields of "dw.Sites" */
export type Dw_Sites_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_Sites_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.Sites" */
export type Dw_Sites_Aggregate_Order_By = {
  avg?: Maybe<Dw_Sites_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_Sites_Max_Order_By>;
  min?: Maybe<Dw_Sites_Min_Order_By>;
  stddev?: Maybe<Dw_Sites_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_Sites_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_Sites_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_Sites_Sum_Order_By>;
  var_pop?: Maybe<Dw_Sites_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_Sites_Var_Samp_Order_By>;
  variance?: Maybe<Dw_Sites_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.Sites" */
export type Dw_Sites_Arr_Rel_Insert_Input = {
  data: Array<Dw_Sites_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_Sites_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_Sites_Avg_Fields = {
  __typename?: 'dw_Sites_avg_fields';
  Ecms?: Maybe<Scalars['Float']>;
  EcmsState?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.Sites" */
export type Dw_Sites_Avg_Order_By = {
  Ecms?: Maybe<Order_By>;
  EcmsState?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.Sites". All fields are combined with a logical 'AND'. */
export type Dw_Sites_Bool_Exp = {
  Account?: Maybe<Dw_Accounts_Bool_Exp>;
  AccountId?: Maybe<Uuid_Comparison_Exp>;
  Buildings?: Maybe<Dw_Buildings_Bool_Exp>;
  City?: Maybe<String_Comparison_Exp>;
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  Ecms?: Maybe<Int_Comparison_Exp>;
  EcmsDate?: Maybe<Timestamp_Comparison_Exp>;
  EcmsState?: Maybe<Int_Comparison_Exp>;
  Group?: Maybe<Dw_Groups_Bool_Exp>;
  GroupId?: Maybe<Uuid_Comparison_Exp>;
  GroupsSites?: Maybe<Dw_GroupsSites_Bool_Exp>;
  InstallerContactId?: Maybe<Uuid_Comparison_Exp>;
  Label?: Maybe<String_Comparison_Exp>;
  Line1?: Maybe<String_Comparison_Exp>;
  Line2?: Maybe<String_Comparison_Exp>;
  Projects?: Maybe<Dw_Projects_Bool_Exp>;
  SiteContactId?: Maybe<Uuid_Comparison_Exp>;
  SiteCustomerContact?: Maybe<Dw_Contacts_Bool_Exp>;
  SiteId?: Maybe<Uuid_Comparison_Exp>;
  SiteInstallerContact?: Maybe<Dw_Contacts_Bool_Exp>;
  Status?: Maybe<Smallint_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  _and?: Maybe<Array<Dw_Sites_Bool_Exp>>;
  _not?: Maybe<Dw_Sites_Bool_Exp>;
  _or?: Maybe<Array<Dw_Sites_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.Sites" */
export enum Dw_Sites_Constraint {
  /** unique or primary key constraint */
  SitesPk = 'sites_pk',
  /** unique or primary key constraint */
  SitesSiteidUindex = 'sites_siteid_uindex'
}

/** input type for incrementing numeric columns in table "dw.Sites" */
export type Dw_Sites_Inc_Input = {
  Ecms?: Maybe<Scalars['Int']>;
  EcmsState?: Maybe<Scalars['Int']>;
  Status?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.Sites" */
export type Dw_Sites_Insert_Input = {
  Account?: Maybe<Dw_Accounts_Obj_Rel_Insert_Input>;
  AccountId?: Maybe<Scalars['uuid']>;
  Buildings?: Maybe<Dw_Buildings_Arr_Rel_Insert_Input>;
  City?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  Ecms?: Maybe<Scalars['Int']>;
  EcmsDate?: Maybe<Scalars['timestamp']>;
  EcmsState?: Maybe<Scalars['Int']>;
  Group?: Maybe<Dw_Groups_Obj_Rel_Insert_Input>;
  GroupId?: Maybe<Scalars['uuid']>;
  GroupsSites?: Maybe<Dw_GroupsSites_Arr_Rel_Insert_Input>;
  InstallerContactId?: Maybe<Scalars['uuid']>;
  Label?: Maybe<Scalars['String']>;
  Line1?: Maybe<Scalars['String']>;
  Line2?: Maybe<Scalars['String']>;
  Projects?: Maybe<Dw_Projects_Arr_Rel_Insert_Input>;
  SiteContactId?: Maybe<Scalars['uuid']>;
  SiteCustomerContact?: Maybe<Dw_Contacts_Obj_Rel_Insert_Input>;
  SiteId?: Maybe<Scalars['uuid']>;
  SiteInstallerContact?: Maybe<Dw_Contacts_Obj_Rel_Insert_Input>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Dw_Sites_Max_Fields = {
  __typename?: 'dw_Sites_max_fields';
  AccountId?: Maybe<Scalars['uuid']>;
  City?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  Ecms?: Maybe<Scalars['Int']>;
  EcmsDate?: Maybe<Scalars['timestamp']>;
  EcmsState?: Maybe<Scalars['Int']>;
  GroupId?: Maybe<Scalars['uuid']>;
  InstallerContactId?: Maybe<Scalars['uuid']>;
  Label?: Maybe<Scalars['String']>;
  Line1?: Maybe<Scalars['String']>;
  Line2?: Maybe<Scalars['String']>;
  SiteContactId?: Maybe<Scalars['uuid']>;
  SiteId?: Maybe<Scalars['uuid']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "dw.Sites" */
export type Dw_Sites_Max_Order_By = {
  AccountId?: Maybe<Order_By>;
  City?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  Ecms?: Maybe<Order_By>;
  EcmsDate?: Maybe<Order_By>;
  EcmsState?: Maybe<Order_By>;
  GroupId?: Maybe<Order_By>;
  InstallerContactId?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  Line1?: Maybe<Order_By>;
  Line2?: Maybe<Order_By>;
  SiteContactId?: Maybe<Order_By>;
  SiteId?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_Sites_Min_Fields = {
  __typename?: 'dw_Sites_min_fields';
  AccountId?: Maybe<Scalars['uuid']>;
  City?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  Ecms?: Maybe<Scalars['Int']>;
  EcmsDate?: Maybe<Scalars['timestamp']>;
  EcmsState?: Maybe<Scalars['Int']>;
  GroupId?: Maybe<Scalars['uuid']>;
  InstallerContactId?: Maybe<Scalars['uuid']>;
  Label?: Maybe<Scalars['String']>;
  Line1?: Maybe<Scalars['String']>;
  Line2?: Maybe<Scalars['String']>;
  SiteContactId?: Maybe<Scalars['uuid']>;
  SiteId?: Maybe<Scalars['uuid']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "dw.Sites" */
export type Dw_Sites_Min_Order_By = {
  AccountId?: Maybe<Order_By>;
  City?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  Ecms?: Maybe<Order_By>;
  EcmsDate?: Maybe<Order_By>;
  EcmsState?: Maybe<Order_By>;
  GroupId?: Maybe<Order_By>;
  InstallerContactId?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  Line1?: Maybe<Order_By>;
  Line2?: Maybe<Order_By>;
  SiteContactId?: Maybe<Order_By>;
  SiteId?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.Sites" */
export type Dw_Sites_Mutation_Response = {
  __typename?: 'dw_Sites_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_Sites>;
};

/** input type for inserting object relation for remote table "dw.Sites" */
export type Dw_Sites_Obj_Rel_Insert_Input = {
  data: Dw_Sites_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_Sites_On_Conflict>;
};

/** on conflict condition type for table "dw.Sites" */
export type Dw_Sites_On_Conflict = {
  constraint: Dw_Sites_Constraint;
  update_columns?: Array<Dw_Sites_Update_Column>;
  where?: Maybe<Dw_Sites_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.Sites". */
export type Dw_Sites_Order_By = {
  Account?: Maybe<Dw_Accounts_Order_By>;
  AccountId?: Maybe<Order_By>;
  Buildings_aggregate?: Maybe<Dw_Buildings_Aggregate_Order_By>;
  City?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  Ecms?: Maybe<Order_By>;
  EcmsDate?: Maybe<Order_By>;
  EcmsState?: Maybe<Order_By>;
  Group?: Maybe<Dw_Groups_Order_By>;
  GroupId?: Maybe<Order_By>;
  GroupsSites_aggregate?: Maybe<Dw_GroupsSites_Aggregate_Order_By>;
  InstallerContactId?: Maybe<Order_By>;
  Label?: Maybe<Order_By>;
  Line1?: Maybe<Order_By>;
  Line2?: Maybe<Order_By>;
  Projects_aggregate?: Maybe<Dw_Projects_Aggregate_Order_By>;
  SiteContactId?: Maybe<Order_By>;
  SiteCustomerContact?: Maybe<Dw_Contacts_Order_By>;
  SiteId?: Maybe<Order_By>;
  SiteInstallerContact?: Maybe<Dw_Contacts_Order_By>;
  Status?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_Sites */
export type Dw_Sites_Pk_Columns_Input = {
  SiteId: Scalars['uuid'];
};

/** select columns of table "dw.Sites" */
export enum Dw_Sites_Select_Column {
  /** column name */
  AccountId = 'AccountId',
  /** column name */
  City = 'City',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Ecms = 'Ecms',
  /** column name */
  EcmsDate = 'EcmsDate',
  /** column name */
  EcmsState = 'EcmsState',
  /** column name */
  GroupId = 'GroupId',
  /** column name */
  InstallerContactId = 'InstallerContactId',
  /** column name */
  Label = 'Label',
  /** column name */
  Line1 = 'Line1',
  /** column name */
  Line2 = 'Line2',
  /** column name */
  SiteContactId = 'SiteContactId',
  /** column name */
  SiteId = 'SiteId',
  /** column name */
  Status = 'Status',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** input type for updating data in table "dw.Sites" */
export type Dw_Sites_Set_Input = {
  AccountId?: Maybe<Scalars['uuid']>;
  City?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  Ecms?: Maybe<Scalars['Int']>;
  EcmsDate?: Maybe<Scalars['timestamp']>;
  EcmsState?: Maybe<Scalars['Int']>;
  GroupId?: Maybe<Scalars['uuid']>;
  InstallerContactId?: Maybe<Scalars['uuid']>;
  Label?: Maybe<Scalars['String']>;
  Line1?: Maybe<Scalars['String']>;
  Line2?: Maybe<Scalars['String']>;
  SiteContactId?: Maybe<Scalars['uuid']>;
  SiteId?: Maybe<Scalars['uuid']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Dw_Sites_Stddev_Fields = {
  __typename?: 'dw_Sites_stddev_fields';
  Ecms?: Maybe<Scalars['Float']>;
  EcmsState?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.Sites" */
export type Dw_Sites_Stddev_Order_By = {
  Ecms?: Maybe<Order_By>;
  EcmsState?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_Sites_Stddev_Pop_Fields = {
  __typename?: 'dw_Sites_stddev_pop_fields';
  Ecms?: Maybe<Scalars['Float']>;
  EcmsState?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.Sites" */
export type Dw_Sites_Stddev_Pop_Order_By = {
  Ecms?: Maybe<Order_By>;
  EcmsState?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_Sites_Stddev_Samp_Fields = {
  __typename?: 'dw_Sites_stddev_samp_fields';
  Ecms?: Maybe<Scalars['Float']>;
  EcmsState?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.Sites" */
export type Dw_Sites_Stddev_Samp_Order_By = {
  Ecms?: Maybe<Order_By>;
  EcmsState?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_Sites_Sum_Fields = {
  __typename?: 'dw_Sites_sum_fields';
  Ecms?: Maybe<Scalars['Int']>;
  EcmsState?: Maybe<Scalars['Int']>;
  Status?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "dw.Sites" */
export type Dw_Sites_Sum_Order_By = {
  Ecms?: Maybe<Order_By>;
  EcmsState?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** update columns of table "dw.Sites" */
export enum Dw_Sites_Update_Column {
  /** column name */
  AccountId = 'AccountId',
  /** column name */
  City = 'City',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Ecms = 'Ecms',
  /** column name */
  EcmsDate = 'EcmsDate',
  /** column name */
  EcmsState = 'EcmsState',
  /** column name */
  GroupId = 'GroupId',
  /** column name */
  InstallerContactId = 'InstallerContactId',
  /** column name */
  Label = 'Label',
  /** column name */
  Line1 = 'Line1',
  /** column name */
  Line2 = 'Line2',
  /** column name */
  SiteContactId = 'SiteContactId',
  /** column name */
  SiteId = 'SiteId',
  /** column name */
  Status = 'Status',
  /** column name */
  UpdatedOn = 'UpdatedOn'
}

/** aggregate var_pop on columns */
export type Dw_Sites_Var_Pop_Fields = {
  __typename?: 'dw_Sites_var_pop_fields';
  Ecms?: Maybe<Scalars['Float']>;
  EcmsState?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.Sites" */
export type Dw_Sites_Var_Pop_Order_By = {
  Ecms?: Maybe<Order_By>;
  EcmsState?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_Sites_Var_Samp_Fields = {
  __typename?: 'dw_Sites_var_samp_fields';
  Ecms?: Maybe<Scalars['Float']>;
  EcmsState?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.Sites" */
export type Dw_Sites_Var_Samp_Order_By = {
  Ecms?: Maybe<Order_By>;
  EcmsState?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_Sites_Variance_Fields = {
  __typename?: 'dw_Sites_variance_fields';
  Ecms?: Maybe<Scalars['Float']>;
  EcmsState?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.Sites" */
export type Dw_Sites_Variance_Order_By = {
  Ecms?: Maybe<Order_By>;
  EcmsState?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** columns and relationships of "dw.Weather" */
export type Dw_Weather = {
  __typename?: 'dw_Weather';
  Address?: Maybe<Scalars['String']>;
  City: Scalars['String'];
  Country: Scalars['String'];
  CreatedOn: Scalars['timestamp'];
  LatestDataPoint?: Maybe<Scalars['timestamp']>;
  Latitude?: Maybe<Scalars['numeric']>;
  Longitude?: Maybe<Scalars['numeric']>;
  StartFrom: Scalars['timestamp'];
  Status: Scalars['smallint'];
  UpdatedOn: Scalars['timestamp'];
  WeatherId: Scalars['uuid'];
  /** An array relationship */
  WeatherReadings: Array<Dw_WeatherReadings>;
  /** An aggregate relationship */
  WeatherReadings_aggregate: Dw_WeatherReadings_Aggregate;
};


/** columns and relationships of "dw.Weather" */
export type Dw_WeatherWeatherReadingsArgs = {
  distinct_on?: Maybe<Array<Dw_WeatherReadings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_WeatherReadings_Order_By>>;
  where?: Maybe<Dw_WeatherReadings_Bool_Exp>;
};


/** columns and relationships of "dw.Weather" */
export type Dw_WeatherWeatherReadings_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_WeatherReadings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_WeatherReadings_Order_By>>;
  where?: Maybe<Dw_WeatherReadings_Bool_Exp>;
};

/** columns and relationships of "dw.WeatherReadings" */
export type Dw_WeatherReadings = {
  __typename?: 'dw_WeatherReadings';
  Cdd?: Maybe<Scalars['numeric']>;
  Conditions: Scalars['String'];
  Dewpoint: Scalars['numeric'];
  Event: Scalars['timestamp'];
  FeelsLike: Scalars['numeric'];
  Hdd?: Maybe<Scalars['numeric']>;
  Humidity: Scalars['Int'];
  ReadingType?: Maybe<Scalars['Int']>;
  Temperature: Scalars['numeric'];
  /** An object relationship */
  Weather: Dw_Weather;
  WeatherId: Scalars['uuid'];
  WindDirection: Scalars['String'];
  WindSpeed: Scalars['numeric'];
};

/** aggregated selection of "dw.WeatherReadings" */
export type Dw_WeatherReadings_Aggregate = {
  __typename?: 'dw_WeatherReadings_aggregate';
  aggregate?: Maybe<Dw_WeatherReadings_Aggregate_Fields>;
  nodes: Array<Dw_WeatherReadings>;
};

/** aggregate fields of "dw.WeatherReadings" */
export type Dw_WeatherReadings_Aggregate_Fields = {
  __typename?: 'dw_WeatherReadings_aggregate_fields';
  avg?: Maybe<Dw_WeatherReadings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_WeatherReadings_Max_Fields>;
  min?: Maybe<Dw_WeatherReadings_Min_Fields>;
  stddev?: Maybe<Dw_WeatherReadings_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_WeatherReadings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_WeatherReadings_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_WeatherReadings_Sum_Fields>;
  var_pop?: Maybe<Dw_WeatherReadings_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_WeatherReadings_Var_Samp_Fields>;
  variance?: Maybe<Dw_WeatherReadings_Variance_Fields>;
};


/** aggregate fields of "dw.WeatherReadings" */
export type Dw_WeatherReadings_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_WeatherReadings_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dw.WeatherReadings" */
export type Dw_WeatherReadings_Aggregate_Order_By = {
  avg?: Maybe<Dw_WeatherReadings_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Dw_WeatherReadings_Max_Order_By>;
  min?: Maybe<Dw_WeatherReadings_Min_Order_By>;
  stddev?: Maybe<Dw_WeatherReadings_Stddev_Order_By>;
  stddev_pop?: Maybe<Dw_WeatherReadings_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Dw_WeatherReadings_Stddev_Samp_Order_By>;
  sum?: Maybe<Dw_WeatherReadings_Sum_Order_By>;
  var_pop?: Maybe<Dw_WeatherReadings_Var_Pop_Order_By>;
  var_samp?: Maybe<Dw_WeatherReadings_Var_Samp_Order_By>;
  variance?: Maybe<Dw_WeatherReadings_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "dw.WeatherReadings" */
export type Dw_WeatherReadings_Arr_Rel_Insert_Input = {
  data: Array<Dw_WeatherReadings_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_WeatherReadings_On_Conflict>;
};

/** aggregate avg on columns */
export type Dw_WeatherReadings_Avg_Fields = {
  __typename?: 'dw_WeatherReadings_avg_fields';
  Cdd?: Maybe<Scalars['Float']>;
  Dewpoint?: Maybe<Scalars['Float']>;
  FeelsLike?: Maybe<Scalars['Float']>;
  Hdd?: Maybe<Scalars['Float']>;
  Humidity?: Maybe<Scalars['Float']>;
  ReadingType?: Maybe<Scalars['Float']>;
  Temperature?: Maybe<Scalars['Float']>;
  WindSpeed?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dw.WeatherReadings" */
export type Dw_WeatherReadings_Avg_Order_By = {
  Cdd?: Maybe<Order_By>;
  Dewpoint?: Maybe<Order_By>;
  FeelsLike?: Maybe<Order_By>;
  Hdd?: Maybe<Order_By>;
  Humidity?: Maybe<Order_By>;
  ReadingType?: Maybe<Order_By>;
  Temperature?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dw.WeatherReadings". All fields are combined with a logical 'AND'. */
export type Dw_WeatherReadings_Bool_Exp = {
  Cdd?: Maybe<Numeric_Comparison_Exp>;
  Conditions?: Maybe<String_Comparison_Exp>;
  Dewpoint?: Maybe<Numeric_Comparison_Exp>;
  Event?: Maybe<Timestamp_Comparison_Exp>;
  FeelsLike?: Maybe<Numeric_Comparison_Exp>;
  Hdd?: Maybe<Numeric_Comparison_Exp>;
  Humidity?: Maybe<Int_Comparison_Exp>;
  ReadingType?: Maybe<Int_Comparison_Exp>;
  Temperature?: Maybe<Numeric_Comparison_Exp>;
  Weather?: Maybe<Dw_Weather_Bool_Exp>;
  WeatherId?: Maybe<Uuid_Comparison_Exp>;
  WindDirection?: Maybe<String_Comparison_Exp>;
  WindSpeed?: Maybe<Numeric_Comparison_Exp>;
  _and?: Maybe<Array<Dw_WeatherReadings_Bool_Exp>>;
  _not?: Maybe<Dw_WeatherReadings_Bool_Exp>;
  _or?: Maybe<Array<Dw_WeatherReadings_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.WeatherReadings" */
export enum Dw_WeatherReadings_Constraint {
  /** unique or primary key constraint */
  WeatherreadingsPk = 'weatherreadings_pk',
  /** unique or primary key constraint */
  WeatherreadingsWeatheridEventUindex = 'weatherreadings_weatherid_event_uindex'
}

/** input type for incrementing numeric columns in table "dw.WeatherReadings" */
export type Dw_WeatherReadings_Inc_Input = {
  Cdd?: Maybe<Scalars['numeric']>;
  Dewpoint?: Maybe<Scalars['numeric']>;
  FeelsLike?: Maybe<Scalars['numeric']>;
  Hdd?: Maybe<Scalars['numeric']>;
  Humidity?: Maybe<Scalars['Int']>;
  ReadingType?: Maybe<Scalars['Int']>;
  Temperature?: Maybe<Scalars['numeric']>;
  WindSpeed?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "dw.WeatherReadings" */
export type Dw_WeatherReadings_Insert_Input = {
  Cdd?: Maybe<Scalars['numeric']>;
  Conditions?: Maybe<Scalars['String']>;
  Dewpoint?: Maybe<Scalars['numeric']>;
  Event?: Maybe<Scalars['timestamp']>;
  FeelsLike?: Maybe<Scalars['numeric']>;
  Hdd?: Maybe<Scalars['numeric']>;
  Humidity?: Maybe<Scalars['Int']>;
  ReadingType?: Maybe<Scalars['Int']>;
  Temperature?: Maybe<Scalars['numeric']>;
  Weather?: Maybe<Dw_Weather_Obj_Rel_Insert_Input>;
  WeatherId?: Maybe<Scalars['uuid']>;
  WindDirection?: Maybe<Scalars['String']>;
  WindSpeed?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Dw_WeatherReadings_Max_Fields = {
  __typename?: 'dw_WeatherReadings_max_fields';
  Cdd?: Maybe<Scalars['numeric']>;
  Conditions?: Maybe<Scalars['String']>;
  Dewpoint?: Maybe<Scalars['numeric']>;
  Event?: Maybe<Scalars['timestamp']>;
  FeelsLike?: Maybe<Scalars['numeric']>;
  Hdd?: Maybe<Scalars['numeric']>;
  Humidity?: Maybe<Scalars['Int']>;
  ReadingType?: Maybe<Scalars['Int']>;
  Temperature?: Maybe<Scalars['numeric']>;
  WeatherId?: Maybe<Scalars['uuid']>;
  WindDirection?: Maybe<Scalars['String']>;
  WindSpeed?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "dw.WeatherReadings" */
export type Dw_WeatherReadings_Max_Order_By = {
  Cdd?: Maybe<Order_By>;
  Conditions?: Maybe<Order_By>;
  Dewpoint?: Maybe<Order_By>;
  Event?: Maybe<Order_By>;
  FeelsLike?: Maybe<Order_By>;
  Hdd?: Maybe<Order_By>;
  Humidity?: Maybe<Order_By>;
  ReadingType?: Maybe<Order_By>;
  Temperature?: Maybe<Order_By>;
  WeatherId?: Maybe<Order_By>;
  WindDirection?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Dw_WeatherReadings_Min_Fields = {
  __typename?: 'dw_WeatherReadings_min_fields';
  Cdd?: Maybe<Scalars['numeric']>;
  Conditions?: Maybe<Scalars['String']>;
  Dewpoint?: Maybe<Scalars['numeric']>;
  Event?: Maybe<Scalars['timestamp']>;
  FeelsLike?: Maybe<Scalars['numeric']>;
  Hdd?: Maybe<Scalars['numeric']>;
  Humidity?: Maybe<Scalars['Int']>;
  ReadingType?: Maybe<Scalars['Int']>;
  Temperature?: Maybe<Scalars['numeric']>;
  WeatherId?: Maybe<Scalars['uuid']>;
  WindDirection?: Maybe<Scalars['String']>;
  WindSpeed?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "dw.WeatherReadings" */
export type Dw_WeatherReadings_Min_Order_By = {
  Cdd?: Maybe<Order_By>;
  Conditions?: Maybe<Order_By>;
  Dewpoint?: Maybe<Order_By>;
  Event?: Maybe<Order_By>;
  FeelsLike?: Maybe<Order_By>;
  Hdd?: Maybe<Order_By>;
  Humidity?: Maybe<Order_By>;
  ReadingType?: Maybe<Order_By>;
  Temperature?: Maybe<Order_By>;
  WeatherId?: Maybe<Order_By>;
  WindDirection?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
};

/** response of any mutation on the table "dw.WeatherReadings" */
export type Dw_WeatherReadings_Mutation_Response = {
  __typename?: 'dw_WeatherReadings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_WeatherReadings>;
};

/** on conflict condition type for table "dw.WeatherReadings" */
export type Dw_WeatherReadings_On_Conflict = {
  constraint: Dw_WeatherReadings_Constraint;
  update_columns?: Array<Dw_WeatherReadings_Update_Column>;
  where?: Maybe<Dw_WeatherReadings_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.WeatherReadings". */
export type Dw_WeatherReadings_Order_By = {
  Cdd?: Maybe<Order_By>;
  Conditions?: Maybe<Order_By>;
  Dewpoint?: Maybe<Order_By>;
  Event?: Maybe<Order_By>;
  FeelsLike?: Maybe<Order_By>;
  Hdd?: Maybe<Order_By>;
  Humidity?: Maybe<Order_By>;
  ReadingType?: Maybe<Order_By>;
  Temperature?: Maybe<Order_By>;
  Weather?: Maybe<Dw_Weather_Order_By>;
  WeatherId?: Maybe<Order_By>;
  WindDirection?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
};

/** primary key columns input for table: dw_WeatherReadings */
export type Dw_WeatherReadings_Pk_Columns_Input = {
  Event: Scalars['timestamp'];
  WeatherId: Scalars['uuid'];
};

/** select columns of table "dw.WeatherReadings" */
export enum Dw_WeatherReadings_Select_Column {
  /** column name */
  Cdd = 'Cdd',
  /** column name */
  Conditions = 'Conditions',
  /** column name */
  Dewpoint = 'Dewpoint',
  /** column name */
  Event = 'Event',
  /** column name */
  FeelsLike = 'FeelsLike',
  /** column name */
  Hdd = 'Hdd',
  /** column name */
  Humidity = 'Humidity',
  /** column name */
  ReadingType = 'ReadingType',
  /** column name */
  Temperature = 'Temperature',
  /** column name */
  WeatherId = 'WeatherId',
  /** column name */
  WindDirection = 'WindDirection',
  /** column name */
  WindSpeed = 'WindSpeed'
}

/** input type for updating data in table "dw.WeatherReadings" */
export type Dw_WeatherReadings_Set_Input = {
  Cdd?: Maybe<Scalars['numeric']>;
  Conditions?: Maybe<Scalars['String']>;
  Dewpoint?: Maybe<Scalars['numeric']>;
  Event?: Maybe<Scalars['timestamp']>;
  FeelsLike?: Maybe<Scalars['numeric']>;
  Hdd?: Maybe<Scalars['numeric']>;
  Humidity?: Maybe<Scalars['Int']>;
  ReadingType?: Maybe<Scalars['Int']>;
  Temperature?: Maybe<Scalars['numeric']>;
  WeatherId?: Maybe<Scalars['uuid']>;
  WindDirection?: Maybe<Scalars['String']>;
  WindSpeed?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Dw_WeatherReadings_Stddev_Fields = {
  __typename?: 'dw_WeatherReadings_stddev_fields';
  Cdd?: Maybe<Scalars['Float']>;
  Dewpoint?: Maybe<Scalars['Float']>;
  FeelsLike?: Maybe<Scalars['Float']>;
  Hdd?: Maybe<Scalars['Float']>;
  Humidity?: Maybe<Scalars['Float']>;
  ReadingType?: Maybe<Scalars['Float']>;
  Temperature?: Maybe<Scalars['Float']>;
  WindSpeed?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dw.WeatherReadings" */
export type Dw_WeatherReadings_Stddev_Order_By = {
  Cdd?: Maybe<Order_By>;
  Dewpoint?: Maybe<Order_By>;
  FeelsLike?: Maybe<Order_By>;
  Hdd?: Maybe<Order_By>;
  Humidity?: Maybe<Order_By>;
  ReadingType?: Maybe<Order_By>;
  Temperature?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dw_WeatherReadings_Stddev_Pop_Fields = {
  __typename?: 'dw_WeatherReadings_stddev_pop_fields';
  Cdd?: Maybe<Scalars['Float']>;
  Dewpoint?: Maybe<Scalars['Float']>;
  FeelsLike?: Maybe<Scalars['Float']>;
  Hdd?: Maybe<Scalars['Float']>;
  Humidity?: Maybe<Scalars['Float']>;
  ReadingType?: Maybe<Scalars['Float']>;
  Temperature?: Maybe<Scalars['Float']>;
  WindSpeed?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dw.WeatherReadings" */
export type Dw_WeatherReadings_Stddev_Pop_Order_By = {
  Cdd?: Maybe<Order_By>;
  Dewpoint?: Maybe<Order_By>;
  FeelsLike?: Maybe<Order_By>;
  Hdd?: Maybe<Order_By>;
  Humidity?: Maybe<Order_By>;
  ReadingType?: Maybe<Order_By>;
  Temperature?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dw_WeatherReadings_Stddev_Samp_Fields = {
  __typename?: 'dw_WeatherReadings_stddev_samp_fields';
  Cdd?: Maybe<Scalars['Float']>;
  Dewpoint?: Maybe<Scalars['Float']>;
  FeelsLike?: Maybe<Scalars['Float']>;
  Hdd?: Maybe<Scalars['Float']>;
  Humidity?: Maybe<Scalars['Float']>;
  ReadingType?: Maybe<Scalars['Float']>;
  Temperature?: Maybe<Scalars['Float']>;
  WindSpeed?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dw.WeatherReadings" */
export type Dw_WeatherReadings_Stddev_Samp_Order_By = {
  Cdd?: Maybe<Order_By>;
  Dewpoint?: Maybe<Order_By>;
  FeelsLike?: Maybe<Order_By>;
  Hdd?: Maybe<Order_By>;
  Humidity?: Maybe<Order_By>;
  ReadingType?: Maybe<Order_By>;
  Temperature?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Dw_WeatherReadings_Sum_Fields = {
  __typename?: 'dw_WeatherReadings_sum_fields';
  Cdd?: Maybe<Scalars['numeric']>;
  Dewpoint?: Maybe<Scalars['numeric']>;
  FeelsLike?: Maybe<Scalars['numeric']>;
  Hdd?: Maybe<Scalars['numeric']>;
  Humidity?: Maybe<Scalars['Int']>;
  ReadingType?: Maybe<Scalars['Int']>;
  Temperature?: Maybe<Scalars['numeric']>;
  WindSpeed?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "dw.WeatherReadings" */
export type Dw_WeatherReadings_Sum_Order_By = {
  Cdd?: Maybe<Order_By>;
  Dewpoint?: Maybe<Order_By>;
  FeelsLike?: Maybe<Order_By>;
  Hdd?: Maybe<Order_By>;
  Humidity?: Maybe<Order_By>;
  ReadingType?: Maybe<Order_By>;
  Temperature?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
};

/** update columns of table "dw.WeatherReadings" */
export enum Dw_WeatherReadings_Update_Column {
  /** column name */
  Cdd = 'Cdd',
  /** column name */
  Conditions = 'Conditions',
  /** column name */
  Dewpoint = 'Dewpoint',
  /** column name */
  Event = 'Event',
  /** column name */
  FeelsLike = 'FeelsLike',
  /** column name */
  Hdd = 'Hdd',
  /** column name */
  Humidity = 'Humidity',
  /** column name */
  ReadingType = 'ReadingType',
  /** column name */
  Temperature = 'Temperature',
  /** column name */
  WeatherId = 'WeatherId',
  /** column name */
  WindDirection = 'WindDirection',
  /** column name */
  WindSpeed = 'WindSpeed'
}

/** aggregate var_pop on columns */
export type Dw_WeatherReadings_Var_Pop_Fields = {
  __typename?: 'dw_WeatherReadings_var_pop_fields';
  Cdd?: Maybe<Scalars['Float']>;
  Dewpoint?: Maybe<Scalars['Float']>;
  FeelsLike?: Maybe<Scalars['Float']>;
  Hdd?: Maybe<Scalars['Float']>;
  Humidity?: Maybe<Scalars['Float']>;
  ReadingType?: Maybe<Scalars['Float']>;
  Temperature?: Maybe<Scalars['Float']>;
  WindSpeed?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dw.WeatherReadings" */
export type Dw_WeatherReadings_Var_Pop_Order_By = {
  Cdd?: Maybe<Order_By>;
  Dewpoint?: Maybe<Order_By>;
  FeelsLike?: Maybe<Order_By>;
  Hdd?: Maybe<Order_By>;
  Humidity?: Maybe<Order_By>;
  ReadingType?: Maybe<Order_By>;
  Temperature?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dw_WeatherReadings_Var_Samp_Fields = {
  __typename?: 'dw_WeatherReadings_var_samp_fields';
  Cdd?: Maybe<Scalars['Float']>;
  Dewpoint?: Maybe<Scalars['Float']>;
  FeelsLike?: Maybe<Scalars['Float']>;
  Hdd?: Maybe<Scalars['Float']>;
  Humidity?: Maybe<Scalars['Float']>;
  ReadingType?: Maybe<Scalars['Float']>;
  Temperature?: Maybe<Scalars['Float']>;
  WindSpeed?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dw.WeatherReadings" */
export type Dw_WeatherReadings_Var_Samp_Order_By = {
  Cdd?: Maybe<Order_By>;
  Dewpoint?: Maybe<Order_By>;
  FeelsLike?: Maybe<Order_By>;
  Hdd?: Maybe<Order_By>;
  Humidity?: Maybe<Order_By>;
  ReadingType?: Maybe<Order_By>;
  Temperature?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Dw_WeatherReadings_Variance_Fields = {
  __typename?: 'dw_WeatherReadings_variance_fields';
  Cdd?: Maybe<Scalars['Float']>;
  Dewpoint?: Maybe<Scalars['Float']>;
  FeelsLike?: Maybe<Scalars['Float']>;
  Hdd?: Maybe<Scalars['Float']>;
  Humidity?: Maybe<Scalars['Float']>;
  ReadingType?: Maybe<Scalars['Float']>;
  Temperature?: Maybe<Scalars['Float']>;
  WindSpeed?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dw.WeatherReadings" */
export type Dw_WeatherReadings_Variance_Order_By = {
  Cdd?: Maybe<Order_By>;
  Dewpoint?: Maybe<Order_By>;
  FeelsLike?: Maybe<Order_By>;
  Hdd?: Maybe<Order_By>;
  Humidity?: Maybe<Order_By>;
  ReadingType?: Maybe<Order_By>;
  Temperature?: Maybe<Order_By>;
  WindSpeed?: Maybe<Order_By>;
};

/** aggregated selection of "dw.Weather" */
export type Dw_Weather_Aggregate = {
  __typename?: 'dw_Weather_aggregate';
  aggregate?: Maybe<Dw_Weather_Aggregate_Fields>;
  nodes: Array<Dw_Weather>;
};

/** aggregate fields of "dw.Weather" */
export type Dw_Weather_Aggregate_Fields = {
  __typename?: 'dw_Weather_aggregate_fields';
  avg?: Maybe<Dw_Weather_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dw_Weather_Max_Fields>;
  min?: Maybe<Dw_Weather_Min_Fields>;
  stddev?: Maybe<Dw_Weather_Stddev_Fields>;
  stddev_pop?: Maybe<Dw_Weather_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dw_Weather_Stddev_Samp_Fields>;
  sum?: Maybe<Dw_Weather_Sum_Fields>;
  var_pop?: Maybe<Dw_Weather_Var_Pop_Fields>;
  var_samp?: Maybe<Dw_Weather_Var_Samp_Fields>;
  variance?: Maybe<Dw_Weather_Variance_Fields>;
};


/** aggregate fields of "dw.Weather" */
export type Dw_Weather_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dw_Weather_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dw_Weather_Avg_Fields = {
  __typename?: 'dw_Weather_avg_fields';
  Latitude?: Maybe<Scalars['Float']>;
  Longitude?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dw.Weather". All fields are combined with a logical 'AND'. */
export type Dw_Weather_Bool_Exp = {
  Address?: Maybe<String_Comparison_Exp>;
  City?: Maybe<String_Comparison_Exp>;
  Country?: Maybe<String_Comparison_Exp>;
  CreatedOn?: Maybe<Timestamp_Comparison_Exp>;
  LatestDataPoint?: Maybe<Timestamp_Comparison_Exp>;
  Latitude?: Maybe<Numeric_Comparison_Exp>;
  Longitude?: Maybe<Numeric_Comparison_Exp>;
  StartFrom?: Maybe<Timestamp_Comparison_Exp>;
  Status?: Maybe<Smallint_Comparison_Exp>;
  UpdatedOn?: Maybe<Timestamp_Comparison_Exp>;
  WeatherId?: Maybe<Uuid_Comparison_Exp>;
  WeatherReadings?: Maybe<Dw_WeatherReadings_Bool_Exp>;
  _and?: Maybe<Array<Dw_Weather_Bool_Exp>>;
  _not?: Maybe<Dw_Weather_Bool_Exp>;
  _or?: Maybe<Array<Dw_Weather_Bool_Exp>>;
};

/** unique or primary key constraints on table "dw.Weather" */
export enum Dw_Weather_Constraint {
  /** unique or primary key constraint */
  WeatherPk = 'weather_pk',
  /** unique or primary key constraint */
  WeatherWeatheridUindex = 'weather_weatherid_uindex'
}

/** input type for incrementing numeric columns in table "dw.Weather" */
export type Dw_Weather_Inc_Input = {
  Latitude?: Maybe<Scalars['numeric']>;
  Longitude?: Maybe<Scalars['numeric']>;
  Status?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "dw.Weather" */
export type Dw_Weather_Insert_Input = {
  Address?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  LatestDataPoint?: Maybe<Scalars['timestamp']>;
  Latitude?: Maybe<Scalars['numeric']>;
  Longitude?: Maybe<Scalars['numeric']>;
  StartFrom?: Maybe<Scalars['timestamp']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  WeatherId?: Maybe<Scalars['uuid']>;
  WeatherReadings?: Maybe<Dw_WeatherReadings_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Dw_Weather_Max_Fields = {
  __typename?: 'dw_Weather_max_fields';
  Address?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  LatestDataPoint?: Maybe<Scalars['timestamp']>;
  Latitude?: Maybe<Scalars['numeric']>;
  Longitude?: Maybe<Scalars['numeric']>;
  StartFrom?: Maybe<Scalars['timestamp']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  WeatherId?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Dw_Weather_Min_Fields = {
  __typename?: 'dw_Weather_min_fields';
  Address?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  LatestDataPoint?: Maybe<Scalars['timestamp']>;
  Latitude?: Maybe<Scalars['numeric']>;
  Longitude?: Maybe<Scalars['numeric']>;
  StartFrom?: Maybe<Scalars['timestamp']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  WeatherId?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "dw.Weather" */
export type Dw_Weather_Mutation_Response = {
  __typename?: 'dw_Weather_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dw_Weather>;
};

/** input type for inserting object relation for remote table "dw.Weather" */
export type Dw_Weather_Obj_Rel_Insert_Input = {
  data: Dw_Weather_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Dw_Weather_On_Conflict>;
};

/** on conflict condition type for table "dw.Weather" */
export type Dw_Weather_On_Conflict = {
  constraint: Dw_Weather_Constraint;
  update_columns?: Array<Dw_Weather_Update_Column>;
  where?: Maybe<Dw_Weather_Bool_Exp>;
};

/** Ordering options when selecting data from "dw.Weather". */
export type Dw_Weather_Order_By = {
  Address?: Maybe<Order_By>;
  City?: Maybe<Order_By>;
  Country?: Maybe<Order_By>;
  CreatedOn?: Maybe<Order_By>;
  LatestDataPoint?: Maybe<Order_By>;
  Latitude?: Maybe<Order_By>;
  Longitude?: Maybe<Order_By>;
  StartFrom?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UpdatedOn?: Maybe<Order_By>;
  WeatherId?: Maybe<Order_By>;
  WeatherReadings_aggregate?: Maybe<Dw_WeatherReadings_Aggregate_Order_By>;
};

/** primary key columns input for table: dw_Weather */
export type Dw_Weather_Pk_Columns_Input = {
  WeatherId: Scalars['uuid'];
};

/** select columns of table "dw.Weather" */
export enum Dw_Weather_Select_Column {
  /** column name */
  Address = 'Address',
  /** column name */
  City = 'City',
  /** column name */
  Country = 'Country',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  LatestDataPoint = 'LatestDataPoint',
  /** column name */
  Latitude = 'Latitude',
  /** column name */
  Longitude = 'Longitude',
  /** column name */
  StartFrom = 'StartFrom',
  /** column name */
  Status = 'Status',
  /** column name */
  UpdatedOn = 'UpdatedOn',
  /** column name */
  WeatherId = 'WeatherId'
}

/** input type for updating data in table "dw.Weather" */
export type Dw_Weather_Set_Input = {
  Address?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  LatestDataPoint?: Maybe<Scalars['timestamp']>;
  Latitude?: Maybe<Scalars['numeric']>;
  Longitude?: Maybe<Scalars['numeric']>;
  StartFrom?: Maybe<Scalars['timestamp']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn?: Maybe<Scalars['timestamp']>;
  WeatherId?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Dw_Weather_Stddev_Fields = {
  __typename?: 'dw_Weather_stddev_fields';
  Latitude?: Maybe<Scalars['Float']>;
  Longitude?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dw_Weather_Stddev_Pop_Fields = {
  __typename?: 'dw_Weather_stddev_pop_fields';
  Latitude?: Maybe<Scalars['Float']>;
  Longitude?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dw_Weather_Stddev_Samp_Fields = {
  __typename?: 'dw_Weather_stddev_samp_fields';
  Latitude?: Maybe<Scalars['Float']>;
  Longitude?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Dw_Weather_Sum_Fields = {
  __typename?: 'dw_Weather_sum_fields';
  Latitude?: Maybe<Scalars['numeric']>;
  Longitude?: Maybe<Scalars['numeric']>;
  Status?: Maybe<Scalars['smallint']>;
};

/** update columns of table "dw.Weather" */
export enum Dw_Weather_Update_Column {
  /** column name */
  Address = 'Address',
  /** column name */
  City = 'City',
  /** column name */
  Country = 'Country',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  LatestDataPoint = 'LatestDataPoint',
  /** column name */
  Latitude = 'Latitude',
  /** column name */
  Longitude = 'Longitude',
  /** column name */
  StartFrom = 'StartFrom',
  /** column name */
  Status = 'Status',
  /** column name */
  UpdatedOn = 'UpdatedOn',
  /** column name */
  WeatherId = 'WeatherId'
}

/** aggregate var_pop on columns */
export type Dw_Weather_Var_Pop_Fields = {
  __typename?: 'dw_Weather_var_pop_fields';
  Latitude?: Maybe<Scalars['Float']>;
  Longitude?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dw_Weather_Var_Samp_Fields = {
  __typename?: 'dw_Weather_var_samp_fields';
  Latitude?: Maybe<Scalars['Float']>;
  Longitude?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dw_Weather_Variance_Fields = {
  __typename?: 'dw_Weather_variance_fields';
  Latitude?: Maybe<Scalars['Float']>;
  Longitude?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['Float']>;
};


/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: Maybe<Scalars['float8']>;
  _gt?: Maybe<Scalars['float8']>;
  _gte?: Maybe<Scalars['float8']>;
  _in?: Maybe<Array<Scalars['float8']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['float8']>;
  _lte?: Maybe<Scalars['float8']>;
  _neq?: Maybe<Scalars['float8']>;
  _nin?: Maybe<Array<Scalars['float8']>>;
};


/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: Maybe<Scalars['json']>;
  _gt?: Maybe<Scalars['json']>;
  _gte?: Maybe<Scalars['json']>;
  _in?: Maybe<Array<Scalars['json']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['json']>;
  _lte?: Maybe<Scalars['json']>;
  _neq?: Maybe<Scalars['json']>;
  _nin?: Maybe<Array<Scalars['json']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "dw.Accounts" */
  delete_dw_Accounts?: Maybe<Dw_Accounts_Mutation_Response>;
  /** delete data from the table: "dw.AccountsAddress" */
  delete_dw_AccountsAddress?: Maybe<Dw_AccountsAddress_Mutation_Response>;
  /** delete single row from the table: "dw.AccountsAddress" */
  delete_dw_AccountsAddress_by_pk?: Maybe<Dw_AccountsAddress>;
  /** delete data from the table: "dw.AccountsSubscription" */
  delete_dw_AccountsSubscription?: Maybe<Dw_AccountsSubscription_Mutation_Response>;
  /** delete single row from the table: "dw.AccountsSubscription" */
  delete_dw_AccountsSubscription_by_pk?: Maybe<Dw_AccountsSubscription>;
  /** delete single row from the table: "dw.Accounts" */
  delete_dw_Accounts_by_pk?: Maybe<Dw_Accounts>;
  /** delete data from the table: "dw.Assessments" */
  delete_dw_Assessments?: Maybe<Dw_Assessments_Mutation_Response>;
  /** delete single row from the table: "dw.Assessments" */
  delete_dw_Assessments_by_pk?: Maybe<Dw_Assessments>;
  /** delete data from the table: "dw.Baseline" */
  delete_dw_Baseline?: Maybe<Dw_Baseline_Mutation_Response>;
  /** delete single row from the table: "dw.Baseline" */
  delete_dw_Baseline_by_pk?: Maybe<Dw_Baseline>;
  /** delete data from the table: "dw.BuildingDocuments" */
  delete_dw_BuildingDocuments?: Maybe<Dw_BuildingDocuments_Mutation_Response>;
  /** delete single row from the table: "dw.BuildingDocuments" */
  delete_dw_BuildingDocuments_by_pk?: Maybe<Dw_BuildingDocuments>;
  /** delete data from the table: "dw.Buildings" */
  delete_dw_Buildings?: Maybe<Dw_Buildings_Mutation_Response>;
  /** delete single row from the table: "dw.Buildings" */
  delete_dw_Buildings_by_pk?: Maybe<Dw_Buildings>;
  /** delete data from the table: "dw.Channels" */
  delete_dw_Channels?: Maybe<Dw_Channels_Mutation_Response>;
  /** delete data from the table: "dw.ChannelsJobs" */
  delete_dw_ChannelsJobs?: Maybe<Dw_ChannelsJobs_Mutation_Response>;
  /** delete single row from the table: "dw.ChannelsJobs" */
  delete_dw_ChannelsJobs_by_pk?: Maybe<Dw_ChannelsJobs>;
  /** delete data from the table: "dw.ChannelsMappings" */
  delete_dw_ChannelsMappings?: Maybe<Dw_ChannelsMappings_Mutation_Response>;
  /** delete single row from the table: "dw.ChannelsMappings" */
  delete_dw_ChannelsMappings_by_pk?: Maybe<Dw_ChannelsMappings>;
  /** delete data from the table: "dw.ChannelsMetadata" */
  delete_dw_ChannelsMetadata?: Maybe<Dw_ChannelsMetadata_Mutation_Response>;
  /** delete single row from the table: "dw.ChannelsMetadata" */
  delete_dw_ChannelsMetadata_by_pk?: Maybe<Dw_ChannelsMetadata>;
  /** delete data from the table: "dw.ChannelsRules" */
  delete_dw_ChannelsRules?: Maybe<Dw_ChannelsRules_Mutation_Response>;
  /** delete single row from the table: "dw.ChannelsRules" */
  delete_dw_ChannelsRules_by_pk?: Maybe<Dw_ChannelsRules>;
  /** delete single row from the table: "dw.Channels" */
  delete_dw_Channels_by_pk?: Maybe<Dw_Channels>;
  /** delete data from the table: "dw.Consumption" */
  delete_dw_Consumption?: Maybe<Dw_Consumption_Mutation_Response>;
  /** delete data from the table: "dw.ConsumptionJobs" */
  delete_dw_ConsumptionJobs?: Maybe<Dw_ConsumptionJobs_Mutation_Response>;
  /** delete single row from the table: "dw.ConsumptionJobs" */
  delete_dw_ConsumptionJobs_by_pk?: Maybe<Dw_ConsumptionJobs>;
  /** delete single row from the table: "dw.Consumption" */
  delete_dw_Consumption_by_pk?: Maybe<Dw_Consumption>;
  /** delete data from the table: "dw.Contacts" */
  delete_dw_Contacts?: Maybe<Dw_Contacts_Mutation_Response>;
  /** delete data from the table: "dw.ContactsRole" */
  delete_dw_ContactsRole?: Maybe<Dw_ContactsRole_Mutation_Response>;
  /** delete single row from the table: "dw.Contacts" */
  delete_dw_Contacts_by_pk?: Maybe<Dw_Contacts>;
  /** delete data from the table: "dw.Dashboards" */
  delete_dw_Dashboards?: Maybe<Dw_Dashboards_Mutation_Response>;
  /** delete data from the table: "dw.DashboardsContent" */
  delete_dw_DashboardsContent?: Maybe<Dw_DashboardsContent_Mutation_Response>;
  /** delete single row from the table: "dw.DashboardsContent" */
  delete_dw_DashboardsContent_by_pk?: Maybe<Dw_DashboardsContent>;
  /** delete data from the table: "dw.DashboardsMetric" */
  delete_dw_DashboardsMetric?: Maybe<Dw_DashboardsMetric_Mutation_Response>;
  /** delete single row from the table: "dw.DashboardsMetric" */
  delete_dw_DashboardsMetric_by_pk?: Maybe<Dw_DashboardsMetric>;
  /** delete single row from the table: "dw.Dashboards" */
  delete_dw_Dashboards_by_pk?: Maybe<Dw_Dashboards>;
  /** delete data from the table: "dw.Documents" */
  delete_dw_Documents?: Maybe<Dw_Documents_Mutation_Response>;
  /** delete single row from the table: "dw.Documents" */
  delete_dw_Documents_by_pk?: Maybe<Dw_Documents>;
  /** delete data from the table: "dw.EmissionFactorCategories" */
  delete_dw_EmissionFactorCategories?: Maybe<Dw_EmissionFactorCategories_Mutation_Response>;
  /** delete single row from the table: "dw.EmissionFactorCategories" */
  delete_dw_EmissionFactorCategories_by_pk?: Maybe<Dw_EmissionFactorCategories>;
  /** delete data from the table: "dw.EmissionFactorSource" */
  delete_dw_EmissionFactorSource?: Maybe<Dw_EmissionFactorSource_Mutation_Response>;
  /** delete single row from the table: "dw.EmissionFactorSource" */
  delete_dw_EmissionFactorSource_by_pk?: Maybe<Dw_EmissionFactorSource>;
  /** delete data from the table: "dw.EmissionFactors" */
  delete_dw_EmissionFactors?: Maybe<Dw_EmissionFactors_Mutation_Response>;
  /** delete single row from the table: "dw.EmissionFactors" */
  delete_dw_EmissionFactors_by_pk?: Maybe<Dw_EmissionFactors>;
  /** delete data from the table: "dw.Feeds" */
  delete_dw_Feeds?: Maybe<Dw_Feeds_Mutation_Response>;
  /** delete data from the table: "dw.FeedsConfigBacNet" */
  delete_dw_FeedsConfigBacNet?: Maybe<Dw_FeedsConfigBacNet_Mutation_Response>;
  /** delete single row from the table: "dw.FeedsConfigBacNet" */
  delete_dw_FeedsConfigBacNet_by_pk?: Maybe<Dw_FeedsConfigBacNet>;
  /** delete data from the table: "dw.FeedsConfigChannels" */
  delete_dw_FeedsConfigChannels?: Maybe<Dw_FeedsConfigChannels_Mutation_Response>;
  /** delete single row from the table: "dw.FeedsConfigChannels" */
  delete_dw_FeedsConfigChannels_by_pk?: Maybe<Dw_FeedsConfigChannels>;
  /** delete data from the table: "dw.FeedsConfigModbus" */
  delete_dw_FeedsConfigModbus?: Maybe<Dw_FeedsConfigModbus_Mutation_Response>;
  /** delete single row from the table: "dw.FeedsConfigModbus" */
  delete_dw_FeedsConfigModbus_by_pk?: Maybe<Dw_FeedsConfigModbus>;
  /** delete data from the table: "dw.FeedsLogs" */
  delete_dw_FeedsLogs?: Maybe<Dw_FeedsLogs_Mutation_Response>;
  /** delete single row from the table: "dw.FeedsLogs" */
  delete_dw_FeedsLogs_by_pk?: Maybe<Dw_FeedsLogs>;
  /** delete data from the table: "dw.FeedsTypes" */
  delete_dw_FeedsTypes?: Maybe<Dw_FeedsTypes_Mutation_Response>;
  /** delete single row from the table: "dw.FeedsTypes" */
  delete_dw_FeedsTypes_by_pk?: Maybe<Dw_FeedsTypes>;
  /** delete single row from the table: "dw.Feeds" */
  delete_dw_Feeds_by_pk?: Maybe<Dw_Feeds>;
  /** delete data from the table: "dw.Groups" */
  delete_dw_Groups?: Maybe<Dw_Groups_Mutation_Response>;
  /** delete data from the table: "dw.GroupsSites" */
  delete_dw_GroupsSites?: Maybe<Dw_GroupsSites_Mutation_Response>;
  /** delete single row from the table: "dw.GroupsSites" */
  delete_dw_GroupsSites_by_pk?: Maybe<Dw_GroupsSites>;
  /** delete single row from the table: "dw.Groups" */
  delete_dw_Groups_by_pk?: Maybe<Dw_Groups>;
  /** delete data from the table: "dw.History" */
  delete_dw_History?: Maybe<Dw_History_Mutation_Response>;
  /** delete single row from the table: "dw.History" */
  delete_dw_History_by_pk?: Maybe<Dw_History>;
  /** delete data from the table: "dw.LoadDocuments" */
  delete_dw_LoadDocuments?: Maybe<Dw_LoadDocuments_Mutation_Response>;
  /** delete single row from the table: "dw.LoadDocuments" */
  delete_dw_LoadDocuments_by_pk?: Maybe<Dw_LoadDocuments>;
  /** delete data from the table: "dw.Loads" */
  delete_dw_Loads?: Maybe<Dw_Loads_Mutation_Response>;
  /** delete data from the table: "dw.LoadsService" */
  delete_dw_LoadsService?: Maybe<Dw_LoadsService_Mutation_Response>;
  /** delete single row from the table: "dw.LoadsService" */
  delete_dw_LoadsService_by_pk?: Maybe<Dw_LoadsService>;
  /** delete data from the table: "dw.LoadsType" */
  delete_dw_LoadsType?: Maybe<Dw_LoadsType_Mutation_Response>;
  /** delete single row from the table: "dw.LoadsType" */
  delete_dw_LoadsType_by_pk?: Maybe<Dw_LoadsType>;
  /** delete single row from the table: "dw.Loads" */
  delete_dw_Loads_by_pk?: Maybe<Dw_Loads>;
  /** delete data from the table: "dw.MLModelsMetadata" */
  delete_dw_MLModelsMetadata?: Maybe<Dw_MlModelsMetadata_Mutation_Response>;
  /** delete single row from the table: "dw.MLModelsMetadata" */
  delete_dw_MLModelsMetadata_by_pk?: Maybe<Dw_MlModelsMetadata>;
  /** delete data from the table: "dw.MLNotificationForecasts" */
  delete_dw_MLNotificationForecasts?: Maybe<Dw_MlNotificationForecasts_Mutation_Response>;
  /** delete data from the table: "dw.MLNotificationGroup" */
  delete_dw_MLNotificationGroup?: Maybe<Dw_MlNotificationGroup_Mutation_Response>;
  /** delete single row from the table: "dw.MLNotificationGroup" */
  delete_dw_MLNotificationGroup_by_pk?: Maybe<Dw_MlNotificationGroup>;
  /** delete data from the table: "dw.MLNotifications" */
  delete_dw_MLNotifications?: Maybe<Dw_MlNotifications_Mutation_Response>;
  /** delete data from the table: "dw.MLNotificationsCorrelation" */
  delete_dw_MLNotificationsCorrelation?: Maybe<Dw_MlNotificationsCorrelation_Mutation_Response>;
  /** delete single row from the table: "dw.MLNotificationsCorrelation" */
  delete_dw_MLNotificationsCorrelation_by_pk?: Maybe<Dw_MlNotificationsCorrelation>;
  /** delete data from the table: "dw.MLNotificationsMetadata" */
  delete_dw_MLNotificationsMetadata?: Maybe<Dw_MlNotificationsMetadata_Mutation_Response>;
  /** delete single row from the table: "dw.MLNotificationsMetadata" */
  delete_dw_MLNotificationsMetadata_by_pk?: Maybe<Dw_MlNotificationsMetadata>;
  /** delete single row from the table: "dw.MLNotifications" */
  delete_dw_MLNotifications_by_pk?: Maybe<Dw_MlNotifications>;
  /** delete data from the table: "dw.Notifications" */
  delete_dw_Notifications?: Maybe<Dw_Notifications_Mutation_Response>;
  /** delete data from the table: "dw.NotificationsEvent" */
  delete_dw_NotificationsEvent?: Maybe<Dw_NotificationsEvent_Mutation_Response>;
  /** delete single row from the table: "dw.NotificationsEvent" */
  delete_dw_NotificationsEvent_by_pk?: Maybe<Dw_NotificationsEvent>;
  /** delete data from the table: "dw.NotificationsLog" */
  delete_dw_NotificationsLog?: Maybe<Dw_NotificationsLog_Mutation_Response>;
  /** delete single row from the table: "dw.NotificationsLog" */
  delete_dw_NotificationsLog_by_pk?: Maybe<Dw_NotificationsLog>;
  /** delete data from the table: "dw.NotificationsVisibility" */
  delete_dw_NotificationsVisibility?: Maybe<Dw_NotificationsVisibility_Mutation_Response>;
  /** delete single row from the table: "dw.NotificationsVisibility" */
  delete_dw_NotificationsVisibility_by_pk?: Maybe<Dw_NotificationsVisibility>;
  /** delete single row from the table: "dw.Notifications" */
  delete_dw_Notifications_by_pk?: Maybe<Dw_Notifications>;
  /** delete data from the table: "dw.Plans" */
  delete_dw_Plans?: Maybe<Dw_Plans_Mutation_Response>;
  /** delete single row from the table: "dw.Plans" */
  delete_dw_Plans_by_pk?: Maybe<Dw_Plans>;
  /** delete data from the table: "dw.Projects" */
  delete_dw_Projects?: Maybe<Dw_Projects_Mutation_Response>;
  /** delete single row from the table: "dw.Projects" */
  delete_dw_Projects_by_pk?: Maybe<Dw_Projects>;
  /** delete data from the table: "dw.Reports" */
  delete_dw_Reports?: Maybe<Dw_Reports_Mutation_Response>;
  /** delete single row from the table: "dw.Reports" */
  delete_dw_Reports_by_pk?: Maybe<Dw_Reports>;
  /** delete data from the table: "dw.Sessions" */
  delete_dw_Sessions?: Maybe<Dw_Sessions_Mutation_Response>;
  /** delete single row from the table: "dw.Sessions" */
  delete_dw_Sessions_by_pk?: Maybe<Dw_Sessions>;
  /** delete data from the table: "dw.Sites" */
  delete_dw_Sites?: Maybe<Dw_Sites_Mutation_Response>;
  /** delete single row from the table: "dw.Sites" */
  delete_dw_Sites_by_pk?: Maybe<Dw_Sites>;
  /** delete data from the table: "dw.Weather" */
  delete_dw_Weather?: Maybe<Dw_Weather_Mutation_Response>;
  /** delete data from the table: "dw.WeatherReadings" */
  delete_dw_WeatherReadings?: Maybe<Dw_WeatherReadings_Mutation_Response>;
  /** delete single row from the table: "dw.WeatherReadings" */
  delete_dw_WeatherReadings_by_pk?: Maybe<Dw_WeatherReadings>;
  /** delete single row from the table: "dw.Weather" */
  delete_dw_Weather_by_pk?: Maybe<Dw_Weather>;
  /** insert data into the table: "dw.Accounts" */
  insert_dw_Accounts?: Maybe<Dw_Accounts_Mutation_Response>;
  /** insert data into the table: "dw.AccountsAddress" */
  insert_dw_AccountsAddress?: Maybe<Dw_AccountsAddress_Mutation_Response>;
  /** insert a single row into the table: "dw.AccountsAddress" */
  insert_dw_AccountsAddress_one?: Maybe<Dw_AccountsAddress>;
  /** insert data into the table: "dw.AccountsSubscription" */
  insert_dw_AccountsSubscription?: Maybe<Dw_AccountsSubscription_Mutation_Response>;
  /** insert a single row into the table: "dw.AccountsSubscription" */
  insert_dw_AccountsSubscription_one?: Maybe<Dw_AccountsSubscription>;
  /** insert a single row into the table: "dw.Accounts" */
  insert_dw_Accounts_one?: Maybe<Dw_Accounts>;
  /** insert data into the table: "dw.Assessments" */
  insert_dw_Assessments?: Maybe<Dw_Assessments_Mutation_Response>;
  /** insert a single row into the table: "dw.Assessments" */
  insert_dw_Assessments_one?: Maybe<Dw_Assessments>;
  /** insert data into the table: "dw.Baseline" */
  insert_dw_Baseline?: Maybe<Dw_Baseline_Mutation_Response>;
  /** insert a single row into the table: "dw.Baseline" */
  insert_dw_Baseline_one?: Maybe<Dw_Baseline>;
  /** insert data into the table: "dw.BuildingDocuments" */
  insert_dw_BuildingDocuments?: Maybe<Dw_BuildingDocuments_Mutation_Response>;
  /** insert a single row into the table: "dw.BuildingDocuments" */
  insert_dw_BuildingDocuments_one?: Maybe<Dw_BuildingDocuments>;
  /** insert data into the table: "dw.Buildings" */
  insert_dw_Buildings?: Maybe<Dw_Buildings_Mutation_Response>;
  /** insert a single row into the table: "dw.Buildings" */
  insert_dw_Buildings_one?: Maybe<Dw_Buildings>;
  /** insert data into the table: "dw.Channels" */
  insert_dw_Channels?: Maybe<Dw_Channels_Mutation_Response>;
  /** insert data into the table: "dw.ChannelsJobs" */
  insert_dw_ChannelsJobs?: Maybe<Dw_ChannelsJobs_Mutation_Response>;
  /** insert a single row into the table: "dw.ChannelsJobs" */
  insert_dw_ChannelsJobs_one?: Maybe<Dw_ChannelsJobs>;
  /** insert data into the table: "dw.ChannelsMappings" */
  insert_dw_ChannelsMappings?: Maybe<Dw_ChannelsMappings_Mutation_Response>;
  /** insert a single row into the table: "dw.ChannelsMappings" */
  insert_dw_ChannelsMappings_one?: Maybe<Dw_ChannelsMappings>;
  /** insert data into the table: "dw.ChannelsMetadata" */
  insert_dw_ChannelsMetadata?: Maybe<Dw_ChannelsMetadata_Mutation_Response>;
  /** insert a single row into the table: "dw.ChannelsMetadata" */
  insert_dw_ChannelsMetadata_one?: Maybe<Dw_ChannelsMetadata>;
  /** insert data into the table: "dw.ChannelsRules" */
  insert_dw_ChannelsRules?: Maybe<Dw_ChannelsRules_Mutation_Response>;
  /** insert a single row into the table: "dw.ChannelsRules" */
  insert_dw_ChannelsRules_one?: Maybe<Dw_ChannelsRules>;
  /** insert a single row into the table: "dw.Channels" */
  insert_dw_Channels_one?: Maybe<Dw_Channels>;
  /** insert data into the table: "dw.Consumption" */
  insert_dw_Consumption?: Maybe<Dw_Consumption_Mutation_Response>;
  /** insert data into the table: "dw.ConsumptionJobs" */
  insert_dw_ConsumptionJobs?: Maybe<Dw_ConsumptionJobs_Mutation_Response>;
  /** insert a single row into the table: "dw.ConsumptionJobs" */
  insert_dw_ConsumptionJobs_one?: Maybe<Dw_ConsumptionJobs>;
  /** insert a single row into the table: "dw.Consumption" */
  insert_dw_Consumption_one?: Maybe<Dw_Consumption>;
  /** insert data into the table: "dw.Contacts" */
  insert_dw_Contacts?: Maybe<Dw_Contacts_Mutation_Response>;
  /** insert data into the table: "dw.ContactsRole" */
  insert_dw_ContactsRole?: Maybe<Dw_ContactsRole_Mutation_Response>;
  /** insert a single row into the table: "dw.ContactsRole" */
  insert_dw_ContactsRole_one?: Maybe<Dw_ContactsRole>;
  /** insert a single row into the table: "dw.Contacts" */
  insert_dw_Contacts_one?: Maybe<Dw_Contacts>;
  /** insert data into the table: "dw.Dashboards" */
  insert_dw_Dashboards?: Maybe<Dw_Dashboards_Mutation_Response>;
  /** insert data into the table: "dw.DashboardsContent" */
  insert_dw_DashboardsContent?: Maybe<Dw_DashboardsContent_Mutation_Response>;
  /** insert a single row into the table: "dw.DashboardsContent" */
  insert_dw_DashboardsContent_one?: Maybe<Dw_DashboardsContent>;
  /** insert data into the table: "dw.DashboardsMetric" */
  insert_dw_DashboardsMetric?: Maybe<Dw_DashboardsMetric_Mutation_Response>;
  /** insert a single row into the table: "dw.DashboardsMetric" */
  insert_dw_DashboardsMetric_one?: Maybe<Dw_DashboardsMetric>;
  /** insert a single row into the table: "dw.Dashboards" */
  insert_dw_Dashboards_one?: Maybe<Dw_Dashboards>;
  /** insert data into the table: "dw.Documents" */
  insert_dw_Documents?: Maybe<Dw_Documents_Mutation_Response>;
  /** insert a single row into the table: "dw.Documents" */
  insert_dw_Documents_one?: Maybe<Dw_Documents>;
  /** insert data into the table: "dw.EmissionFactorCategories" */
  insert_dw_EmissionFactorCategories?: Maybe<Dw_EmissionFactorCategories_Mutation_Response>;
  /** insert a single row into the table: "dw.EmissionFactorCategories" */
  insert_dw_EmissionFactorCategories_one?: Maybe<Dw_EmissionFactorCategories>;
  /** insert data into the table: "dw.EmissionFactorSource" */
  insert_dw_EmissionFactorSource?: Maybe<Dw_EmissionFactorSource_Mutation_Response>;
  /** insert a single row into the table: "dw.EmissionFactorSource" */
  insert_dw_EmissionFactorSource_one?: Maybe<Dw_EmissionFactorSource>;
  /** insert data into the table: "dw.EmissionFactors" */
  insert_dw_EmissionFactors?: Maybe<Dw_EmissionFactors_Mutation_Response>;
  /** insert a single row into the table: "dw.EmissionFactors" */
  insert_dw_EmissionFactors_one?: Maybe<Dw_EmissionFactors>;
  /** insert data into the table: "dw.Feeds" */
  insert_dw_Feeds?: Maybe<Dw_Feeds_Mutation_Response>;
  /** insert data into the table: "dw.FeedsConfigBacNet" */
  insert_dw_FeedsConfigBacNet?: Maybe<Dw_FeedsConfigBacNet_Mutation_Response>;
  /** insert a single row into the table: "dw.FeedsConfigBacNet" */
  insert_dw_FeedsConfigBacNet_one?: Maybe<Dw_FeedsConfigBacNet>;
  /** insert data into the table: "dw.FeedsConfigChannels" */
  insert_dw_FeedsConfigChannels?: Maybe<Dw_FeedsConfigChannels_Mutation_Response>;
  /** insert a single row into the table: "dw.FeedsConfigChannels" */
  insert_dw_FeedsConfigChannels_one?: Maybe<Dw_FeedsConfigChannels>;
  /** insert data into the table: "dw.FeedsConfigModbus" */
  insert_dw_FeedsConfigModbus?: Maybe<Dw_FeedsConfigModbus_Mutation_Response>;
  /** insert a single row into the table: "dw.FeedsConfigModbus" */
  insert_dw_FeedsConfigModbus_one?: Maybe<Dw_FeedsConfigModbus>;
  /** insert data into the table: "dw.FeedsLogs" */
  insert_dw_FeedsLogs?: Maybe<Dw_FeedsLogs_Mutation_Response>;
  /** insert a single row into the table: "dw.FeedsLogs" */
  insert_dw_FeedsLogs_one?: Maybe<Dw_FeedsLogs>;
  /** insert data into the table: "dw.FeedsTypes" */
  insert_dw_FeedsTypes?: Maybe<Dw_FeedsTypes_Mutation_Response>;
  /** insert a single row into the table: "dw.FeedsTypes" */
  insert_dw_FeedsTypes_one?: Maybe<Dw_FeedsTypes>;
  /** insert a single row into the table: "dw.Feeds" */
  insert_dw_Feeds_one?: Maybe<Dw_Feeds>;
  /** insert data into the table: "dw.Groups" */
  insert_dw_Groups?: Maybe<Dw_Groups_Mutation_Response>;
  /** insert data into the table: "dw.GroupsSites" */
  insert_dw_GroupsSites?: Maybe<Dw_GroupsSites_Mutation_Response>;
  /** insert a single row into the table: "dw.GroupsSites" */
  insert_dw_GroupsSites_one?: Maybe<Dw_GroupsSites>;
  /** insert a single row into the table: "dw.Groups" */
  insert_dw_Groups_one?: Maybe<Dw_Groups>;
  /** insert data into the table: "dw.History" */
  insert_dw_History?: Maybe<Dw_History_Mutation_Response>;
  /** insert a single row into the table: "dw.History" */
  insert_dw_History_one?: Maybe<Dw_History>;
  /** insert data into the table: "dw.LoadDocuments" */
  insert_dw_LoadDocuments?: Maybe<Dw_LoadDocuments_Mutation_Response>;
  /** insert a single row into the table: "dw.LoadDocuments" */
  insert_dw_LoadDocuments_one?: Maybe<Dw_LoadDocuments>;
  /** insert data into the table: "dw.Loads" */
  insert_dw_Loads?: Maybe<Dw_Loads_Mutation_Response>;
  /** insert data into the table: "dw.LoadsService" */
  insert_dw_LoadsService?: Maybe<Dw_LoadsService_Mutation_Response>;
  /** insert a single row into the table: "dw.LoadsService" */
  insert_dw_LoadsService_one?: Maybe<Dw_LoadsService>;
  /** insert data into the table: "dw.LoadsType" */
  insert_dw_LoadsType?: Maybe<Dw_LoadsType_Mutation_Response>;
  /** insert a single row into the table: "dw.LoadsType" */
  insert_dw_LoadsType_one?: Maybe<Dw_LoadsType>;
  /** insert a single row into the table: "dw.Loads" */
  insert_dw_Loads_one?: Maybe<Dw_Loads>;
  /** insert data into the table: "dw.MLModelsMetadata" */
  insert_dw_MLModelsMetadata?: Maybe<Dw_MlModelsMetadata_Mutation_Response>;
  /** insert a single row into the table: "dw.MLModelsMetadata" */
  insert_dw_MLModelsMetadata_one?: Maybe<Dw_MlModelsMetadata>;
  /** insert data into the table: "dw.MLNotificationForecasts" */
  insert_dw_MLNotificationForecasts?: Maybe<Dw_MlNotificationForecasts_Mutation_Response>;
  /** insert a single row into the table: "dw.MLNotificationForecasts" */
  insert_dw_MLNotificationForecasts_one?: Maybe<Dw_MlNotificationForecasts>;
  /** insert data into the table: "dw.MLNotificationGroup" */
  insert_dw_MLNotificationGroup?: Maybe<Dw_MlNotificationGroup_Mutation_Response>;
  /** insert a single row into the table: "dw.MLNotificationGroup" */
  insert_dw_MLNotificationGroup_one?: Maybe<Dw_MlNotificationGroup>;
  /** insert data into the table: "dw.MLNotifications" */
  insert_dw_MLNotifications?: Maybe<Dw_MlNotifications_Mutation_Response>;
  /** insert data into the table: "dw.MLNotificationsCorrelation" */
  insert_dw_MLNotificationsCorrelation?: Maybe<Dw_MlNotificationsCorrelation_Mutation_Response>;
  /** insert a single row into the table: "dw.MLNotificationsCorrelation" */
  insert_dw_MLNotificationsCorrelation_one?: Maybe<Dw_MlNotificationsCorrelation>;
  /** insert data into the table: "dw.MLNotificationsMetadata" */
  insert_dw_MLNotificationsMetadata?: Maybe<Dw_MlNotificationsMetadata_Mutation_Response>;
  /** insert a single row into the table: "dw.MLNotificationsMetadata" */
  insert_dw_MLNotificationsMetadata_one?: Maybe<Dw_MlNotificationsMetadata>;
  /** insert a single row into the table: "dw.MLNotifications" */
  insert_dw_MLNotifications_one?: Maybe<Dw_MlNotifications>;
  /** insert data into the table: "dw.Notifications" */
  insert_dw_Notifications?: Maybe<Dw_Notifications_Mutation_Response>;
  /** insert data into the table: "dw.NotificationsEvent" */
  insert_dw_NotificationsEvent?: Maybe<Dw_NotificationsEvent_Mutation_Response>;
  /** insert a single row into the table: "dw.NotificationsEvent" */
  insert_dw_NotificationsEvent_one?: Maybe<Dw_NotificationsEvent>;
  /** insert data into the table: "dw.NotificationsLog" */
  insert_dw_NotificationsLog?: Maybe<Dw_NotificationsLog_Mutation_Response>;
  /** insert a single row into the table: "dw.NotificationsLog" */
  insert_dw_NotificationsLog_one?: Maybe<Dw_NotificationsLog>;
  /** insert data into the table: "dw.NotificationsVisibility" */
  insert_dw_NotificationsVisibility?: Maybe<Dw_NotificationsVisibility_Mutation_Response>;
  /** insert a single row into the table: "dw.NotificationsVisibility" */
  insert_dw_NotificationsVisibility_one?: Maybe<Dw_NotificationsVisibility>;
  /** insert a single row into the table: "dw.Notifications" */
  insert_dw_Notifications_one?: Maybe<Dw_Notifications>;
  /** insert data into the table: "dw.Plans" */
  insert_dw_Plans?: Maybe<Dw_Plans_Mutation_Response>;
  /** insert a single row into the table: "dw.Plans" */
  insert_dw_Plans_one?: Maybe<Dw_Plans>;
  /** insert data into the table: "dw.Projects" */
  insert_dw_Projects?: Maybe<Dw_Projects_Mutation_Response>;
  /** insert a single row into the table: "dw.Projects" */
  insert_dw_Projects_one?: Maybe<Dw_Projects>;
  /** insert data into the table: "dw.Reports" */
  insert_dw_Reports?: Maybe<Dw_Reports_Mutation_Response>;
  /** insert a single row into the table: "dw.Reports" */
  insert_dw_Reports_one?: Maybe<Dw_Reports>;
  /** insert data into the table: "dw.Sessions" */
  insert_dw_Sessions?: Maybe<Dw_Sessions_Mutation_Response>;
  /** insert a single row into the table: "dw.Sessions" */
  insert_dw_Sessions_one?: Maybe<Dw_Sessions>;
  /** insert data into the table: "dw.Sites" */
  insert_dw_Sites?: Maybe<Dw_Sites_Mutation_Response>;
  /** insert a single row into the table: "dw.Sites" */
  insert_dw_Sites_one?: Maybe<Dw_Sites>;
  /** insert data into the table: "dw.Weather" */
  insert_dw_Weather?: Maybe<Dw_Weather_Mutation_Response>;
  /** insert data into the table: "dw.WeatherReadings" */
  insert_dw_WeatherReadings?: Maybe<Dw_WeatherReadings_Mutation_Response>;
  /** insert a single row into the table: "dw.WeatherReadings" */
  insert_dw_WeatherReadings_one?: Maybe<Dw_WeatherReadings>;
  /** insert a single row into the table: "dw.Weather" */
  insert_dw_Weather_one?: Maybe<Dw_Weather>;
  /** update data of the table: "dw.Accounts" */
  update_dw_Accounts?: Maybe<Dw_Accounts_Mutation_Response>;
  /** update data of the table: "dw.AccountsAddress" */
  update_dw_AccountsAddress?: Maybe<Dw_AccountsAddress_Mutation_Response>;
  /** update single row of the table: "dw.AccountsAddress" */
  update_dw_AccountsAddress_by_pk?: Maybe<Dw_AccountsAddress>;
  /** update data of the table: "dw.AccountsSubscription" */
  update_dw_AccountsSubscription?: Maybe<Dw_AccountsSubscription_Mutation_Response>;
  /** update single row of the table: "dw.AccountsSubscription" */
  update_dw_AccountsSubscription_by_pk?: Maybe<Dw_AccountsSubscription>;
  /** update single row of the table: "dw.Accounts" */
  update_dw_Accounts_by_pk?: Maybe<Dw_Accounts>;
  /** update data of the table: "dw.Assessments" */
  update_dw_Assessments?: Maybe<Dw_Assessments_Mutation_Response>;
  /** update single row of the table: "dw.Assessments" */
  update_dw_Assessments_by_pk?: Maybe<Dw_Assessments>;
  /** update data of the table: "dw.Baseline" */
  update_dw_Baseline?: Maybe<Dw_Baseline_Mutation_Response>;
  /** update single row of the table: "dw.Baseline" */
  update_dw_Baseline_by_pk?: Maybe<Dw_Baseline>;
  /** update data of the table: "dw.BuildingDocuments" */
  update_dw_BuildingDocuments?: Maybe<Dw_BuildingDocuments_Mutation_Response>;
  /** update single row of the table: "dw.BuildingDocuments" */
  update_dw_BuildingDocuments_by_pk?: Maybe<Dw_BuildingDocuments>;
  /** update data of the table: "dw.Buildings" */
  update_dw_Buildings?: Maybe<Dw_Buildings_Mutation_Response>;
  /** update single row of the table: "dw.Buildings" */
  update_dw_Buildings_by_pk?: Maybe<Dw_Buildings>;
  /** update data of the table: "dw.Channels" */
  update_dw_Channels?: Maybe<Dw_Channels_Mutation_Response>;
  /** update data of the table: "dw.ChannelsJobs" */
  update_dw_ChannelsJobs?: Maybe<Dw_ChannelsJobs_Mutation_Response>;
  /** update single row of the table: "dw.ChannelsJobs" */
  update_dw_ChannelsJobs_by_pk?: Maybe<Dw_ChannelsJobs>;
  /** update data of the table: "dw.ChannelsMappings" */
  update_dw_ChannelsMappings?: Maybe<Dw_ChannelsMappings_Mutation_Response>;
  /** update single row of the table: "dw.ChannelsMappings" */
  update_dw_ChannelsMappings_by_pk?: Maybe<Dw_ChannelsMappings>;
  /** update data of the table: "dw.ChannelsMetadata" */
  update_dw_ChannelsMetadata?: Maybe<Dw_ChannelsMetadata_Mutation_Response>;
  /** update single row of the table: "dw.ChannelsMetadata" */
  update_dw_ChannelsMetadata_by_pk?: Maybe<Dw_ChannelsMetadata>;
  /** update data of the table: "dw.ChannelsRules" */
  update_dw_ChannelsRules?: Maybe<Dw_ChannelsRules_Mutation_Response>;
  /** update single row of the table: "dw.ChannelsRules" */
  update_dw_ChannelsRules_by_pk?: Maybe<Dw_ChannelsRules>;
  /** update single row of the table: "dw.Channels" */
  update_dw_Channels_by_pk?: Maybe<Dw_Channels>;
  /** update data of the table: "dw.Consumption" */
  update_dw_Consumption?: Maybe<Dw_Consumption_Mutation_Response>;
  /** update data of the table: "dw.ConsumptionJobs" */
  update_dw_ConsumptionJobs?: Maybe<Dw_ConsumptionJobs_Mutation_Response>;
  /** update single row of the table: "dw.ConsumptionJobs" */
  update_dw_ConsumptionJobs_by_pk?: Maybe<Dw_ConsumptionJobs>;
  /** update single row of the table: "dw.Consumption" */
  update_dw_Consumption_by_pk?: Maybe<Dw_Consumption>;
  /** update data of the table: "dw.Contacts" */
  update_dw_Contacts?: Maybe<Dw_Contacts_Mutation_Response>;
  /** update data of the table: "dw.ContactsRole" */
  update_dw_ContactsRole?: Maybe<Dw_ContactsRole_Mutation_Response>;
  /** update single row of the table: "dw.Contacts" */
  update_dw_Contacts_by_pk?: Maybe<Dw_Contacts>;
  /** update data of the table: "dw.Dashboards" */
  update_dw_Dashboards?: Maybe<Dw_Dashboards_Mutation_Response>;
  /** update data of the table: "dw.DashboardsContent" */
  update_dw_DashboardsContent?: Maybe<Dw_DashboardsContent_Mutation_Response>;
  /** update single row of the table: "dw.DashboardsContent" */
  update_dw_DashboardsContent_by_pk?: Maybe<Dw_DashboardsContent>;
  /** update data of the table: "dw.DashboardsMetric" */
  update_dw_DashboardsMetric?: Maybe<Dw_DashboardsMetric_Mutation_Response>;
  /** update single row of the table: "dw.DashboardsMetric" */
  update_dw_DashboardsMetric_by_pk?: Maybe<Dw_DashboardsMetric>;
  /** update single row of the table: "dw.Dashboards" */
  update_dw_Dashboards_by_pk?: Maybe<Dw_Dashboards>;
  /** update data of the table: "dw.Documents" */
  update_dw_Documents?: Maybe<Dw_Documents_Mutation_Response>;
  /** update single row of the table: "dw.Documents" */
  update_dw_Documents_by_pk?: Maybe<Dw_Documents>;
  /** update data of the table: "dw.EmissionFactorCategories" */
  update_dw_EmissionFactorCategories?: Maybe<Dw_EmissionFactorCategories_Mutation_Response>;
  /** update single row of the table: "dw.EmissionFactorCategories" */
  update_dw_EmissionFactorCategories_by_pk?: Maybe<Dw_EmissionFactorCategories>;
  /** update data of the table: "dw.EmissionFactorSource" */
  update_dw_EmissionFactorSource?: Maybe<Dw_EmissionFactorSource_Mutation_Response>;
  /** update single row of the table: "dw.EmissionFactorSource" */
  update_dw_EmissionFactorSource_by_pk?: Maybe<Dw_EmissionFactorSource>;
  /** update data of the table: "dw.EmissionFactors" */
  update_dw_EmissionFactors?: Maybe<Dw_EmissionFactors_Mutation_Response>;
  /** update single row of the table: "dw.EmissionFactors" */
  update_dw_EmissionFactors_by_pk?: Maybe<Dw_EmissionFactors>;
  /** update data of the table: "dw.Feeds" */
  update_dw_Feeds?: Maybe<Dw_Feeds_Mutation_Response>;
  /** update data of the table: "dw.FeedsConfigBacNet" */
  update_dw_FeedsConfigBacNet?: Maybe<Dw_FeedsConfigBacNet_Mutation_Response>;
  /** update single row of the table: "dw.FeedsConfigBacNet" */
  update_dw_FeedsConfigBacNet_by_pk?: Maybe<Dw_FeedsConfigBacNet>;
  /** update data of the table: "dw.FeedsConfigChannels" */
  update_dw_FeedsConfigChannels?: Maybe<Dw_FeedsConfigChannels_Mutation_Response>;
  /** update single row of the table: "dw.FeedsConfigChannels" */
  update_dw_FeedsConfigChannels_by_pk?: Maybe<Dw_FeedsConfigChannels>;
  /** update data of the table: "dw.FeedsConfigModbus" */
  update_dw_FeedsConfigModbus?: Maybe<Dw_FeedsConfigModbus_Mutation_Response>;
  /** update single row of the table: "dw.FeedsConfigModbus" */
  update_dw_FeedsConfigModbus_by_pk?: Maybe<Dw_FeedsConfigModbus>;
  /** update data of the table: "dw.FeedsLogs" */
  update_dw_FeedsLogs?: Maybe<Dw_FeedsLogs_Mutation_Response>;
  /** update single row of the table: "dw.FeedsLogs" */
  update_dw_FeedsLogs_by_pk?: Maybe<Dw_FeedsLogs>;
  /** update data of the table: "dw.FeedsTypes" */
  update_dw_FeedsTypes?: Maybe<Dw_FeedsTypes_Mutation_Response>;
  /** update single row of the table: "dw.FeedsTypes" */
  update_dw_FeedsTypes_by_pk?: Maybe<Dw_FeedsTypes>;
  /** update single row of the table: "dw.Feeds" */
  update_dw_Feeds_by_pk?: Maybe<Dw_Feeds>;
  /** update data of the table: "dw.Groups" */
  update_dw_Groups?: Maybe<Dw_Groups_Mutation_Response>;
  /** update data of the table: "dw.GroupsSites" */
  update_dw_GroupsSites?: Maybe<Dw_GroupsSites_Mutation_Response>;
  /** update single row of the table: "dw.GroupsSites" */
  update_dw_GroupsSites_by_pk?: Maybe<Dw_GroupsSites>;
  /** update single row of the table: "dw.Groups" */
  update_dw_Groups_by_pk?: Maybe<Dw_Groups>;
  /** update data of the table: "dw.History" */
  update_dw_History?: Maybe<Dw_History_Mutation_Response>;
  /** update single row of the table: "dw.History" */
  update_dw_History_by_pk?: Maybe<Dw_History>;
  /** update data of the table: "dw.LoadDocuments" */
  update_dw_LoadDocuments?: Maybe<Dw_LoadDocuments_Mutation_Response>;
  /** update single row of the table: "dw.LoadDocuments" */
  update_dw_LoadDocuments_by_pk?: Maybe<Dw_LoadDocuments>;
  /** update data of the table: "dw.Loads" */
  update_dw_Loads?: Maybe<Dw_Loads_Mutation_Response>;
  /** update data of the table: "dw.LoadsService" */
  update_dw_LoadsService?: Maybe<Dw_LoadsService_Mutation_Response>;
  /** update single row of the table: "dw.LoadsService" */
  update_dw_LoadsService_by_pk?: Maybe<Dw_LoadsService>;
  /** update data of the table: "dw.LoadsType" */
  update_dw_LoadsType?: Maybe<Dw_LoadsType_Mutation_Response>;
  /** update single row of the table: "dw.LoadsType" */
  update_dw_LoadsType_by_pk?: Maybe<Dw_LoadsType>;
  /** update single row of the table: "dw.Loads" */
  update_dw_Loads_by_pk?: Maybe<Dw_Loads>;
  /** update data of the table: "dw.MLModelsMetadata" */
  update_dw_MLModelsMetadata?: Maybe<Dw_MlModelsMetadata_Mutation_Response>;
  /** update single row of the table: "dw.MLModelsMetadata" */
  update_dw_MLModelsMetadata_by_pk?: Maybe<Dw_MlModelsMetadata>;
  /** update data of the table: "dw.MLNotificationForecasts" */
  update_dw_MLNotificationForecasts?: Maybe<Dw_MlNotificationForecasts_Mutation_Response>;
  /** update data of the table: "dw.MLNotificationGroup" */
  update_dw_MLNotificationGroup?: Maybe<Dw_MlNotificationGroup_Mutation_Response>;
  /** update single row of the table: "dw.MLNotificationGroup" */
  update_dw_MLNotificationGroup_by_pk?: Maybe<Dw_MlNotificationGroup>;
  /** update data of the table: "dw.MLNotifications" */
  update_dw_MLNotifications?: Maybe<Dw_MlNotifications_Mutation_Response>;
  /** update data of the table: "dw.MLNotificationsCorrelation" */
  update_dw_MLNotificationsCorrelation?: Maybe<Dw_MlNotificationsCorrelation_Mutation_Response>;
  /** update single row of the table: "dw.MLNotificationsCorrelation" */
  update_dw_MLNotificationsCorrelation_by_pk?: Maybe<Dw_MlNotificationsCorrelation>;
  /** update data of the table: "dw.MLNotificationsMetadata" */
  update_dw_MLNotificationsMetadata?: Maybe<Dw_MlNotificationsMetadata_Mutation_Response>;
  /** update single row of the table: "dw.MLNotificationsMetadata" */
  update_dw_MLNotificationsMetadata_by_pk?: Maybe<Dw_MlNotificationsMetadata>;
  /** update single row of the table: "dw.MLNotifications" */
  update_dw_MLNotifications_by_pk?: Maybe<Dw_MlNotifications>;
  /** update data of the table: "dw.Notifications" */
  update_dw_Notifications?: Maybe<Dw_Notifications_Mutation_Response>;
  /** update data of the table: "dw.NotificationsEvent" */
  update_dw_NotificationsEvent?: Maybe<Dw_NotificationsEvent_Mutation_Response>;
  /** update single row of the table: "dw.NotificationsEvent" */
  update_dw_NotificationsEvent_by_pk?: Maybe<Dw_NotificationsEvent>;
  /** update data of the table: "dw.NotificationsLog" */
  update_dw_NotificationsLog?: Maybe<Dw_NotificationsLog_Mutation_Response>;
  /** update single row of the table: "dw.NotificationsLog" */
  update_dw_NotificationsLog_by_pk?: Maybe<Dw_NotificationsLog>;
  /** update data of the table: "dw.NotificationsVisibility" */
  update_dw_NotificationsVisibility?: Maybe<Dw_NotificationsVisibility_Mutation_Response>;
  /** update single row of the table: "dw.NotificationsVisibility" */
  update_dw_NotificationsVisibility_by_pk?: Maybe<Dw_NotificationsVisibility>;
  /** update single row of the table: "dw.Notifications" */
  update_dw_Notifications_by_pk?: Maybe<Dw_Notifications>;
  /** update data of the table: "dw.Plans" */
  update_dw_Plans?: Maybe<Dw_Plans_Mutation_Response>;
  /** update single row of the table: "dw.Plans" */
  update_dw_Plans_by_pk?: Maybe<Dw_Plans>;
  /** update data of the table: "dw.Projects" */
  update_dw_Projects?: Maybe<Dw_Projects_Mutation_Response>;
  /** update single row of the table: "dw.Projects" */
  update_dw_Projects_by_pk?: Maybe<Dw_Projects>;
  /** update data of the table: "dw.Reports" */
  update_dw_Reports?: Maybe<Dw_Reports_Mutation_Response>;
  /** update single row of the table: "dw.Reports" */
  update_dw_Reports_by_pk?: Maybe<Dw_Reports>;
  /** update data of the table: "dw.Sessions" */
  update_dw_Sessions?: Maybe<Dw_Sessions_Mutation_Response>;
  /** update single row of the table: "dw.Sessions" */
  update_dw_Sessions_by_pk?: Maybe<Dw_Sessions>;
  /** update data of the table: "dw.Sites" */
  update_dw_Sites?: Maybe<Dw_Sites_Mutation_Response>;
  /** update single row of the table: "dw.Sites" */
  update_dw_Sites_by_pk?: Maybe<Dw_Sites>;
  /** update data of the table: "dw.Weather" */
  update_dw_Weather?: Maybe<Dw_Weather_Mutation_Response>;
  /** update data of the table: "dw.WeatherReadings" */
  update_dw_WeatherReadings?: Maybe<Dw_WeatherReadings_Mutation_Response>;
  /** update single row of the table: "dw.WeatherReadings" */
  update_dw_WeatherReadings_by_pk?: Maybe<Dw_WeatherReadings>;
  /** update single row of the table: "dw.Weather" */
  update_dw_Weather_by_pk?: Maybe<Dw_Weather>;
};


/** mutation root */
export type Mutation_RootDelete_Dw_AccountsArgs = {
  where: Dw_Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_AccountsAddressArgs = {
  where: Dw_AccountsAddress_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_AccountsAddress_By_PkArgs = {
  AddressId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_AccountsSubscriptionArgs = {
  where: Dw_AccountsSubscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_AccountsSubscription_By_PkArgs = {
  SubscriptionId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_Accounts_By_PkArgs = {
  AccountId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_AssessmentsArgs = {
  where: Dw_Assessments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_Assessments_By_PkArgs = {
  AssessmentId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_BaselineArgs = {
  where: Dw_Baseline_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_Baseline_By_PkArgs = {
  ChannelId: Scalars['uuid'];
  ConsumptionDate: Scalars['timestamp'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_BuildingDocumentsArgs = {
  where: Dw_BuildingDocuments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_BuildingDocuments_By_PkArgs = {
  DocumentId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_BuildingsArgs = {
  where: Dw_Buildings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_Buildings_By_PkArgs = {
  BuildingId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_ChannelsArgs = {
  where: Dw_Channels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_ChannelsJobsArgs = {
  where: Dw_ChannelsJobs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_ChannelsJobs_By_PkArgs = {
  JobId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_ChannelsMappingsArgs = {
  where: Dw_ChannelsMappings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_ChannelsMappings_By_PkArgs = {
  ChannelId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_ChannelsMetadataArgs = {
  where: Dw_ChannelsMetadata_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_ChannelsMetadata_By_PkArgs = {
  ChannelId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_ChannelsRulesArgs = {
  where: Dw_ChannelsRules_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_ChannelsRules_By_PkArgs = {
  RuleId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_Channels_By_PkArgs = {
  ChannelId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_ConsumptionArgs = {
  where: Dw_Consumption_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_ConsumptionJobsArgs = {
  where: Dw_ConsumptionJobs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_ConsumptionJobs_By_PkArgs = {
  JobId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_Consumption_By_PkArgs = {
  ChannelId: Scalars['uuid'];
  ConsumptionDate: Scalars['timestamp'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_ContactsArgs = {
  where: Dw_Contacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_ContactsRoleArgs = {
  where: Dw_ContactsRole_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_Contacts_By_PkArgs = {
  ContactId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_DashboardsArgs = {
  where: Dw_Dashboards_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_DashboardsContentArgs = {
  where: Dw_DashboardsContent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_DashboardsContent_By_PkArgs = {
  ContentId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_DashboardsMetricArgs = {
  where: Dw_DashboardsMetric_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_DashboardsMetric_By_PkArgs = {
  MetricId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_Dashboards_By_PkArgs = {
  DashboardId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_DocumentsArgs = {
  where: Dw_Documents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_Documents_By_PkArgs = {
  DocumentId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_EmissionFactorCategoriesArgs = {
  where: Dw_EmissionFactorCategories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_EmissionFactorCategories_By_PkArgs = {
  CategoryId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_EmissionFactorSourceArgs = {
  where: Dw_EmissionFactorSource_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_EmissionFactorSource_By_PkArgs = {
  SourceId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_EmissionFactorsArgs = {
  where: Dw_EmissionFactors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_EmissionFactors_By_PkArgs = {
  EmissionFactorId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_FeedsArgs = {
  where: Dw_Feeds_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_FeedsConfigBacNetArgs = {
  where: Dw_FeedsConfigBacNet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_FeedsConfigBacNet_By_PkArgs = {
  ChannelId: Scalars['uuid'];
  FeedId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_FeedsConfigChannelsArgs = {
  where: Dw_FeedsConfigChannels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_FeedsConfigChannels_By_PkArgs = {
  ChannelId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_FeedsConfigModbusArgs = {
  where: Dw_FeedsConfigModbus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_FeedsConfigModbus_By_PkArgs = {
  ChannelId: Scalars['uuid'];
  FeedId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_FeedsLogsArgs = {
  where: Dw_FeedsLogs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_FeedsLogs_By_PkArgs = {
  EventId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_FeedsTypesArgs = {
  where: Dw_FeedsTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_FeedsTypes_By_PkArgs = {
  TypeId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_Feeds_By_PkArgs = {
  FeedId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_GroupsArgs = {
  where: Dw_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_GroupsSitesArgs = {
  where: Dw_GroupsSites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_GroupsSites_By_PkArgs = {
  GroupId: Scalars['uuid'];
  SiteId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_Groups_By_PkArgs = {
  GroupId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_HistoryArgs = {
  where: Dw_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_History_By_PkArgs = {
  HistoryId: Scalars['uuid'];
  SourceId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_LoadDocumentsArgs = {
  where: Dw_LoadDocuments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_LoadDocuments_By_PkArgs = {
  DocumentId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_LoadsArgs = {
  where: Dw_Loads_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_LoadsServiceArgs = {
  where: Dw_LoadsService_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_LoadsService_By_PkArgs = {
  ServiceId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_LoadsTypeArgs = {
  where: Dw_LoadsType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_LoadsType_By_PkArgs = {
  TypeId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_Loads_By_PkArgs = {
  LoadId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_MlModelsMetadataArgs = {
  where: Dw_MlModelsMetadata_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_MlModelsMetadata_By_PkArgs = {
  ModelId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_MlNotificationForecastsArgs = {
  where: Dw_MlNotificationForecasts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_MlNotificationGroupArgs = {
  where: Dw_MlNotificationGroup_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_MlNotificationGroup_By_PkArgs = {
  NotificationGroupId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_MlNotificationsArgs = {
  where: Dw_MlNotifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_MlNotificationsCorrelationArgs = {
  where: Dw_MlNotificationsCorrelation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_MlNotificationsCorrelation_By_PkArgs = {
  NotificationsCorrelationId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_MlNotificationsMetadataArgs = {
  where: Dw_MlNotificationsMetadata_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_MlNotificationsMetadata_By_PkArgs = {
  NotificationGroupId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_MlNotifications_By_PkArgs = {
  NotificationId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_NotificationsArgs = {
  where: Dw_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_NotificationsEventArgs = {
  where: Dw_NotificationsEvent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_NotificationsEvent_By_PkArgs = {
  EventId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_NotificationsLogArgs = {
  where: Dw_NotificationsLog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_NotificationsLog_By_PkArgs = {
  LogId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_NotificationsVisibilityArgs = {
  where: Dw_NotificationsVisibility_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_NotificationsVisibility_By_PkArgs = {
  ContactId: Scalars['uuid'];
  NotificationId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_Notifications_By_PkArgs = {
  NotificationId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_PlansArgs = {
  where: Dw_Plans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_Plans_By_PkArgs = {
  PlanId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_ProjectsArgs = {
  where: Dw_Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_Projects_By_PkArgs = {
  ProjectId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_ReportsArgs = {
  where: Dw_Reports_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_Reports_By_PkArgs = {
  ReportId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_SessionsArgs = {
  where: Dw_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_Sessions_By_PkArgs = {
  SessionId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_SitesArgs = {
  where: Dw_Sites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_Sites_By_PkArgs = {
  SiteId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_WeatherArgs = {
  where: Dw_Weather_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_WeatherReadingsArgs = {
  where: Dw_WeatherReadings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Dw_WeatherReadings_By_PkArgs = {
  Event: Scalars['timestamp'];
  WeatherId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Dw_Weather_By_PkArgs = {
  WeatherId: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootInsert_Dw_AccountsArgs = {
  objects: Array<Dw_Accounts_Insert_Input>;
  on_conflict?: Maybe<Dw_Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_AccountsAddressArgs = {
  objects: Array<Dw_AccountsAddress_Insert_Input>;
  on_conflict?: Maybe<Dw_AccountsAddress_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_AccountsAddress_OneArgs = {
  object: Dw_AccountsAddress_Insert_Input;
  on_conflict?: Maybe<Dw_AccountsAddress_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_AccountsSubscriptionArgs = {
  objects: Array<Dw_AccountsSubscription_Insert_Input>;
  on_conflict?: Maybe<Dw_AccountsSubscription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_AccountsSubscription_OneArgs = {
  object: Dw_AccountsSubscription_Insert_Input;
  on_conflict?: Maybe<Dw_AccountsSubscription_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_Accounts_OneArgs = {
  object: Dw_Accounts_Insert_Input;
  on_conflict?: Maybe<Dw_Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_AssessmentsArgs = {
  objects: Array<Dw_Assessments_Insert_Input>;
  on_conflict?: Maybe<Dw_Assessments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_Assessments_OneArgs = {
  object: Dw_Assessments_Insert_Input;
  on_conflict?: Maybe<Dw_Assessments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_BaselineArgs = {
  objects: Array<Dw_Baseline_Insert_Input>;
  on_conflict?: Maybe<Dw_Baseline_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_Baseline_OneArgs = {
  object: Dw_Baseline_Insert_Input;
  on_conflict?: Maybe<Dw_Baseline_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_BuildingDocumentsArgs = {
  objects: Array<Dw_BuildingDocuments_Insert_Input>;
  on_conflict?: Maybe<Dw_BuildingDocuments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_BuildingDocuments_OneArgs = {
  object: Dw_BuildingDocuments_Insert_Input;
  on_conflict?: Maybe<Dw_BuildingDocuments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_BuildingsArgs = {
  objects: Array<Dw_Buildings_Insert_Input>;
  on_conflict?: Maybe<Dw_Buildings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_Buildings_OneArgs = {
  object: Dw_Buildings_Insert_Input;
  on_conflict?: Maybe<Dw_Buildings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_ChannelsArgs = {
  objects: Array<Dw_Channels_Insert_Input>;
  on_conflict?: Maybe<Dw_Channels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_ChannelsJobsArgs = {
  objects: Array<Dw_ChannelsJobs_Insert_Input>;
  on_conflict?: Maybe<Dw_ChannelsJobs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_ChannelsJobs_OneArgs = {
  object: Dw_ChannelsJobs_Insert_Input;
  on_conflict?: Maybe<Dw_ChannelsJobs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_ChannelsMappingsArgs = {
  objects: Array<Dw_ChannelsMappings_Insert_Input>;
  on_conflict?: Maybe<Dw_ChannelsMappings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_ChannelsMappings_OneArgs = {
  object: Dw_ChannelsMappings_Insert_Input;
  on_conflict?: Maybe<Dw_ChannelsMappings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_ChannelsMetadataArgs = {
  objects: Array<Dw_ChannelsMetadata_Insert_Input>;
  on_conflict?: Maybe<Dw_ChannelsMetadata_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_ChannelsMetadata_OneArgs = {
  object: Dw_ChannelsMetadata_Insert_Input;
  on_conflict?: Maybe<Dw_ChannelsMetadata_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_ChannelsRulesArgs = {
  objects: Array<Dw_ChannelsRules_Insert_Input>;
  on_conflict?: Maybe<Dw_ChannelsRules_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_ChannelsRules_OneArgs = {
  object: Dw_ChannelsRules_Insert_Input;
  on_conflict?: Maybe<Dw_ChannelsRules_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_Channels_OneArgs = {
  object: Dw_Channels_Insert_Input;
  on_conflict?: Maybe<Dw_Channels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_ConsumptionArgs = {
  objects: Array<Dw_Consumption_Insert_Input>;
  on_conflict?: Maybe<Dw_Consumption_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_ConsumptionJobsArgs = {
  objects: Array<Dw_ConsumptionJobs_Insert_Input>;
  on_conflict?: Maybe<Dw_ConsumptionJobs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_ConsumptionJobs_OneArgs = {
  object: Dw_ConsumptionJobs_Insert_Input;
  on_conflict?: Maybe<Dw_ConsumptionJobs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_Consumption_OneArgs = {
  object: Dw_Consumption_Insert_Input;
  on_conflict?: Maybe<Dw_Consumption_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_ContactsArgs = {
  objects: Array<Dw_Contacts_Insert_Input>;
  on_conflict?: Maybe<Dw_Contacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_ContactsRoleArgs = {
  objects: Array<Dw_ContactsRole_Insert_Input>;
  on_conflict?: Maybe<Dw_ContactsRole_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_ContactsRole_OneArgs = {
  object: Dw_ContactsRole_Insert_Input;
  on_conflict?: Maybe<Dw_ContactsRole_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_Contacts_OneArgs = {
  object: Dw_Contacts_Insert_Input;
  on_conflict?: Maybe<Dw_Contacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_DashboardsArgs = {
  objects: Array<Dw_Dashboards_Insert_Input>;
  on_conflict?: Maybe<Dw_Dashboards_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_DashboardsContentArgs = {
  objects: Array<Dw_DashboardsContent_Insert_Input>;
  on_conflict?: Maybe<Dw_DashboardsContent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_DashboardsContent_OneArgs = {
  object: Dw_DashboardsContent_Insert_Input;
  on_conflict?: Maybe<Dw_DashboardsContent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_DashboardsMetricArgs = {
  objects: Array<Dw_DashboardsMetric_Insert_Input>;
  on_conflict?: Maybe<Dw_DashboardsMetric_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_DashboardsMetric_OneArgs = {
  object: Dw_DashboardsMetric_Insert_Input;
  on_conflict?: Maybe<Dw_DashboardsMetric_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_Dashboards_OneArgs = {
  object: Dw_Dashboards_Insert_Input;
  on_conflict?: Maybe<Dw_Dashboards_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_DocumentsArgs = {
  objects: Array<Dw_Documents_Insert_Input>;
  on_conflict?: Maybe<Dw_Documents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_Documents_OneArgs = {
  object: Dw_Documents_Insert_Input;
  on_conflict?: Maybe<Dw_Documents_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_EmissionFactorCategoriesArgs = {
  objects: Array<Dw_EmissionFactorCategories_Insert_Input>;
  on_conflict?: Maybe<Dw_EmissionFactorCategories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_EmissionFactorCategories_OneArgs = {
  object: Dw_EmissionFactorCategories_Insert_Input;
  on_conflict?: Maybe<Dw_EmissionFactorCategories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_EmissionFactorSourceArgs = {
  objects: Array<Dw_EmissionFactorSource_Insert_Input>;
  on_conflict?: Maybe<Dw_EmissionFactorSource_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_EmissionFactorSource_OneArgs = {
  object: Dw_EmissionFactorSource_Insert_Input;
  on_conflict?: Maybe<Dw_EmissionFactorSource_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_EmissionFactorsArgs = {
  objects: Array<Dw_EmissionFactors_Insert_Input>;
  on_conflict?: Maybe<Dw_EmissionFactors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_EmissionFactors_OneArgs = {
  object: Dw_EmissionFactors_Insert_Input;
  on_conflict?: Maybe<Dw_EmissionFactors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_FeedsArgs = {
  objects: Array<Dw_Feeds_Insert_Input>;
  on_conflict?: Maybe<Dw_Feeds_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_FeedsConfigBacNetArgs = {
  objects: Array<Dw_FeedsConfigBacNet_Insert_Input>;
  on_conflict?: Maybe<Dw_FeedsConfigBacNet_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_FeedsConfigBacNet_OneArgs = {
  object: Dw_FeedsConfigBacNet_Insert_Input;
  on_conflict?: Maybe<Dw_FeedsConfigBacNet_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_FeedsConfigChannelsArgs = {
  objects: Array<Dw_FeedsConfigChannels_Insert_Input>;
  on_conflict?: Maybe<Dw_FeedsConfigChannels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_FeedsConfigChannels_OneArgs = {
  object: Dw_FeedsConfigChannels_Insert_Input;
  on_conflict?: Maybe<Dw_FeedsConfigChannels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_FeedsConfigModbusArgs = {
  objects: Array<Dw_FeedsConfigModbus_Insert_Input>;
  on_conflict?: Maybe<Dw_FeedsConfigModbus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_FeedsConfigModbus_OneArgs = {
  object: Dw_FeedsConfigModbus_Insert_Input;
  on_conflict?: Maybe<Dw_FeedsConfigModbus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_FeedsLogsArgs = {
  objects: Array<Dw_FeedsLogs_Insert_Input>;
  on_conflict?: Maybe<Dw_FeedsLogs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_FeedsLogs_OneArgs = {
  object: Dw_FeedsLogs_Insert_Input;
  on_conflict?: Maybe<Dw_FeedsLogs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_FeedsTypesArgs = {
  objects: Array<Dw_FeedsTypes_Insert_Input>;
  on_conflict?: Maybe<Dw_FeedsTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_FeedsTypes_OneArgs = {
  object: Dw_FeedsTypes_Insert_Input;
  on_conflict?: Maybe<Dw_FeedsTypes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_Feeds_OneArgs = {
  object: Dw_Feeds_Insert_Input;
  on_conflict?: Maybe<Dw_Feeds_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_GroupsArgs = {
  objects: Array<Dw_Groups_Insert_Input>;
  on_conflict?: Maybe<Dw_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_GroupsSitesArgs = {
  objects: Array<Dw_GroupsSites_Insert_Input>;
  on_conflict?: Maybe<Dw_GroupsSites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_GroupsSites_OneArgs = {
  object: Dw_GroupsSites_Insert_Input;
  on_conflict?: Maybe<Dw_GroupsSites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_Groups_OneArgs = {
  object: Dw_Groups_Insert_Input;
  on_conflict?: Maybe<Dw_Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_HistoryArgs = {
  objects: Array<Dw_History_Insert_Input>;
  on_conflict?: Maybe<Dw_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_History_OneArgs = {
  object: Dw_History_Insert_Input;
  on_conflict?: Maybe<Dw_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_LoadDocumentsArgs = {
  objects: Array<Dw_LoadDocuments_Insert_Input>;
  on_conflict?: Maybe<Dw_LoadDocuments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_LoadDocuments_OneArgs = {
  object: Dw_LoadDocuments_Insert_Input;
  on_conflict?: Maybe<Dw_LoadDocuments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_LoadsArgs = {
  objects: Array<Dw_Loads_Insert_Input>;
  on_conflict?: Maybe<Dw_Loads_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_LoadsServiceArgs = {
  objects: Array<Dw_LoadsService_Insert_Input>;
  on_conflict?: Maybe<Dw_LoadsService_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_LoadsService_OneArgs = {
  object: Dw_LoadsService_Insert_Input;
  on_conflict?: Maybe<Dw_LoadsService_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_LoadsTypeArgs = {
  objects: Array<Dw_LoadsType_Insert_Input>;
  on_conflict?: Maybe<Dw_LoadsType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_LoadsType_OneArgs = {
  object: Dw_LoadsType_Insert_Input;
  on_conflict?: Maybe<Dw_LoadsType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_Loads_OneArgs = {
  object: Dw_Loads_Insert_Input;
  on_conflict?: Maybe<Dw_Loads_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_MlModelsMetadataArgs = {
  objects: Array<Dw_MlModelsMetadata_Insert_Input>;
  on_conflict?: Maybe<Dw_MlModelsMetadata_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_MlModelsMetadata_OneArgs = {
  object: Dw_MlModelsMetadata_Insert_Input;
  on_conflict?: Maybe<Dw_MlModelsMetadata_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_MlNotificationForecastsArgs = {
  objects: Array<Dw_MlNotificationForecasts_Insert_Input>;
  on_conflict?: Maybe<Dw_MlNotificationForecasts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_MlNotificationForecasts_OneArgs = {
  object: Dw_MlNotificationForecasts_Insert_Input;
  on_conflict?: Maybe<Dw_MlNotificationForecasts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_MlNotificationGroupArgs = {
  objects: Array<Dw_MlNotificationGroup_Insert_Input>;
  on_conflict?: Maybe<Dw_MlNotificationGroup_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_MlNotificationGroup_OneArgs = {
  object: Dw_MlNotificationGroup_Insert_Input;
  on_conflict?: Maybe<Dw_MlNotificationGroup_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_MlNotificationsArgs = {
  objects: Array<Dw_MlNotifications_Insert_Input>;
  on_conflict?: Maybe<Dw_MlNotifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_MlNotificationsCorrelationArgs = {
  objects: Array<Dw_MlNotificationsCorrelation_Insert_Input>;
  on_conflict?: Maybe<Dw_MlNotificationsCorrelation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_MlNotificationsCorrelation_OneArgs = {
  object: Dw_MlNotificationsCorrelation_Insert_Input;
  on_conflict?: Maybe<Dw_MlNotificationsCorrelation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_MlNotificationsMetadataArgs = {
  objects: Array<Dw_MlNotificationsMetadata_Insert_Input>;
  on_conflict?: Maybe<Dw_MlNotificationsMetadata_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_MlNotificationsMetadata_OneArgs = {
  object: Dw_MlNotificationsMetadata_Insert_Input;
  on_conflict?: Maybe<Dw_MlNotificationsMetadata_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_MlNotifications_OneArgs = {
  object: Dw_MlNotifications_Insert_Input;
  on_conflict?: Maybe<Dw_MlNotifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_NotificationsArgs = {
  objects: Array<Dw_Notifications_Insert_Input>;
  on_conflict?: Maybe<Dw_Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_NotificationsEventArgs = {
  objects: Array<Dw_NotificationsEvent_Insert_Input>;
  on_conflict?: Maybe<Dw_NotificationsEvent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_NotificationsEvent_OneArgs = {
  object: Dw_NotificationsEvent_Insert_Input;
  on_conflict?: Maybe<Dw_NotificationsEvent_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_NotificationsLogArgs = {
  objects: Array<Dw_NotificationsLog_Insert_Input>;
  on_conflict?: Maybe<Dw_NotificationsLog_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_NotificationsLog_OneArgs = {
  object: Dw_NotificationsLog_Insert_Input;
  on_conflict?: Maybe<Dw_NotificationsLog_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_NotificationsVisibilityArgs = {
  objects: Array<Dw_NotificationsVisibility_Insert_Input>;
  on_conflict?: Maybe<Dw_NotificationsVisibility_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_NotificationsVisibility_OneArgs = {
  object: Dw_NotificationsVisibility_Insert_Input;
  on_conflict?: Maybe<Dw_NotificationsVisibility_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_Notifications_OneArgs = {
  object: Dw_Notifications_Insert_Input;
  on_conflict?: Maybe<Dw_Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_PlansArgs = {
  objects: Array<Dw_Plans_Insert_Input>;
  on_conflict?: Maybe<Dw_Plans_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_Plans_OneArgs = {
  object: Dw_Plans_Insert_Input;
  on_conflict?: Maybe<Dw_Plans_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_ProjectsArgs = {
  objects: Array<Dw_Projects_Insert_Input>;
  on_conflict?: Maybe<Dw_Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_Projects_OneArgs = {
  object: Dw_Projects_Insert_Input;
  on_conflict?: Maybe<Dw_Projects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_ReportsArgs = {
  objects: Array<Dw_Reports_Insert_Input>;
  on_conflict?: Maybe<Dw_Reports_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_Reports_OneArgs = {
  object: Dw_Reports_Insert_Input;
  on_conflict?: Maybe<Dw_Reports_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_SessionsArgs = {
  objects: Array<Dw_Sessions_Insert_Input>;
  on_conflict?: Maybe<Dw_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_Sessions_OneArgs = {
  object: Dw_Sessions_Insert_Input;
  on_conflict?: Maybe<Dw_Sessions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_SitesArgs = {
  objects: Array<Dw_Sites_Insert_Input>;
  on_conflict?: Maybe<Dw_Sites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_Sites_OneArgs = {
  object: Dw_Sites_Insert_Input;
  on_conflict?: Maybe<Dw_Sites_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_WeatherArgs = {
  objects: Array<Dw_Weather_Insert_Input>;
  on_conflict?: Maybe<Dw_Weather_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_WeatherReadingsArgs = {
  objects: Array<Dw_WeatherReadings_Insert_Input>;
  on_conflict?: Maybe<Dw_WeatherReadings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_WeatherReadings_OneArgs = {
  object: Dw_WeatherReadings_Insert_Input;
  on_conflict?: Maybe<Dw_WeatherReadings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Dw_Weather_OneArgs = {
  object: Dw_Weather_Insert_Input;
  on_conflict?: Maybe<Dw_Weather_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_AccountsArgs = {
  _inc?: Maybe<Dw_Accounts_Inc_Input>;
  _set?: Maybe<Dw_Accounts_Set_Input>;
  where: Dw_Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_AccountsAddressArgs = {
  _inc?: Maybe<Dw_AccountsAddress_Inc_Input>;
  _set?: Maybe<Dw_AccountsAddress_Set_Input>;
  where: Dw_AccountsAddress_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_AccountsAddress_By_PkArgs = {
  _inc?: Maybe<Dw_AccountsAddress_Inc_Input>;
  _set?: Maybe<Dw_AccountsAddress_Set_Input>;
  pk_columns: Dw_AccountsAddress_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_AccountsSubscriptionArgs = {
  _inc?: Maybe<Dw_AccountsSubscription_Inc_Input>;
  _set?: Maybe<Dw_AccountsSubscription_Set_Input>;
  where: Dw_AccountsSubscription_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_AccountsSubscription_By_PkArgs = {
  _inc?: Maybe<Dw_AccountsSubscription_Inc_Input>;
  _set?: Maybe<Dw_AccountsSubscription_Set_Input>;
  pk_columns: Dw_AccountsSubscription_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_Accounts_By_PkArgs = {
  _inc?: Maybe<Dw_Accounts_Inc_Input>;
  _set?: Maybe<Dw_Accounts_Set_Input>;
  pk_columns: Dw_Accounts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_AssessmentsArgs = {
  _inc?: Maybe<Dw_Assessments_Inc_Input>;
  _set?: Maybe<Dw_Assessments_Set_Input>;
  where: Dw_Assessments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_Assessments_By_PkArgs = {
  _inc?: Maybe<Dw_Assessments_Inc_Input>;
  _set?: Maybe<Dw_Assessments_Set_Input>;
  pk_columns: Dw_Assessments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_BaselineArgs = {
  _inc?: Maybe<Dw_Baseline_Inc_Input>;
  _set?: Maybe<Dw_Baseline_Set_Input>;
  where: Dw_Baseline_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_Baseline_By_PkArgs = {
  _inc?: Maybe<Dw_Baseline_Inc_Input>;
  _set?: Maybe<Dw_Baseline_Set_Input>;
  pk_columns: Dw_Baseline_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_BuildingDocumentsArgs = {
  _inc?: Maybe<Dw_BuildingDocuments_Inc_Input>;
  _set?: Maybe<Dw_BuildingDocuments_Set_Input>;
  where: Dw_BuildingDocuments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_BuildingDocuments_By_PkArgs = {
  _inc?: Maybe<Dw_BuildingDocuments_Inc_Input>;
  _set?: Maybe<Dw_BuildingDocuments_Set_Input>;
  pk_columns: Dw_BuildingDocuments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_BuildingsArgs = {
  _inc?: Maybe<Dw_Buildings_Inc_Input>;
  _set?: Maybe<Dw_Buildings_Set_Input>;
  where: Dw_Buildings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_Buildings_By_PkArgs = {
  _inc?: Maybe<Dw_Buildings_Inc_Input>;
  _set?: Maybe<Dw_Buildings_Set_Input>;
  pk_columns: Dw_Buildings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_ChannelsArgs = {
  _inc?: Maybe<Dw_Channels_Inc_Input>;
  _set?: Maybe<Dw_Channels_Set_Input>;
  where: Dw_Channels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_ChannelsJobsArgs = {
  _inc?: Maybe<Dw_ChannelsJobs_Inc_Input>;
  _set?: Maybe<Dw_ChannelsJobs_Set_Input>;
  where: Dw_ChannelsJobs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_ChannelsJobs_By_PkArgs = {
  _inc?: Maybe<Dw_ChannelsJobs_Inc_Input>;
  _set?: Maybe<Dw_ChannelsJobs_Set_Input>;
  pk_columns: Dw_ChannelsJobs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_ChannelsMappingsArgs = {
  _set?: Maybe<Dw_ChannelsMappings_Set_Input>;
  where: Dw_ChannelsMappings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_ChannelsMappings_By_PkArgs = {
  _set?: Maybe<Dw_ChannelsMappings_Set_Input>;
  pk_columns: Dw_ChannelsMappings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_ChannelsMetadataArgs = {
  _inc?: Maybe<Dw_ChannelsMetadata_Inc_Input>;
  _set?: Maybe<Dw_ChannelsMetadata_Set_Input>;
  where: Dw_ChannelsMetadata_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_ChannelsMetadata_By_PkArgs = {
  _inc?: Maybe<Dw_ChannelsMetadata_Inc_Input>;
  _set?: Maybe<Dw_ChannelsMetadata_Set_Input>;
  pk_columns: Dw_ChannelsMetadata_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_ChannelsRulesArgs = {
  _inc?: Maybe<Dw_ChannelsRules_Inc_Input>;
  _set?: Maybe<Dw_ChannelsRules_Set_Input>;
  where: Dw_ChannelsRules_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_ChannelsRules_By_PkArgs = {
  _inc?: Maybe<Dw_ChannelsRules_Inc_Input>;
  _set?: Maybe<Dw_ChannelsRules_Set_Input>;
  pk_columns: Dw_ChannelsRules_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_Channels_By_PkArgs = {
  _inc?: Maybe<Dw_Channels_Inc_Input>;
  _set?: Maybe<Dw_Channels_Set_Input>;
  pk_columns: Dw_Channels_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_ConsumptionArgs = {
  _inc?: Maybe<Dw_Consumption_Inc_Input>;
  _set?: Maybe<Dw_Consumption_Set_Input>;
  where: Dw_Consumption_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_ConsumptionJobsArgs = {
  _inc?: Maybe<Dw_ConsumptionJobs_Inc_Input>;
  _set?: Maybe<Dw_ConsumptionJobs_Set_Input>;
  where: Dw_ConsumptionJobs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_ConsumptionJobs_By_PkArgs = {
  _inc?: Maybe<Dw_ConsumptionJobs_Inc_Input>;
  _set?: Maybe<Dw_ConsumptionJobs_Set_Input>;
  pk_columns: Dw_ConsumptionJobs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_Consumption_By_PkArgs = {
  _inc?: Maybe<Dw_Consumption_Inc_Input>;
  _set?: Maybe<Dw_Consumption_Set_Input>;
  pk_columns: Dw_Consumption_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_ContactsArgs = {
  _inc?: Maybe<Dw_Contacts_Inc_Input>;
  _set?: Maybe<Dw_Contacts_Set_Input>;
  where: Dw_Contacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_ContactsRoleArgs = {
  _inc?: Maybe<Dw_ContactsRole_Inc_Input>;
  _set?: Maybe<Dw_ContactsRole_Set_Input>;
  where: Dw_ContactsRole_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_Contacts_By_PkArgs = {
  _inc?: Maybe<Dw_Contacts_Inc_Input>;
  _set?: Maybe<Dw_Contacts_Set_Input>;
  pk_columns: Dw_Contacts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_DashboardsArgs = {
  _inc?: Maybe<Dw_Dashboards_Inc_Input>;
  _set?: Maybe<Dw_Dashboards_Set_Input>;
  where: Dw_Dashboards_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_DashboardsContentArgs = {
  _set?: Maybe<Dw_DashboardsContent_Set_Input>;
  where: Dw_DashboardsContent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_DashboardsContent_By_PkArgs = {
  _set?: Maybe<Dw_DashboardsContent_Set_Input>;
  pk_columns: Dw_DashboardsContent_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_DashboardsMetricArgs = {
  _inc?: Maybe<Dw_DashboardsMetric_Inc_Input>;
  _set?: Maybe<Dw_DashboardsMetric_Set_Input>;
  where: Dw_DashboardsMetric_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_DashboardsMetric_By_PkArgs = {
  _inc?: Maybe<Dw_DashboardsMetric_Inc_Input>;
  _set?: Maybe<Dw_DashboardsMetric_Set_Input>;
  pk_columns: Dw_DashboardsMetric_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_Dashboards_By_PkArgs = {
  _inc?: Maybe<Dw_Dashboards_Inc_Input>;
  _set?: Maybe<Dw_Dashboards_Set_Input>;
  pk_columns: Dw_Dashboards_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_DocumentsArgs = {
  _inc?: Maybe<Dw_Documents_Inc_Input>;
  _set?: Maybe<Dw_Documents_Set_Input>;
  where: Dw_Documents_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_Documents_By_PkArgs = {
  _inc?: Maybe<Dw_Documents_Inc_Input>;
  _set?: Maybe<Dw_Documents_Set_Input>;
  pk_columns: Dw_Documents_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_EmissionFactorCategoriesArgs = {
  _inc?: Maybe<Dw_EmissionFactorCategories_Inc_Input>;
  _set?: Maybe<Dw_EmissionFactorCategories_Set_Input>;
  where: Dw_EmissionFactorCategories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_EmissionFactorCategories_By_PkArgs = {
  _inc?: Maybe<Dw_EmissionFactorCategories_Inc_Input>;
  _set?: Maybe<Dw_EmissionFactorCategories_Set_Input>;
  pk_columns: Dw_EmissionFactorCategories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_EmissionFactorSourceArgs = {
  _inc?: Maybe<Dw_EmissionFactorSource_Inc_Input>;
  _set?: Maybe<Dw_EmissionFactorSource_Set_Input>;
  where: Dw_EmissionFactorSource_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_EmissionFactorSource_By_PkArgs = {
  _inc?: Maybe<Dw_EmissionFactorSource_Inc_Input>;
  _set?: Maybe<Dw_EmissionFactorSource_Set_Input>;
  pk_columns: Dw_EmissionFactorSource_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_EmissionFactorsArgs = {
  _inc?: Maybe<Dw_EmissionFactors_Inc_Input>;
  _set?: Maybe<Dw_EmissionFactors_Set_Input>;
  where: Dw_EmissionFactors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_EmissionFactors_By_PkArgs = {
  _inc?: Maybe<Dw_EmissionFactors_Inc_Input>;
  _set?: Maybe<Dw_EmissionFactors_Set_Input>;
  pk_columns: Dw_EmissionFactors_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_FeedsArgs = {
  _inc?: Maybe<Dw_Feeds_Inc_Input>;
  _set?: Maybe<Dw_Feeds_Set_Input>;
  where: Dw_Feeds_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_FeedsConfigBacNetArgs = {
  _inc?: Maybe<Dw_FeedsConfigBacNet_Inc_Input>;
  _set?: Maybe<Dw_FeedsConfigBacNet_Set_Input>;
  where: Dw_FeedsConfigBacNet_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_FeedsConfigBacNet_By_PkArgs = {
  _inc?: Maybe<Dw_FeedsConfigBacNet_Inc_Input>;
  _set?: Maybe<Dw_FeedsConfigBacNet_Set_Input>;
  pk_columns: Dw_FeedsConfigBacNet_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_FeedsConfigChannelsArgs = {
  _inc?: Maybe<Dw_FeedsConfigChannels_Inc_Input>;
  _set?: Maybe<Dw_FeedsConfigChannels_Set_Input>;
  where: Dw_FeedsConfigChannels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_FeedsConfigChannels_By_PkArgs = {
  _inc?: Maybe<Dw_FeedsConfigChannels_Inc_Input>;
  _set?: Maybe<Dw_FeedsConfigChannels_Set_Input>;
  pk_columns: Dw_FeedsConfigChannels_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_FeedsConfigModbusArgs = {
  _inc?: Maybe<Dw_FeedsConfigModbus_Inc_Input>;
  _set?: Maybe<Dw_FeedsConfigModbus_Set_Input>;
  where: Dw_FeedsConfigModbus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_FeedsConfigModbus_By_PkArgs = {
  _inc?: Maybe<Dw_FeedsConfigModbus_Inc_Input>;
  _set?: Maybe<Dw_FeedsConfigModbus_Set_Input>;
  pk_columns: Dw_FeedsConfigModbus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_FeedsLogsArgs = {
  _inc?: Maybe<Dw_FeedsLogs_Inc_Input>;
  _set?: Maybe<Dw_FeedsLogs_Set_Input>;
  where: Dw_FeedsLogs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_FeedsLogs_By_PkArgs = {
  _inc?: Maybe<Dw_FeedsLogs_Inc_Input>;
  _set?: Maybe<Dw_FeedsLogs_Set_Input>;
  pk_columns: Dw_FeedsLogs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_FeedsTypesArgs = {
  _set?: Maybe<Dw_FeedsTypes_Set_Input>;
  where: Dw_FeedsTypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_FeedsTypes_By_PkArgs = {
  _set?: Maybe<Dw_FeedsTypes_Set_Input>;
  pk_columns: Dw_FeedsTypes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_Feeds_By_PkArgs = {
  _inc?: Maybe<Dw_Feeds_Inc_Input>;
  _set?: Maybe<Dw_Feeds_Set_Input>;
  pk_columns: Dw_Feeds_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_GroupsArgs = {
  _inc?: Maybe<Dw_Groups_Inc_Input>;
  _set?: Maybe<Dw_Groups_Set_Input>;
  where: Dw_Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_GroupsSitesArgs = {
  _inc?: Maybe<Dw_GroupsSites_Inc_Input>;
  _set?: Maybe<Dw_GroupsSites_Set_Input>;
  where: Dw_GroupsSites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_GroupsSites_By_PkArgs = {
  _inc?: Maybe<Dw_GroupsSites_Inc_Input>;
  _set?: Maybe<Dw_GroupsSites_Set_Input>;
  pk_columns: Dw_GroupsSites_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_Groups_By_PkArgs = {
  _inc?: Maybe<Dw_Groups_Inc_Input>;
  _set?: Maybe<Dw_Groups_Set_Input>;
  pk_columns: Dw_Groups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_HistoryArgs = {
  _inc?: Maybe<Dw_History_Inc_Input>;
  _set?: Maybe<Dw_History_Set_Input>;
  where: Dw_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_History_By_PkArgs = {
  _inc?: Maybe<Dw_History_Inc_Input>;
  _set?: Maybe<Dw_History_Set_Input>;
  pk_columns: Dw_History_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_LoadDocumentsArgs = {
  _inc?: Maybe<Dw_LoadDocuments_Inc_Input>;
  _set?: Maybe<Dw_LoadDocuments_Set_Input>;
  where: Dw_LoadDocuments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_LoadDocuments_By_PkArgs = {
  _inc?: Maybe<Dw_LoadDocuments_Inc_Input>;
  _set?: Maybe<Dw_LoadDocuments_Set_Input>;
  pk_columns: Dw_LoadDocuments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_LoadsArgs = {
  _inc?: Maybe<Dw_Loads_Inc_Input>;
  _set?: Maybe<Dw_Loads_Set_Input>;
  where: Dw_Loads_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_LoadsServiceArgs = {
  _inc?: Maybe<Dw_LoadsService_Inc_Input>;
  _set?: Maybe<Dw_LoadsService_Set_Input>;
  where: Dw_LoadsService_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_LoadsService_By_PkArgs = {
  _inc?: Maybe<Dw_LoadsService_Inc_Input>;
  _set?: Maybe<Dw_LoadsService_Set_Input>;
  pk_columns: Dw_LoadsService_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_LoadsTypeArgs = {
  _inc?: Maybe<Dw_LoadsType_Inc_Input>;
  _set?: Maybe<Dw_LoadsType_Set_Input>;
  where: Dw_LoadsType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_LoadsType_By_PkArgs = {
  _inc?: Maybe<Dw_LoadsType_Inc_Input>;
  _set?: Maybe<Dw_LoadsType_Set_Input>;
  pk_columns: Dw_LoadsType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_Loads_By_PkArgs = {
  _inc?: Maybe<Dw_Loads_Inc_Input>;
  _set?: Maybe<Dw_Loads_Set_Input>;
  pk_columns: Dw_Loads_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_MlModelsMetadataArgs = {
  _set?: Maybe<Dw_MlModelsMetadata_Set_Input>;
  where: Dw_MlModelsMetadata_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_MlModelsMetadata_By_PkArgs = {
  _set?: Maybe<Dw_MlModelsMetadata_Set_Input>;
  pk_columns: Dw_MlModelsMetadata_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_MlNotificationForecastsArgs = {
  _inc?: Maybe<Dw_MlNotificationForecasts_Inc_Input>;
  _set?: Maybe<Dw_MlNotificationForecasts_Set_Input>;
  where: Dw_MlNotificationForecasts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_MlNotificationGroupArgs = {
  _inc?: Maybe<Dw_MlNotificationGroup_Inc_Input>;
  _set?: Maybe<Dw_MlNotificationGroup_Set_Input>;
  where: Dw_MlNotificationGroup_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_MlNotificationGroup_By_PkArgs = {
  _inc?: Maybe<Dw_MlNotificationGroup_Inc_Input>;
  _set?: Maybe<Dw_MlNotificationGroup_Set_Input>;
  pk_columns: Dw_MlNotificationGroup_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_MlNotificationsArgs = {
  _inc?: Maybe<Dw_MlNotifications_Inc_Input>;
  _set?: Maybe<Dw_MlNotifications_Set_Input>;
  where: Dw_MlNotifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_MlNotificationsCorrelationArgs = {
  _inc?: Maybe<Dw_MlNotificationsCorrelation_Inc_Input>;
  _set?: Maybe<Dw_MlNotificationsCorrelation_Set_Input>;
  where: Dw_MlNotificationsCorrelation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_MlNotificationsCorrelation_By_PkArgs = {
  _inc?: Maybe<Dw_MlNotificationsCorrelation_Inc_Input>;
  _set?: Maybe<Dw_MlNotificationsCorrelation_Set_Input>;
  pk_columns: Dw_MlNotificationsCorrelation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_MlNotificationsMetadataArgs = {
  _inc?: Maybe<Dw_MlNotificationsMetadata_Inc_Input>;
  _set?: Maybe<Dw_MlNotificationsMetadata_Set_Input>;
  where: Dw_MlNotificationsMetadata_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_MlNotificationsMetadata_By_PkArgs = {
  _inc?: Maybe<Dw_MlNotificationsMetadata_Inc_Input>;
  _set?: Maybe<Dw_MlNotificationsMetadata_Set_Input>;
  pk_columns: Dw_MlNotificationsMetadata_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_MlNotifications_By_PkArgs = {
  _inc?: Maybe<Dw_MlNotifications_Inc_Input>;
  _set?: Maybe<Dw_MlNotifications_Set_Input>;
  pk_columns: Dw_MlNotifications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_NotificationsArgs = {
  _inc?: Maybe<Dw_Notifications_Inc_Input>;
  _set?: Maybe<Dw_Notifications_Set_Input>;
  where: Dw_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_NotificationsEventArgs = {
  _inc?: Maybe<Dw_NotificationsEvent_Inc_Input>;
  _set?: Maybe<Dw_NotificationsEvent_Set_Input>;
  where: Dw_NotificationsEvent_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_NotificationsEvent_By_PkArgs = {
  _inc?: Maybe<Dw_NotificationsEvent_Inc_Input>;
  _set?: Maybe<Dw_NotificationsEvent_Set_Input>;
  pk_columns: Dw_NotificationsEvent_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_NotificationsLogArgs = {
  _inc?: Maybe<Dw_NotificationsLog_Inc_Input>;
  _set?: Maybe<Dw_NotificationsLog_Set_Input>;
  where: Dw_NotificationsLog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_NotificationsLog_By_PkArgs = {
  _inc?: Maybe<Dw_NotificationsLog_Inc_Input>;
  _set?: Maybe<Dw_NotificationsLog_Set_Input>;
  pk_columns: Dw_NotificationsLog_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_NotificationsVisibilityArgs = {
  _set?: Maybe<Dw_NotificationsVisibility_Set_Input>;
  where: Dw_NotificationsVisibility_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_NotificationsVisibility_By_PkArgs = {
  _set?: Maybe<Dw_NotificationsVisibility_Set_Input>;
  pk_columns: Dw_NotificationsVisibility_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_Notifications_By_PkArgs = {
  _inc?: Maybe<Dw_Notifications_Inc_Input>;
  _set?: Maybe<Dw_Notifications_Set_Input>;
  pk_columns: Dw_Notifications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_PlansArgs = {
  _inc?: Maybe<Dw_Plans_Inc_Input>;
  _set?: Maybe<Dw_Plans_Set_Input>;
  where: Dw_Plans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_Plans_By_PkArgs = {
  _inc?: Maybe<Dw_Plans_Inc_Input>;
  _set?: Maybe<Dw_Plans_Set_Input>;
  pk_columns: Dw_Plans_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_ProjectsArgs = {
  _inc?: Maybe<Dw_Projects_Inc_Input>;
  _set?: Maybe<Dw_Projects_Set_Input>;
  where: Dw_Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_Projects_By_PkArgs = {
  _inc?: Maybe<Dw_Projects_Inc_Input>;
  _set?: Maybe<Dw_Projects_Set_Input>;
  pk_columns: Dw_Projects_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_ReportsArgs = {
  _inc?: Maybe<Dw_Reports_Inc_Input>;
  _set?: Maybe<Dw_Reports_Set_Input>;
  where: Dw_Reports_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_Reports_By_PkArgs = {
  _inc?: Maybe<Dw_Reports_Inc_Input>;
  _set?: Maybe<Dw_Reports_Set_Input>;
  pk_columns: Dw_Reports_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_SessionsArgs = {
  _inc?: Maybe<Dw_Sessions_Inc_Input>;
  _set?: Maybe<Dw_Sessions_Set_Input>;
  where: Dw_Sessions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_Sessions_By_PkArgs = {
  _inc?: Maybe<Dw_Sessions_Inc_Input>;
  _set?: Maybe<Dw_Sessions_Set_Input>;
  pk_columns: Dw_Sessions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_SitesArgs = {
  _inc?: Maybe<Dw_Sites_Inc_Input>;
  _set?: Maybe<Dw_Sites_Set_Input>;
  where: Dw_Sites_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_Sites_By_PkArgs = {
  _inc?: Maybe<Dw_Sites_Inc_Input>;
  _set?: Maybe<Dw_Sites_Set_Input>;
  pk_columns: Dw_Sites_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_WeatherArgs = {
  _inc?: Maybe<Dw_Weather_Inc_Input>;
  _set?: Maybe<Dw_Weather_Set_Input>;
  where: Dw_Weather_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_WeatherReadingsArgs = {
  _inc?: Maybe<Dw_WeatherReadings_Inc_Input>;
  _set?: Maybe<Dw_WeatherReadings_Set_Input>;
  where: Dw_WeatherReadings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_WeatherReadings_By_PkArgs = {
  _inc?: Maybe<Dw_WeatherReadings_Inc_Input>;
  _set?: Maybe<Dw_WeatherReadings_Set_Input>;
  pk_columns: Dw_WeatherReadings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Dw_Weather_By_PkArgs = {
  _inc?: Maybe<Dw_Weather_Inc_Input>;
  _set?: Maybe<Dw_Weather_Set_Input>;
  pk_columns: Dw_Weather_Pk_Columns_Input;
};


/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "dw.Accounts" */
  dw_Accounts: Array<Dw_Accounts>;
  /** fetch data from the table: "dw.AccountsAddress" */
  dw_AccountsAddress: Array<Dw_AccountsAddress>;
  /** fetch aggregated fields from the table: "dw.AccountsAddress" */
  dw_AccountsAddress_aggregate: Dw_AccountsAddress_Aggregate;
  /** fetch data from the table: "dw.AccountsAddress" using primary key columns */
  dw_AccountsAddress_by_pk?: Maybe<Dw_AccountsAddress>;
  /** fetch data from the table: "dw.AccountsSubscription" */
  dw_AccountsSubscription: Array<Dw_AccountsSubscription>;
  /** fetch aggregated fields from the table: "dw.AccountsSubscription" */
  dw_AccountsSubscription_aggregate: Dw_AccountsSubscription_Aggregate;
  /** fetch data from the table: "dw.AccountsSubscription" using primary key columns */
  dw_AccountsSubscription_by_pk?: Maybe<Dw_AccountsSubscription>;
  /** fetch aggregated fields from the table: "dw.Accounts" */
  dw_Accounts_aggregate: Dw_Accounts_Aggregate;
  /** fetch data from the table: "dw.Accounts" using primary key columns */
  dw_Accounts_by_pk?: Maybe<Dw_Accounts>;
  /** fetch data from the table: "dw.Assessments" */
  dw_Assessments: Array<Dw_Assessments>;
  /** fetch aggregated fields from the table: "dw.Assessments" */
  dw_Assessments_aggregate: Dw_Assessments_Aggregate;
  /** fetch data from the table: "dw.Assessments" using primary key columns */
  dw_Assessments_by_pk?: Maybe<Dw_Assessments>;
  /** fetch data from the table: "dw.Baseline" */
  dw_Baseline: Array<Dw_Baseline>;
  /** fetch aggregated fields from the table: "dw.Baseline" */
  dw_Baseline_aggregate: Dw_Baseline_Aggregate;
  /** fetch data from the table: "dw.Baseline" using primary key columns */
  dw_Baseline_by_pk?: Maybe<Dw_Baseline>;
  /** fetch data from the table: "dw.BuildingDocuments" */
  dw_BuildingDocuments: Array<Dw_BuildingDocuments>;
  /** fetch aggregated fields from the table: "dw.BuildingDocuments" */
  dw_BuildingDocuments_aggregate: Dw_BuildingDocuments_Aggregate;
  /** fetch data from the table: "dw.BuildingDocuments" using primary key columns */
  dw_BuildingDocuments_by_pk?: Maybe<Dw_BuildingDocuments>;
  /** fetch data from the table: "dw.Buildings" */
  dw_Buildings: Array<Dw_Buildings>;
  /** fetch aggregated fields from the table: "dw.Buildings" */
  dw_Buildings_aggregate: Dw_Buildings_Aggregate;
  /** fetch data from the table: "dw.Buildings" using primary key columns */
  dw_Buildings_by_pk?: Maybe<Dw_Buildings>;
  /** fetch data from the table: "dw.Channels" */
  dw_Channels: Array<Dw_Channels>;
  /** fetch data from the table: "dw.ChannelsJobs" */
  dw_ChannelsJobs: Array<Dw_ChannelsJobs>;
  /** fetch aggregated fields from the table: "dw.ChannelsJobs" */
  dw_ChannelsJobs_aggregate: Dw_ChannelsJobs_Aggregate;
  /** fetch data from the table: "dw.ChannelsJobs" using primary key columns */
  dw_ChannelsJobs_by_pk?: Maybe<Dw_ChannelsJobs>;
  /** fetch data from the table: "dw.ChannelsMappings" */
  dw_ChannelsMappings: Array<Dw_ChannelsMappings>;
  /** fetch aggregated fields from the table: "dw.ChannelsMappings" */
  dw_ChannelsMappings_aggregate: Dw_ChannelsMappings_Aggregate;
  /** fetch data from the table: "dw.ChannelsMappings" using primary key columns */
  dw_ChannelsMappings_by_pk?: Maybe<Dw_ChannelsMappings>;
  /** fetch data from the table: "dw.ChannelsMetadata" */
  dw_ChannelsMetadata: Array<Dw_ChannelsMetadata>;
  /** fetch aggregated fields from the table: "dw.ChannelsMetadata" */
  dw_ChannelsMetadata_aggregate: Dw_ChannelsMetadata_Aggregate;
  /** fetch data from the table: "dw.ChannelsMetadata" using primary key columns */
  dw_ChannelsMetadata_by_pk?: Maybe<Dw_ChannelsMetadata>;
  /** fetch data from the table: "dw.ChannelsRules" */
  dw_ChannelsRules: Array<Dw_ChannelsRules>;
  /** fetch aggregated fields from the table: "dw.ChannelsRules" */
  dw_ChannelsRules_aggregate: Dw_ChannelsRules_Aggregate;
  /** fetch data from the table: "dw.ChannelsRules" using primary key columns */
  dw_ChannelsRules_by_pk?: Maybe<Dw_ChannelsRules>;
  /** fetch aggregated fields from the table: "dw.Channels" */
  dw_Channels_aggregate: Dw_Channels_Aggregate;
  /** fetch data from the table: "dw.Channels" using primary key columns */
  dw_Channels_by_pk?: Maybe<Dw_Channels>;
  /** fetch data from the table: "dw.Consumption" */
  dw_Consumption: Array<Dw_Consumption>;
  /** fetch data from the table: "dw.ConsumptionJobs" */
  dw_ConsumptionJobs: Array<Dw_ConsumptionJobs>;
  /** fetch aggregated fields from the table: "dw.ConsumptionJobs" */
  dw_ConsumptionJobs_aggregate: Dw_ConsumptionJobs_Aggregate;
  /** fetch data from the table: "dw.ConsumptionJobs" using primary key columns */
  dw_ConsumptionJobs_by_pk?: Maybe<Dw_ConsumptionJobs>;
  /** fetch aggregated fields from the table: "dw.Consumption" */
  dw_Consumption_aggregate: Dw_Consumption_Aggregate;
  /** fetch data from the table: "dw.Consumption" using primary key columns */
  dw_Consumption_by_pk?: Maybe<Dw_Consumption>;
  /** fetch data from the table: "dw.Contacts" */
  dw_Contacts: Array<Dw_Contacts>;
  /** fetch data from the table: "dw.ContactsRole" */
  dw_ContactsRole: Array<Dw_ContactsRole>;
  /** fetch aggregated fields from the table: "dw.ContactsRole" */
  dw_ContactsRole_aggregate: Dw_ContactsRole_Aggregate;
  /** fetch aggregated fields from the table: "dw.Contacts" */
  dw_Contacts_aggregate: Dw_Contacts_Aggregate;
  /** fetch data from the table: "dw.Contacts" using primary key columns */
  dw_Contacts_by_pk?: Maybe<Dw_Contacts>;
  /** fetch data from the table: "dw.Dashboards" */
  dw_Dashboards: Array<Dw_Dashboards>;
  /** fetch data from the table: "dw.DashboardsContent" */
  dw_DashboardsContent: Array<Dw_DashboardsContent>;
  /** fetch aggregated fields from the table: "dw.DashboardsContent" */
  dw_DashboardsContent_aggregate: Dw_DashboardsContent_Aggregate;
  /** fetch data from the table: "dw.DashboardsContent" using primary key columns */
  dw_DashboardsContent_by_pk?: Maybe<Dw_DashboardsContent>;
  /** fetch data from the table: "dw.DashboardsMetric" */
  dw_DashboardsMetric: Array<Dw_DashboardsMetric>;
  /** fetch aggregated fields from the table: "dw.DashboardsMetric" */
  dw_DashboardsMetric_aggregate: Dw_DashboardsMetric_Aggregate;
  /** fetch data from the table: "dw.DashboardsMetric" using primary key columns */
  dw_DashboardsMetric_by_pk?: Maybe<Dw_DashboardsMetric>;
  /** fetch aggregated fields from the table: "dw.Dashboards" */
  dw_Dashboards_aggregate: Dw_Dashboards_Aggregate;
  /** fetch data from the table: "dw.Dashboards" using primary key columns */
  dw_Dashboards_by_pk?: Maybe<Dw_Dashboards>;
  /** fetch data from the table: "dw.Documents" */
  dw_Documents: Array<Dw_Documents>;
  /** fetch aggregated fields from the table: "dw.Documents" */
  dw_Documents_aggregate: Dw_Documents_Aggregate;
  /** fetch data from the table: "dw.Documents" using primary key columns */
  dw_Documents_by_pk?: Maybe<Dw_Documents>;
  /** fetch data from the table: "dw.EmissionFactorCategories" */
  dw_EmissionFactorCategories: Array<Dw_EmissionFactorCategories>;
  /** fetch aggregated fields from the table: "dw.EmissionFactorCategories" */
  dw_EmissionFactorCategories_aggregate: Dw_EmissionFactorCategories_Aggregate;
  /** fetch data from the table: "dw.EmissionFactorCategories" using primary key columns */
  dw_EmissionFactorCategories_by_pk?: Maybe<Dw_EmissionFactorCategories>;
  /** fetch data from the table: "dw.EmissionFactorSource" */
  dw_EmissionFactorSource: Array<Dw_EmissionFactorSource>;
  /** fetch aggregated fields from the table: "dw.EmissionFactorSource" */
  dw_EmissionFactorSource_aggregate: Dw_EmissionFactorSource_Aggregate;
  /** fetch data from the table: "dw.EmissionFactorSource" using primary key columns */
  dw_EmissionFactorSource_by_pk?: Maybe<Dw_EmissionFactorSource>;
  /** fetch data from the table: "dw.EmissionFactors" */
  dw_EmissionFactors: Array<Dw_EmissionFactors>;
  /** fetch aggregated fields from the table: "dw.EmissionFactors" */
  dw_EmissionFactors_aggregate: Dw_EmissionFactors_Aggregate;
  /** fetch data from the table: "dw.EmissionFactors" using primary key columns */
  dw_EmissionFactors_by_pk?: Maybe<Dw_EmissionFactors>;
  /** fetch data from the table: "dw.Feeds" */
  dw_Feeds: Array<Dw_Feeds>;
  /** fetch data from the table: "dw.FeedsConfigBacNet" */
  dw_FeedsConfigBacNet: Array<Dw_FeedsConfigBacNet>;
  /** fetch aggregated fields from the table: "dw.FeedsConfigBacNet" */
  dw_FeedsConfigBacNet_aggregate: Dw_FeedsConfigBacNet_Aggregate;
  /** fetch data from the table: "dw.FeedsConfigBacNet" using primary key columns */
  dw_FeedsConfigBacNet_by_pk?: Maybe<Dw_FeedsConfigBacNet>;
  /** fetch data from the table: "dw.FeedsConfigChannels" */
  dw_FeedsConfigChannels: Array<Dw_FeedsConfigChannels>;
  /** fetch aggregated fields from the table: "dw.FeedsConfigChannels" */
  dw_FeedsConfigChannels_aggregate: Dw_FeedsConfigChannels_Aggregate;
  /** fetch data from the table: "dw.FeedsConfigChannels" using primary key columns */
  dw_FeedsConfigChannels_by_pk?: Maybe<Dw_FeedsConfigChannels>;
  /** fetch data from the table: "dw.FeedsConfigModbus" */
  dw_FeedsConfigModbus: Array<Dw_FeedsConfigModbus>;
  /** fetch aggregated fields from the table: "dw.FeedsConfigModbus" */
  dw_FeedsConfigModbus_aggregate: Dw_FeedsConfigModbus_Aggregate;
  /** fetch data from the table: "dw.FeedsConfigModbus" using primary key columns */
  dw_FeedsConfigModbus_by_pk?: Maybe<Dw_FeedsConfigModbus>;
  /** fetch data from the table: "dw.FeedsLogs" */
  dw_FeedsLogs: Array<Dw_FeedsLogs>;
  /** fetch aggregated fields from the table: "dw.FeedsLogs" */
  dw_FeedsLogs_aggregate: Dw_FeedsLogs_Aggregate;
  /** fetch data from the table: "dw.FeedsLogs" using primary key columns */
  dw_FeedsLogs_by_pk?: Maybe<Dw_FeedsLogs>;
  /** fetch data from the table: "dw.FeedsTypes" */
  dw_FeedsTypes: Array<Dw_FeedsTypes>;
  /** fetch aggregated fields from the table: "dw.FeedsTypes" */
  dw_FeedsTypes_aggregate: Dw_FeedsTypes_Aggregate;
  /** fetch data from the table: "dw.FeedsTypes" using primary key columns */
  dw_FeedsTypes_by_pk?: Maybe<Dw_FeedsTypes>;
  /** fetch aggregated fields from the table: "dw.Feeds" */
  dw_Feeds_aggregate: Dw_Feeds_Aggregate;
  /** fetch data from the table: "dw.Feeds" using primary key columns */
  dw_Feeds_by_pk?: Maybe<Dw_Feeds>;
  /** fetch data from the table: "dw.Groups" */
  dw_Groups: Array<Dw_Groups>;
  /** fetch data from the table: "dw.GroupsSites" */
  dw_GroupsSites: Array<Dw_GroupsSites>;
  /** fetch aggregated fields from the table: "dw.GroupsSites" */
  dw_GroupsSites_aggregate: Dw_GroupsSites_Aggregate;
  /** fetch data from the table: "dw.GroupsSites" using primary key columns */
  dw_GroupsSites_by_pk?: Maybe<Dw_GroupsSites>;
  /** fetch aggregated fields from the table: "dw.Groups" */
  dw_Groups_aggregate: Dw_Groups_Aggregate;
  /** fetch data from the table: "dw.Groups" using primary key columns */
  dw_Groups_by_pk?: Maybe<Dw_Groups>;
  /** fetch data from the table: "dw.History" */
  dw_History: Array<Dw_History>;
  /** fetch aggregated fields from the table: "dw.History" */
  dw_History_aggregate: Dw_History_Aggregate;
  /** fetch data from the table: "dw.History" using primary key columns */
  dw_History_by_pk?: Maybe<Dw_History>;
  /** fetch data from the table: "dw.LoadDocuments" */
  dw_LoadDocuments: Array<Dw_LoadDocuments>;
  /** fetch aggregated fields from the table: "dw.LoadDocuments" */
  dw_LoadDocuments_aggregate: Dw_LoadDocuments_Aggregate;
  /** fetch data from the table: "dw.LoadDocuments" using primary key columns */
  dw_LoadDocuments_by_pk?: Maybe<Dw_LoadDocuments>;
  /** fetch data from the table: "dw.Loads" */
  dw_Loads: Array<Dw_Loads>;
  /** fetch data from the table: "dw.LoadsService" */
  dw_LoadsService: Array<Dw_LoadsService>;
  /** fetch aggregated fields from the table: "dw.LoadsService" */
  dw_LoadsService_aggregate: Dw_LoadsService_Aggregate;
  /** fetch data from the table: "dw.LoadsService" using primary key columns */
  dw_LoadsService_by_pk?: Maybe<Dw_LoadsService>;
  /** fetch data from the table: "dw.LoadsType" */
  dw_LoadsType: Array<Dw_LoadsType>;
  /** fetch aggregated fields from the table: "dw.LoadsType" */
  dw_LoadsType_aggregate: Dw_LoadsType_Aggregate;
  /** fetch data from the table: "dw.LoadsType" using primary key columns */
  dw_LoadsType_by_pk?: Maybe<Dw_LoadsType>;
  /** fetch aggregated fields from the table: "dw.Loads" */
  dw_Loads_aggregate: Dw_Loads_Aggregate;
  /** fetch data from the table: "dw.Loads" using primary key columns */
  dw_Loads_by_pk?: Maybe<Dw_Loads>;
  /** fetch data from the table: "dw.MLModelsMetadata" */
  dw_MLModelsMetadata: Array<Dw_MlModelsMetadata>;
  /** fetch aggregated fields from the table: "dw.MLModelsMetadata" */
  dw_MLModelsMetadata_aggregate: Dw_MlModelsMetadata_Aggregate;
  /** fetch data from the table: "dw.MLModelsMetadata" using primary key columns */
  dw_MLModelsMetadata_by_pk?: Maybe<Dw_MlModelsMetadata>;
  /** fetch data from the table: "dw.MLNotificationForecasts" */
  dw_MLNotificationForecasts: Array<Dw_MlNotificationForecasts>;
  /** fetch aggregated fields from the table: "dw.MLNotificationForecasts" */
  dw_MLNotificationForecasts_aggregate: Dw_MlNotificationForecasts_Aggregate;
  /** fetch data from the table: "dw.MLNotificationGroup" */
  dw_MLNotificationGroup: Array<Dw_MlNotificationGroup>;
  /** fetch aggregated fields from the table: "dw.MLNotificationGroup" */
  dw_MLNotificationGroup_aggregate: Dw_MlNotificationGroup_Aggregate;
  /** fetch data from the table: "dw.MLNotificationGroup" using primary key columns */
  dw_MLNotificationGroup_by_pk?: Maybe<Dw_MlNotificationGroup>;
  /** fetch data from the table: "dw.MLNotifications" */
  dw_MLNotifications: Array<Dw_MlNotifications>;
  /** fetch data from the table: "dw.MLNotificationsCorrelation" */
  dw_MLNotificationsCorrelation: Array<Dw_MlNotificationsCorrelation>;
  /** fetch aggregated fields from the table: "dw.MLNotificationsCorrelation" */
  dw_MLNotificationsCorrelation_aggregate: Dw_MlNotificationsCorrelation_Aggregate;
  /** fetch data from the table: "dw.MLNotificationsCorrelation" using primary key columns */
  dw_MLNotificationsCorrelation_by_pk?: Maybe<Dw_MlNotificationsCorrelation>;
  /** fetch data from the table: "dw.MLNotificationsMetadata" */
  dw_MLNotificationsMetadata: Array<Dw_MlNotificationsMetadata>;
  /** fetch aggregated fields from the table: "dw.MLNotificationsMetadata" */
  dw_MLNotificationsMetadata_aggregate: Dw_MlNotificationsMetadata_Aggregate;
  /** fetch data from the table: "dw.MLNotificationsMetadata" using primary key columns */
  dw_MLNotificationsMetadata_by_pk?: Maybe<Dw_MlNotificationsMetadata>;
  /** fetch aggregated fields from the table: "dw.MLNotifications" */
  dw_MLNotifications_aggregate: Dw_MlNotifications_Aggregate;
  /** fetch data from the table: "dw.MLNotifications" using primary key columns */
  dw_MLNotifications_by_pk?: Maybe<Dw_MlNotifications>;
  /** fetch data from the table: "dw.Notifications" */
  dw_Notifications: Array<Dw_Notifications>;
  /** fetch data from the table: "dw.NotificationsEvent" */
  dw_NotificationsEvent: Array<Dw_NotificationsEvent>;
  /** fetch aggregated fields from the table: "dw.NotificationsEvent" */
  dw_NotificationsEvent_aggregate: Dw_NotificationsEvent_Aggregate;
  /** fetch data from the table: "dw.NotificationsEvent" using primary key columns */
  dw_NotificationsEvent_by_pk?: Maybe<Dw_NotificationsEvent>;
  /** fetch data from the table: "dw.NotificationsLog" */
  dw_NotificationsLog: Array<Dw_NotificationsLog>;
  /** fetch aggregated fields from the table: "dw.NotificationsLog" */
  dw_NotificationsLog_aggregate: Dw_NotificationsLog_Aggregate;
  /** fetch data from the table: "dw.NotificationsLog" using primary key columns */
  dw_NotificationsLog_by_pk?: Maybe<Dw_NotificationsLog>;
  /** fetch data from the table: "dw.NotificationsVisibility" */
  dw_NotificationsVisibility: Array<Dw_NotificationsVisibility>;
  /** fetch aggregated fields from the table: "dw.NotificationsVisibility" */
  dw_NotificationsVisibility_aggregate: Dw_NotificationsVisibility_Aggregate;
  /** fetch data from the table: "dw.NotificationsVisibility" using primary key columns */
  dw_NotificationsVisibility_by_pk?: Maybe<Dw_NotificationsVisibility>;
  /** fetch aggregated fields from the table: "dw.Notifications" */
  dw_Notifications_aggregate: Dw_Notifications_Aggregate;
  /** fetch data from the table: "dw.Notifications" using primary key columns */
  dw_Notifications_by_pk?: Maybe<Dw_Notifications>;
  /** fetch data from the table: "dw.Plans" */
  dw_Plans: Array<Dw_Plans>;
  /** fetch aggregated fields from the table: "dw.Plans" */
  dw_Plans_aggregate: Dw_Plans_Aggregate;
  /** fetch data from the table: "dw.Plans" using primary key columns */
  dw_Plans_by_pk?: Maybe<Dw_Plans>;
  /** fetch data from the table: "dw.Projects" */
  dw_Projects: Array<Dw_Projects>;
  /** fetch aggregated fields from the table: "dw.Projects" */
  dw_Projects_aggregate: Dw_Projects_Aggregate;
  /** fetch data from the table: "dw.Projects" using primary key columns */
  dw_Projects_by_pk?: Maybe<Dw_Projects>;
  /** fetch data from the table: "dw.Reports" */
  dw_Reports: Array<Dw_Reports>;
  /** fetch aggregated fields from the table: "dw.Reports" */
  dw_Reports_aggregate: Dw_Reports_Aggregate;
  /** fetch data from the table: "dw.Reports" using primary key columns */
  dw_Reports_by_pk?: Maybe<Dw_Reports>;
  /** fetch data from the table: "dw.Sessions" */
  dw_Sessions: Array<Dw_Sessions>;
  /** fetch aggregated fields from the table: "dw.Sessions" */
  dw_Sessions_aggregate: Dw_Sessions_Aggregate;
  /** fetch data from the table: "dw.Sessions" using primary key columns */
  dw_Sessions_by_pk?: Maybe<Dw_Sessions>;
  /** fetch data from the table: "dw.Sites" */
  dw_Sites: Array<Dw_Sites>;
  /** fetch aggregated fields from the table: "dw.Sites" */
  dw_Sites_aggregate: Dw_Sites_Aggregate;
  /** fetch data from the table: "dw.Sites" using primary key columns */
  dw_Sites_by_pk?: Maybe<Dw_Sites>;
  /** fetch data from the table: "dw.Weather" */
  dw_Weather: Array<Dw_Weather>;
  /** fetch data from the table: "dw.WeatherReadings" */
  dw_WeatherReadings: Array<Dw_WeatherReadings>;
  /** fetch aggregated fields from the table: "dw.WeatherReadings" */
  dw_WeatherReadings_aggregate: Dw_WeatherReadings_Aggregate;
  /** fetch data from the table: "dw.WeatherReadings" using primary key columns */
  dw_WeatherReadings_by_pk?: Maybe<Dw_WeatherReadings>;
  /** fetch aggregated fields from the table: "dw.Weather" */
  dw_Weather_aggregate: Dw_Weather_Aggregate;
  /** fetch data from the table: "dw.Weather" using primary key columns */
  dw_Weather_by_pk?: Maybe<Dw_Weather>;
};


export type Query_RootDw_AccountsArgs = {
  distinct_on?: Maybe<Array<Dw_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Accounts_Order_By>>;
  where?: Maybe<Dw_Accounts_Bool_Exp>;
};


export type Query_RootDw_AccountsAddressArgs = {
  distinct_on?: Maybe<Array<Dw_AccountsAddress_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_AccountsAddress_Order_By>>;
  where?: Maybe<Dw_AccountsAddress_Bool_Exp>;
};


export type Query_RootDw_AccountsAddress_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_AccountsAddress_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_AccountsAddress_Order_By>>;
  where?: Maybe<Dw_AccountsAddress_Bool_Exp>;
};


export type Query_RootDw_AccountsAddress_By_PkArgs = {
  AddressId: Scalars['uuid'];
};


export type Query_RootDw_AccountsSubscriptionArgs = {
  distinct_on?: Maybe<Array<Dw_AccountsSubscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_AccountsSubscription_Order_By>>;
  where?: Maybe<Dw_AccountsSubscription_Bool_Exp>;
};


export type Query_RootDw_AccountsSubscription_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_AccountsSubscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_AccountsSubscription_Order_By>>;
  where?: Maybe<Dw_AccountsSubscription_Bool_Exp>;
};


export type Query_RootDw_AccountsSubscription_By_PkArgs = {
  SubscriptionId: Scalars['uuid'];
};


export type Query_RootDw_Accounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Accounts_Order_By>>;
  where?: Maybe<Dw_Accounts_Bool_Exp>;
};


export type Query_RootDw_Accounts_By_PkArgs = {
  AccountId: Scalars['uuid'];
};


export type Query_RootDw_AssessmentsArgs = {
  distinct_on?: Maybe<Array<Dw_Assessments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Assessments_Order_By>>;
  where?: Maybe<Dw_Assessments_Bool_Exp>;
};


export type Query_RootDw_Assessments_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Assessments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Assessments_Order_By>>;
  where?: Maybe<Dw_Assessments_Bool_Exp>;
};


export type Query_RootDw_Assessments_By_PkArgs = {
  AssessmentId: Scalars['uuid'];
};


export type Query_RootDw_BaselineArgs = {
  distinct_on?: Maybe<Array<Dw_Baseline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Baseline_Order_By>>;
  where?: Maybe<Dw_Baseline_Bool_Exp>;
};


export type Query_RootDw_Baseline_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Baseline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Baseline_Order_By>>;
  where?: Maybe<Dw_Baseline_Bool_Exp>;
};


export type Query_RootDw_Baseline_By_PkArgs = {
  ChannelId: Scalars['uuid'];
  ConsumptionDate: Scalars['timestamp'];
};


export type Query_RootDw_BuildingDocumentsArgs = {
  distinct_on?: Maybe<Array<Dw_BuildingDocuments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_BuildingDocuments_Order_By>>;
  where?: Maybe<Dw_BuildingDocuments_Bool_Exp>;
};


export type Query_RootDw_BuildingDocuments_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_BuildingDocuments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_BuildingDocuments_Order_By>>;
  where?: Maybe<Dw_BuildingDocuments_Bool_Exp>;
};


export type Query_RootDw_BuildingDocuments_By_PkArgs = {
  DocumentId: Scalars['uuid'];
};


export type Query_RootDw_BuildingsArgs = {
  distinct_on?: Maybe<Array<Dw_Buildings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Buildings_Order_By>>;
  where?: Maybe<Dw_Buildings_Bool_Exp>;
};


export type Query_RootDw_Buildings_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Buildings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Buildings_Order_By>>;
  where?: Maybe<Dw_Buildings_Bool_Exp>;
};


export type Query_RootDw_Buildings_By_PkArgs = {
  BuildingId: Scalars['uuid'];
};


export type Query_RootDw_ChannelsArgs = {
  distinct_on?: Maybe<Array<Dw_Channels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Channels_Order_By>>;
  where?: Maybe<Dw_Channels_Bool_Exp>;
};


export type Query_RootDw_ChannelsJobsArgs = {
  distinct_on?: Maybe<Array<Dw_ChannelsJobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ChannelsJobs_Order_By>>;
  where?: Maybe<Dw_ChannelsJobs_Bool_Exp>;
};


export type Query_RootDw_ChannelsJobs_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_ChannelsJobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ChannelsJobs_Order_By>>;
  where?: Maybe<Dw_ChannelsJobs_Bool_Exp>;
};


export type Query_RootDw_ChannelsJobs_By_PkArgs = {
  JobId: Scalars['uuid'];
};


export type Query_RootDw_ChannelsMappingsArgs = {
  distinct_on?: Maybe<Array<Dw_ChannelsMappings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ChannelsMappings_Order_By>>;
  where?: Maybe<Dw_ChannelsMappings_Bool_Exp>;
};


export type Query_RootDw_ChannelsMappings_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_ChannelsMappings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ChannelsMappings_Order_By>>;
  where?: Maybe<Dw_ChannelsMappings_Bool_Exp>;
};


export type Query_RootDw_ChannelsMappings_By_PkArgs = {
  ChannelId: Scalars['uuid'];
};


export type Query_RootDw_ChannelsMetadataArgs = {
  distinct_on?: Maybe<Array<Dw_ChannelsMetadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ChannelsMetadata_Order_By>>;
  where?: Maybe<Dw_ChannelsMetadata_Bool_Exp>;
};


export type Query_RootDw_ChannelsMetadata_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_ChannelsMetadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ChannelsMetadata_Order_By>>;
  where?: Maybe<Dw_ChannelsMetadata_Bool_Exp>;
};


export type Query_RootDw_ChannelsMetadata_By_PkArgs = {
  ChannelId: Scalars['uuid'];
};


export type Query_RootDw_ChannelsRulesArgs = {
  distinct_on?: Maybe<Array<Dw_ChannelsRules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ChannelsRules_Order_By>>;
  where?: Maybe<Dw_ChannelsRules_Bool_Exp>;
};


export type Query_RootDw_ChannelsRules_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_ChannelsRules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ChannelsRules_Order_By>>;
  where?: Maybe<Dw_ChannelsRules_Bool_Exp>;
};


export type Query_RootDw_ChannelsRules_By_PkArgs = {
  RuleId: Scalars['uuid'];
};


export type Query_RootDw_Channels_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Channels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Channels_Order_By>>;
  where?: Maybe<Dw_Channels_Bool_Exp>;
};


export type Query_RootDw_Channels_By_PkArgs = {
  ChannelId: Scalars['uuid'];
};


export type Query_RootDw_ConsumptionArgs = {
  distinct_on?: Maybe<Array<Dw_Consumption_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Consumption_Order_By>>;
  where?: Maybe<Dw_Consumption_Bool_Exp>;
};


export type Query_RootDw_ConsumptionJobsArgs = {
  distinct_on?: Maybe<Array<Dw_ConsumptionJobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ConsumptionJobs_Order_By>>;
  where?: Maybe<Dw_ConsumptionJobs_Bool_Exp>;
};


export type Query_RootDw_ConsumptionJobs_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_ConsumptionJobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ConsumptionJobs_Order_By>>;
  where?: Maybe<Dw_ConsumptionJobs_Bool_Exp>;
};


export type Query_RootDw_ConsumptionJobs_By_PkArgs = {
  JobId: Scalars['uuid'];
};


export type Query_RootDw_Consumption_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Consumption_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Consumption_Order_By>>;
  where?: Maybe<Dw_Consumption_Bool_Exp>;
};


export type Query_RootDw_Consumption_By_PkArgs = {
  ChannelId: Scalars['uuid'];
  ConsumptionDate: Scalars['timestamp'];
};


export type Query_RootDw_ContactsArgs = {
  distinct_on?: Maybe<Array<Dw_Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Contacts_Order_By>>;
  where?: Maybe<Dw_Contacts_Bool_Exp>;
};


export type Query_RootDw_ContactsRoleArgs = {
  distinct_on?: Maybe<Array<Dw_ContactsRole_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ContactsRole_Order_By>>;
  where?: Maybe<Dw_ContactsRole_Bool_Exp>;
};


export type Query_RootDw_ContactsRole_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_ContactsRole_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ContactsRole_Order_By>>;
  where?: Maybe<Dw_ContactsRole_Bool_Exp>;
};


export type Query_RootDw_Contacts_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Contacts_Order_By>>;
  where?: Maybe<Dw_Contacts_Bool_Exp>;
};


export type Query_RootDw_Contacts_By_PkArgs = {
  ContactId: Scalars['uuid'];
};


export type Query_RootDw_DashboardsArgs = {
  distinct_on?: Maybe<Array<Dw_Dashboards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Dashboards_Order_By>>;
  where?: Maybe<Dw_Dashboards_Bool_Exp>;
};


export type Query_RootDw_DashboardsContentArgs = {
  distinct_on?: Maybe<Array<Dw_DashboardsContent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_DashboardsContent_Order_By>>;
  where?: Maybe<Dw_DashboardsContent_Bool_Exp>;
};


export type Query_RootDw_DashboardsContent_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_DashboardsContent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_DashboardsContent_Order_By>>;
  where?: Maybe<Dw_DashboardsContent_Bool_Exp>;
};


export type Query_RootDw_DashboardsContent_By_PkArgs = {
  ContentId: Scalars['uuid'];
};


export type Query_RootDw_DashboardsMetricArgs = {
  distinct_on?: Maybe<Array<Dw_DashboardsMetric_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_DashboardsMetric_Order_By>>;
  where?: Maybe<Dw_DashboardsMetric_Bool_Exp>;
};


export type Query_RootDw_DashboardsMetric_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_DashboardsMetric_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_DashboardsMetric_Order_By>>;
  where?: Maybe<Dw_DashboardsMetric_Bool_Exp>;
};


export type Query_RootDw_DashboardsMetric_By_PkArgs = {
  MetricId: Scalars['uuid'];
};


export type Query_RootDw_Dashboards_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Dashboards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Dashboards_Order_By>>;
  where?: Maybe<Dw_Dashboards_Bool_Exp>;
};


export type Query_RootDw_Dashboards_By_PkArgs = {
  DashboardId: Scalars['uuid'];
};


export type Query_RootDw_DocumentsArgs = {
  distinct_on?: Maybe<Array<Dw_Documents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Documents_Order_By>>;
  where?: Maybe<Dw_Documents_Bool_Exp>;
};


export type Query_RootDw_Documents_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Documents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Documents_Order_By>>;
  where?: Maybe<Dw_Documents_Bool_Exp>;
};


export type Query_RootDw_Documents_By_PkArgs = {
  DocumentId: Scalars['uuid'];
};


export type Query_RootDw_EmissionFactorCategoriesArgs = {
  distinct_on?: Maybe<Array<Dw_EmissionFactorCategories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_EmissionFactorCategories_Order_By>>;
  where?: Maybe<Dw_EmissionFactorCategories_Bool_Exp>;
};


export type Query_RootDw_EmissionFactorCategories_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_EmissionFactorCategories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_EmissionFactorCategories_Order_By>>;
  where?: Maybe<Dw_EmissionFactorCategories_Bool_Exp>;
};


export type Query_RootDw_EmissionFactorCategories_By_PkArgs = {
  CategoryId: Scalars['uuid'];
};


export type Query_RootDw_EmissionFactorSourceArgs = {
  distinct_on?: Maybe<Array<Dw_EmissionFactorSource_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_EmissionFactorSource_Order_By>>;
  where?: Maybe<Dw_EmissionFactorSource_Bool_Exp>;
};


export type Query_RootDw_EmissionFactorSource_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_EmissionFactorSource_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_EmissionFactorSource_Order_By>>;
  where?: Maybe<Dw_EmissionFactorSource_Bool_Exp>;
};


export type Query_RootDw_EmissionFactorSource_By_PkArgs = {
  SourceId: Scalars['uuid'];
};


export type Query_RootDw_EmissionFactorsArgs = {
  distinct_on?: Maybe<Array<Dw_EmissionFactors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_EmissionFactors_Order_By>>;
  where?: Maybe<Dw_EmissionFactors_Bool_Exp>;
};


export type Query_RootDw_EmissionFactors_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_EmissionFactors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_EmissionFactors_Order_By>>;
  where?: Maybe<Dw_EmissionFactors_Bool_Exp>;
};


export type Query_RootDw_EmissionFactors_By_PkArgs = {
  EmissionFactorId: Scalars['uuid'];
};


export type Query_RootDw_FeedsArgs = {
  distinct_on?: Maybe<Array<Dw_Feeds_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Feeds_Order_By>>;
  where?: Maybe<Dw_Feeds_Bool_Exp>;
};


export type Query_RootDw_FeedsConfigBacNetArgs = {
  distinct_on?: Maybe<Array<Dw_FeedsConfigBacNet_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_FeedsConfigBacNet_Order_By>>;
  where?: Maybe<Dw_FeedsConfigBacNet_Bool_Exp>;
};


export type Query_RootDw_FeedsConfigBacNet_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_FeedsConfigBacNet_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_FeedsConfigBacNet_Order_By>>;
  where?: Maybe<Dw_FeedsConfigBacNet_Bool_Exp>;
};


export type Query_RootDw_FeedsConfigBacNet_By_PkArgs = {
  ChannelId: Scalars['uuid'];
  FeedId: Scalars['uuid'];
};


export type Query_RootDw_FeedsConfigChannelsArgs = {
  distinct_on?: Maybe<Array<Dw_FeedsConfigChannels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_FeedsConfigChannels_Order_By>>;
  where?: Maybe<Dw_FeedsConfigChannels_Bool_Exp>;
};


export type Query_RootDw_FeedsConfigChannels_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_FeedsConfigChannels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_FeedsConfigChannels_Order_By>>;
  where?: Maybe<Dw_FeedsConfigChannels_Bool_Exp>;
};


export type Query_RootDw_FeedsConfigChannels_By_PkArgs = {
  ChannelId: Scalars['uuid'];
};


export type Query_RootDw_FeedsConfigModbusArgs = {
  distinct_on?: Maybe<Array<Dw_FeedsConfigModbus_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_FeedsConfigModbus_Order_By>>;
  where?: Maybe<Dw_FeedsConfigModbus_Bool_Exp>;
};


export type Query_RootDw_FeedsConfigModbus_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_FeedsConfigModbus_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_FeedsConfigModbus_Order_By>>;
  where?: Maybe<Dw_FeedsConfigModbus_Bool_Exp>;
};


export type Query_RootDw_FeedsConfigModbus_By_PkArgs = {
  ChannelId: Scalars['uuid'];
  FeedId: Scalars['uuid'];
};


export type Query_RootDw_FeedsLogsArgs = {
  distinct_on?: Maybe<Array<Dw_FeedsLogs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_FeedsLogs_Order_By>>;
  where?: Maybe<Dw_FeedsLogs_Bool_Exp>;
};


export type Query_RootDw_FeedsLogs_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_FeedsLogs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_FeedsLogs_Order_By>>;
  where?: Maybe<Dw_FeedsLogs_Bool_Exp>;
};


export type Query_RootDw_FeedsLogs_By_PkArgs = {
  EventId: Scalars['uuid'];
};


export type Query_RootDw_FeedsTypesArgs = {
  distinct_on?: Maybe<Array<Dw_FeedsTypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_FeedsTypes_Order_By>>;
  where?: Maybe<Dw_FeedsTypes_Bool_Exp>;
};


export type Query_RootDw_FeedsTypes_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_FeedsTypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_FeedsTypes_Order_By>>;
  where?: Maybe<Dw_FeedsTypes_Bool_Exp>;
};


export type Query_RootDw_FeedsTypes_By_PkArgs = {
  TypeId: Scalars['uuid'];
};


export type Query_RootDw_Feeds_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Feeds_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Feeds_Order_By>>;
  where?: Maybe<Dw_Feeds_Bool_Exp>;
};


export type Query_RootDw_Feeds_By_PkArgs = {
  FeedId: Scalars['uuid'];
};


export type Query_RootDw_GroupsArgs = {
  distinct_on?: Maybe<Array<Dw_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Groups_Order_By>>;
  where?: Maybe<Dw_Groups_Bool_Exp>;
};


export type Query_RootDw_GroupsSitesArgs = {
  distinct_on?: Maybe<Array<Dw_GroupsSites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_GroupsSites_Order_By>>;
  where?: Maybe<Dw_GroupsSites_Bool_Exp>;
};


export type Query_RootDw_GroupsSites_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_GroupsSites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_GroupsSites_Order_By>>;
  where?: Maybe<Dw_GroupsSites_Bool_Exp>;
};


export type Query_RootDw_GroupsSites_By_PkArgs = {
  GroupId: Scalars['uuid'];
  SiteId: Scalars['uuid'];
};


export type Query_RootDw_Groups_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Groups_Order_By>>;
  where?: Maybe<Dw_Groups_Bool_Exp>;
};


export type Query_RootDw_Groups_By_PkArgs = {
  GroupId: Scalars['uuid'];
};


export type Query_RootDw_HistoryArgs = {
  distinct_on?: Maybe<Array<Dw_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_History_Order_By>>;
  where?: Maybe<Dw_History_Bool_Exp>;
};


export type Query_RootDw_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_History_Order_By>>;
  where?: Maybe<Dw_History_Bool_Exp>;
};


export type Query_RootDw_History_By_PkArgs = {
  HistoryId: Scalars['uuid'];
  SourceId: Scalars['uuid'];
};


export type Query_RootDw_LoadDocumentsArgs = {
  distinct_on?: Maybe<Array<Dw_LoadDocuments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_LoadDocuments_Order_By>>;
  where?: Maybe<Dw_LoadDocuments_Bool_Exp>;
};


export type Query_RootDw_LoadDocuments_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_LoadDocuments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_LoadDocuments_Order_By>>;
  where?: Maybe<Dw_LoadDocuments_Bool_Exp>;
};


export type Query_RootDw_LoadDocuments_By_PkArgs = {
  DocumentId: Scalars['uuid'];
};


export type Query_RootDw_LoadsArgs = {
  distinct_on?: Maybe<Array<Dw_Loads_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Loads_Order_By>>;
  where?: Maybe<Dw_Loads_Bool_Exp>;
};


export type Query_RootDw_LoadsServiceArgs = {
  distinct_on?: Maybe<Array<Dw_LoadsService_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_LoadsService_Order_By>>;
  where?: Maybe<Dw_LoadsService_Bool_Exp>;
};


export type Query_RootDw_LoadsService_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_LoadsService_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_LoadsService_Order_By>>;
  where?: Maybe<Dw_LoadsService_Bool_Exp>;
};


export type Query_RootDw_LoadsService_By_PkArgs = {
  ServiceId: Scalars['uuid'];
};


export type Query_RootDw_LoadsTypeArgs = {
  distinct_on?: Maybe<Array<Dw_LoadsType_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_LoadsType_Order_By>>;
  where?: Maybe<Dw_LoadsType_Bool_Exp>;
};


export type Query_RootDw_LoadsType_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_LoadsType_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_LoadsType_Order_By>>;
  where?: Maybe<Dw_LoadsType_Bool_Exp>;
};


export type Query_RootDw_LoadsType_By_PkArgs = {
  TypeId: Scalars['uuid'];
};


export type Query_RootDw_Loads_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Loads_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Loads_Order_By>>;
  where?: Maybe<Dw_Loads_Bool_Exp>;
};


export type Query_RootDw_Loads_By_PkArgs = {
  LoadId: Scalars['uuid'];
};


export type Query_RootDw_MlModelsMetadataArgs = {
  distinct_on?: Maybe<Array<Dw_MlModelsMetadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlModelsMetadata_Order_By>>;
  where?: Maybe<Dw_MlModelsMetadata_Bool_Exp>;
};


export type Query_RootDw_MlModelsMetadata_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_MlModelsMetadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlModelsMetadata_Order_By>>;
  where?: Maybe<Dw_MlModelsMetadata_Bool_Exp>;
};


export type Query_RootDw_MlModelsMetadata_By_PkArgs = {
  ModelId: Scalars['uuid'];
};


export type Query_RootDw_MlNotificationForecastsArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotificationForecasts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotificationForecasts_Order_By>>;
  where?: Maybe<Dw_MlNotificationForecasts_Bool_Exp>;
};


export type Query_RootDw_MlNotificationForecasts_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotificationForecasts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotificationForecasts_Order_By>>;
  where?: Maybe<Dw_MlNotificationForecasts_Bool_Exp>;
};


export type Query_RootDw_MlNotificationGroupArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotificationGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotificationGroup_Order_By>>;
  where?: Maybe<Dw_MlNotificationGroup_Bool_Exp>;
};


export type Query_RootDw_MlNotificationGroup_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotificationGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotificationGroup_Order_By>>;
  where?: Maybe<Dw_MlNotificationGroup_Bool_Exp>;
};


export type Query_RootDw_MlNotificationGroup_By_PkArgs = {
  NotificationGroupId: Scalars['uuid'];
};


export type Query_RootDw_MlNotificationsArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotifications_Order_By>>;
  where?: Maybe<Dw_MlNotifications_Bool_Exp>;
};


export type Query_RootDw_MlNotificationsCorrelationArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotificationsCorrelation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotificationsCorrelation_Order_By>>;
  where?: Maybe<Dw_MlNotificationsCorrelation_Bool_Exp>;
};


export type Query_RootDw_MlNotificationsCorrelation_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotificationsCorrelation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotificationsCorrelation_Order_By>>;
  where?: Maybe<Dw_MlNotificationsCorrelation_Bool_Exp>;
};


export type Query_RootDw_MlNotificationsCorrelation_By_PkArgs = {
  NotificationsCorrelationId: Scalars['uuid'];
};


export type Query_RootDw_MlNotificationsMetadataArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotificationsMetadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotificationsMetadata_Order_By>>;
  where?: Maybe<Dw_MlNotificationsMetadata_Bool_Exp>;
};


export type Query_RootDw_MlNotificationsMetadata_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotificationsMetadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotificationsMetadata_Order_By>>;
  where?: Maybe<Dw_MlNotificationsMetadata_Bool_Exp>;
};


export type Query_RootDw_MlNotificationsMetadata_By_PkArgs = {
  NotificationGroupId: Scalars['uuid'];
};


export type Query_RootDw_MlNotifications_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotifications_Order_By>>;
  where?: Maybe<Dw_MlNotifications_Bool_Exp>;
};


export type Query_RootDw_MlNotifications_By_PkArgs = {
  NotificationId: Scalars['uuid'];
};


export type Query_RootDw_NotificationsArgs = {
  distinct_on?: Maybe<Array<Dw_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Notifications_Order_By>>;
  where?: Maybe<Dw_Notifications_Bool_Exp>;
};


export type Query_RootDw_NotificationsEventArgs = {
  distinct_on?: Maybe<Array<Dw_NotificationsEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_NotificationsEvent_Order_By>>;
  where?: Maybe<Dw_NotificationsEvent_Bool_Exp>;
};


export type Query_RootDw_NotificationsEvent_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_NotificationsEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_NotificationsEvent_Order_By>>;
  where?: Maybe<Dw_NotificationsEvent_Bool_Exp>;
};


export type Query_RootDw_NotificationsEvent_By_PkArgs = {
  EventId: Scalars['uuid'];
};


export type Query_RootDw_NotificationsLogArgs = {
  distinct_on?: Maybe<Array<Dw_NotificationsLog_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_NotificationsLog_Order_By>>;
  where?: Maybe<Dw_NotificationsLog_Bool_Exp>;
};


export type Query_RootDw_NotificationsLog_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_NotificationsLog_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_NotificationsLog_Order_By>>;
  where?: Maybe<Dw_NotificationsLog_Bool_Exp>;
};


export type Query_RootDw_NotificationsLog_By_PkArgs = {
  LogId: Scalars['uuid'];
};


export type Query_RootDw_NotificationsVisibilityArgs = {
  distinct_on?: Maybe<Array<Dw_NotificationsVisibility_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_NotificationsVisibility_Order_By>>;
  where?: Maybe<Dw_NotificationsVisibility_Bool_Exp>;
};


export type Query_RootDw_NotificationsVisibility_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_NotificationsVisibility_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_NotificationsVisibility_Order_By>>;
  where?: Maybe<Dw_NotificationsVisibility_Bool_Exp>;
};


export type Query_RootDw_NotificationsVisibility_By_PkArgs = {
  ContactId: Scalars['uuid'];
  NotificationId: Scalars['uuid'];
};


export type Query_RootDw_Notifications_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Notifications_Order_By>>;
  where?: Maybe<Dw_Notifications_Bool_Exp>;
};


export type Query_RootDw_Notifications_By_PkArgs = {
  NotificationId: Scalars['uuid'];
};


export type Query_RootDw_PlansArgs = {
  distinct_on?: Maybe<Array<Dw_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Plans_Order_By>>;
  where?: Maybe<Dw_Plans_Bool_Exp>;
};


export type Query_RootDw_Plans_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Plans_Order_By>>;
  where?: Maybe<Dw_Plans_Bool_Exp>;
};


export type Query_RootDw_Plans_By_PkArgs = {
  PlanId: Scalars['uuid'];
};


export type Query_RootDw_ProjectsArgs = {
  distinct_on?: Maybe<Array<Dw_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Projects_Order_By>>;
  where?: Maybe<Dw_Projects_Bool_Exp>;
};


export type Query_RootDw_Projects_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Projects_Order_By>>;
  where?: Maybe<Dw_Projects_Bool_Exp>;
};


export type Query_RootDw_Projects_By_PkArgs = {
  ProjectId: Scalars['uuid'];
};


export type Query_RootDw_ReportsArgs = {
  distinct_on?: Maybe<Array<Dw_Reports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Reports_Order_By>>;
  where?: Maybe<Dw_Reports_Bool_Exp>;
};


export type Query_RootDw_Reports_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Reports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Reports_Order_By>>;
  where?: Maybe<Dw_Reports_Bool_Exp>;
};


export type Query_RootDw_Reports_By_PkArgs = {
  ReportId: Scalars['uuid'];
};


export type Query_RootDw_SessionsArgs = {
  distinct_on?: Maybe<Array<Dw_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Sessions_Order_By>>;
  where?: Maybe<Dw_Sessions_Bool_Exp>;
};


export type Query_RootDw_Sessions_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Sessions_Order_By>>;
  where?: Maybe<Dw_Sessions_Bool_Exp>;
};


export type Query_RootDw_Sessions_By_PkArgs = {
  SessionId: Scalars['uuid'];
};


export type Query_RootDw_SitesArgs = {
  distinct_on?: Maybe<Array<Dw_Sites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Sites_Order_By>>;
  where?: Maybe<Dw_Sites_Bool_Exp>;
};


export type Query_RootDw_Sites_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Sites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Sites_Order_By>>;
  where?: Maybe<Dw_Sites_Bool_Exp>;
};


export type Query_RootDw_Sites_By_PkArgs = {
  SiteId: Scalars['uuid'];
};


export type Query_RootDw_WeatherArgs = {
  distinct_on?: Maybe<Array<Dw_Weather_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Weather_Order_By>>;
  where?: Maybe<Dw_Weather_Bool_Exp>;
};


export type Query_RootDw_WeatherReadingsArgs = {
  distinct_on?: Maybe<Array<Dw_WeatherReadings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_WeatherReadings_Order_By>>;
  where?: Maybe<Dw_WeatherReadings_Bool_Exp>;
};


export type Query_RootDw_WeatherReadings_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_WeatherReadings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_WeatherReadings_Order_By>>;
  where?: Maybe<Dw_WeatherReadings_Bool_Exp>;
};


export type Query_RootDw_WeatherReadings_By_PkArgs = {
  Event: Scalars['timestamp'];
  WeatherId: Scalars['uuid'];
};


export type Query_RootDw_Weather_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Weather_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Weather_Order_By>>;
  where?: Maybe<Dw_Weather_Bool_Exp>;
};


export type Query_RootDw_Weather_By_PkArgs = {
  WeatherId: Scalars['uuid'];
};


/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq?: Maybe<Scalars['smallint']>;
  _gt?: Maybe<Scalars['smallint']>;
  _gte?: Maybe<Scalars['smallint']>;
  _in?: Maybe<Array<Scalars['smallint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['smallint']>;
  _lte?: Maybe<Scalars['smallint']>;
  _neq?: Maybe<Scalars['smallint']>;
  _nin?: Maybe<Array<Scalars['smallint']>>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "dw.Accounts" */
  dw_Accounts: Array<Dw_Accounts>;
  /** fetch data from the table: "dw.AccountsAddress" */
  dw_AccountsAddress: Array<Dw_AccountsAddress>;
  /** fetch aggregated fields from the table: "dw.AccountsAddress" */
  dw_AccountsAddress_aggregate: Dw_AccountsAddress_Aggregate;
  /** fetch data from the table: "dw.AccountsAddress" using primary key columns */
  dw_AccountsAddress_by_pk?: Maybe<Dw_AccountsAddress>;
  /** fetch data from the table: "dw.AccountsSubscription" */
  dw_AccountsSubscription: Array<Dw_AccountsSubscription>;
  /** fetch aggregated fields from the table: "dw.AccountsSubscription" */
  dw_AccountsSubscription_aggregate: Dw_AccountsSubscription_Aggregate;
  /** fetch data from the table: "dw.AccountsSubscription" using primary key columns */
  dw_AccountsSubscription_by_pk?: Maybe<Dw_AccountsSubscription>;
  /** fetch aggregated fields from the table: "dw.Accounts" */
  dw_Accounts_aggregate: Dw_Accounts_Aggregate;
  /** fetch data from the table: "dw.Accounts" using primary key columns */
  dw_Accounts_by_pk?: Maybe<Dw_Accounts>;
  /** fetch data from the table: "dw.Assessments" */
  dw_Assessments: Array<Dw_Assessments>;
  /** fetch aggregated fields from the table: "dw.Assessments" */
  dw_Assessments_aggregate: Dw_Assessments_Aggregate;
  /** fetch data from the table: "dw.Assessments" using primary key columns */
  dw_Assessments_by_pk?: Maybe<Dw_Assessments>;
  /** fetch data from the table: "dw.Baseline" */
  dw_Baseline: Array<Dw_Baseline>;
  /** fetch aggregated fields from the table: "dw.Baseline" */
  dw_Baseline_aggregate: Dw_Baseline_Aggregate;
  /** fetch data from the table: "dw.Baseline" using primary key columns */
  dw_Baseline_by_pk?: Maybe<Dw_Baseline>;
  /** fetch data from the table: "dw.BuildingDocuments" */
  dw_BuildingDocuments: Array<Dw_BuildingDocuments>;
  /** fetch aggregated fields from the table: "dw.BuildingDocuments" */
  dw_BuildingDocuments_aggregate: Dw_BuildingDocuments_Aggregate;
  /** fetch data from the table: "dw.BuildingDocuments" using primary key columns */
  dw_BuildingDocuments_by_pk?: Maybe<Dw_BuildingDocuments>;
  /** fetch data from the table: "dw.Buildings" */
  dw_Buildings: Array<Dw_Buildings>;
  /** fetch aggregated fields from the table: "dw.Buildings" */
  dw_Buildings_aggregate: Dw_Buildings_Aggregate;
  /** fetch data from the table: "dw.Buildings" using primary key columns */
  dw_Buildings_by_pk?: Maybe<Dw_Buildings>;
  /** fetch data from the table: "dw.Channels" */
  dw_Channels: Array<Dw_Channels>;
  /** fetch data from the table: "dw.ChannelsJobs" */
  dw_ChannelsJobs: Array<Dw_ChannelsJobs>;
  /** fetch aggregated fields from the table: "dw.ChannelsJobs" */
  dw_ChannelsJobs_aggregate: Dw_ChannelsJobs_Aggregate;
  /** fetch data from the table: "dw.ChannelsJobs" using primary key columns */
  dw_ChannelsJobs_by_pk?: Maybe<Dw_ChannelsJobs>;
  /** fetch data from the table: "dw.ChannelsMappings" */
  dw_ChannelsMappings: Array<Dw_ChannelsMappings>;
  /** fetch aggregated fields from the table: "dw.ChannelsMappings" */
  dw_ChannelsMappings_aggregate: Dw_ChannelsMappings_Aggregate;
  /** fetch data from the table: "dw.ChannelsMappings" using primary key columns */
  dw_ChannelsMappings_by_pk?: Maybe<Dw_ChannelsMappings>;
  /** fetch data from the table: "dw.ChannelsMetadata" */
  dw_ChannelsMetadata: Array<Dw_ChannelsMetadata>;
  /** fetch aggregated fields from the table: "dw.ChannelsMetadata" */
  dw_ChannelsMetadata_aggregate: Dw_ChannelsMetadata_Aggregate;
  /** fetch data from the table: "dw.ChannelsMetadata" using primary key columns */
  dw_ChannelsMetadata_by_pk?: Maybe<Dw_ChannelsMetadata>;
  /** fetch data from the table: "dw.ChannelsRules" */
  dw_ChannelsRules: Array<Dw_ChannelsRules>;
  /** fetch aggregated fields from the table: "dw.ChannelsRules" */
  dw_ChannelsRules_aggregate: Dw_ChannelsRules_Aggregate;
  /** fetch data from the table: "dw.ChannelsRules" using primary key columns */
  dw_ChannelsRules_by_pk?: Maybe<Dw_ChannelsRules>;
  /** fetch aggregated fields from the table: "dw.Channels" */
  dw_Channels_aggregate: Dw_Channels_Aggregate;
  /** fetch data from the table: "dw.Channels" using primary key columns */
  dw_Channels_by_pk?: Maybe<Dw_Channels>;
  /** fetch data from the table: "dw.Consumption" */
  dw_Consumption: Array<Dw_Consumption>;
  /** fetch data from the table: "dw.ConsumptionJobs" */
  dw_ConsumptionJobs: Array<Dw_ConsumptionJobs>;
  /** fetch aggregated fields from the table: "dw.ConsumptionJobs" */
  dw_ConsumptionJobs_aggregate: Dw_ConsumptionJobs_Aggregate;
  /** fetch data from the table: "dw.ConsumptionJobs" using primary key columns */
  dw_ConsumptionJobs_by_pk?: Maybe<Dw_ConsumptionJobs>;
  /** fetch aggregated fields from the table: "dw.Consumption" */
  dw_Consumption_aggregate: Dw_Consumption_Aggregate;
  /** fetch data from the table: "dw.Consumption" using primary key columns */
  dw_Consumption_by_pk?: Maybe<Dw_Consumption>;
  /** fetch data from the table: "dw.Contacts" */
  dw_Contacts: Array<Dw_Contacts>;
  /** fetch data from the table: "dw.ContactsRole" */
  dw_ContactsRole: Array<Dw_ContactsRole>;
  /** fetch aggregated fields from the table: "dw.ContactsRole" */
  dw_ContactsRole_aggregate: Dw_ContactsRole_Aggregate;
  /** fetch aggregated fields from the table: "dw.Contacts" */
  dw_Contacts_aggregate: Dw_Contacts_Aggregate;
  /** fetch data from the table: "dw.Contacts" using primary key columns */
  dw_Contacts_by_pk?: Maybe<Dw_Contacts>;
  /** fetch data from the table: "dw.Dashboards" */
  dw_Dashboards: Array<Dw_Dashboards>;
  /** fetch data from the table: "dw.DashboardsContent" */
  dw_DashboardsContent: Array<Dw_DashboardsContent>;
  /** fetch aggregated fields from the table: "dw.DashboardsContent" */
  dw_DashboardsContent_aggregate: Dw_DashboardsContent_Aggregate;
  /** fetch data from the table: "dw.DashboardsContent" using primary key columns */
  dw_DashboardsContent_by_pk?: Maybe<Dw_DashboardsContent>;
  /** fetch data from the table: "dw.DashboardsMetric" */
  dw_DashboardsMetric: Array<Dw_DashboardsMetric>;
  /** fetch aggregated fields from the table: "dw.DashboardsMetric" */
  dw_DashboardsMetric_aggregate: Dw_DashboardsMetric_Aggregate;
  /** fetch data from the table: "dw.DashboardsMetric" using primary key columns */
  dw_DashboardsMetric_by_pk?: Maybe<Dw_DashboardsMetric>;
  /** fetch aggregated fields from the table: "dw.Dashboards" */
  dw_Dashboards_aggregate: Dw_Dashboards_Aggregate;
  /** fetch data from the table: "dw.Dashboards" using primary key columns */
  dw_Dashboards_by_pk?: Maybe<Dw_Dashboards>;
  /** fetch data from the table: "dw.Documents" */
  dw_Documents: Array<Dw_Documents>;
  /** fetch aggregated fields from the table: "dw.Documents" */
  dw_Documents_aggregate: Dw_Documents_Aggregate;
  /** fetch data from the table: "dw.Documents" using primary key columns */
  dw_Documents_by_pk?: Maybe<Dw_Documents>;
  /** fetch data from the table: "dw.EmissionFactorCategories" */
  dw_EmissionFactorCategories: Array<Dw_EmissionFactorCategories>;
  /** fetch aggregated fields from the table: "dw.EmissionFactorCategories" */
  dw_EmissionFactorCategories_aggregate: Dw_EmissionFactorCategories_Aggregate;
  /** fetch data from the table: "dw.EmissionFactorCategories" using primary key columns */
  dw_EmissionFactorCategories_by_pk?: Maybe<Dw_EmissionFactorCategories>;
  /** fetch data from the table: "dw.EmissionFactorSource" */
  dw_EmissionFactorSource: Array<Dw_EmissionFactorSource>;
  /** fetch aggregated fields from the table: "dw.EmissionFactorSource" */
  dw_EmissionFactorSource_aggregate: Dw_EmissionFactorSource_Aggregate;
  /** fetch data from the table: "dw.EmissionFactorSource" using primary key columns */
  dw_EmissionFactorSource_by_pk?: Maybe<Dw_EmissionFactorSource>;
  /** fetch data from the table: "dw.EmissionFactors" */
  dw_EmissionFactors: Array<Dw_EmissionFactors>;
  /** fetch aggregated fields from the table: "dw.EmissionFactors" */
  dw_EmissionFactors_aggregate: Dw_EmissionFactors_Aggregate;
  /** fetch data from the table: "dw.EmissionFactors" using primary key columns */
  dw_EmissionFactors_by_pk?: Maybe<Dw_EmissionFactors>;
  /** fetch data from the table: "dw.Feeds" */
  dw_Feeds: Array<Dw_Feeds>;
  /** fetch data from the table: "dw.FeedsConfigBacNet" */
  dw_FeedsConfigBacNet: Array<Dw_FeedsConfigBacNet>;
  /** fetch aggregated fields from the table: "dw.FeedsConfigBacNet" */
  dw_FeedsConfigBacNet_aggregate: Dw_FeedsConfigBacNet_Aggregate;
  /** fetch data from the table: "dw.FeedsConfigBacNet" using primary key columns */
  dw_FeedsConfigBacNet_by_pk?: Maybe<Dw_FeedsConfigBacNet>;
  /** fetch data from the table: "dw.FeedsConfigChannels" */
  dw_FeedsConfigChannels: Array<Dw_FeedsConfigChannels>;
  /** fetch aggregated fields from the table: "dw.FeedsConfigChannels" */
  dw_FeedsConfigChannels_aggregate: Dw_FeedsConfigChannels_Aggregate;
  /** fetch data from the table: "dw.FeedsConfigChannels" using primary key columns */
  dw_FeedsConfigChannels_by_pk?: Maybe<Dw_FeedsConfigChannels>;
  /** fetch data from the table: "dw.FeedsConfigModbus" */
  dw_FeedsConfigModbus: Array<Dw_FeedsConfigModbus>;
  /** fetch aggregated fields from the table: "dw.FeedsConfigModbus" */
  dw_FeedsConfigModbus_aggregate: Dw_FeedsConfigModbus_Aggregate;
  /** fetch data from the table: "dw.FeedsConfigModbus" using primary key columns */
  dw_FeedsConfigModbus_by_pk?: Maybe<Dw_FeedsConfigModbus>;
  /** fetch data from the table: "dw.FeedsLogs" */
  dw_FeedsLogs: Array<Dw_FeedsLogs>;
  /** fetch aggregated fields from the table: "dw.FeedsLogs" */
  dw_FeedsLogs_aggregate: Dw_FeedsLogs_Aggregate;
  /** fetch data from the table: "dw.FeedsLogs" using primary key columns */
  dw_FeedsLogs_by_pk?: Maybe<Dw_FeedsLogs>;
  /** fetch data from the table: "dw.FeedsTypes" */
  dw_FeedsTypes: Array<Dw_FeedsTypes>;
  /** fetch aggregated fields from the table: "dw.FeedsTypes" */
  dw_FeedsTypes_aggregate: Dw_FeedsTypes_Aggregate;
  /** fetch data from the table: "dw.FeedsTypes" using primary key columns */
  dw_FeedsTypes_by_pk?: Maybe<Dw_FeedsTypes>;
  /** fetch aggregated fields from the table: "dw.Feeds" */
  dw_Feeds_aggregate: Dw_Feeds_Aggregate;
  /** fetch data from the table: "dw.Feeds" using primary key columns */
  dw_Feeds_by_pk?: Maybe<Dw_Feeds>;
  /** fetch data from the table: "dw.Groups" */
  dw_Groups: Array<Dw_Groups>;
  /** fetch data from the table: "dw.GroupsSites" */
  dw_GroupsSites: Array<Dw_GroupsSites>;
  /** fetch aggregated fields from the table: "dw.GroupsSites" */
  dw_GroupsSites_aggregate: Dw_GroupsSites_Aggregate;
  /** fetch data from the table: "dw.GroupsSites" using primary key columns */
  dw_GroupsSites_by_pk?: Maybe<Dw_GroupsSites>;
  /** fetch aggregated fields from the table: "dw.Groups" */
  dw_Groups_aggregate: Dw_Groups_Aggregate;
  /** fetch data from the table: "dw.Groups" using primary key columns */
  dw_Groups_by_pk?: Maybe<Dw_Groups>;
  /** fetch data from the table: "dw.History" */
  dw_History: Array<Dw_History>;
  /** fetch aggregated fields from the table: "dw.History" */
  dw_History_aggregate: Dw_History_Aggregate;
  /** fetch data from the table: "dw.History" using primary key columns */
  dw_History_by_pk?: Maybe<Dw_History>;
  /** fetch data from the table: "dw.LoadDocuments" */
  dw_LoadDocuments: Array<Dw_LoadDocuments>;
  /** fetch aggregated fields from the table: "dw.LoadDocuments" */
  dw_LoadDocuments_aggregate: Dw_LoadDocuments_Aggregate;
  /** fetch data from the table: "dw.LoadDocuments" using primary key columns */
  dw_LoadDocuments_by_pk?: Maybe<Dw_LoadDocuments>;
  /** fetch data from the table: "dw.Loads" */
  dw_Loads: Array<Dw_Loads>;
  /** fetch data from the table: "dw.LoadsService" */
  dw_LoadsService: Array<Dw_LoadsService>;
  /** fetch aggregated fields from the table: "dw.LoadsService" */
  dw_LoadsService_aggregate: Dw_LoadsService_Aggregate;
  /** fetch data from the table: "dw.LoadsService" using primary key columns */
  dw_LoadsService_by_pk?: Maybe<Dw_LoadsService>;
  /** fetch data from the table: "dw.LoadsType" */
  dw_LoadsType: Array<Dw_LoadsType>;
  /** fetch aggregated fields from the table: "dw.LoadsType" */
  dw_LoadsType_aggregate: Dw_LoadsType_Aggregate;
  /** fetch data from the table: "dw.LoadsType" using primary key columns */
  dw_LoadsType_by_pk?: Maybe<Dw_LoadsType>;
  /** fetch aggregated fields from the table: "dw.Loads" */
  dw_Loads_aggregate: Dw_Loads_Aggregate;
  /** fetch data from the table: "dw.Loads" using primary key columns */
  dw_Loads_by_pk?: Maybe<Dw_Loads>;
  /** fetch data from the table: "dw.MLModelsMetadata" */
  dw_MLModelsMetadata: Array<Dw_MlModelsMetadata>;
  /** fetch aggregated fields from the table: "dw.MLModelsMetadata" */
  dw_MLModelsMetadata_aggregate: Dw_MlModelsMetadata_Aggregate;
  /** fetch data from the table: "dw.MLModelsMetadata" using primary key columns */
  dw_MLModelsMetadata_by_pk?: Maybe<Dw_MlModelsMetadata>;
  /** fetch data from the table: "dw.MLNotificationForecasts" */
  dw_MLNotificationForecasts: Array<Dw_MlNotificationForecasts>;
  /** fetch aggregated fields from the table: "dw.MLNotificationForecasts" */
  dw_MLNotificationForecasts_aggregate: Dw_MlNotificationForecasts_Aggregate;
  /** fetch data from the table: "dw.MLNotificationGroup" */
  dw_MLNotificationGroup: Array<Dw_MlNotificationGroup>;
  /** fetch aggregated fields from the table: "dw.MLNotificationGroup" */
  dw_MLNotificationGroup_aggregate: Dw_MlNotificationGroup_Aggregate;
  /** fetch data from the table: "dw.MLNotificationGroup" using primary key columns */
  dw_MLNotificationGroup_by_pk?: Maybe<Dw_MlNotificationGroup>;
  /** fetch data from the table: "dw.MLNotifications" */
  dw_MLNotifications: Array<Dw_MlNotifications>;
  /** fetch data from the table: "dw.MLNotificationsCorrelation" */
  dw_MLNotificationsCorrelation: Array<Dw_MlNotificationsCorrelation>;
  /** fetch aggregated fields from the table: "dw.MLNotificationsCorrelation" */
  dw_MLNotificationsCorrelation_aggregate: Dw_MlNotificationsCorrelation_Aggregate;
  /** fetch data from the table: "dw.MLNotificationsCorrelation" using primary key columns */
  dw_MLNotificationsCorrelation_by_pk?: Maybe<Dw_MlNotificationsCorrelation>;
  /** fetch data from the table: "dw.MLNotificationsMetadata" */
  dw_MLNotificationsMetadata: Array<Dw_MlNotificationsMetadata>;
  /** fetch aggregated fields from the table: "dw.MLNotificationsMetadata" */
  dw_MLNotificationsMetadata_aggregate: Dw_MlNotificationsMetadata_Aggregate;
  /** fetch data from the table: "dw.MLNotificationsMetadata" using primary key columns */
  dw_MLNotificationsMetadata_by_pk?: Maybe<Dw_MlNotificationsMetadata>;
  /** fetch aggregated fields from the table: "dw.MLNotifications" */
  dw_MLNotifications_aggregate: Dw_MlNotifications_Aggregate;
  /** fetch data from the table: "dw.MLNotifications" using primary key columns */
  dw_MLNotifications_by_pk?: Maybe<Dw_MlNotifications>;
  /** fetch data from the table: "dw.Notifications" */
  dw_Notifications: Array<Dw_Notifications>;
  /** fetch data from the table: "dw.NotificationsEvent" */
  dw_NotificationsEvent: Array<Dw_NotificationsEvent>;
  /** fetch aggregated fields from the table: "dw.NotificationsEvent" */
  dw_NotificationsEvent_aggregate: Dw_NotificationsEvent_Aggregate;
  /** fetch data from the table: "dw.NotificationsEvent" using primary key columns */
  dw_NotificationsEvent_by_pk?: Maybe<Dw_NotificationsEvent>;
  /** fetch data from the table: "dw.NotificationsLog" */
  dw_NotificationsLog: Array<Dw_NotificationsLog>;
  /** fetch aggregated fields from the table: "dw.NotificationsLog" */
  dw_NotificationsLog_aggregate: Dw_NotificationsLog_Aggregate;
  /** fetch data from the table: "dw.NotificationsLog" using primary key columns */
  dw_NotificationsLog_by_pk?: Maybe<Dw_NotificationsLog>;
  /** fetch data from the table: "dw.NotificationsVisibility" */
  dw_NotificationsVisibility: Array<Dw_NotificationsVisibility>;
  /** fetch aggregated fields from the table: "dw.NotificationsVisibility" */
  dw_NotificationsVisibility_aggregate: Dw_NotificationsVisibility_Aggregate;
  /** fetch data from the table: "dw.NotificationsVisibility" using primary key columns */
  dw_NotificationsVisibility_by_pk?: Maybe<Dw_NotificationsVisibility>;
  /** fetch aggregated fields from the table: "dw.Notifications" */
  dw_Notifications_aggregate: Dw_Notifications_Aggregate;
  /** fetch data from the table: "dw.Notifications" using primary key columns */
  dw_Notifications_by_pk?: Maybe<Dw_Notifications>;
  /** fetch data from the table: "dw.Plans" */
  dw_Plans: Array<Dw_Plans>;
  /** fetch aggregated fields from the table: "dw.Plans" */
  dw_Plans_aggregate: Dw_Plans_Aggregate;
  /** fetch data from the table: "dw.Plans" using primary key columns */
  dw_Plans_by_pk?: Maybe<Dw_Plans>;
  /** fetch data from the table: "dw.Projects" */
  dw_Projects: Array<Dw_Projects>;
  /** fetch aggregated fields from the table: "dw.Projects" */
  dw_Projects_aggregate: Dw_Projects_Aggregate;
  /** fetch data from the table: "dw.Projects" using primary key columns */
  dw_Projects_by_pk?: Maybe<Dw_Projects>;
  /** fetch data from the table: "dw.Reports" */
  dw_Reports: Array<Dw_Reports>;
  /** fetch aggregated fields from the table: "dw.Reports" */
  dw_Reports_aggregate: Dw_Reports_Aggregate;
  /** fetch data from the table: "dw.Reports" using primary key columns */
  dw_Reports_by_pk?: Maybe<Dw_Reports>;
  /** fetch data from the table: "dw.Sessions" */
  dw_Sessions: Array<Dw_Sessions>;
  /** fetch aggregated fields from the table: "dw.Sessions" */
  dw_Sessions_aggregate: Dw_Sessions_Aggregate;
  /** fetch data from the table: "dw.Sessions" using primary key columns */
  dw_Sessions_by_pk?: Maybe<Dw_Sessions>;
  /** fetch data from the table: "dw.Sites" */
  dw_Sites: Array<Dw_Sites>;
  /** fetch aggregated fields from the table: "dw.Sites" */
  dw_Sites_aggregate: Dw_Sites_Aggregate;
  /** fetch data from the table: "dw.Sites" using primary key columns */
  dw_Sites_by_pk?: Maybe<Dw_Sites>;
  /** fetch data from the table: "dw.Weather" */
  dw_Weather: Array<Dw_Weather>;
  /** fetch data from the table: "dw.WeatherReadings" */
  dw_WeatherReadings: Array<Dw_WeatherReadings>;
  /** fetch aggregated fields from the table: "dw.WeatherReadings" */
  dw_WeatherReadings_aggregate: Dw_WeatherReadings_Aggregate;
  /** fetch data from the table: "dw.WeatherReadings" using primary key columns */
  dw_WeatherReadings_by_pk?: Maybe<Dw_WeatherReadings>;
  /** fetch aggregated fields from the table: "dw.Weather" */
  dw_Weather_aggregate: Dw_Weather_Aggregate;
  /** fetch data from the table: "dw.Weather" using primary key columns */
  dw_Weather_by_pk?: Maybe<Dw_Weather>;
};


export type Subscription_RootDw_AccountsArgs = {
  distinct_on?: Maybe<Array<Dw_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Accounts_Order_By>>;
  where?: Maybe<Dw_Accounts_Bool_Exp>;
};


export type Subscription_RootDw_AccountsAddressArgs = {
  distinct_on?: Maybe<Array<Dw_AccountsAddress_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_AccountsAddress_Order_By>>;
  where?: Maybe<Dw_AccountsAddress_Bool_Exp>;
};


export type Subscription_RootDw_AccountsAddress_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_AccountsAddress_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_AccountsAddress_Order_By>>;
  where?: Maybe<Dw_AccountsAddress_Bool_Exp>;
};


export type Subscription_RootDw_AccountsAddress_By_PkArgs = {
  AddressId: Scalars['uuid'];
};


export type Subscription_RootDw_AccountsSubscriptionArgs = {
  distinct_on?: Maybe<Array<Dw_AccountsSubscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_AccountsSubscription_Order_By>>;
  where?: Maybe<Dw_AccountsSubscription_Bool_Exp>;
};


export type Subscription_RootDw_AccountsSubscription_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_AccountsSubscription_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_AccountsSubscription_Order_By>>;
  where?: Maybe<Dw_AccountsSubscription_Bool_Exp>;
};


export type Subscription_RootDw_AccountsSubscription_By_PkArgs = {
  SubscriptionId: Scalars['uuid'];
};


export type Subscription_RootDw_Accounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Accounts_Order_By>>;
  where?: Maybe<Dw_Accounts_Bool_Exp>;
};


export type Subscription_RootDw_Accounts_By_PkArgs = {
  AccountId: Scalars['uuid'];
};


export type Subscription_RootDw_AssessmentsArgs = {
  distinct_on?: Maybe<Array<Dw_Assessments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Assessments_Order_By>>;
  where?: Maybe<Dw_Assessments_Bool_Exp>;
};


export type Subscription_RootDw_Assessments_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Assessments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Assessments_Order_By>>;
  where?: Maybe<Dw_Assessments_Bool_Exp>;
};


export type Subscription_RootDw_Assessments_By_PkArgs = {
  AssessmentId: Scalars['uuid'];
};


export type Subscription_RootDw_BaselineArgs = {
  distinct_on?: Maybe<Array<Dw_Baseline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Baseline_Order_By>>;
  where?: Maybe<Dw_Baseline_Bool_Exp>;
};


export type Subscription_RootDw_Baseline_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Baseline_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Baseline_Order_By>>;
  where?: Maybe<Dw_Baseline_Bool_Exp>;
};


export type Subscription_RootDw_Baseline_By_PkArgs = {
  ChannelId: Scalars['uuid'];
  ConsumptionDate: Scalars['timestamp'];
};


export type Subscription_RootDw_BuildingDocumentsArgs = {
  distinct_on?: Maybe<Array<Dw_BuildingDocuments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_BuildingDocuments_Order_By>>;
  where?: Maybe<Dw_BuildingDocuments_Bool_Exp>;
};


export type Subscription_RootDw_BuildingDocuments_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_BuildingDocuments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_BuildingDocuments_Order_By>>;
  where?: Maybe<Dw_BuildingDocuments_Bool_Exp>;
};


export type Subscription_RootDw_BuildingDocuments_By_PkArgs = {
  DocumentId: Scalars['uuid'];
};


export type Subscription_RootDw_BuildingsArgs = {
  distinct_on?: Maybe<Array<Dw_Buildings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Buildings_Order_By>>;
  where?: Maybe<Dw_Buildings_Bool_Exp>;
};


export type Subscription_RootDw_Buildings_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Buildings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Buildings_Order_By>>;
  where?: Maybe<Dw_Buildings_Bool_Exp>;
};


export type Subscription_RootDw_Buildings_By_PkArgs = {
  BuildingId: Scalars['uuid'];
};


export type Subscription_RootDw_ChannelsArgs = {
  distinct_on?: Maybe<Array<Dw_Channels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Channels_Order_By>>;
  where?: Maybe<Dw_Channels_Bool_Exp>;
};


export type Subscription_RootDw_ChannelsJobsArgs = {
  distinct_on?: Maybe<Array<Dw_ChannelsJobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ChannelsJobs_Order_By>>;
  where?: Maybe<Dw_ChannelsJobs_Bool_Exp>;
};


export type Subscription_RootDw_ChannelsJobs_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_ChannelsJobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ChannelsJobs_Order_By>>;
  where?: Maybe<Dw_ChannelsJobs_Bool_Exp>;
};


export type Subscription_RootDw_ChannelsJobs_By_PkArgs = {
  JobId: Scalars['uuid'];
};


export type Subscription_RootDw_ChannelsMappingsArgs = {
  distinct_on?: Maybe<Array<Dw_ChannelsMappings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ChannelsMappings_Order_By>>;
  where?: Maybe<Dw_ChannelsMappings_Bool_Exp>;
};


export type Subscription_RootDw_ChannelsMappings_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_ChannelsMappings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ChannelsMappings_Order_By>>;
  where?: Maybe<Dw_ChannelsMappings_Bool_Exp>;
};


export type Subscription_RootDw_ChannelsMappings_By_PkArgs = {
  ChannelId: Scalars['uuid'];
};


export type Subscription_RootDw_ChannelsMetadataArgs = {
  distinct_on?: Maybe<Array<Dw_ChannelsMetadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ChannelsMetadata_Order_By>>;
  where?: Maybe<Dw_ChannelsMetadata_Bool_Exp>;
};


export type Subscription_RootDw_ChannelsMetadata_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_ChannelsMetadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ChannelsMetadata_Order_By>>;
  where?: Maybe<Dw_ChannelsMetadata_Bool_Exp>;
};


export type Subscription_RootDw_ChannelsMetadata_By_PkArgs = {
  ChannelId: Scalars['uuid'];
};


export type Subscription_RootDw_ChannelsRulesArgs = {
  distinct_on?: Maybe<Array<Dw_ChannelsRules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ChannelsRules_Order_By>>;
  where?: Maybe<Dw_ChannelsRules_Bool_Exp>;
};


export type Subscription_RootDw_ChannelsRules_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_ChannelsRules_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ChannelsRules_Order_By>>;
  where?: Maybe<Dw_ChannelsRules_Bool_Exp>;
};


export type Subscription_RootDw_ChannelsRules_By_PkArgs = {
  RuleId: Scalars['uuid'];
};


export type Subscription_RootDw_Channels_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Channels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Channels_Order_By>>;
  where?: Maybe<Dw_Channels_Bool_Exp>;
};


export type Subscription_RootDw_Channels_By_PkArgs = {
  ChannelId: Scalars['uuid'];
};


export type Subscription_RootDw_ConsumptionArgs = {
  distinct_on?: Maybe<Array<Dw_Consumption_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Consumption_Order_By>>;
  where?: Maybe<Dw_Consumption_Bool_Exp>;
};


export type Subscription_RootDw_ConsumptionJobsArgs = {
  distinct_on?: Maybe<Array<Dw_ConsumptionJobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ConsumptionJobs_Order_By>>;
  where?: Maybe<Dw_ConsumptionJobs_Bool_Exp>;
};


export type Subscription_RootDw_ConsumptionJobs_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_ConsumptionJobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ConsumptionJobs_Order_By>>;
  where?: Maybe<Dw_ConsumptionJobs_Bool_Exp>;
};


export type Subscription_RootDw_ConsumptionJobs_By_PkArgs = {
  JobId: Scalars['uuid'];
};


export type Subscription_RootDw_Consumption_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Consumption_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Consumption_Order_By>>;
  where?: Maybe<Dw_Consumption_Bool_Exp>;
};


export type Subscription_RootDw_Consumption_By_PkArgs = {
  ChannelId: Scalars['uuid'];
  ConsumptionDate: Scalars['timestamp'];
};


export type Subscription_RootDw_ContactsArgs = {
  distinct_on?: Maybe<Array<Dw_Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Contacts_Order_By>>;
  where?: Maybe<Dw_Contacts_Bool_Exp>;
};


export type Subscription_RootDw_ContactsRoleArgs = {
  distinct_on?: Maybe<Array<Dw_ContactsRole_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ContactsRole_Order_By>>;
  where?: Maybe<Dw_ContactsRole_Bool_Exp>;
};


export type Subscription_RootDw_ContactsRole_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_ContactsRole_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_ContactsRole_Order_By>>;
  where?: Maybe<Dw_ContactsRole_Bool_Exp>;
};


export type Subscription_RootDw_Contacts_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Contacts_Order_By>>;
  where?: Maybe<Dw_Contacts_Bool_Exp>;
};


export type Subscription_RootDw_Contacts_By_PkArgs = {
  ContactId: Scalars['uuid'];
};


export type Subscription_RootDw_DashboardsArgs = {
  distinct_on?: Maybe<Array<Dw_Dashboards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Dashboards_Order_By>>;
  where?: Maybe<Dw_Dashboards_Bool_Exp>;
};


export type Subscription_RootDw_DashboardsContentArgs = {
  distinct_on?: Maybe<Array<Dw_DashboardsContent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_DashboardsContent_Order_By>>;
  where?: Maybe<Dw_DashboardsContent_Bool_Exp>;
};


export type Subscription_RootDw_DashboardsContent_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_DashboardsContent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_DashboardsContent_Order_By>>;
  where?: Maybe<Dw_DashboardsContent_Bool_Exp>;
};


export type Subscription_RootDw_DashboardsContent_By_PkArgs = {
  ContentId: Scalars['uuid'];
};


export type Subscription_RootDw_DashboardsMetricArgs = {
  distinct_on?: Maybe<Array<Dw_DashboardsMetric_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_DashboardsMetric_Order_By>>;
  where?: Maybe<Dw_DashboardsMetric_Bool_Exp>;
};


export type Subscription_RootDw_DashboardsMetric_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_DashboardsMetric_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_DashboardsMetric_Order_By>>;
  where?: Maybe<Dw_DashboardsMetric_Bool_Exp>;
};


export type Subscription_RootDw_DashboardsMetric_By_PkArgs = {
  MetricId: Scalars['uuid'];
};


export type Subscription_RootDw_Dashboards_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Dashboards_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Dashboards_Order_By>>;
  where?: Maybe<Dw_Dashboards_Bool_Exp>;
};


export type Subscription_RootDw_Dashboards_By_PkArgs = {
  DashboardId: Scalars['uuid'];
};


export type Subscription_RootDw_DocumentsArgs = {
  distinct_on?: Maybe<Array<Dw_Documents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Documents_Order_By>>;
  where?: Maybe<Dw_Documents_Bool_Exp>;
};


export type Subscription_RootDw_Documents_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Documents_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Documents_Order_By>>;
  where?: Maybe<Dw_Documents_Bool_Exp>;
};


export type Subscription_RootDw_Documents_By_PkArgs = {
  DocumentId: Scalars['uuid'];
};


export type Subscription_RootDw_EmissionFactorCategoriesArgs = {
  distinct_on?: Maybe<Array<Dw_EmissionFactorCategories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_EmissionFactorCategories_Order_By>>;
  where?: Maybe<Dw_EmissionFactorCategories_Bool_Exp>;
};


export type Subscription_RootDw_EmissionFactorCategories_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_EmissionFactorCategories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_EmissionFactorCategories_Order_By>>;
  where?: Maybe<Dw_EmissionFactorCategories_Bool_Exp>;
};


export type Subscription_RootDw_EmissionFactorCategories_By_PkArgs = {
  CategoryId: Scalars['uuid'];
};


export type Subscription_RootDw_EmissionFactorSourceArgs = {
  distinct_on?: Maybe<Array<Dw_EmissionFactorSource_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_EmissionFactorSource_Order_By>>;
  where?: Maybe<Dw_EmissionFactorSource_Bool_Exp>;
};


export type Subscription_RootDw_EmissionFactorSource_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_EmissionFactorSource_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_EmissionFactorSource_Order_By>>;
  where?: Maybe<Dw_EmissionFactorSource_Bool_Exp>;
};


export type Subscription_RootDw_EmissionFactorSource_By_PkArgs = {
  SourceId: Scalars['uuid'];
};


export type Subscription_RootDw_EmissionFactorsArgs = {
  distinct_on?: Maybe<Array<Dw_EmissionFactors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_EmissionFactors_Order_By>>;
  where?: Maybe<Dw_EmissionFactors_Bool_Exp>;
};


export type Subscription_RootDw_EmissionFactors_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_EmissionFactors_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_EmissionFactors_Order_By>>;
  where?: Maybe<Dw_EmissionFactors_Bool_Exp>;
};


export type Subscription_RootDw_EmissionFactors_By_PkArgs = {
  EmissionFactorId: Scalars['uuid'];
};


export type Subscription_RootDw_FeedsArgs = {
  distinct_on?: Maybe<Array<Dw_Feeds_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Feeds_Order_By>>;
  where?: Maybe<Dw_Feeds_Bool_Exp>;
};


export type Subscription_RootDw_FeedsConfigBacNetArgs = {
  distinct_on?: Maybe<Array<Dw_FeedsConfigBacNet_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_FeedsConfigBacNet_Order_By>>;
  where?: Maybe<Dw_FeedsConfigBacNet_Bool_Exp>;
};


export type Subscription_RootDw_FeedsConfigBacNet_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_FeedsConfigBacNet_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_FeedsConfigBacNet_Order_By>>;
  where?: Maybe<Dw_FeedsConfigBacNet_Bool_Exp>;
};


export type Subscription_RootDw_FeedsConfigBacNet_By_PkArgs = {
  ChannelId: Scalars['uuid'];
  FeedId: Scalars['uuid'];
};


export type Subscription_RootDw_FeedsConfigChannelsArgs = {
  distinct_on?: Maybe<Array<Dw_FeedsConfigChannels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_FeedsConfigChannels_Order_By>>;
  where?: Maybe<Dw_FeedsConfigChannels_Bool_Exp>;
};


export type Subscription_RootDw_FeedsConfigChannels_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_FeedsConfigChannels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_FeedsConfigChannels_Order_By>>;
  where?: Maybe<Dw_FeedsConfigChannels_Bool_Exp>;
};


export type Subscription_RootDw_FeedsConfigChannels_By_PkArgs = {
  ChannelId: Scalars['uuid'];
};


export type Subscription_RootDw_FeedsConfigModbusArgs = {
  distinct_on?: Maybe<Array<Dw_FeedsConfigModbus_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_FeedsConfigModbus_Order_By>>;
  where?: Maybe<Dw_FeedsConfigModbus_Bool_Exp>;
};


export type Subscription_RootDw_FeedsConfigModbus_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_FeedsConfigModbus_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_FeedsConfigModbus_Order_By>>;
  where?: Maybe<Dw_FeedsConfigModbus_Bool_Exp>;
};


export type Subscription_RootDw_FeedsConfigModbus_By_PkArgs = {
  ChannelId: Scalars['uuid'];
  FeedId: Scalars['uuid'];
};


export type Subscription_RootDw_FeedsLogsArgs = {
  distinct_on?: Maybe<Array<Dw_FeedsLogs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_FeedsLogs_Order_By>>;
  where?: Maybe<Dw_FeedsLogs_Bool_Exp>;
};


export type Subscription_RootDw_FeedsLogs_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_FeedsLogs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_FeedsLogs_Order_By>>;
  where?: Maybe<Dw_FeedsLogs_Bool_Exp>;
};


export type Subscription_RootDw_FeedsLogs_By_PkArgs = {
  EventId: Scalars['uuid'];
};


export type Subscription_RootDw_FeedsTypesArgs = {
  distinct_on?: Maybe<Array<Dw_FeedsTypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_FeedsTypes_Order_By>>;
  where?: Maybe<Dw_FeedsTypes_Bool_Exp>;
};


export type Subscription_RootDw_FeedsTypes_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_FeedsTypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_FeedsTypes_Order_By>>;
  where?: Maybe<Dw_FeedsTypes_Bool_Exp>;
};


export type Subscription_RootDw_FeedsTypes_By_PkArgs = {
  TypeId: Scalars['uuid'];
};


export type Subscription_RootDw_Feeds_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Feeds_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Feeds_Order_By>>;
  where?: Maybe<Dw_Feeds_Bool_Exp>;
};


export type Subscription_RootDw_Feeds_By_PkArgs = {
  FeedId: Scalars['uuid'];
};


export type Subscription_RootDw_GroupsArgs = {
  distinct_on?: Maybe<Array<Dw_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Groups_Order_By>>;
  where?: Maybe<Dw_Groups_Bool_Exp>;
};


export type Subscription_RootDw_GroupsSitesArgs = {
  distinct_on?: Maybe<Array<Dw_GroupsSites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_GroupsSites_Order_By>>;
  where?: Maybe<Dw_GroupsSites_Bool_Exp>;
};


export type Subscription_RootDw_GroupsSites_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_GroupsSites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_GroupsSites_Order_By>>;
  where?: Maybe<Dw_GroupsSites_Bool_Exp>;
};


export type Subscription_RootDw_GroupsSites_By_PkArgs = {
  GroupId: Scalars['uuid'];
  SiteId: Scalars['uuid'];
};


export type Subscription_RootDw_Groups_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Groups_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Groups_Order_By>>;
  where?: Maybe<Dw_Groups_Bool_Exp>;
};


export type Subscription_RootDw_Groups_By_PkArgs = {
  GroupId: Scalars['uuid'];
};


export type Subscription_RootDw_HistoryArgs = {
  distinct_on?: Maybe<Array<Dw_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_History_Order_By>>;
  where?: Maybe<Dw_History_Bool_Exp>;
};


export type Subscription_RootDw_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_History_Order_By>>;
  where?: Maybe<Dw_History_Bool_Exp>;
};


export type Subscription_RootDw_History_By_PkArgs = {
  HistoryId: Scalars['uuid'];
  SourceId: Scalars['uuid'];
};


export type Subscription_RootDw_LoadDocumentsArgs = {
  distinct_on?: Maybe<Array<Dw_LoadDocuments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_LoadDocuments_Order_By>>;
  where?: Maybe<Dw_LoadDocuments_Bool_Exp>;
};


export type Subscription_RootDw_LoadDocuments_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_LoadDocuments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_LoadDocuments_Order_By>>;
  where?: Maybe<Dw_LoadDocuments_Bool_Exp>;
};


export type Subscription_RootDw_LoadDocuments_By_PkArgs = {
  DocumentId: Scalars['uuid'];
};


export type Subscription_RootDw_LoadsArgs = {
  distinct_on?: Maybe<Array<Dw_Loads_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Loads_Order_By>>;
  where?: Maybe<Dw_Loads_Bool_Exp>;
};


export type Subscription_RootDw_LoadsServiceArgs = {
  distinct_on?: Maybe<Array<Dw_LoadsService_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_LoadsService_Order_By>>;
  where?: Maybe<Dw_LoadsService_Bool_Exp>;
};


export type Subscription_RootDw_LoadsService_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_LoadsService_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_LoadsService_Order_By>>;
  where?: Maybe<Dw_LoadsService_Bool_Exp>;
};


export type Subscription_RootDw_LoadsService_By_PkArgs = {
  ServiceId: Scalars['uuid'];
};


export type Subscription_RootDw_LoadsTypeArgs = {
  distinct_on?: Maybe<Array<Dw_LoadsType_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_LoadsType_Order_By>>;
  where?: Maybe<Dw_LoadsType_Bool_Exp>;
};


export type Subscription_RootDw_LoadsType_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_LoadsType_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_LoadsType_Order_By>>;
  where?: Maybe<Dw_LoadsType_Bool_Exp>;
};


export type Subscription_RootDw_LoadsType_By_PkArgs = {
  TypeId: Scalars['uuid'];
};


export type Subscription_RootDw_Loads_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Loads_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Loads_Order_By>>;
  where?: Maybe<Dw_Loads_Bool_Exp>;
};


export type Subscription_RootDw_Loads_By_PkArgs = {
  LoadId: Scalars['uuid'];
};


export type Subscription_RootDw_MlModelsMetadataArgs = {
  distinct_on?: Maybe<Array<Dw_MlModelsMetadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlModelsMetadata_Order_By>>;
  where?: Maybe<Dw_MlModelsMetadata_Bool_Exp>;
};


export type Subscription_RootDw_MlModelsMetadata_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_MlModelsMetadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlModelsMetadata_Order_By>>;
  where?: Maybe<Dw_MlModelsMetadata_Bool_Exp>;
};


export type Subscription_RootDw_MlModelsMetadata_By_PkArgs = {
  ModelId: Scalars['uuid'];
};


export type Subscription_RootDw_MlNotificationForecastsArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotificationForecasts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotificationForecasts_Order_By>>;
  where?: Maybe<Dw_MlNotificationForecasts_Bool_Exp>;
};


export type Subscription_RootDw_MlNotificationForecasts_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotificationForecasts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotificationForecasts_Order_By>>;
  where?: Maybe<Dw_MlNotificationForecasts_Bool_Exp>;
};


export type Subscription_RootDw_MlNotificationGroupArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotificationGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotificationGroup_Order_By>>;
  where?: Maybe<Dw_MlNotificationGroup_Bool_Exp>;
};


export type Subscription_RootDw_MlNotificationGroup_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotificationGroup_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotificationGroup_Order_By>>;
  where?: Maybe<Dw_MlNotificationGroup_Bool_Exp>;
};


export type Subscription_RootDw_MlNotificationGroup_By_PkArgs = {
  NotificationGroupId: Scalars['uuid'];
};


export type Subscription_RootDw_MlNotificationsArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotifications_Order_By>>;
  where?: Maybe<Dw_MlNotifications_Bool_Exp>;
};


export type Subscription_RootDw_MlNotificationsCorrelationArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotificationsCorrelation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotificationsCorrelation_Order_By>>;
  where?: Maybe<Dw_MlNotificationsCorrelation_Bool_Exp>;
};


export type Subscription_RootDw_MlNotificationsCorrelation_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotificationsCorrelation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotificationsCorrelation_Order_By>>;
  where?: Maybe<Dw_MlNotificationsCorrelation_Bool_Exp>;
};


export type Subscription_RootDw_MlNotificationsCorrelation_By_PkArgs = {
  NotificationsCorrelationId: Scalars['uuid'];
};


export type Subscription_RootDw_MlNotificationsMetadataArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotificationsMetadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotificationsMetadata_Order_By>>;
  where?: Maybe<Dw_MlNotificationsMetadata_Bool_Exp>;
};


export type Subscription_RootDw_MlNotificationsMetadata_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotificationsMetadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotificationsMetadata_Order_By>>;
  where?: Maybe<Dw_MlNotificationsMetadata_Bool_Exp>;
};


export type Subscription_RootDw_MlNotificationsMetadata_By_PkArgs = {
  NotificationGroupId: Scalars['uuid'];
};


export type Subscription_RootDw_MlNotifications_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_MlNotifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_MlNotifications_Order_By>>;
  where?: Maybe<Dw_MlNotifications_Bool_Exp>;
};


export type Subscription_RootDw_MlNotifications_By_PkArgs = {
  NotificationId: Scalars['uuid'];
};


export type Subscription_RootDw_NotificationsArgs = {
  distinct_on?: Maybe<Array<Dw_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Notifications_Order_By>>;
  where?: Maybe<Dw_Notifications_Bool_Exp>;
};


export type Subscription_RootDw_NotificationsEventArgs = {
  distinct_on?: Maybe<Array<Dw_NotificationsEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_NotificationsEvent_Order_By>>;
  where?: Maybe<Dw_NotificationsEvent_Bool_Exp>;
};


export type Subscription_RootDw_NotificationsEvent_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_NotificationsEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_NotificationsEvent_Order_By>>;
  where?: Maybe<Dw_NotificationsEvent_Bool_Exp>;
};


export type Subscription_RootDw_NotificationsEvent_By_PkArgs = {
  EventId: Scalars['uuid'];
};


export type Subscription_RootDw_NotificationsLogArgs = {
  distinct_on?: Maybe<Array<Dw_NotificationsLog_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_NotificationsLog_Order_By>>;
  where?: Maybe<Dw_NotificationsLog_Bool_Exp>;
};


export type Subscription_RootDw_NotificationsLog_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_NotificationsLog_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_NotificationsLog_Order_By>>;
  where?: Maybe<Dw_NotificationsLog_Bool_Exp>;
};


export type Subscription_RootDw_NotificationsLog_By_PkArgs = {
  LogId: Scalars['uuid'];
};


export type Subscription_RootDw_NotificationsVisibilityArgs = {
  distinct_on?: Maybe<Array<Dw_NotificationsVisibility_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_NotificationsVisibility_Order_By>>;
  where?: Maybe<Dw_NotificationsVisibility_Bool_Exp>;
};


export type Subscription_RootDw_NotificationsVisibility_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_NotificationsVisibility_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_NotificationsVisibility_Order_By>>;
  where?: Maybe<Dw_NotificationsVisibility_Bool_Exp>;
};


export type Subscription_RootDw_NotificationsVisibility_By_PkArgs = {
  ContactId: Scalars['uuid'];
  NotificationId: Scalars['uuid'];
};


export type Subscription_RootDw_Notifications_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Notifications_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Notifications_Order_By>>;
  where?: Maybe<Dw_Notifications_Bool_Exp>;
};


export type Subscription_RootDw_Notifications_By_PkArgs = {
  NotificationId: Scalars['uuid'];
};


export type Subscription_RootDw_PlansArgs = {
  distinct_on?: Maybe<Array<Dw_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Plans_Order_By>>;
  where?: Maybe<Dw_Plans_Bool_Exp>;
};


export type Subscription_RootDw_Plans_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Plans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Plans_Order_By>>;
  where?: Maybe<Dw_Plans_Bool_Exp>;
};


export type Subscription_RootDw_Plans_By_PkArgs = {
  PlanId: Scalars['uuid'];
};


export type Subscription_RootDw_ProjectsArgs = {
  distinct_on?: Maybe<Array<Dw_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Projects_Order_By>>;
  where?: Maybe<Dw_Projects_Bool_Exp>;
};


export type Subscription_RootDw_Projects_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Projects_Order_By>>;
  where?: Maybe<Dw_Projects_Bool_Exp>;
};


export type Subscription_RootDw_Projects_By_PkArgs = {
  ProjectId: Scalars['uuid'];
};


export type Subscription_RootDw_ReportsArgs = {
  distinct_on?: Maybe<Array<Dw_Reports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Reports_Order_By>>;
  where?: Maybe<Dw_Reports_Bool_Exp>;
};


export type Subscription_RootDw_Reports_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Reports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Reports_Order_By>>;
  where?: Maybe<Dw_Reports_Bool_Exp>;
};


export type Subscription_RootDw_Reports_By_PkArgs = {
  ReportId: Scalars['uuid'];
};


export type Subscription_RootDw_SessionsArgs = {
  distinct_on?: Maybe<Array<Dw_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Sessions_Order_By>>;
  where?: Maybe<Dw_Sessions_Bool_Exp>;
};


export type Subscription_RootDw_Sessions_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Sessions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Sessions_Order_By>>;
  where?: Maybe<Dw_Sessions_Bool_Exp>;
};


export type Subscription_RootDw_Sessions_By_PkArgs = {
  SessionId: Scalars['uuid'];
};


export type Subscription_RootDw_SitesArgs = {
  distinct_on?: Maybe<Array<Dw_Sites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Sites_Order_By>>;
  where?: Maybe<Dw_Sites_Bool_Exp>;
};


export type Subscription_RootDw_Sites_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Sites_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Sites_Order_By>>;
  where?: Maybe<Dw_Sites_Bool_Exp>;
};


export type Subscription_RootDw_Sites_By_PkArgs = {
  SiteId: Scalars['uuid'];
};


export type Subscription_RootDw_WeatherArgs = {
  distinct_on?: Maybe<Array<Dw_Weather_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Weather_Order_By>>;
  where?: Maybe<Dw_Weather_Bool_Exp>;
};


export type Subscription_RootDw_WeatherReadingsArgs = {
  distinct_on?: Maybe<Array<Dw_WeatherReadings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_WeatherReadings_Order_By>>;
  where?: Maybe<Dw_WeatherReadings_Bool_Exp>;
};


export type Subscription_RootDw_WeatherReadings_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_WeatherReadings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_WeatherReadings_Order_By>>;
  where?: Maybe<Dw_WeatherReadings_Bool_Exp>;
};


export type Subscription_RootDw_WeatherReadings_By_PkArgs = {
  Event: Scalars['timestamp'];
  WeatherId: Scalars['uuid'];
};


export type Subscription_RootDw_Weather_AggregateArgs = {
  distinct_on?: Maybe<Array<Dw_Weather_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dw_Weather_Order_By>>;
  where?: Maybe<Dw_Weather_Bool_Exp>;
};


export type Subscription_RootDw_Weather_By_PkArgs = {
  WeatherId: Scalars['uuid'];
};


/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

export type AccountFieldsFragment = { __typename?: 'dw_Accounts', AccountId: any, Label: string, BillingEmailAddress?: Maybe<string>, IndustryCode?: Maybe<string>, WebsiteUrl?: Maybe<string>, Status: any, CreatedOn: any, UpdatedOn: any, Reports: Array<{ __typename?: 'dw_Reports', ReportId: any, BaselineMode: any, Type: any, TargetElectricity: any, TargetGas: any, TargetWater: any, StartDate: any, CreatedOn: any, UpdatedOn: any }> };

export type AccountDetailFieldsFragment = (
  { __typename?: 'dw_Accounts', ParentAccountId?: Maybe<any>, NumEmployees: number, Notes?: Maybe<string>, Status: any, FinancialStartMonth: any, BillingEmailAddress?: Maybe<string>, UpdatedOn: any, CreatedOn: any, contactByPrimarycontactid?: Maybe<{ __typename?: 'dw_Contacts', DisplayName: string, ContactId: any }>, AccountsAddresses_aggregate: { __typename?: 'dw_AccountsAddress_aggregate', nodes: Array<{ __typename?: 'dw_AccountsAddress', City?: Maybe<string> }> } }
  & AccountFieldsFragment
);

export type AccountSearchQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  ContactId?: Maybe<Scalars['uuid']>;
}>;


export type AccountSearchQuery = { __typename?: 'query_root', dw_Accounts: Array<{ __typename?: 'dw_Accounts', AccountId: any, Label: string, Reports: Array<{ __typename?: 'dw_Reports', ReportId: any, BaselineMode: any, Type: any }> }> };

export type AccountContactRolesSearchQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  ContactId?: Maybe<Scalars['uuid']>;
}>;


export type AccountContactRolesSearchQuery = { __typename?: 'query_root', dw_Accounts: Array<{ __typename?: 'dw_Accounts', AccountId: any, Label: string, Reports: Array<{ __typename?: 'dw_Reports', ReportId: any, BaselineMode: any, Type: any }> }> };

export type AccountDetailsQueryVariables = Exact<{
  accountId: Scalars['uuid'];
}>;


export type AccountDetailsQuery = { __typename?: 'query_root', dw_Accounts_by_pk?: Maybe<(
    { __typename?: 'dw_Accounts' }
    & AccountDetailFieldsFragment
  )> };

export type AccountsQueryVariables = Exact<{
  accountId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['smallint']>;
  search?: Maybe<Scalars['String']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order_by?: Maybe<Array<Dw_Accounts_Order_By> | Dw_Accounts_Order_By>;
}>;


export type AccountsQuery = { __typename?: 'query_root', dw_Accounts: Array<(
    { __typename?: 'dw_Accounts', Sites_aggregate: { __typename?: 'dw_Sites_aggregate', aggregate?: Maybe<{ __typename?: 'dw_Sites_aggregate_fields', count: number }> } }
    & AccountFieldsFragment
  )>, dw_Accounts_aggregate: { __typename?: 'dw_Accounts_aggregate', aggregate?: Maybe<{ __typename?: 'dw_Accounts_aggregate_fields', totalCount: number }> } };

export type ActiveAccountsQueryVariables = Exact<{
  accountId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['smallint']>;
  search?: Maybe<Scalars['String']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order_by?: Maybe<Array<Dw_Accounts_Order_By> | Dw_Accounts_Order_By>;
}>;


export type ActiveAccountsQuery = { __typename?: 'query_root', dw_Accounts: Array<(
    { __typename?: 'dw_Accounts', Sites_aggregate: { __typename?: 'dw_Sites_aggregate', aggregate?: Maybe<{ __typename?: 'dw_Sites_aggregate_fields', count: number }> } }
    & AccountFieldsFragment
  )>, dw_Accounts_aggregate: { __typename?: 'dw_Accounts_aggregate', aggregate?: Maybe<{ __typename?: 'dw_Accounts_aggregate_fields', totalCount: number }> } };

export type InsertAccountMutationVariables = Exact<{
  object: Dw_Accounts_Insert_Input;
}>;


export type InsertAccountMutation = { __typename?: 'mutation_root', insert_dw_Accounts_one?: Maybe<{ __typename?: 'dw_Accounts', AccountId: any }> };

export type UpdateAccountsMutationVariables = Exact<{
  AccountId: Scalars['uuid'];
  ParentAccountId?: Maybe<Scalars['uuid']>;
  Label: Scalars['String'];
  BillingEmailAddress?: Maybe<Scalars['String']>;
  IndustryCode?: Maybe<Scalars['String']>;
  WebsiteUrl?: Maybe<Scalars['String']>;
  NumEmployees?: Maybe<Scalars['Int']>;
  Notes?: Maybe<Scalars['String']>;
  FinancialStartMonth?: Maybe<Scalars['smallint']>;
  Status: Scalars['smallint'];
  UpdatedOn: Scalars['timestamp'];
}>;


export type UpdateAccountsMutation = { __typename?: 'mutation_root', update_dw_Accounts_by_pk?: Maybe<{ __typename?: 'dw_Accounts', AccountId: any, UpdatedOn: any }> };

export type AccountDataForCsvImportQueryVariables = Exact<{
  accountId: Scalars['uuid'];
}>;


export type AccountDataForCsvImportQuery = { __typename?: 'query_root', dw_Accounts_by_pk?: Maybe<{ __typename?: 'dw_Accounts', Label: string, AccountId: any }>, dw_Sites: Array<{ __typename?: 'dw_Sites', Label: string, SiteId: any, AccountId: any }>, dw_Buildings: Array<{ __typename?: 'dw_Buildings', Label?: Maybe<string>, BuildingId: any, SiteId: any }>, dw_Loads: Array<{ __typename?: 'dw_Loads', Label: string, LoadId: any, IcpNumber?: Maybe<string>, MainIncomer: boolean, BuildingId: any }>, dw_Channels: Array<{ __typename?: 'dw_Channels', Label: string, ChannelId: any, ChannelKey?: Maybe<string>, LoadId: any }>, dw_Feeds: Array<{ __typename?: 'dw_Feeds', Label: string, FeedId: any }> };

export type AccountLoadDataForCsvImportQueryVariables = Exact<{
  accountId: Scalars['uuid'];
}>;


export type AccountLoadDataForCsvImportQuery = { __typename?: 'query_root', dw_Accounts_by_pk?: Maybe<{ __typename?: 'dw_Accounts', Label: string, AccountId: any }>, dw_Loads: Array<{ __typename?: 'dw_Loads', LoadId: any, BuildingId: any, ParentLoadId?: Maybe<any>, Label: string, IcpNumber?: Maybe<string>, Description?: Maybe<string>, NabersRating: any, TransformerCapacity: number, MainIncomer: boolean, UtilityType: any, Status: any, ServiceId?: Maybe<any>, TypeId?: Maybe<any>, FeedId?: Maybe<any> }>, dw_Buildings: Array<{ __typename?: 'dw_Buildings', BuildingId: any, Label?: Maybe<string> }>, dw_LoadsService: Array<{ __typename?: 'dw_LoadsService', ServiceId: any, Label: string }>, dw_LoadsType: Array<{ __typename?: 'dw_LoadsType', TypeId: any, Label: string }>, dw_Feeds: Array<{ __typename?: 'dw_Feeds', Label: string, FeedId: any }> };

export type AccountChannelDataForCsvImportQueryVariables = Exact<{
  accountId: Scalars['uuid'];
}>;


export type AccountChannelDataForCsvImportQuery = { __typename?: 'query_root', dw_Accounts_by_pk?: Maybe<{ __typename?: 'dw_Accounts', Label: string, AccountId: any }>, dw_Channels: Array<{ __typename?: 'dw_Channels', ChannelId: any, Label: string, ChannelKey?: Maybe<string>, Type: any, SummationType: any, ValueTypeEnum?: Maybe<any>, NatureType: any, IntervalFrequency?: Maybe<string>, TimeZone: string, Status: any, EnableAlerts: boolean, FeedId?: Maybe<any>, LoadId: any }>, dw_Loads: Array<{ __typename?: 'dw_Loads', Label: string, LoadId: any }>, dw_Feeds: Array<{ __typename?: 'dw_Feeds', Label: string, FeedId: any }> };

export type UpdateAccountAssociationsMutationVariables = Exact<{
  AccountId: Scalars['uuid'];
  ContactId?: Maybe<Scalars['uuid']>;
  UpdatedOn: Scalars['timestamp'];
}>;


export type UpdateAccountAssociationsMutation = { __typename?: 'mutation_root', update_dw_Accounts_by_pk?: Maybe<{ __typename?: 'dw_Accounts', AccountId: any, UpdatedOn: any }> };

export type AccountAddressFieldsFragment = { __typename?: 'dw_AccountsAddress', AddressId: any, City?: Maybe<string>, Composite?: Maybe<string>, Country?: Maybe<string>, Latitude: any, Line1?: Maybe<string>, Line2?: Maybe<string>, Line3?: Maybe<string>, Longitude: any, Name?: Maybe<string>, Phone1?: Maybe<string>, Phone2?: Maybe<string>, Phone3?: Maybe<string>, Fax?: Maybe<string>, PostalCode?: Maybe<string>, State?: Maybe<string>, Type: any, CreatedOn: any, UpdatedOn: any, Account: { __typename?: 'dw_Accounts', AccountId: any, Label: string } };

export type AccountAddressDetailsQueryVariables = Exact<{
  addressId: Scalars['uuid'];
}>;


export type AccountAddressDetailsQuery = { __typename?: 'query_root', dw_AccountsAddress_by_pk?: Maybe<(
    { __typename?: 'dw_AccountsAddress' }
    & AccountAddressFieldsFragment
  )> };

export type AccountAddressQueryVariables = Exact<{
  addressId?: Maybe<Scalars['uuid']>;
  accountId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['smallint']>;
  search?: Maybe<Scalars['String']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order_by?: Maybe<Array<Dw_AccountsAddress_Order_By> | Dw_AccountsAddress_Order_By>;
}>;


export type AccountAddressQuery = { __typename?: 'query_root', dw_AccountsAddress: Array<(
    { __typename?: 'dw_AccountsAddress' }
    & AccountAddressFieldsFragment
  )>, dw_AccountsAddress_aggregate: { __typename?: 'dw_AccountsAddress_aggregate', aggregate?: Maybe<{ __typename?: 'dw_AccountsAddress_aggregate_fields', totalCount: number }> } };

export type InsertAccountAddressMutationVariables = Exact<{
  object: Dw_AccountsAddress_Insert_Input;
}>;


export type InsertAccountAddressMutation = { __typename?: 'mutation_root', insert_dw_AccountsAddress_one?: Maybe<{ __typename?: 'dw_AccountsAddress', AddressId: any }> };

export type UpdateAccountAddressMutationVariables = Exact<{
  AddressId: Scalars['uuid'];
  Name?: Maybe<Scalars['String']>;
  Line1?: Maybe<Scalars['String']>;
  Line2?: Maybe<Scalars['String']>;
  Line3?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  PostalCode?: Maybe<Scalars['String']>;
  Composite?: Maybe<Scalars['String']>;
  PostOfficeBox?: Maybe<Scalars['String']>;
  ContactName?: Maybe<Scalars['String']>;
  FreightTerms?: Maybe<Scalars['String']>;
  Fax?: Maybe<Scalars['String']>;
  Latitude: Scalars['numeric'];
  Longitude: Scalars['numeric'];
  Phone1?: Maybe<Scalars['String']>;
  Phone2?: Maybe<Scalars['String']>;
  Phone3?: Maybe<Scalars['String']>;
  ShippingMethod?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['smallint']>;
  UpdatedOn: Scalars['timestamp'];
}>;


export type UpdateAccountAddressMutation = { __typename?: 'mutation_root', update_dw_AccountsAddress_by_pk?: Maybe<{ __typename?: 'dw_AccountsAddress', AddressId: any, UpdatedOn: any }> };

export type DeleteAccountAddressMutationVariables = Exact<{
  addressId: Scalars['uuid'];
}>;


export type DeleteAccountAddressMutation = { __typename?: 'mutation_root', delete_dw_AccountsAddress_by_pk?: Maybe<{ __typename?: 'dw_AccountsAddress', AddressId: any }> };

export type AccountsSubscriptionFieldsFragment = { __typename?: 'dw_AccountsSubscription', BalanceAsAt: any, Balance: any, CeasedOn?: Maybe<any>, CommerceOn: any, PaymentCustomerRef?: Maybe<string>, CreatedOn: any, Status: any, SubscriptionId: any, UpdatedOn: any, Account: { __typename?: 'dw_Accounts', AccountId: any, Label: string }, Plan: { __typename?: 'dw_Plans', PlanId: any, Label: string } };

export type AccountsSubscriptionDetailsQueryVariables = Exact<{
  subscriptionId: Scalars['uuid'];
}>;


export type AccountsSubscriptionDetailsQuery = { __typename?: 'query_root', dw_AccountsSubscription_by_pk?: Maybe<(
    { __typename?: 'dw_AccountsSubscription' }
    & AccountsSubscriptionFieldsFragment
  )> };

export type AccountSubscriptionsQueryVariables = Exact<{
  subscriptionId?: Maybe<Scalars['uuid']>;
  accountId?: Maybe<Scalars['uuid']>;
  search?: Maybe<Scalars['String']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order_by?: Maybe<Array<Dw_AccountsSubscription_Order_By> | Dw_AccountsSubscription_Order_By>;
}>;


export type AccountSubscriptionsQuery = { __typename?: 'query_root', dw_AccountsSubscription: Array<(
    { __typename?: 'dw_AccountsSubscription' }
    & AccountsSubscriptionFieldsFragment
  )>, dw_AccountsSubscription_aggregate: { __typename?: 'dw_AccountsSubscription_aggregate', aggregate?: Maybe<{ __typename?: 'dw_AccountsSubscription_aggregate_fields', totalCount: number }> } };

export type InsertAccountSubscriptionMutationVariables = Exact<{
  object: Dw_AccountsSubscription_Insert_Input;
}>;


export type InsertAccountSubscriptionMutation = { __typename?: 'mutation_root', insert_dw_AccountsSubscription_one?: Maybe<{ __typename?: 'dw_AccountsSubscription', SubscriptionId: any }> };

export type UpdateAccountSubscriptionMutationVariables = Exact<{
  SubscriptionId: Scalars['uuid'];
  BalanceAsAt?: Maybe<Scalars['timestamp']>;
  Balance?: Maybe<Scalars['numeric']>;
  CeasedOn?: Maybe<Scalars['timestamp']>;
  CommerceOn?: Maybe<Scalars['timestamp']>;
  PaymentCustomerRef?: Maybe<Scalars['String']>;
  CreatedOn?: Maybe<Scalars['timestamp']>;
  PlanId: Scalars['uuid'];
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn: Scalars['timestamp'];
}>;


export type UpdateAccountSubscriptionMutation = { __typename?: 'mutation_root', update_dw_AccountsSubscription_by_pk?: Maybe<{ __typename?: 'dw_AccountsSubscription', SubscriptionId: any, UpdatedOn: any }> };

export type DeleteAccountSubscriptionMutationVariables = Exact<{
  subscriptionId: Scalars['uuid'];
}>;


export type DeleteAccountSubscriptionMutation = { __typename?: 'mutation_root', delete_dw_AccountsSubscription_by_pk?: Maybe<{ __typename?: 'dw_AccountsSubscription', SubscriptionId: any }> };

export type PlanSearchQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
}>;


export type PlanSearchQuery = { __typename?: 'query_root', dw_Plans: Array<{ __typename?: 'dw_Plans', PlanId: any, Label: string }> };

export type BuildingFieldsFragment = { __typename?: 'dw_Buildings', BuildingId: any, Label?: Maybe<string>, Status: any, CreatedOn: any, UpdatedOn: any, NlaSqm?: Maybe<any>, GfaSqm?: Maybe<any>, FunctionalUse?: Maybe<number>, HeatingSystem?: Maybe<number>, CoolingSystem?: Maybe<number>, Location?: Maybe<string>, WeatherId?: Maybe<any>, IsicCode?: Maybe<string>, RatingType?: Maybe<number>, RatingValue?: Maybe<number>, RatingExpiry?: Maybe<any>, Site: { __typename?: 'dw_Sites', SiteId: any, City?: Maybe<string>, Label: string, Account: { __typename?: 'dw_Accounts', AccountId: any, Label: string, IndustryCode?: Maybe<string> } } };

export type BuildingDetailFieldsFragment = (
  { __typename?: 'dw_Buildings', BuildingDocuments: Array<{ __typename?: 'dw_BuildingDocuments', DocumentId: any, BuildingId: any, FileName: string, UploadDate: any, Status: any }> }
  & BuildingFieldsFragment
);

export type BuildingSearchQueryVariables = Exact<{
  SiteId?: Maybe<Scalars['uuid']>;
  search?: Maybe<Scalars['String']>;
}>;


export type BuildingSearchQuery = { __typename?: 'query_root', dw_Buildings: Array<{ __typename?: 'dw_Buildings', BuildingId: any, Label?: Maybe<string> }> };

export type BuildingCorrelationDataQueryVariables = Exact<{
  buildingId?: Maybe<Scalars['uuid']>;
}>;


export type BuildingCorrelationDataQuery = { __typename?: 'query_root', dw_Loads: Array<{ __typename?: 'dw_Loads', LoadId: any, Label: string, Channels: Array<{ __typename?: 'dw_Channels', ChannelId: any, Label: string, UnitOfMeasure?: Maybe<string> }> }> };

export type BuildingDetailsQueryVariables = Exact<{
  buildingId: Scalars['uuid'];
}>;


export type BuildingDetailsQuery = { __typename?: 'query_root', dw_Buildings_by_pk?: Maybe<(
    { __typename?: 'dw_Buildings', BuildingDocuments: Array<{ __typename?: 'dw_BuildingDocuments', DocumentId: any, FileName: string, Status: any, UploadDate: any }> }
    & BuildingFieldsFragment
  )> };

export type BuildingsQueryVariables = Exact<{
  buildingId?: Maybe<Scalars['uuid']>;
  siteId?: Maybe<Scalars['uuid']>;
  accountId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['smallint']>;
  search?: Maybe<Scalars['String']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order_by?: Maybe<Array<Dw_Buildings_Order_By> | Dw_Buildings_Order_By>;
}>;


export type BuildingsQuery = { __typename?: 'query_root', dw_Buildings: Array<(
    { __typename?: 'dw_Buildings' }
    & BuildingFieldsFragment
  )>, dw_Buildings_aggregate: { __typename?: 'dw_Buildings_aggregate', aggregate?: Maybe<{ __typename?: 'dw_Buildings_aggregate_fields', totalCount: number }> } };

export type InsertBuildingMutationVariables = Exact<{
  object: Dw_Buildings_Insert_Input;
}>;


export type InsertBuildingMutation = { __typename?: 'mutation_root', insert_dw_Buildings_one?: Maybe<{ __typename?: 'dw_Buildings', BuildingId: any }> };

export type UpdateBuildingsMutationVariables = Exact<{
  BuildingId: Scalars['uuid'];
  Label: Scalars['String'];
  Status: Scalars['smallint'];
  NlaSqm?: Maybe<Scalars['numeric']>;
  GfaSqm?: Maybe<Scalars['numeric']>;
  FunctionalUse?: Maybe<Scalars['Int']>;
  HeatingSystem?: Maybe<Scalars['Int']>;
  CoolingSystem?: Maybe<Scalars['Int']>;
  Location?: Maybe<Scalars['String']>;
  WeatherId?: Maybe<Scalars['uuid']>;
  IsicCode?: Maybe<Scalars['String']>;
  RatingType?: Maybe<Scalars['Int']>;
  RatingValue?: Maybe<Scalars['Int']>;
  RatingExpiry?: Maybe<Scalars['timestamp']>;
  UpdatedOn: Scalars['timestamp'];
}>;


export type UpdateBuildingsMutation = { __typename?: 'mutation_root', update_dw_Buildings_by_pk?: Maybe<{ __typename?: 'dw_Buildings', BuildingId: any, UpdatedOn: any }> };

export type UpdateBuildingAssociationsMutationVariables = Exact<{
  BuildingId: Scalars['uuid'];
  SiteId?: Maybe<Scalars['uuid']>;
  UpdatedOn: Scalars['timestamp'];
}>;


export type UpdateBuildingAssociationsMutation = { __typename?: 'mutation_root', update_dw_Buildings_by_pk?: Maybe<{ __typename?: 'dw_Buildings', BuildingId: any, UpdatedOn: any }> };

export type ChannelFieldsFragment = { __typename?: 'dw_Channels', ChannelId: any, ChannelKey?: Maybe<string>, Label: string, MeterType?: Maybe<string>, Type: any, SummationType: any, ValueTypeEnum?: Maybe<any>, Formula?: Maybe<string>, IntervalFrequency?: Maybe<string>, IntervalMins: number, Level: any, Parameter?: Maybe<string>, Reference?: Maybe<string>, TimeZone: string, UnitOfMeasure?: Maybe<string>, EnableAlerts: boolean, NatureType: any, Status: any, StartDate?: Maybe<any>, Feed?: Maybe<{ __typename?: 'dw_Feeds', FeedId: any, Label: string, Notes?: Maybe<string> }>, Load: { __typename?: 'dw_Loads', LoadId: any, Label: string, Building: { __typename?: 'dw_Buildings', BuildingId: any, Label?: Maybe<string>, Site: { __typename?: 'dw_Sites', SiteId: any, Label: string, Account: { __typename?: 'dw_Accounts', AccountId: any, Label: string } } } } };

export type ExportFieldsFragment = { __typename?: 'dw_Channels', ChannelId: any, ChannelKey?: Maybe<string>, Label: string, Type: any, SummationType: any, ValueTypeEnum?: Maybe<any>, Formula?: Maybe<string>, IntervalFrequency?: Maybe<string>, IntervalMins: number, Parameter?: Maybe<string>, TimeZone: string, UnitOfMeasure?: Maybe<string>, EnableAlerts: boolean, NatureType: any, Status: any, StartDate?: Maybe<any>, CreatedOn: any, UpdatedOn: any, Feed?: Maybe<{ __typename?: 'dw_Feeds', FeedId: any, Label: string, Notes?: Maybe<string>, Status: any, FeedsType?: Maybe<{ __typename?: 'dw_FeedsTypes', TypeId: any, Label: string }> }>, Load: { __typename?: 'dw_Loads', LoadId: any, Label: string, IcpNumber?: Maybe<string>, MainIncomer: boolean, TransformerCapacity: number, NegotiatedCapacity: number, MeterSerialNumber?: Maybe<string>, UtilityType: any, Description?: Maybe<string>, ModbusId: number, Status: any, Feed?: Maybe<{ __typename?: 'dw_Feeds', FeedId: any, Label: string, FeedsType?: Maybe<{ __typename?: 'dw_FeedsTypes', TypeId: any, Label: string }> }>, Building: { __typename?: 'dw_Buildings', BuildingId: any, Label?: Maybe<string>, Status: any, Site: { __typename?: 'dw_Sites', SiteId: any, Label: string, City?: Maybe<string>, Status: any, Account: { __typename?: 'dw_Accounts', AccountId: any, Label: string, Status: any } } } }, ChannelsMetadataSingle?: Maybe<{ __typename?: 'dw_ChannelsMetadata', LatestData?: Maybe<any>, LatestDataUploaded?: Maybe<any>, ConsumptionStartDate?: Maybe<any> }> };

export type LoggersOfflineQueryVariables = Exact<{
  LatestData?: Maybe<Scalars['timestamp']>;
}>;


export type LoggersOfflineQuery = { __typename?: 'query_root', dw_ChannelsMetadata: Array<{ __typename?: 'dw_ChannelsMetadata', LatestData?: Maybe<any>, Channel: { __typename?: 'dw_Channels', ChannelId: any, ChannelKey?: Maybe<string>, Label: string, Load: { __typename?: 'dw_Loads', LoadId: any, Label: string, Feed?: Maybe<{ __typename?: 'dw_Feeds', FeedId: any, Label: string, Notes?: Maybe<string>, FeedsType?: Maybe<{ __typename?: 'dw_FeedsTypes', TypeId: any, Label: string }> }> } } }> };

export type ChannelSearchQueryVariables = Exact<{
  AccountId?: Maybe<Scalars['uuid']>;
  SiteId?: Maybe<Scalars['uuid']>;
  BuildingId?: Maybe<Scalars['uuid']>;
  LoadId?: Maybe<Scalars['uuid']>;
  FeedId?: Maybe<Scalars['uuid']>;
  search?: Maybe<Scalars['String']>;
}>;


export type ChannelSearchQuery = { __typename?: 'query_root', dw_Channels: Array<{ __typename?: 'dw_Channels', ChannelId: any, ChannelKey?: Maybe<string>, Label: string, Type: any, Load: { __typename?: 'dw_Loads', Label: string } }> };

export type ChannelKeySearchQueryVariables = Exact<{
  ChannelKey?: Maybe<Scalars['String']>;
}>;


export type ChannelKeySearchQuery = { __typename?: 'query_root', dw_Channels: Array<{ __typename?: 'dw_Channels', ChannelId: any, ChannelKey?: Maybe<string> }> };

export type ChannelDetailsQueryVariables = Exact<{
  channelId: Scalars['uuid'];
}>;


export type ChannelDetailsQuery = { __typename?: 'query_root', dw_Channels_by_pk?: Maybe<(
    { __typename?: 'dw_Channels' }
    & ChannelFieldsFragment
  )> };

export type ChannelsQueryVariables = Exact<{
  channelId?: Maybe<Scalars['uuid']>;
  loadId?: Maybe<Scalars['uuid']>;
  feedId?: Maybe<Scalars['uuid']>;
  feedIds?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  buildingId?: Maybe<Scalars['uuid']>;
  siteId?: Maybe<Scalars['uuid']>;
  accountId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['smallint']>;
  latestDateDateStart?: Maybe<Scalars['timestamp']>;
  latestDateDateEnd?: Maybe<Scalars['timestamp']>;
  search?: Maybe<Scalars['String']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order_by?: Maybe<Array<Dw_Channels_Order_By> | Dw_Channels_Order_By>;
}>;


export type ChannelsQuery = { __typename?: 'query_root', dw_Channels: Array<(
    { __typename?: 'dw_Channels' }
    & ExportFieldsFragment
  )>, dw_Channels_aggregate: { __typename?: 'dw_Channels_aggregate', aggregate?: Maybe<{ __typename?: 'dw_Channels_aggregate_fields', totalCount: number }> } };

export type InsertChannelMutationVariables = Exact<{
  object: Dw_Channels_Insert_Input;
}>;


export type InsertChannelMutation = { __typename?: 'mutation_root', insert_dw_Channels_one?: Maybe<{ __typename?: 'dw_Channels', ChannelId: any }> };

export type UpdateChannelsMutationVariables = Exact<{
  ChannelId: Scalars['uuid'];
  ChannelKey?: Maybe<Scalars['String']>;
  Label: Scalars['String'];
  MeterType?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['smallint']>;
  SummationType?: Maybe<Scalars['smallint']>;
  ValueTypeEnum?: Maybe<Scalars['smallint']>;
  Formula?: Maybe<Scalars['String']>;
  IntervalFrequency?: Maybe<Scalars['String']>;
  IntervalMins?: Maybe<Scalars['Int']>;
  Level?: Maybe<Scalars['String']>;
  Parameter?: Maybe<Scalars['String']>;
  Reference?: Maybe<Scalars['String']>;
  TimeZone?: Maybe<Scalars['String']>;
  UnitOfMeasure?: Maybe<Scalars['String']>;
  EnableAlerts?: Maybe<Scalars['Boolean']>;
  StartDate?: Maybe<Scalars['timestamp']>;
  Status: Scalars['smallint'];
  UpdatedOn: Scalars['timestamp'];
  NatureType: Scalars['smallint'];
}>;


export type UpdateChannelsMutation = { __typename?: 'mutation_root', update_dw_Channels_by_pk?: Maybe<{ __typename?: 'dw_Channels', ChannelId: any }> };

export type UpdateChannelAssociationsMutationVariables = Exact<{
  ChannelId: Scalars['uuid'];
  LoadId?: Maybe<Scalars['uuid']>;
  FeedId?: Maybe<Scalars['uuid']>;
  UpdatedOn: Scalars['timestamp'];
}>;


export type UpdateChannelAssociationsMutation = { __typename?: 'mutation_root', update_dw_Channels_by_pk?: Maybe<{ __typename?: 'dw_Channels', ChannelId: any, UpdatedOn: any }> };

export type BulkChangeChannelsFeedMutationVariables = Exact<{
  FeedId: Scalars['uuid'];
  ChannelIds: Array<Scalars['uuid']> | Scalars['uuid'];
  UpdatedOn: Scalars['timestamp'];
}>;


export type BulkChangeChannelsFeedMutation = { __typename?: 'mutation_root', update_dw_Channels?: Maybe<{ __typename?: 'dw_Channels_mutation_response', affected_rows: number }> };

export type BulkInsertLoadChannelsMutationVariables = Exact<{
  objects: Array<Dw_Channels_Insert_Input> | Dw_Channels_Insert_Input;
}>;


export type BulkInsertLoadChannelsMutation = { __typename?: 'mutation_root', insert_dw_Channels?: Maybe<{ __typename?: 'dw_Channels_mutation_response', affected_rows: number }> };

export type UpdateChannelFormulaMutationVariables = Exact<{
  ChannelId: Scalars['uuid'];
  Formula?: Maybe<Scalars['String']>;
  UpdatedOn: Scalars['timestamp'];
}>;


export type UpdateChannelFormulaMutation = { __typename?: 'mutation_root', update_dw_Channels_by_pk?: Maybe<{ __typename?: 'dw_Channels', ChannelId: any }> };

export type ChannelsByMetadataDateQueryVariables = Exact<{
  channelId?: Maybe<Scalars['uuid']>;
  loadId?: Maybe<Scalars['uuid']>;
  feedId?: Maybe<Scalars['uuid']>;
  feedIds?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  buildingId?: Maybe<Scalars['uuid']>;
  siteId?: Maybe<Scalars['uuid']>;
  accountId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['smallint']>;
  latestDateDateStart?: Maybe<Scalars['timestamp']>;
  latestDateDateEnd?: Maybe<Scalars['timestamp']>;
  search?: Maybe<Scalars['String']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order_by?: Maybe<Array<Dw_Channels_Order_By> | Dw_Channels_Order_By>;
}>;


export type ChannelsByMetadataDateQuery = { __typename?: 'query_root', dw_Channels: Array<(
    { __typename?: 'dw_Channels' }
    & ExportFieldsFragment
  )>, dw_Channels_aggregate: { __typename?: 'dw_Channels_aggregate', aggregate?: Maybe<{ __typename?: 'dw_Channels_aggregate_fields', totalCount: number }> } };

export type UpdateChannelBulkItemMutationVariables = Exact<{
  ChannelId: Scalars['uuid'];
  Label: Scalars['String'];
  LoadId?: Maybe<Scalars['uuid']>;
  FeedId?: Maybe<Scalars['uuid']>;
  ChannelKey?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['smallint']>;
  SummationType?: Maybe<Scalars['smallint']>;
  ValueTypeEnum?: Maybe<Scalars['smallint']>;
  NatureType: Scalars['smallint'];
  IntervalFrequency?: Maybe<Scalars['String']>;
  TimeZone?: Maybe<Scalars['String']>;
  Status: Scalars['smallint'];
  EnableAlerts?: Maybe<Scalars['Boolean']>;
  UpdatedOn: Scalars['timestamp'];
}>;


export type UpdateChannelBulkItemMutation = { __typename?: 'mutation_root', update_dw_Channels_by_pk?: Maybe<{ __typename?: 'dw_Channels', ChannelId: any, UpdatedOn: any }> };

export type ChannelsJobsFieldsFragment = { __typename?: 'dw_ChannelsJobs', JobId: any, ChannelId: any, Formula?: Maybe<string>, From: any, To: any, Logs?: Maybe<any>, Status: any, LastActivity: any, StartedOn: any, Channel: { __typename?: 'dw_Channels', ChannelId: any, ChannelKey?: Maybe<string>, Label: string, Load: { __typename?: 'dw_Loads', LoadId: any, Label: string, Building: { __typename?: 'dw_Buildings', BuildingId: any, Label?: Maybe<string>, Site: { __typename?: 'dw_Sites', SiteId: any, Label: string, Account: { __typename?: 'dw_Accounts', AccountId: any, Label: string } } } } } };

export type ChannelsJobsDetailsQueryVariables = Exact<{
  jobId: Scalars['uuid'];
}>;


export type ChannelsJobsDetailsQuery = { __typename?: 'query_root', dw_ChannelsJobs_by_pk?: Maybe<(
    { __typename?: 'dw_ChannelsJobs' }
    & ChannelsJobsFieldsFragment
  )> };

export type ChannelsJobsQueryVariables = Exact<{
  jobId?: Maybe<Scalars['uuid']>;
  channelId?: Maybe<Scalars['uuid']>;
  loadId?: Maybe<Scalars['uuid']>;
  buildingId?: Maybe<Scalars['uuid']>;
  siteId?: Maybe<Scalars['uuid']>;
  accountId?: Maybe<Scalars['uuid']>;
  search?: Maybe<Scalars['String']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order_by?: Maybe<Array<Dw_ChannelsJobs_Order_By> | Dw_ChannelsJobs_Order_By>;
}>;


export type ChannelsJobsQuery = { __typename?: 'query_root', dw_ChannelsJobs: Array<(
    { __typename?: 'dw_ChannelsJobs' }
    & ChannelsJobsFieldsFragment
  )>, dw_ChannelsJobs_aggregate: { __typename?: 'dw_ChannelsJobs_aggregate', aggregate?: Maybe<{ __typename?: 'dw_ChannelsJobs_aggregate_fields', totalCount: number }> } };

export type ChannelsMappingsFieldsFragment = { __typename?: 'dw_ChannelsMappings', ExternalId: string, Reference: string, Channel: { __typename?: 'dw_Channels', ChannelId: any, ChannelKey?: Maybe<string>, Label: string, Feed?: Maybe<{ __typename?: 'dw_Feeds', FeedId: any, Label: string, SerialNumber: string }> } };

export type ChannelsMappingsQueryVariables = Exact<{
  channelId?: Maybe<Scalars['uuid']>;
  feedId?: Maybe<Scalars['uuid']>;
  externalId?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order_by?: Maybe<Array<Dw_ChannelsMappings_Order_By> | Dw_ChannelsMappings_Order_By>;
}>;


export type ChannelsMappingsQuery = { __typename?: 'query_root', dw_ChannelsMappings: Array<(
    { __typename?: 'dw_ChannelsMappings' }
    & ChannelsMappingsFieldsFragment
  )>, dw_ChannelsMappings_aggregate: { __typename?: 'dw_ChannelsMappings_aggregate', aggregate?: Maybe<{ __typename?: 'dw_ChannelsMappings_aggregate_fields', totalCount: number }> } };

export type InsertChannelMappingsMutationVariables = Exact<{
  object: Dw_ChannelsMappings_Insert_Input;
}>;


export type InsertChannelMappingsMutation = { __typename?: 'mutation_root', insert_dw_ChannelsMappings_one?: Maybe<{ __typename?: 'dw_ChannelsMappings', ChannelId: any }> };

export type UpdateChannelMappingsMutationVariables = Exact<{
  ChannelId: Scalars['uuid'];
  ExternalId?: Maybe<Scalars['String']>;
  Reference?: Maybe<Scalars['String']>;
}>;


export type UpdateChannelMappingsMutation = { __typename?: 'mutation_root', update_dw_ChannelsMappings_by_pk?: Maybe<{ __typename?: 'dw_ChannelsMappings', ChannelId: any }> };

export type DeleteChannelMappingsMutationVariables = Exact<{
  ChannelId: Scalars['uuid'];
}>;


export type DeleteChannelMappingsMutation = { __typename?: 'mutation_root', delete_dw_ChannelsMappings_by_pk?: Maybe<{ __typename?: 'dw_ChannelsMappings', ChannelId: any }> };

export type ChannelsMetadataFieldsFragment = { __typename?: 'dw_ChannelsMetadata', ChannelId: any, BaselineDailyAverage?: Maybe<any>, ConsumptionStartDate?: Maybe<any>, CumulativeToYear?: Maybe<any>, DataStabilised: boolean, LatestData?: Maybe<any>, LatestDataUploaded?: Maybe<any>, NegativeValuesPermissible: boolean, Channel: { __typename?: 'dw_Channels', ChannelId: any, ChannelKey?: Maybe<string>, Label: string, Feed?: Maybe<{ __typename?: 'dw_Feeds', FeedId: any, Label: string, SerialNumber: string }> } };

export type ChannelsMetadataQueryVariables = Exact<{
  channelId?: Maybe<Scalars['uuid']>;
  feedId?: Maybe<Scalars['uuid']>;
  search?: Maybe<Scalars['String']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order_by?: Maybe<Array<Dw_ChannelsMetadata_Order_By> | Dw_ChannelsMetadata_Order_By>;
}>;


export type ChannelsMetadataQuery = { __typename?: 'query_root', dw_ChannelsMetadata: Array<(
    { __typename?: 'dw_ChannelsMetadata' }
    & ChannelsMetadataFieldsFragment
  )>, dw_ChannelsMetadata_aggregate: { __typename?: 'dw_ChannelsMetadata_aggregate', aggregate?: Maybe<{ __typename?: 'dw_ChannelsMetadata_aggregate_fields', totalCount: number }> } };

export type InsertChannelMetadataMutationVariables = Exact<{
  object: Dw_ChannelsMetadata_Insert_Input;
}>;


export type InsertChannelMetadataMutation = { __typename?: 'mutation_root', insert_dw_ChannelsMetadata_one?: Maybe<{ __typename?: 'dw_ChannelsMetadata', ChannelId: any }> };

export type UpdateChannelMetadataMutationVariables = Exact<{
  ChannelId: Scalars['uuid'];
  NegativeValuesPermissible?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateChannelMetadataMutation = { __typename?: 'mutation_root', update_dw_ChannelsMetadata_by_pk?: Maybe<{ __typename?: 'dw_ChannelsMetadata', ChannelId: any }> };

export type BulkInsertChannelsMetadataMutationVariables = Exact<{
  objects: Array<Dw_ChannelsMetadata_Insert_Input> | Dw_ChannelsMetadata_Insert_Input;
}>;


export type BulkInsertChannelsMetadataMutation = { __typename?: 'mutation_root', insert_dw_ChannelsMetadata?: Maybe<{ __typename?: 'dw_ChannelsMetadata_mutation_response', affected_rows: number }> };

export type ChannelsRulesFieldsFragment = { __typename?: 'dw_ChannelsRules', RuleId: any, ChannelId: any, Status: any, CreatedOn: any, Channel: { __typename?: 'dw_Channels', ChannelId: any, ChannelKey?: Maybe<string>, Label: string, UnitOfMeasure?: Maybe<string>, IntervalFrequency?: Maybe<string>, Load: { __typename?: 'dw_Loads', LoadId: any, Label: string, Building: { __typename?: 'dw_Buildings', BuildingId: any, Label?: Maybe<string> } } }, channelByChannelid: { __typename?: 'dw_Channels', ChannelId: any, ChannelKey?: Maybe<string>, Label: string, UnitOfMeasure?: Maybe<string>, Load: { __typename?: 'dw_Loads', LoadId: any, Label: string } } };

export type ChannelsRulesDetailsQueryVariables = Exact<{
  ruleId: Scalars['uuid'];
}>;


export type ChannelsRulesDetailsQuery = { __typename?: 'query_root', dw_ChannelsRules_by_pk?: Maybe<(
    { __typename?: 'dw_ChannelsRules' }
    & ChannelsRulesFieldsFragment
  )> };

export type GetChannelRulesByChildChannelIdQueryVariables = Exact<{
  ChannelId: Scalars['uuid'];
}>;


export type GetChannelRulesByChildChannelIdQuery = { __typename?: 'query_root', dw_ChannelsRules: Array<{ __typename?: 'dw_ChannelsRules', ChannelId: any, SourceChannelId: any, SourceChannelKey: string }> };

export type ChannelsRulesQueryVariables = Exact<{
  ruleId?: Maybe<Scalars['uuid']>;
  channelId?: Maybe<Scalars['uuid']>;
  loadId?: Maybe<Scalars['uuid']>;
  buildingId?: Maybe<Scalars['uuid']>;
  siteId?: Maybe<Scalars['uuid']>;
  accountId?: Maybe<Scalars['uuid']>;
  search?: Maybe<Scalars['String']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type ChannelsRulesQuery = { __typename?: 'query_root', dw_ChannelsRules: Array<(
    { __typename?: 'dw_ChannelsRules' }
    & ChannelsRulesFieldsFragment
  )>, dw_ChannelsRules_aggregate: { __typename?: 'dw_ChannelsRules_aggregate', aggregate?: Maybe<{ __typename?: 'dw_ChannelsRules_aggregate_fields', totalCount: number }> } };

export type InsertChannelRuleMutationVariables = Exact<{
  object: Dw_ChannelsRules_Insert_Input;
}>;


export type InsertChannelRuleMutation = { __typename?: 'mutation_root', insert_dw_ChannelsRules_one?: Maybe<{ __typename?: 'dw_ChannelsRules', RuleId: any }> };

export type UpdateChannelRuleMutationVariables = Exact<{
  RuleId: Scalars['uuid'];
  Status: Scalars['smallint'];
  UpdatedOn: Scalars['timestamp'];
}>;


export type UpdateChannelRuleMutation = { __typename?: 'mutation_root', update_dw_ChannelsRules_by_pk?: Maybe<{ __typename?: 'dw_ChannelsRules', RuleId: any }> };

export type DeleteChannelRulesMutationVariables = Exact<{
  RuleId: Scalars['uuid'];
}>;


export type DeleteChannelRulesMutation = { __typename?: 'mutation_root', delete_dw_ChannelsRules_by_pk?: Maybe<{ __typename?: 'dw_ChannelsRules', RuleId: any }> };

export type ConsumptionJobsFieldsFragment = { __typename?: 'dw_ConsumptionJobs', JobId: any, File: string, Logs: any, Size: any, Type: any, Status: any, LastActivity: any };

export type ConsumptionJobsDetailsQueryVariables = Exact<{
  jobId: Scalars['uuid'];
}>;


export type ConsumptionJobsDetailsQuery = { __typename?: 'query_root', dw_ConsumptionJobs_by_pk?: Maybe<(
    { __typename?: 'dw_ConsumptionJobs' }
    & ConsumptionJobsFieldsFragment
  )> };

export type ConsumptionJobsQueryVariables = Exact<{
  jobId?: Maybe<Scalars['uuid']>;
  search?: Maybe<Scalars['String']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order_by?: Maybe<Array<Dw_ConsumptionJobs_Order_By> | Dw_ConsumptionJobs_Order_By>;
}>;


export type ConsumptionJobsQuery = { __typename?: 'query_root', dw_ConsumptionJobs: Array<(
    { __typename?: 'dw_ConsumptionJobs' }
    & ConsumptionJobsFieldsFragment
  )>, dw_ConsumptionJobs_aggregate: { __typename?: 'dw_ConsumptionJobs_aggregate', aggregate?: Maybe<{ __typename?: 'dw_ConsumptionJobs_aggregate_fields', totalCount: number }> } };

export type ContactFieldsFragment = { __typename?: 'dw_Contacts', ContactId: any, DisplayName: string, FirstName: string, LastName: string, EmailAddress: string, Status: any, Type: any, CreatedOn?: Maybe<any>, UpdatedOn?: Maybe<any>, Sessions: Array<{ __typename?: 'dw_Sessions', LastActivity: any }> };

export type ContactSearchQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  AccountId?: Maybe<Scalars['uuid']>;
}>;


export type ContactSearchQuery = { __typename?: 'query_root', dw_Contacts: Array<(
    { __typename?: 'dw_Contacts' }
    & ContactFieldsFragment
  )> };

export type ContactSearchNoLimitQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  AccountId?: Maybe<Scalars['uuid']>;
}>;


export type ContactSearchNoLimitQuery = { __typename?: 'query_root', dw_Contacts: Array<(
    { __typename?: 'dw_Contacts' }
    & ContactFieldsFragment
  )> };

export type ContactAccountRolesSearchQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  AccountId?: Maybe<Scalars['uuid']>;
}>;


export type ContactAccountRolesSearchQuery = { __typename?: 'query_root', dw_Contacts: Array<(
    { __typename?: 'dw_Contacts' }
    & ContactFieldsFragment
  )> };

export type ContactDetailsQueryVariables = Exact<{
  contactId: Scalars['uuid'];
}>;


export type ContactDetailsQuery = { __typename?: 'query_root', dw_Contacts_by_pk?: Maybe<(
    { __typename?: 'dw_Contacts' }
    & ContactFieldsFragment
  )> };

export type ContactsQueryVariables = Exact<{
  contactId?: Maybe<Scalars['uuid']>;
  search?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['smallint']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order_by?: Maybe<Array<Dw_Contacts_Order_By> | Dw_Contacts_Order_By>;
}>;


export type ContactsQuery = { __typename?: 'query_root', dw_Contacts: Array<(
    { __typename?: 'dw_Contacts' }
    & ContactFieldsFragment
  )>, dw_Contacts_aggregate: { __typename?: 'dw_Contacts_aggregate', aggregate?: Maybe<{ __typename?: 'dw_Contacts_aggregate_fields', totalCount: number }> } };

export type InsertContactMutationVariables = Exact<{
  ContactId: Scalars['uuid'];
  DisplayName: Scalars['String'];
  FirstName: Scalars['String'];
  LastName: Scalars['String'];
  EmailAddress: Scalars['String'];
  Type: Scalars['smallint'];
  Status: Scalars['smallint'];
  CreatedOn: Scalars['timestamp'];
  UpdatedOn: Scalars['timestamp'];
}>;


export type InsertContactMutation = { __typename?: 'mutation_root', insert_dw_Contacts_one?: Maybe<{ __typename?: 'dw_Contacts', ContactId: any }> };

export type UpdateContactsMutationVariables = Exact<{
  ContactId: Scalars['uuid'];
  DisplayName: Scalars['String'];
  FirstName: Scalars['String'];
  LastName: Scalars['String'];
  EmailAddress: Scalars['String'];
  Type: Scalars['smallint'];
  Status: Scalars['smallint'];
  UpdatedOn: Scalars['timestamp'];
}>;


export type UpdateContactsMutation = { __typename?: 'mutation_root', update_dw_Contacts_by_pk?: Maybe<{ __typename?: 'dw_Contacts', ContactId: any, UpdatedOn?: Maybe<any> }> };

export type ContactsRoleFieldsFragment = { __typename?: 'dw_ContactsRole', EmailAddress: string, Role: any, Status: any, Account: { __typename?: 'dw_Accounts', AccountId: any, Label: string, Status: any }, Contact: { __typename?: 'dw_Contacts', ContactId: any, DisplayName: string, EmailAddress: string, Status: any, Type: any } };

export type ContactsRoleQueryVariables = Exact<{
  contactId?: Maybe<Scalars['uuid']>;
  accountId?: Maybe<Scalars['uuid']>;
  search?: Maybe<Scalars['String']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order_by?: Maybe<Array<Dw_ContactsRole_Order_By> | Dw_ContactsRole_Order_By>;
}>;


export type ContactsRoleQuery = { __typename?: 'query_root', dw_ContactsRole: Array<(
    { __typename?: 'dw_ContactsRole' }
    & ContactsRoleFieldsFragment
  )>, dw_ContactsRole_aggregate: { __typename?: 'dw_ContactsRole_aggregate', aggregate?: Maybe<{ __typename?: 'dw_ContactsRole_aggregate_fields', totalCount: number }> } };

export type InsertContactRolesMutationVariables = Exact<{
  objects: Array<Dw_ContactsRole_Insert_Input> | Dw_ContactsRole_Insert_Input;
}>;


export type InsertContactRolesMutation = { __typename?: 'mutation_root', insert_dw_ContactsRole?: Maybe<{ __typename?: 'dw_ContactsRole_mutation_response', returning: Array<{ __typename?: 'dw_ContactsRole', ContactId: any, AccountId: any }> }> };

export type DeleteContactRolesMutationVariables = Exact<{
  AccountId: Scalars['uuid'];
  ContactId: Scalars['uuid'];
}>;


export type DeleteContactRolesMutation = { __typename?: 'mutation_root', delete_dw_ContactsRole?: Maybe<{ __typename?: 'dw_ContactsRole_mutation_response', affected_rows: number }> };

export type DashboardDetailFieldsFragment = { __typename?: 'dw_Dashboards', DashboardId: any, Label: string, Code: string, WeatherColor: any, BackgroundColor: any, ElectricityColor: any, GasColor: any, WaterColor: any, HasBackground: boolean, ShowWeather: boolean, Status: any, Account: { __typename?: 'dw_Accounts', AccountId: any, Label: string }, Building?: Maybe<{ __typename?: 'dw_Buildings', BuildingId: any, Label?: Maybe<string>, Site: { __typename?: 'dw_Sites', SiteId: any, Label: string } }> };

export type DashboardFieldsFragment = { __typename?: 'dw_Dashboards', DashboardId: any, Label: string, Code: string, WeatherColor: any, BackgroundColor: any, ElectricityColor: any, GasColor: any, WaterColor: any, HasBackground: boolean, ShowWeather: boolean, Status: any, Account: { __typename?: 'dw_Accounts', AccountId: any, Label: string }, Building?: Maybe<{ __typename?: 'dw_Buildings', BuildingId: any, Label?: Maybe<string>, Site: { __typename?: 'dw_Sites', SiteId: any, Label: string } }> };

export type DashboardsDetailsQueryVariables = Exact<{
  dashboardId: Scalars['uuid'];
}>;


export type DashboardsDetailsQuery = { __typename?: 'query_root', dw_Dashboards_by_pk?: Maybe<(
    { __typename?: 'dw_Dashboards' }
    & DashboardDetailFieldsFragment
  )> };

export type DashboardsQueryVariables = Exact<{
  dashboardId?: Maybe<Scalars['uuid']>;
  accountId?: Maybe<Scalars['uuid']>;
  search?: Maybe<Scalars['String']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order_by?: Maybe<Array<Dw_Dashboards_Order_By> | Dw_Dashboards_Order_By>;
}>;


export type DashboardsQuery = { __typename?: 'query_root', dw_Dashboards: Array<(
    { __typename?: 'dw_Dashboards' }
    & DashboardFieldsFragment
  )>, dw_Dashboards_aggregate: { __typename?: 'dw_Dashboards_aggregate', aggregate?: Maybe<{ __typename?: 'dw_Dashboards_aggregate_fields', totalCount: number }> } };

export type InsertDashboardMutationVariables = Exact<{
  object: Dw_Dashboards_Insert_Input;
}>;


export type InsertDashboardMutation = { __typename?: 'mutation_root', insert_dw_Dashboards_one?: Maybe<{ __typename?: 'dw_Dashboards', DashboardId: any }> };

export type UpdateDashboardsMutationVariables = Exact<{
  DashboardId: Scalars['uuid'];
  Label?: Maybe<Scalars['String']>;
  Code: Scalars['String'];
  WeatherColor: Scalars['_int4'];
  BackgroundColor: Scalars['_int4'];
  ElectricityColor: Scalars['_int4'];
  GasColor: Scalars['_int4'];
  WaterColor: Scalars['_int4'];
  HasBackground: Scalars['Boolean'];
  ShowWeather: Scalars['Boolean'];
  Status: Scalars['smallint'];
  UpdatedOn: Scalars['timestamp'];
}>;


export type UpdateDashboardsMutation = { __typename?: 'mutation_root', update_dw_Dashboards_by_pk?: Maybe<{ __typename?: 'dw_Dashboards', DashboardId: any, UpdatedOn: any }> };

export type UpdateDashboardsAssociationsMutationVariables = Exact<{
  DashboardId: Scalars['uuid'];
  BuildingId?: Maybe<Scalars['uuid']>;
  AccountId?: Maybe<Scalars['uuid']>;
  UpdatedOn: Scalars['timestamp'];
}>;


export type UpdateDashboardsAssociationsMutation = { __typename?: 'mutation_root', update_dw_Dashboards_by_pk?: Maybe<{ __typename?: 'dw_Dashboards', DashboardId: any, UpdatedOn: any }> };

export type FeedFieldsFragment = { __typename?: 'dw_Feeds', FeedId: any, Label: string, SerialNumber: string, BatteryDailyUsage?: Maybe<any>, BatteryPercentage?: Maybe<any>, Notes?: Maybe<string>, UserName?: Maybe<string>, Password: string, SimCard: string, Temperature?: Maybe<any>, Status: any, CreatedOn: any, UpdatedOn: any, ActivatedOn?: Maybe<any>, LastSync?: Maybe<any>, Url?: Maybe<string>, ProductionMode: boolean, IntervalEmitFrequency: any, FeedsType?: Maybe<{ __typename?: 'dw_FeedsTypes', TypeId: any, Label: string }>, Building?: Maybe<{ __typename?: 'dw_Buildings', BuildingId: any, Label?: Maybe<string>, Site: { __typename?: 'dw_Sites', SiteId: any, Label: string, Account: { __typename?: 'dw_Accounts', AccountId: any, Label: string } } }> };

export type FeedSearchAllQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
}>;


export type FeedSearchAllQuery = { __typename?: 'query_root', dw_Feeds: Array<{ __typename?: 'dw_Feeds', FeedId: any, Label: string, Notes?: Maybe<string>, SerialNumber: string, SimCard: string, Building?: Maybe<{ __typename?: 'dw_Buildings', BuildingId: any, Label?: Maybe<string> }>, FeedsType?: Maybe<{ __typename?: 'dw_FeedsTypes', TypeId: any, Label: string }> }> };

export type FeedSearchQueryVariables = Exact<{
  AccountId?: Maybe<Scalars['uuid']>;
  BuildingId?: Maybe<Scalars['uuid']>;
  search?: Maybe<Scalars['String']>;
}>;


export type FeedSearchQuery = { __typename?: 'query_root', dw_Feeds: Array<{ __typename?: 'dw_Feeds', FeedId: any, Label: string, Notes?: Maybe<string>, SerialNumber: string, SimCard: string, Building?: Maybe<{ __typename?: 'dw_Buildings', BuildingId: any, Label?: Maybe<string> }>, FeedsType?: Maybe<{ __typename?: 'dw_FeedsTypes', TypeId: any, Label: string }> }> };

export type FeedsTypeSearchQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
}>;


export type FeedsTypeSearchQuery = { __typename?: 'query_root', dw_FeedsTypes: Array<{ __typename?: 'dw_FeedsTypes', TypeId: any, Label: string }> };

export type FeedDetailsQueryVariables = Exact<{
  feedId: Scalars['uuid'];
}>;


export type FeedDetailsQuery = { __typename?: 'query_root', dw_Feeds_by_pk?: Maybe<(
    { __typename?: 'dw_Feeds' }
    & FeedFieldsFragment
  )> };

export type AllFeedsQueryVariables = Exact<{
  feedId?: Maybe<Scalars['uuid']>;
  feedIds?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  status?: Maybe<Scalars['smallint']>;
  search?: Maybe<Scalars['String']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order_by?: Maybe<Array<Dw_Feeds_Order_By> | Dw_Feeds_Order_By>;
}>;


export type AllFeedsQuery = { __typename?: 'query_root', dw_Feeds: Array<(
    { __typename?: 'dw_Feeds' }
    & FeedFieldsFragment
  )>, dw_Feeds_aggregate: { __typename?: 'dw_Feeds_aggregate', aggregate?: Maybe<{ __typename?: 'dw_Feeds_aggregate_fields', totalCount: number }> } };

export type FeedsQueryVariables = Exact<{
  feedId?: Maybe<Scalars['uuid']>;
  feedIds?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  buildingId?: Maybe<Scalars['uuid']>;
  siteId?: Maybe<Scalars['uuid']>;
  accountId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['smallint']>;
  search?: Maybe<Scalars['String']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order_by?: Maybe<Array<Dw_Feeds_Order_By> | Dw_Feeds_Order_By>;
}>;


export type FeedsQuery = { __typename?: 'query_root', dw_Feeds: Array<(
    { __typename?: 'dw_Feeds' }
    & FeedFieldsFragment
  )>, dw_Feeds_aggregate: { __typename?: 'dw_Feeds_aggregate', aggregate?: Maybe<{ __typename?: 'dw_Feeds_aggregate_fields', totalCount: number }> } };

export type InsertFeedMutationVariables = Exact<{
  object: Dw_Feeds_Insert_Input;
}>;


export type InsertFeedMutation = { __typename?: 'mutation_root', insert_dw_Feeds_one?: Maybe<{ __typename?: 'dw_Feeds', FeedId: any }> };

export type UpdateFeedsMutationVariables = Exact<{
  FeedId: Scalars['uuid'];
  Label: Scalars['String'];
  SerialNumber?: Maybe<Scalars['String']>;
  SimCard?: Maybe<Scalars['String']>;
  BatteryDailyUsage?: Maybe<Scalars['numeric']>;
  BatteryPercentage?: Maybe<Scalars['numeric']>;
  Temperature?: Maybe<Scalars['numeric']>;
  UserName?: Maybe<Scalars['String']>;
  Password?: Maybe<Scalars['String']>;
  Notes?: Maybe<Scalars['String']>;
  Url: Scalars['String'];
  ProductionMode: Scalars['Boolean'];
  IntervalEmitFrequency: Scalars['smallint'];
  Status: Scalars['smallint'];
  LastSync: Scalars['timestamp'];
  ActivatedOn: Scalars['timestamp'];
  UpdatedOn: Scalars['timestamp'];
}>;


export type UpdateFeedsMutation = { __typename?: 'mutation_root', update_dw_Feeds_by_pk?: Maybe<{ __typename?: 'dw_Feeds', FeedId: any }> };

export type UpdateFeedAssociationsMutationVariables = Exact<{
  FeedId: Scalars['uuid'];
  BuildingId?: Maybe<Scalars['uuid']>;
  TypeId?: Maybe<Scalars['uuid']>;
  UpdatedOn: Scalars['timestamp'];
}>;


export type UpdateFeedAssociationsMutation = { __typename?: 'mutation_root', update_dw_Feeds_by_pk?: Maybe<{ __typename?: 'dw_Feeds', FeedId: any, UpdatedOn: any }> };

export type FeedsConfigBacNetFieldsFragment = { __typename?: 'dw_FeedsConfigBacNet', ChannelId: any, FeedId: any, ObjectId?: Maybe<string>, DeviceId?: Maybe<string>, CalculateDelta?: Maybe<boolean>, ObjectInstance?: Maybe<number>, ReadFrequencySeconds?: Maybe<number>, Status: any, CreatedOn?: Maybe<any>, UpdatedOn?: Maybe<any>, Channel: { __typename?: 'dw_Channels', ChannelId: any, ChannelKey?: Maybe<string>, Label: string, Load: { __typename?: 'dw_Loads', LoadId: any, Label: string, Building: { __typename?: 'dw_Buildings', BuildingId: any, Label?: Maybe<string>, Site: { __typename?: 'dw_Sites', SiteId: any, Label: string, Account: { __typename?: 'dw_Accounts', AccountId: any, Label: string } } } } }, Feed: { __typename?: 'dw_Feeds', FeedId: any, Label: string, Notes?: Maybe<string>, SerialNumber: string, SimCard: string } };

export type FeedsConfigBacNetDetailsQueryVariables = Exact<{
  channelId: Scalars['uuid'];
  feedId: Scalars['uuid'];
}>;


export type FeedsConfigBacNetDetailsQuery = { __typename?: 'query_root', dw_FeedsConfigBacNet_by_pk?: Maybe<(
    { __typename?: 'dw_FeedsConfigBacNet' }
    & FeedsConfigBacNetFieldsFragment
  )> };

export type FeedsConfigBacNetsQueryVariables = Exact<{
  channelId?: Maybe<Scalars['uuid']>;
  loadId?: Maybe<Scalars['uuid']>;
  feedId?: Maybe<Scalars['uuid']>;
  buildingId?: Maybe<Scalars['uuid']>;
  siteId?: Maybe<Scalars['uuid']>;
  accountId?: Maybe<Scalars['uuid']>;
  serialNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['smallint']>;
  search?: Maybe<Scalars['String']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order_by?: Maybe<Array<Dw_FeedsConfigBacNet_Order_By> | Dw_FeedsConfigBacNet_Order_By>;
}>;


export type FeedsConfigBacNetsQuery = { __typename?: 'query_root', dw_FeedsConfigBacNet: Array<(
    { __typename?: 'dw_FeedsConfigBacNet' }
    & FeedsConfigBacNetFieldsFragment
  )>, dw_FeedsConfigBacNet_aggregate: { __typename?: 'dw_FeedsConfigBacNet_aggregate', aggregate?: Maybe<{ __typename?: 'dw_FeedsConfigBacNet_aggregate_fields', totalCount: number }> } };

export type InsertFeedConfigBacNetMutationVariables = Exact<{
  object: Dw_FeedsConfigBacNet_Insert_Input;
}>;


export type InsertFeedConfigBacNetMutation = { __typename?: 'mutation_root', insert_dw_FeedsConfigBacNet_one?: Maybe<{ __typename?: 'dw_FeedsConfigBacNet', ChannelId: any, FeedId: any }> };

export type UpdateFeedConfigBacNetMutationVariables = Exact<{
  ChannelId: Scalars['uuid'];
  FeedId: Scalars['uuid'];
  ObjectId?: Maybe<Scalars['String']>;
  DeviceId?: Maybe<Scalars['String']>;
  CalculateDelta?: Maybe<Scalars['Boolean']>;
  ObjectInstance?: Maybe<Scalars['Int']>;
  ReadFrequencySeconds?: Maybe<Scalars['Int']>;
  Status?: Maybe<Scalars['smallint']>;
  CreatedOn: Scalars['timestamp'];
  UpdatedOn: Scalars['timestamp'];
}>;


export type UpdateFeedConfigBacNetMutation = { __typename?: 'mutation_root', update_dw_FeedsConfigBacNet_by_pk?: Maybe<{ __typename?: 'dw_FeedsConfigBacNet', ChannelId: any }> };

export type DeleteFeedConfigBacNetMutationVariables = Exact<{
  ChannelId: Scalars['uuid'];
  FeedId: Scalars['uuid'];
}>;


export type DeleteFeedConfigBacNetMutation = { __typename?: 'mutation_root', delete_dw_FeedsConfigBacNet_by_pk?: Maybe<{ __typename?: 'dw_FeedsConfigBacNet', ChannelId: any }> };

export type FeedsConfigModbusDetailFieldsFragment = { __typename?: 'dw_FeedsConfigModbus', ChannelId: any, CalculateDelta: boolean, DeviceAddress: any, IsBigEndian: boolean, ReadFrequencySeconds: number, RegisterAddress: number, RegisterCount: number, RegisterType: any, RtuBaudRate?: Maybe<number>, RtuDataBits?: Maybe<number>, RtuParity?: Maybe<string>, RtuSerialPort?: Maybe<string>, RtuStopBits?: Maybe<string>, ScalingFactor: any, Status: any, TcpIpAddress?: Maybe<string>, TcpPort?: Maybe<number>, CreatedOn: any, UpdatedOn: any, Feed: { __typename?: 'dw_Feeds', FeedId: any, Label: string, SerialNumber: string, SimCard: string }, Channel: { __typename?: 'dw_Channels', ChannelId: any, Label: string, Load: { __typename?: 'dw_Loads', LoadId: any, Label: string, Building: { __typename?: 'dw_Buildings', BuildingId: any, Label?: Maybe<string>, Site: { __typename?: 'dw_Sites', SiteId: any, Label: string, Account: { __typename?: 'dw_Accounts', AccountId: any, Label: string } } } } } };

export type FeedsConfigModbusFieldsFragment = { __typename?: 'dw_FeedsConfigModbus', ChannelId: any, ReadFrequencySeconds: number, RegisterAddress: number, RegisterCount: number, RegisterType: any, DeviceAddress: any, Status: any, CreatedOn: any, UpdatedOn: any, Feed: { __typename?: 'dw_Feeds', FeedId: any, Label: string, SerialNumber: string, SimCard: string }, Channel: { __typename?: 'dw_Channels', ChannelId: any, Label: string, Load: { __typename?: 'dw_Loads', LoadId: any, Label: string, Building: { __typename?: 'dw_Buildings', BuildingId: any, Label?: Maybe<string>, Site: { __typename?: 'dw_Sites', SiteId: any, Label: string, Account: { __typename?: 'dw_Accounts', AccountId: any, Label: string } } } } } };

export type FeedsConfigModbusQueryVariables = Exact<{
  channelId: Scalars['uuid'];
  feedId: Scalars['uuid'];
}>;


export type FeedsConfigModbusQuery = { __typename?: 'query_root', dw_FeedsConfigModbus_by_pk?: Maybe<(
    { __typename?: 'dw_FeedsConfigModbus' }
    & FeedsConfigModbusDetailFieldsFragment
  )> };

export type FeedsConfigModbusesQueryVariables = Exact<{
  channelId?: Maybe<Scalars['uuid']>;
  loadId?: Maybe<Scalars['uuid']>;
  feedId?: Maybe<Scalars['uuid']>;
  buildingId?: Maybe<Scalars['uuid']>;
  siteId?: Maybe<Scalars['uuid']>;
  accountId?: Maybe<Scalars['uuid']>;
  serialNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['smallint']>;
  search?: Maybe<Scalars['String']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order_by?: Maybe<Array<Dw_FeedsConfigModbus_Order_By> | Dw_FeedsConfigModbus_Order_By>;
}>;


export type FeedsConfigModbusesQuery = { __typename?: 'query_root', dw_FeedsConfigModbus: Array<(
    { __typename?: 'dw_FeedsConfigModbus' }
    & FeedsConfigModbusFieldsFragment
  )>, dw_FeedsConfigModbus_aggregate: { __typename?: 'dw_FeedsConfigModbus_aggregate', aggregate?: Maybe<{ __typename?: 'dw_FeedsConfigModbus_aggregate_fields', totalCount: number }> } };

export type InsertFeedConfigModbusMutationVariables = Exact<{
  object: Dw_FeedsConfigModbus_Insert_Input;
}>;


export type InsertFeedConfigModbusMutation = { __typename?: 'mutation_root', insert_dw_FeedsConfigModbus_one?: Maybe<{ __typename?: 'dw_FeedsConfigModbus', ChannelId: any, FeedId: any }> };

export type UpdateFeedConfigModbusMutationVariables = Exact<{
  ChannelId: Scalars['uuid'];
  FeedId: Scalars['uuid'];
  DeviceAddress?: Maybe<Scalars['numeric']>;
  ReadFrequencySeconds?: Maybe<Scalars['Int']>;
  RegisterAddress?: Maybe<Scalars['Int']>;
  RegisterCount?: Maybe<Scalars['Int']>;
  RegisterType?: Maybe<Scalars['smallint']>;
  RtuBaudRate?: Maybe<Scalars['Int']>;
  RtuDataBits?: Maybe<Scalars['Int']>;
  RtuParity?: Maybe<Scalars['String']>;
  RtuSerialPort?: Maybe<Scalars['String']>;
  RtuStopBits?: Maybe<Scalars['String']>;
  ScalingFactor?: Maybe<Scalars['numeric']>;
  TcpIpAddress?: Maybe<Scalars['String']>;
  TcpPort?: Maybe<Scalars['Int']>;
  CalculateDelta?: Maybe<Scalars['Boolean']>;
  IsBigEndian?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Scalars['smallint']>;
  UpdatedOn: Scalars['timestamp'];
}>;


export type UpdateFeedConfigModbusMutation = { __typename?: 'mutation_root', update_dw_FeedsConfigModbus_by_pk?: Maybe<{ __typename?: 'dw_FeedsConfigModbus', ChannelId: any }> };

export type BulkInsertFeedConfigModbusesMutationVariables = Exact<{
  objects: Array<Dw_FeedsConfigModbus_Insert_Input> | Dw_FeedsConfigModbus_Insert_Input;
}>;


export type BulkInsertFeedConfigModbusesMutation = { __typename?: 'mutation_root', insert_dw_FeedsConfigModbus?: Maybe<{ __typename?: 'dw_FeedsConfigModbus_mutation_response', affected_rows: number }> };

export type DeleteFeedConfigModbusMutationVariables = Exact<{
  ChannelId: Scalars['uuid'];
  FeedId: Scalars['uuid'];
}>;


export type DeleteFeedConfigModbusMutation = { __typename?: 'mutation_root', delete_dw_FeedsConfigModbus_by_pk?: Maybe<{ __typename?: 'dw_FeedsConfigModbus', ChannelId: any }> };

export type FeedsLogsFieldsFragment = { __typename?: 'dw_FeedsLogs', EventId: any, Event: any, Log: string, Severity: any, Feed: { __typename?: 'dw_Feeds', FeedId: any, Label: string, SerialNumber: string, SimCard: string } };

export type FeedsLogsQueryVariables = Exact<{
  eventId?: Maybe<Scalars['uuid']>;
  feedId?: Maybe<Scalars['uuid']>;
  severity?: Maybe<Scalars['smallint']>;
  search?: Maybe<Scalars['String']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order_by?: Maybe<Array<Dw_FeedsLogs_Order_By> | Dw_FeedsLogs_Order_By>;
}>;


export type FeedsLogsQuery = { __typename?: 'query_root', dw_FeedsLogs: Array<(
    { __typename?: 'dw_FeedsLogs' }
    & FeedsLogsFieldsFragment
  )>, dw_FeedsLogs_aggregate: { __typename?: 'dw_FeedsLogs_aggregate', aggregate?: Maybe<{ __typename?: 'dw_FeedsLogs_aggregate_fields', totalCount: number }> } };

export type GroupFieldsFragment = { __typename?: 'dw_Groups', GroupId: any, Label: string, Status: any, CreatedOn: any, UpdatedOn: any, Account: { __typename?: 'dw_Accounts', AccountId: any, Label: string } };

export type GroupSearchQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  AccountId?: Maybe<Scalars['uuid']>;
}>;


export type GroupSearchQuery = { __typename?: 'query_root', dw_Groups: Array<{ __typename?: 'dw_Groups', GroupId: any, Label: string }> };

export type GroupDetailsQueryVariables = Exact<{
  groupId: Scalars['uuid'];
}>;


export type GroupDetailsQuery = { __typename?: 'query_root', dw_Groups_by_pk?: Maybe<(
    { __typename?: 'dw_Groups' }
    & GroupFieldsFragment
  )> };

export type GroupsQueryVariables = Exact<{
  groupId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['smallint']>;
  accountId?: Maybe<Scalars['uuid']>;
  search?: Maybe<Scalars['String']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order_by?: Maybe<Array<Dw_Groups_Order_By> | Dw_Groups_Order_By>;
}>;


export type GroupsQuery = { __typename?: 'query_root', dw_Groups: Array<(
    { __typename?: 'dw_Groups', GroupsSites_aggregate: { __typename?: 'dw_GroupsSites_aggregate', aggregate?: Maybe<{ __typename?: 'dw_GroupsSites_aggregate_fields', count: number }> } }
    & GroupFieldsFragment
  )>, dw_Groups_aggregate: { __typename?: 'dw_Groups_aggregate', aggregate?: Maybe<{ __typename?: 'dw_Groups_aggregate_fields', totalCount: number }> } };

export type InsertGroupMutationVariables = Exact<{
  object: Dw_Groups_Insert_Input;
}>;


export type InsertGroupMutation = { __typename?: 'mutation_root', insert_dw_Groups_one?: Maybe<{ __typename?: 'dw_Groups', GroupId: any }> };

export type UpdateGroupsMutationVariables = Exact<{
  GroupId: Scalars['uuid'];
  Label: Scalars['String'];
  Status: Scalars['smallint'];
  UpdatedOn: Scalars['timestamp'];
}>;


export type UpdateGroupsMutation = { __typename?: 'mutation_root', update_dw_Groups_by_pk?: Maybe<{ __typename?: 'dw_Groups', GroupId: any, UpdatedOn: any }> };

export type GroupsSitesFieldsFragment = { __typename?: 'dw_GroupsSites', GroupId: any, SiteId: any, Status: any, CreatedOn: any, UpdatedOn: any, Group: { __typename?: 'dw_Groups', GroupId: any, Label: string }, Site: { __typename?: 'dw_Sites', SiteId: any, Label: string } };

export type GroupsSitesQueryVariables = Exact<{
  groupId?: Maybe<Scalars['uuid']>;
  siteId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['smallint']>;
  search?: Maybe<Scalars['String']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order_by?: Maybe<Array<Dw_GroupsSites_Order_By> | Dw_GroupsSites_Order_By>;
}>;


export type GroupsSitesQuery = { __typename?: 'query_root', dw_GroupsSites: Array<(
    { __typename?: 'dw_GroupsSites' }
    & GroupsSitesFieldsFragment
  )>, dw_GroupsSites_aggregate: { __typename?: 'dw_GroupsSites_aggregate', aggregate?: Maybe<{ __typename?: 'dw_GroupsSites_aggregate_fields', totalCount: number }> } };

export type InsertGroupsSiteMutationVariables = Exact<{
  object: Dw_GroupsSites_Insert_Input;
}>;


export type InsertGroupsSiteMutation = { __typename?: 'mutation_root', insert_dw_GroupsSites_one?: Maybe<{ __typename?: 'dw_GroupsSites', GroupId: any, SiteId: any }> };

export type UpdateGroupsSitesMutationVariables = Exact<{
  GroupId: Scalars['uuid'];
  SiteId: Scalars['uuid'];
  Status: Scalars['smallint'];
  UpdatedOn: Scalars['timestamp'];
}>;


export type UpdateGroupsSitesMutation = { __typename?: 'mutation_root', update_dw_GroupsSites_by_pk?: Maybe<{ __typename?: 'dw_GroupsSites', GroupId: any, SiteId: any, UpdatedOn: any }> };

export type HistoryFieldsFragment = { __typename?: 'dw_History', HistoryId: any, Description: string, SourceId: any, SourceType: any, OccuredOn: any, Contact: { __typename?: 'dw_Contacts', ContactId: any, DisplayName: string } };

export type HistoryQueryVariables = Exact<{
  historyId?: Maybe<Scalars['uuid']>;
  contactId?: Maybe<Scalars['uuid']>;
  search?: Maybe<Scalars['String']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order_by?: Maybe<Array<Dw_History_Order_By> | Dw_History_Order_By>;
}>;


export type HistoryQuery = { __typename?: 'query_root', dw_History: Array<(
    { __typename?: 'dw_History' }
    & HistoryFieldsFragment
  )>, dw_History_aggregate: { __typename?: 'dw_History_aggregate', aggregate?: Maybe<{ __typename?: 'dw_History_aggregate_fields', totalCount: number }> } };

export type InsertHistoryMutationVariables = Exact<{
  object: Dw_History_Insert_Input;
}>;


export type InsertHistoryMutation = { __typename?: 'mutation_root', insert_dw_History_one?: Maybe<{ __typename?: 'dw_History', HistoryId: any }> };

export type LoadDetailFieldsFragment = (
  { __typename?: 'dw_Loads', Channels: Array<{ __typename?: 'dw_Channels', ChannelId: any, FeedId?: Maybe<any> }>, LoadDocuments: Array<{ __typename?: 'dw_LoadDocuments', DocumentId: any, LoadId: any, FileName: string, UploadDate: any, Status: any }> }
  & LoadFieldsFragment
);

export type LoadFieldsFragment = { __typename?: 'dw_Loads', LoadId: any, Label: string, TenantLabel?: Maybe<string>, ParentLoadId?: Maybe<any>, CommunicationType?: Maybe<string>, CtRating: number, CtSupplied?: Maybe<string>, CtType?: Maybe<string>, Description?: Maybe<string>, BreakerRating?: Maybe<string>, IcpNumber?: Maybe<string>, MeterName?: Maybe<string>, MeterType?: Maybe<string>, MeterSerialNumber?: Maybe<string>, ModbusId: number, NabersRating: any, NegotiatedCapacity: number, TransformerCapacity: number, MainIncomer: boolean, UtilityType: any, Status: any, ReferenceValue?: Maybe<string>, CreatedOn: any, UpdatedOn: any, ParentLoad?: Maybe<{ __typename?: 'dw_Loads', LoadId: any, Label: string }>, LoadsService?: Maybe<{ __typename?: 'dw_LoadsService', ServiceId: any, Label: string }>, LoadsType?: Maybe<{ __typename?: 'dw_LoadsType', TypeId: any, Label: string }>, Feed?: Maybe<{ __typename?: 'dw_Feeds', FeedId: any, Label: string, Notes?: Maybe<string>, SerialNumber: string }>, Building: { __typename?: 'dw_Buildings', BuildingId: any, Label?: Maybe<string>, Site: { __typename?: 'dw_Sites', SiteId: any, Label: string, Account: { __typename?: 'dw_Accounts', AccountId: any, Label: string } } } };

export type LoadSearchQueryVariables = Exact<{
  BuildingId?: Maybe<Scalars['uuid']>;
  LoadId?: Maybe<Scalars['uuid']>;
  search?: Maybe<Scalars['String']>;
}>;


export type LoadSearchQuery = { __typename?: 'query_root', dw_Loads: Array<{ __typename?: 'dw_Loads', LoadId: any, Label: string }> };

export type LoadsWithChannelsSearchQueryVariables = Exact<{
  LoadIds: Array<Scalars['uuid']> | Scalars['uuid'];
}>;


export type LoadsWithChannelsSearchQuery = { __typename?: 'query_root', dw_Loads: Array<{ __typename?: 'dw_Loads', LoadId: any, Label: string, Channels: Array<{ __typename?: 'dw_Channels', ChannelId: any, Label: string, ChannelKey?: Maybe<string>, Parameter?: Maybe<string>, MeterType?: Maybe<string>, Reference?: Maybe<string>, IntervalFrequency?: Maybe<string> }> }> };

export type LoadsServiceSearchQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
}>;


export type LoadsServiceSearchQuery = { __typename?: 'query_root', dw_LoadsService: Array<{ __typename?: 'dw_LoadsService', ServiceId: any, Label: string }> };

export type LoadsTypeSearchQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
}>;


export type LoadsTypeSearchQuery = { __typename?: 'query_root', dw_LoadsType: Array<{ __typename?: 'dw_LoadsType', TypeId: any, Label: string }> };

export type LoadDetailsQueryVariables = Exact<{
  loadId: Scalars['uuid'];
}>;


export type LoadDetailsQuery = { __typename?: 'query_root', dw_Loads_by_pk?: Maybe<(
    { __typename?: 'dw_Loads' }
    & LoadDetailFieldsFragment
  )> };

export type LoadsQueryVariables = Exact<{
  loadId?: Maybe<Scalars['uuid']>;
  feedId?: Maybe<Scalars['uuid']>;
  feedIds?: Maybe<Array<Scalars['uuid']> | Scalars['uuid']>;
  buildingId?: Maybe<Scalars['uuid']>;
  siteId?: Maybe<Scalars['uuid']>;
  accountId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['smallint']>;
  utilityType?: Maybe<Scalars['smallint']>;
  search?: Maybe<Scalars['String']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order_by?: Maybe<Array<Dw_Loads_Order_By> | Dw_Loads_Order_By>;
}>;


export type LoadsQuery = { __typename?: 'query_root', dw_Loads: Array<(
    { __typename?: 'dw_Loads' }
    & LoadFieldsFragment
  )>, dw_Loads_aggregate: { __typename?: 'dw_Loads_aggregate', aggregate?: Maybe<{ __typename?: 'dw_Loads_aggregate_fields', totalCount: number }> } };

export type InsertLoadMutationVariables = Exact<{
  object: Dw_Loads_Insert_Input;
}>;


export type InsertLoadMutation = { __typename?: 'mutation_root', insert_dw_Loads_one?: Maybe<{ __typename?: 'dw_Loads', LoadId: any }> };

export type UpdateLoadsMutationVariables = Exact<{
  LoadId: Scalars['uuid'];
  Label: Scalars['String'];
  TenantLabel: Scalars['String'];
  IcpNumber?: Maybe<Scalars['String']>;
  MeterName?: Maybe<Scalars['String']>;
  MeterType?: Maybe<Scalars['String']>;
  MeterSerialNumber?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  BreakerRating?: Maybe<Scalars['String']>;
  ParentLoadId?: Maybe<Scalars['uuid']>;
  CommunicationType?: Maybe<Scalars['String']>;
  CtRating?: Maybe<Scalars['Int']>;
  CtSupplied?: Maybe<Scalars['String']>;
  CtType?: Maybe<Scalars['String']>;
  ModbusId?: Maybe<Scalars['Int']>;
  NabersRating?: Maybe<Scalars['smallint']>;
  NegotiatedCapacity?: Maybe<Scalars['Int']>;
  MainIncomer: Scalars['Boolean'];
  UtilityType: Scalars['smallint'];
  TransformerCapacity?: Maybe<Scalars['Int']>;
  ReferenceValue?: Maybe<Scalars['String']>;
  Status: Scalars['smallint'];
  UpdatedOn: Scalars['timestamp'];
}>;


export type UpdateLoadsMutation = { __typename?: 'mutation_root', update_dw_Loads_by_pk?: Maybe<{ __typename?: 'dw_Loads', LoadId: any, UpdatedOn: any }> };

export type UpdateLoadBulkItemMutationVariables = Exact<{
  LoadId: Scalars['uuid'];
  BuildingId: Scalars['uuid'];
  FeedId?: Maybe<Scalars['uuid']>;
  Label: Scalars['String'];
  IcpNumber?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ParentLoadId?: Maybe<Scalars['uuid']>;
  NabersRating?: Maybe<Scalars['smallint']>;
  MainIncomer: Scalars['Boolean'];
  UtilityType: Scalars['smallint'];
  TransformerCapacity?: Maybe<Scalars['Int']>;
  Status: Scalars['smallint'];
  ServiceId?: Maybe<Scalars['uuid']>;
  TypeId?: Maybe<Scalars['uuid']>;
  UpdatedOn: Scalars['timestamp'];
}>;


export type UpdateLoadBulkItemMutation = { __typename?: 'mutation_root', update_dw_Loads_by_pk?: Maybe<{ __typename?: 'dw_Loads', LoadId: any, UpdatedOn: any }> };

export type UpdateLoadAssociationsMutationVariables = Exact<{
  LoadId: Scalars['uuid'];
  BuildingId?: Maybe<Scalars['uuid']>;
  FeedId?: Maybe<Scalars['uuid']>;
  TypeId?: Maybe<Scalars['uuid']>;
  ServiceId?: Maybe<Scalars['uuid']>;
  UpdatedOn: Scalars['timestamp'];
}>;


export type UpdateLoadAssociationsMutation = { __typename?: 'mutation_root', update_dw_Loads_by_pk?: Maybe<{ __typename?: 'dw_Loads', LoadId: any, UpdatedOn: any }> };

export type BulkChangeLoadsFeedMutationVariables = Exact<{
  FeedId: Scalars['uuid'];
  LoadIds: Array<Scalars['uuid']> | Scalars['uuid'];
  UpdatedOn: Scalars['timestamp'];
}>;


export type BulkChangeLoadsFeedMutation = { __typename?: 'mutation_root', update_dw_Loads?: Maybe<{ __typename?: 'dw_Loads_mutation_response', affected_rows: number }> };

export type MlNotificationGroupsFieldsFragment = { __typename?: 'dw_MLNotificationGroup', NotificationGroupId: any, AlertType: string, OnShift: boolean, Priority: number, QualificationStatus: any, RootCause?: Maybe<string>, Summary: string, StartDate: any, EndDate: any, CreatedOn: any, UpdatedOn: any, Contact?: Maybe<{ __typename?: 'dw_Contacts', ContactId: any, DisplayName: string }>, Building: { __typename?: 'dw_Buildings', BuildingId: any, Label?: Maybe<string>, Site: { __typename?: 'dw_Sites', SiteId: any, Label: string, Account: { __typename?: 'dw_Accounts', AccountId: any, Label: string } } }, MLNotifications: Array<{ __typename?: 'dw_MLNotifications', EquivalentC02: any, EquivalentCost: any, Channel: { __typename?: 'dw_Channels', Load: { __typename?: 'dw_Loads', MainIncomer: boolean } } }>, MLNotifications_aggregate: { __typename?: 'dw_MLNotifications_aggregate', aggregate?: Maybe<{ __typename?: 'dw_MLNotifications_aggregate_fields', sum?: Maybe<{ __typename?: 'dw_MLNotifications_sum_fields', EquivalentC02?: Maybe<any>, EquivalentCost?: Maybe<any> }> }> } };

export type MlNotificationGroupsDetailFieldsFragment = { __typename?: 'dw_MLNotificationGroup', NotificationGroupId: any, AlertType: string, OnShift: boolean, Priority: number, QualificationStatus: any, RootCause?: Maybe<string>, Summary: string, StartDate: any, EndDate: any, CreatedOn: any, UpdatedOn: any, Contact?: Maybe<{ __typename?: 'dw_Contacts', ContactId: any, DisplayName: string }>, Building: { __typename?: 'dw_Buildings', BuildingId: any, Label?: Maybe<string>, Site: { __typename?: 'dw_Sites', SiteId: any, Label: string, Account: { __typename?: 'dw_Accounts', AccountId: any, Label: string } }, Loads: Array<{ __typename?: 'dw_Loads', Channels: Array<{ __typename?: 'dw_Channels', IntervalFrequency?: Maybe<string> }> }> } };

export type MlNotificationsGroupsComparisonFieldsFragment = { __typename?: 'dw_MLNotificationGroup_aggregate', aggregate?: Maybe<{ __typename?: 'dw_MLNotificationGroup_aggregate_fields', count: number }> };

export type MlNotificationGroupDetailsQueryVariables = Exact<{
  notificationGroupId: Scalars['uuid'];
}>;


export type MlNotificationGroupDetailsQuery = { __typename?: 'query_root', dw_MLNotificationGroup_by_pk?: Maybe<(
    { __typename?: 'dw_MLNotificationGroup', MLNotificationsMetadatum: { __typename?: 'dw_MLNotificationsMetadata', Action: any, DueDate: any, Pinned: boolean, Comments: any, UpdatedOn: any, contactByAssigneecontactid?: Maybe<{ __typename?: 'dw_Contacts', ContactId: any, DisplayName: string }>, Contact?: Maybe<{ __typename?: 'dw_Contacts', ContactId: any, DisplayName: string }> } }
    & MlNotificationGroupsDetailFieldsFragment
  )> };

export type MlNotificationGroupsQueryVariables = Exact<{
  notificationGroupId?: Maybe<Scalars['uuid']>;
  buildingId?: Maybe<Scalars['uuid']>;
  siteId?: Maybe<Scalars['uuid']>;
  accountId?: Maybe<Scalars['uuid']>;
  contactId?: Maybe<Scalars['uuid']>;
  search?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  qualificationStatus?: Maybe<Scalars['smallint']>;
  timestamp?: Maybe<Scalars['timestamp']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order_by?: Maybe<Array<Dw_MlNotificationGroup_Order_By> | Dw_MlNotificationGroup_Order_By>;
}>;


export type MlNotificationGroupsQuery = { __typename?: 'query_root', dw_MLNotificationGroup: Array<(
    { __typename?: 'dw_MLNotificationGroup' }
    & MlNotificationGroupsFieldsFragment
  )>, dw_MLNotificationGroup_aggregate: { __typename?: 'dw_MLNotificationGroup_aggregate', aggregate?: Maybe<{ __typename?: 'dw_MLNotificationGroup_aggregate_fields', totalCount: number }> } };

export type MlNotificationGroupsAnalyticsQueryVariables = Exact<{
  buildingId?: Maybe<Scalars['uuid']>;
  siteId?: Maybe<Scalars['uuid']>;
  accountId?: Maybe<Scalars['uuid']>;
  priority?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['timestamp']>;
}>;


export type MlNotificationGroupsAnalyticsQuery = { __typename?: 'query_root', TOTAL: (
    { __typename?: 'dw_MLNotificationGroup_aggregate' }
    & MlNotificationsGroupsComparisonFieldsFragment
  ), TO_BE_REVIEWED: (
    { __typename?: 'dw_MLNotificationGroup_aggregate' }
    & MlNotificationsGroupsComparisonFieldsFragment
  ), TO_INVESTIGATE_LATER_BY_ESP: (
    { __typename?: 'dw_MLNotificationGroup_aggregate' }
    & MlNotificationsGroupsComparisonFieldsFragment
  ), DISQUALIFIED_BY_ESP: (
    { __typename?: 'dw_MLNotificationGroup_aggregate', nodes: Array<{ __typename?: 'dw_MLNotificationGroup', CreatedOn: any, UpdatedOn: any }> }
    & MlNotificationsGroupsComparisonFieldsFragment
  ), QUALIFIED_NOT_ESCALATED: (
    { __typename?: 'dw_MLNotificationGroup_aggregate' }
    & MlNotificationsGroupsComparisonFieldsFragment
  ), QUALIFIED_BY_ESP: (
    { __typename?: 'dw_MLNotificationGroup_aggregate', nodes: Array<{ __typename?: 'dw_MLNotificationGroup', CreatedOn: any, UpdatedOn: any }> }
    & MlNotificationsGroupsComparisonFieldsFragment
  ), UNDER_INVESTIGATION_BY_CUSTOMER: (
    { __typename?: 'dw_MLNotificationGroup_aggregate' }
    & MlNotificationsGroupsComparisonFieldsFragment
  ), PENDING_ACTION: (
    { __typename?: 'dw_MLNotificationGroup_aggregate' }
    & MlNotificationsGroupsComparisonFieldsFragment
  ), QUALIFIED_BY_CUSTOMER: (
    { __typename?: 'dw_MLNotificationGroup_aggregate', nodes: Array<{ __typename?: 'dw_MLNotificationGroup', CreatedOn: any, UpdatedOn: any }> }
    & MlNotificationsGroupsComparisonFieldsFragment
  ), DISQUALIFIED_BY_CUSTOMER: (
    { __typename?: 'dw_MLNotificationGroup_aggregate', nodes: Array<{ __typename?: 'dw_MLNotificationGroup', CreatedOn: any, UpdatedOn: any }> }
    & MlNotificationsGroupsComparisonFieldsFragment
  ) };

export type NotificationGroupsMissingAnalyticsQueryVariables = Exact<{
  buildingId?: Maybe<Scalars['uuid']>;
  siteId?: Maybe<Scalars['uuid']>;
  accountId?: Maybe<Scalars['uuid']>;
}>;


export type NotificationGroupsMissingAnalyticsQuery = { __typename?: 'query_root', dw_Buildings: Array<{ __typename?: 'dw_Buildings', BuildingId: any, Label?: Maybe<string>, Site: { __typename?: 'dw_Sites', SiteId: any, Label: string, Account: { __typename?: 'dw_Accounts', AccountId: any, Label: string } }, MLNotificationGroups: Array<{ __typename?: 'dw_MLNotificationGroup', StartDate: any }> }> };

export type UpdateMlNotificationGroupDetailsMutationVariables = Exact<{
  notificationGroupId: Scalars['uuid'];
  priority: Scalars['Int'];
  qualificationStatus: Scalars['smallint'];
  rootCause?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  contactId: Scalars['uuid'];
  updatedOn: Scalars['timestamp'];
}>;


export type UpdateMlNotificationGroupDetailsMutation = { __typename?: 'mutation_root', update_dw_MLNotificationGroup_by_pk?: Maybe<{ __typename?: 'dw_MLNotificationGroup', NotificationGroupId: any, UpdatedOn: any }> };

export type UpdateMlNotificationsMetadataMutationVariables = Exact<{
  notificationGroupId: Scalars['uuid'];
  qualificationStatus: Scalars['smallint'];
  updatedOn: Scalars['timestamp'];
  comments: Scalars['json'];
}>;


export type UpdateMlNotificationsMetadataMutation = { __typename?: 'mutation_root', update_dw_MLNotificationGroup_by_pk?: Maybe<{ __typename?: 'dw_MLNotificationGroup', NotificationGroupId: any, QualificationStatus: any, UpdatedOn: any, ContactId?: Maybe<any> }>, update_dw_MLNotificationsMetadata?: Maybe<{ __typename?: 'dw_MLNotificationsMetadata_mutation_response', returning: Array<{ __typename?: 'dw_MLNotificationsMetadata', Comments: any }> }> };

export type BulkDisqualifyNotificationGroupsMutationVariables = Exact<{
  notificationGroupIds: Array<Scalars['uuid']> | Scalars['uuid'];
  qualificationStatus?: Maybe<Scalars['smallint']>;
  rootCause?: Maybe<Scalars['String']>;
  updatedOn: Scalars['timestamp'];
}>;


export type BulkDisqualifyNotificationGroupsMutation = { __typename?: 'mutation_root', update_dw_MLNotificationGroup?: Maybe<{ __typename?: 'dw_MLNotificationGroup_mutation_response', affected_rows: number }> };

export type NotificationsFieldsFragment = { __typename?: 'dw_Notifications', NotificationId: any, WindowStart: any, WindowEnd: any, Status: any, CreatedOn: any, UpdatedOn: any, Load: { __typename?: 'dw_Loads', LoadId: any, Label: string, UtilityType: any }, Building: { __typename?: 'dw_Buildings', BuildingId: any, Label?: Maybe<string>, Site: { __typename?: 'dw_Sites', SiteId: any, Label: string } }, Account: { __typename?: 'dw_Accounts', AccountId: any, Label: string } };

export type NotificationDetailFieldsFragment = { __typename?: 'dw_Notifications', NotificationId: any, WindowStart: any, WindowEnd: any, Label: string, Assessment?: Maybe<string>, RootCause: any, Status: any, DueDate?: Maybe<any>, CreatedOn: any, UpdatedOn: any, Contact?: Maybe<{ __typename?: 'dw_Contacts', ContactId: any, DisplayName: string, EmailAddress: string }>, contactByReviewer?: Maybe<{ __typename?: 'dw_Contacts', ContactId: any, DisplayName: string, EmailAddress: string }>, Channel: { __typename?: 'dw_Channels', ChannelId: any, Label: string, IntervalFrequency?: Maybe<string>, UnitOfMeasure?: Maybe<string>, NatureType: any }, Load: { __typename?: 'dw_Loads', LoadId: any, Label: string, UtilityType: any }, Building: { __typename?: 'dw_Buildings', BuildingId: any, Label?: Maybe<string>, Site: { __typename?: 'dw_Sites', SiteId: any, Label: string } }, Account: { __typename?: 'dw_Accounts', AccountId: any, Label: string }, NotificationsEvents: Array<{ __typename?: 'dw_NotificationsEvent', EventId: any, Event: any, Consumption: any, Score: any, PValue: any, Status: any }>, NotificationsLogs: Array<{ __typename?: 'dw_NotificationsLog', LogId: any, Type: any, Event: any, Message: string, Contact?: Maybe<{ __typename?: 'dw_Contacts', ContactId: any, DisplayName: string }> }> };

export type NotificationsDetailsQueryVariables = Exact<{
  notificationId: Scalars['uuid'];
}>;


export type NotificationsDetailsQuery = { __typename?: 'query_root', dw_Notifications_by_pk?: Maybe<(
    { __typename?: 'dw_Notifications' }
    & NotificationDetailFieldsFragment
  )> };

export type NotificationsQueryVariables = Exact<{
  notificationId?: Maybe<Scalars['uuid']>;
  accountId?: Maybe<Scalars['uuid']>;
  siteId?: Maybe<Scalars['uuid']>;
  buildingId?: Maybe<Scalars['uuid']>;
  loadId?: Maybe<Scalars['uuid']>;
  channelId?: Maybe<Scalars['uuid']>;
  search?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['smallint']>;
  timestamp?: Maybe<Scalars['timestamp']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order_by?: Maybe<Array<Dw_Notifications_Order_By> | Dw_Notifications_Order_By>;
}>;


export type NotificationsQuery = { __typename?: 'query_root', dw_Notifications: Array<(
    { __typename?: 'dw_Notifications', NotificationsEvents_aggregate: { __typename?: 'dw_NotificationsEvent_aggregate', aggregate?: Maybe<{ __typename?: 'dw_NotificationsEvent_aggregate_fields', count: number }> } }
    & NotificationsFieldsFragment
  )> };

export type UpdateNotificationMutationVariables = Exact<{
  notificationId: Scalars['uuid'];
  status: Scalars['smallint'];
  rootCause?: Maybe<Scalars['smallint']>;
  usage?: Maybe<Scalars['numeric']>;
  emissions?: Maybe<Scalars['numeric']>;
  cost?: Maybe<Scalars['numeric']>;
  assessment?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['timestamp']>;
  assignee?: Maybe<Scalars['uuid']>;
  reviewer?: Maybe<Scalars['uuid']>;
  updatedOn: Scalars['timestamp'];
}>;


export type UpdateNotificationMutation = { __typename?: 'mutation_root', update_dw_Notifications_by_pk?: Maybe<{ __typename?: 'dw_Notifications', NotificationId: any, UpdatedOn: any }> };

export type UpdateNotificationsEventMutationVariables = Exact<{
  eventId: Scalars['uuid'];
  status: Scalars['smallint'];
}>;


export type UpdateNotificationsEventMutation = { __typename?: 'mutation_root', update_dw_NotificationsEvent_by_pk?: Maybe<{ __typename?: 'dw_NotificationsEvent', EventId: any, Status: any }> };

export type InsertNotificationsLogMutationVariables = Exact<{
  object: Dw_NotificationsLog_Insert_Input;
}>;


export type InsertNotificationsLogMutation = { __typename?: 'mutation_root', insert_dw_NotificationsLog_one?: Maybe<{ __typename?: 'dw_NotificationsLog', LogId: any }> };

export type ProjectsFieldsFragment = { __typename?: 'dw_Projects', ProjectId: any, Label: string, Description: string, Comments: string, Cost: any, UnitCost: any, CumulativeKwhSavings: any, AnnualKwhSavings: any, AnnualSavings: any, Status: any, Priority?: Maybe<any>, NotifiedOn: any, ImplementationDate?: Maybe<any>, UpdatedOn: any, CreatedOn: any, Contact?: Maybe<{ __typename?: 'dw_Contacts', ContactId: any, DisplayName: string }>, contactByAssigneecontactid?: Maybe<{ __typename?: 'dw_Contacts', ContactId: any, DisplayName: string }>, Load?: Maybe<{ __typename?: 'dw_Loads', LoadId: any, Label: string }>, Building?: Maybe<{ __typename?: 'dw_Buildings', BuildingId: any, Label?: Maybe<string> }>, Site?: Maybe<{ __typename?: 'dw_Sites', SiteId: any, Label: string }>, Account: { __typename?: 'dw_Accounts', AccountId: any, Label: string } };

export type ProjectDetailFieldsFragment = { __typename?: 'dw_Projects', ProjectId: any, Label: string, Description: string, Comments: string, Cost: any, UnitCost: any, CumulativeKwhSavings: any, AnnualKwhSavings: any, AnnualSavings: any, Status: any, Priority?: Maybe<any>, NotifiedOn: any, ImplementationDate?: Maybe<any>, UpdatedOn: any, CreatedOn: any, AccountId: any, SiteId?: Maybe<any>, BuildingId?: Maybe<any>, LoadId?: Maybe<any>, Contact?: Maybe<{ __typename?: 'dw_Contacts', ContactId: any, DisplayName: string }>, contactByAssigneecontactid?: Maybe<{ __typename?: 'dw_Contacts', ContactId: any, DisplayName: string }>, Load?: Maybe<{ __typename?: 'dw_Loads', LoadId: any, Label: string, Building: { __typename?: 'dw_Buildings', BuildingId: any, Label?: Maybe<string>, Site: { __typename?: 'dw_Sites', SiteId: any, Label: string, Account: { __typename?: 'dw_Accounts', AccountId: any, Label: string } } } }>, Building?: Maybe<{ __typename?: 'dw_Buildings', BuildingId: any, Label?: Maybe<string>, Site: { __typename?: 'dw_Sites', SiteId: any, Label: string, Account: { __typename?: 'dw_Accounts', AccountId: any, Label: string } } }>, Site?: Maybe<{ __typename?: 'dw_Sites', SiteId: any, Label: string, Account: { __typename?: 'dw_Accounts', AccountId: any, Label: string } }>, Account: { __typename?: 'dw_Accounts', AccountId: any, Label: string } };

export type ProjectDetailsQueryVariables = Exact<{
  projectId: Scalars['uuid'];
}>;


export type ProjectDetailsQuery = { __typename?: 'query_root', dw_Projects_by_pk?: Maybe<(
    { __typename?: 'dw_Projects' }
    & ProjectDetailFieldsFragment
  )> };

export type ProjectsQueryVariables = Exact<{
  projectId?: Maybe<Scalars['uuid']>;
  loadId?: Maybe<Scalars['uuid']>;
  buildingId?: Maybe<Scalars['uuid']>;
  siteId?: Maybe<Scalars['uuid']>;
  accountId?: Maybe<Scalars['uuid']>;
  contactId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['smallint']>;
  search?: Maybe<Scalars['String']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order_by?: Maybe<Array<Dw_Projects_Order_By> | Dw_Projects_Order_By>;
}>;


export type ProjectsQuery = { __typename?: 'query_root', dw_Projects: Array<(
    { __typename?: 'dw_Projects' }
    & ProjectsFieldsFragment
  )>, dw_Projects_aggregate: { __typename?: 'dw_Projects_aggregate', aggregate?: Maybe<{ __typename?: 'dw_Projects_aggregate_fields', totalCount: number }> } };

export type InsertProjectMutationVariables = Exact<{
  object: Dw_Projects_Insert_Input;
}>;


export type InsertProjectMutation = { __typename?: 'mutation_root', insert_dw_Projects_one?: Maybe<{ __typename?: 'dw_Projects', ProjectId: any }> };

export type UpdateProjectsMutationVariables = Exact<{
  ProjectId: Scalars['uuid'];
  Label: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Comments?: Maybe<Scalars['String']>;
  Cost?: Maybe<Scalars['numeric']>;
  UnitCost?: Maybe<Scalars['numeric']>;
  CumulativeKwhSavings?: Maybe<Scalars['numeric']>;
  AnnualKwhSavings?: Maybe<Scalars['numeric']>;
  AnnualSavings?: Maybe<Scalars['numeric']>;
  Priority: Scalars['smallint'];
  Status: Scalars['smallint'];
  NotifiedOn: Scalars['timestamp'];
  ImplementationDate?: Maybe<Scalars['timestamp']>;
  UpdatedOn: Scalars['timestamp'];
}>;


export type UpdateProjectsMutation = { __typename?: 'mutation_root', update_dw_Projects_by_pk?: Maybe<{ __typename?: 'dw_Projects', ProjectId: any, UpdatedOn: any }> };

export type UpdateProjectAssociationsMutationVariables = Exact<{
  ProjectId: Scalars['uuid'];
  AccountId?: Maybe<Scalars['uuid']>;
  SiteId?: Maybe<Scalars['uuid']>;
  BuildingId?: Maybe<Scalars['uuid']>;
  LoadId?: Maybe<Scalars['uuid']>;
  ReporterContactId?: Maybe<Scalars['uuid']>;
  AssigneeContactId?: Maybe<Scalars['uuid']>;
  UpdatedOn: Scalars['timestamp'];
}>;


export type UpdateProjectAssociationsMutation = { __typename?: 'mutation_root', update_dw_Projects_by_pk?: Maybe<{ __typename?: 'dw_Projects', ProjectId: any, UpdatedOn: any }> };

export type ReportsFieldsFragment = { __typename?: 'dw_Reports', ReportId: any, Type: any, BaselineMode: any, TargetElectricity: any, TargetWater: any, TargetGas: any, StartDate: any, UpdatedOn: any, CreatedOn: any, NotificationFrequency: any, Account: { __typename?: 'dw_Accounts', AccountId: any, Label: string } };

export type ReportDetailsQueryVariables = Exact<{
  reportId: Scalars['uuid'];
}>;


export type ReportDetailsQuery = { __typename?: 'query_root', dw_Reports_by_pk?: Maybe<(
    { __typename?: 'dw_Reports' }
    & ReportsFieldsFragment
  )> };

export type ReportsQueryVariables = Exact<{
  reportId?: Maybe<Scalars['uuid']>;
  accountId?: Maybe<Scalars['uuid']>;
  contactId?: Maybe<Scalars['uuid']>;
  search?: Maybe<Scalars['String']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order_by?: Maybe<Array<Dw_Reports_Order_By> | Dw_Reports_Order_By>;
}>;


export type ReportsQuery = { __typename?: 'query_root', dw_Reports: Array<(
    { __typename?: 'dw_Reports' }
    & ReportsFieldsFragment
  )>, dw_Reports_aggregate: { __typename?: 'dw_Reports_aggregate', aggregate?: Maybe<{ __typename?: 'dw_Reports_aggregate_fields', totalCount: number }> } };

export type DeleteReportMutationVariables = Exact<{
  reportId: Scalars['uuid'];
}>;


export type DeleteReportMutation = { __typename?: 'mutation_root', delete_dw_Reports_by_pk?: Maybe<{ __typename?: 'dw_Reports', AccountId: any }> };

export type InsertReportDetailsMutationVariables = Exact<{
  reportId: Scalars['uuid'];
  accountId: Scalars['uuid'];
  targetElectricity: Scalars['numeric'];
  targetGas: Scalars['numeric'];
  targetWater: Scalars['numeric'];
  type: Scalars['smallint'];
  baselineMode: Scalars['smallint'];
  createdOn: Scalars['timestamp'];
  updatedOn: Scalars['timestamp'];
  startDate: Scalars['timestamp'];
  notificationFrequency: Scalars['smallint'];
}>;


export type InsertReportDetailsMutation = { __typename?: 'mutation_root', insert_dw_Reports_one?: Maybe<{ __typename?: 'dw_Reports', ReportId: any }> };

export type UpdateReportDetailsMutationVariables = Exact<{
  ReportId: Scalars['uuid'];
  TargetElectricity: Scalars['numeric'];
  TargetGas: Scalars['numeric'];
  TargetWater: Scalars['numeric'];
  Type: Scalars['smallint'];
  BaselineMode: Scalars['smallint'];
  UpdatedOn: Scalars['timestamp'];
  StartDate: Scalars['timestamp'];
  NotificationFrequency: Scalars['smallint'];
}>;


export type UpdateReportDetailsMutation = { __typename?: 'mutation_root', update_dw_Reports_by_pk?: Maybe<{ __typename?: 'dw_Reports', ReportId: any, TargetElectricity: any, TargetGas: any, TargetWater: any, Type: any, BaselineMode: any, UpdatedOn: any, StartDate: any, NotificationFrequency: any }> };

export type SessionFieldsFragment = { __typename?: 'dw_Sessions', SessionId: any, Source: any, LastActivity: any, AuthorisedOn: any };

export type SessionsQueryVariables = Exact<{
  contactId?: Maybe<Scalars['uuid']>;
  search?: Maybe<Scalars['String']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order_by?: Maybe<Array<Dw_Sessions_Order_By> | Dw_Sessions_Order_By>;
}>;


export type SessionsQuery = { __typename?: 'query_root', dw_Sessions: Array<(
    { __typename?: 'dw_Sessions' }
    & SessionFieldsFragment
  )>, dw_Sessions_aggregate: { __typename?: 'dw_Sessions_aggregate', aggregate?: Maybe<{ __typename?: 'dw_Sessions_aggregate_fields', totalCount: number }> } };

export type SiteFieldsFragment = { __typename?: 'dw_Sites', SiteId: any, Label: string, Status: any, City?: Maybe<string>, CreatedOn: any, UpdatedOn: any, Account: { __typename?: 'dw_Accounts', AccountId: any, Label: string }, SiteCustomerContact?: Maybe<{ __typename?: 'dw_Contacts', ContactId: any, DisplayName: string }>, SiteInstallerContact?: Maybe<{ __typename?: 'dw_Contacts', ContactId: any, DisplayName: string }> };

export type SiteDetailFieldsFragment = { __typename?: 'dw_Sites', SiteId: any, Label: string, Status: any, Line1?: Maybe<string>, Line2?: Maybe<string>, City?: Maybe<string>, Account: { __typename?: 'dw_Accounts', AccountId: any, Label: string }, SiteCustomerContact?: Maybe<{ __typename?: 'dw_Contacts', ContactId: any, DisplayName: string }>, SiteInstallerContact?: Maybe<{ __typename?: 'dw_Contacts', ContactId: any, DisplayName: string }> };

export type SiteSearchQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  AccountId?: Maybe<Scalars['uuid']>;
}>;


export type SiteSearchQuery = { __typename?: 'query_root', dw_Sites: Array<{ __typename?: 'dw_Sites', SiteId: any, Label: string }> };

export type SiteDetailsQueryVariables = Exact<{
  siteId: Scalars['uuid'];
}>;


export type SiteDetailsQuery = { __typename?: 'query_root', dw_Sites_by_pk?: Maybe<(
    { __typename?: 'dw_Sites' }
    & SiteDetailFieldsFragment
  )> };

export type SitesQueryVariables = Exact<{
  siteId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['smallint']>;
  accountId?: Maybe<Scalars['uuid']>;
  search?: Maybe<Scalars['String']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order_by?: Maybe<Array<Dw_Sites_Order_By> | Dw_Sites_Order_By>;
}>;


export type SitesQuery = { __typename?: 'query_root', dw_Sites: Array<(
    { __typename?: 'dw_Sites' }
    & SiteFieldsFragment
  )>, dw_Sites_aggregate: { __typename?: 'dw_Sites_aggregate', aggregate?: Maybe<{ __typename?: 'dw_Sites_aggregate_fields', totalCount: number }> } };

export type InsertSiteMutationVariables = Exact<{
  object: Dw_Sites_Insert_Input;
}>;


export type InsertSiteMutation = { __typename?: 'mutation_root', insert_dw_Sites_one?: Maybe<{ __typename?: 'dw_Sites', SiteId: any }> };

export type UpdateSitesMutationVariables = Exact<{
  SiteId: Scalars['uuid'];
  Label: Scalars['String'];
  Line1?: Maybe<Scalars['String']>;
  Line2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Status: Scalars['smallint'];
  UpdatedOn: Scalars['timestamp'];
}>;


export type UpdateSitesMutation = { __typename?: 'mutation_root', update_dw_Sites_by_pk?: Maybe<{ __typename?: 'dw_Sites', SiteId: any, UpdatedOn: any }> };

export type UpdateSiteAssociationsMutationVariables = Exact<{
  SiteId: Scalars['uuid'];
  AccountId?: Maybe<Scalars['uuid']>;
  SiteContactId?: Maybe<Scalars['uuid']>;
  InstallerContactId?: Maybe<Scalars['uuid']>;
  UpdatedOn: Scalars['timestamp'];
}>;


export type UpdateSiteAssociationsMutation = { __typename?: 'mutation_root', update_dw_Sites_by_pk?: Maybe<{ __typename?: 'dw_Sites', SiteId: any, UpdatedOn: any }> };

export type WeatherFieldsFragment = { __typename?: 'dw_Weather', WeatherId: any, Address?: Maybe<string>, City: string, Country: string, CreatedOn: any, LatestDataPoint?: Maybe<any>, Latitude?: Maybe<any>, Longitude?: Maybe<any>, StartFrom: any, Status: any, UpdatedOn: any };

export type WeatherDetailsQueryVariables = Exact<{
  WeatherId: Scalars['uuid'];
}>;


export type WeatherDetailsQuery = { __typename?: 'query_root', dw_Weather_by_pk?: Maybe<(
    { __typename?: 'dw_Weather' }
    & WeatherFieldsFragment
  )> };

export type WeatherQueryVariables = Exact<{
  weatherId?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['smallint']>;
  search?: Maybe<Scalars['String']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order_by?: Maybe<Array<Dw_Weather_Order_By> | Dw_Weather_Order_By>;
}>;


export type WeatherQuery = { __typename?: 'query_root', dw_Weather: Array<(
    { __typename?: 'dw_Weather', WeatherReadings_aggregate: { __typename?: 'dw_WeatherReadings_aggregate', aggregate?: Maybe<{ __typename?: 'dw_WeatherReadings_aggregate_fields', count: number }> } }
    & WeatherFieldsFragment
  )>, dw_Weather_aggregate: { __typename?: 'dw_Weather_aggregate', aggregate?: Maybe<{ __typename?: 'dw_Weather_aggregate_fields', totalCount: number }> } };

export type InsertWeatherMutationVariables = Exact<{
  object: Dw_Weather_Insert_Input;
}>;


export type InsertWeatherMutation = { __typename?: 'mutation_root', insert_dw_Weather_one?: Maybe<{ __typename?: 'dw_Weather', WeatherId: any }> };

export type UpdateWeatherMutationVariables = Exact<{
  WeatherId: Scalars['uuid'];
  Status: Scalars['smallint'];
  Address?: Maybe<Scalars['String']>;
  Longitude?: Maybe<Scalars['numeric']>;
  Latitude?: Maybe<Scalars['numeric']>;
  UpdatedOn: Scalars['timestamp'];
}>;


export type UpdateWeatherMutation = { __typename?: 'mutation_root', update_dw_Weather_by_pk?: Maybe<{ __typename?: 'dw_Weather', WeatherId: any, UpdatedOn: any }> };

export type WeatherReadingsFieldsFragment = { __typename?: 'dw_WeatherReadings', WeatherId: any, Cdd?: Maybe<any>, Conditions: string, Dewpoint: any, Event: any, FeelsLike: any, Hdd?: Maybe<any>, Humidity: number, ReadingType?: Maybe<number>, Temperature: any, WindDirection: string, WindSpeed: any };

export type WeatherReadingsQueryVariables = Exact<{
  weatherId?: Maybe<Scalars['uuid']>;
  search?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamp']>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  order_by?: Maybe<Array<Dw_WeatherReadings_Order_By> | Dw_WeatherReadings_Order_By>;
}>;


export type WeatherReadingsQuery = { __typename?: 'query_root', dw_WeatherReadings: Array<(
    { __typename?: 'dw_WeatherReadings' }
    & WeatherReadingsFieldsFragment
  )>, dw_WeatherReadings_aggregate: { __typename?: 'dw_WeatherReadings_aggregate', aggregate?: Maybe<{ __typename?: 'dw_WeatherReadings_aggregate_fields', totalCount: number }> } };

export const AccountFieldsFragmentDoc = gql`
    fragment AccountFields on dw_Accounts {
  AccountId
  Label
  BillingEmailAddress
  IndustryCode
  WebsiteUrl
  Status
  CreatedOn
  UpdatedOn
  Reports {
    ReportId
    BaselineMode
    Type
    TargetElectricity
    TargetGas
    TargetWater
    StartDate
    CreatedOn
    UpdatedOn
  }
}
    `;
export const AccountDetailFieldsFragmentDoc = gql`
    fragment AccountDetailFields on dw_Accounts {
  ...AccountFields
  ParentAccountId
  NumEmployees
  Notes
  Status
  FinancialStartMonth
  contactByPrimarycontactid {
    DisplayName
    ContactId
  }
  BillingEmailAddress
  AccountsAddresses_aggregate(
    limit: 1
    where: {City: {_is_null: false}, Type: {_eq: 1}}
  ) {
    nodes {
      City
    }
  }
  UpdatedOn
  CreatedOn
}
    ${AccountFieldsFragmentDoc}`;
export const AccountAddressFieldsFragmentDoc = gql`
    fragment AccountAddressFields on dw_AccountsAddress {
  AddressId
  City
  Composite
  Country
  Latitude
  Line1
  Line2
  Line3
  Longitude
  Name
  Phone1
  Phone2
  Phone3
  Fax
  PostalCode
  State
  Type
  CreatedOn
  UpdatedOn
  Account {
    AccountId
    Label
  }
}
    `;
export const AccountsSubscriptionFieldsFragmentDoc = gql`
    fragment AccountsSubscriptionFields on dw_AccountsSubscription {
  Account {
    AccountId
    Label
  }
  BalanceAsAt
  Balance
  CeasedOn
  CommerceOn
  PaymentCustomerRef
  CreatedOn
  Plan {
    PlanId
    Label
  }
  Status
  SubscriptionId
  UpdatedOn
}
    `;
export const BuildingFieldsFragmentDoc = gql`
    fragment BuildingFields on dw_Buildings {
  BuildingId
  Label
  Status
  CreatedOn
  UpdatedOn
  NlaSqm
  GfaSqm
  FunctionalUse
  HeatingSystem
  CoolingSystem
  Location
  WeatherId
  IsicCode
  RatingType
  RatingValue
  RatingExpiry
  Site {
    SiteId
    City
    Label
    Account {
      AccountId
      Label
      IndustryCode
    }
  }
}
    `;
export const BuildingDetailFieldsFragmentDoc = gql`
    fragment BuildingDetailFields on dw_Buildings {
  ...BuildingFields
  BuildingDocuments {
    DocumentId
    BuildingId
    FileName
    UploadDate
    Status
  }
}
    ${BuildingFieldsFragmentDoc}`;
export const ChannelFieldsFragmentDoc = gql`
    fragment ChannelFields on dw_Channels {
  ChannelId
  ChannelKey
  Label
  MeterType
  Type
  SummationType
  ValueTypeEnum
  Formula
  IntervalFrequency
  IntervalMins
  Level
  Parameter
  Reference
  TimeZone
  UnitOfMeasure
  EnableAlerts
  NatureType
  Status
  StartDate
  Feed {
    FeedId
    Label
    Notes
  }
  Load {
    LoadId
    Label
    Building {
      BuildingId
      Label
      Site {
        SiteId
        Label
        Account {
          AccountId
          Label
        }
      }
    }
  }
}
    `;
export const ExportFieldsFragmentDoc = gql`
    fragment ExportFields on dw_Channels {
  ChannelId
  ChannelKey
  Label
  Type
  SummationType
  ValueTypeEnum
  Formula
  IntervalFrequency
  IntervalMins
  Parameter
  TimeZone
  UnitOfMeasure
  EnableAlerts
  NatureType
  Status
  StartDate
  CreatedOn
  UpdatedOn
  Feed {
    FeedId
    Label
    Notes
    Status
    FeedsType {
      TypeId
      Label
    }
  }
  Load {
    LoadId
    Label
    IcpNumber
    MainIncomer
    TransformerCapacity
    NegotiatedCapacity
    MeterSerialNumber
    UtilityType
    Description
    ModbusId
    Status
    Feed {
      FeedId
      Label
      FeedsType {
        TypeId
        Label
      }
    }
    Building {
      BuildingId
      Label
      Status
      Site {
        SiteId
        Label
        City
        Status
        Account {
          AccountId
          Label
          Status
        }
      }
    }
  }
  ChannelsMetadataSingle {
    LatestData
    LatestDataUploaded
    ConsumptionStartDate
  }
}
    `;
export const ChannelsJobsFieldsFragmentDoc = gql`
    fragment ChannelsJobsFields on dw_ChannelsJobs {
  JobId
  ChannelId
  Formula
  From
  To
  Logs
  Status
  LastActivity
  StartedOn
  Channel {
    ChannelId
    ChannelKey
    Label
    Load {
      LoadId
      Label
      Building {
        BuildingId
        Label
        Site {
          SiteId
          Label
          Account {
            AccountId
            Label
          }
        }
      }
    }
  }
}
    `;
export const ChannelsMappingsFieldsFragmentDoc = gql`
    fragment ChannelsMappingsFields on dw_ChannelsMappings {
  ExternalId
  Reference
  Channel {
    ChannelId
    ChannelKey
    Label
    Feed {
      FeedId
      Label
      SerialNumber
    }
  }
}
    `;
export const ChannelsMetadataFieldsFragmentDoc = gql`
    fragment ChannelsMetadataFields on dw_ChannelsMetadata {
  ChannelId
  BaselineDailyAverage
  ConsumptionStartDate
  CumulativeToYear
  DataStabilised
  LatestData
  LatestDataUploaded
  NegativeValuesPermissible
  Channel {
    ChannelId
    ChannelKey
    Label
    Feed {
      FeedId
      Label
      SerialNumber
    }
  }
}
    `;
export const ChannelsRulesFieldsFragmentDoc = gql`
    fragment ChannelsRulesFields on dw_ChannelsRules {
  RuleId
  ChannelId
  Status
  CreatedOn
  Channel {
    ChannelId
    ChannelKey
    Label
    UnitOfMeasure
    IntervalFrequency
    Load {
      LoadId
      Label
      Building {
        BuildingId
        Label
      }
    }
  }
  channelByChannelid {
    ChannelId
    ChannelKey
    Label
    UnitOfMeasure
    Load {
      LoadId
      Label
    }
  }
}
    `;
export const ConsumptionJobsFieldsFragmentDoc = gql`
    fragment ConsumptionJobsFields on dw_ConsumptionJobs {
  JobId
  File
  Logs
  Size
  Type
  Status
  LastActivity
}
    `;
export const ContactFieldsFragmentDoc = gql`
    fragment ContactFields on dw_Contacts {
  ContactId
  DisplayName
  FirstName
  LastName
  EmailAddress
  Status
  Type
  CreatedOn
  UpdatedOn
  Sessions(limit: 1, order_by: {LastActivity: desc}) {
    LastActivity
  }
}
    `;
export const ContactsRoleFieldsFragmentDoc = gql`
    fragment ContactsRoleFields on dw_ContactsRole {
  EmailAddress
  Role
  Status
  Account {
    AccountId
    Label
    Status
  }
  Contact {
    ContactId
    DisplayName
    EmailAddress
    Status
    Type
  }
}
    `;
export const DashboardDetailFieldsFragmentDoc = gql`
    fragment DashboardDetailFields on dw_Dashboards {
  DashboardId
  Label
  Code
  WeatherColor
  BackgroundColor
  ElectricityColor
  GasColor
  WaterColor
  HasBackground
  ShowWeather
  Status
  Account {
    AccountId
    Label
  }
  Building {
    BuildingId
    Label
    Site {
      SiteId
      Label
    }
  }
}
    `;
export const DashboardFieldsFragmentDoc = gql`
    fragment DashboardFields on dw_Dashboards {
  DashboardId
  Label
  Code
  WeatherColor
  BackgroundColor
  ElectricityColor
  GasColor
  WaterColor
  HasBackground
  ShowWeather
  Status
  Account {
    AccountId
    Label
  }
  Building {
    BuildingId
    Label
    Site {
      SiteId
      Label
    }
  }
}
    `;
export const FeedFieldsFragmentDoc = gql`
    fragment FeedFields on dw_Feeds {
  FeedId
  Label
  SerialNumber
  BatteryDailyUsage
  BatteryPercentage
  Notes
  UserName
  Password
  SimCard
  Temperature
  Status
  CreatedOn
  UpdatedOn
  ActivatedOn
  LastSync
  Url
  ProductionMode
  IntervalEmitFrequency
  FeedsType {
    TypeId
    Label
  }
  Building {
    BuildingId
    Label
    Site {
      SiteId
      Label
      Account {
        AccountId
        Label
      }
    }
  }
}
    `;
export const FeedsConfigBacNetFieldsFragmentDoc = gql`
    fragment FeedsConfigBacNetFields on dw_FeedsConfigBacNet {
  ChannelId
  FeedId
  ObjectId
  DeviceId
  CalculateDelta
  ObjectInstance
  ReadFrequencySeconds
  Status
  CreatedOn
  UpdatedOn
  Channel {
    ChannelId
    ChannelKey
    Label
    Load {
      LoadId
      Label
      Building {
        BuildingId
        Label
        Site {
          SiteId
          Label
          Account {
            AccountId
            Label
          }
        }
      }
    }
  }
  Feed {
    FeedId
    Label
    Notes
    SerialNumber
    SimCard
  }
}
    `;
export const FeedsConfigModbusDetailFieldsFragmentDoc = gql`
    fragment FeedsConfigModbusDetailFields on dw_FeedsConfigModbus {
  ChannelId
  CalculateDelta
  DeviceAddress
  IsBigEndian
  ReadFrequencySeconds
  RegisterAddress
  RegisterCount
  RegisterType
  RtuBaudRate
  RtuDataBits
  RtuParity
  RtuSerialPort
  RtuStopBits
  ScalingFactor
  Status
  TcpIpAddress
  TcpPort
  CreatedOn
  UpdatedOn
  Feed {
    FeedId
    Label
    SerialNumber
    SimCard
  }
  Channel {
    ChannelId
    Label
    Load {
      LoadId
      Label
      Building {
        BuildingId
        Label
        Site {
          SiteId
          Label
          Account {
            AccountId
            Label
          }
        }
      }
    }
  }
}
    `;
export const FeedsConfigModbusFieldsFragmentDoc = gql`
    fragment FeedsConfigModbusFields on dw_FeedsConfigModbus {
  ChannelId
  ReadFrequencySeconds
  RegisterAddress
  RegisterCount
  RegisterType
  DeviceAddress
  Status
  CreatedOn
  UpdatedOn
  Feed {
    FeedId
    Label
    SerialNumber
    SimCard
  }
  Channel {
    ChannelId
    Label
    Load {
      LoadId
      Label
      Building {
        BuildingId
        Label
        Site {
          SiteId
          Label
          Account {
            AccountId
            Label
          }
        }
      }
    }
  }
}
    `;
export const FeedsLogsFieldsFragmentDoc = gql`
    fragment FeedsLogsFields on dw_FeedsLogs {
  EventId
  Event
  Log
  Severity
  Feed {
    FeedId
    Label
    SerialNumber
    SimCard
  }
}
    `;
export const GroupFieldsFragmentDoc = gql`
    fragment GroupFields on dw_Groups {
  GroupId
  Label
  Status
  CreatedOn
  UpdatedOn
  Account {
    AccountId
    Label
  }
}
    `;
export const GroupsSitesFieldsFragmentDoc = gql`
    fragment GroupsSitesFields on dw_GroupsSites {
  GroupId
  SiteId
  Group {
    GroupId
    Label
  }
  Site {
    SiteId
    Label
  }
  Status
  CreatedOn
  UpdatedOn
}
    `;
export const HistoryFieldsFragmentDoc = gql`
    fragment HistoryFields on dw_History {
  HistoryId
  Description
  SourceId
  SourceType
  OccuredOn
  Contact {
    ContactId
    DisplayName
  }
}
    `;
export const LoadFieldsFragmentDoc = gql`
    fragment LoadFields on dw_Loads {
  LoadId
  Label
  TenantLabel
  ParentLoadId
  CommunicationType
  CtRating
  CtSupplied
  CtType
  Description
  BreakerRating
  IcpNumber
  MeterName
  MeterType
  MeterSerialNumber
  ModbusId
  NabersRating
  NegotiatedCapacity
  TransformerCapacity
  MainIncomer
  UtilityType
  Status
  ReferenceValue
  CreatedOn
  UpdatedOn
  ParentLoad {
    LoadId
    Label
  }
  LoadsService {
    ServiceId
    Label
  }
  LoadsType {
    TypeId
    Label
  }
  Feed {
    FeedId
    Label
    Notes
    SerialNumber
  }
  Building {
    BuildingId
    Label
    Site {
      SiteId
      Label
      Account {
        AccountId
        Label
      }
    }
  }
}
    `;
export const LoadDetailFieldsFragmentDoc = gql`
    fragment LoadDetailFields on dw_Loads {
  ...LoadFields
  Channels {
    ChannelId
    FeedId
  }
  LoadDocuments {
    DocumentId
    LoadId
    FileName
    UploadDate
    Status
  }
}
    ${LoadFieldsFragmentDoc}`;
export const MlNotificationGroupsFieldsFragmentDoc = gql`
    fragment MLNotificationGroupsFields on dw_MLNotificationGroup {
  NotificationGroupId
  AlertType
  OnShift
  Priority
  QualificationStatus
  RootCause
  Summary
  StartDate
  EndDate
  CreatedOn
  UpdatedOn
  Contact {
    ContactId
    DisplayName
  }
  Building {
    BuildingId
    Label
    Site {
      SiteId
      Label
      Account {
        AccountId
        Label
      }
    }
  }
  MLNotifications {
    EquivalentC02
    EquivalentCost
    Channel {
      Load {
        MainIncomer
      }
    }
  }
  MLNotifications_aggregate {
    aggregate {
      sum {
        EquivalentC02
        EquivalentCost
      }
    }
  }
}
    `;
export const MlNotificationGroupsDetailFieldsFragmentDoc = gql`
    fragment MLNotificationGroupsDetailFields on dw_MLNotificationGroup {
  NotificationGroupId
  AlertType
  OnShift
  Priority
  QualificationStatus
  RootCause
  Summary
  StartDate
  EndDate
  CreatedOn
  UpdatedOn
  Contact {
    ContactId
    DisplayName
  }
  Building {
    BuildingId
    Label
    Site {
      SiteId
      Label
      Account {
        AccountId
        Label
      }
    }
    Loads {
      Channels {
        IntervalFrequency
      }
    }
  }
}
    `;
export const MlNotificationsGroupsComparisonFieldsFragmentDoc = gql`
    fragment mlNotificationsGroupsComparisonFields on dw_MLNotificationGroup_aggregate {
  aggregate {
    count
  }
}
    `;
export const NotificationsFieldsFragmentDoc = gql`
    fragment NotificationsFields on dw_Notifications {
  NotificationId
  WindowStart
  WindowEnd
  Status
  CreatedOn
  UpdatedOn
  Load {
    LoadId
    Label
    UtilityType
  }
  Building {
    BuildingId
    Label
    Site {
      SiteId
      Label
    }
  }
  Account {
    AccountId
    Label
  }
}
    `;
export const NotificationDetailFieldsFragmentDoc = gql`
    fragment NotificationDetailFields on dw_Notifications {
  NotificationId
  WindowStart
  WindowEnd
  Label
  Assessment
  RootCause
  Status
  Contact {
    ContactId
    DisplayName
    EmailAddress
  }
  contactByReviewer {
    ContactId
    DisplayName
    EmailAddress
  }
  DueDate
  CreatedOn
  UpdatedOn
  Channel {
    ChannelId
    Label
    IntervalFrequency
    UnitOfMeasure
    NatureType
  }
  Load {
    LoadId
    Label
    UtilityType
  }
  Building {
    BuildingId
    Label
    Site {
      SiteId
      Label
    }
  }
  Account {
    AccountId
    Label
  }
  NotificationsEvents(order_by: {Event: asc}) {
    EventId
    Event
    Consumption
    Score
    PValue
    Status
  }
  NotificationsLogs(order_by: {Event: desc}) {
    LogId
    Type
    Event
    Message
    Contact {
      ContactId
      DisplayName
    }
  }
}
    `;
export const ProjectsFieldsFragmentDoc = gql`
    fragment ProjectsFields on dw_Projects {
  ProjectId
  Label
  Description
  Comments
  Cost
  UnitCost
  CumulativeKwhSavings
  AnnualKwhSavings
  AnnualSavings
  Status
  Priority
  NotifiedOn
  ImplementationDate
  UpdatedOn
  CreatedOn
  Contact {
    ContactId
    DisplayName
  }
  contactByAssigneecontactid {
    ContactId
    DisplayName
  }
  Load {
    LoadId
    Label
  }
  Building {
    BuildingId
    Label
  }
  Site {
    SiteId
    Label
  }
  Account {
    AccountId
    Label
  }
}
    `;
export const ProjectDetailFieldsFragmentDoc = gql`
    fragment ProjectDetailFields on dw_Projects {
  ProjectId
  Label
  Description
  Comments
  Cost
  UnitCost
  CumulativeKwhSavings
  AnnualKwhSavings
  AnnualSavings
  Status
  Priority
  NotifiedOn
  ImplementationDate
  UpdatedOn
  CreatedOn
  Contact {
    ContactId
    DisplayName
  }
  contactByAssigneecontactid {
    ContactId
    DisplayName
  }
  AccountId
  SiteId
  BuildingId
  LoadId
  Load {
    LoadId
    Label
    Building {
      BuildingId
      Label
      Site {
        SiteId
        Label
        Account {
          AccountId
          Label
        }
      }
    }
  }
  Building {
    BuildingId
    Label
    Site {
      SiteId
      Label
      Account {
        AccountId
        Label
      }
    }
  }
  Site {
    SiteId
    Label
    Account {
      AccountId
      Label
    }
  }
  Account {
    AccountId
    Label
  }
}
    `;
export const ReportsFieldsFragmentDoc = gql`
    fragment ReportsFields on dw_Reports {
  ReportId
  Type
  BaselineMode
  TargetElectricity
  TargetWater
  TargetGas
  StartDate
  UpdatedOn
  CreatedOn
  NotificationFrequency
  Account {
    AccountId
    Label
  }
}
    `;
export const SessionFieldsFragmentDoc = gql`
    fragment SessionFields on dw_Sessions {
  SessionId
  Source
  LastActivity
  AuthorisedOn
}
    `;
export const SiteFieldsFragmentDoc = gql`
    fragment SiteFields on dw_Sites {
  SiteId
  Label
  Status
  City
  CreatedOn
  UpdatedOn
  Account {
    AccountId
    Label
  }
  SiteCustomerContact {
    ContactId
    DisplayName
  }
  SiteInstallerContact {
    ContactId
    DisplayName
  }
}
    `;
export const SiteDetailFieldsFragmentDoc = gql`
    fragment SiteDetailFields on dw_Sites {
  SiteId
  Label
  Status
  Line1
  Line2
  City
  Account {
    AccountId
    Label
  }
  SiteCustomerContact {
    ContactId
    DisplayName
  }
  SiteInstallerContact {
    ContactId
    DisplayName
  }
}
    `;
export const WeatherFieldsFragmentDoc = gql`
    fragment WeatherFields on dw_Weather {
  WeatherId
  Address
  City
  Country
  CreatedOn
  LatestDataPoint
  Latitude
  Longitude
  StartFrom
  Status
  UpdatedOn
}
    `;
export const WeatherReadingsFieldsFragmentDoc = gql`
    fragment WeatherReadingsFields on dw_WeatherReadings {
  WeatherId
  Cdd
  Conditions
  Dewpoint
  Event
  FeelsLike
  Hdd
  Humidity
  ReadingType
  Temperature
  WindDirection
  WindSpeed
}
    `;
export const AccountSearchDocument = gql`
    query AccountSearch($search: String, $ContactId: uuid) {
  dw_Accounts(
    where: {Status: {_eq: 1}, _and: {Label: {_ilike: $search}}}
    limit: 30
    order_by: {Label: asc}
  ) {
    AccountId
    Label
    Reports {
      ReportId
      BaselineMode
      Type
    }
  }
}
    `;

/**
 * __useAccountSearchQuery__
 *
 * To run a query within a React component, call `useAccountSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountSearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *      ContactId: // value for 'ContactId'
 *   },
 * });
 */
export function useAccountSearchQuery(baseOptions?: Apollo.QueryHookOptions<AccountSearchQuery, AccountSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountSearchQuery, AccountSearchQueryVariables>(AccountSearchDocument, options);
      }
export function useAccountSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountSearchQuery, AccountSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountSearchQuery, AccountSearchQueryVariables>(AccountSearchDocument, options);
        }
export type AccountSearchQueryHookResult = ReturnType<typeof useAccountSearchQuery>;
export type AccountSearchLazyQueryHookResult = ReturnType<typeof useAccountSearchLazyQuery>;
export type AccountSearchQueryResult = Apollo.QueryResult<AccountSearchQuery, AccountSearchQueryVariables>;
export const AccountContactRolesSearchDocument = gql`
    query AccountContactRolesSearch($search: String, $ContactId: uuid) {
  dw_Accounts(
    where: {Status: {_eq: 1}, _and: {Label: {_ilike: $search}}}
    limit: 30
    order_by: {Label: asc}
  ) {
    AccountId
    Label
    Reports {
      ReportId
      BaselineMode
      Type
    }
  }
}
    `;

/**
 * __useAccountContactRolesSearchQuery__
 *
 * To run a query within a React component, call `useAccountContactRolesSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountContactRolesSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountContactRolesSearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *      ContactId: // value for 'ContactId'
 *   },
 * });
 */
export function useAccountContactRolesSearchQuery(baseOptions?: Apollo.QueryHookOptions<AccountContactRolesSearchQuery, AccountContactRolesSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountContactRolesSearchQuery, AccountContactRolesSearchQueryVariables>(AccountContactRolesSearchDocument, options);
      }
export function useAccountContactRolesSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountContactRolesSearchQuery, AccountContactRolesSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountContactRolesSearchQuery, AccountContactRolesSearchQueryVariables>(AccountContactRolesSearchDocument, options);
        }
export type AccountContactRolesSearchQueryHookResult = ReturnType<typeof useAccountContactRolesSearchQuery>;
export type AccountContactRolesSearchLazyQueryHookResult = ReturnType<typeof useAccountContactRolesSearchLazyQuery>;
export type AccountContactRolesSearchQueryResult = Apollo.QueryResult<AccountContactRolesSearchQuery, AccountContactRolesSearchQueryVariables>;
export const AccountDetailsDocument = gql`
    query AccountDetails($accountId: uuid!) {
  dw_Accounts_by_pk(AccountId: $accountId) {
    ...AccountDetailFields
  }
}
    ${AccountDetailFieldsFragmentDoc}`;

/**
 * __useAccountDetailsQuery__
 *
 * To run a query within a React component, call `useAccountDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountDetailsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountDetailsQuery(baseOptions: Apollo.QueryHookOptions<AccountDetailsQuery, AccountDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountDetailsQuery, AccountDetailsQueryVariables>(AccountDetailsDocument, options);
      }
export function useAccountDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountDetailsQuery, AccountDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountDetailsQuery, AccountDetailsQueryVariables>(AccountDetailsDocument, options);
        }
export type AccountDetailsQueryHookResult = ReturnType<typeof useAccountDetailsQuery>;
export type AccountDetailsLazyQueryHookResult = ReturnType<typeof useAccountDetailsLazyQuery>;
export type AccountDetailsQueryResult = Apollo.QueryResult<AccountDetailsQuery, AccountDetailsQueryVariables>;
export const AccountsDocument = gql`
    query Accounts($accountId: uuid, $status: smallint, $search: String, $offset: Int!, $limit: Int!, $order_by: [dw_Accounts_order_by!]) {
  dw_Accounts(
    where: {AccountId: {_eq: $accountId}, Status: {_eq: $status}, _or: [{BillingEmailAddress: {_ilike: $search}}, {Label: {_ilike: $search}}, {WebsiteUrl: {_ilike: $search}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...AccountFields
    Sites_aggregate {
      aggregate {
        count
      }
    }
  }
  dw_Accounts_aggregate(
    where: {AccountId: {_eq: $accountId}, Status: {_eq: $status}, _or: [{BillingEmailAddress: {_ilike: $search}}, {Label: {_ilike: $search}}, {WebsiteUrl: {_ilike: $search}}]}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${AccountFieldsFragmentDoc}`;

/**
 * __useAccountsQuery__
 *
 * To run a query within a React component, call `useAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      status: // value for 'status'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useAccountsQuery(baseOptions: Apollo.QueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
      }
export function useAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountsQuery, AccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountsQuery, AccountsQueryVariables>(AccountsDocument, options);
        }
export type AccountsQueryHookResult = ReturnType<typeof useAccountsQuery>;
export type AccountsLazyQueryHookResult = ReturnType<typeof useAccountsLazyQuery>;
export type AccountsQueryResult = Apollo.QueryResult<AccountsQuery, AccountsQueryVariables>;
export const ActiveAccountsDocument = gql`
    query ActiveAccounts($accountId: uuid, $status: smallint, $search: String, $offset: Int!, $limit: Int!, $order_by: [dw_Accounts_order_by!]) {
  dw_Accounts(
    where: {AccountId: {_eq: $accountId}, Status: {_eq: $status}, Sites: {Status: {_eq: $status}, Buildings: {Status: {_eq: $status}, Loads: {Status: {_eq: $status}, Channels: {Status: {_eq: $status}}}}}, _or: [{BillingEmailAddress: {_ilike: $search}}, {Label: {_ilike: $search}}, {WebsiteUrl: {_ilike: $search}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...AccountFields
    Sites_aggregate(
      where: {Status: {_eq: $status}, Buildings: {Status: {_eq: $status}, Loads: {Status: {_eq: $status}, Channels: {Status: {_eq: $status}}}}}
    ) {
      aggregate {
        count
      }
    }
  }
  dw_Accounts_aggregate(
    where: {AccountId: {_eq: $accountId}, Status: {_eq: $status}, Sites: {Status: {_eq: $status}, Buildings: {Status: {_eq: $status}, Loads: {Status: {_eq: $status}, Channels: {Status: {_eq: $status}}}}}, _or: [{BillingEmailAddress: {_ilike: $search}}, {Label: {_ilike: $search}}, {WebsiteUrl: {_ilike: $search}}]}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${AccountFieldsFragmentDoc}`;

/**
 * __useActiveAccountsQuery__
 *
 * To run a query within a React component, call `useActiveAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveAccountsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      status: // value for 'status'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useActiveAccountsQuery(baseOptions: Apollo.QueryHookOptions<ActiveAccountsQuery, ActiveAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveAccountsQuery, ActiveAccountsQueryVariables>(ActiveAccountsDocument, options);
      }
export function useActiveAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveAccountsQuery, ActiveAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveAccountsQuery, ActiveAccountsQueryVariables>(ActiveAccountsDocument, options);
        }
export type ActiveAccountsQueryHookResult = ReturnType<typeof useActiveAccountsQuery>;
export type ActiveAccountsLazyQueryHookResult = ReturnType<typeof useActiveAccountsLazyQuery>;
export type ActiveAccountsQueryResult = Apollo.QueryResult<ActiveAccountsQuery, ActiveAccountsQueryVariables>;
export const InsertAccountDocument = gql`
    mutation InsertAccount($object: dw_Accounts_insert_input!) {
  insert_dw_Accounts_one(object: $object) {
    AccountId
  }
}
    `;
export type InsertAccountMutationFn = Apollo.MutationFunction<InsertAccountMutation, InsertAccountMutationVariables>;

/**
 * __useInsertAccountMutation__
 *
 * To run a mutation, you first call `useInsertAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertAccountMutation, { data, loading, error }] = useInsertAccountMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertAccountMutation(baseOptions?: Apollo.MutationHookOptions<InsertAccountMutation, InsertAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertAccountMutation, InsertAccountMutationVariables>(InsertAccountDocument, options);
      }
export type InsertAccountMutationHookResult = ReturnType<typeof useInsertAccountMutation>;
export type InsertAccountMutationResult = Apollo.MutationResult<InsertAccountMutation>;
export type InsertAccountMutationOptions = Apollo.BaseMutationOptions<InsertAccountMutation, InsertAccountMutationVariables>;
export const UpdateAccountsDocument = gql`
    mutation UpdateAccounts($AccountId: uuid!, $ParentAccountId: uuid, $Label: String!, $BillingEmailAddress: String, $IndustryCode: String, $WebsiteUrl: String, $NumEmployees: Int, $Notes: String, $FinancialStartMonth: smallint, $Status: smallint!, $UpdatedOn: timestamp!) {
  update_dw_Accounts_by_pk(
    pk_columns: {AccountId: $AccountId}
    _set: {ParentAccountId: $ParentAccountId, Label: $Label, BillingEmailAddress: $BillingEmailAddress, IndustryCode: $IndustryCode, WebsiteUrl: $WebsiteUrl, NumEmployees: $NumEmployees, Notes: $Notes, FinancialStartMonth: $FinancialStartMonth, Status: $Status, UpdatedOn: $UpdatedOn}
  ) {
    AccountId
    UpdatedOn
  }
}
    `;
export type UpdateAccountsMutationFn = Apollo.MutationFunction<UpdateAccountsMutation, UpdateAccountsMutationVariables>;

/**
 * __useUpdateAccountsMutation__
 *
 * To run a mutation, you first call `useUpdateAccountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountsMutation, { data, loading, error }] = useUpdateAccountsMutation({
 *   variables: {
 *      AccountId: // value for 'AccountId'
 *      ParentAccountId: // value for 'ParentAccountId'
 *      Label: // value for 'Label'
 *      BillingEmailAddress: // value for 'BillingEmailAddress'
 *      IndustryCode: // value for 'IndustryCode'
 *      WebsiteUrl: // value for 'WebsiteUrl'
 *      NumEmployees: // value for 'NumEmployees'
 *      Notes: // value for 'Notes'
 *      FinancialStartMonth: // value for 'FinancialStartMonth'
 *      Status: // value for 'Status'
 *      UpdatedOn: // value for 'UpdatedOn'
 *   },
 * });
 */
export function useUpdateAccountsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountsMutation, UpdateAccountsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountsMutation, UpdateAccountsMutationVariables>(UpdateAccountsDocument, options);
      }
export type UpdateAccountsMutationHookResult = ReturnType<typeof useUpdateAccountsMutation>;
export type UpdateAccountsMutationResult = Apollo.MutationResult<UpdateAccountsMutation>;
export type UpdateAccountsMutationOptions = Apollo.BaseMutationOptions<UpdateAccountsMutation, UpdateAccountsMutationVariables>;
export const AccountDataForCsvImportDocument = gql`
    query AccountDataForCsvImport($accountId: uuid!) {
  dw_Accounts_by_pk(AccountId: $accountId) {
    Label
    AccountId
  }
  dw_Sites(where: {Status: {_eq: 1}, AccountId: {_eq: $accountId}}) {
    Label
    SiteId
    AccountId
  }
  dw_Buildings(where: {Status: {_eq: 1}, Site: {AccountId: {_eq: $accountId}}}) {
    Label
    BuildingId
    SiteId
  }
  dw_Loads(
    where: {Status: {_eq: 1}, Building: {Site: {AccountId: {_eq: $accountId}}}}
  ) {
    Label
    LoadId
    IcpNumber
    MainIncomer
    BuildingId
  }
  dw_Channels(
    where: {Status: {_eq: 1}, Load: {Building: {Site: {AccountId: {_eq: $accountId}}}}}
  ) {
    Label
    ChannelId
    ChannelKey
    LoadId
  }
  dw_Feeds(
    where: {Status: {_eq: 1}, Building: {Site: {AccountId: {_eq: $accountId}}}}
  ) {
    Label
    FeedId
  }
}
    `;

/**
 * __useAccountDataForCsvImportQuery__
 *
 * To run a query within a React component, call `useAccountDataForCsvImportQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountDataForCsvImportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountDataForCsvImportQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountDataForCsvImportQuery(baseOptions: Apollo.QueryHookOptions<AccountDataForCsvImportQuery, AccountDataForCsvImportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountDataForCsvImportQuery, AccountDataForCsvImportQueryVariables>(AccountDataForCsvImportDocument, options);
      }
export function useAccountDataForCsvImportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountDataForCsvImportQuery, AccountDataForCsvImportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountDataForCsvImportQuery, AccountDataForCsvImportQueryVariables>(AccountDataForCsvImportDocument, options);
        }
export type AccountDataForCsvImportQueryHookResult = ReturnType<typeof useAccountDataForCsvImportQuery>;
export type AccountDataForCsvImportLazyQueryHookResult = ReturnType<typeof useAccountDataForCsvImportLazyQuery>;
export type AccountDataForCsvImportQueryResult = Apollo.QueryResult<AccountDataForCsvImportQuery, AccountDataForCsvImportQueryVariables>;
export const AccountLoadDataForCsvImportDocument = gql`
    query AccountLoadDataForCsvImport($accountId: uuid!) {
  dw_Accounts_by_pk(AccountId: $accountId) {
    Label
    AccountId
  }
  dw_Loads(
    where: {Status: {_eq: 1}, Building: {Site: {AccountId: {_eq: $accountId}}}}
  ) {
    LoadId
    BuildingId
    ParentLoadId
    Label
    IcpNumber
    Description
    NabersRating
    TransformerCapacity
    MainIncomer
    UtilityType
    Status
    ServiceId
    TypeId
    FeedId
  }
  dw_Buildings(where: {Status: {_eq: 1}, Site: {AccountId: {_eq: $accountId}}}) {
    BuildingId
    Label
  }
  dw_LoadsService(where: {Status: {_eq: 1}}) {
    ServiceId
    Label
  }
  dw_LoadsType(where: {Status: {_eq: 1}}) {
    TypeId
    Label
  }
  dw_Feeds(
    where: {Status: {_eq: 1}, Building: {Site: {AccountId: {_eq: $accountId}}}}
  ) {
    Label
    FeedId
  }
}
    `;

/**
 * __useAccountLoadDataForCsvImportQuery__
 *
 * To run a query within a React component, call `useAccountLoadDataForCsvImportQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountLoadDataForCsvImportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountLoadDataForCsvImportQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountLoadDataForCsvImportQuery(baseOptions: Apollo.QueryHookOptions<AccountLoadDataForCsvImportQuery, AccountLoadDataForCsvImportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountLoadDataForCsvImportQuery, AccountLoadDataForCsvImportQueryVariables>(AccountLoadDataForCsvImportDocument, options);
      }
export function useAccountLoadDataForCsvImportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountLoadDataForCsvImportQuery, AccountLoadDataForCsvImportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountLoadDataForCsvImportQuery, AccountLoadDataForCsvImportQueryVariables>(AccountLoadDataForCsvImportDocument, options);
        }
export type AccountLoadDataForCsvImportQueryHookResult = ReturnType<typeof useAccountLoadDataForCsvImportQuery>;
export type AccountLoadDataForCsvImportLazyQueryHookResult = ReturnType<typeof useAccountLoadDataForCsvImportLazyQuery>;
export type AccountLoadDataForCsvImportQueryResult = Apollo.QueryResult<AccountLoadDataForCsvImportQuery, AccountLoadDataForCsvImportQueryVariables>;
export const AccountChannelDataForCsvImportDocument = gql`
    query AccountChannelDataForCsvImport($accountId: uuid!) {
  dw_Accounts_by_pk(AccountId: $accountId) {
    Label
    AccountId
  }
  dw_Channels(
    where: {Status: {_eq: 1}, Load: {Building: {Site: {AccountId: {_eq: $accountId}}}}}
  ) {
    ChannelId
    Label
    ChannelKey
    Type
    SummationType
    ValueTypeEnum
    NatureType
    IntervalFrequency
    TimeZone
    Status
    EnableAlerts
    FeedId
    LoadId
  }
  dw_Loads(
    where: {Status: {_eq: 1}, Building: {Site: {AccountId: {_eq: $accountId}}}}
  ) {
    Label
    LoadId
  }
  dw_Feeds(
    where: {Status: {_eq: 1}, Building: {Site: {AccountId: {_eq: $accountId}}}}
  ) {
    Label
    FeedId
  }
}
    `;

/**
 * __useAccountChannelDataForCsvImportQuery__
 *
 * To run a query within a React component, call `useAccountChannelDataForCsvImportQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountChannelDataForCsvImportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountChannelDataForCsvImportQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountChannelDataForCsvImportQuery(baseOptions: Apollo.QueryHookOptions<AccountChannelDataForCsvImportQuery, AccountChannelDataForCsvImportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountChannelDataForCsvImportQuery, AccountChannelDataForCsvImportQueryVariables>(AccountChannelDataForCsvImportDocument, options);
      }
export function useAccountChannelDataForCsvImportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountChannelDataForCsvImportQuery, AccountChannelDataForCsvImportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountChannelDataForCsvImportQuery, AccountChannelDataForCsvImportQueryVariables>(AccountChannelDataForCsvImportDocument, options);
        }
export type AccountChannelDataForCsvImportQueryHookResult = ReturnType<typeof useAccountChannelDataForCsvImportQuery>;
export type AccountChannelDataForCsvImportLazyQueryHookResult = ReturnType<typeof useAccountChannelDataForCsvImportLazyQuery>;
export type AccountChannelDataForCsvImportQueryResult = Apollo.QueryResult<AccountChannelDataForCsvImportQuery, AccountChannelDataForCsvImportQueryVariables>;
export const UpdateAccountAssociationsDocument = gql`
    mutation UpdateAccountAssociations($AccountId: uuid!, $ContactId: uuid, $UpdatedOn: timestamp!) {
  update_dw_Accounts_by_pk(
    pk_columns: {AccountId: $AccountId}
    _set: {PrimaryContactId: $ContactId, UpdatedOn: $UpdatedOn}
  ) {
    AccountId
    UpdatedOn
  }
}
    `;
export type UpdateAccountAssociationsMutationFn = Apollo.MutationFunction<UpdateAccountAssociationsMutation, UpdateAccountAssociationsMutationVariables>;

/**
 * __useUpdateAccountAssociationsMutation__
 *
 * To run a mutation, you first call `useUpdateAccountAssociationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountAssociationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountAssociationsMutation, { data, loading, error }] = useUpdateAccountAssociationsMutation({
 *   variables: {
 *      AccountId: // value for 'AccountId'
 *      ContactId: // value for 'ContactId'
 *      UpdatedOn: // value for 'UpdatedOn'
 *   },
 * });
 */
export function useUpdateAccountAssociationsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountAssociationsMutation, UpdateAccountAssociationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountAssociationsMutation, UpdateAccountAssociationsMutationVariables>(UpdateAccountAssociationsDocument, options);
      }
export type UpdateAccountAssociationsMutationHookResult = ReturnType<typeof useUpdateAccountAssociationsMutation>;
export type UpdateAccountAssociationsMutationResult = Apollo.MutationResult<UpdateAccountAssociationsMutation>;
export type UpdateAccountAssociationsMutationOptions = Apollo.BaseMutationOptions<UpdateAccountAssociationsMutation, UpdateAccountAssociationsMutationVariables>;
export const AccountAddressDetailsDocument = gql`
    query AccountAddressDetails($addressId: uuid!) {
  dw_AccountsAddress_by_pk(AddressId: $addressId) {
    ...AccountAddressFields
  }
}
    ${AccountAddressFieldsFragmentDoc}`;

/**
 * __useAccountAddressDetailsQuery__
 *
 * To run a query within a React component, call `useAccountAddressDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountAddressDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountAddressDetailsQuery({
 *   variables: {
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function useAccountAddressDetailsQuery(baseOptions: Apollo.QueryHookOptions<AccountAddressDetailsQuery, AccountAddressDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountAddressDetailsQuery, AccountAddressDetailsQueryVariables>(AccountAddressDetailsDocument, options);
      }
export function useAccountAddressDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountAddressDetailsQuery, AccountAddressDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountAddressDetailsQuery, AccountAddressDetailsQueryVariables>(AccountAddressDetailsDocument, options);
        }
export type AccountAddressDetailsQueryHookResult = ReturnType<typeof useAccountAddressDetailsQuery>;
export type AccountAddressDetailsLazyQueryHookResult = ReturnType<typeof useAccountAddressDetailsLazyQuery>;
export type AccountAddressDetailsQueryResult = Apollo.QueryResult<AccountAddressDetailsQuery, AccountAddressDetailsQueryVariables>;
export const AccountAddressDocument = gql`
    query AccountAddress($addressId: uuid, $accountId: uuid, $status: smallint, $search: String, $offset: Int!, $limit: Int!, $order_by: [dw_AccountsAddress_order_by!]) {
  dw_AccountsAddress(
    where: {AddressId: {_eq: $addressId}, AccountId: {_eq: $accountId}, _or: [{Composite: {_ilike: $search}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...AccountAddressFields
  }
  dw_AccountsAddress_aggregate(
    where: {AddressId: {_eq: $addressId}, AccountId: {_eq: $accountId}, _or: [{Composite: {_ilike: $search}}]}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${AccountAddressFieldsFragmentDoc}`;

/**
 * __useAccountAddressQuery__
 *
 * To run a query within a React component, call `useAccountAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountAddressQuery({
 *   variables: {
 *      addressId: // value for 'addressId'
 *      accountId: // value for 'accountId'
 *      status: // value for 'status'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useAccountAddressQuery(baseOptions: Apollo.QueryHookOptions<AccountAddressQuery, AccountAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountAddressQuery, AccountAddressQueryVariables>(AccountAddressDocument, options);
      }
export function useAccountAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountAddressQuery, AccountAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountAddressQuery, AccountAddressQueryVariables>(AccountAddressDocument, options);
        }
export type AccountAddressQueryHookResult = ReturnType<typeof useAccountAddressQuery>;
export type AccountAddressLazyQueryHookResult = ReturnType<typeof useAccountAddressLazyQuery>;
export type AccountAddressQueryResult = Apollo.QueryResult<AccountAddressQuery, AccountAddressQueryVariables>;
export const InsertAccountAddressDocument = gql`
    mutation InsertAccountAddress($object: dw_AccountsAddress_insert_input!) {
  insert_dw_AccountsAddress_one(object: $object) {
    AddressId
  }
}
    `;
export type InsertAccountAddressMutationFn = Apollo.MutationFunction<InsertAccountAddressMutation, InsertAccountAddressMutationVariables>;

/**
 * __useInsertAccountAddressMutation__
 *
 * To run a mutation, you first call `useInsertAccountAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertAccountAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertAccountAddressMutation, { data, loading, error }] = useInsertAccountAddressMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertAccountAddressMutation(baseOptions?: Apollo.MutationHookOptions<InsertAccountAddressMutation, InsertAccountAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertAccountAddressMutation, InsertAccountAddressMutationVariables>(InsertAccountAddressDocument, options);
      }
export type InsertAccountAddressMutationHookResult = ReturnType<typeof useInsertAccountAddressMutation>;
export type InsertAccountAddressMutationResult = Apollo.MutationResult<InsertAccountAddressMutation>;
export type InsertAccountAddressMutationOptions = Apollo.BaseMutationOptions<InsertAccountAddressMutation, InsertAccountAddressMutationVariables>;
export const UpdateAccountAddressDocument = gql`
    mutation UpdateAccountAddress($AddressId: uuid!, $Name: String, $Line1: String, $Line2: String, $Line3: String, $State: String, $City: String, $Country: String, $PostalCode: String, $Composite: String, $PostOfficeBox: String, $ContactName: String, $FreightTerms: String, $Fax: String, $Latitude: numeric!, $Longitude: numeric!, $Phone1: String, $Phone2: String, $Phone3: String, $ShippingMethod: String, $Type: smallint, $UpdatedOn: timestamp!) {
  update_dw_AccountsAddress_by_pk(
    pk_columns: {AddressId: $AddressId}
    _set: {Name: $Name, Line1: $Line1, Line2: $Line2, Line3: $Line3, State: $State, City: $City, Country: $Country, PostalCode: $PostalCode, Composite: $Composite, PostOfficeBox: $PostOfficeBox, ContactName: $ContactName, FreightTerms: $FreightTerms, Fax: $Fax, Latitude: $Latitude, Longitude: $Longitude, Phone1: $Phone1, Phone2: $Phone2, Phone3: $Phone3, ShippingMethod: $ShippingMethod, Type: $Type, UpdatedOn: $UpdatedOn}
  ) {
    AddressId
    UpdatedOn
  }
}
    `;
export type UpdateAccountAddressMutationFn = Apollo.MutationFunction<UpdateAccountAddressMutation, UpdateAccountAddressMutationVariables>;

/**
 * __useUpdateAccountAddressMutation__
 *
 * To run a mutation, you first call `useUpdateAccountAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountAddressMutation, { data, loading, error }] = useUpdateAccountAddressMutation({
 *   variables: {
 *      AddressId: // value for 'AddressId'
 *      Name: // value for 'Name'
 *      Line1: // value for 'Line1'
 *      Line2: // value for 'Line2'
 *      Line3: // value for 'Line3'
 *      State: // value for 'State'
 *      City: // value for 'City'
 *      Country: // value for 'Country'
 *      PostalCode: // value for 'PostalCode'
 *      Composite: // value for 'Composite'
 *      PostOfficeBox: // value for 'PostOfficeBox'
 *      ContactName: // value for 'ContactName'
 *      FreightTerms: // value for 'FreightTerms'
 *      Fax: // value for 'Fax'
 *      Latitude: // value for 'Latitude'
 *      Longitude: // value for 'Longitude'
 *      Phone1: // value for 'Phone1'
 *      Phone2: // value for 'Phone2'
 *      Phone3: // value for 'Phone3'
 *      ShippingMethod: // value for 'ShippingMethod'
 *      Type: // value for 'Type'
 *      UpdatedOn: // value for 'UpdatedOn'
 *   },
 * });
 */
export function useUpdateAccountAddressMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountAddressMutation, UpdateAccountAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountAddressMutation, UpdateAccountAddressMutationVariables>(UpdateAccountAddressDocument, options);
      }
export type UpdateAccountAddressMutationHookResult = ReturnType<typeof useUpdateAccountAddressMutation>;
export type UpdateAccountAddressMutationResult = Apollo.MutationResult<UpdateAccountAddressMutation>;
export type UpdateAccountAddressMutationOptions = Apollo.BaseMutationOptions<UpdateAccountAddressMutation, UpdateAccountAddressMutationVariables>;
export const DeleteAccountAddressDocument = gql`
    mutation DeleteAccountAddress($addressId: uuid!) {
  delete_dw_AccountsAddress_by_pk(AddressId: $addressId) {
    AddressId
  }
}
    `;
export type DeleteAccountAddressMutationFn = Apollo.MutationFunction<DeleteAccountAddressMutation, DeleteAccountAddressMutationVariables>;

/**
 * __useDeleteAccountAddressMutation__
 *
 * To run a mutation, you first call `useDeleteAccountAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountAddressMutation, { data, loading, error }] = useDeleteAccountAddressMutation({
 *   variables: {
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function useDeleteAccountAddressMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAccountAddressMutation, DeleteAccountAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAccountAddressMutation, DeleteAccountAddressMutationVariables>(DeleteAccountAddressDocument, options);
      }
export type DeleteAccountAddressMutationHookResult = ReturnType<typeof useDeleteAccountAddressMutation>;
export type DeleteAccountAddressMutationResult = Apollo.MutationResult<DeleteAccountAddressMutation>;
export type DeleteAccountAddressMutationOptions = Apollo.BaseMutationOptions<DeleteAccountAddressMutation, DeleteAccountAddressMutationVariables>;
export const AccountsSubscriptionDetailsDocument = gql`
    query AccountsSubscriptionDetails($subscriptionId: uuid!) {
  dw_AccountsSubscription_by_pk(SubscriptionId: $subscriptionId) {
    ...AccountsSubscriptionFields
  }
}
    ${AccountsSubscriptionFieldsFragmentDoc}`;

/**
 * __useAccountsSubscriptionDetailsQuery__
 *
 * To run a query within a React component, call `useAccountsSubscriptionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountsSubscriptionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountsSubscriptionDetailsQuery({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useAccountsSubscriptionDetailsQuery(baseOptions: Apollo.QueryHookOptions<AccountsSubscriptionDetailsQuery, AccountsSubscriptionDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountsSubscriptionDetailsQuery, AccountsSubscriptionDetailsQueryVariables>(AccountsSubscriptionDetailsDocument, options);
      }
export function useAccountsSubscriptionDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountsSubscriptionDetailsQuery, AccountsSubscriptionDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountsSubscriptionDetailsQuery, AccountsSubscriptionDetailsQueryVariables>(AccountsSubscriptionDetailsDocument, options);
        }
export type AccountsSubscriptionDetailsQueryHookResult = ReturnType<typeof useAccountsSubscriptionDetailsQuery>;
export type AccountsSubscriptionDetailsLazyQueryHookResult = ReturnType<typeof useAccountsSubscriptionDetailsLazyQuery>;
export type AccountsSubscriptionDetailsQueryResult = Apollo.QueryResult<AccountsSubscriptionDetailsQuery, AccountsSubscriptionDetailsQueryVariables>;
export const AccountSubscriptionsDocument = gql`
    query AccountSubscriptions($subscriptionId: uuid, $accountId: uuid, $search: String, $offset: Int!, $limit: Int!, $order_by: [dw_AccountsSubscription_order_by!]) {
  dw_AccountsSubscription(
    where: {SubscriptionId: {_eq: $subscriptionId}, AccountId: {_eq: $accountId}, _or: [{Account: {Label: {_ilike: $search}}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...AccountsSubscriptionFields
  }
  dw_AccountsSubscription_aggregate(
    where: {SubscriptionId: {_eq: $subscriptionId}, AccountId: {_eq: $accountId}, _or: [{Account: {Label: {_ilike: $search}}}]}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${AccountsSubscriptionFieldsFragmentDoc}`;

/**
 * __useAccountSubscriptionsQuery__
 *
 * To run a query within a React component, call `useAccountSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountSubscriptionsQuery({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *      accountId: // value for 'accountId'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useAccountSubscriptionsQuery(baseOptions: Apollo.QueryHookOptions<AccountSubscriptionsQuery, AccountSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountSubscriptionsQuery, AccountSubscriptionsQueryVariables>(AccountSubscriptionsDocument, options);
      }
export function useAccountSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountSubscriptionsQuery, AccountSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountSubscriptionsQuery, AccountSubscriptionsQueryVariables>(AccountSubscriptionsDocument, options);
        }
export type AccountSubscriptionsQueryHookResult = ReturnType<typeof useAccountSubscriptionsQuery>;
export type AccountSubscriptionsLazyQueryHookResult = ReturnType<typeof useAccountSubscriptionsLazyQuery>;
export type AccountSubscriptionsQueryResult = Apollo.QueryResult<AccountSubscriptionsQuery, AccountSubscriptionsQueryVariables>;
export const InsertAccountSubscriptionDocument = gql`
    mutation InsertAccountSubscription($object: dw_AccountsSubscription_insert_input!) {
  insert_dw_AccountsSubscription_one(object: $object) {
    SubscriptionId
  }
}
    `;
export type InsertAccountSubscriptionMutationFn = Apollo.MutationFunction<InsertAccountSubscriptionMutation, InsertAccountSubscriptionMutationVariables>;

/**
 * __useInsertAccountSubscriptionMutation__
 *
 * To run a mutation, you first call `useInsertAccountSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertAccountSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertAccountSubscriptionMutation, { data, loading, error }] = useInsertAccountSubscriptionMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertAccountSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<InsertAccountSubscriptionMutation, InsertAccountSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertAccountSubscriptionMutation, InsertAccountSubscriptionMutationVariables>(InsertAccountSubscriptionDocument, options);
      }
export type InsertAccountSubscriptionMutationHookResult = ReturnType<typeof useInsertAccountSubscriptionMutation>;
export type InsertAccountSubscriptionMutationResult = Apollo.MutationResult<InsertAccountSubscriptionMutation>;
export type InsertAccountSubscriptionMutationOptions = Apollo.BaseMutationOptions<InsertAccountSubscriptionMutation, InsertAccountSubscriptionMutationVariables>;
export const UpdateAccountSubscriptionDocument = gql`
    mutation UpdateAccountSubscription($SubscriptionId: uuid!, $BalanceAsAt: timestamp, $Balance: numeric, $CeasedOn: timestamp, $CommerceOn: timestamp, $PaymentCustomerRef: String, $CreatedOn: timestamp, $PlanId: uuid!, $Status: smallint, $UpdatedOn: timestamp!) {
  update_dw_AccountsSubscription_by_pk(
    pk_columns: {SubscriptionId: $SubscriptionId}
    _set: {BalanceAsAt: $BalanceAsAt, Balance: $Balance, CeasedOn: $CeasedOn, CommerceOn: $CommerceOn, PaymentCustomerRef: $PaymentCustomerRef, CreatedOn: $CreatedOn, PlanId: $PlanId, Status: $Status, SubscriptionId: $SubscriptionId, UpdatedOn: $UpdatedOn}
  ) {
    SubscriptionId
    UpdatedOn
  }
}
    `;
export type UpdateAccountSubscriptionMutationFn = Apollo.MutationFunction<UpdateAccountSubscriptionMutation, UpdateAccountSubscriptionMutationVariables>;

/**
 * __useUpdateAccountSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateAccountSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountSubscriptionMutation, { data, loading, error }] = useUpdateAccountSubscriptionMutation({
 *   variables: {
 *      SubscriptionId: // value for 'SubscriptionId'
 *      BalanceAsAt: // value for 'BalanceAsAt'
 *      Balance: // value for 'Balance'
 *      CeasedOn: // value for 'CeasedOn'
 *      CommerceOn: // value for 'CommerceOn'
 *      PaymentCustomerRef: // value for 'PaymentCustomerRef'
 *      CreatedOn: // value for 'CreatedOn'
 *      PlanId: // value for 'PlanId'
 *      Status: // value for 'Status'
 *      UpdatedOn: // value for 'UpdatedOn'
 *   },
 * });
 */
export function useUpdateAccountSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountSubscriptionMutation, UpdateAccountSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountSubscriptionMutation, UpdateAccountSubscriptionMutationVariables>(UpdateAccountSubscriptionDocument, options);
      }
export type UpdateAccountSubscriptionMutationHookResult = ReturnType<typeof useUpdateAccountSubscriptionMutation>;
export type UpdateAccountSubscriptionMutationResult = Apollo.MutationResult<UpdateAccountSubscriptionMutation>;
export type UpdateAccountSubscriptionMutationOptions = Apollo.BaseMutationOptions<UpdateAccountSubscriptionMutation, UpdateAccountSubscriptionMutationVariables>;
export const DeleteAccountSubscriptionDocument = gql`
    mutation DeleteAccountSubscription($subscriptionId: uuid!) {
  delete_dw_AccountsSubscription_by_pk(SubscriptionId: $subscriptionId) {
    SubscriptionId
  }
}
    `;
export type DeleteAccountSubscriptionMutationFn = Apollo.MutationFunction<DeleteAccountSubscriptionMutation, DeleteAccountSubscriptionMutationVariables>;

/**
 * __useDeleteAccountSubscriptionMutation__
 *
 * To run a mutation, you first call `useDeleteAccountSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountSubscriptionMutation, { data, loading, error }] = useDeleteAccountSubscriptionMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useDeleteAccountSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAccountSubscriptionMutation, DeleteAccountSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAccountSubscriptionMutation, DeleteAccountSubscriptionMutationVariables>(DeleteAccountSubscriptionDocument, options);
      }
export type DeleteAccountSubscriptionMutationHookResult = ReturnType<typeof useDeleteAccountSubscriptionMutation>;
export type DeleteAccountSubscriptionMutationResult = Apollo.MutationResult<DeleteAccountSubscriptionMutation>;
export type DeleteAccountSubscriptionMutationOptions = Apollo.BaseMutationOptions<DeleteAccountSubscriptionMutation, DeleteAccountSubscriptionMutationVariables>;
export const PlanSearchDocument = gql`
    query PlanSearch($search: String) {
  dw_Plans(
    where: {_and: {Label: {_ilike: $search}}}
    limit: 30
    order_by: {Label: asc}
  ) {
    PlanId
    Label
  }
}
    `;

/**
 * __usePlanSearchQuery__
 *
 * To run a query within a React component, call `usePlanSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanSearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function usePlanSearchQuery(baseOptions?: Apollo.QueryHookOptions<PlanSearchQuery, PlanSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanSearchQuery, PlanSearchQueryVariables>(PlanSearchDocument, options);
      }
export function usePlanSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanSearchQuery, PlanSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanSearchQuery, PlanSearchQueryVariables>(PlanSearchDocument, options);
        }
export type PlanSearchQueryHookResult = ReturnType<typeof usePlanSearchQuery>;
export type PlanSearchLazyQueryHookResult = ReturnType<typeof usePlanSearchLazyQuery>;
export type PlanSearchQueryResult = Apollo.QueryResult<PlanSearchQuery, PlanSearchQueryVariables>;
export const BuildingSearchDocument = gql`
    query BuildingSearch($SiteId: uuid, $search: String) {
  dw_Buildings(
    where: {SiteId: {_eq: $SiteId}, Status: {_eq: 1}, _and: {Label: {_ilike: $search}}}
    limit: 30
    order_by: {Label: asc}
  ) {
    BuildingId
    Label
  }
}
    `;

/**
 * __useBuildingSearchQuery__
 *
 * To run a query within a React component, call `useBuildingSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildingSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildingSearchQuery({
 *   variables: {
 *      SiteId: // value for 'SiteId'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useBuildingSearchQuery(baseOptions?: Apollo.QueryHookOptions<BuildingSearchQuery, BuildingSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BuildingSearchQuery, BuildingSearchQueryVariables>(BuildingSearchDocument, options);
      }
export function useBuildingSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuildingSearchQuery, BuildingSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BuildingSearchQuery, BuildingSearchQueryVariables>(BuildingSearchDocument, options);
        }
export type BuildingSearchQueryHookResult = ReturnType<typeof useBuildingSearchQuery>;
export type BuildingSearchLazyQueryHookResult = ReturnType<typeof useBuildingSearchLazyQuery>;
export type BuildingSearchQueryResult = Apollo.QueryResult<BuildingSearchQuery, BuildingSearchQueryVariables>;
export const BuildingCorrelationDataDocument = gql`
    query BuildingCorrelationData($buildingId: uuid) {
  dw_Loads(where: {BuildingId: {_eq: $buildingId}, Status: {_eq: 1}}) {
    LoadId
    Label
    Channels(
      where: {Status: {_eq: 1}, UnitOfMeasure: {_in: ["kWh", "Water m3", "Gas kWh", "Count", "kgs", "Meters", "Tonnes", "Hours"]}}
    ) {
      ChannelId
      Label
      UnitOfMeasure
    }
  }
}
    `;

/**
 * __useBuildingCorrelationDataQuery__
 *
 * To run a query within a React component, call `useBuildingCorrelationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildingCorrelationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildingCorrelationDataQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *   },
 * });
 */
export function useBuildingCorrelationDataQuery(baseOptions?: Apollo.QueryHookOptions<BuildingCorrelationDataQuery, BuildingCorrelationDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BuildingCorrelationDataQuery, BuildingCorrelationDataQueryVariables>(BuildingCorrelationDataDocument, options);
      }
export function useBuildingCorrelationDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuildingCorrelationDataQuery, BuildingCorrelationDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BuildingCorrelationDataQuery, BuildingCorrelationDataQueryVariables>(BuildingCorrelationDataDocument, options);
        }
export type BuildingCorrelationDataQueryHookResult = ReturnType<typeof useBuildingCorrelationDataQuery>;
export type BuildingCorrelationDataLazyQueryHookResult = ReturnType<typeof useBuildingCorrelationDataLazyQuery>;
export type BuildingCorrelationDataQueryResult = Apollo.QueryResult<BuildingCorrelationDataQuery, BuildingCorrelationDataQueryVariables>;
export const BuildingDetailsDocument = gql`
    query BuildingDetails($buildingId: uuid!) {
  dw_Buildings_by_pk(BuildingId: $buildingId) {
    ...BuildingFields
    BuildingDocuments {
      DocumentId
      FileName
      Status
      UploadDate
    }
  }
}
    ${BuildingFieldsFragmentDoc}`;

/**
 * __useBuildingDetailsQuery__
 *
 * To run a query within a React component, call `useBuildingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildingDetailsQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *   },
 * });
 */
export function useBuildingDetailsQuery(baseOptions: Apollo.QueryHookOptions<BuildingDetailsQuery, BuildingDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BuildingDetailsQuery, BuildingDetailsQueryVariables>(BuildingDetailsDocument, options);
      }
export function useBuildingDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuildingDetailsQuery, BuildingDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BuildingDetailsQuery, BuildingDetailsQueryVariables>(BuildingDetailsDocument, options);
        }
export type BuildingDetailsQueryHookResult = ReturnType<typeof useBuildingDetailsQuery>;
export type BuildingDetailsLazyQueryHookResult = ReturnType<typeof useBuildingDetailsLazyQuery>;
export type BuildingDetailsQueryResult = Apollo.QueryResult<BuildingDetailsQuery, BuildingDetailsQueryVariables>;
export const BuildingsDocument = gql`
    query Buildings($buildingId: uuid, $siteId: uuid, $accountId: uuid, $status: smallint, $search: String, $offset: Int!, $limit: Int!, $order_by: [dw_Buildings_order_by!]) {
  dw_Buildings(
    where: {BuildingId: {_eq: $buildingId}, SiteId: {_eq: $siteId}, Site: {AccountId: {_eq: $accountId}}, Status: {_eq: $status}, _or: [{Label: {_ilike: $search}}, {Site: {Label: {_ilike: $search}}}, {Site: {Account: {Label: {_ilike: $search}}}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...BuildingFields
  }
  dw_Buildings_aggregate(
    where: {BuildingId: {_eq: $buildingId}, SiteId: {_eq: $siteId}, Site: {AccountId: {_eq: $accountId}}, Status: {_eq: $status}, _or: [{Label: {_ilike: $search}}, {Site: {Label: {_ilike: $search}}}, {Site: {Account: {Label: {_ilike: $search}}}}]}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${BuildingFieldsFragmentDoc}`;

/**
 * __useBuildingsQuery__
 *
 * To run a query within a React component, call `useBuildingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuildingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuildingsQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *      siteId: // value for 'siteId'
 *      accountId: // value for 'accountId'
 *      status: // value for 'status'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useBuildingsQuery(baseOptions: Apollo.QueryHookOptions<BuildingsQuery, BuildingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BuildingsQuery, BuildingsQueryVariables>(BuildingsDocument, options);
      }
export function useBuildingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuildingsQuery, BuildingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BuildingsQuery, BuildingsQueryVariables>(BuildingsDocument, options);
        }
export type BuildingsQueryHookResult = ReturnType<typeof useBuildingsQuery>;
export type BuildingsLazyQueryHookResult = ReturnType<typeof useBuildingsLazyQuery>;
export type BuildingsQueryResult = Apollo.QueryResult<BuildingsQuery, BuildingsQueryVariables>;
export const InsertBuildingDocument = gql`
    mutation InsertBuilding($object: dw_Buildings_insert_input!) {
  insert_dw_Buildings_one(object: $object) {
    BuildingId
  }
}
    `;
export type InsertBuildingMutationFn = Apollo.MutationFunction<InsertBuildingMutation, InsertBuildingMutationVariables>;

/**
 * __useInsertBuildingMutation__
 *
 * To run a mutation, you first call `useInsertBuildingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertBuildingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertBuildingMutation, { data, loading, error }] = useInsertBuildingMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertBuildingMutation(baseOptions?: Apollo.MutationHookOptions<InsertBuildingMutation, InsertBuildingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertBuildingMutation, InsertBuildingMutationVariables>(InsertBuildingDocument, options);
      }
export type InsertBuildingMutationHookResult = ReturnType<typeof useInsertBuildingMutation>;
export type InsertBuildingMutationResult = Apollo.MutationResult<InsertBuildingMutation>;
export type InsertBuildingMutationOptions = Apollo.BaseMutationOptions<InsertBuildingMutation, InsertBuildingMutationVariables>;
export const UpdateBuildingsDocument = gql`
    mutation UpdateBuildings($BuildingId: uuid!, $Label: String!, $Status: smallint!, $NlaSqm: numeric, $GfaSqm: numeric, $FunctionalUse: Int, $HeatingSystem: Int, $CoolingSystem: Int, $Location: String, $WeatherId: uuid, $IsicCode: String, $RatingType: Int, $RatingValue: Int, $RatingExpiry: timestamp, $UpdatedOn: timestamp!) {
  update_dw_Buildings_by_pk(
    pk_columns: {BuildingId: $BuildingId}
    _set: {Label: $Label, Status: $Status, NlaSqm: $NlaSqm, GfaSqm: $GfaSqm, FunctionalUse: $FunctionalUse, HeatingSystem: $HeatingSystem, CoolingSystem: $CoolingSystem, Location: $Location, WeatherId: $WeatherId, IsicCode: $IsicCode, RatingType: $RatingType, RatingValue: $RatingValue, RatingExpiry: $RatingExpiry, UpdatedOn: $UpdatedOn}
  ) {
    BuildingId
    UpdatedOn
  }
}
    `;
export type UpdateBuildingsMutationFn = Apollo.MutationFunction<UpdateBuildingsMutation, UpdateBuildingsMutationVariables>;

/**
 * __useUpdateBuildingsMutation__
 *
 * To run a mutation, you first call `useUpdateBuildingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBuildingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBuildingsMutation, { data, loading, error }] = useUpdateBuildingsMutation({
 *   variables: {
 *      BuildingId: // value for 'BuildingId'
 *      Label: // value for 'Label'
 *      Status: // value for 'Status'
 *      NlaSqm: // value for 'NlaSqm'
 *      GfaSqm: // value for 'GfaSqm'
 *      FunctionalUse: // value for 'FunctionalUse'
 *      HeatingSystem: // value for 'HeatingSystem'
 *      CoolingSystem: // value for 'CoolingSystem'
 *      Location: // value for 'Location'
 *      WeatherId: // value for 'WeatherId'
 *      IsicCode: // value for 'IsicCode'
 *      RatingType: // value for 'RatingType'
 *      RatingValue: // value for 'RatingValue'
 *      RatingExpiry: // value for 'RatingExpiry'
 *      UpdatedOn: // value for 'UpdatedOn'
 *   },
 * });
 */
export function useUpdateBuildingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBuildingsMutation, UpdateBuildingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBuildingsMutation, UpdateBuildingsMutationVariables>(UpdateBuildingsDocument, options);
      }
export type UpdateBuildingsMutationHookResult = ReturnType<typeof useUpdateBuildingsMutation>;
export type UpdateBuildingsMutationResult = Apollo.MutationResult<UpdateBuildingsMutation>;
export type UpdateBuildingsMutationOptions = Apollo.BaseMutationOptions<UpdateBuildingsMutation, UpdateBuildingsMutationVariables>;
export const UpdateBuildingAssociationsDocument = gql`
    mutation UpdateBuildingAssociations($BuildingId: uuid!, $SiteId: uuid, $UpdatedOn: timestamp!) {
  update_dw_Buildings_by_pk(
    pk_columns: {BuildingId: $BuildingId}
    _set: {SiteId: $SiteId, UpdatedOn: $UpdatedOn}
  ) {
    BuildingId
    UpdatedOn
  }
}
    `;
export type UpdateBuildingAssociationsMutationFn = Apollo.MutationFunction<UpdateBuildingAssociationsMutation, UpdateBuildingAssociationsMutationVariables>;

/**
 * __useUpdateBuildingAssociationsMutation__
 *
 * To run a mutation, you first call `useUpdateBuildingAssociationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBuildingAssociationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBuildingAssociationsMutation, { data, loading, error }] = useUpdateBuildingAssociationsMutation({
 *   variables: {
 *      BuildingId: // value for 'BuildingId'
 *      SiteId: // value for 'SiteId'
 *      UpdatedOn: // value for 'UpdatedOn'
 *   },
 * });
 */
export function useUpdateBuildingAssociationsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBuildingAssociationsMutation, UpdateBuildingAssociationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBuildingAssociationsMutation, UpdateBuildingAssociationsMutationVariables>(UpdateBuildingAssociationsDocument, options);
      }
export type UpdateBuildingAssociationsMutationHookResult = ReturnType<typeof useUpdateBuildingAssociationsMutation>;
export type UpdateBuildingAssociationsMutationResult = Apollo.MutationResult<UpdateBuildingAssociationsMutation>;
export type UpdateBuildingAssociationsMutationOptions = Apollo.BaseMutationOptions<UpdateBuildingAssociationsMutation, UpdateBuildingAssociationsMutationVariables>;
export const LoggersOfflineDocument = gql`
    query LoggersOffline($LatestData: timestamp) {
  dw_ChannelsMetadata(
    where: {LatestData: {_gte: $LatestData}, Channel: {Status: {_eq: 1}, Load: {Status: {_eq: 1}, Feed: {Status: {_eq: 1}}}}}
    order_by: {LatestData: desc}
  ) {
    LatestData
    Channel {
      ChannelId
      ChannelKey
      Label
      Load {
        LoadId
        Label
        Feed {
          FeedId
          Label
          Notes
          FeedsType {
            TypeId
            Label
          }
        }
      }
    }
  }
}
    `;

/**
 * __useLoggersOfflineQuery__
 *
 * To run a query within a React component, call `useLoggersOfflineQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggersOfflineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggersOfflineQuery({
 *   variables: {
 *      LatestData: // value for 'LatestData'
 *   },
 * });
 */
export function useLoggersOfflineQuery(baseOptions?: Apollo.QueryHookOptions<LoggersOfflineQuery, LoggersOfflineQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoggersOfflineQuery, LoggersOfflineQueryVariables>(LoggersOfflineDocument, options);
      }
export function useLoggersOfflineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoggersOfflineQuery, LoggersOfflineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoggersOfflineQuery, LoggersOfflineQueryVariables>(LoggersOfflineDocument, options);
        }
export type LoggersOfflineQueryHookResult = ReturnType<typeof useLoggersOfflineQuery>;
export type LoggersOfflineLazyQueryHookResult = ReturnType<typeof useLoggersOfflineLazyQuery>;
export type LoggersOfflineQueryResult = Apollo.QueryResult<LoggersOfflineQuery, LoggersOfflineQueryVariables>;
export const ChannelSearchDocument = gql`
    query ChannelSearch($AccountId: uuid, $SiteId: uuid, $BuildingId: uuid, $LoadId: uuid, $FeedId: uuid, $search: String) {
  dw_Channels(
    where: {FeedId: {_eq: $FeedId}, LoadId: {_eq: $LoadId}, Load: {Building: {BuildingId: {_eq: $BuildingId}, Site: {SiteId: {_eq: $SiteId}, Account: {AccountId: {_eq: $AccountId}}}}}, Status: {_eq: 1}, _or: [{Label: {_ilike: $search}}, {ChannelKey: {_ilike: $search}}]}
    limit: 30
    order_by: {Label: asc}
  ) {
    ChannelId
    ChannelKey
    Label
    Type
    Load {
      Label
    }
  }
}
    `;

/**
 * __useChannelSearchQuery__
 *
 * To run a query within a React component, call `useChannelSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelSearchQuery({
 *   variables: {
 *      AccountId: // value for 'AccountId'
 *      SiteId: // value for 'SiteId'
 *      BuildingId: // value for 'BuildingId'
 *      LoadId: // value for 'LoadId'
 *      FeedId: // value for 'FeedId'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useChannelSearchQuery(baseOptions?: Apollo.QueryHookOptions<ChannelSearchQuery, ChannelSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChannelSearchQuery, ChannelSearchQueryVariables>(ChannelSearchDocument, options);
      }
export function useChannelSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChannelSearchQuery, ChannelSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChannelSearchQuery, ChannelSearchQueryVariables>(ChannelSearchDocument, options);
        }
export type ChannelSearchQueryHookResult = ReturnType<typeof useChannelSearchQuery>;
export type ChannelSearchLazyQueryHookResult = ReturnType<typeof useChannelSearchLazyQuery>;
export type ChannelSearchQueryResult = Apollo.QueryResult<ChannelSearchQuery, ChannelSearchQueryVariables>;
export const ChannelKeySearchDocument = gql`
    query ChannelKeySearch($ChannelKey: String) {
  dw_Channels(where: {ChannelKey: {_eq: $ChannelKey}}) {
    ChannelId
    ChannelKey
  }
}
    `;

/**
 * __useChannelKeySearchQuery__
 *
 * To run a query within a React component, call `useChannelKeySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelKeySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelKeySearchQuery({
 *   variables: {
 *      ChannelKey: // value for 'ChannelKey'
 *   },
 * });
 */
export function useChannelKeySearchQuery(baseOptions?: Apollo.QueryHookOptions<ChannelKeySearchQuery, ChannelKeySearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChannelKeySearchQuery, ChannelKeySearchQueryVariables>(ChannelKeySearchDocument, options);
      }
export function useChannelKeySearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChannelKeySearchQuery, ChannelKeySearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChannelKeySearchQuery, ChannelKeySearchQueryVariables>(ChannelKeySearchDocument, options);
        }
export type ChannelKeySearchQueryHookResult = ReturnType<typeof useChannelKeySearchQuery>;
export type ChannelKeySearchLazyQueryHookResult = ReturnType<typeof useChannelKeySearchLazyQuery>;
export type ChannelKeySearchQueryResult = Apollo.QueryResult<ChannelKeySearchQuery, ChannelKeySearchQueryVariables>;
export const ChannelDetailsDocument = gql`
    query ChannelDetails($channelId: uuid!) {
  dw_Channels_by_pk(ChannelId: $channelId) {
    ...ChannelFields
  }
}
    ${ChannelFieldsFragmentDoc}`;

/**
 * __useChannelDetailsQuery__
 *
 * To run a query within a React component, call `useChannelDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelDetailsQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useChannelDetailsQuery(baseOptions: Apollo.QueryHookOptions<ChannelDetailsQuery, ChannelDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChannelDetailsQuery, ChannelDetailsQueryVariables>(ChannelDetailsDocument, options);
      }
export function useChannelDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChannelDetailsQuery, ChannelDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChannelDetailsQuery, ChannelDetailsQueryVariables>(ChannelDetailsDocument, options);
        }
export type ChannelDetailsQueryHookResult = ReturnType<typeof useChannelDetailsQuery>;
export type ChannelDetailsLazyQueryHookResult = ReturnType<typeof useChannelDetailsLazyQuery>;
export type ChannelDetailsQueryResult = Apollo.QueryResult<ChannelDetailsQuery, ChannelDetailsQueryVariables>;
export const ChannelsDocument = gql`
    query Channels($channelId: uuid, $loadId: uuid, $feedId: uuid, $feedIds: [uuid!], $buildingId: uuid, $siteId: uuid, $accountId: uuid, $status: smallint, $latestDateDateStart: timestamp, $latestDateDateEnd: timestamp, $search: String, $offset: Int!, $limit: Int!, $order_by: [dw_Channels_order_by!]) {
  dw_Channels(
    where: {ChannelId: {_eq: $channelId}, LoadId: {_eq: $loadId}, FeedId: {_eq: $feedId, _in: $feedIds}, Status: {_eq: $status}, Load: {Building: {BuildingId: {_eq: $buildingId}, SiteId: {_eq: $siteId}, Site: {Account: {AccountId: {_eq: $accountId}}}}}, _or: [{Label: {_ilike: $search}}, {ChannelKey: {_ilike: $search}}, {Feed: {Label: {_ilike: $search}}}, {Feed: {SerialNumber: {_ilike: $search}}}, {MeterType: {_ilike: $search}}, {UnitOfMeasure: {_ilike: $search}}, {Load: {Label: {_ilike: $search}}}, {Load: {Building: {Label: {_ilike: $search}}}}, {Building: {Site: {Label: {_ilike: $search}}}}, {Building: {Site: {Account: {Label: {_ilike: $search}}}}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...ExportFields
  }
  dw_Channels_aggregate(
    where: {ChannelId: {_eq: $channelId}, LoadId: {_eq: $loadId}, FeedId: {_eq: $feedId, _in: $feedIds}, Status: {_eq: $status}, Load: {Building: {BuildingId: {_eq: $buildingId}, SiteId: {_eq: $siteId}, Site: {Account: {AccountId: {_eq: $accountId}}}}}, _or: [{Label: {_ilike: $search}}, {ChannelKey: {_ilike: $search}}, {Feed: {Label: {_ilike: $search}}}, {Feed: {SerialNumber: {_ilike: $search}}}, {MeterType: {_ilike: $search}}, {UnitOfMeasure: {_ilike: $search}}, {Load: {Label: {_ilike: $search}}}, {Load: {Building: {Label: {_ilike: $search}}}}, {Building: {Site: {Label: {_ilike: $search}}}}, {Building: {Site: {Account: {Label: {_ilike: $search}}}}}]}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${ExportFieldsFragmentDoc}`;

/**
 * __useChannelsQuery__
 *
 * To run a query within a React component, call `useChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelsQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      loadId: // value for 'loadId'
 *      feedId: // value for 'feedId'
 *      feedIds: // value for 'feedIds'
 *      buildingId: // value for 'buildingId'
 *      siteId: // value for 'siteId'
 *      accountId: // value for 'accountId'
 *      status: // value for 'status'
 *      latestDateDateStart: // value for 'latestDateDateStart'
 *      latestDateDateEnd: // value for 'latestDateDateEnd'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useChannelsQuery(baseOptions: Apollo.QueryHookOptions<ChannelsQuery, ChannelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChannelsQuery, ChannelsQueryVariables>(ChannelsDocument, options);
      }
export function useChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChannelsQuery, ChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChannelsQuery, ChannelsQueryVariables>(ChannelsDocument, options);
        }
export type ChannelsQueryHookResult = ReturnType<typeof useChannelsQuery>;
export type ChannelsLazyQueryHookResult = ReturnType<typeof useChannelsLazyQuery>;
export type ChannelsQueryResult = Apollo.QueryResult<ChannelsQuery, ChannelsQueryVariables>;
export const InsertChannelDocument = gql`
    mutation InsertChannel($object: dw_Channels_insert_input!) {
  insert_dw_Channels_one(object: $object) {
    ChannelId
  }
}
    `;
export type InsertChannelMutationFn = Apollo.MutationFunction<InsertChannelMutation, InsertChannelMutationVariables>;

/**
 * __useInsertChannelMutation__
 *
 * To run a mutation, you first call `useInsertChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertChannelMutation, { data, loading, error }] = useInsertChannelMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertChannelMutation(baseOptions?: Apollo.MutationHookOptions<InsertChannelMutation, InsertChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertChannelMutation, InsertChannelMutationVariables>(InsertChannelDocument, options);
      }
export type InsertChannelMutationHookResult = ReturnType<typeof useInsertChannelMutation>;
export type InsertChannelMutationResult = Apollo.MutationResult<InsertChannelMutation>;
export type InsertChannelMutationOptions = Apollo.BaseMutationOptions<InsertChannelMutation, InsertChannelMutationVariables>;
export const UpdateChannelsDocument = gql`
    mutation UpdateChannels($ChannelId: uuid!, $ChannelKey: String, $Label: String!, $MeterType: String, $Type: smallint, $SummationType: smallint, $ValueTypeEnum: smallint, $Formula: String, $IntervalFrequency: String, $IntervalMins: Int, $Level: String, $Parameter: String, $Reference: String, $TimeZone: String, $UnitOfMeasure: String, $EnableAlerts: Boolean, $StartDate: timestamp, $Status: smallint!, $UpdatedOn: timestamp!, $NatureType: smallint!) {
  update_dw_Channels_by_pk(
    pk_columns: {ChannelId: $ChannelId}
    _set: {ChannelKey: $ChannelKey, Label: $Label, MeterType: $MeterType, Type: $Type, SummationType: $SummationType, ValueTypeEnum: $ValueTypeEnum, Formula: $Formula, IntervalFrequency: $IntervalFrequency, IntervalMins: $IntervalMins, Parameter: $Parameter, Reference: $Reference, TimeZone: $TimeZone, UnitOfMeasure: $UnitOfMeasure, EnableAlerts: $EnableAlerts, StartDate: $StartDate, Status: $Status, UpdatedOn: $UpdatedOn, NatureType: $NatureType}
  ) {
    ChannelId
  }
}
    `;
export type UpdateChannelsMutationFn = Apollo.MutationFunction<UpdateChannelsMutation, UpdateChannelsMutationVariables>;

/**
 * __useUpdateChannelsMutation__
 *
 * To run a mutation, you first call `useUpdateChannelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelsMutation, { data, loading, error }] = useUpdateChannelsMutation({
 *   variables: {
 *      ChannelId: // value for 'ChannelId'
 *      ChannelKey: // value for 'ChannelKey'
 *      Label: // value for 'Label'
 *      MeterType: // value for 'MeterType'
 *      Type: // value for 'Type'
 *      SummationType: // value for 'SummationType'
 *      ValueTypeEnum: // value for 'ValueTypeEnum'
 *      Formula: // value for 'Formula'
 *      IntervalFrequency: // value for 'IntervalFrequency'
 *      IntervalMins: // value for 'IntervalMins'
 *      Level: // value for 'Level'
 *      Parameter: // value for 'Parameter'
 *      Reference: // value for 'Reference'
 *      TimeZone: // value for 'TimeZone'
 *      UnitOfMeasure: // value for 'UnitOfMeasure'
 *      EnableAlerts: // value for 'EnableAlerts'
 *      StartDate: // value for 'StartDate'
 *      Status: // value for 'Status'
 *      UpdatedOn: // value for 'UpdatedOn'
 *      NatureType: // value for 'NatureType'
 *   },
 * });
 */
export function useUpdateChannelsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChannelsMutation, UpdateChannelsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChannelsMutation, UpdateChannelsMutationVariables>(UpdateChannelsDocument, options);
      }
export type UpdateChannelsMutationHookResult = ReturnType<typeof useUpdateChannelsMutation>;
export type UpdateChannelsMutationResult = Apollo.MutationResult<UpdateChannelsMutation>;
export type UpdateChannelsMutationOptions = Apollo.BaseMutationOptions<UpdateChannelsMutation, UpdateChannelsMutationVariables>;
export const UpdateChannelAssociationsDocument = gql`
    mutation UpdateChannelAssociations($ChannelId: uuid!, $LoadId: uuid, $FeedId: uuid, $UpdatedOn: timestamp!) {
  update_dw_Channels_by_pk(
    pk_columns: {ChannelId: $ChannelId}
    _set: {LoadId: $LoadId, FeedId: $FeedId, UpdatedOn: $UpdatedOn}
  ) {
    ChannelId
    UpdatedOn
  }
}
    `;
export type UpdateChannelAssociationsMutationFn = Apollo.MutationFunction<UpdateChannelAssociationsMutation, UpdateChannelAssociationsMutationVariables>;

/**
 * __useUpdateChannelAssociationsMutation__
 *
 * To run a mutation, you first call `useUpdateChannelAssociationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelAssociationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelAssociationsMutation, { data, loading, error }] = useUpdateChannelAssociationsMutation({
 *   variables: {
 *      ChannelId: // value for 'ChannelId'
 *      LoadId: // value for 'LoadId'
 *      FeedId: // value for 'FeedId'
 *      UpdatedOn: // value for 'UpdatedOn'
 *   },
 * });
 */
export function useUpdateChannelAssociationsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChannelAssociationsMutation, UpdateChannelAssociationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChannelAssociationsMutation, UpdateChannelAssociationsMutationVariables>(UpdateChannelAssociationsDocument, options);
      }
export type UpdateChannelAssociationsMutationHookResult = ReturnType<typeof useUpdateChannelAssociationsMutation>;
export type UpdateChannelAssociationsMutationResult = Apollo.MutationResult<UpdateChannelAssociationsMutation>;
export type UpdateChannelAssociationsMutationOptions = Apollo.BaseMutationOptions<UpdateChannelAssociationsMutation, UpdateChannelAssociationsMutationVariables>;
export const BulkChangeChannelsFeedDocument = gql`
    mutation BulkChangeChannelsFeed($FeedId: uuid!, $ChannelIds: [uuid!]!, $UpdatedOn: timestamp!) {
  update_dw_Channels(
    where: {ChannelId: {_in: $ChannelIds}}
    _set: {FeedId: $FeedId, UpdatedOn: $UpdatedOn}
  ) {
    affected_rows
  }
}
    `;
export type BulkChangeChannelsFeedMutationFn = Apollo.MutationFunction<BulkChangeChannelsFeedMutation, BulkChangeChannelsFeedMutationVariables>;

/**
 * __useBulkChangeChannelsFeedMutation__
 *
 * To run a mutation, you first call `useBulkChangeChannelsFeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkChangeChannelsFeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkChangeChannelsFeedMutation, { data, loading, error }] = useBulkChangeChannelsFeedMutation({
 *   variables: {
 *      FeedId: // value for 'FeedId'
 *      ChannelIds: // value for 'ChannelIds'
 *      UpdatedOn: // value for 'UpdatedOn'
 *   },
 * });
 */
export function useBulkChangeChannelsFeedMutation(baseOptions?: Apollo.MutationHookOptions<BulkChangeChannelsFeedMutation, BulkChangeChannelsFeedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkChangeChannelsFeedMutation, BulkChangeChannelsFeedMutationVariables>(BulkChangeChannelsFeedDocument, options);
      }
export type BulkChangeChannelsFeedMutationHookResult = ReturnType<typeof useBulkChangeChannelsFeedMutation>;
export type BulkChangeChannelsFeedMutationResult = Apollo.MutationResult<BulkChangeChannelsFeedMutation>;
export type BulkChangeChannelsFeedMutationOptions = Apollo.BaseMutationOptions<BulkChangeChannelsFeedMutation, BulkChangeChannelsFeedMutationVariables>;
export const BulkInsertLoadChannelsDocument = gql`
    mutation BulkInsertLoadChannels($objects: [dw_Channels_insert_input!]!) {
  insert_dw_Channels(objects: $objects) {
    affected_rows
  }
}
    `;
export type BulkInsertLoadChannelsMutationFn = Apollo.MutationFunction<BulkInsertLoadChannelsMutation, BulkInsertLoadChannelsMutationVariables>;

/**
 * __useBulkInsertLoadChannelsMutation__
 *
 * To run a mutation, you first call `useBulkInsertLoadChannelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkInsertLoadChannelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkInsertLoadChannelsMutation, { data, loading, error }] = useBulkInsertLoadChannelsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useBulkInsertLoadChannelsMutation(baseOptions?: Apollo.MutationHookOptions<BulkInsertLoadChannelsMutation, BulkInsertLoadChannelsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkInsertLoadChannelsMutation, BulkInsertLoadChannelsMutationVariables>(BulkInsertLoadChannelsDocument, options);
      }
export type BulkInsertLoadChannelsMutationHookResult = ReturnType<typeof useBulkInsertLoadChannelsMutation>;
export type BulkInsertLoadChannelsMutationResult = Apollo.MutationResult<BulkInsertLoadChannelsMutation>;
export type BulkInsertLoadChannelsMutationOptions = Apollo.BaseMutationOptions<BulkInsertLoadChannelsMutation, BulkInsertLoadChannelsMutationVariables>;
export const UpdateChannelFormulaDocument = gql`
    mutation UpdateChannelFormula($ChannelId: uuid!, $Formula: String, $UpdatedOn: timestamp!) {
  update_dw_Channels_by_pk(
    pk_columns: {ChannelId: $ChannelId}
    _set: {Formula: $Formula, UpdatedOn: $UpdatedOn}
  ) {
    ChannelId
  }
}
    `;
export type UpdateChannelFormulaMutationFn = Apollo.MutationFunction<UpdateChannelFormulaMutation, UpdateChannelFormulaMutationVariables>;

/**
 * __useUpdateChannelFormulaMutation__
 *
 * To run a mutation, you first call `useUpdateChannelFormulaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelFormulaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelFormulaMutation, { data, loading, error }] = useUpdateChannelFormulaMutation({
 *   variables: {
 *      ChannelId: // value for 'ChannelId'
 *      Formula: // value for 'Formula'
 *      UpdatedOn: // value for 'UpdatedOn'
 *   },
 * });
 */
export function useUpdateChannelFormulaMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChannelFormulaMutation, UpdateChannelFormulaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChannelFormulaMutation, UpdateChannelFormulaMutationVariables>(UpdateChannelFormulaDocument, options);
      }
export type UpdateChannelFormulaMutationHookResult = ReturnType<typeof useUpdateChannelFormulaMutation>;
export type UpdateChannelFormulaMutationResult = Apollo.MutationResult<UpdateChannelFormulaMutation>;
export type UpdateChannelFormulaMutationOptions = Apollo.BaseMutationOptions<UpdateChannelFormulaMutation, UpdateChannelFormulaMutationVariables>;
export const ChannelsByMetadataDateDocument = gql`
    query ChannelsByMetadataDate($channelId: uuid, $loadId: uuid, $feedId: uuid, $feedIds: [uuid!], $buildingId: uuid, $siteId: uuid, $accountId: uuid, $status: smallint, $latestDateDateStart: timestamp, $latestDateDateEnd: timestamp, $search: String, $offset: Int!, $limit: Int!, $order_by: [dw_Channels_order_by!]) {
  dw_Channels(
    where: {ChannelId: {_eq: $channelId}, LoadId: {_eq: $loadId}, FeedId: {_eq: $feedId, _in: $feedIds}, Status: {_eq: $status}, ChannelsMetadata: {LatestData: {_gte: $latestDateDateStart, _lte: $latestDateDateEnd}}, Load: {Building: {BuildingId: {_eq: $buildingId}, SiteId: {_eq: $siteId}, Site: {Account: {AccountId: {_eq: $accountId}}}}}, _or: [{Label: {_ilike: $search}}, {ChannelKey: {_ilike: $search}}, {Feed: {Label: {_ilike: $search}}}, {Feed: {SerialNumber: {_ilike: $search}}}, {MeterType: {_ilike: $search}}, {UnitOfMeasure: {_ilike: $search}}, {Load: {Label: {_ilike: $search}}}, {Load: {Building: {Label: {_ilike: $search}}}}, {Building: {Site: {Label: {_ilike: $search}}}}, {Building: {Site: {Account: {Label: {_ilike: $search}}}}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...ExportFields
  }
  dw_Channels_aggregate(
    where: {ChannelId: {_eq: $channelId}, LoadId: {_eq: $loadId}, FeedId: {_eq: $feedId, _in: $feedIds}, Status: {_eq: $status}, ChannelsMetadata: {LatestData: {_gte: $latestDateDateStart, _lte: $latestDateDateEnd}}, Load: {Building: {BuildingId: {_eq: $buildingId}, SiteId: {_eq: $siteId}, Site: {Account: {AccountId: {_eq: $accountId}}}}}, _or: [{Label: {_ilike: $search}}, {ChannelKey: {_ilike: $search}}, {Feed: {Label: {_ilike: $search}}}, {Feed: {SerialNumber: {_ilike: $search}}}, {MeterType: {_ilike: $search}}, {UnitOfMeasure: {_ilike: $search}}, {Load: {Label: {_ilike: $search}}}, {Load: {Building: {Label: {_ilike: $search}}}}, {Building: {Site: {Label: {_ilike: $search}}}}, {Building: {Site: {Account: {Label: {_ilike: $search}}}}}]}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${ExportFieldsFragmentDoc}`;

/**
 * __useChannelsByMetadataDateQuery__
 *
 * To run a query within a React component, call `useChannelsByMetadataDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelsByMetadataDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelsByMetadataDateQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      loadId: // value for 'loadId'
 *      feedId: // value for 'feedId'
 *      feedIds: // value for 'feedIds'
 *      buildingId: // value for 'buildingId'
 *      siteId: // value for 'siteId'
 *      accountId: // value for 'accountId'
 *      status: // value for 'status'
 *      latestDateDateStart: // value for 'latestDateDateStart'
 *      latestDateDateEnd: // value for 'latestDateDateEnd'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useChannelsByMetadataDateQuery(baseOptions: Apollo.QueryHookOptions<ChannelsByMetadataDateQuery, ChannelsByMetadataDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChannelsByMetadataDateQuery, ChannelsByMetadataDateQueryVariables>(ChannelsByMetadataDateDocument, options);
      }
export function useChannelsByMetadataDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChannelsByMetadataDateQuery, ChannelsByMetadataDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChannelsByMetadataDateQuery, ChannelsByMetadataDateQueryVariables>(ChannelsByMetadataDateDocument, options);
        }
export type ChannelsByMetadataDateQueryHookResult = ReturnType<typeof useChannelsByMetadataDateQuery>;
export type ChannelsByMetadataDateLazyQueryHookResult = ReturnType<typeof useChannelsByMetadataDateLazyQuery>;
export type ChannelsByMetadataDateQueryResult = Apollo.QueryResult<ChannelsByMetadataDateQuery, ChannelsByMetadataDateQueryVariables>;
export const UpdateChannelBulkItemDocument = gql`
    mutation UpdateChannelBulkItem($ChannelId: uuid!, $Label: String!, $LoadId: uuid, $FeedId: uuid, $ChannelKey: String, $Type: smallint, $SummationType: smallint, $ValueTypeEnum: smallint, $NatureType: smallint!, $IntervalFrequency: String, $TimeZone: String, $Status: smallint!, $EnableAlerts: Boolean, $UpdatedOn: timestamp!) {
  update_dw_Channels_by_pk(
    pk_columns: {ChannelId: $ChannelId}
    _set: {Label: $Label, ChannelKey: $ChannelKey, LoadId: $LoadId, FeedId: $FeedId, Type: $Type, SummationType: $SummationType, ValueTypeEnum: $ValueTypeEnum, NatureType: $NatureType, IntervalFrequency: $IntervalFrequency, TimeZone: $TimeZone, Status: $Status, EnableAlerts: $EnableAlerts, UpdatedOn: $UpdatedOn}
  ) {
    ChannelId
    UpdatedOn
  }
}
    `;
export type UpdateChannelBulkItemMutationFn = Apollo.MutationFunction<UpdateChannelBulkItemMutation, UpdateChannelBulkItemMutationVariables>;

/**
 * __useUpdateChannelBulkItemMutation__
 *
 * To run a mutation, you first call `useUpdateChannelBulkItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelBulkItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelBulkItemMutation, { data, loading, error }] = useUpdateChannelBulkItemMutation({
 *   variables: {
 *      ChannelId: // value for 'ChannelId'
 *      Label: // value for 'Label'
 *      LoadId: // value for 'LoadId'
 *      FeedId: // value for 'FeedId'
 *      ChannelKey: // value for 'ChannelKey'
 *      Type: // value for 'Type'
 *      SummationType: // value for 'SummationType'
 *      ValueTypeEnum: // value for 'ValueTypeEnum'
 *      NatureType: // value for 'NatureType'
 *      IntervalFrequency: // value for 'IntervalFrequency'
 *      TimeZone: // value for 'TimeZone'
 *      Status: // value for 'Status'
 *      EnableAlerts: // value for 'EnableAlerts'
 *      UpdatedOn: // value for 'UpdatedOn'
 *   },
 * });
 */
export function useUpdateChannelBulkItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChannelBulkItemMutation, UpdateChannelBulkItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChannelBulkItemMutation, UpdateChannelBulkItemMutationVariables>(UpdateChannelBulkItemDocument, options);
      }
export type UpdateChannelBulkItemMutationHookResult = ReturnType<typeof useUpdateChannelBulkItemMutation>;
export type UpdateChannelBulkItemMutationResult = Apollo.MutationResult<UpdateChannelBulkItemMutation>;
export type UpdateChannelBulkItemMutationOptions = Apollo.BaseMutationOptions<UpdateChannelBulkItemMutation, UpdateChannelBulkItemMutationVariables>;
export const ChannelsJobsDetailsDocument = gql`
    query ChannelsJobsDetails($jobId: uuid!) {
  dw_ChannelsJobs_by_pk(JobId: $jobId) {
    ...ChannelsJobsFields
  }
}
    ${ChannelsJobsFieldsFragmentDoc}`;

/**
 * __useChannelsJobsDetailsQuery__
 *
 * To run a query within a React component, call `useChannelsJobsDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelsJobsDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelsJobsDetailsQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useChannelsJobsDetailsQuery(baseOptions: Apollo.QueryHookOptions<ChannelsJobsDetailsQuery, ChannelsJobsDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChannelsJobsDetailsQuery, ChannelsJobsDetailsQueryVariables>(ChannelsJobsDetailsDocument, options);
      }
export function useChannelsJobsDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChannelsJobsDetailsQuery, ChannelsJobsDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChannelsJobsDetailsQuery, ChannelsJobsDetailsQueryVariables>(ChannelsJobsDetailsDocument, options);
        }
export type ChannelsJobsDetailsQueryHookResult = ReturnType<typeof useChannelsJobsDetailsQuery>;
export type ChannelsJobsDetailsLazyQueryHookResult = ReturnType<typeof useChannelsJobsDetailsLazyQuery>;
export type ChannelsJobsDetailsQueryResult = Apollo.QueryResult<ChannelsJobsDetailsQuery, ChannelsJobsDetailsQueryVariables>;
export const ChannelsJobsDocument = gql`
    query ChannelsJobs($jobId: uuid, $channelId: uuid, $loadId: uuid, $buildingId: uuid, $siteId: uuid, $accountId: uuid, $search: String, $offset: Int!, $limit: Int!, $order_by: [dw_ChannelsJobs_order_by!]) {
  dw_ChannelsJobs(
    where: {JobId: {_eq: $jobId}, ChannelId: {_eq: $channelId}, Channel: {LoadId: {_eq: $loadId}, Load: {BuildingId: {_eq: $buildingId}, Building: {SiteId: {_eq: $siteId}, Site: {AccountId: {_eq: $accountId}}}}}, _or: [{Formula: {_ilike: $search}}, {Channel: {Label: {_ilike: $search}}}, {Channel: {ChannelKey: {_ilike: $search}}}, {Channel: {Load: {Label: {_ilike: $search}}}}, {Channel: {Load: {Building: {Label: {_ilike: $search}}}}}, {Channel: {Load: {Building: {Site: {Label: {_ilike: $search}}}}}}, {Channel: {Load: {Building: {Site: {Account: {Label: {_ilike: $search}}}}}}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...ChannelsJobsFields
  }
  dw_ChannelsJobs_aggregate(
    where: {JobId: {_eq: $jobId}, ChannelId: {_eq: $channelId}, Channel: {LoadId: {_eq: $loadId}, Load: {BuildingId: {_eq: $buildingId}, Building: {SiteId: {_eq: $siteId}, Site: {AccountId: {_eq: $accountId}}}}}, _or: [{Formula: {_ilike: $search}}, {Channel: {Label: {_ilike: $search}}}, {Channel: {ChannelKey: {_ilike: $search}}}, {Channel: {Load: {Label: {_ilike: $search}}}}, {Channel: {Load: {Building: {Label: {_ilike: $search}}}}}, {Channel: {Load: {Building: {Site: {Label: {_ilike: $search}}}}}}, {Channel: {Load: {Building: {Site: {Account: {Label: {_ilike: $search}}}}}}}]}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${ChannelsJobsFieldsFragmentDoc}`;

/**
 * __useChannelsJobsQuery__
 *
 * To run a query within a React component, call `useChannelsJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelsJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelsJobsQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      channelId: // value for 'channelId'
 *      loadId: // value for 'loadId'
 *      buildingId: // value for 'buildingId'
 *      siteId: // value for 'siteId'
 *      accountId: // value for 'accountId'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useChannelsJobsQuery(baseOptions: Apollo.QueryHookOptions<ChannelsJobsQuery, ChannelsJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChannelsJobsQuery, ChannelsJobsQueryVariables>(ChannelsJobsDocument, options);
      }
export function useChannelsJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChannelsJobsQuery, ChannelsJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChannelsJobsQuery, ChannelsJobsQueryVariables>(ChannelsJobsDocument, options);
        }
export type ChannelsJobsQueryHookResult = ReturnType<typeof useChannelsJobsQuery>;
export type ChannelsJobsLazyQueryHookResult = ReturnType<typeof useChannelsJobsLazyQuery>;
export type ChannelsJobsQueryResult = Apollo.QueryResult<ChannelsJobsQuery, ChannelsJobsQueryVariables>;
export const ChannelsMappingsDocument = gql`
    query ChannelsMappings($channelId: uuid, $feedId: uuid, $externalId: String, $reference: String, $search: String, $offset: Int!, $limit: Int!, $order_by: [dw_ChannelsMappings_order_by!]) {
  dw_ChannelsMappings(
    where: {ChannelId: {_eq: $channelId}, Channel: {FeedId: {_eq: $feedId}}, ExternalId: {_eq: $externalId}, Reference: {_eq: $reference}, _or: [{Reference: {_ilike: $search}}, {Channel: {Label: {_ilike: $search}}}, {Channel: {ChannelKey: {_ilike: $search}}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...ChannelsMappingsFields
  }
  dw_ChannelsMappings_aggregate(
    where: {ChannelId: {_eq: $channelId}, Channel: {FeedId: {_eq: $feedId}}, ExternalId: {_eq: $externalId}, Reference: {_eq: $reference}, _or: [{Reference: {_ilike: $search}}, {Channel: {Label: {_ilike: $search}}}, {Channel: {ChannelKey: {_ilike: $search}}}]}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${ChannelsMappingsFieldsFragmentDoc}`;

/**
 * __useChannelsMappingsQuery__
 *
 * To run a query within a React component, call `useChannelsMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelsMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelsMappingsQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      feedId: // value for 'feedId'
 *      externalId: // value for 'externalId'
 *      reference: // value for 'reference'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useChannelsMappingsQuery(baseOptions: Apollo.QueryHookOptions<ChannelsMappingsQuery, ChannelsMappingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChannelsMappingsQuery, ChannelsMappingsQueryVariables>(ChannelsMappingsDocument, options);
      }
export function useChannelsMappingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChannelsMappingsQuery, ChannelsMappingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChannelsMappingsQuery, ChannelsMappingsQueryVariables>(ChannelsMappingsDocument, options);
        }
export type ChannelsMappingsQueryHookResult = ReturnType<typeof useChannelsMappingsQuery>;
export type ChannelsMappingsLazyQueryHookResult = ReturnType<typeof useChannelsMappingsLazyQuery>;
export type ChannelsMappingsQueryResult = Apollo.QueryResult<ChannelsMappingsQuery, ChannelsMappingsQueryVariables>;
export const InsertChannelMappingsDocument = gql`
    mutation InsertChannelMappings($object: dw_ChannelsMappings_insert_input!) {
  insert_dw_ChannelsMappings_one(object: $object) {
    ChannelId
  }
}
    `;
export type InsertChannelMappingsMutationFn = Apollo.MutationFunction<InsertChannelMappingsMutation, InsertChannelMappingsMutationVariables>;

/**
 * __useInsertChannelMappingsMutation__
 *
 * To run a mutation, you first call `useInsertChannelMappingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertChannelMappingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertChannelMappingsMutation, { data, loading, error }] = useInsertChannelMappingsMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertChannelMappingsMutation(baseOptions?: Apollo.MutationHookOptions<InsertChannelMappingsMutation, InsertChannelMappingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertChannelMappingsMutation, InsertChannelMappingsMutationVariables>(InsertChannelMappingsDocument, options);
      }
export type InsertChannelMappingsMutationHookResult = ReturnType<typeof useInsertChannelMappingsMutation>;
export type InsertChannelMappingsMutationResult = Apollo.MutationResult<InsertChannelMappingsMutation>;
export type InsertChannelMappingsMutationOptions = Apollo.BaseMutationOptions<InsertChannelMappingsMutation, InsertChannelMappingsMutationVariables>;
export const UpdateChannelMappingsDocument = gql`
    mutation UpdateChannelMappings($ChannelId: uuid!, $ExternalId: String, $Reference: String) {
  update_dw_ChannelsMappings_by_pk(
    pk_columns: {ChannelId: $ChannelId}
    _set: {ExternalId: $ExternalId, Reference: $Reference}
  ) {
    ChannelId
  }
}
    `;
export type UpdateChannelMappingsMutationFn = Apollo.MutationFunction<UpdateChannelMappingsMutation, UpdateChannelMappingsMutationVariables>;

/**
 * __useUpdateChannelMappingsMutation__
 *
 * To run a mutation, you first call `useUpdateChannelMappingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelMappingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelMappingsMutation, { data, loading, error }] = useUpdateChannelMappingsMutation({
 *   variables: {
 *      ChannelId: // value for 'ChannelId'
 *      ExternalId: // value for 'ExternalId'
 *      Reference: // value for 'Reference'
 *   },
 * });
 */
export function useUpdateChannelMappingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChannelMappingsMutation, UpdateChannelMappingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChannelMappingsMutation, UpdateChannelMappingsMutationVariables>(UpdateChannelMappingsDocument, options);
      }
export type UpdateChannelMappingsMutationHookResult = ReturnType<typeof useUpdateChannelMappingsMutation>;
export type UpdateChannelMappingsMutationResult = Apollo.MutationResult<UpdateChannelMappingsMutation>;
export type UpdateChannelMappingsMutationOptions = Apollo.BaseMutationOptions<UpdateChannelMappingsMutation, UpdateChannelMappingsMutationVariables>;
export const DeleteChannelMappingsDocument = gql`
    mutation DeleteChannelMappings($ChannelId: uuid!) {
  delete_dw_ChannelsMappings_by_pk(ChannelId: $ChannelId) {
    ChannelId
  }
}
    `;
export type DeleteChannelMappingsMutationFn = Apollo.MutationFunction<DeleteChannelMappingsMutation, DeleteChannelMappingsMutationVariables>;

/**
 * __useDeleteChannelMappingsMutation__
 *
 * To run a mutation, you first call `useDeleteChannelMappingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChannelMappingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChannelMappingsMutation, { data, loading, error }] = useDeleteChannelMappingsMutation({
 *   variables: {
 *      ChannelId: // value for 'ChannelId'
 *   },
 * });
 */
export function useDeleteChannelMappingsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChannelMappingsMutation, DeleteChannelMappingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteChannelMappingsMutation, DeleteChannelMappingsMutationVariables>(DeleteChannelMappingsDocument, options);
      }
export type DeleteChannelMappingsMutationHookResult = ReturnType<typeof useDeleteChannelMappingsMutation>;
export type DeleteChannelMappingsMutationResult = Apollo.MutationResult<DeleteChannelMappingsMutation>;
export type DeleteChannelMappingsMutationOptions = Apollo.BaseMutationOptions<DeleteChannelMappingsMutation, DeleteChannelMappingsMutationVariables>;
export const ChannelsMetadataDocument = gql`
    query ChannelsMetadata($channelId: uuid, $feedId: uuid, $search: String, $offset: Int!, $limit: Int!, $order_by: [dw_ChannelsMetadata_order_by!]) {
  dw_ChannelsMetadata(
    where: {ChannelId: {_eq: $channelId}, Channel: {FeedId: {_eq: $feedId}}, _or: [{Channel: {Label: {_ilike: $search}}}, {Channel: {ChannelKey: {_ilike: $search}}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...ChannelsMetadataFields
  }
  dw_ChannelsMetadata_aggregate(
    where: {ChannelId: {_eq: $channelId}, Channel: {FeedId: {_eq: $feedId}}, _or: [{Channel: {Label: {_ilike: $search}}}, {Channel: {ChannelKey: {_ilike: $search}}}]}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${ChannelsMetadataFieldsFragmentDoc}`;

/**
 * __useChannelsMetadataQuery__
 *
 * To run a query within a React component, call `useChannelsMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelsMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelsMetadataQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      feedId: // value for 'feedId'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useChannelsMetadataQuery(baseOptions: Apollo.QueryHookOptions<ChannelsMetadataQuery, ChannelsMetadataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChannelsMetadataQuery, ChannelsMetadataQueryVariables>(ChannelsMetadataDocument, options);
      }
export function useChannelsMetadataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChannelsMetadataQuery, ChannelsMetadataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChannelsMetadataQuery, ChannelsMetadataQueryVariables>(ChannelsMetadataDocument, options);
        }
export type ChannelsMetadataQueryHookResult = ReturnType<typeof useChannelsMetadataQuery>;
export type ChannelsMetadataLazyQueryHookResult = ReturnType<typeof useChannelsMetadataLazyQuery>;
export type ChannelsMetadataQueryResult = Apollo.QueryResult<ChannelsMetadataQuery, ChannelsMetadataQueryVariables>;
export const InsertChannelMetadataDocument = gql`
    mutation InsertChannelMetadata($object: dw_ChannelsMetadata_insert_input!) {
  insert_dw_ChannelsMetadata_one(object: $object) {
    ChannelId
  }
}
    `;
export type InsertChannelMetadataMutationFn = Apollo.MutationFunction<InsertChannelMetadataMutation, InsertChannelMetadataMutationVariables>;

/**
 * __useInsertChannelMetadataMutation__
 *
 * To run a mutation, you first call `useInsertChannelMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertChannelMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertChannelMetadataMutation, { data, loading, error }] = useInsertChannelMetadataMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertChannelMetadataMutation(baseOptions?: Apollo.MutationHookOptions<InsertChannelMetadataMutation, InsertChannelMetadataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertChannelMetadataMutation, InsertChannelMetadataMutationVariables>(InsertChannelMetadataDocument, options);
      }
export type InsertChannelMetadataMutationHookResult = ReturnType<typeof useInsertChannelMetadataMutation>;
export type InsertChannelMetadataMutationResult = Apollo.MutationResult<InsertChannelMetadataMutation>;
export type InsertChannelMetadataMutationOptions = Apollo.BaseMutationOptions<InsertChannelMetadataMutation, InsertChannelMetadataMutationVariables>;
export const UpdateChannelMetadataDocument = gql`
    mutation UpdateChannelMetadata($ChannelId: uuid!, $NegativeValuesPermissible: Boolean) {
  update_dw_ChannelsMetadata_by_pk(
    pk_columns: {ChannelId: $ChannelId}
    _set: {NegativeValuesPermissible: $NegativeValuesPermissible}
  ) {
    ChannelId
  }
}
    `;
export type UpdateChannelMetadataMutationFn = Apollo.MutationFunction<UpdateChannelMetadataMutation, UpdateChannelMetadataMutationVariables>;

/**
 * __useUpdateChannelMetadataMutation__
 *
 * To run a mutation, you first call `useUpdateChannelMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelMetadataMutation, { data, loading, error }] = useUpdateChannelMetadataMutation({
 *   variables: {
 *      ChannelId: // value for 'ChannelId'
 *      NegativeValuesPermissible: // value for 'NegativeValuesPermissible'
 *   },
 * });
 */
export function useUpdateChannelMetadataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChannelMetadataMutation, UpdateChannelMetadataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChannelMetadataMutation, UpdateChannelMetadataMutationVariables>(UpdateChannelMetadataDocument, options);
      }
export type UpdateChannelMetadataMutationHookResult = ReturnType<typeof useUpdateChannelMetadataMutation>;
export type UpdateChannelMetadataMutationResult = Apollo.MutationResult<UpdateChannelMetadataMutation>;
export type UpdateChannelMetadataMutationOptions = Apollo.BaseMutationOptions<UpdateChannelMetadataMutation, UpdateChannelMetadataMutationVariables>;
export const BulkInsertChannelsMetadataDocument = gql`
    mutation BulkInsertChannelsMetadata($objects: [dw_ChannelsMetadata_insert_input!]!) {
  insert_dw_ChannelsMetadata(objects: $objects) {
    affected_rows
  }
}
    `;
export type BulkInsertChannelsMetadataMutationFn = Apollo.MutationFunction<BulkInsertChannelsMetadataMutation, BulkInsertChannelsMetadataMutationVariables>;

/**
 * __useBulkInsertChannelsMetadataMutation__
 *
 * To run a mutation, you first call `useBulkInsertChannelsMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkInsertChannelsMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkInsertChannelsMetadataMutation, { data, loading, error }] = useBulkInsertChannelsMetadataMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useBulkInsertChannelsMetadataMutation(baseOptions?: Apollo.MutationHookOptions<BulkInsertChannelsMetadataMutation, BulkInsertChannelsMetadataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkInsertChannelsMetadataMutation, BulkInsertChannelsMetadataMutationVariables>(BulkInsertChannelsMetadataDocument, options);
      }
export type BulkInsertChannelsMetadataMutationHookResult = ReturnType<typeof useBulkInsertChannelsMetadataMutation>;
export type BulkInsertChannelsMetadataMutationResult = Apollo.MutationResult<BulkInsertChannelsMetadataMutation>;
export type BulkInsertChannelsMetadataMutationOptions = Apollo.BaseMutationOptions<BulkInsertChannelsMetadataMutation, BulkInsertChannelsMetadataMutationVariables>;
export const ChannelsRulesDetailsDocument = gql`
    query ChannelsRulesDetails($ruleId: uuid!) {
  dw_ChannelsRules_by_pk(RuleId: $ruleId) {
    ...ChannelsRulesFields
  }
}
    ${ChannelsRulesFieldsFragmentDoc}`;

/**
 * __useChannelsRulesDetailsQuery__
 *
 * To run a query within a React component, call `useChannelsRulesDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelsRulesDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelsRulesDetailsQuery({
 *   variables: {
 *      ruleId: // value for 'ruleId'
 *   },
 * });
 */
export function useChannelsRulesDetailsQuery(baseOptions: Apollo.QueryHookOptions<ChannelsRulesDetailsQuery, ChannelsRulesDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChannelsRulesDetailsQuery, ChannelsRulesDetailsQueryVariables>(ChannelsRulesDetailsDocument, options);
      }
export function useChannelsRulesDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChannelsRulesDetailsQuery, ChannelsRulesDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChannelsRulesDetailsQuery, ChannelsRulesDetailsQueryVariables>(ChannelsRulesDetailsDocument, options);
        }
export type ChannelsRulesDetailsQueryHookResult = ReturnType<typeof useChannelsRulesDetailsQuery>;
export type ChannelsRulesDetailsLazyQueryHookResult = ReturnType<typeof useChannelsRulesDetailsLazyQuery>;
export type ChannelsRulesDetailsQueryResult = Apollo.QueryResult<ChannelsRulesDetailsQuery, ChannelsRulesDetailsQueryVariables>;
export const GetChannelRulesByChildChannelIdDocument = gql`
    query GetChannelRulesByChildChannelId($ChannelId: uuid!) {
  dw_ChannelsRules(
    where: {channelByChannelid: {ChannelId: {_eq: $ChannelId}}, Channel: {Type: {_eq: 2}}}
  ) {
    ChannelId
    SourceChannelId
    SourceChannelKey
  }
}
    `;

/**
 * __useGetChannelRulesByChildChannelIdQuery__
 *
 * To run a query within a React component, call `useGetChannelRulesByChildChannelIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelRulesByChildChannelIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelRulesByChildChannelIdQuery({
 *   variables: {
 *      ChannelId: // value for 'ChannelId'
 *   },
 * });
 */
export function useGetChannelRulesByChildChannelIdQuery(baseOptions: Apollo.QueryHookOptions<GetChannelRulesByChildChannelIdQuery, GetChannelRulesByChildChannelIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChannelRulesByChildChannelIdQuery, GetChannelRulesByChildChannelIdQueryVariables>(GetChannelRulesByChildChannelIdDocument, options);
      }
export function useGetChannelRulesByChildChannelIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChannelRulesByChildChannelIdQuery, GetChannelRulesByChildChannelIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChannelRulesByChildChannelIdQuery, GetChannelRulesByChildChannelIdQueryVariables>(GetChannelRulesByChildChannelIdDocument, options);
        }
export type GetChannelRulesByChildChannelIdQueryHookResult = ReturnType<typeof useGetChannelRulesByChildChannelIdQuery>;
export type GetChannelRulesByChildChannelIdLazyQueryHookResult = ReturnType<typeof useGetChannelRulesByChildChannelIdLazyQuery>;
export type GetChannelRulesByChildChannelIdQueryResult = Apollo.QueryResult<GetChannelRulesByChildChannelIdQuery, GetChannelRulesByChildChannelIdQueryVariables>;
export const ChannelsRulesDocument = gql`
    query ChannelsRules($ruleId: uuid, $channelId: uuid, $loadId: uuid, $buildingId: uuid, $siteId: uuid, $accountId: uuid, $search: String, $offset: Int!, $limit: Int!) {
  dw_ChannelsRules(
    where: {RuleId: {_eq: $ruleId}, ChannelId: {_eq: $channelId}, channelByChannelid: {LoadId: {_eq: $loadId}, Load: {BuildingId: {_eq: $buildingId}, Building: {SiteId: {_eq: $siteId}, Site: {AccountId: {_eq: $accountId}}}}}, _or: [{channelByChannelid: {Label: {_ilike: $search}}}, {channelByChannelid: {Load: {Label: {_ilike: $search}}}}, {channelByChannelid: {Load: {Building: {Label: {_ilike: $search}}}}}, {channelByChannelid: {Load: {Building: {Site: {Label: {_ilike: $search}}}}}}, {channelByChannelid: {Load: {Building: {Site: {Account: {Label: {_ilike: $search}}}}}}}, {Channel: {Label: {_ilike: $search}}}, {Channel: {ChannelKey: {_ilike: $search}}}]}
    limit: $limit
    offset: $offset
    order_by: {CreatedOn: asc}
  ) {
    ...ChannelsRulesFields
  }
  dw_ChannelsRules_aggregate(
    where: {RuleId: {_eq: $ruleId}, ChannelId: {_eq: $channelId}, channelByChannelid: {LoadId: {_eq: $loadId}, Load: {BuildingId: {_eq: $buildingId}, Building: {SiteId: {_eq: $siteId}, Site: {AccountId: {_eq: $accountId}}}}}, _or: [{channelByChannelid: {Label: {_ilike: $search}}}, {channelByChannelid: {Load: {Label: {_ilike: $search}}}}, {channelByChannelid: {Load: {Building: {Label: {_ilike: $search}}}}}, {channelByChannelid: {Load: {Building: {Site: {Label: {_ilike: $search}}}}}}, {channelByChannelid: {Load: {Building: {Site: {Account: {Label: {_ilike: $search}}}}}}}, {Channel: {Label: {_ilike: $search}}}, {Channel: {ChannelKey: {_ilike: $search}}}]}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${ChannelsRulesFieldsFragmentDoc}`;

/**
 * __useChannelsRulesQuery__
 *
 * To run a query within a React component, call `useChannelsRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelsRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelsRulesQuery({
 *   variables: {
 *      ruleId: // value for 'ruleId'
 *      channelId: // value for 'channelId'
 *      loadId: // value for 'loadId'
 *      buildingId: // value for 'buildingId'
 *      siteId: // value for 'siteId'
 *      accountId: // value for 'accountId'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useChannelsRulesQuery(baseOptions: Apollo.QueryHookOptions<ChannelsRulesQuery, ChannelsRulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChannelsRulesQuery, ChannelsRulesQueryVariables>(ChannelsRulesDocument, options);
      }
export function useChannelsRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChannelsRulesQuery, ChannelsRulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChannelsRulesQuery, ChannelsRulesQueryVariables>(ChannelsRulesDocument, options);
        }
export type ChannelsRulesQueryHookResult = ReturnType<typeof useChannelsRulesQuery>;
export type ChannelsRulesLazyQueryHookResult = ReturnType<typeof useChannelsRulesLazyQuery>;
export type ChannelsRulesQueryResult = Apollo.QueryResult<ChannelsRulesQuery, ChannelsRulesQueryVariables>;
export const InsertChannelRuleDocument = gql`
    mutation InsertChannelRule($object: dw_ChannelsRules_insert_input!) {
  insert_dw_ChannelsRules_one(object: $object) {
    RuleId
  }
}
    `;
export type InsertChannelRuleMutationFn = Apollo.MutationFunction<InsertChannelRuleMutation, InsertChannelRuleMutationVariables>;

/**
 * __useInsertChannelRuleMutation__
 *
 * To run a mutation, you first call `useInsertChannelRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertChannelRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertChannelRuleMutation, { data, loading, error }] = useInsertChannelRuleMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertChannelRuleMutation(baseOptions?: Apollo.MutationHookOptions<InsertChannelRuleMutation, InsertChannelRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertChannelRuleMutation, InsertChannelRuleMutationVariables>(InsertChannelRuleDocument, options);
      }
export type InsertChannelRuleMutationHookResult = ReturnType<typeof useInsertChannelRuleMutation>;
export type InsertChannelRuleMutationResult = Apollo.MutationResult<InsertChannelRuleMutation>;
export type InsertChannelRuleMutationOptions = Apollo.BaseMutationOptions<InsertChannelRuleMutation, InsertChannelRuleMutationVariables>;
export const UpdateChannelRuleDocument = gql`
    mutation UpdateChannelRule($RuleId: uuid!, $Status: smallint!, $UpdatedOn: timestamp!) {
  update_dw_ChannelsRules_by_pk(
    pk_columns: {RuleId: $RuleId}
    _set: {Status: $Status, UpdatedOn: $UpdatedOn}
  ) {
    RuleId
  }
}
    `;
export type UpdateChannelRuleMutationFn = Apollo.MutationFunction<UpdateChannelRuleMutation, UpdateChannelRuleMutationVariables>;

/**
 * __useUpdateChannelRuleMutation__
 *
 * To run a mutation, you first call `useUpdateChannelRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelRuleMutation, { data, loading, error }] = useUpdateChannelRuleMutation({
 *   variables: {
 *      RuleId: // value for 'RuleId'
 *      Status: // value for 'Status'
 *      UpdatedOn: // value for 'UpdatedOn'
 *   },
 * });
 */
export function useUpdateChannelRuleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChannelRuleMutation, UpdateChannelRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChannelRuleMutation, UpdateChannelRuleMutationVariables>(UpdateChannelRuleDocument, options);
      }
export type UpdateChannelRuleMutationHookResult = ReturnType<typeof useUpdateChannelRuleMutation>;
export type UpdateChannelRuleMutationResult = Apollo.MutationResult<UpdateChannelRuleMutation>;
export type UpdateChannelRuleMutationOptions = Apollo.BaseMutationOptions<UpdateChannelRuleMutation, UpdateChannelRuleMutationVariables>;
export const DeleteChannelRulesDocument = gql`
    mutation DeleteChannelRules($RuleId: uuid!) {
  delete_dw_ChannelsRules_by_pk(RuleId: $RuleId) {
    RuleId
  }
}
    `;
export type DeleteChannelRulesMutationFn = Apollo.MutationFunction<DeleteChannelRulesMutation, DeleteChannelRulesMutationVariables>;

/**
 * __useDeleteChannelRulesMutation__
 *
 * To run a mutation, you first call `useDeleteChannelRulesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChannelRulesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChannelRulesMutation, { data, loading, error }] = useDeleteChannelRulesMutation({
 *   variables: {
 *      RuleId: // value for 'RuleId'
 *   },
 * });
 */
export function useDeleteChannelRulesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChannelRulesMutation, DeleteChannelRulesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteChannelRulesMutation, DeleteChannelRulesMutationVariables>(DeleteChannelRulesDocument, options);
      }
export type DeleteChannelRulesMutationHookResult = ReturnType<typeof useDeleteChannelRulesMutation>;
export type DeleteChannelRulesMutationResult = Apollo.MutationResult<DeleteChannelRulesMutation>;
export type DeleteChannelRulesMutationOptions = Apollo.BaseMutationOptions<DeleteChannelRulesMutation, DeleteChannelRulesMutationVariables>;
export const ConsumptionJobsDetailsDocument = gql`
    query ConsumptionJobsDetails($jobId: uuid!) {
  dw_ConsumptionJobs_by_pk(JobId: $jobId) {
    ...ConsumptionJobsFields
  }
}
    ${ConsumptionJobsFieldsFragmentDoc}`;

/**
 * __useConsumptionJobsDetailsQuery__
 *
 * To run a query within a React component, call `useConsumptionJobsDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsumptionJobsDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsumptionJobsDetailsQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useConsumptionJobsDetailsQuery(baseOptions: Apollo.QueryHookOptions<ConsumptionJobsDetailsQuery, ConsumptionJobsDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConsumptionJobsDetailsQuery, ConsumptionJobsDetailsQueryVariables>(ConsumptionJobsDetailsDocument, options);
      }
export function useConsumptionJobsDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsumptionJobsDetailsQuery, ConsumptionJobsDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConsumptionJobsDetailsQuery, ConsumptionJobsDetailsQueryVariables>(ConsumptionJobsDetailsDocument, options);
        }
export type ConsumptionJobsDetailsQueryHookResult = ReturnType<typeof useConsumptionJobsDetailsQuery>;
export type ConsumptionJobsDetailsLazyQueryHookResult = ReturnType<typeof useConsumptionJobsDetailsLazyQuery>;
export type ConsumptionJobsDetailsQueryResult = Apollo.QueryResult<ConsumptionJobsDetailsQuery, ConsumptionJobsDetailsQueryVariables>;
export const ConsumptionJobsDocument = gql`
    query ConsumptionJobs($jobId: uuid, $search: String, $offset: Int!, $limit: Int!, $order_by: [dw_ConsumptionJobs_order_by!]) {
  dw_ConsumptionJobs(
    where: {JobId: {_eq: $jobId}, _or: [{File: {_ilike: $search}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...ConsumptionJobsFields
  }
  dw_ConsumptionJobs_aggregate(
    where: {JobId: {_eq: $jobId}, _or: [{File: {_ilike: $search}}]}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${ConsumptionJobsFieldsFragmentDoc}`;

/**
 * __useConsumptionJobsQuery__
 *
 * To run a query within a React component, call `useConsumptionJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsumptionJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsumptionJobsQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useConsumptionJobsQuery(baseOptions: Apollo.QueryHookOptions<ConsumptionJobsQuery, ConsumptionJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConsumptionJobsQuery, ConsumptionJobsQueryVariables>(ConsumptionJobsDocument, options);
      }
export function useConsumptionJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsumptionJobsQuery, ConsumptionJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConsumptionJobsQuery, ConsumptionJobsQueryVariables>(ConsumptionJobsDocument, options);
        }
export type ConsumptionJobsQueryHookResult = ReturnType<typeof useConsumptionJobsQuery>;
export type ConsumptionJobsLazyQueryHookResult = ReturnType<typeof useConsumptionJobsLazyQuery>;
export type ConsumptionJobsQueryResult = Apollo.QueryResult<ConsumptionJobsQuery, ConsumptionJobsQueryVariables>;
export const ContactSearchDocument = gql`
    query ContactSearch($search: String, $AccountId: uuid) {
  dw_Contacts(
    where: {ContactsRoles: {AccountId: {_eq: $AccountId}}, _or: [{DisplayName: {_ilike: $search}}, {FirstName: {_ilike: $search}}, {LastName: {_ilike: $search}}, {EmailAddress: {_ilike: $search}}]}
    limit: 30
    order_by: {DisplayName: asc}
  ) {
    ...ContactFields
  }
}
    ${ContactFieldsFragmentDoc}`;

/**
 * __useContactSearchQuery__
 *
 * To run a query within a React component, call `useContactSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactSearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *      AccountId: // value for 'AccountId'
 *   },
 * });
 */
export function useContactSearchQuery(baseOptions?: Apollo.QueryHookOptions<ContactSearchQuery, ContactSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactSearchQuery, ContactSearchQueryVariables>(ContactSearchDocument, options);
      }
export function useContactSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactSearchQuery, ContactSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactSearchQuery, ContactSearchQueryVariables>(ContactSearchDocument, options);
        }
export type ContactSearchQueryHookResult = ReturnType<typeof useContactSearchQuery>;
export type ContactSearchLazyQueryHookResult = ReturnType<typeof useContactSearchLazyQuery>;
export type ContactSearchQueryResult = Apollo.QueryResult<ContactSearchQuery, ContactSearchQueryVariables>;
export const ContactSearchNoLimitDocument = gql`
    query ContactSearchNoLimit($search: String, $AccountId: uuid) {
  dw_Contacts(
    where: {ContactsRoles: {AccountId: {_eq: $AccountId}}, _or: [{DisplayName: {_ilike: $search}}, {FirstName: {_ilike: $search}}, {LastName: {_ilike: $search}}, {EmailAddress: {_ilike: $search}}]}
    order_by: {DisplayName: asc}
  ) {
    ...ContactFields
  }
}
    ${ContactFieldsFragmentDoc}`;

/**
 * __useContactSearchNoLimitQuery__
 *
 * To run a query within a React component, call `useContactSearchNoLimitQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactSearchNoLimitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactSearchNoLimitQuery({
 *   variables: {
 *      search: // value for 'search'
 *      AccountId: // value for 'AccountId'
 *   },
 * });
 */
export function useContactSearchNoLimitQuery(baseOptions?: Apollo.QueryHookOptions<ContactSearchNoLimitQuery, ContactSearchNoLimitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactSearchNoLimitQuery, ContactSearchNoLimitQueryVariables>(ContactSearchNoLimitDocument, options);
      }
export function useContactSearchNoLimitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactSearchNoLimitQuery, ContactSearchNoLimitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactSearchNoLimitQuery, ContactSearchNoLimitQueryVariables>(ContactSearchNoLimitDocument, options);
        }
export type ContactSearchNoLimitQueryHookResult = ReturnType<typeof useContactSearchNoLimitQuery>;
export type ContactSearchNoLimitLazyQueryHookResult = ReturnType<typeof useContactSearchNoLimitLazyQuery>;
export type ContactSearchNoLimitQueryResult = Apollo.QueryResult<ContactSearchNoLimitQuery, ContactSearchNoLimitQueryVariables>;
export const ContactAccountRolesSearchDocument = gql`
    query ContactAccountRolesSearch($search: String, $AccountId: uuid) {
  dw_Contacts(
    where: {_or: [{DisplayName: {_ilike: $search}}, {FirstName: {_ilike: $search}}, {LastName: {_ilike: $search}}, {EmailAddress: {_ilike: $search}}]}
    limit: 30
    order_by: {DisplayName: asc}
  ) {
    ...ContactFields
  }
}
    ${ContactFieldsFragmentDoc}`;

/**
 * __useContactAccountRolesSearchQuery__
 *
 * To run a query within a React component, call `useContactAccountRolesSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactAccountRolesSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactAccountRolesSearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *      AccountId: // value for 'AccountId'
 *   },
 * });
 */
export function useContactAccountRolesSearchQuery(baseOptions?: Apollo.QueryHookOptions<ContactAccountRolesSearchQuery, ContactAccountRolesSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactAccountRolesSearchQuery, ContactAccountRolesSearchQueryVariables>(ContactAccountRolesSearchDocument, options);
      }
export function useContactAccountRolesSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactAccountRolesSearchQuery, ContactAccountRolesSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactAccountRolesSearchQuery, ContactAccountRolesSearchQueryVariables>(ContactAccountRolesSearchDocument, options);
        }
export type ContactAccountRolesSearchQueryHookResult = ReturnType<typeof useContactAccountRolesSearchQuery>;
export type ContactAccountRolesSearchLazyQueryHookResult = ReturnType<typeof useContactAccountRolesSearchLazyQuery>;
export type ContactAccountRolesSearchQueryResult = Apollo.QueryResult<ContactAccountRolesSearchQuery, ContactAccountRolesSearchQueryVariables>;
export const ContactDetailsDocument = gql`
    query ContactDetails($contactId: uuid!) {
  dw_Contacts_by_pk(ContactId: $contactId) {
    ...ContactFields
  }
}
    ${ContactFieldsFragmentDoc}`;

/**
 * __useContactDetailsQuery__
 *
 * To run a query within a React component, call `useContactDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactDetailsQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useContactDetailsQuery(baseOptions: Apollo.QueryHookOptions<ContactDetailsQuery, ContactDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactDetailsQuery, ContactDetailsQueryVariables>(ContactDetailsDocument, options);
      }
export function useContactDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactDetailsQuery, ContactDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactDetailsQuery, ContactDetailsQueryVariables>(ContactDetailsDocument, options);
        }
export type ContactDetailsQueryHookResult = ReturnType<typeof useContactDetailsQuery>;
export type ContactDetailsLazyQueryHookResult = ReturnType<typeof useContactDetailsLazyQuery>;
export type ContactDetailsQueryResult = Apollo.QueryResult<ContactDetailsQuery, ContactDetailsQueryVariables>;
export const ContactsDocument = gql`
    query Contacts($contactId: uuid, $search: String, $status: smallint, $offset: Int!, $limit: Int!, $order_by: [dw_Contacts_order_by!]) {
  dw_Contacts(
    where: {ContactId: {_eq: $contactId}, Status: {_eq: $status}, _or: [{DisplayName: {_ilike: $search}}, {FirstName: {_ilike: $search}}, {LastName: {_ilike: $search}}, {EmailAddress: {_ilike: $search}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...ContactFields
  }
  dw_Contacts_aggregate(
    where: {ContactId: {_eq: $contactId}, Status: {_eq: $status}, _or: [{DisplayName: {_ilike: $search}}, {FirstName: {_ilike: $search}}, {LastName: {_ilike: $search}}, {EmailAddress: {_ilike: $search}}]}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${ContactFieldsFragmentDoc}`;

/**
 * __useContactsQuery__
 *
 * To run a query within a React component, call `useContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      search: // value for 'search'
 *      status: // value for 'status'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useContactsQuery(baseOptions: Apollo.QueryHookOptions<ContactsQuery, ContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactsQuery, ContactsQueryVariables>(ContactsDocument, options);
      }
export function useContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactsQuery, ContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactsQuery, ContactsQueryVariables>(ContactsDocument, options);
        }
export type ContactsQueryHookResult = ReturnType<typeof useContactsQuery>;
export type ContactsLazyQueryHookResult = ReturnType<typeof useContactsLazyQuery>;
export type ContactsQueryResult = Apollo.QueryResult<ContactsQuery, ContactsQueryVariables>;
export const InsertContactDocument = gql`
    mutation InsertContact($ContactId: uuid!, $DisplayName: String!, $FirstName: String!, $LastName: String!, $EmailAddress: String!, $Type: smallint!, $Status: smallint!, $CreatedOn: timestamp!, $UpdatedOn: timestamp!) {
  insert_dw_Contacts_one(
    object: {ContactId: $ContactId, DisplayName: $DisplayName, FirstName: $FirstName, LastName: $LastName, EmailAddress: $EmailAddress, Type: $Type, Status: $Status, CreatedOn: $CreatedOn, UpdatedOn: $UpdatedOn}
  ) {
    ContactId
  }
}
    `;
export type InsertContactMutationFn = Apollo.MutationFunction<InsertContactMutation, InsertContactMutationVariables>;

/**
 * __useInsertContactMutation__
 *
 * To run a mutation, you first call `useInsertContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertContactMutation, { data, loading, error }] = useInsertContactMutation({
 *   variables: {
 *      ContactId: // value for 'ContactId'
 *      DisplayName: // value for 'DisplayName'
 *      FirstName: // value for 'FirstName'
 *      LastName: // value for 'LastName'
 *      EmailAddress: // value for 'EmailAddress'
 *      Type: // value for 'Type'
 *      Status: // value for 'Status'
 *      CreatedOn: // value for 'CreatedOn'
 *      UpdatedOn: // value for 'UpdatedOn'
 *   },
 * });
 */
export function useInsertContactMutation(baseOptions?: Apollo.MutationHookOptions<InsertContactMutation, InsertContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertContactMutation, InsertContactMutationVariables>(InsertContactDocument, options);
      }
export type InsertContactMutationHookResult = ReturnType<typeof useInsertContactMutation>;
export type InsertContactMutationResult = Apollo.MutationResult<InsertContactMutation>;
export type InsertContactMutationOptions = Apollo.BaseMutationOptions<InsertContactMutation, InsertContactMutationVariables>;
export const UpdateContactsDocument = gql`
    mutation UpdateContacts($ContactId: uuid!, $DisplayName: String!, $FirstName: String!, $LastName: String!, $EmailAddress: String!, $Type: smallint!, $Status: smallint!, $UpdatedOn: timestamp!) {
  update_dw_Contacts_by_pk(
    pk_columns: {ContactId: $ContactId}
    _set: {DisplayName: $DisplayName, FirstName: $FirstName, LastName: $LastName, EmailAddress: $EmailAddress, Type: $Type, Status: $Status, UpdatedOn: $UpdatedOn}
  ) {
    ContactId
    UpdatedOn
  }
}
    `;
export type UpdateContactsMutationFn = Apollo.MutationFunction<UpdateContactsMutation, UpdateContactsMutationVariables>;

/**
 * __useUpdateContactsMutation__
 *
 * To run a mutation, you first call `useUpdateContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactsMutation, { data, loading, error }] = useUpdateContactsMutation({
 *   variables: {
 *      ContactId: // value for 'ContactId'
 *      DisplayName: // value for 'DisplayName'
 *      FirstName: // value for 'FirstName'
 *      LastName: // value for 'LastName'
 *      EmailAddress: // value for 'EmailAddress'
 *      Type: // value for 'Type'
 *      Status: // value for 'Status'
 *      UpdatedOn: // value for 'UpdatedOn'
 *   },
 * });
 */
export function useUpdateContactsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactsMutation, UpdateContactsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContactsMutation, UpdateContactsMutationVariables>(UpdateContactsDocument, options);
      }
export type UpdateContactsMutationHookResult = ReturnType<typeof useUpdateContactsMutation>;
export type UpdateContactsMutationResult = Apollo.MutationResult<UpdateContactsMutation>;
export type UpdateContactsMutationOptions = Apollo.BaseMutationOptions<UpdateContactsMutation, UpdateContactsMutationVariables>;
export const ContactsRoleDocument = gql`
    query ContactsRole($contactId: uuid, $accountId: uuid, $search: String, $offset: Int!, $limit: Int!, $order_by: [dw_ContactsRole_order_by!]) {
  dw_ContactsRole(
    where: {ContactId: {_eq: $contactId}, AccountId: {_eq: $accountId}, _or: [{Account: {Label: {_ilike: $search}}}, {Contact: {DisplayName: {_ilike: $search}}}, {EmailAddress: {_ilike: $search}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...ContactsRoleFields
  }
  dw_ContactsRole_aggregate(
    where: {ContactId: {_eq: $contactId}, AccountId: {_eq: $accountId}, _or: [{Account: {Label: {_ilike: $search}}}, {Contact: {DisplayName: {_ilike: $search}}}, {EmailAddress: {_ilike: $search}}]}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${ContactsRoleFieldsFragmentDoc}`;

/**
 * __useContactsRoleQuery__
 *
 * To run a query within a React component, call `useContactsRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactsRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactsRoleQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      accountId: // value for 'accountId'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useContactsRoleQuery(baseOptions: Apollo.QueryHookOptions<ContactsRoleQuery, ContactsRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactsRoleQuery, ContactsRoleQueryVariables>(ContactsRoleDocument, options);
      }
export function useContactsRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactsRoleQuery, ContactsRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactsRoleQuery, ContactsRoleQueryVariables>(ContactsRoleDocument, options);
        }
export type ContactsRoleQueryHookResult = ReturnType<typeof useContactsRoleQuery>;
export type ContactsRoleLazyQueryHookResult = ReturnType<typeof useContactsRoleLazyQuery>;
export type ContactsRoleQueryResult = Apollo.QueryResult<ContactsRoleQuery, ContactsRoleQueryVariables>;
export const InsertContactRolesDocument = gql`
    mutation InsertContactRoles($objects: [dw_ContactsRole_insert_input!]!) {
  insert_dw_ContactsRole(objects: $objects) {
    returning {
      ContactId
      AccountId
    }
  }
}
    `;
export type InsertContactRolesMutationFn = Apollo.MutationFunction<InsertContactRolesMutation, InsertContactRolesMutationVariables>;

/**
 * __useInsertContactRolesMutation__
 *
 * To run a mutation, you first call `useInsertContactRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertContactRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertContactRolesMutation, { data, loading, error }] = useInsertContactRolesMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertContactRolesMutation(baseOptions?: Apollo.MutationHookOptions<InsertContactRolesMutation, InsertContactRolesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertContactRolesMutation, InsertContactRolesMutationVariables>(InsertContactRolesDocument, options);
      }
export type InsertContactRolesMutationHookResult = ReturnType<typeof useInsertContactRolesMutation>;
export type InsertContactRolesMutationResult = Apollo.MutationResult<InsertContactRolesMutation>;
export type InsertContactRolesMutationOptions = Apollo.BaseMutationOptions<InsertContactRolesMutation, InsertContactRolesMutationVariables>;
export const DeleteContactRolesDocument = gql`
    mutation DeleteContactRoles($AccountId: uuid!, $ContactId: uuid!) {
  delete_dw_ContactsRole(
    where: {_and: {AccountId: {_eq: $AccountId}, ContactId: {_eq: $ContactId}}}
  ) {
    affected_rows
  }
}
    `;
export type DeleteContactRolesMutationFn = Apollo.MutationFunction<DeleteContactRolesMutation, DeleteContactRolesMutationVariables>;

/**
 * __useDeleteContactRolesMutation__
 *
 * To run a mutation, you first call `useDeleteContactRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactRolesMutation, { data, loading, error }] = useDeleteContactRolesMutation({
 *   variables: {
 *      AccountId: // value for 'AccountId'
 *      ContactId: // value for 'ContactId'
 *   },
 * });
 */
export function useDeleteContactRolesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactRolesMutation, DeleteContactRolesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContactRolesMutation, DeleteContactRolesMutationVariables>(DeleteContactRolesDocument, options);
      }
export type DeleteContactRolesMutationHookResult = ReturnType<typeof useDeleteContactRolesMutation>;
export type DeleteContactRolesMutationResult = Apollo.MutationResult<DeleteContactRolesMutation>;
export type DeleteContactRolesMutationOptions = Apollo.BaseMutationOptions<DeleteContactRolesMutation, DeleteContactRolesMutationVariables>;
export const DashboardsDetailsDocument = gql`
    query DashboardsDetails($dashboardId: uuid!) {
  dw_Dashboards_by_pk(DashboardId: $dashboardId) {
    ...DashboardDetailFields
  }
}
    ${DashboardDetailFieldsFragmentDoc}`;

/**
 * __useDashboardsDetailsQuery__
 *
 * To run a query within a React component, call `useDashboardsDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardsDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardsDetailsQuery({
 *   variables: {
 *      dashboardId: // value for 'dashboardId'
 *   },
 * });
 */
export function useDashboardsDetailsQuery(baseOptions: Apollo.QueryHookOptions<DashboardsDetailsQuery, DashboardsDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardsDetailsQuery, DashboardsDetailsQueryVariables>(DashboardsDetailsDocument, options);
      }
export function useDashboardsDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardsDetailsQuery, DashboardsDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardsDetailsQuery, DashboardsDetailsQueryVariables>(DashboardsDetailsDocument, options);
        }
export type DashboardsDetailsQueryHookResult = ReturnType<typeof useDashboardsDetailsQuery>;
export type DashboardsDetailsLazyQueryHookResult = ReturnType<typeof useDashboardsDetailsLazyQuery>;
export type DashboardsDetailsQueryResult = Apollo.QueryResult<DashboardsDetailsQuery, DashboardsDetailsQueryVariables>;
export const DashboardsDocument = gql`
    query Dashboards($dashboardId: uuid, $accountId: uuid, $search: String, $offset: Int!, $limit: Int!, $order_by: [dw_Dashboards_order_by!]) {
  dw_Dashboards(
    where: {DashboardId: {_eq: $dashboardId}, AccountId: {_eq: $accountId}, _or: [{Label: {_ilike: $search}}, {Code: {_ilike: $search}}, {Building: {Label: {_ilike: $search}}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...DashboardFields
  }
  dw_Dashboards_aggregate(
    where: {DashboardId: {_eq: $dashboardId}, AccountId: {_eq: $accountId}, _or: [{Label: {_ilike: $search}}, {Code: {_ilike: $search}}, {Building: {Label: {_ilike: $search}}}]}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${DashboardFieldsFragmentDoc}`;

/**
 * __useDashboardsQuery__
 *
 * To run a query within a React component, call `useDashboardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardsQuery({
 *   variables: {
 *      dashboardId: // value for 'dashboardId'
 *      accountId: // value for 'accountId'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useDashboardsQuery(baseOptions: Apollo.QueryHookOptions<DashboardsQuery, DashboardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardsQuery, DashboardsQueryVariables>(DashboardsDocument, options);
      }
export function useDashboardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardsQuery, DashboardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardsQuery, DashboardsQueryVariables>(DashboardsDocument, options);
        }
export type DashboardsQueryHookResult = ReturnType<typeof useDashboardsQuery>;
export type DashboardsLazyQueryHookResult = ReturnType<typeof useDashboardsLazyQuery>;
export type DashboardsQueryResult = Apollo.QueryResult<DashboardsQuery, DashboardsQueryVariables>;
export const InsertDashboardDocument = gql`
    mutation InsertDashboard($object: dw_Dashboards_insert_input!) {
  insert_dw_Dashboards_one(object: $object) {
    DashboardId
  }
}
    `;
export type InsertDashboardMutationFn = Apollo.MutationFunction<InsertDashboardMutation, InsertDashboardMutationVariables>;

/**
 * __useInsertDashboardMutation__
 *
 * To run a mutation, you first call `useInsertDashboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertDashboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertDashboardMutation, { data, loading, error }] = useInsertDashboardMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertDashboardMutation(baseOptions?: Apollo.MutationHookOptions<InsertDashboardMutation, InsertDashboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertDashboardMutation, InsertDashboardMutationVariables>(InsertDashboardDocument, options);
      }
export type InsertDashboardMutationHookResult = ReturnType<typeof useInsertDashboardMutation>;
export type InsertDashboardMutationResult = Apollo.MutationResult<InsertDashboardMutation>;
export type InsertDashboardMutationOptions = Apollo.BaseMutationOptions<InsertDashboardMutation, InsertDashboardMutationVariables>;
export const UpdateDashboardsDocument = gql`
    mutation UpdateDashboards($DashboardId: uuid!, $Label: String, $Code: String!, $WeatherColor: _int4!, $BackgroundColor: _int4!, $ElectricityColor: _int4!, $GasColor: _int4!, $WaterColor: _int4!, $HasBackground: Boolean!, $ShowWeather: Boolean!, $Status: smallint!, $UpdatedOn: timestamp!) {
  update_dw_Dashboards_by_pk(
    pk_columns: {DashboardId: $DashboardId}
    _set: {Label: $Label, Code: $Code, WeatherColor: $WeatherColor, BackgroundColor: $BackgroundColor, ElectricityColor: $ElectricityColor, GasColor: $GasColor, WaterColor: $WaterColor, HasBackground: $HasBackground, ShowWeather: $ShowWeather, Status: $Status, UpdatedOn: $UpdatedOn}
  ) {
    DashboardId
    UpdatedOn
  }
}
    `;
export type UpdateDashboardsMutationFn = Apollo.MutationFunction<UpdateDashboardsMutation, UpdateDashboardsMutationVariables>;

/**
 * __useUpdateDashboardsMutation__
 *
 * To run a mutation, you first call `useUpdateDashboardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDashboardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDashboardsMutation, { data, loading, error }] = useUpdateDashboardsMutation({
 *   variables: {
 *      DashboardId: // value for 'DashboardId'
 *      Label: // value for 'Label'
 *      Code: // value for 'Code'
 *      WeatherColor: // value for 'WeatherColor'
 *      BackgroundColor: // value for 'BackgroundColor'
 *      ElectricityColor: // value for 'ElectricityColor'
 *      GasColor: // value for 'GasColor'
 *      WaterColor: // value for 'WaterColor'
 *      HasBackground: // value for 'HasBackground'
 *      ShowWeather: // value for 'ShowWeather'
 *      Status: // value for 'Status'
 *      UpdatedOn: // value for 'UpdatedOn'
 *   },
 * });
 */
export function useUpdateDashboardsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDashboardsMutation, UpdateDashboardsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDashboardsMutation, UpdateDashboardsMutationVariables>(UpdateDashboardsDocument, options);
      }
export type UpdateDashboardsMutationHookResult = ReturnType<typeof useUpdateDashboardsMutation>;
export type UpdateDashboardsMutationResult = Apollo.MutationResult<UpdateDashboardsMutation>;
export type UpdateDashboardsMutationOptions = Apollo.BaseMutationOptions<UpdateDashboardsMutation, UpdateDashboardsMutationVariables>;
export const UpdateDashboardsAssociationsDocument = gql`
    mutation UpdateDashboardsAssociations($DashboardId: uuid!, $BuildingId: uuid, $AccountId: uuid, $UpdatedOn: timestamp!) {
  update_dw_Dashboards_by_pk(
    pk_columns: {DashboardId: $DashboardId}
    _set: {BuildingId: $BuildingId, AccountId: $AccountId, UpdatedOn: $UpdatedOn}
  ) {
    DashboardId
    UpdatedOn
  }
}
    `;
export type UpdateDashboardsAssociationsMutationFn = Apollo.MutationFunction<UpdateDashboardsAssociationsMutation, UpdateDashboardsAssociationsMutationVariables>;

/**
 * __useUpdateDashboardsAssociationsMutation__
 *
 * To run a mutation, you first call `useUpdateDashboardsAssociationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDashboardsAssociationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDashboardsAssociationsMutation, { data, loading, error }] = useUpdateDashboardsAssociationsMutation({
 *   variables: {
 *      DashboardId: // value for 'DashboardId'
 *      BuildingId: // value for 'BuildingId'
 *      AccountId: // value for 'AccountId'
 *      UpdatedOn: // value for 'UpdatedOn'
 *   },
 * });
 */
export function useUpdateDashboardsAssociationsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDashboardsAssociationsMutation, UpdateDashboardsAssociationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDashboardsAssociationsMutation, UpdateDashboardsAssociationsMutationVariables>(UpdateDashboardsAssociationsDocument, options);
      }
export type UpdateDashboardsAssociationsMutationHookResult = ReturnType<typeof useUpdateDashboardsAssociationsMutation>;
export type UpdateDashboardsAssociationsMutationResult = Apollo.MutationResult<UpdateDashboardsAssociationsMutation>;
export type UpdateDashboardsAssociationsMutationOptions = Apollo.BaseMutationOptions<UpdateDashboardsAssociationsMutation, UpdateDashboardsAssociationsMutationVariables>;
export const FeedSearchAllDocument = gql`
    query FeedSearchAll($search: String) {
  dw_Feeds(
    where: {Status: {_eq: 1}, _or: [{Label: {_ilike: $search}}, {Notes: {_ilike: $search}}, {SerialNumber: {_ilike: $search}}, {SimCard: {_ilike: $search}}]}
    limit: 30
    order_by: {Label: asc}
  ) {
    FeedId
    Label
    Notes
    SerialNumber
    SimCard
    Building {
      BuildingId
      Label
    }
    FeedsType {
      TypeId
      Label
    }
  }
}
    `;

/**
 * __useFeedSearchAllQuery__
 *
 * To run a query within a React component, call `useFeedSearchAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedSearchAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedSearchAllQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useFeedSearchAllQuery(baseOptions?: Apollo.QueryHookOptions<FeedSearchAllQuery, FeedSearchAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeedSearchAllQuery, FeedSearchAllQueryVariables>(FeedSearchAllDocument, options);
      }
export function useFeedSearchAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeedSearchAllQuery, FeedSearchAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeedSearchAllQuery, FeedSearchAllQueryVariables>(FeedSearchAllDocument, options);
        }
export type FeedSearchAllQueryHookResult = ReturnType<typeof useFeedSearchAllQuery>;
export type FeedSearchAllLazyQueryHookResult = ReturnType<typeof useFeedSearchAllLazyQuery>;
export type FeedSearchAllQueryResult = Apollo.QueryResult<FeedSearchAllQuery, FeedSearchAllQueryVariables>;
export const FeedSearchDocument = gql`
    query FeedSearch($AccountId: uuid, $BuildingId: uuid, $search: String) {
  dw_Feeds(
    where: {BuildingId: {_eq: $BuildingId}, Building: {Site: {AccountId: {_eq: $AccountId}}}, Status: {_eq: 1}, _or: [{Label: {_ilike: $search}}, {Notes: {_ilike: $search}}, {SerialNumber: {_ilike: $search}}, {SimCard: {_ilike: $search}}]}
    limit: 30
    order_by: {Label: asc}
  ) {
    FeedId
    Label
    Notes
    SerialNumber
    SimCard
    Building {
      BuildingId
      Label
    }
    FeedsType {
      TypeId
      Label
    }
  }
}
    `;

/**
 * __useFeedSearchQuery__
 *
 * To run a query within a React component, call `useFeedSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedSearchQuery({
 *   variables: {
 *      AccountId: // value for 'AccountId'
 *      BuildingId: // value for 'BuildingId'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useFeedSearchQuery(baseOptions?: Apollo.QueryHookOptions<FeedSearchQuery, FeedSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeedSearchQuery, FeedSearchQueryVariables>(FeedSearchDocument, options);
      }
export function useFeedSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeedSearchQuery, FeedSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeedSearchQuery, FeedSearchQueryVariables>(FeedSearchDocument, options);
        }
export type FeedSearchQueryHookResult = ReturnType<typeof useFeedSearchQuery>;
export type FeedSearchLazyQueryHookResult = ReturnType<typeof useFeedSearchLazyQuery>;
export type FeedSearchQueryResult = Apollo.QueryResult<FeedSearchQuery, FeedSearchQueryVariables>;
export const FeedsTypeSearchDocument = gql`
    query FeedsTypeSearch($search: String) {
  dw_FeedsTypes(
    where: {Label: {_ilike: $search}}
    limit: 30
    order_by: {Label: asc}
  ) {
    TypeId
    Label
  }
}
    `;

/**
 * __useFeedsTypeSearchQuery__
 *
 * To run a query within a React component, call `useFeedsTypeSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedsTypeSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedsTypeSearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useFeedsTypeSearchQuery(baseOptions?: Apollo.QueryHookOptions<FeedsTypeSearchQuery, FeedsTypeSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeedsTypeSearchQuery, FeedsTypeSearchQueryVariables>(FeedsTypeSearchDocument, options);
      }
export function useFeedsTypeSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeedsTypeSearchQuery, FeedsTypeSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeedsTypeSearchQuery, FeedsTypeSearchQueryVariables>(FeedsTypeSearchDocument, options);
        }
export type FeedsTypeSearchQueryHookResult = ReturnType<typeof useFeedsTypeSearchQuery>;
export type FeedsTypeSearchLazyQueryHookResult = ReturnType<typeof useFeedsTypeSearchLazyQuery>;
export type FeedsTypeSearchQueryResult = Apollo.QueryResult<FeedsTypeSearchQuery, FeedsTypeSearchQueryVariables>;
export const FeedDetailsDocument = gql`
    query FeedDetails($feedId: uuid!) {
  dw_Feeds_by_pk(FeedId: $feedId) {
    ...FeedFields
  }
}
    ${FeedFieldsFragmentDoc}`;

/**
 * __useFeedDetailsQuery__
 *
 * To run a query within a React component, call `useFeedDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedDetailsQuery({
 *   variables: {
 *      feedId: // value for 'feedId'
 *   },
 * });
 */
export function useFeedDetailsQuery(baseOptions: Apollo.QueryHookOptions<FeedDetailsQuery, FeedDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeedDetailsQuery, FeedDetailsQueryVariables>(FeedDetailsDocument, options);
      }
export function useFeedDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeedDetailsQuery, FeedDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeedDetailsQuery, FeedDetailsQueryVariables>(FeedDetailsDocument, options);
        }
export type FeedDetailsQueryHookResult = ReturnType<typeof useFeedDetailsQuery>;
export type FeedDetailsLazyQueryHookResult = ReturnType<typeof useFeedDetailsLazyQuery>;
export type FeedDetailsQueryResult = Apollo.QueryResult<FeedDetailsQuery, FeedDetailsQueryVariables>;
export const AllFeedsDocument = gql`
    query AllFeeds($feedId: uuid, $feedIds: [uuid!], $status: smallint, $search: String, $offset: Int!, $limit: Int!, $order_by: [dw_Feeds_order_by!]) {
  dw_Feeds(
    where: {FeedId: {_eq: $feedId, _in: $feedIds}, Status: {_eq: $status}, _or: [{Label: {_ilike: $search}}, {Notes: {_ilike: $search}}, {SerialNumber: {_ilike: $search}}, {FeedsType: {Label: {_ilike: $search}}}, {Building: {Label: {_ilike: $search}}}, {Building: {Site: {Label: {_ilike: $search}}}}, {Building: {Site: {Account: {Label: {_ilike: $search}}}}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...FeedFields
  }
  dw_Feeds_aggregate(
    where: {FeedId: {_eq: $feedId, _in: $feedIds}, Status: {_eq: $status}, _or: [{Label: {_ilike: $search}}, {Notes: {_ilike: $search}}, {SerialNumber: {_ilike: $search}}, {FeedsType: {Label: {_ilike: $search}}}, {Building: {Label: {_ilike: $search}}}, {Building: {Site: {Label: {_ilike: $search}}}}, {Building: {Site: {Account: {Label: {_ilike: $search}}}}}]}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${FeedFieldsFragmentDoc}`;

/**
 * __useAllFeedsQuery__
 *
 * To run a query within a React component, call `useAllFeedsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFeedsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllFeedsQuery({
 *   variables: {
 *      feedId: // value for 'feedId'
 *      feedIds: // value for 'feedIds'
 *      status: // value for 'status'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useAllFeedsQuery(baseOptions: Apollo.QueryHookOptions<AllFeedsQuery, AllFeedsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllFeedsQuery, AllFeedsQueryVariables>(AllFeedsDocument, options);
      }
export function useAllFeedsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllFeedsQuery, AllFeedsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllFeedsQuery, AllFeedsQueryVariables>(AllFeedsDocument, options);
        }
export type AllFeedsQueryHookResult = ReturnType<typeof useAllFeedsQuery>;
export type AllFeedsLazyQueryHookResult = ReturnType<typeof useAllFeedsLazyQuery>;
export type AllFeedsQueryResult = Apollo.QueryResult<AllFeedsQuery, AllFeedsQueryVariables>;
export const FeedsDocument = gql`
    query Feeds($feedId: uuid, $feedIds: [uuid!], $buildingId: uuid, $siteId: uuid, $accountId: uuid, $status: smallint, $search: String, $offset: Int!, $limit: Int!, $order_by: [dw_Feeds_order_by!]) {
  dw_Feeds(
    where: {FeedId: {_eq: $feedId, _in: $feedIds}, Building: {BuildingId: {_eq: $buildingId}, Site: {SiteId: {_eq: $siteId}, Account: {AccountId: {_eq: $accountId}}}}, Status: {_eq: $status}, _or: [{FeedId: {_in: $feedIds}}, {Label: {_ilike: $search}}, {Notes: {_ilike: $search}}, {SerialNumber: {_ilike: $search}}, {FeedsType: {Label: {_ilike: $search}}}, {Building: {Label: {_ilike: $search}}}, {Building: {Site: {Label: {_ilike: $search}}}}, {Building: {Site: {Account: {Label: {_ilike: $search}}}}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...FeedFields
  }
  dw_Feeds_aggregate(
    where: {FeedId: {_eq: $feedId, _in: $feedIds}, Building: {BuildingId: {_eq: $buildingId}, Site: {SiteId: {_eq: $siteId}, Account: {AccountId: {_eq: $accountId}}}}, Status: {_eq: $status}, _or: [{FeedId: {_in: $feedIds}}, {Label: {_ilike: $search}}, {Notes: {_ilike: $search}}, {SerialNumber: {_ilike: $search}}, {FeedsType: {Label: {_ilike: $search}}}, {Building: {Label: {_ilike: $search}}}, {Building: {Site: {Label: {_ilike: $search}}}}, {Building: {Site: {Account: {Label: {_ilike: $search}}}}}]}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${FeedFieldsFragmentDoc}`;

/**
 * __useFeedsQuery__
 *
 * To run a query within a React component, call `useFeedsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedsQuery({
 *   variables: {
 *      feedId: // value for 'feedId'
 *      feedIds: // value for 'feedIds'
 *      buildingId: // value for 'buildingId'
 *      siteId: // value for 'siteId'
 *      accountId: // value for 'accountId'
 *      status: // value for 'status'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useFeedsQuery(baseOptions: Apollo.QueryHookOptions<FeedsQuery, FeedsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeedsQuery, FeedsQueryVariables>(FeedsDocument, options);
      }
export function useFeedsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeedsQuery, FeedsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeedsQuery, FeedsQueryVariables>(FeedsDocument, options);
        }
export type FeedsQueryHookResult = ReturnType<typeof useFeedsQuery>;
export type FeedsLazyQueryHookResult = ReturnType<typeof useFeedsLazyQuery>;
export type FeedsQueryResult = Apollo.QueryResult<FeedsQuery, FeedsQueryVariables>;
export const InsertFeedDocument = gql`
    mutation InsertFeed($object: dw_Feeds_insert_input!) {
  insert_dw_Feeds_one(object: $object) {
    FeedId
  }
}
    `;
export type InsertFeedMutationFn = Apollo.MutationFunction<InsertFeedMutation, InsertFeedMutationVariables>;

/**
 * __useInsertFeedMutation__
 *
 * To run a mutation, you first call `useInsertFeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertFeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertFeedMutation, { data, loading, error }] = useInsertFeedMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertFeedMutation(baseOptions?: Apollo.MutationHookOptions<InsertFeedMutation, InsertFeedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertFeedMutation, InsertFeedMutationVariables>(InsertFeedDocument, options);
      }
export type InsertFeedMutationHookResult = ReturnType<typeof useInsertFeedMutation>;
export type InsertFeedMutationResult = Apollo.MutationResult<InsertFeedMutation>;
export type InsertFeedMutationOptions = Apollo.BaseMutationOptions<InsertFeedMutation, InsertFeedMutationVariables>;
export const UpdateFeedsDocument = gql`
    mutation UpdateFeeds($FeedId: uuid!, $Label: String!, $SerialNumber: String, $SimCard: String, $BatteryDailyUsage: numeric, $BatteryPercentage: numeric, $Temperature: numeric, $UserName: String, $Password: String, $Notes: String, $Url: String!, $ProductionMode: Boolean!, $IntervalEmitFrequency: smallint!, $Status: smallint!, $LastSync: timestamp!, $ActivatedOn: timestamp!, $UpdatedOn: timestamp!) {
  update_dw_Feeds_by_pk(
    pk_columns: {FeedId: $FeedId}
    _set: {Label: $Label, SerialNumber: $SerialNumber, SimCard: $SimCard, BatteryDailyUsage: $BatteryDailyUsage, BatteryPercentage: $BatteryPercentage, Temperature: $Temperature, UserName: $UserName, Password: $Password, Notes: $Notes, Status: $Status, Url: $Url, ProductionMode: $ProductionMode, IntervalEmitFrequency: $IntervalEmitFrequency, LastSync: $LastSync, ActivatedOn: $ActivatedOn, UpdatedOn: $UpdatedOn}
  ) {
    FeedId
  }
}
    `;
export type UpdateFeedsMutationFn = Apollo.MutationFunction<UpdateFeedsMutation, UpdateFeedsMutationVariables>;

/**
 * __useUpdateFeedsMutation__
 *
 * To run a mutation, you first call `useUpdateFeedsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeedsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeedsMutation, { data, loading, error }] = useUpdateFeedsMutation({
 *   variables: {
 *      FeedId: // value for 'FeedId'
 *      Label: // value for 'Label'
 *      SerialNumber: // value for 'SerialNumber'
 *      SimCard: // value for 'SimCard'
 *      BatteryDailyUsage: // value for 'BatteryDailyUsage'
 *      BatteryPercentage: // value for 'BatteryPercentage'
 *      Temperature: // value for 'Temperature'
 *      UserName: // value for 'UserName'
 *      Password: // value for 'Password'
 *      Notes: // value for 'Notes'
 *      Url: // value for 'Url'
 *      ProductionMode: // value for 'ProductionMode'
 *      IntervalEmitFrequency: // value for 'IntervalEmitFrequency'
 *      Status: // value for 'Status'
 *      LastSync: // value for 'LastSync'
 *      ActivatedOn: // value for 'ActivatedOn'
 *      UpdatedOn: // value for 'UpdatedOn'
 *   },
 * });
 */
export function useUpdateFeedsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFeedsMutation, UpdateFeedsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFeedsMutation, UpdateFeedsMutationVariables>(UpdateFeedsDocument, options);
      }
export type UpdateFeedsMutationHookResult = ReturnType<typeof useUpdateFeedsMutation>;
export type UpdateFeedsMutationResult = Apollo.MutationResult<UpdateFeedsMutation>;
export type UpdateFeedsMutationOptions = Apollo.BaseMutationOptions<UpdateFeedsMutation, UpdateFeedsMutationVariables>;
export const UpdateFeedAssociationsDocument = gql`
    mutation UpdateFeedAssociations($FeedId: uuid!, $BuildingId: uuid, $TypeId: uuid, $UpdatedOn: timestamp!) {
  update_dw_Feeds_by_pk(
    pk_columns: {FeedId: $FeedId}
    _set: {BuildingId: $BuildingId, TypeId: $TypeId, UpdatedOn: $UpdatedOn}
  ) {
    FeedId
    UpdatedOn
  }
}
    `;
export type UpdateFeedAssociationsMutationFn = Apollo.MutationFunction<UpdateFeedAssociationsMutation, UpdateFeedAssociationsMutationVariables>;

/**
 * __useUpdateFeedAssociationsMutation__
 *
 * To run a mutation, you first call `useUpdateFeedAssociationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeedAssociationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeedAssociationsMutation, { data, loading, error }] = useUpdateFeedAssociationsMutation({
 *   variables: {
 *      FeedId: // value for 'FeedId'
 *      BuildingId: // value for 'BuildingId'
 *      TypeId: // value for 'TypeId'
 *      UpdatedOn: // value for 'UpdatedOn'
 *   },
 * });
 */
export function useUpdateFeedAssociationsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFeedAssociationsMutation, UpdateFeedAssociationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFeedAssociationsMutation, UpdateFeedAssociationsMutationVariables>(UpdateFeedAssociationsDocument, options);
      }
export type UpdateFeedAssociationsMutationHookResult = ReturnType<typeof useUpdateFeedAssociationsMutation>;
export type UpdateFeedAssociationsMutationResult = Apollo.MutationResult<UpdateFeedAssociationsMutation>;
export type UpdateFeedAssociationsMutationOptions = Apollo.BaseMutationOptions<UpdateFeedAssociationsMutation, UpdateFeedAssociationsMutationVariables>;
export const FeedsConfigBacNetDetailsDocument = gql`
    query FeedsConfigBacNetDetails($channelId: uuid!, $feedId: uuid!) {
  dw_FeedsConfigBacNet_by_pk(ChannelId: $channelId, FeedId: $feedId) {
    ...FeedsConfigBacNetFields
  }
}
    ${FeedsConfigBacNetFieldsFragmentDoc}`;

/**
 * __useFeedsConfigBacNetDetailsQuery__
 *
 * To run a query within a React component, call `useFeedsConfigBacNetDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedsConfigBacNetDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedsConfigBacNetDetailsQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      feedId: // value for 'feedId'
 *   },
 * });
 */
export function useFeedsConfigBacNetDetailsQuery(baseOptions: Apollo.QueryHookOptions<FeedsConfigBacNetDetailsQuery, FeedsConfigBacNetDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeedsConfigBacNetDetailsQuery, FeedsConfigBacNetDetailsQueryVariables>(FeedsConfigBacNetDetailsDocument, options);
      }
export function useFeedsConfigBacNetDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeedsConfigBacNetDetailsQuery, FeedsConfigBacNetDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeedsConfigBacNetDetailsQuery, FeedsConfigBacNetDetailsQueryVariables>(FeedsConfigBacNetDetailsDocument, options);
        }
export type FeedsConfigBacNetDetailsQueryHookResult = ReturnType<typeof useFeedsConfigBacNetDetailsQuery>;
export type FeedsConfigBacNetDetailsLazyQueryHookResult = ReturnType<typeof useFeedsConfigBacNetDetailsLazyQuery>;
export type FeedsConfigBacNetDetailsQueryResult = Apollo.QueryResult<FeedsConfigBacNetDetailsQuery, FeedsConfigBacNetDetailsQueryVariables>;
export const FeedsConfigBacNetsDocument = gql`
    query FeedsConfigBacNets($channelId: uuid, $loadId: uuid, $feedId: uuid, $buildingId: uuid, $siteId: uuid, $accountId: uuid, $serialNumber: String, $status: smallint, $search: String, $offset: Int!, $limit: Int!, $order_by: [dw_FeedsConfigBacNet_order_by!]) {
  dw_FeedsConfigBacNet(
    where: {ChannelId: {_eq: $channelId}, Feed: {FeedId: {_eq: $feedId}, SerialNumber: {_eq: $serialNumber}}, Channel: {LoadId: {_eq: $loadId}, Load: {BuildingId: {_eq: $buildingId}, Building: {SiteId: {_eq: $siteId}, Site: {AccountId: {_eq: $accountId}}}}}, Status: {_eq: $status}, _or: [{Channel: {Label: {_ilike: $search}}}, {Channel: {ChannelKey: {_ilike: $search}}}, {Channel: {Feed: {Label: {_ilike: $search}}}}, {Channel: {Feed: {SerialNumber: {_ilike: $search}}}}, {Channel: {Load: {Label: {_ilike: $search}}}}, {Channel: {Load: {Building: {Label: {_ilike: $search}}}}}, {Channel: {Building: {Site: {Label: {_ilike: $search}}}}}, {Channel: {Building: {Site: {Account: {Label: {_ilike: $search}}}}}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...FeedsConfigBacNetFields
  }
  dw_FeedsConfigBacNet_aggregate(
    where: {ChannelId: {_eq: $channelId}, Feed: {FeedId: {_eq: $feedId}, SerialNumber: {_eq: $serialNumber}}, Channel: {LoadId: {_eq: $loadId}, Load: {BuildingId: {_eq: $buildingId}, Building: {SiteId: {_eq: $siteId}, Site: {AccountId: {_eq: $accountId}}}}}, Status: {_eq: $status}, _or: [{Channel: {Label: {_ilike: $search}}}, {Channel: {ChannelKey: {_ilike: $search}}}, {Channel: {Feed: {Label: {_ilike: $search}}}}, {Channel: {Feed: {SerialNumber: {_ilike: $search}}}}, {Channel: {Load: {Label: {_ilike: $search}}}}, {Channel: {Load: {Building: {Label: {_ilike: $search}}}}}, {Channel: {Building: {Site: {Label: {_ilike: $search}}}}}, {Channel: {Building: {Site: {Account: {Label: {_ilike: $search}}}}}}]}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${FeedsConfigBacNetFieldsFragmentDoc}`;

/**
 * __useFeedsConfigBacNetsQuery__
 *
 * To run a query within a React component, call `useFeedsConfigBacNetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedsConfigBacNetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedsConfigBacNetsQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      loadId: // value for 'loadId'
 *      feedId: // value for 'feedId'
 *      buildingId: // value for 'buildingId'
 *      siteId: // value for 'siteId'
 *      accountId: // value for 'accountId'
 *      serialNumber: // value for 'serialNumber'
 *      status: // value for 'status'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useFeedsConfigBacNetsQuery(baseOptions: Apollo.QueryHookOptions<FeedsConfigBacNetsQuery, FeedsConfigBacNetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeedsConfigBacNetsQuery, FeedsConfigBacNetsQueryVariables>(FeedsConfigBacNetsDocument, options);
      }
export function useFeedsConfigBacNetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeedsConfigBacNetsQuery, FeedsConfigBacNetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeedsConfigBacNetsQuery, FeedsConfigBacNetsQueryVariables>(FeedsConfigBacNetsDocument, options);
        }
export type FeedsConfigBacNetsQueryHookResult = ReturnType<typeof useFeedsConfigBacNetsQuery>;
export type FeedsConfigBacNetsLazyQueryHookResult = ReturnType<typeof useFeedsConfigBacNetsLazyQuery>;
export type FeedsConfigBacNetsQueryResult = Apollo.QueryResult<FeedsConfigBacNetsQuery, FeedsConfigBacNetsQueryVariables>;
export const InsertFeedConfigBacNetDocument = gql`
    mutation InsertFeedConfigBacNet($object: dw_FeedsConfigBacNet_insert_input!) {
  insert_dw_FeedsConfigBacNet_one(object: $object) {
    ChannelId
    FeedId
  }
}
    `;
export type InsertFeedConfigBacNetMutationFn = Apollo.MutationFunction<InsertFeedConfigBacNetMutation, InsertFeedConfigBacNetMutationVariables>;

/**
 * __useInsertFeedConfigBacNetMutation__
 *
 * To run a mutation, you first call `useInsertFeedConfigBacNetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertFeedConfigBacNetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertFeedConfigBacNetMutation, { data, loading, error }] = useInsertFeedConfigBacNetMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertFeedConfigBacNetMutation(baseOptions?: Apollo.MutationHookOptions<InsertFeedConfigBacNetMutation, InsertFeedConfigBacNetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertFeedConfigBacNetMutation, InsertFeedConfigBacNetMutationVariables>(InsertFeedConfigBacNetDocument, options);
      }
export type InsertFeedConfigBacNetMutationHookResult = ReturnType<typeof useInsertFeedConfigBacNetMutation>;
export type InsertFeedConfigBacNetMutationResult = Apollo.MutationResult<InsertFeedConfigBacNetMutation>;
export type InsertFeedConfigBacNetMutationOptions = Apollo.BaseMutationOptions<InsertFeedConfigBacNetMutation, InsertFeedConfigBacNetMutationVariables>;
export const UpdateFeedConfigBacNetDocument = gql`
    mutation UpdateFeedConfigBacNet($ChannelId: uuid!, $FeedId: uuid!, $ObjectId: String, $DeviceId: String, $CalculateDelta: Boolean, $ObjectInstance: Int, $ReadFrequencySeconds: Int, $Status: smallint, $CreatedOn: timestamp!, $UpdatedOn: timestamp!) {
  update_dw_FeedsConfigBacNet_by_pk(
    pk_columns: {ChannelId: $ChannelId, FeedId: $FeedId}
    _set: {ChannelId: $ChannelId, FeedId: $FeedId, ObjectId: $ObjectId, DeviceId: $DeviceId, CalculateDelta: $CalculateDelta, ObjectInstance: $ObjectInstance, ReadFrequencySeconds: $ReadFrequencySeconds, Status: $Status, CreatedOn: $CreatedOn, UpdatedOn: $UpdatedOn}
  ) {
    ChannelId
  }
}
    `;
export type UpdateFeedConfigBacNetMutationFn = Apollo.MutationFunction<UpdateFeedConfigBacNetMutation, UpdateFeedConfigBacNetMutationVariables>;

/**
 * __useUpdateFeedConfigBacNetMutation__
 *
 * To run a mutation, you first call `useUpdateFeedConfigBacNetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeedConfigBacNetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeedConfigBacNetMutation, { data, loading, error }] = useUpdateFeedConfigBacNetMutation({
 *   variables: {
 *      ChannelId: // value for 'ChannelId'
 *      FeedId: // value for 'FeedId'
 *      ObjectId: // value for 'ObjectId'
 *      DeviceId: // value for 'DeviceId'
 *      CalculateDelta: // value for 'CalculateDelta'
 *      ObjectInstance: // value for 'ObjectInstance'
 *      ReadFrequencySeconds: // value for 'ReadFrequencySeconds'
 *      Status: // value for 'Status'
 *      CreatedOn: // value for 'CreatedOn'
 *      UpdatedOn: // value for 'UpdatedOn'
 *   },
 * });
 */
export function useUpdateFeedConfigBacNetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFeedConfigBacNetMutation, UpdateFeedConfigBacNetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFeedConfigBacNetMutation, UpdateFeedConfigBacNetMutationVariables>(UpdateFeedConfigBacNetDocument, options);
      }
export type UpdateFeedConfigBacNetMutationHookResult = ReturnType<typeof useUpdateFeedConfigBacNetMutation>;
export type UpdateFeedConfigBacNetMutationResult = Apollo.MutationResult<UpdateFeedConfigBacNetMutation>;
export type UpdateFeedConfigBacNetMutationOptions = Apollo.BaseMutationOptions<UpdateFeedConfigBacNetMutation, UpdateFeedConfigBacNetMutationVariables>;
export const DeleteFeedConfigBacNetDocument = gql`
    mutation DeleteFeedConfigBacNet($ChannelId: uuid!, $FeedId: uuid!) {
  delete_dw_FeedsConfigBacNet_by_pk(ChannelId: $ChannelId, FeedId: $FeedId) {
    ChannelId
  }
}
    `;
export type DeleteFeedConfigBacNetMutationFn = Apollo.MutationFunction<DeleteFeedConfigBacNetMutation, DeleteFeedConfigBacNetMutationVariables>;

/**
 * __useDeleteFeedConfigBacNetMutation__
 *
 * To run a mutation, you first call `useDeleteFeedConfigBacNetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFeedConfigBacNetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFeedConfigBacNetMutation, { data, loading, error }] = useDeleteFeedConfigBacNetMutation({
 *   variables: {
 *      ChannelId: // value for 'ChannelId'
 *      FeedId: // value for 'FeedId'
 *   },
 * });
 */
export function useDeleteFeedConfigBacNetMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFeedConfigBacNetMutation, DeleteFeedConfigBacNetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFeedConfigBacNetMutation, DeleteFeedConfigBacNetMutationVariables>(DeleteFeedConfigBacNetDocument, options);
      }
export type DeleteFeedConfigBacNetMutationHookResult = ReturnType<typeof useDeleteFeedConfigBacNetMutation>;
export type DeleteFeedConfigBacNetMutationResult = Apollo.MutationResult<DeleteFeedConfigBacNetMutation>;
export type DeleteFeedConfigBacNetMutationOptions = Apollo.BaseMutationOptions<DeleteFeedConfigBacNetMutation, DeleteFeedConfigBacNetMutationVariables>;
export const FeedsConfigModbusDocument = gql`
    query FeedsConfigModbus($channelId: uuid!, $feedId: uuid!) {
  dw_FeedsConfigModbus_by_pk(ChannelId: $channelId, FeedId: $feedId) {
    ...FeedsConfigModbusDetailFields
  }
}
    ${FeedsConfigModbusDetailFieldsFragmentDoc}`;

/**
 * __useFeedsConfigModbusQuery__
 *
 * To run a query within a React component, call `useFeedsConfigModbusQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedsConfigModbusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedsConfigModbusQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      feedId: // value for 'feedId'
 *   },
 * });
 */
export function useFeedsConfigModbusQuery(baseOptions: Apollo.QueryHookOptions<FeedsConfigModbusQuery, FeedsConfigModbusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeedsConfigModbusQuery, FeedsConfigModbusQueryVariables>(FeedsConfigModbusDocument, options);
      }
export function useFeedsConfigModbusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeedsConfigModbusQuery, FeedsConfigModbusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeedsConfigModbusQuery, FeedsConfigModbusQueryVariables>(FeedsConfigModbusDocument, options);
        }
export type FeedsConfigModbusQueryHookResult = ReturnType<typeof useFeedsConfigModbusQuery>;
export type FeedsConfigModbusLazyQueryHookResult = ReturnType<typeof useFeedsConfigModbusLazyQuery>;
export type FeedsConfigModbusQueryResult = Apollo.QueryResult<FeedsConfigModbusQuery, FeedsConfigModbusQueryVariables>;
export const FeedsConfigModbusesDocument = gql`
    query FeedsConfigModbuses($channelId: uuid, $loadId: uuid, $feedId: uuid, $buildingId: uuid, $siteId: uuid, $accountId: uuid, $serialNumber: String, $status: smallint, $search: String, $offset: Int!, $limit: Int!, $order_by: [dw_FeedsConfigModbus_order_by!]) {
  dw_FeedsConfigModbus(
    where: {ChannelId: {_eq: $channelId}, Feed: {FeedId: {_eq: $feedId}, SerialNumber: {_eq: $serialNumber}}, Channel: {LoadId: {_eq: $loadId}, Load: {BuildingId: {_eq: $buildingId}, Building: {SiteId: {_eq: $siteId}, Site: {AccountId: {_eq: $accountId}}}}}, Status: {_eq: $status}, _or: [{Channel: {Label: {_ilike: $search}}}, {Channel: {ChannelKey: {_ilike: $search}}}, {Channel: {Feed: {Label: {_ilike: $search}}}}, {Channel: {Feed: {SerialNumber: {_ilike: $search}}}}, {Channel: {Load: {Label: {_ilike: $search}}}}, {Channel: {Load: {Building: {Label: {_ilike: $search}}}}}, {Channel: {Building: {Site: {Label: {_ilike: $search}}}}}, {Channel: {Building: {Site: {Account: {Label: {_ilike: $search}}}}}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...FeedsConfigModbusFields
  }
  dw_FeedsConfigModbus_aggregate(
    where: {ChannelId: {_eq: $channelId}, Feed: {FeedId: {_eq: $feedId}, SerialNumber: {_eq: $serialNumber}}, Channel: {LoadId: {_eq: $loadId}, Load: {BuildingId: {_eq: $buildingId}, Building: {SiteId: {_eq: $siteId}, Site: {AccountId: {_eq: $accountId}}}}}, Status: {_eq: $status}, _or: [{Channel: {Label: {_ilike: $search}}}, {Channel: {ChannelKey: {_ilike: $search}}}, {Channel: {Feed: {Label: {_ilike: $search}}}}, {Channel: {Feed: {SerialNumber: {_ilike: $search}}}}, {Channel: {Load: {Label: {_ilike: $search}}}}, {Channel: {Load: {Building: {Label: {_ilike: $search}}}}}, {Channel: {Building: {Site: {Label: {_ilike: $search}}}}}, {Channel: {Building: {Site: {Account: {Label: {_ilike: $search}}}}}}]}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${FeedsConfigModbusFieldsFragmentDoc}`;

/**
 * __useFeedsConfigModbusesQuery__
 *
 * To run a query within a React component, call `useFeedsConfigModbusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedsConfigModbusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedsConfigModbusesQuery({
 *   variables: {
 *      channelId: // value for 'channelId'
 *      loadId: // value for 'loadId'
 *      feedId: // value for 'feedId'
 *      buildingId: // value for 'buildingId'
 *      siteId: // value for 'siteId'
 *      accountId: // value for 'accountId'
 *      serialNumber: // value for 'serialNumber'
 *      status: // value for 'status'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useFeedsConfigModbusesQuery(baseOptions: Apollo.QueryHookOptions<FeedsConfigModbusesQuery, FeedsConfigModbusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeedsConfigModbusesQuery, FeedsConfigModbusesQueryVariables>(FeedsConfigModbusesDocument, options);
      }
export function useFeedsConfigModbusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeedsConfigModbusesQuery, FeedsConfigModbusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeedsConfigModbusesQuery, FeedsConfigModbusesQueryVariables>(FeedsConfigModbusesDocument, options);
        }
export type FeedsConfigModbusesQueryHookResult = ReturnType<typeof useFeedsConfigModbusesQuery>;
export type FeedsConfigModbusesLazyQueryHookResult = ReturnType<typeof useFeedsConfigModbusesLazyQuery>;
export type FeedsConfigModbusesQueryResult = Apollo.QueryResult<FeedsConfigModbusesQuery, FeedsConfigModbusesQueryVariables>;
export const InsertFeedConfigModbusDocument = gql`
    mutation InsertFeedConfigModbus($object: dw_FeedsConfigModbus_insert_input!) {
  insert_dw_FeedsConfigModbus_one(object: $object) {
    ChannelId
    FeedId
  }
}
    `;
export type InsertFeedConfigModbusMutationFn = Apollo.MutationFunction<InsertFeedConfigModbusMutation, InsertFeedConfigModbusMutationVariables>;

/**
 * __useInsertFeedConfigModbusMutation__
 *
 * To run a mutation, you first call `useInsertFeedConfigModbusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertFeedConfigModbusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertFeedConfigModbusMutation, { data, loading, error }] = useInsertFeedConfigModbusMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertFeedConfigModbusMutation(baseOptions?: Apollo.MutationHookOptions<InsertFeedConfigModbusMutation, InsertFeedConfigModbusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertFeedConfigModbusMutation, InsertFeedConfigModbusMutationVariables>(InsertFeedConfigModbusDocument, options);
      }
export type InsertFeedConfigModbusMutationHookResult = ReturnType<typeof useInsertFeedConfigModbusMutation>;
export type InsertFeedConfigModbusMutationResult = Apollo.MutationResult<InsertFeedConfigModbusMutation>;
export type InsertFeedConfigModbusMutationOptions = Apollo.BaseMutationOptions<InsertFeedConfigModbusMutation, InsertFeedConfigModbusMutationVariables>;
export const UpdateFeedConfigModbusDocument = gql`
    mutation UpdateFeedConfigModbus($ChannelId: uuid!, $FeedId: uuid!, $DeviceAddress: numeric, $ReadFrequencySeconds: Int, $RegisterAddress: Int, $RegisterCount: Int, $RegisterType: smallint, $RtuBaudRate: Int, $RtuDataBits: Int, $RtuParity: String, $RtuSerialPort: String, $RtuStopBits: String, $ScalingFactor: numeric, $TcpIpAddress: String, $TcpPort: Int, $CalculateDelta: Boolean, $IsBigEndian: Boolean, $Status: smallint, $UpdatedOn: timestamp!) {
  update_dw_FeedsConfigModbus_by_pk(
    pk_columns: {ChannelId: $ChannelId, FeedId: $FeedId}
    _set: {DeviceAddress: $DeviceAddress, ReadFrequencySeconds: $ReadFrequencySeconds, RegisterAddress: $RegisterAddress, RegisterCount: $RegisterCount, RegisterType: $RegisterType, RtuBaudRate: $RtuBaudRate, RtuDataBits: $RtuDataBits, RtuParity: $RtuParity, RtuSerialPort: $RtuSerialPort, RtuStopBits: $RtuStopBits, ScalingFactor: $ScalingFactor, TcpIpAddress: $TcpIpAddress, TcpPort: $TcpPort, CalculateDelta: $CalculateDelta, IsBigEndian: $IsBigEndian, Status: $Status, UpdatedOn: $UpdatedOn}
  ) {
    ChannelId
  }
}
    `;
export type UpdateFeedConfigModbusMutationFn = Apollo.MutationFunction<UpdateFeedConfigModbusMutation, UpdateFeedConfigModbusMutationVariables>;

/**
 * __useUpdateFeedConfigModbusMutation__
 *
 * To run a mutation, you first call `useUpdateFeedConfigModbusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeedConfigModbusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeedConfigModbusMutation, { data, loading, error }] = useUpdateFeedConfigModbusMutation({
 *   variables: {
 *      ChannelId: // value for 'ChannelId'
 *      FeedId: // value for 'FeedId'
 *      DeviceAddress: // value for 'DeviceAddress'
 *      ReadFrequencySeconds: // value for 'ReadFrequencySeconds'
 *      RegisterAddress: // value for 'RegisterAddress'
 *      RegisterCount: // value for 'RegisterCount'
 *      RegisterType: // value for 'RegisterType'
 *      RtuBaudRate: // value for 'RtuBaudRate'
 *      RtuDataBits: // value for 'RtuDataBits'
 *      RtuParity: // value for 'RtuParity'
 *      RtuSerialPort: // value for 'RtuSerialPort'
 *      RtuStopBits: // value for 'RtuStopBits'
 *      ScalingFactor: // value for 'ScalingFactor'
 *      TcpIpAddress: // value for 'TcpIpAddress'
 *      TcpPort: // value for 'TcpPort'
 *      CalculateDelta: // value for 'CalculateDelta'
 *      IsBigEndian: // value for 'IsBigEndian'
 *      Status: // value for 'Status'
 *      UpdatedOn: // value for 'UpdatedOn'
 *   },
 * });
 */
export function useUpdateFeedConfigModbusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFeedConfigModbusMutation, UpdateFeedConfigModbusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFeedConfigModbusMutation, UpdateFeedConfigModbusMutationVariables>(UpdateFeedConfigModbusDocument, options);
      }
export type UpdateFeedConfigModbusMutationHookResult = ReturnType<typeof useUpdateFeedConfigModbusMutation>;
export type UpdateFeedConfigModbusMutationResult = Apollo.MutationResult<UpdateFeedConfigModbusMutation>;
export type UpdateFeedConfigModbusMutationOptions = Apollo.BaseMutationOptions<UpdateFeedConfigModbusMutation, UpdateFeedConfigModbusMutationVariables>;
export const BulkInsertFeedConfigModbusesDocument = gql`
    mutation BulkInsertFeedConfigModbuses($objects: [dw_FeedsConfigModbus_insert_input!]!) {
  insert_dw_FeedsConfigModbus(objects: $objects) {
    affected_rows
  }
}
    `;
export type BulkInsertFeedConfigModbusesMutationFn = Apollo.MutationFunction<BulkInsertFeedConfigModbusesMutation, BulkInsertFeedConfigModbusesMutationVariables>;

/**
 * __useBulkInsertFeedConfigModbusesMutation__
 *
 * To run a mutation, you first call `useBulkInsertFeedConfigModbusesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkInsertFeedConfigModbusesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkInsertFeedConfigModbusesMutation, { data, loading, error }] = useBulkInsertFeedConfigModbusesMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useBulkInsertFeedConfigModbusesMutation(baseOptions?: Apollo.MutationHookOptions<BulkInsertFeedConfigModbusesMutation, BulkInsertFeedConfigModbusesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkInsertFeedConfigModbusesMutation, BulkInsertFeedConfigModbusesMutationVariables>(BulkInsertFeedConfigModbusesDocument, options);
      }
export type BulkInsertFeedConfigModbusesMutationHookResult = ReturnType<typeof useBulkInsertFeedConfigModbusesMutation>;
export type BulkInsertFeedConfigModbusesMutationResult = Apollo.MutationResult<BulkInsertFeedConfigModbusesMutation>;
export type BulkInsertFeedConfigModbusesMutationOptions = Apollo.BaseMutationOptions<BulkInsertFeedConfigModbusesMutation, BulkInsertFeedConfigModbusesMutationVariables>;
export const DeleteFeedConfigModbusDocument = gql`
    mutation DeleteFeedConfigModbus($ChannelId: uuid!, $FeedId: uuid!) {
  delete_dw_FeedsConfigModbus_by_pk(ChannelId: $ChannelId, FeedId: $FeedId) {
    ChannelId
  }
}
    `;
export type DeleteFeedConfigModbusMutationFn = Apollo.MutationFunction<DeleteFeedConfigModbusMutation, DeleteFeedConfigModbusMutationVariables>;

/**
 * __useDeleteFeedConfigModbusMutation__
 *
 * To run a mutation, you first call `useDeleteFeedConfigModbusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFeedConfigModbusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFeedConfigModbusMutation, { data, loading, error }] = useDeleteFeedConfigModbusMutation({
 *   variables: {
 *      ChannelId: // value for 'ChannelId'
 *      FeedId: // value for 'FeedId'
 *   },
 * });
 */
export function useDeleteFeedConfigModbusMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFeedConfigModbusMutation, DeleteFeedConfigModbusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFeedConfigModbusMutation, DeleteFeedConfigModbusMutationVariables>(DeleteFeedConfigModbusDocument, options);
      }
export type DeleteFeedConfigModbusMutationHookResult = ReturnType<typeof useDeleteFeedConfigModbusMutation>;
export type DeleteFeedConfigModbusMutationResult = Apollo.MutationResult<DeleteFeedConfigModbusMutation>;
export type DeleteFeedConfigModbusMutationOptions = Apollo.BaseMutationOptions<DeleteFeedConfigModbusMutation, DeleteFeedConfigModbusMutationVariables>;
export const FeedsLogsDocument = gql`
    query FeedsLogs($eventId: uuid, $feedId: uuid, $severity: smallint, $search: String, $offset: Int!, $limit: Int!, $order_by: [dw_FeedsLogs_order_by!]) {
  dw_FeedsLogs(
    where: {EventId: {_eq: $eventId}, FeedId: {_eq: $feedId}, Severity: {_eq: $severity}, _or: [{Log: {_ilike: $search}}, {Feed: {Label: {_ilike: $search}}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...FeedsLogsFields
  }
  dw_FeedsLogs_aggregate(
    where: {EventId: {_eq: $eventId}, FeedId: {_eq: $feedId}, Severity: {_eq: $severity}, _or: [{Log: {_ilike: $search}}, {Feed: {Label: {_ilike: $search}}}]}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${FeedsLogsFieldsFragmentDoc}`;

/**
 * __useFeedsLogsQuery__
 *
 * To run a query within a React component, call `useFeedsLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedsLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedsLogsQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      feedId: // value for 'feedId'
 *      severity: // value for 'severity'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useFeedsLogsQuery(baseOptions: Apollo.QueryHookOptions<FeedsLogsQuery, FeedsLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeedsLogsQuery, FeedsLogsQueryVariables>(FeedsLogsDocument, options);
      }
export function useFeedsLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeedsLogsQuery, FeedsLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeedsLogsQuery, FeedsLogsQueryVariables>(FeedsLogsDocument, options);
        }
export type FeedsLogsQueryHookResult = ReturnType<typeof useFeedsLogsQuery>;
export type FeedsLogsLazyQueryHookResult = ReturnType<typeof useFeedsLogsLazyQuery>;
export type FeedsLogsQueryResult = Apollo.QueryResult<FeedsLogsQuery, FeedsLogsQueryVariables>;
export const GroupSearchDocument = gql`
    query GroupSearch($search: String, $AccountId: uuid) {
  dw_Groups(
    where: {AccountId: {_eq: $AccountId}, Status: {_eq: 1}, _and: {Label: {_ilike: $search}}}
    limit: 30
    order_by: {Label: asc}
  ) {
    GroupId
    Label
  }
}
    `;

/**
 * __useGroupSearchQuery__
 *
 * To run a query within a React component, call `useGroupSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupSearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *      AccountId: // value for 'AccountId'
 *   },
 * });
 */
export function useGroupSearchQuery(baseOptions?: Apollo.QueryHookOptions<GroupSearchQuery, GroupSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupSearchQuery, GroupSearchQueryVariables>(GroupSearchDocument, options);
      }
export function useGroupSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupSearchQuery, GroupSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupSearchQuery, GroupSearchQueryVariables>(GroupSearchDocument, options);
        }
export type GroupSearchQueryHookResult = ReturnType<typeof useGroupSearchQuery>;
export type GroupSearchLazyQueryHookResult = ReturnType<typeof useGroupSearchLazyQuery>;
export type GroupSearchQueryResult = Apollo.QueryResult<GroupSearchQuery, GroupSearchQueryVariables>;
export const GroupDetailsDocument = gql`
    query GroupDetails($groupId: uuid!) {
  dw_Groups_by_pk(GroupId: $groupId) {
    ...GroupFields
  }
}
    ${GroupFieldsFragmentDoc}`;

/**
 * __useGroupDetailsQuery__
 *
 * To run a query within a React component, call `useGroupDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupDetailsQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGroupDetailsQuery(baseOptions: Apollo.QueryHookOptions<GroupDetailsQuery, GroupDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupDetailsQuery, GroupDetailsQueryVariables>(GroupDetailsDocument, options);
      }
export function useGroupDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupDetailsQuery, GroupDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupDetailsQuery, GroupDetailsQueryVariables>(GroupDetailsDocument, options);
        }
export type GroupDetailsQueryHookResult = ReturnType<typeof useGroupDetailsQuery>;
export type GroupDetailsLazyQueryHookResult = ReturnType<typeof useGroupDetailsLazyQuery>;
export type GroupDetailsQueryResult = Apollo.QueryResult<GroupDetailsQuery, GroupDetailsQueryVariables>;
export const GroupsDocument = gql`
    query Groups($groupId: uuid, $status: smallint, $accountId: uuid, $search: String, $offset: Int!, $limit: Int!, $order_by: [dw_Groups_order_by!]) {
  dw_Groups(
    where: {GroupId: {_eq: $groupId}, AccountId: {_eq: $accountId}, Status: {_eq: $status}, _or: [{Label: {_ilike: $search}}, {Account: {Label: {_ilike: $search}}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...GroupFields
    GroupsSites_aggregate(where: {Status: {_eq: $status}}) {
      aggregate {
        count
      }
    }
  }
  dw_Groups_aggregate(
    where: {GroupId: {_eq: $groupId}, AccountId: {_eq: $accountId}, Status: {_eq: $status}, _or: [{Label: {_ilike: $search}}, {Account: {Label: {_ilike: $search}}}]}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${GroupFieldsFragmentDoc}`;

/**
 * __useGroupsQuery__
 *
 * To run a query within a React component, call `useGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      status: // value for 'status'
 *      accountId: // value for 'accountId'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGroupsQuery(baseOptions: Apollo.QueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
      }
export function useGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
        }
export type GroupsQueryHookResult = ReturnType<typeof useGroupsQuery>;
export type GroupsLazyQueryHookResult = ReturnType<typeof useGroupsLazyQuery>;
export type GroupsQueryResult = Apollo.QueryResult<GroupsQuery, GroupsQueryVariables>;
export const InsertGroupDocument = gql`
    mutation InsertGroup($object: dw_Groups_insert_input!) {
  insert_dw_Groups_one(object: $object) {
    GroupId
  }
}
    `;
export type InsertGroupMutationFn = Apollo.MutationFunction<InsertGroupMutation, InsertGroupMutationVariables>;

/**
 * __useInsertGroupMutation__
 *
 * To run a mutation, you first call `useInsertGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertGroupMutation, { data, loading, error }] = useInsertGroupMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertGroupMutation(baseOptions?: Apollo.MutationHookOptions<InsertGroupMutation, InsertGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertGroupMutation, InsertGroupMutationVariables>(InsertGroupDocument, options);
      }
export type InsertGroupMutationHookResult = ReturnType<typeof useInsertGroupMutation>;
export type InsertGroupMutationResult = Apollo.MutationResult<InsertGroupMutation>;
export type InsertGroupMutationOptions = Apollo.BaseMutationOptions<InsertGroupMutation, InsertGroupMutationVariables>;
export const UpdateGroupsDocument = gql`
    mutation UpdateGroups($GroupId: uuid!, $Label: String!, $Status: smallint!, $UpdatedOn: timestamp!) {
  update_dw_Groups_by_pk(
    pk_columns: {GroupId: $GroupId}
    _set: {Label: $Label, Status: $Status, UpdatedOn: $UpdatedOn}
  ) {
    GroupId
    UpdatedOn
  }
}
    `;
export type UpdateGroupsMutationFn = Apollo.MutationFunction<UpdateGroupsMutation, UpdateGroupsMutationVariables>;

/**
 * __useUpdateGroupsMutation__
 *
 * To run a mutation, you first call `useUpdateGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupsMutation, { data, loading, error }] = useUpdateGroupsMutation({
 *   variables: {
 *      GroupId: // value for 'GroupId'
 *      Label: // value for 'Label'
 *      Status: // value for 'Status'
 *      UpdatedOn: // value for 'UpdatedOn'
 *   },
 * });
 */
export function useUpdateGroupsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGroupsMutation, UpdateGroupsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGroupsMutation, UpdateGroupsMutationVariables>(UpdateGroupsDocument, options);
      }
export type UpdateGroupsMutationHookResult = ReturnType<typeof useUpdateGroupsMutation>;
export type UpdateGroupsMutationResult = Apollo.MutationResult<UpdateGroupsMutation>;
export type UpdateGroupsMutationOptions = Apollo.BaseMutationOptions<UpdateGroupsMutation, UpdateGroupsMutationVariables>;
export const GroupsSitesDocument = gql`
    query GroupsSites($groupId: uuid, $siteId: uuid, $status: smallint, $search: String, $offset: Int!, $limit: Int!, $order_by: [dw_GroupsSites_order_by!]) {
  dw_GroupsSites(
    where: {GroupId: {_eq: $groupId}, SiteId: {_eq: $siteId}, Status: {_eq: $status}, _or: [{Group: {Label: {_ilike: $search}}}, {Site: {Label: {_ilike: $search}}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...GroupsSitesFields
  }
  dw_GroupsSites_aggregate(
    where: {GroupId: {_eq: $groupId}, SiteId: {_eq: $siteId}, Status: {_eq: $status}, _or: [{Group: {Label: {_ilike: $search}}}, {Site: {Label: {_ilike: $search}}}]}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${GroupsSitesFieldsFragmentDoc}`;

/**
 * __useGroupsSitesQuery__
 *
 * To run a query within a React component, call `useGroupsSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsSitesQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      siteId: // value for 'siteId'
 *      status: // value for 'status'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGroupsSitesQuery(baseOptions: Apollo.QueryHookOptions<GroupsSitesQuery, GroupsSitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupsSitesQuery, GroupsSitesQueryVariables>(GroupsSitesDocument, options);
      }
export function useGroupsSitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupsSitesQuery, GroupsSitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupsSitesQuery, GroupsSitesQueryVariables>(GroupsSitesDocument, options);
        }
export type GroupsSitesQueryHookResult = ReturnType<typeof useGroupsSitesQuery>;
export type GroupsSitesLazyQueryHookResult = ReturnType<typeof useGroupsSitesLazyQuery>;
export type GroupsSitesQueryResult = Apollo.QueryResult<GroupsSitesQuery, GroupsSitesQueryVariables>;
export const InsertGroupsSiteDocument = gql`
    mutation InsertGroupsSite($object: dw_GroupsSites_insert_input!) {
  insert_dw_GroupsSites_one(object: $object) {
    GroupId
    SiteId
  }
}
    `;
export type InsertGroupsSiteMutationFn = Apollo.MutationFunction<InsertGroupsSiteMutation, InsertGroupsSiteMutationVariables>;

/**
 * __useInsertGroupsSiteMutation__
 *
 * To run a mutation, you first call `useInsertGroupsSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertGroupsSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertGroupsSiteMutation, { data, loading, error }] = useInsertGroupsSiteMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertGroupsSiteMutation(baseOptions?: Apollo.MutationHookOptions<InsertGroupsSiteMutation, InsertGroupsSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertGroupsSiteMutation, InsertGroupsSiteMutationVariables>(InsertGroupsSiteDocument, options);
      }
export type InsertGroupsSiteMutationHookResult = ReturnType<typeof useInsertGroupsSiteMutation>;
export type InsertGroupsSiteMutationResult = Apollo.MutationResult<InsertGroupsSiteMutation>;
export type InsertGroupsSiteMutationOptions = Apollo.BaseMutationOptions<InsertGroupsSiteMutation, InsertGroupsSiteMutationVariables>;
export const UpdateGroupsSitesDocument = gql`
    mutation UpdateGroupsSites($GroupId: uuid!, $SiteId: uuid!, $Status: smallint!, $UpdatedOn: timestamp!) {
  update_dw_GroupsSites_by_pk(
    pk_columns: {GroupId: $GroupId, SiteId: $SiteId}
    _set: {Status: $Status, UpdatedOn: $UpdatedOn}
  ) {
    GroupId
    SiteId
    UpdatedOn
  }
}
    `;
export type UpdateGroupsSitesMutationFn = Apollo.MutationFunction<UpdateGroupsSitesMutation, UpdateGroupsSitesMutationVariables>;

/**
 * __useUpdateGroupsSitesMutation__
 *
 * To run a mutation, you first call `useUpdateGroupsSitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupsSitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupsSitesMutation, { data, loading, error }] = useUpdateGroupsSitesMutation({
 *   variables: {
 *      GroupId: // value for 'GroupId'
 *      SiteId: // value for 'SiteId'
 *      Status: // value for 'Status'
 *      UpdatedOn: // value for 'UpdatedOn'
 *   },
 * });
 */
export function useUpdateGroupsSitesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGroupsSitesMutation, UpdateGroupsSitesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGroupsSitesMutation, UpdateGroupsSitesMutationVariables>(UpdateGroupsSitesDocument, options);
      }
export type UpdateGroupsSitesMutationHookResult = ReturnType<typeof useUpdateGroupsSitesMutation>;
export type UpdateGroupsSitesMutationResult = Apollo.MutationResult<UpdateGroupsSitesMutation>;
export type UpdateGroupsSitesMutationOptions = Apollo.BaseMutationOptions<UpdateGroupsSitesMutation, UpdateGroupsSitesMutationVariables>;
export const HistoryDocument = gql`
    query History($historyId: uuid, $contactId: uuid, $search: String, $offset: Int!, $limit: Int!, $order_by: [dw_History_order_by!]) {
  dw_History(
    where: {HistoryId: {_eq: $historyId}, ContactId: {_eq: $contactId}, _or: [{Description: {_ilike: $search}}, {Contact: {DisplayName: {_ilike: $search}}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...HistoryFields
  }
  dw_History_aggregate(
    where: {HistoryId: {_eq: $historyId}, ContactId: {_eq: $contactId}, _or: [{Description: {_ilike: $search}}, {Contact: {DisplayName: {_ilike: $search}}}]}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${HistoryFieldsFragmentDoc}`;

/**
 * __useHistoryQuery__
 *
 * To run a query within a React component, call `useHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoryQuery({
 *   variables: {
 *      historyId: // value for 'historyId'
 *      contactId: // value for 'contactId'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useHistoryQuery(baseOptions: Apollo.QueryHookOptions<HistoryQuery, HistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HistoryQuery, HistoryQueryVariables>(HistoryDocument, options);
      }
export function useHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HistoryQuery, HistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HistoryQuery, HistoryQueryVariables>(HistoryDocument, options);
        }
export type HistoryQueryHookResult = ReturnType<typeof useHistoryQuery>;
export type HistoryLazyQueryHookResult = ReturnType<typeof useHistoryLazyQuery>;
export type HistoryQueryResult = Apollo.QueryResult<HistoryQuery, HistoryQueryVariables>;
export const InsertHistoryDocument = gql`
    mutation InsertHistory($object: dw_History_insert_input!) {
  insert_dw_History_one(object: $object) {
    HistoryId
  }
}
    `;
export type InsertHistoryMutationFn = Apollo.MutationFunction<InsertHistoryMutation, InsertHistoryMutationVariables>;

/**
 * __useInsertHistoryMutation__
 *
 * To run a mutation, you first call `useInsertHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertHistoryMutation, { data, loading, error }] = useInsertHistoryMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertHistoryMutation(baseOptions?: Apollo.MutationHookOptions<InsertHistoryMutation, InsertHistoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertHistoryMutation, InsertHistoryMutationVariables>(InsertHistoryDocument, options);
      }
export type InsertHistoryMutationHookResult = ReturnType<typeof useInsertHistoryMutation>;
export type InsertHistoryMutationResult = Apollo.MutationResult<InsertHistoryMutation>;
export type InsertHistoryMutationOptions = Apollo.BaseMutationOptions<InsertHistoryMutation, InsertHistoryMutationVariables>;
export const LoadSearchDocument = gql`
    query LoadSearch($BuildingId: uuid, $LoadId: uuid, $search: String) {
  dw_Loads(
    where: {BuildingId: {_eq: $BuildingId}, LoadId: {_eq: $LoadId}, Status: {_eq: 1}, _and: {Label: {_ilike: $search}}}
    limit: 30
    order_by: {Label: asc}
  ) {
    LoadId
    Label
  }
}
    `;

/**
 * __useLoadSearchQuery__
 *
 * To run a query within a React component, call `useLoadSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadSearchQuery({
 *   variables: {
 *      BuildingId: // value for 'BuildingId'
 *      LoadId: // value for 'LoadId'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useLoadSearchQuery(baseOptions?: Apollo.QueryHookOptions<LoadSearchQuery, LoadSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadSearchQuery, LoadSearchQueryVariables>(LoadSearchDocument, options);
      }
export function useLoadSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadSearchQuery, LoadSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadSearchQuery, LoadSearchQueryVariables>(LoadSearchDocument, options);
        }
export type LoadSearchQueryHookResult = ReturnType<typeof useLoadSearchQuery>;
export type LoadSearchLazyQueryHookResult = ReturnType<typeof useLoadSearchLazyQuery>;
export type LoadSearchQueryResult = Apollo.QueryResult<LoadSearchQuery, LoadSearchQueryVariables>;
export const LoadsWithChannelsSearchDocument = gql`
    query LoadsWithChannelsSearch($LoadIds: [uuid!]!) {
  dw_Loads(where: {LoadId: {_in: $LoadIds}, Status: {_eq: 1}}) {
    LoadId
    Label
    Channels {
      ChannelId
      Label
      ChannelKey
      Parameter
      MeterType
      Reference
      IntervalFrequency
    }
  }
}
    `;

/**
 * __useLoadsWithChannelsSearchQuery__
 *
 * To run a query within a React component, call `useLoadsWithChannelsSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadsWithChannelsSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadsWithChannelsSearchQuery({
 *   variables: {
 *      LoadIds: // value for 'LoadIds'
 *   },
 * });
 */
export function useLoadsWithChannelsSearchQuery(baseOptions: Apollo.QueryHookOptions<LoadsWithChannelsSearchQuery, LoadsWithChannelsSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadsWithChannelsSearchQuery, LoadsWithChannelsSearchQueryVariables>(LoadsWithChannelsSearchDocument, options);
      }
export function useLoadsWithChannelsSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadsWithChannelsSearchQuery, LoadsWithChannelsSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadsWithChannelsSearchQuery, LoadsWithChannelsSearchQueryVariables>(LoadsWithChannelsSearchDocument, options);
        }
export type LoadsWithChannelsSearchQueryHookResult = ReturnType<typeof useLoadsWithChannelsSearchQuery>;
export type LoadsWithChannelsSearchLazyQueryHookResult = ReturnType<typeof useLoadsWithChannelsSearchLazyQuery>;
export type LoadsWithChannelsSearchQueryResult = Apollo.QueryResult<LoadsWithChannelsSearchQuery, LoadsWithChannelsSearchQueryVariables>;
export const LoadsServiceSearchDocument = gql`
    query LoadsServiceSearch($search: String) {
  dw_LoadsService(
    where: {Status: {_eq: 1}, _and: {Label: {_ilike: $search}}}
    order_by: {Label: asc}
  ) {
    ServiceId
    Label
  }
}
    `;

/**
 * __useLoadsServiceSearchQuery__
 *
 * To run a query within a React component, call `useLoadsServiceSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadsServiceSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadsServiceSearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useLoadsServiceSearchQuery(baseOptions?: Apollo.QueryHookOptions<LoadsServiceSearchQuery, LoadsServiceSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadsServiceSearchQuery, LoadsServiceSearchQueryVariables>(LoadsServiceSearchDocument, options);
      }
export function useLoadsServiceSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadsServiceSearchQuery, LoadsServiceSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadsServiceSearchQuery, LoadsServiceSearchQueryVariables>(LoadsServiceSearchDocument, options);
        }
export type LoadsServiceSearchQueryHookResult = ReturnType<typeof useLoadsServiceSearchQuery>;
export type LoadsServiceSearchLazyQueryHookResult = ReturnType<typeof useLoadsServiceSearchLazyQuery>;
export type LoadsServiceSearchQueryResult = Apollo.QueryResult<LoadsServiceSearchQuery, LoadsServiceSearchQueryVariables>;
export const LoadsTypeSearchDocument = gql`
    query LoadsTypeSearch($search: String) {
  dw_LoadsType(
    where: {Status: {_eq: 1}, _and: {Label: {_ilike: $search}}}
    order_by: {Label: asc}
  ) {
    TypeId
    Label
  }
}
    `;

/**
 * __useLoadsTypeSearchQuery__
 *
 * To run a query within a React component, call `useLoadsTypeSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadsTypeSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadsTypeSearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useLoadsTypeSearchQuery(baseOptions?: Apollo.QueryHookOptions<LoadsTypeSearchQuery, LoadsTypeSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadsTypeSearchQuery, LoadsTypeSearchQueryVariables>(LoadsTypeSearchDocument, options);
      }
export function useLoadsTypeSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadsTypeSearchQuery, LoadsTypeSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadsTypeSearchQuery, LoadsTypeSearchQueryVariables>(LoadsTypeSearchDocument, options);
        }
export type LoadsTypeSearchQueryHookResult = ReturnType<typeof useLoadsTypeSearchQuery>;
export type LoadsTypeSearchLazyQueryHookResult = ReturnType<typeof useLoadsTypeSearchLazyQuery>;
export type LoadsTypeSearchQueryResult = Apollo.QueryResult<LoadsTypeSearchQuery, LoadsTypeSearchQueryVariables>;
export const LoadDetailsDocument = gql`
    query LoadDetails($loadId: uuid!) {
  dw_Loads_by_pk(LoadId: $loadId) {
    ...LoadDetailFields
  }
}
    ${LoadDetailFieldsFragmentDoc}`;

/**
 * __useLoadDetailsQuery__
 *
 * To run a query within a React component, call `useLoadDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadDetailsQuery({
 *   variables: {
 *      loadId: // value for 'loadId'
 *   },
 * });
 */
export function useLoadDetailsQuery(baseOptions: Apollo.QueryHookOptions<LoadDetailsQuery, LoadDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadDetailsQuery, LoadDetailsQueryVariables>(LoadDetailsDocument, options);
      }
export function useLoadDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadDetailsQuery, LoadDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadDetailsQuery, LoadDetailsQueryVariables>(LoadDetailsDocument, options);
        }
export type LoadDetailsQueryHookResult = ReturnType<typeof useLoadDetailsQuery>;
export type LoadDetailsLazyQueryHookResult = ReturnType<typeof useLoadDetailsLazyQuery>;
export type LoadDetailsQueryResult = Apollo.QueryResult<LoadDetailsQuery, LoadDetailsQueryVariables>;
export const LoadsDocument = gql`
    query Loads($loadId: uuid, $feedId: uuid, $feedIds: [uuid!], $buildingId: uuid, $siteId: uuid, $accountId: uuid, $status: smallint, $utilityType: smallint, $search: String, $offset: Int!, $limit: Int!, $order_by: [dw_Loads_order_by!]) {
  dw_Loads(
    where: {LoadId: {_eq: $loadId}, FeedId: {_eq: $feedId, _in: $feedIds}, BuildingId: {_eq: $buildingId}, Status: {_eq: $status}, UtilityType: {_eq: $utilityType}, Building: {SiteId: {_eq: $siteId}, Site: {AccountId: {_eq: $accountId}}}, _or: [{Label: {_ilike: $search}}, {CommunicationType: {_ilike: $search}}, {MeterName: {_ilike: $search}}, {CommunicationType: {_ilike: $search}}, {ReferenceValue: {_ilike: $search}}, {Building: {Label: {_ilike: $search}}}, {Building: {Site: {Label: {_ilike: $search}}}}, {Building: {Site: {Account: {Label: {_ilike: $search}}}}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...LoadFields
  }
  dw_Loads_aggregate(
    where: {LoadId: {_eq: $loadId}, FeedId: {_eq: $feedId, _in: $feedIds}, BuildingId: {_eq: $buildingId}, Status: {_eq: $status}, UtilityType: {_eq: $utilityType}, Building: {SiteId: {_eq: $siteId}, Site: {AccountId: {_eq: $accountId}}}, _or: [{Label: {_ilike: $search}}, {CommunicationType: {_ilike: $search}}, {MeterName: {_ilike: $search}}, {CommunicationType: {_ilike: $search}}, {ReferenceValue: {_ilike: $search}}, {Building: {Label: {_ilike: $search}}}, {Building: {Site: {Label: {_ilike: $search}}}}, {Building: {Site: {Account: {Label: {_ilike: $search}}}}}]}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${LoadFieldsFragmentDoc}`;

/**
 * __useLoadsQuery__
 *
 * To run a query within a React component, call `useLoadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadsQuery({
 *   variables: {
 *      loadId: // value for 'loadId'
 *      feedId: // value for 'feedId'
 *      feedIds: // value for 'feedIds'
 *      buildingId: // value for 'buildingId'
 *      siteId: // value for 'siteId'
 *      accountId: // value for 'accountId'
 *      status: // value for 'status'
 *      utilityType: // value for 'utilityType'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useLoadsQuery(baseOptions: Apollo.QueryHookOptions<LoadsQuery, LoadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadsQuery, LoadsQueryVariables>(LoadsDocument, options);
      }
export function useLoadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadsQuery, LoadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadsQuery, LoadsQueryVariables>(LoadsDocument, options);
        }
export type LoadsQueryHookResult = ReturnType<typeof useLoadsQuery>;
export type LoadsLazyQueryHookResult = ReturnType<typeof useLoadsLazyQuery>;
export type LoadsQueryResult = Apollo.QueryResult<LoadsQuery, LoadsQueryVariables>;
export const InsertLoadDocument = gql`
    mutation InsertLoad($object: dw_Loads_insert_input!) {
  insert_dw_Loads_one(object: $object) {
    LoadId
  }
}
    `;
export type InsertLoadMutationFn = Apollo.MutationFunction<InsertLoadMutation, InsertLoadMutationVariables>;

/**
 * __useInsertLoadMutation__
 *
 * To run a mutation, you first call `useInsertLoadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertLoadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertLoadMutation, { data, loading, error }] = useInsertLoadMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertLoadMutation(baseOptions?: Apollo.MutationHookOptions<InsertLoadMutation, InsertLoadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertLoadMutation, InsertLoadMutationVariables>(InsertLoadDocument, options);
      }
export type InsertLoadMutationHookResult = ReturnType<typeof useInsertLoadMutation>;
export type InsertLoadMutationResult = Apollo.MutationResult<InsertLoadMutation>;
export type InsertLoadMutationOptions = Apollo.BaseMutationOptions<InsertLoadMutation, InsertLoadMutationVariables>;
export const UpdateLoadsDocument = gql`
    mutation UpdateLoads($LoadId: uuid!, $Label: String!, $TenantLabel: String!, $IcpNumber: String, $MeterName: String, $MeterType: String, $MeterSerialNumber: String, $Description: String, $BreakerRating: String, $ParentLoadId: uuid, $CommunicationType: String, $CtRating: Int, $CtSupplied: String, $CtType: String, $ModbusId: Int, $NabersRating: smallint, $NegotiatedCapacity: Int, $MainIncomer: Boolean!, $UtilityType: smallint!, $TransformerCapacity: Int, $ReferenceValue: String, $Status: smallint!, $UpdatedOn: timestamp!) {
  update_dw_Loads_by_pk(
    pk_columns: {LoadId: $LoadId}
    _set: {Label: $Label, TenantLabel: $TenantLabel, IcpNumber: $IcpNumber, MeterName: $MeterName, MeterType: $MeterType, MeterSerialNumber: $MeterSerialNumber, Description: $Description, BreakerRating: $BreakerRating, ParentLoadId: $ParentLoadId, CommunicationType: $CommunicationType, CtRating: $CtRating, CtSupplied: $CtSupplied, CtType: $CtType, ModbusId: $ModbusId, NabersRating: $NabersRating, NegotiatedCapacity: $NegotiatedCapacity, MainIncomer: $MainIncomer, TransformerCapacity: $TransformerCapacity, UtilityType: $UtilityType, ReferenceValue: $ReferenceValue, Status: $Status, UpdatedOn: $UpdatedOn}
  ) {
    LoadId
    UpdatedOn
  }
}
    `;
export type UpdateLoadsMutationFn = Apollo.MutationFunction<UpdateLoadsMutation, UpdateLoadsMutationVariables>;

/**
 * __useUpdateLoadsMutation__
 *
 * To run a mutation, you first call `useUpdateLoadsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLoadsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLoadsMutation, { data, loading, error }] = useUpdateLoadsMutation({
 *   variables: {
 *      LoadId: // value for 'LoadId'
 *      Label: // value for 'Label'
 *      TenantLabel: // value for 'TenantLabel'
 *      IcpNumber: // value for 'IcpNumber'
 *      MeterName: // value for 'MeterName'
 *      MeterType: // value for 'MeterType'
 *      MeterSerialNumber: // value for 'MeterSerialNumber'
 *      Description: // value for 'Description'
 *      BreakerRating: // value for 'BreakerRating'
 *      ParentLoadId: // value for 'ParentLoadId'
 *      CommunicationType: // value for 'CommunicationType'
 *      CtRating: // value for 'CtRating'
 *      CtSupplied: // value for 'CtSupplied'
 *      CtType: // value for 'CtType'
 *      ModbusId: // value for 'ModbusId'
 *      NabersRating: // value for 'NabersRating'
 *      NegotiatedCapacity: // value for 'NegotiatedCapacity'
 *      MainIncomer: // value for 'MainIncomer'
 *      UtilityType: // value for 'UtilityType'
 *      TransformerCapacity: // value for 'TransformerCapacity'
 *      ReferenceValue: // value for 'ReferenceValue'
 *      Status: // value for 'Status'
 *      UpdatedOn: // value for 'UpdatedOn'
 *   },
 * });
 */
export function useUpdateLoadsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLoadsMutation, UpdateLoadsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLoadsMutation, UpdateLoadsMutationVariables>(UpdateLoadsDocument, options);
      }
export type UpdateLoadsMutationHookResult = ReturnType<typeof useUpdateLoadsMutation>;
export type UpdateLoadsMutationResult = Apollo.MutationResult<UpdateLoadsMutation>;
export type UpdateLoadsMutationOptions = Apollo.BaseMutationOptions<UpdateLoadsMutation, UpdateLoadsMutationVariables>;
export const UpdateLoadBulkItemDocument = gql`
    mutation UpdateLoadBulkItem($LoadId: uuid!, $BuildingId: uuid!, $FeedId: uuid, $Label: String!, $IcpNumber: String, $Description: String, $ParentLoadId: uuid, $NabersRating: smallint, $MainIncomer: Boolean!, $UtilityType: smallint!, $TransformerCapacity: Int, $Status: smallint!, $ServiceId: uuid, $TypeId: uuid, $UpdatedOn: timestamp!) {
  update_dw_Loads_by_pk(
    pk_columns: {LoadId: $LoadId}
    _set: {BuildingId: $BuildingId, FeedId: $FeedId, Label: $Label, IcpNumber: $IcpNumber, Description: $Description, ParentLoadId: $ParentLoadId, NabersRating: $NabersRating, MainIncomer: $MainIncomer, UtilityType: $UtilityType, TransformerCapacity: $TransformerCapacity, Status: $Status, ServiceId: $ServiceId, TypeId: $TypeId, UpdatedOn: $UpdatedOn}
  ) {
    LoadId
    UpdatedOn
  }
}
    `;
export type UpdateLoadBulkItemMutationFn = Apollo.MutationFunction<UpdateLoadBulkItemMutation, UpdateLoadBulkItemMutationVariables>;

/**
 * __useUpdateLoadBulkItemMutation__
 *
 * To run a mutation, you first call `useUpdateLoadBulkItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLoadBulkItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLoadBulkItemMutation, { data, loading, error }] = useUpdateLoadBulkItemMutation({
 *   variables: {
 *      LoadId: // value for 'LoadId'
 *      BuildingId: // value for 'BuildingId'
 *      FeedId: // value for 'FeedId'
 *      Label: // value for 'Label'
 *      IcpNumber: // value for 'IcpNumber'
 *      Description: // value for 'Description'
 *      ParentLoadId: // value for 'ParentLoadId'
 *      NabersRating: // value for 'NabersRating'
 *      MainIncomer: // value for 'MainIncomer'
 *      UtilityType: // value for 'UtilityType'
 *      TransformerCapacity: // value for 'TransformerCapacity'
 *      Status: // value for 'Status'
 *      ServiceId: // value for 'ServiceId'
 *      TypeId: // value for 'TypeId'
 *      UpdatedOn: // value for 'UpdatedOn'
 *   },
 * });
 */
export function useUpdateLoadBulkItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLoadBulkItemMutation, UpdateLoadBulkItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLoadBulkItemMutation, UpdateLoadBulkItemMutationVariables>(UpdateLoadBulkItemDocument, options);
      }
export type UpdateLoadBulkItemMutationHookResult = ReturnType<typeof useUpdateLoadBulkItemMutation>;
export type UpdateLoadBulkItemMutationResult = Apollo.MutationResult<UpdateLoadBulkItemMutation>;
export type UpdateLoadBulkItemMutationOptions = Apollo.BaseMutationOptions<UpdateLoadBulkItemMutation, UpdateLoadBulkItemMutationVariables>;
export const UpdateLoadAssociationsDocument = gql`
    mutation UpdateLoadAssociations($LoadId: uuid!, $BuildingId: uuid, $FeedId: uuid, $TypeId: uuid, $ServiceId: uuid, $UpdatedOn: timestamp!) {
  update_dw_Loads_by_pk(
    pk_columns: {LoadId: $LoadId}
    _set: {FeedId: $FeedId, BuildingId: $BuildingId, ServiceId: $ServiceId, TypeId: $TypeId, UpdatedOn: $UpdatedOn}
  ) {
    LoadId
    UpdatedOn
  }
}
    `;
export type UpdateLoadAssociationsMutationFn = Apollo.MutationFunction<UpdateLoadAssociationsMutation, UpdateLoadAssociationsMutationVariables>;

/**
 * __useUpdateLoadAssociationsMutation__
 *
 * To run a mutation, you first call `useUpdateLoadAssociationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLoadAssociationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLoadAssociationsMutation, { data, loading, error }] = useUpdateLoadAssociationsMutation({
 *   variables: {
 *      LoadId: // value for 'LoadId'
 *      BuildingId: // value for 'BuildingId'
 *      FeedId: // value for 'FeedId'
 *      TypeId: // value for 'TypeId'
 *      ServiceId: // value for 'ServiceId'
 *      UpdatedOn: // value for 'UpdatedOn'
 *   },
 * });
 */
export function useUpdateLoadAssociationsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLoadAssociationsMutation, UpdateLoadAssociationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLoadAssociationsMutation, UpdateLoadAssociationsMutationVariables>(UpdateLoadAssociationsDocument, options);
      }
export type UpdateLoadAssociationsMutationHookResult = ReturnType<typeof useUpdateLoadAssociationsMutation>;
export type UpdateLoadAssociationsMutationResult = Apollo.MutationResult<UpdateLoadAssociationsMutation>;
export type UpdateLoadAssociationsMutationOptions = Apollo.BaseMutationOptions<UpdateLoadAssociationsMutation, UpdateLoadAssociationsMutationVariables>;
export const BulkChangeLoadsFeedDocument = gql`
    mutation BulkChangeLoadsFeed($FeedId: uuid!, $LoadIds: [uuid!]!, $UpdatedOn: timestamp!) {
  update_dw_Loads(
    where: {LoadId: {_in: $LoadIds}}
    _set: {FeedId: $FeedId, UpdatedOn: $UpdatedOn}
  ) {
    affected_rows
  }
}
    `;
export type BulkChangeLoadsFeedMutationFn = Apollo.MutationFunction<BulkChangeLoadsFeedMutation, BulkChangeLoadsFeedMutationVariables>;

/**
 * __useBulkChangeLoadsFeedMutation__
 *
 * To run a mutation, you first call `useBulkChangeLoadsFeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkChangeLoadsFeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkChangeLoadsFeedMutation, { data, loading, error }] = useBulkChangeLoadsFeedMutation({
 *   variables: {
 *      FeedId: // value for 'FeedId'
 *      LoadIds: // value for 'LoadIds'
 *      UpdatedOn: // value for 'UpdatedOn'
 *   },
 * });
 */
export function useBulkChangeLoadsFeedMutation(baseOptions?: Apollo.MutationHookOptions<BulkChangeLoadsFeedMutation, BulkChangeLoadsFeedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkChangeLoadsFeedMutation, BulkChangeLoadsFeedMutationVariables>(BulkChangeLoadsFeedDocument, options);
      }
export type BulkChangeLoadsFeedMutationHookResult = ReturnType<typeof useBulkChangeLoadsFeedMutation>;
export type BulkChangeLoadsFeedMutationResult = Apollo.MutationResult<BulkChangeLoadsFeedMutation>;
export type BulkChangeLoadsFeedMutationOptions = Apollo.BaseMutationOptions<BulkChangeLoadsFeedMutation, BulkChangeLoadsFeedMutationVariables>;
export const MlNotificationGroupDetailsDocument = gql`
    query MLNotificationGroupDetails($notificationGroupId: uuid!) {
  dw_MLNotificationGroup_by_pk(NotificationGroupId: $notificationGroupId) {
    ...MLNotificationGroupsDetailFields
    MLNotificationsMetadatum {
      Action
      DueDate
      Pinned
      Comments
      UpdatedOn
      contactByAssigneecontactid {
        ContactId
        DisplayName
      }
      Contact {
        ContactId
        DisplayName
      }
    }
  }
}
    ${MlNotificationGroupsDetailFieldsFragmentDoc}`;

/**
 * __useMlNotificationGroupDetailsQuery__
 *
 * To run a query within a React component, call `useMlNotificationGroupDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMlNotificationGroupDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMlNotificationGroupDetailsQuery({
 *   variables: {
 *      notificationGroupId: // value for 'notificationGroupId'
 *   },
 * });
 */
export function useMlNotificationGroupDetailsQuery(baseOptions: Apollo.QueryHookOptions<MlNotificationGroupDetailsQuery, MlNotificationGroupDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MlNotificationGroupDetailsQuery, MlNotificationGroupDetailsQueryVariables>(MlNotificationGroupDetailsDocument, options);
      }
export function useMlNotificationGroupDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MlNotificationGroupDetailsQuery, MlNotificationGroupDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MlNotificationGroupDetailsQuery, MlNotificationGroupDetailsQueryVariables>(MlNotificationGroupDetailsDocument, options);
        }
export type MlNotificationGroupDetailsQueryHookResult = ReturnType<typeof useMlNotificationGroupDetailsQuery>;
export type MlNotificationGroupDetailsLazyQueryHookResult = ReturnType<typeof useMlNotificationGroupDetailsLazyQuery>;
export type MlNotificationGroupDetailsQueryResult = Apollo.QueryResult<MlNotificationGroupDetailsQuery, MlNotificationGroupDetailsQueryVariables>;
export const MlNotificationGroupsDocument = gql`
    query MLNotificationGroups($notificationGroupId: uuid, $buildingId: uuid, $siteId: uuid, $accountId: uuid, $contactId: uuid, $search: String, $priority: Int, $qualificationStatus: smallint, $timestamp: timestamp, $offset: Int!, $limit: Int!, $order_by: [dw_MLNotificationGroup_order_by!]) {
  dw_MLNotificationGroup(
    where: {NotificationGroupId: {_eq: $notificationGroupId}, ContactId: {_eq: $contactId}, Building: {BuildingId: {_eq: $buildingId}, SiteId: {_eq: $siteId}, Site: {Account: {AccountId: {_eq: $accountId}}}}, Priority: {_eq: $priority}, QualificationStatus: {_eq: $qualificationStatus}, CreatedOn: {_gte: $timestamp}, _or: [{AlertType: {_ilike: $search}}, {Summary: {_ilike: $search}}, {RootCause: {_ilike: $search}}, {Building: {Label: {_ilike: $search}}}, {Building: {Site: {Label: {_ilike: $search}}}}, {Building: {Site: {Account: {Label: {_ilike: $search}}}}}]}
    offset: $offset
    limit: $limit
    order_by: $order_by
  ) {
    ...MLNotificationGroupsFields
  }
  dw_MLNotificationGroup_aggregate(
    where: {NotificationGroupId: {_eq: $notificationGroupId}, ContactId: {_eq: $contactId}, Building: {BuildingId: {_eq: $buildingId}, SiteId: {_eq: $siteId}, Site: {Account: {AccountId: {_eq: $accountId}}}}, Priority: {_eq: $priority}, QualificationStatus: {_eq: $qualificationStatus}, CreatedOn: {_gte: $timestamp}, _or: [{AlertType: {_ilike: $search}}, {Summary: {_ilike: $search}}, {RootCause: {_ilike: $search}}, {Building: {Label: {_ilike: $search}}}, {Building: {Site: {Label: {_ilike: $search}}}}, {Building: {Site: {Account: {Label: {_ilike: $search}}}}}]}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${MlNotificationGroupsFieldsFragmentDoc}`;

/**
 * __useMlNotificationGroupsQuery__
 *
 * To run a query within a React component, call `useMlNotificationGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMlNotificationGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMlNotificationGroupsQuery({
 *   variables: {
 *      notificationGroupId: // value for 'notificationGroupId'
 *      buildingId: // value for 'buildingId'
 *      siteId: // value for 'siteId'
 *      accountId: // value for 'accountId'
 *      contactId: // value for 'contactId'
 *      search: // value for 'search'
 *      priority: // value for 'priority'
 *      qualificationStatus: // value for 'qualificationStatus'
 *      timestamp: // value for 'timestamp'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useMlNotificationGroupsQuery(baseOptions: Apollo.QueryHookOptions<MlNotificationGroupsQuery, MlNotificationGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MlNotificationGroupsQuery, MlNotificationGroupsQueryVariables>(MlNotificationGroupsDocument, options);
      }
export function useMlNotificationGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MlNotificationGroupsQuery, MlNotificationGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MlNotificationGroupsQuery, MlNotificationGroupsQueryVariables>(MlNotificationGroupsDocument, options);
        }
export type MlNotificationGroupsQueryHookResult = ReturnType<typeof useMlNotificationGroupsQuery>;
export type MlNotificationGroupsLazyQueryHookResult = ReturnType<typeof useMlNotificationGroupsLazyQuery>;
export type MlNotificationGroupsQueryResult = Apollo.QueryResult<MlNotificationGroupsQuery, MlNotificationGroupsQueryVariables>;
export const MlNotificationGroupsAnalyticsDocument = gql`
    query MLNotificationGroupsAnalytics($buildingId: uuid, $siteId: uuid, $accountId: uuid, $priority: Int, $timestamp: timestamp) {
  TOTAL: dw_MLNotificationGroup_aggregate(
    order_by: {UpdatedOn: asc}
    where: {CreatedOn: {_gte: $timestamp}, Priority: {_eq: $priority}, Building: {BuildingId: {_eq: $buildingId}, SiteId: {_eq: $siteId}, Site: {Account: {AccountId: {_eq: $accountId}}}}}
  ) {
    ...mlNotificationsGroupsComparisonFields
  }
  TO_BE_REVIEWED: dw_MLNotificationGroup_aggregate(
    order_by: {UpdatedOn: asc}
    where: {QualificationStatus: {_eq: "0"}, CreatedOn: {_gte: $timestamp}, Priority: {_eq: $priority}, Building: {BuildingId: {_eq: $buildingId}, SiteId: {_eq: $siteId}, Site: {Account: {AccountId: {_eq: $accountId}}}}}
  ) {
    ...mlNotificationsGroupsComparisonFields
  }
  TO_INVESTIGATE_LATER_BY_ESP: dw_MLNotificationGroup_aggregate(
    order_by: {UpdatedOn: asc}
    where: {QualificationStatus: {_eq: "1"}, CreatedOn: {_gte: $timestamp}, Priority: {_eq: $priority}, Building: {BuildingId: {_eq: $buildingId}, SiteId: {_eq: $siteId}, Site: {Account: {AccountId: {_eq: $accountId}}}}}
  ) {
    ...mlNotificationsGroupsComparisonFields
  }
  DISQUALIFIED_BY_ESP: dw_MLNotificationGroup_aggregate(
    order_by: {UpdatedOn: asc}
    where: {QualificationStatus: {_eq: "2"}, CreatedOn: {_gte: $timestamp}, Priority: {_eq: $priority}, Building: {BuildingId: {_eq: $buildingId}, SiteId: {_eq: $siteId}, Site: {Account: {AccountId: {_eq: $accountId}}}}}
  ) {
    ...mlNotificationsGroupsComparisonFields
    nodes {
      CreatedOn
      UpdatedOn
    }
  }
  QUALIFIED_NOT_ESCALATED: dw_MLNotificationGroup_aggregate(
    order_by: {UpdatedOn: asc}
    where: {QualificationStatus: {_eq: "3"}, CreatedOn: {_gte: $timestamp}, Priority: {_eq: $priority}, Building: {BuildingId: {_eq: $buildingId}, SiteId: {_eq: $siteId}, Site: {Account: {AccountId: {_eq: $accountId}}}}}
  ) {
    ...mlNotificationsGroupsComparisonFields
  }
  QUALIFIED_BY_ESP: dw_MLNotificationGroup_aggregate(
    order_by: {UpdatedOn: asc}
    where: {QualificationStatus: {_eq: "4"}, CreatedOn: {_gte: $timestamp}, Priority: {_eq: $priority}, Building: {BuildingId: {_eq: $buildingId}, SiteId: {_eq: $siteId}, Site: {Account: {AccountId: {_eq: $accountId}}}}}
  ) {
    ...mlNotificationsGroupsComparisonFields
    nodes {
      CreatedOn
      UpdatedOn
    }
  }
  UNDER_INVESTIGATION_BY_CUSTOMER: dw_MLNotificationGroup_aggregate(
    order_by: {UpdatedOn: asc}
    where: {QualificationStatus: {_eq: "5"}, CreatedOn: {_gte: $timestamp}, Priority: {_eq: $priority}, Building: {BuildingId: {_eq: $buildingId}, SiteId: {_eq: $siteId}, Site: {Account: {AccountId: {_eq: $accountId}}}}}
  ) {
    ...mlNotificationsGroupsComparisonFields
  }
  PENDING_ACTION: dw_MLNotificationGroup_aggregate(
    order_by: {UpdatedOn: asc}
    where: {QualificationStatus: {_eq: "6"}, CreatedOn: {_gte: $timestamp}, Priority: {_eq: $priority}, Building: {BuildingId: {_eq: $buildingId}, SiteId: {_eq: $siteId}, Site: {Account: {AccountId: {_eq: $accountId}}}}}
  ) {
    ...mlNotificationsGroupsComparisonFields
  }
  QUALIFIED_BY_CUSTOMER: dw_MLNotificationGroup_aggregate(
    order_by: {UpdatedOn: asc}
    where: {QualificationStatus: {_eq: "7"}, CreatedOn: {_gte: $timestamp}, Priority: {_eq: $priority}, Building: {BuildingId: {_eq: $buildingId}, SiteId: {_eq: $siteId}, Site: {Account: {AccountId: {_eq: $accountId}}}}}
  ) {
    ...mlNotificationsGroupsComparisonFields
    nodes {
      CreatedOn
      UpdatedOn
    }
  }
  DISQUALIFIED_BY_CUSTOMER: dw_MLNotificationGroup_aggregate(
    order_by: {UpdatedOn: asc}
    where: {QualificationStatus: {_eq: "8"}, CreatedOn: {_gte: $timestamp}, Priority: {_eq: $priority}, Building: {BuildingId: {_eq: $buildingId}, SiteId: {_eq: $siteId}, Site: {Account: {AccountId: {_eq: $accountId}}}}}
  ) {
    ...mlNotificationsGroupsComparisonFields
    nodes {
      CreatedOn
      UpdatedOn
    }
  }
}
    ${MlNotificationsGroupsComparisonFieldsFragmentDoc}`;

/**
 * __useMlNotificationGroupsAnalyticsQuery__
 *
 * To run a query within a React component, call `useMlNotificationGroupsAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMlNotificationGroupsAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMlNotificationGroupsAnalyticsQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *      siteId: // value for 'siteId'
 *      accountId: // value for 'accountId'
 *      priority: // value for 'priority'
 *      timestamp: // value for 'timestamp'
 *   },
 * });
 */
export function useMlNotificationGroupsAnalyticsQuery(baseOptions?: Apollo.QueryHookOptions<MlNotificationGroupsAnalyticsQuery, MlNotificationGroupsAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MlNotificationGroupsAnalyticsQuery, MlNotificationGroupsAnalyticsQueryVariables>(MlNotificationGroupsAnalyticsDocument, options);
      }
export function useMlNotificationGroupsAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MlNotificationGroupsAnalyticsQuery, MlNotificationGroupsAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MlNotificationGroupsAnalyticsQuery, MlNotificationGroupsAnalyticsQueryVariables>(MlNotificationGroupsAnalyticsDocument, options);
        }
export type MlNotificationGroupsAnalyticsQueryHookResult = ReturnType<typeof useMlNotificationGroupsAnalyticsQuery>;
export type MlNotificationGroupsAnalyticsLazyQueryHookResult = ReturnType<typeof useMlNotificationGroupsAnalyticsLazyQuery>;
export type MlNotificationGroupsAnalyticsQueryResult = Apollo.QueryResult<MlNotificationGroupsAnalyticsQuery, MlNotificationGroupsAnalyticsQueryVariables>;
export const NotificationGroupsMissingAnalyticsDocument = gql`
    query NotificationGroupsMissingAnalytics($buildingId: uuid, $siteId: uuid, $accountId: uuid) {
  dw_Buildings(
    order_by: {MLNotificationGroups_aggregate: {count: desc}}
    where: {BuildingId: {_eq: $buildingId}, Status: {_eq: 1}, Loads: {Status: {_eq: 1}, Channels: {Status: {_eq: 1}, Type: {_eq: 1}, UnitOfMeasure: {_in: ["kWh", "Gas kWh", "Water m3"]}}}, Site: {SiteId: {_eq: $siteId}, Status: {_eq: 1}, Account: {AccountId: {_eq: $accountId}, Status: {_eq: 1}}}}
  ) {
    BuildingId
    Label
    Site {
      SiteId
      Label
      Account {
        AccountId
        Label
      }
    }
    MLNotificationGroups(limit: 1, order_by: {CreatedOn: desc}) {
      StartDate
    }
  }
}
    `;

/**
 * __useNotificationGroupsMissingAnalyticsQuery__
 *
 * To run a query within a React component, call `useNotificationGroupsMissingAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationGroupsMissingAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationGroupsMissingAnalyticsQuery({
 *   variables: {
 *      buildingId: // value for 'buildingId'
 *      siteId: // value for 'siteId'
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useNotificationGroupsMissingAnalyticsQuery(baseOptions?: Apollo.QueryHookOptions<NotificationGroupsMissingAnalyticsQuery, NotificationGroupsMissingAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationGroupsMissingAnalyticsQuery, NotificationGroupsMissingAnalyticsQueryVariables>(NotificationGroupsMissingAnalyticsDocument, options);
      }
export function useNotificationGroupsMissingAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationGroupsMissingAnalyticsQuery, NotificationGroupsMissingAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationGroupsMissingAnalyticsQuery, NotificationGroupsMissingAnalyticsQueryVariables>(NotificationGroupsMissingAnalyticsDocument, options);
        }
export type NotificationGroupsMissingAnalyticsQueryHookResult = ReturnType<typeof useNotificationGroupsMissingAnalyticsQuery>;
export type NotificationGroupsMissingAnalyticsLazyQueryHookResult = ReturnType<typeof useNotificationGroupsMissingAnalyticsLazyQuery>;
export type NotificationGroupsMissingAnalyticsQueryResult = Apollo.QueryResult<NotificationGroupsMissingAnalyticsQuery, NotificationGroupsMissingAnalyticsQueryVariables>;
export const UpdateMlNotificationGroupDetailsDocument = gql`
    mutation UpdateMLNotificationGroupDetails($notificationGroupId: uuid!, $priority: Int!, $qualificationStatus: smallint!, $rootCause: String, $summary: String, $contactId: uuid!, $updatedOn: timestamp!) {
  update_dw_MLNotificationGroup_by_pk(
    pk_columns: {NotificationGroupId: $notificationGroupId}
    _set: {Priority: $priority, QualificationStatus: $qualificationStatus, RootCause: $rootCause, Summary: $summary, ContactId: $contactId, UpdatedOn: $updatedOn}
  ) {
    NotificationGroupId
    UpdatedOn
  }
}
    `;
export type UpdateMlNotificationGroupDetailsMutationFn = Apollo.MutationFunction<UpdateMlNotificationGroupDetailsMutation, UpdateMlNotificationGroupDetailsMutationVariables>;

/**
 * __useUpdateMlNotificationGroupDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateMlNotificationGroupDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMlNotificationGroupDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMlNotificationGroupDetailsMutation, { data, loading, error }] = useUpdateMlNotificationGroupDetailsMutation({
 *   variables: {
 *      notificationGroupId: // value for 'notificationGroupId'
 *      priority: // value for 'priority'
 *      qualificationStatus: // value for 'qualificationStatus'
 *      rootCause: // value for 'rootCause'
 *      summary: // value for 'summary'
 *      contactId: // value for 'contactId'
 *      updatedOn: // value for 'updatedOn'
 *   },
 * });
 */
export function useUpdateMlNotificationGroupDetailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMlNotificationGroupDetailsMutation, UpdateMlNotificationGroupDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMlNotificationGroupDetailsMutation, UpdateMlNotificationGroupDetailsMutationVariables>(UpdateMlNotificationGroupDetailsDocument, options);
      }
export type UpdateMlNotificationGroupDetailsMutationHookResult = ReturnType<typeof useUpdateMlNotificationGroupDetailsMutation>;
export type UpdateMlNotificationGroupDetailsMutationResult = Apollo.MutationResult<UpdateMlNotificationGroupDetailsMutation>;
export type UpdateMlNotificationGroupDetailsMutationOptions = Apollo.BaseMutationOptions<UpdateMlNotificationGroupDetailsMutation, UpdateMlNotificationGroupDetailsMutationVariables>;
export const UpdateMlNotificationsMetadataDocument = gql`
    mutation UpdateMLNotificationsMetadata($notificationGroupId: uuid!, $qualificationStatus: smallint!, $updatedOn: timestamp!, $comments: json!) {
  update_dw_MLNotificationGroup_by_pk(
    pk_columns: {NotificationGroupId: $notificationGroupId}
    _set: {QualificationStatus: $qualificationStatus, UpdatedOn: $updatedOn}
  ) {
    NotificationGroupId
    QualificationStatus
    UpdatedOn
    ContactId
  }
  update_dw_MLNotificationsMetadata(
    where: {NotificationId: {_eq: $notificationGroupId}}
    _set: {UpdatedOn: $updatedOn, Comments: $comments}
  ) {
    returning {
      Comments
    }
  }
}
    `;
export type UpdateMlNotificationsMetadataMutationFn = Apollo.MutationFunction<UpdateMlNotificationsMetadataMutation, UpdateMlNotificationsMetadataMutationVariables>;

/**
 * __useUpdateMlNotificationsMetadataMutation__
 *
 * To run a mutation, you first call `useUpdateMlNotificationsMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMlNotificationsMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMlNotificationsMetadataMutation, { data, loading, error }] = useUpdateMlNotificationsMetadataMutation({
 *   variables: {
 *      notificationGroupId: // value for 'notificationGroupId'
 *      qualificationStatus: // value for 'qualificationStatus'
 *      updatedOn: // value for 'updatedOn'
 *      comments: // value for 'comments'
 *   },
 * });
 */
export function useUpdateMlNotificationsMetadataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMlNotificationsMetadataMutation, UpdateMlNotificationsMetadataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMlNotificationsMetadataMutation, UpdateMlNotificationsMetadataMutationVariables>(UpdateMlNotificationsMetadataDocument, options);
      }
export type UpdateMlNotificationsMetadataMutationHookResult = ReturnType<typeof useUpdateMlNotificationsMetadataMutation>;
export type UpdateMlNotificationsMetadataMutationResult = Apollo.MutationResult<UpdateMlNotificationsMetadataMutation>;
export type UpdateMlNotificationsMetadataMutationOptions = Apollo.BaseMutationOptions<UpdateMlNotificationsMetadataMutation, UpdateMlNotificationsMetadataMutationVariables>;
export const BulkDisqualifyNotificationGroupsDocument = gql`
    mutation BulkDisqualifyNotificationGroups($notificationGroupIds: [uuid!]!, $qualificationStatus: smallint, $rootCause: String, $updatedOn: timestamp!) {
  update_dw_MLNotificationGroup(
    where: {NotificationGroupId: {_in: $notificationGroupIds}}
    _set: {QualificationStatus: $qualificationStatus, RootCause: $rootCause, UpdatedOn: $updatedOn}
  ) {
    affected_rows
  }
}
    `;
export type BulkDisqualifyNotificationGroupsMutationFn = Apollo.MutationFunction<BulkDisqualifyNotificationGroupsMutation, BulkDisqualifyNotificationGroupsMutationVariables>;

/**
 * __useBulkDisqualifyNotificationGroupsMutation__
 *
 * To run a mutation, you first call `useBulkDisqualifyNotificationGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkDisqualifyNotificationGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkDisqualifyNotificationGroupsMutation, { data, loading, error }] = useBulkDisqualifyNotificationGroupsMutation({
 *   variables: {
 *      notificationGroupIds: // value for 'notificationGroupIds'
 *      qualificationStatus: // value for 'qualificationStatus'
 *      rootCause: // value for 'rootCause'
 *      updatedOn: // value for 'updatedOn'
 *   },
 * });
 */
export function useBulkDisqualifyNotificationGroupsMutation(baseOptions?: Apollo.MutationHookOptions<BulkDisqualifyNotificationGroupsMutation, BulkDisqualifyNotificationGroupsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkDisqualifyNotificationGroupsMutation, BulkDisqualifyNotificationGroupsMutationVariables>(BulkDisqualifyNotificationGroupsDocument, options);
      }
export type BulkDisqualifyNotificationGroupsMutationHookResult = ReturnType<typeof useBulkDisqualifyNotificationGroupsMutation>;
export type BulkDisqualifyNotificationGroupsMutationResult = Apollo.MutationResult<BulkDisqualifyNotificationGroupsMutation>;
export type BulkDisqualifyNotificationGroupsMutationOptions = Apollo.BaseMutationOptions<BulkDisqualifyNotificationGroupsMutation, BulkDisqualifyNotificationGroupsMutationVariables>;
export const NotificationsDetailsDocument = gql`
    query NotificationsDetails($notificationId: uuid!) {
  dw_Notifications_by_pk(NotificationId: $notificationId) {
    ...NotificationDetailFields
  }
}
    ${NotificationDetailFieldsFragmentDoc}`;

/**
 * __useNotificationsDetailsQuery__
 *
 * To run a query within a React component, call `useNotificationsDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsDetailsQuery({
 *   variables: {
 *      notificationId: // value for 'notificationId'
 *   },
 * });
 */
export function useNotificationsDetailsQuery(baseOptions: Apollo.QueryHookOptions<NotificationsDetailsQuery, NotificationsDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsDetailsQuery, NotificationsDetailsQueryVariables>(NotificationsDetailsDocument, options);
      }
export function useNotificationsDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsDetailsQuery, NotificationsDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsDetailsQuery, NotificationsDetailsQueryVariables>(NotificationsDetailsDocument, options);
        }
export type NotificationsDetailsQueryHookResult = ReturnType<typeof useNotificationsDetailsQuery>;
export type NotificationsDetailsLazyQueryHookResult = ReturnType<typeof useNotificationsDetailsLazyQuery>;
export type NotificationsDetailsQueryResult = Apollo.QueryResult<NotificationsDetailsQuery, NotificationsDetailsQueryVariables>;
export const NotificationsDocument = gql`
    query Notifications($notificationId: uuid, $accountId: uuid, $siteId: uuid, $buildingId: uuid, $loadId: uuid, $channelId: uuid, $search: String, $status: smallint, $timestamp: timestamp, $offset: Int!, $limit: Int!, $order_by: [dw_Notifications_order_by!]) {
  dw_Notifications(
    where: {NotificationId: {_eq: $notificationId}, ChannelId: {_eq: $channelId}, LoadId: {_eq: $loadId}, BuildingId: {_eq: $buildingId}, Building: {Site: {SiteId: {_eq: $siteId}}}, AccountId: {_eq: $accountId}, Status: {_eq: $status}, CreatedOn: {_gte: $timestamp}, _or: [{Label: {_ilike: $search}}, {Assessment: {_ilike: $search}}, {Channel: {Label: {_ilike: $search}}}, {Load: {Label: {_ilike: $search}}}, {Building: {Label: {_ilike: $search}, Site: {Label: {_ilike: $search}}}}, {Account: {Label: {_ilike: $search}}}]}
    offset: $offset
    limit: $limit
    order_by: $order_by
  ) {
    ...NotificationsFields
    NotificationsEvents_aggregate {
      aggregate {
        count
      }
    }
  }
}
    ${NotificationsFieldsFragmentDoc}`;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      notificationId: // value for 'notificationId'
 *      accountId: // value for 'accountId'
 *      siteId: // value for 'siteId'
 *      buildingId: // value for 'buildingId'
 *      loadId: // value for 'loadId'
 *      channelId: // value for 'channelId'
 *      search: // value for 'search'
 *      status: // value for 'status'
 *      timestamp: // value for 'timestamp'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const UpdateNotificationDocument = gql`
    mutation UpdateNotification($notificationId: uuid!, $status: smallint!, $rootCause: smallint, $usage: numeric, $emissions: numeric, $cost: numeric, $assessment: String, $dueDate: timestamp, $assignee: uuid, $reviewer: uuid, $updatedOn: timestamp!) {
  update_dw_Notifications_by_pk(
    pk_columns: {NotificationId: $notificationId}
    _set: {Cost: $cost, Status: $status, RootCause: $rootCause, Usage: $usage, Emissions: $emissions, Assessment: $assessment, DueDate: $dueDate, Assignee: $assignee, Reviewer: $reviewer, UpdatedOn: $updatedOn}
  ) {
    NotificationId
    UpdatedOn
  }
}
    `;
export type UpdateNotificationMutationFn = Apollo.MutationFunction<UpdateNotificationMutation, UpdateNotificationMutationVariables>;

/**
 * __useUpdateNotificationMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationMutation, { data, loading, error }] = useUpdateNotificationMutation({
 *   variables: {
 *      notificationId: // value for 'notificationId'
 *      status: // value for 'status'
 *      rootCause: // value for 'rootCause'
 *      usage: // value for 'usage'
 *      emissions: // value for 'emissions'
 *      cost: // value for 'cost'
 *      assessment: // value for 'assessment'
 *      dueDate: // value for 'dueDate'
 *      assignee: // value for 'assignee'
 *      reviewer: // value for 'reviewer'
 *      updatedOn: // value for 'updatedOn'
 *   },
 * });
 */
export function useUpdateNotificationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNotificationMutation, UpdateNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNotificationMutation, UpdateNotificationMutationVariables>(UpdateNotificationDocument, options);
      }
export type UpdateNotificationMutationHookResult = ReturnType<typeof useUpdateNotificationMutation>;
export type UpdateNotificationMutationResult = Apollo.MutationResult<UpdateNotificationMutation>;
export type UpdateNotificationMutationOptions = Apollo.BaseMutationOptions<UpdateNotificationMutation, UpdateNotificationMutationVariables>;
export const UpdateNotificationsEventDocument = gql`
    mutation UpdateNotificationsEvent($eventId: uuid!, $status: smallint!) {
  update_dw_NotificationsEvent_by_pk(
    pk_columns: {EventId: $eventId}
    _set: {Status: $status}
  ) {
    EventId
    Status
  }
}
    `;
export type UpdateNotificationsEventMutationFn = Apollo.MutationFunction<UpdateNotificationsEventMutation, UpdateNotificationsEventMutationVariables>;

/**
 * __useUpdateNotificationsEventMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationsEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationsEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationsEventMutation, { data, loading, error }] = useUpdateNotificationsEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateNotificationsEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNotificationsEventMutation, UpdateNotificationsEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNotificationsEventMutation, UpdateNotificationsEventMutationVariables>(UpdateNotificationsEventDocument, options);
      }
export type UpdateNotificationsEventMutationHookResult = ReturnType<typeof useUpdateNotificationsEventMutation>;
export type UpdateNotificationsEventMutationResult = Apollo.MutationResult<UpdateNotificationsEventMutation>;
export type UpdateNotificationsEventMutationOptions = Apollo.BaseMutationOptions<UpdateNotificationsEventMutation, UpdateNotificationsEventMutationVariables>;
export const InsertNotificationsLogDocument = gql`
    mutation InsertNotificationsLog($object: dw_NotificationsLog_insert_input!) {
  insert_dw_NotificationsLog_one(object: $object) {
    LogId
  }
}
    `;
export type InsertNotificationsLogMutationFn = Apollo.MutationFunction<InsertNotificationsLogMutation, InsertNotificationsLogMutationVariables>;

/**
 * __useInsertNotificationsLogMutation__
 *
 * To run a mutation, you first call `useInsertNotificationsLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertNotificationsLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertNotificationsLogMutation, { data, loading, error }] = useInsertNotificationsLogMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertNotificationsLogMutation(baseOptions?: Apollo.MutationHookOptions<InsertNotificationsLogMutation, InsertNotificationsLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertNotificationsLogMutation, InsertNotificationsLogMutationVariables>(InsertNotificationsLogDocument, options);
      }
export type InsertNotificationsLogMutationHookResult = ReturnType<typeof useInsertNotificationsLogMutation>;
export type InsertNotificationsLogMutationResult = Apollo.MutationResult<InsertNotificationsLogMutation>;
export type InsertNotificationsLogMutationOptions = Apollo.BaseMutationOptions<InsertNotificationsLogMutation, InsertNotificationsLogMutationVariables>;
export const ProjectDetailsDocument = gql`
    query ProjectDetails($projectId: uuid!) {
  dw_Projects_by_pk(ProjectId: $projectId) {
    ...ProjectDetailFields
  }
}
    ${ProjectDetailFieldsFragmentDoc}`;

/**
 * __useProjectDetailsQuery__
 *
 * To run a query within a React component, call `useProjectDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectDetailsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectDetailsQuery(baseOptions: Apollo.QueryHookOptions<ProjectDetailsQuery, ProjectDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectDetailsQuery, ProjectDetailsQueryVariables>(ProjectDetailsDocument, options);
      }
export function useProjectDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectDetailsQuery, ProjectDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectDetailsQuery, ProjectDetailsQueryVariables>(ProjectDetailsDocument, options);
        }
export type ProjectDetailsQueryHookResult = ReturnType<typeof useProjectDetailsQuery>;
export type ProjectDetailsLazyQueryHookResult = ReturnType<typeof useProjectDetailsLazyQuery>;
export type ProjectDetailsQueryResult = Apollo.QueryResult<ProjectDetailsQuery, ProjectDetailsQueryVariables>;
export const ProjectsDocument = gql`
    query Projects($projectId: uuid, $loadId: uuid, $buildingId: uuid, $siteId: uuid, $accountId: uuid, $contactId: uuid, $status: smallint, $search: String, $offset: Int!, $limit: Int!, $order_by: [dw_Projects_order_by!]) {
  dw_Projects(
    where: {ProjectId: {_eq: $projectId}, LoadId: {_eq: $loadId}, BuildingId: {_eq: $buildingId}, SiteId: {_eq: $siteId}, AccountId: {_eq: $accountId}, Status: {_eq: $status}, _or: [{Load: {Label: {_ilike: $search}, Status: {_eq: $status}}}, {Building: {Label: {_ilike: $search}, Status: {_eq: $status}}}, {Site: {Label: {_ilike: $search}}, Status: {_eq: $status}}, {Account: {Label: {_ilike: $search}, Status: {_eq: $status}}}, {Contact: {DisplayName: {_ilike: $search}}}, {Label: {_ilike: $search}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...ProjectsFields
  }
  dw_Projects_aggregate(
    where: {ProjectId: {_eq: $projectId}, LoadId: {_eq: $loadId}, BuildingId: {_eq: $buildingId}, SiteId: {_eq: $siteId}, AccountId: {_eq: $accountId}, Status: {_eq: $status}, _or: [{Load: {Label: {_ilike: $search}, Status: {_eq: $status}}}, {Building: {Label: {_ilike: $search}, Status: {_eq: $status}}}, {Site: {Label: {_ilike: $search}}, Status: {_eq: $status}}, {Account: {Label: {_ilike: $search}, Status: {_eq: $status}}}, {Contact: {DisplayName: {_ilike: $search}}}, {Label: {_ilike: $search}}]}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${ProjectsFieldsFragmentDoc}`;

/**
 * __useProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      loadId: // value for 'loadId'
 *      buildingId: // value for 'buildingId'
 *      siteId: // value for 'siteId'
 *      accountId: // value for 'accountId'
 *      contactId: // value for 'contactId'
 *      status: // value for 'status'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useProjectsQuery(baseOptions: Apollo.QueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
      }
export function useProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
        }
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsLazyQueryHookResult = ReturnType<typeof useProjectsLazyQuery>;
export type ProjectsQueryResult = Apollo.QueryResult<ProjectsQuery, ProjectsQueryVariables>;
export const InsertProjectDocument = gql`
    mutation InsertProject($object: dw_Projects_insert_input!) {
  insert_dw_Projects_one(object: $object) {
    ProjectId
  }
}
    `;
export type InsertProjectMutationFn = Apollo.MutationFunction<InsertProjectMutation, InsertProjectMutationVariables>;

/**
 * __useInsertProjectMutation__
 *
 * To run a mutation, you first call `useInsertProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertProjectMutation, { data, loading, error }] = useInsertProjectMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertProjectMutation(baseOptions?: Apollo.MutationHookOptions<InsertProjectMutation, InsertProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertProjectMutation, InsertProjectMutationVariables>(InsertProjectDocument, options);
      }
export type InsertProjectMutationHookResult = ReturnType<typeof useInsertProjectMutation>;
export type InsertProjectMutationResult = Apollo.MutationResult<InsertProjectMutation>;
export type InsertProjectMutationOptions = Apollo.BaseMutationOptions<InsertProjectMutation, InsertProjectMutationVariables>;
export const UpdateProjectsDocument = gql`
    mutation UpdateProjects($ProjectId: uuid!, $Label: String!, $Description: String, $Comments: String, $Cost: numeric, $UnitCost: numeric, $CumulativeKwhSavings: numeric, $AnnualKwhSavings: numeric, $AnnualSavings: numeric, $Priority: smallint!, $Status: smallint!, $NotifiedOn: timestamp!, $ImplementationDate: timestamp, $UpdatedOn: timestamp!) {
  update_dw_Projects_by_pk(
    pk_columns: {ProjectId: $ProjectId}
    _set: {Label: $Label, Description: $Description, Comments: $Comments, Cost: $Cost, UnitCost: $UnitCost, CumulativeKwhSavings: $CumulativeKwhSavings, AnnualKwhSavings: $AnnualKwhSavings, AnnualSavings: $AnnualSavings, Priority: $Priority, Status: $Status, NotifiedOn: $NotifiedOn, ImplementationDate: $ImplementationDate, UpdatedOn: $UpdatedOn}
  ) {
    ProjectId
    UpdatedOn
  }
}
    `;
export type UpdateProjectsMutationFn = Apollo.MutationFunction<UpdateProjectsMutation, UpdateProjectsMutationVariables>;

/**
 * __useUpdateProjectsMutation__
 *
 * To run a mutation, you first call `useUpdateProjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectsMutation, { data, loading, error }] = useUpdateProjectsMutation({
 *   variables: {
 *      ProjectId: // value for 'ProjectId'
 *      Label: // value for 'Label'
 *      Description: // value for 'Description'
 *      Comments: // value for 'Comments'
 *      Cost: // value for 'Cost'
 *      UnitCost: // value for 'UnitCost'
 *      CumulativeKwhSavings: // value for 'CumulativeKwhSavings'
 *      AnnualKwhSavings: // value for 'AnnualKwhSavings'
 *      AnnualSavings: // value for 'AnnualSavings'
 *      Priority: // value for 'Priority'
 *      Status: // value for 'Status'
 *      NotifiedOn: // value for 'NotifiedOn'
 *      ImplementationDate: // value for 'ImplementationDate'
 *      UpdatedOn: // value for 'UpdatedOn'
 *   },
 * });
 */
export function useUpdateProjectsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectsMutation, UpdateProjectsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectsMutation, UpdateProjectsMutationVariables>(UpdateProjectsDocument, options);
      }
export type UpdateProjectsMutationHookResult = ReturnType<typeof useUpdateProjectsMutation>;
export type UpdateProjectsMutationResult = Apollo.MutationResult<UpdateProjectsMutation>;
export type UpdateProjectsMutationOptions = Apollo.BaseMutationOptions<UpdateProjectsMutation, UpdateProjectsMutationVariables>;
export const UpdateProjectAssociationsDocument = gql`
    mutation UpdateProjectAssociations($ProjectId: uuid!, $AccountId: uuid, $SiteId: uuid, $BuildingId: uuid, $LoadId: uuid, $ReporterContactId: uuid, $AssigneeContactId: uuid, $UpdatedOn: timestamp!) {
  update_dw_Projects_by_pk(
    pk_columns: {ProjectId: $ProjectId}
    _set: {AccountId: $AccountId, SiteId: $SiteId, BuildingId: $BuildingId, LoadId: $LoadId, ReporterContactId: $ReporterContactId, AssigneeContactId: $AssigneeContactId, UpdatedOn: $UpdatedOn}
  ) {
    ProjectId
    UpdatedOn
  }
}
    `;
export type UpdateProjectAssociationsMutationFn = Apollo.MutationFunction<UpdateProjectAssociationsMutation, UpdateProjectAssociationsMutationVariables>;

/**
 * __useUpdateProjectAssociationsMutation__
 *
 * To run a mutation, you first call `useUpdateProjectAssociationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectAssociationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectAssociationsMutation, { data, loading, error }] = useUpdateProjectAssociationsMutation({
 *   variables: {
 *      ProjectId: // value for 'ProjectId'
 *      AccountId: // value for 'AccountId'
 *      SiteId: // value for 'SiteId'
 *      BuildingId: // value for 'BuildingId'
 *      LoadId: // value for 'LoadId'
 *      ReporterContactId: // value for 'ReporterContactId'
 *      AssigneeContactId: // value for 'AssigneeContactId'
 *      UpdatedOn: // value for 'UpdatedOn'
 *   },
 * });
 */
export function useUpdateProjectAssociationsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectAssociationsMutation, UpdateProjectAssociationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectAssociationsMutation, UpdateProjectAssociationsMutationVariables>(UpdateProjectAssociationsDocument, options);
      }
export type UpdateProjectAssociationsMutationHookResult = ReturnType<typeof useUpdateProjectAssociationsMutation>;
export type UpdateProjectAssociationsMutationResult = Apollo.MutationResult<UpdateProjectAssociationsMutation>;
export type UpdateProjectAssociationsMutationOptions = Apollo.BaseMutationOptions<UpdateProjectAssociationsMutation, UpdateProjectAssociationsMutationVariables>;
export const ReportDetailsDocument = gql`
    query ReportDetails($reportId: uuid!) {
  dw_Reports_by_pk(ReportId: $reportId) {
    ...ReportsFields
  }
}
    ${ReportsFieldsFragmentDoc}`;

/**
 * __useReportDetailsQuery__
 *
 * To run a query within a React component, call `useReportDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportDetailsQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useReportDetailsQuery(baseOptions: Apollo.QueryHookOptions<ReportDetailsQuery, ReportDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportDetailsQuery, ReportDetailsQueryVariables>(ReportDetailsDocument, options);
      }
export function useReportDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportDetailsQuery, ReportDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportDetailsQuery, ReportDetailsQueryVariables>(ReportDetailsDocument, options);
        }
export type ReportDetailsQueryHookResult = ReturnType<typeof useReportDetailsQuery>;
export type ReportDetailsLazyQueryHookResult = ReturnType<typeof useReportDetailsLazyQuery>;
export type ReportDetailsQueryResult = Apollo.QueryResult<ReportDetailsQuery, ReportDetailsQueryVariables>;
export const ReportsDocument = gql`
    query Reports($reportId: uuid, $accountId: uuid, $contactId: uuid, $search: String, $offset: Int!, $limit: Int!, $order_by: [dw_Reports_order_by!]) {
  dw_Reports(
    where: {ReportId: {_eq: $reportId}, AccountId: {_eq: $accountId}, _or: [{Account: {Label: {_ilike: $search}}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...ReportsFields
  }
  dw_Reports_aggregate(
    where: {ReportId: {_eq: $reportId}, AccountId: {_eq: $accountId}, _or: [{Account: {Label: {_ilike: $search}}}]}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${ReportsFieldsFragmentDoc}`;

/**
 * __useReportsQuery__
 *
 * To run a query within a React component, call `useReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      accountId: // value for 'accountId'
 *      contactId: // value for 'contactId'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useReportsQuery(baseOptions: Apollo.QueryHookOptions<ReportsQuery, ReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, options);
      }
export function useReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportsQuery, ReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, options);
        }
export type ReportsQueryHookResult = ReturnType<typeof useReportsQuery>;
export type ReportsLazyQueryHookResult = ReturnType<typeof useReportsLazyQuery>;
export type ReportsQueryResult = Apollo.QueryResult<ReportsQuery, ReportsQueryVariables>;
export const DeleteReportDocument = gql`
    mutation DeleteReport($reportId: uuid!) {
  delete_dw_Reports_by_pk(ReportId: $reportId) {
    AccountId
  }
}
    `;
export type DeleteReportMutationFn = Apollo.MutationFunction<DeleteReportMutation, DeleteReportMutationVariables>;

/**
 * __useDeleteReportMutation__
 *
 * To run a mutation, you first call `useDeleteReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReportMutation, { data, loading, error }] = useDeleteReportMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useDeleteReportMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReportMutation, DeleteReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReportMutation, DeleteReportMutationVariables>(DeleteReportDocument, options);
      }
export type DeleteReportMutationHookResult = ReturnType<typeof useDeleteReportMutation>;
export type DeleteReportMutationResult = Apollo.MutationResult<DeleteReportMutation>;
export type DeleteReportMutationOptions = Apollo.BaseMutationOptions<DeleteReportMutation, DeleteReportMutationVariables>;
export const InsertReportDetailsDocument = gql`
    mutation InsertReportDetails($reportId: uuid!, $accountId: uuid!, $targetElectricity: numeric!, $targetGas: numeric!, $targetWater: numeric!, $type: smallint!, $baselineMode: smallint!, $createdOn: timestamp!, $updatedOn: timestamp!, $startDate: timestamp!, $notificationFrequency: smallint!) {
  insert_dw_Reports_one(
    object: {ReportId: $reportId, AccountId: $accountId, TargetElectricity: $targetElectricity, TargetGas: $targetGas, TargetWater: $targetWater, Type: $type, BaselineMode: $baselineMode, CreatedOn: $createdOn, UpdatedOn: $updatedOn, StartDate: $startDate, NotificationFrequency: $notificationFrequency}
  ) {
    ReportId
  }
}
    `;
export type InsertReportDetailsMutationFn = Apollo.MutationFunction<InsertReportDetailsMutation, InsertReportDetailsMutationVariables>;

/**
 * __useInsertReportDetailsMutation__
 *
 * To run a mutation, you first call `useInsertReportDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertReportDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertReportDetailsMutation, { data, loading, error }] = useInsertReportDetailsMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      accountId: // value for 'accountId'
 *      targetElectricity: // value for 'targetElectricity'
 *      targetGas: // value for 'targetGas'
 *      targetWater: // value for 'targetWater'
 *      type: // value for 'type'
 *      baselineMode: // value for 'baselineMode'
 *      createdOn: // value for 'createdOn'
 *      updatedOn: // value for 'updatedOn'
 *      startDate: // value for 'startDate'
 *      notificationFrequency: // value for 'notificationFrequency'
 *   },
 * });
 */
export function useInsertReportDetailsMutation(baseOptions?: Apollo.MutationHookOptions<InsertReportDetailsMutation, InsertReportDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertReportDetailsMutation, InsertReportDetailsMutationVariables>(InsertReportDetailsDocument, options);
      }
export type InsertReportDetailsMutationHookResult = ReturnType<typeof useInsertReportDetailsMutation>;
export type InsertReportDetailsMutationResult = Apollo.MutationResult<InsertReportDetailsMutation>;
export type InsertReportDetailsMutationOptions = Apollo.BaseMutationOptions<InsertReportDetailsMutation, InsertReportDetailsMutationVariables>;
export const UpdateReportDetailsDocument = gql`
    mutation UpdateReportDetails($ReportId: uuid!, $TargetElectricity: numeric!, $TargetGas: numeric!, $TargetWater: numeric!, $Type: smallint!, $BaselineMode: smallint!, $UpdatedOn: timestamp!, $StartDate: timestamp!, $NotificationFrequency: smallint!) {
  update_dw_Reports_by_pk(
    pk_columns: {ReportId: $ReportId}
    _set: {TargetElectricity: $TargetElectricity, TargetGas: $TargetGas, TargetWater: $TargetWater, Type: $Type, BaselineMode: $BaselineMode, UpdatedOn: $UpdatedOn, StartDate: $StartDate, NotificationFrequency: $NotificationFrequency}
  ) {
    ReportId
    TargetElectricity
    TargetGas
    TargetWater
    Type
    BaselineMode
    UpdatedOn
    StartDate
    NotificationFrequency
  }
}
    `;
export type UpdateReportDetailsMutationFn = Apollo.MutationFunction<UpdateReportDetailsMutation, UpdateReportDetailsMutationVariables>;

/**
 * __useUpdateReportDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateReportDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportDetailsMutation, { data, loading, error }] = useUpdateReportDetailsMutation({
 *   variables: {
 *      ReportId: // value for 'ReportId'
 *      TargetElectricity: // value for 'TargetElectricity'
 *      TargetGas: // value for 'TargetGas'
 *      TargetWater: // value for 'TargetWater'
 *      Type: // value for 'Type'
 *      BaselineMode: // value for 'BaselineMode'
 *      UpdatedOn: // value for 'UpdatedOn'
 *      StartDate: // value for 'StartDate'
 *      NotificationFrequency: // value for 'NotificationFrequency'
 *   },
 * });
 */
export function useUpdateReportDetailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReportDetailsMutation, UpdateReportDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReportDetailsMutation, UpdateReportDetailsMutationVariables>(UpdateReportDetailsDocument, options);
      }
export type UpdateReportDetailsMutationHookResult = ReturnType<typeof useUpdateReportDetailsMutation>;
export type UpdateReportDetailsMutationResult = Apollo.MutationResult<UpdateReportDetailsMutation>;
export type UpdateReportDetailsMutationOptions = Apollo.BaseMutationOptions<UpdateReportDetailsMutation, UpdateReportDetailsMutationVariables>;
export const SessionsDocument = gql`
    query Sessions($contactId: uuid, $search: String, $offset: Int!, $limit: Int!, $order_by: [dw_Sessions_order_by!]) {
  dw_Sessions(
    where: {ContactId: {_eq: $contactId}, _or: [{Contact: {DisplayName: {_ilike: $search}}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...SessionFields
  }
  dw_Sessions_aggregate(
    where: {ContactId: {_eq: $contactId}, _or: [{Contact: {DisplayName: {_ilike: $search}}}]}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${SessionFieldsFragmentDoc}`;

/**
 * __useSessionsQuery__
 *
 * To run a query within a React component, call `useSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionsQuery({
 *   variables: {
 *      contactId: // value for 'contactId'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSessionsQuery(baseOptions: Apollo.QueryHookOptions<SessionsQuery, SessionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SessionsQuery, SessionsQueryVariables>(SessionsDocument, options);
      }
export function useSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionsQuery, SessionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SessionsQuery, SessionsQueryVariables>(SessionsDocument, options);
        }
export type SessionsQueryHookResult = ReturnType<typeof useSessionsQuery>;
export type SessionsLazyQueryHookResult = ReturnType<typeof useSessionsLazyQuery>;
export type SessionsQueryResult = Apollo.QueryResult<SessionsQuery, SessionsQueryVariables>;
export const SiteSearchDocument = gql`
    query SiteSearch($search: String, $AccountId: uuid) {
  dw_Sites(
    where: {AccountId: {_eq: $AccountId}, Status: {_eq: 1}, _and: {Label: {_ilike: $search}}}
    limit: 30
    order_by: {Label: asc}
  ) {
    SiteId
    Label
  }
}
    `;

/**
 * __useSiteSearchQuery__
 *
 * To run a query within a React component, call `useSiteSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteSearchQuery({
 *   variables: {
 *      search: // value for 'search'
 *      AccountId: // value for 'AccountId'
 *   },
 * });
 */
export function useSiteSearchQuery(baseOptions?: Apollo.QueryHookOptions<SiteSearchQuery, SiteSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SiteSearchQuery, SiteSearchQueryVariables>(SiteSearchDocument, options);
      }
export function useSiteSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SiteSearchQuery, SiteSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SiteSearchQuery, SiteSearchQueryVariables>(SiteSearchDocument, options);
        }
export type SiteSearchQueryHookResult = ReturnType<typeof useSiteSearchQuery>;
export type SiteSearchLazyQueryHookResult = ReturnType<typeof useSiteSearchLazyQuery>;
export type SiteSearchQueryResult = Apollo.QueryResult<SiteSearchQuery, SiteSearchQueryVariables>;
export const SiteDetailsDocument = gql`
    query SiteDetails($siteId: uuid!) {
  dw_Sites_by_pk(SiteId: $siteId) {
    ...SiteDetailFields
  }
}
    ${SiteDetailFieldsFragmentDoc}`;

/**
 * __useSiteDetailsQuery__
 *
 * To run a query within a React component, call `useSiteDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteDetailsQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useSiteDetailsQuery(baseOptions: Apollo.QueryHookOptions<SiteDetailsQuery, SiteDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SiteDetailsQuery, SiteDetailsQueryVariables>(SiteDetailsDocument, options);
      }
export function useSiteDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SiteDetailsQuery, SiteDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SiteDetailsQuery, SiteDetailsQueryVariables>(SiteDetailsDocument, options);
        }
export type SiteDetailsQueryHookResult = ReturnType<typeof useSiteDetailsQuery>;
export type SiteDetailsLazyQueryHookResult = ReturnType<typeof useSiteDetailsLazyQuery>;
export type SiteDetailsQueryResult = Apollo.QueryResult<SiteDetailsQuery, SiteDetailsQueryVariables>;
export const SitesDocument = gql`
    query Sites($siteId: uuid, $status: smallint, $accountId: uuid, $search: String, $offset: Int!, $limit: Int!, $order_by: [dw_Sites_order_by!]) {
  dw_Sites(
    where: {SiteId: {_eq: $siteId}, AccountId: {_eq: $accountId}, Status: {_eq: $status}, _or: [{Label: {_ilike: $search}}, {Account: {Label: {_ilike: $search}}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...SiteFields
  }
  dw_Sites_aggregate(
    where: {SiteId: {_eq: $siteId}, AccountId: {_eq: $accountId}, Status: {_eq: $status}, _or: [{Label: {_ilike: $search}}, {Account: {Label: {_ilike: $search}}}]}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${SiteFieldsFragmentDoc}`;

/**
 * __useSitesQuery__
 *
 * To run a query within a React component, call `useSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSitesQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *      status: // value for 'status'
 *      accountId: // value for 'accountId'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useSitesQuery(baseOptions: Apollo.QueryHookOptions<SitesQuery, SitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SitesQuery, SitesQueryVariables>(SitesDocument, options);
      }
export function useSitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SitesQuery, SitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SitesQuery, SitesQueryVariables>(SitesDocument, options);
        }
export type SitesQueryHookResult = ReturnType<typeof useSitesQuery>;
export type SitesLazyQueryHookResult = ReturnType<typeof useSitesLazyQuery>;
export type SitesQueryResult = Apollo.QueryResult<SitesQuery, SitesQueryVariables>;
export const InsertSiteDocument = gql`
    mutation InsertSite($object: dw_Sites_insert_input!) {
  insert_dw_Sites_one(object: $object) {
    SiteId
  }
}
    `;
export type InsertSiteMutationFn = Apollo.MutationFunction<InsertSiteMutation, InsertSiteMutationVariables>;

/**
 * __useInsertSiteMutation__
 *
 * To run a mutation, you first call `useInsertSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSiteMutation, { data, loading, error }] = useInsertSiteMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertSiteMutation(baseOptions?: Apollo.MutationHookOptions<InsertSiteMutation, InsertSiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertSiteMutation, InsertSiteMutationVariables>(InsertSiteDocument, options);
      }
export type InsertSiteMutationHookResult = ReturnType<typeof useInsertSiteMutation>;
export type InsertSiteMutationResult = Apollo.MutationResult<InsertSiteMutation>;
export type InsertSiteMutationOptions = Apollo.BaseMutationOptions<InsertSiteMutation, InsertSiteMutationVariables>;
export const UpdateSitesDocument = gql`
    mutation UpdateSites($SiteId: uuid!, $Label: String!, $Line1: String, $Line2: String, $City: String, $Status: smallint!, $UpdatedOn: timestamp!) {
  update_dw_Sites_by_pk(
    pk_columns: {SiteId: $SiteId}
    _set: {Label: $Label, Line1: $Line1, Line2: $Line2, City: $City, Status: $Status, UpdatedOn: $UpdatedOn}
  ) {
    SiteId
    UpdatedOn
  }
}
    `;
export type UpdateSitesMutationFn = Apollo.MutationFunction<UpdateSitesMutation, UpdateSitesMutationVariables>;

/**
 * __useUpdateSitesMutation__
 *
 * To run a mutation, you first call `useUpdateSitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSitesMutation, { data, loading, error }] = useUpdateSitesMutation({
 *   variables: {
 *      SiteId: // value for 'SiteId'
 *      Label: // value for 'Label'
 *      Line1: // value for 'Line1'
 *      Line2: // value for 'Line2'
 *      City: // value for 'City'
 *      Status: // value for 'Status'
 *      UpdatedOn: // value for 'UpdatedOn'
 *   },
 * });
 */
export function useUpdateSitesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSitesMutation, UpdateSitesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSitesMutation, UpdateSitesMutationVariables>(UpdateSitesDocument, options);
      }
export type UpdateSitesMutationHookResult = ReturnType<typeof useUpdateSitesMutation>;
export type UpdateSitesMutationResult = Apollo.MutationResult<UpdateSitesMutation>;
export type UpdateSitesMutationOptions = Apollo.BaseMutationOptions<UpdateSitesMutation, UpdateSitesMutationVariables>;
export const UpdateSiteAssociationsDocument = gql`
    mutation UpdateSiteAssociations($SiteId: uuid!, $AccountId: uuid, $SiteContactId: uuid, $InstallerContactId: uuid, $UpdatedOn: timestamp!) {
  update_dw_Sites_by_pk(
    pk_columns: {SiteId: $SiteId}
    _set: {AccountId: $AccountId, SiteContactId: $SiteContactId, InstallerContactId: $InstallerContactId, UpdatedOn: $UpdatedOn}
  ) {
    SiteId
    UpdatedOn
  }
}
    `;
export type UpdateSiteAssociationsMutationFn = Apollo.MutationFunction<UpdateSiteAssociationsMutation, UpdateSiteAssociationsMutationVariables>;

/**
 * __useUpdateSiteAssociationsMutation__
 *
 * To run a mutation, you first call `useUpdateSiteAssociationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteAssociationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteAssociationsMutation, { data, loading, error }] = useUpdateSiteAssociationsMutation({
 *   variables: {
 *      SiteId: // value for 'SiteId'
 *      AccountId: // value for 'AccountId'
 *      SiteContactId: // value for 'SiteContactId'
 *      InstallerContactId: // value for 'InstallerContactId'
 *      UpdatedOn: // value for 'UpdatedOn'
 *   },
 * });
 */
export function useUpdateSiteAssociationsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSiteAssociationsMutation, UpdateSiteAssociationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSiteAssociationsMutation, UpdateSiteAssociationsMutationVariables>(UpdateSiteAssociationsDocument, options);
      }
export type UpdateSiteAssociationsMutationHookResult = ReturnType<typeof useUpdateSiteAssociationsMutation>;
export type UpdateSiteAssociationsMutationResult = Apollo.MutationResult<UpdateSiteAssociationsMutation>;
export type UpdateSiteAssociationsMutationOptions = Apollo.BaseMutationOptions<UpdateSiteAssociationsMutation, UpdateSiteAssociationsMutationVariables>;
export const WeatherDetailsDocument = gql`
    query WeatherDetails($WeatherId: uuid!) {
  dw_Weather_by_pk(WeatherId: $WeatherId) {
    ...WeatherFields
  }
}
    ${WeatherFieldsFragmentDoc}`;

/**
 * __useWeatherDetailsQuery__
 *
 * To run a query within a React component, call `useWeatherDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWeatherDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWeatherDetailsQuery({
 *   variables: {
 *      WeatherId: // value for 'WeatherId'
 *   },
 * });
 */
export function useWeatherDetailsQuery(baseOptions: Apollo.QueryHookOptions<WeatherDetailsQuery, WeatherDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WeatherDetailsQuery, WeatherDetailsQueryVariables>(WeatherDetailsDocument, options);
      }
export function useWeatherDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WeatherDetailsQuery, WeatherDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WeatherDetailsQuery, WeatherDetailsQueryVariables>(WeatherDetailsDocument, options);
        }
export type WeatherDetailsQueryHookResult = ReturnType<typeof useWeatherDetailsQuery>;
export type WeatherDetailsLazyQueryHookResult = ReturnType<typeof useWeatherDetailsLazyQuery>;
export type WeatherDetailsQueryResult = Apollo.QueryResult<WeatherDetailsQuery, WeatherDetailsQueryVariables>;
export const WeatherDocument = gql`
    query Weather($weatherId: uuid, $status: smallint, $search: String, $offset: Int!, $limit: Int!, $order_by: [dw_Weather_order_by!]) {
  dw_Weather(
    where: {WeatherId: {_eq: $weatherId}, Status: {_eq: $status}, _or: [{City: {_ilike: $search}}, {Country: {_ilike: $search}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...WeatherFields
    WeatherReadings_aggregate {
      aggregate {
        count
      }
    }
  }
  dw_Weather_aggregate(
    where: {WeatherId: {_eq: $weatherId}, Status: {_eq: $status}, _or: [{City: {_ilike: $search}}, {Country: {_ilike: $search}}]}
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${WeatherFieldsFragmentDoc}`;

/**
 * __useWeatherQuery__
 *
 * To run a query within a React component, call `useWeatherQuery` and pass it any options that fit your needs.
 * When your component renders, `useWeatherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWeatherQuery({
 *   variables: {
 *      weatherId: // value for 'weatherId'
 *      status: // value for 'status'
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useWeatherQuery(baseOptions: Apollo.QueryHookOptions<WeatherQuery, WeatherQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WeatherQuery, WeatherQueryVariables>(WeatherDocument, options);
      }
export function useWeatherLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WeatherQuery, WeatherQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WeatherQuery, WeatherQueryVariables>(WeatherDocument, options);
        }
export type WeatherQueryHookResult = ReturnType<typeof useWeatherQuery>;
export type WeatherLazyQueryHookResult = ReturnType<typeof useWeatherLazyQuery>;
export type WeatherQueryResult = Apollo.QueryResult<WeatherQuery, WeatherQueryVariables>;
export const InsertWeatherDocument = gql`
    mutation InsertWeather($object: dw_Weather_insert_input!) {
  insert_dw_Weather_one(object: $object) {
    WeatherId
  }
}
    `;
export type InsertWeatherMutationFn = Apollo.MutationFunction<InsertWeatherMutation, InsertWeatherMutationVariables>;

/**
 * __useInsertWeatherMutation__
 *
 * To run a mutation, you first call `useInsertWeatherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertWeatherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertWeatherMutation, { data, loading, error }] = useInsertWeatherMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertWeatherMutation(baseOptions?: Apollo.MutationHookOptions<InsertWeatherMutation, InsertWeatherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertWeatherMutation, InsertWeatherMutationVariables>(InsertWeatherDocument, options);
      }
export type InsertWeatherMutationHookResult = ReturnType<typeof useInsertWeatherMutation>;
export type InsertWeatherMutationResult = Apollo.MutationResult<InsertWeatherMutation>;
export type InsertWeatherMutationOptions = Apollo.BaseMutationOptions<InsertWeatherMutation, InsertWeatherMutationVariables>;
export const UpdateWeatherDocument = gql`
    mutation UpdateWeather($WeatherId: uuid!, $Status: smallint!, $Address: String, $Longitude: numeric, $Latitude: numeric, $UpdatedOn: timestamp!) {
  update_dw_Weather_by_pk(
    pk_columns: {WeatherId: $WeatherId}
    _set: {Address: $Address, Longitude: $Longitude, Latitude: $Latitude, Status: $Status, UpdatedOn: $UpdatedOn}
  ) {
    WeatherId
    UpdatedOn
  }
}
    `;
export type UpdateWeatherMutationFn = Apollo.MutationFunction<UpdateWeatherMutation, UpdateWeatherMutationVariables>;

/**
 * __useUpdateWeatherMutation__
 *
 * To run a mutation, you first call `useUpdateWeatherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWeatherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWeatherMutation, { data, loading, error }] = useUpdateWeatherMutation({
 *   variables: {
 *      WeatherId: // value for 'WeatherId'
 *      Status: // value for 'Status'
 *      Address: // value for 'Address'
 *      Longitude: // value for 'Longitude'
 *      Latitude: // value for 'Latitude'
 *      UpdatedOn: // value for 'UpdatedOn'
 *   },
 * });
 */
export function useUpdateWeatherMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWeatherMutation, UpdateWeatherMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWeatherMutation, UpdateWeatherMutationVariables>(UpdateWeatherDocument, options);
      }
export type UpdateWeatherMutationHookResult = ReturnType<typeof useUpdateWeatherMutation>;
export type UpdateWeatherMutationResult = Apollo.MutationResult<UpdateWeatherMutation>;
export type UpdateWeatherMutationOptions = Apollo.BaseMutationOptions<UpdateWeatherMutation, UpdateWeatherMutationVariables>;
export const WeatherReadingsDocument = gql`
    query WeatherReadings($weatherId: uuid, $search: String, $timestamp: timestamp, $offset: Int!, $limit: Int!, $order_by: [dw_WeatherReadings_order_by!]) {
  dw_WeatherReadings(
    where: {WeatherId: {_eq: $weatherId}, Event: {_gte: $timestamp}}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...WeatherReadingsFields
  }
  dw_WeatherReadings_aggregate(
    where: {WeatherId: {_eq: $weatherId}, Event: {_gte: $timestamp}}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    aggregate {
      totalCount: count
    }
  }
}
    ${WeatherReadingsFieldsFragmentDoc}`;

/**
 * __useWeatherReadingsQuery__
 *
 * To run a query within a React component, call `useWeatherReadingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWeatherReadingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWeatherReadingsQuery({
 *   variables: {
 *      weatherId: // value for 'weatherId'
 *      search: // value for 'search'
 *      timestamp: // value for 'timestamp'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useWeatherReadingsQuery(baseOptions: Apollo.QueryHookOptions<WeatherReadingsQuery, WeatherReadingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WeatherReadingsQuery, WeatherReadingsQueryVariables>(WeatherReadingsDocument, options);
      }
export function useWeatherReadingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WeatherReadingsQuery, WeatherReadingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WeatherReadingsQuery, WeatherReadingsQueryVariables>(WeatherReadingsDocument, options);
        }
export type WeatherReadingsQueryHookResult = ReturnType<typeof useWeatherReadingsQuery>;
export type WeatherReadingsLazyQueryHookResult = ReturnType<typeof useWeatherReadingsLazyQuery>;
export type WeatherReadingsQueryResult = Apollo.QueryResult<WeatherReadingsQuery, WeatherReadingsQueryVariables>;