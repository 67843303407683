import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';
import { Checkbox } from '@rmwc/checkbox';
import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';

import {
  useBulkChangeLoadsFeedMutation,
  useFeedSearchLazyQuery,
  useLoadsWithChannelsSearchQuery,
  useBulkChangeChannelsFeedMutation,
} from 'generated/graphql';
import { queue, useAppState } from 'utilities';
import { Autocomplete } from 'components';

import styles from './CustomModal.module.scss';

export const BulkChangeLoadsFeedModal = ({ open, setOpen, additionalModalProps }: any) => {
  const { createFeedsConfigModbusEntries } = useAppState();
  const { formState, control, handleSubmit, setError, clearErrors, reset, watch, setValue } = useForm();
  const { errors } = formState;

  const [Feed] = watch(['Feed']);

  const [searchFeeds, { data: FeedSearchData, loading: FeedSearchLoading }] = useFeedSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const { data: loadsWithChannels } = useLoadsWithChannelsSearchQuery({
    variables: additionalModalProps.variables,
    fetchPolicy: 'no-cache',
  });

  const [bulkChangeFn] = useBulkChangeLoadsFeedMutation();
  const [bulkChangeChannelsFeedFn] = useBulkChangeChannelsFeedMutation();

  const onSubmit = async (data: any) => {
    try {
      const variables = {
        ...additionalModalProps.variables,
        FeedId: data.Feed?.FeedId,
        UpdatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
      };
      delete variables.Feed;
      const { errors } = await bulkChangeFn({ variables });
      if (errors?.length > 0) {
        throw new Error(errors[0].message);
      }
      queue.clearAll();
      queue.notify({ icon: 'done', title: 'Success!', body: `Bulk Loads Feed Changed!` });

      const channels = loadsWithChannels?.dw_Loads.flatMap((load) => load.Channels);
      if (data.UpdateChannelsFeed && channels.length > 0) {
        const channelIds = channels.map((x) => x.ChannelId);
        queue.notify({ icon: 'done', title: 'Success!', body: `Now changing Channels Feeds, please wait` });
        const changeChannelVariables = {
          ChannelIds: channelIds,
          FeedId: data.Feed?.FeedId,
          UpdatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
        };
        const { errors: channelsErrors } = await bulkChangeChannelsFeedFn({ variables: changeChannelVariables });
        if (channelsErrors?.length > 0) {
          throw new Error(errors[0].message);
        }
        queue.clearAll();
        queue.notify({ icon: 'done', title: 'Success!', body: `Bulk Loads Channels Feed Changed!` });
      }

      if (data.createFeedsConfigModbusEntries && channels.length > 0) {
        const result = await createFeedsConfigModbusEntries(channels);
        if (!result) {
          setOpen(false);
          return;
        }
      }
      setOpen(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (ex) {
      queue.clearAll();
      queue.notify({
        icon: 'priority_high',
        title: 'Oh oh, error!',
        body: ex.message,
      });
      setError('api', { type: 'manual', message: ex.message });
    }
  };

  React.useEffect(() => {
    searchFeeds({ variables: { search: `%%`, AccountId: additionalModalProps.variables.AccountId } });
    // eslint-disable-next-line
  }, ['open']);

  return (
    <div className={styles.customModal}>
      <Dialog
        className={styles.addLoad}
        open={open}
        onClose={(evt) => {
          reset();
          clearErrors();
          if (open) {
            setOpen(false);
          }
        }}>
        <form onSubmit={handleSubmit(onSubmit)} data-private autoComplete="off">
          <DialogTitle>Bulk Change Loads Feed</DialogTitle>
          <DialogContent>
            <div className={styles.row}>
              <Controller
                name="Feed"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    label="Feed"
                    data={
                      FeedSearchData?.dw_Feeds.map((x) => {
                        return {
                          FeedId: x.FeedId,

                          Label: `${x.Label} - ${x.Building?.Label} ${x.Notes ? `(${x.Notes})` : ''}`,
                          FeedsType: x.FeedsType,
                        };
                      }) || []
                    }
                    labelKey="Label"
                    valueKey="FeedId"
                    loading={FeedSearchLoading}
                    getData={searchFeeds}
                    callback={(item) => {
                      field.onChange(item?.FullData);
                      setValue('createFeedsConfigModbusEntries', false);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.Feed?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.Feed.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <Controller
                name="UpdateChannelsFeed"
                control={control}
                defaultValue={true}
                render={({ field }) => <Checkbox label="Update Channels Feed?" defaultChecked {...field} />}
              />
            </div>

            {Feed?.FeedsType?.Label === 'ESP Hub Connect' && (
              <div className={styles.row}>
                <Controller
                  name="createFeedsConfigModbusEntries"
                  control={control}
                  render={({ field }) => <Checkbox label="Create Feeds Config Modbus Channels?" {...field} />}
                />
              </div>
            )}

            <div className={styles.row}>
              {errors.api && (
                <div className={styles.error} style={{ marginBottom: '16px' }}>
                  <Typography use="caption">{errors.api.message}</Typography>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-between' }}>
            <Button
              type="button"
              label="Close"
              disabled={formState.isSubmitting}
              outlined
              onClick={() => {
                setOpen(false);
                clearErrors();
                reset();
              }}
            />
            <Button
              raised
              disabled={formState.isSubmitting}
              label={formState.isSubmitting ? 'Saving' : 'Save'}
              icon={formState.isSubmitting ? <CircularProgress theme="secondary" /> : null}
              onClick={() => {
                clearErrors();
              }}
              type="submit"
            />
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default BulkChangeLoadsFeedModal;
