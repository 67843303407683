import axios from 'axios';
import nProgress from 'nprogress';
import moment from 'moment';
import { setCookie } from 'utilities';

export type IdentityDto = {
  outcome?: number;
  token?: string;
};

const API_ENV = process.env.REACT_APP_API_ENV || '';
const api = axios.create({ baseURL: `${API_ENV}/` });

export const checkFederated = async (email: string) => {
  try {
    const { data } = await api.get(
      `identity?email=${encodeURIComponent(email)}&host=${encodeURIComponent(window.location.origin)}`
    );
    if (data.federated) {
      return data.url;
    } else {
      return '';
    }
  } catch (error) {
    nProgress.done();
    return null;
  }
};

/**
 * Login via email and password to identity api
 * @param email
 * @param password
 */
export const login = async (email: string, password: string) => {
  // nProgress.start();
  const states = {
    0: 'Sorry, those details are incorrect. Please try again.',
    1: 'Sorry, this account is locked. Please contact support.',
    2: 'Success! Redirecting you now.',
    3: 'MFA',
  };
  try {
    const { data } = await api.post<IdentityDto>('identity', { email, password });
    const resultState = states[data.outcome];
    if (data.outcome === 2) {
      storeTokenData(data);
      // queueRenewal();
    }
    nProgress.done();
    return resultState;
  } catch (error) {
    nProgress.done();
    return states[1];
  }
};

/**
 * Set timeout to renew session
 */
export const queueRenewal = () => {
  const now = moment();
  const expires = moment(localStorage.getItem('expires'));
  const diffMs = expires.diff(now) - 1000 * 60;
  if (diffMs > 0) {
    setTimeout(() => {
      renewSession();
    }, diffMs);
  } else {
    logout(true);
  }
};

/**
 * Renew session
 */
export const renewSession = async () => {
  try {
    const token = localStorage.getItem('token');
    const { data } = await api.patch<IdentityDto>('identity', null, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (data.outcome === 2) {
      storeTokenData(data);
    } else {
      throw Error();
    }
  } catch (error) {
    logout(true);
    return null;
  }
};

/**
 * Logout user and clear storage
 * Optional notification about session timeout
 * @param sessionTimeout
 */
export const logout = (sessionTimeout: boolean) => {
  if (sessionTimeout) {
    sessionStorage.clear();
    localStorage.clear();
    setTimeout(() => {
      window.location.href = `${process.env.REACT_APP_IDENTITY}/SignOut?ref=${window.location.origin}`;
    }, 0);
  } else {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = `${process.env.REACT_APP_IDENTITY}/SignOut?ref=${window.location.origin}`;
  }
};

/**
 * Send forgot password email to email
 * @param email
 */
export const forgotPassword = async (emailAddress: string) => {
  nProgress.start();
  try {
    const result = await api.post('identity/forgot', { emailAddress });
    if (result.status === 204) {
      nProgress.done();
      return true;
    } else {
      throw Error();
    }
  } catch (error) {
    nProgress.done();
    return false;
  }
};

/**
 * Reset Password by email and reset code
 * @param email
 * @param password
 * @param resetCode
 */
export const resetPassword = async (emailAddress: string, password: string, resetCode: string) => {
  nProgress.start();
  try {
    const result = await api.put('identity/forgot', { emailAddress, password, resetCode });
    if (result.status === 204) {
      nProgress.done();
      return true;
    } else {
      throw Error();
    }
  } catch (error) {
    nProgress.done();
    return false;
  }
};

/**
 * Store token data into local storage
 * @param data
 */
export const storeTokenData = (data: IdentityDto) => {
  localStorage.setItem('token', data.token);
  setCookie('token', data.token);
};
