import React from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';
import { TextField } from '@rmwc/textfield';
import { Select } from '@rmwc/select';
import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';

import {
  useAccountSearchLazyQuery,
  useSiteSearchLazyQuery,
  useBuildingSearchLazyQuery,
  useLoadSearchLazyQuery,
  useContactSearchLazyQuery,
  useInsertProjectMutation,
} from 'generated/graphql';
import { ProjectPriorityEnum, queue, StatusEnum, useAppState } from 'utilities';
import { Autocomplete } from 'components';

import styles from './CustomModal.module.scss';

export const AddProjectModal = ({ open, setOpen }: any) => {
  const { user } = useAppState();
  const history = useHistory();
  const {
    formState,
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    setValue,
    getValues,
    watch,
  } = useForm();
  const { errors } = formState;
  const [Account, Site, Building, Load, Assignee] = watch(['Account', 'Site', 'Building', 'Load', 'Assignee']);
  const [searchAccounts, { data: AccountSearchData, loading: AccountSearchLoading }] = useAccountSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [searchSites, { data: SiteSearchData, loading: SiteSearchLoading }] = useSiteSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [searchBuildings, { data: BuildingSearchData, loading: BuildingSearchLoading }] = useBuildingSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [searchLoads, { data: LoadSearchData, loading: LoadSearchLoading }] = useLoadSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [searchContacts, { data: ContactSearchData, loading: ContactSearchLoading }] = useContactSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [insertFn] = useInsertProjectMutation();
  const statusFields = [];
  for (let k in Object.keys(StatusEnum)) {
    if (StatusEnum[k]) {
      statusFields.push({ label: StatusEnum[k], value: Number(k) });
    }
  }

  const projectPriorityFields = [];
  for (let k in Object.keys(ProjectPriorityEnum)) {
    if (ProjectPriorityEnum[k]) {
      projectPriorityFields.push({ label: ProjectPriorityEnum[k], value: Number(k) });
    }
  }

  const onSubmit = async (data: any) => {
    try {
      const variables = {
        object: {
          ...data,
          AccountId: data.Account?.AccountId,
          SiteId: data.Site?.SiteId,
          BuildingId: data.Building?.BuildingId,
          LoadId: data.Load?.LoadId,
          ReporterContactId: user.profile.id,
          AssigneeContactId: data.Assignee?.ContactId,
          ProjectId: uuidv4(),
          CreatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
          UpdatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
          NotifiedOn: moment(data.NotifiedOn, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:00:00'),
          ImplementationDate: data?.ImplementationDate
            ? moment(data.ImplementationDate, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:00:00')
            : null,
        },
      };
      delete variables.object.Account;
      delete variables.object.Site;
      delete variables.object.Building;
      delete variables.object.Load;
      delete variables.object.Assignee;
      const { data: resultData, errors } = await insertFn({ variables });
      if (errors?.length > 0) {
        throw new Error(errors[0].message);
      }
      queue.clearAll();
      queue.notify({
        icon: 'done',
        title: 'Success!',
        body: `Project Added!`,
      });

      setOpen(false);
      setTimeout(() => {
        // window.location.reload();
        history.push('/app/detail/projects/' + resultData.insert_dw_Projects_one.ProjectId);
      }, 1000);
    } catch (ex) {
      queue.clearAll();
      queue.notify({
        icon: 'priority_high',
        title: 'Oh oh, error!',
        body: ex.message,
      });
      setError('api', { type: 'manual', message: ex.message });
    }
  };

  let accountDefaultValue = null as any;
  let siteDefaultValue = null as any;
  let buildingDefaultValue = null as any;
  let loadDefaultValue = null as any;
  const lastDetail = JSON.parse(sessionStorage.getItem('lastDetail'));
  if (lastDetail?.data['LoadId'] && lastDetail?.data['Label']) {
    accountDefaultValue = lastDetail.data.Building.Site.Account;
    siteDefaultValue = lastDetail.data.Building.Site;
    buildingDefaultValue = lastDetail.data.Building;
    loadDefaultValue = lastDetail.data;
  }
  if (lastDetail?.data['BuildingId'] && lastDetail?.data['Label']) {
    accountDefaultValue = lastDetail.data.Site.Account;
    siteDefaultValue = lastDetail.data.Site;
    buildingDefaultValue = lastDetail.data;
  } else if (lastDetail?.data['SiteId'] && lastDetail?.data['Label']) {
    accountDefaultValue = lastDetail.data.Account;
    siteDefaultValue = lastDetail.data;
  } else if (lastDetail?.data['AccountId'] && lastDetail?.data['Label']) {
    accountDefaultValue = lastDetail.data;
  }

  React.useEffect(() => {
    if (loadDefaultValue) {
      setValue('Load', loadDefaultValue);
      setValue('Building', loadDefaultValue.Building);
      setValue('Site', loadDefaultValue.Building.Site);
      setValue('Account', loadDefaultValue.Building.Site.Account);
      searchContacts({ variables: { search: `%%`, AccountId: loadDefaultValue.Building.Site.Account.AccountId } });
    } else if (buildingDefaultValue) {
      setValue('Building', buildingDefaultValue);
      setValue('Site', buildingDefaultValue.Site);
      setValue('Account', buildingDefaultValue.Site.Account);
      searchLoads({ variables: { BuildingId: buildingDefaultValue.BuildingId, search: `%%` } });
      searchContacts({ variables: { search: `%%`, AccountId: buildingDefaultValue.Site.Account.AccountId } });
    } else if (siteDefaultValue) {
      setValue('Site', siteDefaultValue);
      setValue('Account', siteDefaultValue.Account);
      searchBuildings({ variables: { SiteId: siteDefaultValue.SiteId, search: `%%` } });
      searchContacts({ variables: { search: `%%`, AccountId: siteDefaultValue.Account } });
    } else if (accountDefaultValue) {
      setValue('Account', accountDefaultValue);
      searchSites({ variables: { AccountId: accountDefaultValue.AccountId, search: `%%` } });
      searchContacts({ variables: { search: `%%`, AccountId: accountDefaultValue.AccountId } });
    } else {
      searchAccounts({ variables: { search: `%%` } });
      searchSites({ variables: { search: `%%` } });
      searchBuildings({ variables: { search: `%%` } });
      searchContacts({ variables: { search: `%%` } });
    }
    // eslint-disable-next-line
  }, ['open']);

  return (
    <div className={styles.customModal}>
      <Dialog
        className={styles.addLoad}
        open={open}
        onClose={(evt) => {
          reset();
          clearErrors();
          if (open) {
            setOpen(false);
          }
        }}>
        <form onSubmit={handleSubmit(onSubmit)} data-private autoComplete="off">
          <DialogTitle>Add Project</DialogTitle>
          <DialogContent>
            <div className={styles.row}>
              <Controller
                name="Account"
                control={control}
                rules={{
                  required: 'Please select an account',
                }}
                render={({ field }) => (
                  <Autocomplete
                    label="Account"
                    data={AccountSearchData?.dw_Accounts || []}
                    labelKey="Label"
                    valueKey="AccountId"
                    loading={AccountSearchLoading}
                    getData={searchAccounts}
                    isDisabled={accountDefaultValue}
                    value={Account}
                    callback={(item) => {
                      field.onChange(item?.FullData);
                      if (item?.FullData) {
                        const newQuery = { variables: { AccountId: item.FullData?.AccountId, search: `%%` } };
                        searchSites(newQuery);
                        searchContacts(newQuery);
                      }
                      setValue('Site', null);
                      setValue('Building', null);
                      setValue('Load', null);
                      setValue('Assignee', null);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.Account?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.Account.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <Controller
                name="Site"
                control={control}
                rules={{
                  required: 'Please select a site',
                }}
                render={({ field }) => (
                  <Autocomplete
                    label="Site"
                    data={SiteSearchData?.dw_Sites || []}
                    labelKey="Label"
                    valueKey="SiteId"
                    loading={SiteSearchLoading}
                    value={Site}
                    isDisabled={siteDefaultValue || !Account}
                    getData={(query: any) => {
                      const account = getValues('Account');
                      const newQuery = { ...query };
                      newQuery.variables.AccountId = account?.AccountId;
                      searchSites(newQuery);
                    }}
                    callback={(item) => {
                      field.onChange(item?.FullData);
                      if (item?.FullData) {
                        const newQuery = { variables: { SiteId: item.FullData?.SiteId, search: `%%` } };
                        searchBuildings(newQuery);
                      }
                      setValue('Building', null);
                      setValue('Load', null);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.Site?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.Site.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <Controller
                name="Building"
                control={control}
                rules={{
                  required: 'Please select a building',
                }}
                render={({ field }) => (
                  <Autocomplete
                    label="Building"
                    data={BuildingSearchData?.dw_Buildings || []}
                    labelKey="Label"
                    valueKey="BuildingId"
                    loading={BuildingSearchLoading}
                    value={Building}
                    isDisabled={buildingDefaultValue || !Site}
                    getData={(query: any) => {
                      const site = getValues('Site');
                      const newQuery = { ...query };
                      newQuery.variables.SiteId = site?.SiteId;
                      searchBuildings(newQuery);
                    }}
                    callback={(item) => {
                      field.onChange(item?.FullData);
                      if (item?.FullData) {
                        const newQuery = { variables: { BuildingId: item.FullData?.BuildingId, search: `%%` } };
                        searchLoads(newQuery);
                      }
                      setValue('Load', null);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.Building?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.Building.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <Controller
                name="Load"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    label="Load"
                    data={LoadSearchData?.dw_Loads || []}
                    labelKey="Label"
                    valueKey="LoadId"
                    loading={LoadSearchLoading}
                    value={Load}
                    isDisabled={loadDefaultValue || !Building}
                    getData={(query: any) => {
                      const building = getValues('Building');
                      const newQuery = { ...query };
                      newQuery.variables.BuildingId = building?.BuildingId;
                      searchLoads(newQuery);
                    }}
                    callback={(item) => {
                      field.onChange(item?.FullData);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.Load?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.Load.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <Controller
                name="Assignee"
                control={control}
                // rules={{
                //   required: 'Please select an assignee',
                // }}
                render={({ field }) => (
                  <Autocomplete
                    label="Assignee"
                    data={ContactSearchData?.dw_Contacts || []}
                    labelKey="DisplayName"
                    valueKey="ContactId"
                    loading={ContactSearchLoading}
                    isDisabled={!Account}
                    getData={(query: any) => {
                      const account = getValues('Account');
                      const newQuery = { ...query };
                      newQuery.variables.AccountId = account?.AccountId;
                      searchContacts(newQuery);
                    }}
                    value={Assignee}
                    callback={(item) => {
                      field.onChange(item?.FullData);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.Assignee?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.Assignee.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <TextField label="Label" name="Label" outlined required {...register('Label')} floatLabel={true} />
              {errors.Label && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a label</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <TextField
                label="Description"
                name="Description"
                outlined
                {...register('Description')}
                floatLabel={true}
                textarea
                rows={3}
              />
              {errors.Description && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter description</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <TextField
                label="Comments"
                name="Comments"
                outlined
                {...register('Comments')}
                floatLabel={true}
                textarea
                rows={3}
              />
              {errors.Comments && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter comments</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <TextField label="Cost" name="Cost" outlined {...register('Cost')} floatLabel={true} type="number" />
              {errors.Cost && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a cost</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <TextField
                label="UnitCost"
                name="UnitCost"
                outlined
                {...register('UnitCost')}
                floatLabel={true}
                type="number"
              />
              {errors.UnitCost && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a Uni Cost</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <TextField
                label="CumulativeKwhSavings"
                name="CumulativeKwhSavings"
                outlined
                {...register('CumulativeKwhSavings')}
                floatLabel={true}
                type="number"
              />
              {errors.CumulativeKwhSavings && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter Cumulative Kwh Savings</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <TextField
                label="AnnualKwhSavings"
                name="AnnualKwhSavings"
                outlined
                {...register('AnnualKwhSavings')}
                floatLabel={true}
                type="number"
              />
              {errors.AnnualKwhSavings && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter Annual Kwh Savings</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <TextField
                label="AnnualSavings"
                name="AnnualSavings"
                outlined
                {...register('AnnualSavings')}
                floatLabel={true}
                type="number"
              />
              {errors.AnnualSavings && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter Annual Savings</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <Controller
                name="NotifiedOn"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={moment().format('YYYY-MM-DD')}
                render={({ field }) => (
                  <TextField
                    label="Notified On"
                    name="NotifiedOn"
                    type="date"
                    outlined
                    required
                    autoFocus
                    floatLabel={true}
                    {...field}
                  />
                )}
              />
              {errors.NotifiedOn && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a valid Notified On date DD/MM/YYYY</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <Controller
                name="ImplementationDate"
                control={control}
                // defaultValue={moment().format('YYYY-MM-DD')}
                render={({ field }) => (
                  <TextField
                    label="Implementation Date"
                    name="ImplementationDate"
                    type="date"
                    outlined
                    autoFocus
                    floatLabel={true}
                    {...field}
                  />
                )}
              />
              {errors.ImplementationDate && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a valid Implementation Date date DD/MM/YYYY</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <Controller
                name="Priority"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={'1'}
                render={({ field }) => (
                  <Select
                    label="Priority"
                    name="Priority"
                    outlined
                    required
                    options={projectPriorityFields}
                    defaultValue={'1'}
                    {...field}
                  />
                )}
              />
              {errors.Priority && (
                <div className={styles.error}>
                  <Typography use="caption">Please select a valid Priority</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <Controller
                name="Status"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={'1'}
                render={({ field }) => (
                  <Select
                    label="Status"
                    name="Status"
                    outlined
                    required
                    options={statusFields}
                    defaultValue={'1'}
                    {...field}
                  />
                )}
              />
              {errors.Status && (
                <div className={styles.error}>
                  <Typography use="caption">Please select a valid status</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              {errors.api && (
                <div className={styles.error} style={{ marginBottom: '16px' }}>
                  <Typography use="caption">{errors.api.message}</Typography>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-between' }}>
            <Button
              type="button"
              label="Close"
              disabled={formState.isSubmitting}
              outlined
              onClick={() => {
                setOpen(false);
                clearErrors();
                reset();
              }}
            />
            <Button
              raised
              disabled={formState.isSubmitting}
              label={formState.isSubmitting ? 'Saving' : 'Save'}
              icon={formState.isSubmitting ? <CircularProgress theme="secondary" /> : null}
              onClick={() => {
                clearErrors();
              }}
              type="submit"
            />
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AddProjectModal;
