import React from 'react';
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';
import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';
import { TextField } from '@rmwc/textfield';
import { Select } from '@rmwc/select';

import { useBulkDisqualifyNotificationGroupsMutation } from 'generated/graphql';
import { mlQualificationStatusTypes, queue } from 'utilities';

import styles from './CustomModal.module.scss';

export const BulkChangeNotificationGroupsModal = ({ open, setOpen, additionalModalProps }: any) => {
  const { formState, control, handleSubmit, setError, clearErrors, reset } = useForm();
  const { errors } = formState;

  const types = mlQualificationStatusTypes.map((x, i) => ({ label: x, value: i.toString() }));

  const [bulkFn] = useBulkDisqualifyNotificationGroupsMutation();

  const onSubmit = async (data: any) => {
    try {
      // @ts-ignore
      const notificationGroupIds = additionalModalProps.variables.notificationGroupIds;
      const variables = {
        notificationGroupIds: notificationGroupIds,
        qualificationStatus: Number(data.qualificationStatus),
        rootCause: data.rootCause || '',
        updatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
      };
      const result = await bulkFn({ variables: variables });
      if (result?.data?.update_dw_MLNotificationGroup?.affected_rows) {
        setOpen(false);
        queue.notify({
          icon: 'done',
          title: 'Success!',
          body: `${notificationGroupIds.length} Notifications Updated!`,
        });

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        throw new Error('There was a problem updating notifications, please refresh and review.');
      }
    } catch (ex) {
      queue.clearAll();
      queue.notify({
        icon: 'priority_high',
        title: 'Oh oh, error!',
        body: ex.message,
      });
      setError('api', { type: 'manual', message: ex.message });
    }
  };

  return (
    <div className={styles.customModal}>
      <Dialog
        className={styles.bulkChangeNotifications}
        open={open}
        onClose={(evt) => {
          reset();
          clearErrors();
          if (open) {
            setOpen(false);
          }
        }}>
        <form onSubmit={handleSubmit(onSubmit)} data-private autoComplete="off">
          <DialogTitle>Bulk Change Notifications</DialogTitle>
          <DialogContent>
            <div className={styles.row}>
              <Controller
                name="qualificationStatus"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={'2'}
                render={({ field }) => (
                  <Select
                    label="Qualification Status"
                    name="qualificationStatus"
                    outlined
                    required
                    options={types}
                    defaultValue={'2'}
                    {...field}
                  />
                )}
              />
              {errors.Status && (
                <div className={styles.error}>
                  <Typography use="caption">Please select a valid status</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <Controller
                name="rootCause"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => <TextField outlined label="Root Cause" autoFocus floatLabel={true} {...field} />}
              />
            </div>

            <div className={styles.row}>
              {errors.api && (
                <div className={styles.error} style={{ marginBottom: '16px' }}>
                  <Typography use="caption">{errors.api.message}</Typography>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-between' }}>
            <Button
              type="button"
              label="Close"
              disabled={formState.isSubmitting}
              outlined
              onClick={() => {
                setOpen(false);
                clearErrors();
                reset();
              }}
            />
            <Button
              raised
              disabled={formState.isSubmitting}
              label={formState.isSubmitting ? 'Saving' : 'Save'}
              icon={formState.isSubmitting ? <CircularProgress theme="secondary" /> : null}
              onClick={() => {
                clearErrors();
              }}
              type="submit"
            />
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default BulkChangeNotificationGroupsModal;
