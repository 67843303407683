import React from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';
import { TextField } from '@rmwc/textfield';
import { Select } from '@rmwc/select';
import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';

import {
  useAccountSearchLazyQuery,
  useSiteSearchLazyQuery,
  useBuildingSearchLazyQuery,
  useFeedsTypeSearchLazyQuery,
  useInsertFeedMutation,
} from 'generated/graphql';
import { queue, StatusEnum } from 'utilities';
import { Autocomplete } from 'components';

import styles from './CustomModal.module.scss';

export const AddFeedModal = ({ open, setOpen }: any) => {
  const history = useHistory();
  const {
    formState,
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    setValue,
    getValues,
    watch,
  } = useForm();
  const { errors } = formState;
  const [Account, Site, Building] = watch(['Account', 'Site', 'Building']);
  const [searchAccounts, { data: AccountSearchData, loading: AccountSearchLoading }] = useAccountSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [searchSites, { data: SiteSearchData, loading: SiteSearchLoading }] = useSiteSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [searchBuildings, { data: BuildingSearchData, loading: BuildingSearchLoading }] = useBuildingSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [
    searchFeedsTypes,
    { data: FeedsTypesSearchData, loading: FeedsTypesSearchLoading },
  ] = useFeedsTypeSearchLazyQuery({ fetchPolicy: 'no-cache' });

  const [insertFn] = useInsertFeedMutation();
  const statusFields = [];
  for (let k in Object.keys(StatusEnum)) {
    if (StatusEnum[k]) {
      statusFields.push({ label: StatusEnum[k], value: Number(k) });
    }
  }

  const onSubmit = async (data: any) => {
    try {
      const variables = {
        object: {
          ...data,
          BuildingId: data.Building?.BuildingId,
          FeedId: uuidv4(),
          TypeId: data.FeedsType?.TypeId,
          SerialNumber: data?.SerialNumber ? data.SerialNumber : '',
          UserName: data?.UserName ? data.UserName : '',
          Password: data?.Password ? data.Password : '',
          SimCard: data?.SimCard ? data.SimCard : '',
          IntervalEmitFrequency: data?.IntervalEmitFrequency ? data.IntervalEmitFrequency : 1,
          CreatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
          UpdatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
        },
      };
      delete variables.object.Account;
      delete variables.object.Site;
      delete variables.object.Building;
      delete variables.object.FeedsType;

      const { data: resultData, errors } = await insertFn({ variables });
      if (errors?.length > 0) {
        throw new Error(errors[0].message);
      }
      queue.clearAll();
      queue.notify({
        icon: 'done',
        title: 'Success!',
        body: `Feed Added!`,
      });

      setOpen(false);
      setTimeout(() => {
        // window.location.reload();
        history.push('/app/detail/feeds/' + resultData.insert_dw_Feeds_one.FeedId);
      }, 1000);
    } catch (ex) {
      queue.clearAll();
      queue.notify({
        icon: 'priority_high',
        title: 'Oh oh, error!',
        body: ex.message,
      });
      setError('api', { type: 'manual', message: ex.message });
    }
  };

  let accountDefaultValue = null as any;
  let siteDefaultValue = null as any;
  let buildingDefaultValue = null as any;
  let loadDefaultValue = null as any;
  const lastDetail = JSON.parse(sessionStorage.getItem('lastDetail'));
  if (lastDetail?.data['LoadId'] && lastDetail?.data['Label']) {
    accountDefaultValue = lastDetail.data.Building.Site.Account;
    siteDefaultValue = lastDetail.data.Building.Site;
    buildingDefaultValue = lastDetail.data.Buiding;
    loadDefaultValue = lastDetail.data;
  } else if (lastDetail?.data['BuildingId'] && lastDetail?.data['Label']) {
    accountDefaultValue = lastDetail.data.Site.Account;
    siteDefaultValue = lastDetail.data.Site;
    buildingDefaultValue = lastDetail.data;
  } else if (lastDetail?.data['SiteId'] && lastDetail?.data['Label']) {
    accountDefaultValue = lastDetail.data.Account;
    siteDefaultValue = lastDetail.data;
  } else if (lastDetail?.data['AccountId'] && lastDetail?.data['Label']) {
    accountDefaultValue = lastDetail.data;
  }

  React.useEffect(() => {
    if (loadDefaultValue) {
      setValue('Building', loadDefaultValue.Building);
      setValue('Site', loadDefaultValue.Building.Site);
      setValue('Account', loadDefaultValue.Building.Site.Account);
    } else if (buildingDefaultValue) {
      setValue('Building', buildingDefaultValue);
      setValue('Site', buildingDefaultValue.Site);
      setValue('Account', buildingDefaultValue.Site.Account);
    } else if (siteDefaultValue) {
      setValue('Site', siteDefaultValue);
      setValue('Account', siteDefaultValue.Account);
      searchBuildings({ variables: { SiteId: siteDefaultValue.SiteId, search: `%%` } });
    } else if (accountDefaultValue) {
      setValue('Account', accountDefaultValue);
      searchSites({ variables: { AccountId: accountDefaultValue.AccountId, search: `%%` } });
    } else {
      searchAccounts({ variables: { search: `%%` } });
      searchSites({ variables: { search: `%%` } });
      searchBuildings({ variables: { search: `%%` } });
    }
    searchFeedsTypes({ variables: { search: `%%` } });

    // eslint-disable-next-line
  }, ['open']);

  return (
    <div className={styles.customModal}>
      <Dialog
        className={styles.addLoad}
        open={open}
        onClose={(evt) => {
          reset();
          clearErrors();
          if (open) {
            setOpen(false);
          }
        }}>
        <form onSubmit={handleSubmit(onSubmit)} data-private autoComplete="off">
          <DialogTitle>Add Feed</DialogTitle>
          <DialogContent>
            <div className={styles.row}>
              <Controller
                name="Account"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    label="Account"
                    data={AccountSearchData?.dw_Accounts || []}
                    labelKey="Label"
                    valueKey="AccountId"
                    loading={AccountSearchLoading}
                    getData={searchAccounts}
                    isDisabled={accountDefaultValue}
                    value={Account}
                    callback={(item) => {
                      field.onChange(item?.FullData);
                      if (item?.FullData) {
                        const newQuery = { variables: { AccountId: item.FullData?.AccountId, search: `%%` } };
                        searchSites(newQuery);
                      }
                      setValue('Site', null);
                      setValue('Building', null);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.Account?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.Account.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <Controller
                name="Site"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    label="Site"
                    data={SiteSearchData?.dw_Sites || []}
                    labelKey="Label"
                    valueKey="SiteId"
                    loading={SiteSearchLoading}
                    value={Site}
                    isDisabled={siteDefaultValue || !Account}
                    getData={(query: any) => {
                      const account = getValues('Account');
                      const newQuery = { ...query };
                      newQuery.variables.AccountId = account?.AccountId;
                      searchSites(newQuery);
                    }}
                    callback={(item) => {
                      field.onChange(item?.FullData);
                      if (item?.FullData) {
                        const newQuery = { variables: { SiteId: item.FullData?.SiteId, search: `%%` } };
                        searchBuildings(newQuery);
                      }
                      setValue('Building', null);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.Site?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.Site.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <Controller
                name="Building"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    label="Building"
                    data={BuildingSearchData?.dw_Buildings || []}
                    labelKey="Label"
                    valueKey="BuildingId"
                    loading={BuildingSearchLoading}
                    value={Building}
                    isDisabled={buildingDefaultValue || !Site}
                    getData={(query: any) => {
                      const site = getValues('Site');
                      const newQuery = { ...query };
                      newQuery.variables.SiteId = site?.SiteId;
                      searchBuildings(newQuery);
                    }}
                    callback={(item) => {
                      field.onChange(item?.FullData);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.Building?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.Building.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <TextField label="Label" name="Label" outlined required {...register('Label')} floatLabel={true} />
              {errors.Label && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a label</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <Controller
                name="FeedsType"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    label="Feed Type"
                    data={FeedsTypesSearchData?.dw_FeedsTypes || []}
                    labelKey="Label"
                    valueKey="TypeId"
                    loading={FeedsTypesSearchLoading}
                    getData={searchFeedsTypes}
                    callback={(item) => {
                      field.onChange(item?.FullData);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.FeedsType?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.FeedsType.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <TextField label="SimCard" name="SimCard" outlined {...register('SimCard')} floatLabel={true} />
              {errors.DisplayName && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a Sim Card</Typography>
                </div>
              )}
            </div>
            <div className={styles.row}>
              <TextField
                label="SerialNumber"
                name="SerialNumber"
                outlined
                {...register('SerialNumber')}
                floatLabel={true}
              />
              {errors.DisplayName && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a Serial Number</Typography>
                </div>
              )}
            </div>
            <div className={styles.row}>
              <TextField label="UserName" name="UserName" outlined {...register('UserName')} floatLabel={true} />
              {errors.DisplayName && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a UserName</Typography>
                </div>
              )}
            </div>
            <div className={styles.row}>
              <TextField label="Password" name="Password" outlined {...register('Password')} floatLabel={true} />
              {errors.DisplayName && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a Password</Typography>
                </div>
              )}
            </div>
            <div className={styles.row}>
              <TextField
                label="Notes"
                name="Notes"
                outlined
                {...register('Notes')}
                floatLabel={true}
                textarea
                rows={3}
              />
              {errors.Notes && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter Notes</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <Controller
                name="Status"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={'1'}
                render={({ field }) => (
                  <Select
                    label="Status"
                    name="Status"
                    outlined
                    required
                    options={statusFields}
                    defaultValue={'1'}
                    {...field}
                  />
                )}
              />
              {errors.Status && (
                <div className={styles.error}>
                  <Typography use="caption">Please select a valid status</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              {errors.api && (
                <div className={styles.error} style={{ marginBottom: '16px' }}>
                  <Typography use="caption">{errors.api.message}</Typography>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-between' }}>
            <Button
              type="button"
              label="Close"
              disabled={formState.isSubmitting}
              outlined
              onClick={() => {
                setOpen(false);
                clearErrors();
                reset();
              }}
            />
            <Button
              raised
              disabled={formState.isSubmitting}
              label={formState.isSubmitting ? 'Saving' : 'Save'}
              icon={formState.isSubmitting ? <CircularProgress theme="secondary" /> : null}
              onClick={() => {
                clearErrors();
              }}
              type="submit"
            />
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AddFeedModal;
