export enum EntityEnum {
  dw_Accounts = 1,
  dw_Groups = 2,
  dw_Sites = 3,
  dw_Buildings = 4,
  dw_Loads = 5,
  dw_Channels = 6,
}

export enum UtilityTypeEnum {
  None = 0,
  Electricity = 1,
  Water = 2,
  Gas = 3,
  Production = 4,
  Heat = 5,
  Steam = 6,
  Weather = 7,
  Enviromental = 8,
  GHGEmmissions = 9,
  Other = 10,
  Solar = 11,
}

export enum SourceTypeEnum {
  Unknown = 0,
  Account = 1,
  AccountAddress = 2,
  Contact = 3,
  Site = 4,
  Building = 5,
  Feed = 6,
  Load = 7,
  Channel = 8,
  Dashboard = 9,
  Report = 10,
  Project = 11,
  FeedConfig = 12,
  FeedConfigChannel = 13,
  ContactRole = 14,
  MLNotifications = 15,
  ChannelRule = 16,
  ChannelMapping = 17,
  Group = 18,
  GroupsSites = 19,
  BulkInsertLoadChannels = 20,
  BulkInsertFeedConfigChannels = 21,
}

export enum StatusEnum {
  Active = 1,
  Inactive = 2,
  Deleted = 3,
  Unvalidated = 4,
}

export enum AccountStatusEnum {
  Active = 1,
  Inactive = 2,
}

export enum AccountSubscriptionStatusEnum {
  Active = 0,
  Suspended = 1,
  Closed = 2,
  Created = 3,
  Cancelling = 4,
}

export enum ContactStatusEnum {
  Active = 1,
  Inactive = 2,
  Deleted = 3,
}

export enum ContactRoleStatusEnum {
  Active = 1,
  Inactive = 2,
  Suspended = 3,
  Invited = 4,
}

export enum ProjectPriorityEnum {
  Low = 0,
  Medium = 1,
  High = 2,
}

export enum ProjectStatusEnum {
  Unknown = 0,
  New = 1,
  InProgress = 2,
  Complete = 3,
}

export enum JobStatusEnum {
  Pending = 0,
  Queued = 1,
  Processing = 2,
  Completed = 3,
  Failed = 4,
  IngestionQueue = 5,
}

export enum DashboardStatusEnum {
  Active = 1,
  Deleted = 2,
  Pending = 3,
}

export enum SummationTypeEnum {
  Unknown = 0,
  SUM = 1,
  AVG = 2,
}

export enum ValueTypeEnum {
  Unknown = 0,
  ActualForInterval = 1,
  Instantaneous = 2,
  Cumulative = 3,
}

export enum NatureTypeEnum {
  Unknown = 0,
  Consumption = 1,
  Mlr = 2,
  Losses = 3,
  WasteWater = 4,
  Financial = 5,
  Other = 10,
}

export enum ChannelTypeEnum {
  Actual = 1,
  Calculated = 2,
  MLR = 3,
}

export enum ContactTypeEnum {
  Client = 1,
  Esp = 2,
}

export enum RoleEnum {
  User = 1,
  Admin = 2,
  Reviewer = 3,
  Contributor = 4,
  Editor = 5,
}

export enum NabersRatingEnum {
  None = 0,
  Tenant = 1,
  Basebuild = 2,
}

export enum ConsumptionJobTypeEnum {
  Unknown = 0,
  EICT = 1,
  IoT = 2,
  AIUT = 3,
  Clariti = 4,
  eGuage = 5,
}

export enum ConsumptionJobReadingTypeEnum {
  Unknown = 0,
  A = 1,
  E = 2,
}

export enum ConsumptionJobStatusEnum {
  Pending = 0,
  Queued = 1,
  Processing = 2,
  Completed = 3,
  Failed = 4,
}

export enum AddressTypeEnum {
  Unknown = 0,
  Physical = 1,
  Billing = 2,
}

export enum BaselineModeEnum {
  Unknown = 0,
  Baseline = 1,
  MLR = 2,
}

export enum ReportTypeEnum {
  Unknown = 0,
  Operational = 1,
  Sustainability = 2,
}

export enum WeatherReadingTypeEnum {
  Recorded = 0,
  Forecast = 1,
}

export enum ChannelsValueTypeEnum {
  Unknown = 0,
  ActualForInterval = 1,
  Instantaneous = 2,
  Cumulative = 2,
}

export enum FeedsConfigStatusEnum {
  Unknown = 0,
  Active = 1,
  Inactive = 2,
  Deleted = 3,
}

export enum FeedsRegisterTypeEnum {
  R4Integer = 0,
  R4Floating = 1,
  R3Integer = 2,
  R3Floating = 3,
  R1Boolean = 4,
  R0Boolean = 5,
}

export enum NotificationFrequencyEnum {
  None = 0,
  Monthly = 1,
}

export enum SubscriptionStatusEnum {
  Active = 0,
  Suspended = 1,
  Closed = 2,
  Created = 3,
  Cancelling = 4,
}

export const functionalUses = [
  { label: '', value: 0 },
  { label: 'Industrial', value: 1 },
  { label: 'Data Center', value: 2 },
  { label: 'Hotel / Motel', value: 3 },
  { label: 'Manufacturing', value: 4 },
  { label: 'Office Building', value: 5 },
  { label: 'Car Park', value: 6 },
  { label: 'Education', value: 7 },
  { label: 'Healthcare', value: 8 },
  { label: 'Residential', value: 9 },
  { label: 'Library', value: 10 },
  { label: 'Public Pool', value: 11 },
  { label: 'Public Assembly', value: 12 },
  { label: 'Other', value: 13 },
  { label: 'None', value: 100 },
];

export const heatSystems = [
  { label: '', value: 0 },
  { label: 'Coal / Fuel Oil', value: 1 },
  { label: 'Electric Resistive', value: 2 },
  { label: 'Electric Heat Pump', value: 3 },
  { label: 'Gas', value: 4 },
  { label: 'Bio Fuel', value: 5 },
  { label: 'None', value: 100 },
];

export const coolingSystems = [
  { label: '', value: 0 },
  { label: 'Heatpump', value: 1 },
  { label: 'Chiller - Water Cooled', value: 2 },
  { label: 'Chiller - Air Cooled', value: 3 },
  { label: 'Chiller - Hydronic', value: 4 },
  { label: 'Bio Fuel', value: 5 },
  { label: 'None', value: 100 },
];

export const ratingTypes = [
  { label: '', value: 0 },
  { label: 'GREENSTAR', value: 1 },
  { label: 'NABERSNZ', value: 2 },
  { label: 'LEED', value: 3 },
];

export const weatherStations = [
  { label: 'Auckland Aiport', value: 1 },
  { label: 'Manukau', value: 2 },
  { label: 'Wellington', value: 3 },
  { label: 'Somewhere else', value: 4 },
];

export const unitToSummationType = {
  Binary: 'AVG',
  'Degrees Celsius': 'AVG',
  Hz: 'AVG',
  'kg/kWh': 'AVG',
  kVa: 'AVG',
  kVAr: 'AVG',
  kW: 'AVG',
  L1A: 'AVG',
  'L1A Max': 'AVG',
  L1V: 'AVG',
  L2A: 'AVG',
  'L2A Max': 'AVG',
  L2V: 'AVG',
  L3A: 'AVG',
  'L3A Max': 'AVG',
  L3V: 'AVG',
  'Litres per second': 'AVG',
  Occupancy: 'AVG',
  'on/off': 'AVG',
  Pascals: 'AVG',
  'Power Factor': 'AVG',
  PUE: 'AVG',
  Ratio: 'AVG',
  'RH (Relative Humidity)': 'AVG',
  VoltsA: 'AVG',
  VoltsB: 'AVG',
  VoltsC: 'AVG',
  'Baseline kWh': 'SUM',
  'Baseline m3': 'SUM',
  Cans: 'SUM',
  'CDD for 14C': 'SUM',
  'CDD for 16C': 'SUM',
  'CDD for 18C': 'SUM',
  'CDD for -5C': 'SUM',
  Count: 'SUM',
  Days: 'SUM',
  'Gas kWh': 'SUM',
  'GasNorm m3': 'SUM',
  'GasRaw m3': 'SUM',
  'Generated kVAh': 'SUM',
  'Generated kVArh': 'SUM',
  'Generated kWh': 'SUM',
  GJ: 'SUM',
  'HDD for 18C': 'SUM',
  Hours: 'SUM',
  Joules: 'SUM',
  kgs: 'SUM',
  kVAh: 'SUM',
  kVArh: 'SUM',
  kWh: 'SUM',
  Metres: 'SUM',
  Minutes: 'SUM',
  NONE: 'SUM',
  'Predicted kWh': 'SUM',
  'Predicted m3': 'SUM',
  'Reduction Target kWh': 'SUM',
  'Reduction Target m3': 'SUM',
  'Relative Humidity': 'SUM',
  'Square Metres': 'SUM',
  'Thermal kWh': 'SUM',
  Tonnes: 'SUM',
  Unknown: 'SUM',
  'Water m3': 'SUM',
};
