import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { useApolloClient } from '@apollo/client';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';
import { Select } from '@rmwc/select';
import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';
import Autocomplete from 'components/Autocomplete/Autocomplete';

import {
  useInsertChannelRuleMutation,
  useChannelSearchLazyQuery,
  GetChannelRulesByChildChannelIdQuery,
  GetChannelRulesByChildChannelIdDocument,
} from 'generated/graphql';
import { queue, StatusEnum } from 'utilities';

import styles from './CustomModal.module.scss';

export const AddChannelRuleModal = ({ open, setOpen, additionalModalProps }: any) => {
  const client = useApolloClient();
  const { formState, control, handleSubmit, setError, clearErrors, reset, watch } = useForm();
  const { errors } = formState;
  const [SourceChannel] = watch(['SourceChannel']);

  const [searchChannels, { data: ChannelSearchData, loading: ChannelSearchLoading }] = useChannelSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [insertFn] = useInsertChannelRuleMutation();

  const statusFields = [];
  for (let k in Object.keys(StatusEnum)) {
    if (StatusEnum[k]) {
      statusFields.push({ label: StatusEnum[k], value: Number(k) });
    }
  }

  const lastDetail = JSON.parse(sessionStorage.getItem('lastDetail'));
  const ChannelId = lastDetail?.data?.ChannelId;
  const AccountId = lastDetail?.data?.Load?.Building?.Site?.Account?.AccountId;
  const AccountLabel = lastDetail?.data?.Load?.Building?.Site?.Account?.Label;
  const isDemo = AccountLabel.indexOf('Demo Account') > -1;

  const validateChannel = async (newChannelId: string, parentChannelId: string) => {
    const result = await client.query<GetChannelRulesByChildChannelIdQuery>({
      query: GetChannelRulesByChildChannelIdDocument,
      variables: {
        ChannelId: newChannelId,
      },
      fetchPolicy: 'no-cache',
    });
    const channelRules = result.data.dw_ChannelsRules;
    const parentChannel = channelRules.find((x) => x.SourceChannelId === parentChannelId);

    if (channelRules.length === 0 && !parentChannel) {
      return true;
    } else if (parentChannel) {
      return false;
    } else {
      const promises = channelRules.map((x) => {
        return validateChannel(x.SourceChannelId, parentChannelId);
      });
      const results = await Promise.all(promises);
      return results.indexOf(false) === -1;
    }
  };

  const validateRules = async (newChannelId: string, parentChannelId: string) => {
    const existingChannelRuleIds = additionalModalProps.channelRules.map((x) => x.Channel.ChannelId);
    if (existingChannelRuleIds.indexOf(newChannelId) > -1) {
      alert('This channel is already used in the formula');
      return false;
    }
    if (parentChannelId === newChannelId) {
      alert("Cannot add this channel as a channel rule as it's the current channel being viewed");
      return false;
    }

    const result = await validateChannel(newChannelId, parentChannelId);

    if (result === false) {
      alert("Cannot add this calculated channel as one of it's own rules references the parent channel");
      return false;
    }

    return true;
  };

  const onSubmit = async (data: any) => {
    // @ts-ignore
    const result = await validateRules(data.SourceChannel?.ChannelId, ChannelId);
    if (!result) {
      return;
    }
    try {
      const variables = {
        object: {
          RuleId: uuidv4(),
          ChannelId: ChannelId,
          SourceChannelId: data.SourceChannel?.ChannelId,
          SourceChannelKey: data.SourceChannel?.ChannelKey,
          Status: data.Status,
          CreatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
          UpdatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
        },
      };
      const { errors } = await insertFn({ variables });
      if (errors?.length > 0) {
        throw new Error(errors[0].message);
      }
      queue.clearAll();
      queue.notify({
        icon: 'done',
        title: 'Success!',
        body: `Channel Rule Added!`,
      });

      setOpen(false);
      setTimeout(() => {
        window.location.reload();
        // history.push('/app/detail/contacts/' + resultData.insert_dw_Contacts_one.ContactId);
      }, 1000);
    } catch (ex) {
      queue.clearAll();
      queue.notify({
        icon: 'priority_high',
        title: 'Oh oh, error!',
        body: ex.message,
      });
      setError('api', { type: 'manual', message: ex.message });
    }
  };

  React.useEffect(() => {
    if (isDemo) {
      searchChannels({ variables: { search: `%%` } });
    } else {
      searchChannels({ variables: { AccountId: AccountId, search: `%%` } });
    }
    // eslint-disable-next-line
  }, ['open']);
  return (
    <div className={styles.customModal}>
      <Dialog
        className={styles.AddChannelRule}
        open={open}
        onClose={(evt) => {
          reset();
          clearErrors();
          if (open) {
            setOpen(false);
          }
        }}>
        <form onSubmit={handleSubmit(onSubmit)} data-private autoComplete="off">
          <DialogTitle>
            Add Channel Rule to: <br />
            {lastDetail?.data?.Label} ({lastDetail?.data?.ChannelKey})
          </DialogTitle>
          <DialogContent>
            <div className={styles.row}>
              <Controller
                name="SourceChannel"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    label="SourceChannel"
                    data={
                      ChannelSearchData?.dw_Channels.map((x) => {
                        return {
                          Label: `${x.Load?.Label}: ${x.Label} ${x.ChannelKey ? `(${x.ChannelKey})` : ''}`,
                          ChannelId: x.ChannelId,
                          ChannelKey: x.ChannelKey,
                          Type: x.Type,
                        };
                      }) || []
                    }
                    labelKey="Label"
                    valueKey="ChannelId"
                    loading={ChannelSearchLoading}
                    value={SourceChannel}
                    getData={(query: any) => {
                      const newQuery = { ...query };
                      if (!isDemo) {
                        newQuery.variables.AccountId = AccountId;
                      }
                      searchChannels(newQuery);
                    }}
                    callback={(item) => {
                      field.onChange(item?.FullData);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.SourceChannel?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.Load.message}</Typography>
                </div>
              ) : null}
            </div>
            <div className={styles.row}>
              <Controller
                name="Status"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={'1'}
                render={({ field }) => (
                  <Select
                    label="Status"
                    name="Status"
                    outlined
                    required
                    options={statusFields}
                    defaultValue={'1'}
                    {...field}
                  />
                )}
              />
              {errors.Status && (
                <div className={styles.error}>
                  <Typography use="caption">Please select a valid status</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              {errors.api && (
                <div className={styles.error} style={{ marginBottom: '16px' }}>
                  <Typography use="caption">{errors.api.message}</Typography>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-between' }}>
            <Button
              type="button"
              label="Close"
              disabled={formState.isSubmitting}
              outlined
              onClick={() => {
                setOpen(false);
                clearErrors();
                reset();
              }}
            />
            <Button
              raised
              disabled={formState.isSubmitting}
              label={formState.isSubmitting ? 'Saving' : 'Save'}
              icon={formState.isSubmitting ? <CircularProgress theme="secondary" /> : null}
              onClick={() => {
                clearErrors();
              }}
              type="submit"
            />
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AddChannelRuleModal;
