import React from 'react';
import moment from 'moment';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@rmwc/dialog';
import { CircularProgress } from '@rmwc/circular-progress';
import { Button } from '@rmwc/button';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

import { useNotificationGroupsMissingAnalyticsQuery } from 'generated/graphql';

import styles from './CustomModal.module.scss';

export const MissingNotificationsChartModal = ({ open, setOpen, additionalModalProps }: any) => {
  const { loading, error, data } = useNotificationGroupsMissingAnalyticsQuery({
    variables: {
      ...additionalModalProps.filters,
    },
  });

  const [areaType, setAreaType] = React.useState(additionalModalProps.areaType || 'Account');

  const dates = [];
  const categories = ['No Date'];
  const series = [];

  const chartOptions = {
    title: {
      text: '',
    },
    chart: {
      height: '600px',
      type: 'column',
      alignTicks: true,
      showAxis: true,
      animation: false,
      style: {
        fontFamily: '"Roboto", Helvetica, sans-serif',
      },
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      animation: false,
      formatter: function () {
        const filteredPoints = this.points.filter((point) => point.y !== null);
        let output = filteredPoints.slice(0, 20).reduce(function (s, point) {
          return s + '<br/>' + point.series.name;
        }, '<b>' + this.x + '</b>');
        if (filteredPoints.length >= 20) {
          output += `<br/>...(${filteredPoints.length - 20} remaining)...<br/>`;
        }
        return output;
      },
      shared: true,
    },
    legend: {
      enabled: false,
      align: 'center',
      verticalAlign: 'top',
      style: {
        itemStyle: {
          fontWeight: 400,
          color: '#616161',
        },
      },
    },
    plotOptions: {
      series: {
        animation: false,
      },
      column: {
        animation: false,
        stacking: 'normal',
        dataLabels: {
          enabled: false,
        },
      },
    },
    xAxis: {
      categories: categories,
    },
    yAxis: {
      min: 0,
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color:
            // theme
            (Highcharts.defaultOptions.title.style && Highcharts.defaultOptions.title.style.color) || 'gray',
        },
      },
    },
    series: series,
  };

  let dataProcessed = false;
  if (!loading && data?.dw_Buildings?.length > 0) {
    const idHash = {};
    const namesHash = {};
    data?.dw_Buildings.forEach((building) => {
      const date =
        building.MLNotificationGroups.length > 0 ? new Date(building.MLNotificationGroups[0].StartDate) : null;
      const momentDate = date ? moment(date) : null;
      const strDate = date ? momentDate.format('DD/MM/YYYY') : null;
      let id = building.Site.Account.AccountId;
      let label = building.Site.Account.Label;

      if (areaType === 'Site') {
        id = building.Site.SiteId;
        label = building.Site.Label;
      } else if (areaType === 'Building') {
        id = building.BuildingId;
        label = building.Label;
      }
      if (!idHash[id]) {
        idHash[id] = strDate;
      } else if (strDate && idHash[id] !== null) {
        const existingDate = moment(idHash[id], 'DD/MM/YYYY');
        if (momentDate.isAfter(existingDate)) {
          idHash[id] = strDate;
        }
      }
      namesHash[id] = label;
    });

    for (let k in idHash) {
      const dt = idHash[k];
      if (dt && dates.indexOf(dt) === -1) {
        dates.push(dt);
      }
    }

    dates.sort((a, b) => (moment(a, 'DD/MM/YYYY').isAfter(moment(b, 'DD/MM/YYYY')) ? 1 : -1));
    dates.forEach((date) => {
      categories.push(date);
    });

    for (let k in idHash) {
      const obj = { name: namesHash[k], data: new Array(categories.length).fill(null) };
      const innerDate = idHash[k];
      if (innerDate) {
        const dtIdx = categories.findIndex((x) => x === innerDate);
        obj.data[dtIdx] = 1;
      } else {
        obj.data[0] = 1;
      }
      series.push(obj);
    }

    dataProcessed = true;
  }

  return (
    <div className={`${styles.customModal} ${styles.fullscreen}`}>
      <Dialog
        className={styles.missingNotifications}
        open={open}
        onClose={(evt) => {
          if (open) {
            setOpen(false);
          }
        }}>
        <DialogTitle>Notifications by Latest Date ({areaType})</DialogTitle>
        <DialogContent>
          <div className={styles.chartContainer}>
            {loading || !dataProcessed ? (
              <div className={styles.customLoader}>
                <CircularProgress style={{ fontSize: '125px' }} />
              </div>
            ) : null}

            <div className={styles.chartContainer}>
              {dataProcessed && <HighchartsReact highcharts={Highcharts} options={chartOptions} />}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            label="Close"
            raised
            onClick={() => {
              setOpen(false);
            }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MissingNotificationsChartModal;
