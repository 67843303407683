import React from 'react';

import styles from './Loader.module.scss';

interface LoaderComponentProps {
  relative?: boolean;
  children?: any;
}
export const LoaderComponent: React.FC<LoaderComponentProps> = ({ relative, children }) => (
  <div className={`${styles.loader} ${relative ? styles.relative : null}`}>
    <div className={styles.holder}>
      <div className={styles.box}></div>
    </div>
    <div className={styles.holder}>
      <div className={styles.box}></div>
    </div>
    <div className={styles.holder}>
      <div className={styles.box}></div>
    </div>
  </div>
);

export default LoaderComponent;
