import React from 'react';
import moment from 'moment';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@rmwc/dialog';
import { CircularProgress } from '@rmwc/circular-progress';
import { Button } from '@rmwc/button';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

import { useLoggersOfflineQuery } from 'generated/graphql';

import styles from './CustomModal.module.scss';

export const OfflineLoggerChartModal = ({ open, setOpen }: any) => {
  const { loading, error, data } = useLoggersOfflineQuery({
    variables: { LatestData: moment().subtract(3, 'months').format('YYYY-MM-DDT00:00:00') },
  });

  const dates = [];
  const series = [];
  const loadSeries = [{ name: 'All', data: [], feedIds: [] }];

  const chartOptions = {
    title: {
      text: 'Logger Count by Latest Data Date',
    },
    chart: {
      height: '600px',
      type: 'column',
      alignTicks: false,
      showAxis: true,
      animation: false,
      style: {
        fontFamily: '"Roboto", Helvetica, sans-serif',
        color: '#616161',
      },
    },
    scrollbar: {
      buttonBackgroundColor: '#fff',
      buttonBorderColor: '#fff',
      buttonArrowColor: '#fff',
      minWidth: 0,
      height: 0,
      liveRedraw: true,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      shared: true,
    },
    legend: {
      enabled: true,
      align: 'center',
      verticalAlign: 'top',
      style: {
        itemStyle: {
          fontWeight: 400,
          color: '#616161',
        },
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      },
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
        },
        cursor: 'pointer',
        point: {
          events: {
            click: (e) => {
              const baseUrl = `${window.location.origin}${window.location.pathname}`.replace('feeds', 'channels');
              const dt = e.point.category;
              const dtStr = moment.utc(dt, 'DD/MM/YYYY').format('DD-MM-YYYY');
              // const dtIdx = dates.findIndex((x) => x === dt);
              // const feedIds = loadSeries[0].feedIds[dtIdx];
              // sessionStorage.setItem('feedIdsFilter', feedIds.join(','));
              window.open(`${baseUrl}?latestDataDate=${dtStr}`);
            },
          },
        },
      },
    },
    xAxis: {
      categories: dates,
    },
    series: series,
  };

  let dataProcessed = false;
  if (!loading && data?.dw_ChannelsMetadata?.length > 0) {
    const channelData = JSON.parse(JSON.stringify(data?.dw_ChannelsMetadata));
    const feedTypeHash = {};
    const loadNameHash = {};
    channelData.forEach((item) => {
      const dt = moment(item.LatestData).utc().format('DD/MM/YYYY');
      if (dates.indexOf(dt) === -1) {
        dates.push(dt);
      }
      const feedType = item.Channel.Load.Feed?.FeedsType?.Label || 'Unknown';
      const feedId = item.Channel.Load.Feed.FeedId;
      const loadName = item.Channel.Load.Label || 'Unknown';

      if (!feedTypeHash[feedType]) {
        feedTypeHash[feedType] = {};
      }
      if (!feedTypeHash[feedType][dt]) {
        feedTypeHash[feedType][dt] = [];
      }
      if (feedTypeHash[feedType][dt].indexOf(feedId) === -1) {
        feedTypeHash[feedType][dt].push(feedId);
      }

      if (!loadNameHash[loadName]) {
        loadNameHash[loadName] = {};
      }
      if (!loadNameHash[loadName][dt]) {
        loadNameHash[loadName][dt] = [];
      }
      if (loadNameHash[loadName][dt].indexOf(feedId) === -1) {
        loadNameHash[loadName][dt].push(feedId);
      }
    });
    dates.reverse();
    loadSeries[0].data = new Array(dates.length).fill(0);
    loadSeries[0].feedIds = new Array(dates.length).fill([]);
    for (let k in feedTypeHash) {
      const newItem = {
        name: k,
        data: new Array(dates.length).fill(null),
        feedIds: new Array(dates.length).fill([]),
      };
      const datesObj = feedTypeHash[k];
      for (let kk in datesObj) {
        const dtIdx = dates.indexOf(kk);
        newItem.data[dtIdx] = datesObj[kk].length;
        newItem.feedIds[dtIdx] = newItem.feedIds[dtIdx].concat(datesObj[kk]);
      }
      series.push(newItem);
    }

    for (let k in loadNameHash) {
      const datesObj = loadNameHash[k];
      for (let kk in datesObj) {
        const dtIdx = dates.indexOf(kk);
        const newIds = datesObj[kk];
        loadSeries[0].data[dtIdx] += newIds.length;
        loadSeries[0].feedIds[dtIdx] = loadSeries[0].feedIds[dtIdx].concat(newIds);
      }
    }
    dataProcessed = true;
  }

  return (
    <div className={`${styles.customModal} ${styles.fullscreen}`}>
      <Dialog
        className={styles.OfflineLoggers}
        open={open}
        onClose={(evt) => {
          if (open) {
            setOpen(false);
          }
        }}>
        <DialogTitle>Offline Loggers</DialogTitle>
        <DialogContent>
          <div className={styles.chartContainer}>
            {loading && (
              <div className={styles.customLoader}>
                <CircularProgress style={{ fontSize: '125px' }} />
              </div>
            )}

            <div className={styles.dualChartContainer}>
              <div className={styles.dualChartContent}>
                {dataProcessed && <HighchartsReact highcharts={Highcharts} options={chartOptions} />}
              </div>
              <div className={styles.dualChartContent}>
                {dataProcessed && (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{ ...chartOptions, title: { text: 'Load Count by Latest Data Date' }, series: loadSeries }}
                  />
                )}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            label="Close"
            raised
            onClick={() => {
              setOpen(false);
            }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OfflineLoggerChartModal;
