import React from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';
import { TextField } from '@rmwc/textfield';
import { Checkbox } from '@rmwc/checkbox';
import { Select } from '@rmwc/select';
import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';

import {
  useAccountSearchLazyQuery,
  useSiteSearchLazyQuery,
  useBuildingSearchLazyQuery,
  useInsertLoadMutation,
  useLoadSearchLazyQuery,
  useLoadsServiceSearchLazyQuery,
  useLoadsTypeSearchLazyQuery,
  useFeedSearchLazyQuery,
  useBulkInsertLoadChannelsMutation,
  useBulkInsertChannelsMetadataMutation,
} from 'generated/graphql';
import { mapChannelOptionsToChannels, queue, StatusEnum, useAppState, UtilityTypeEnum } from 'utilities';
import { Autocomplete } from 'components';

import styles from './CustomModal.module.scss';

export const AddLoadModal = ({ open, setOpen }: any) => {
  const { createFeedsConfigModbusEntries } = useAppState();
  const history = useHistory();
  const {
    formState,
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    setValue,
    getValues,
    watch,
  } = useForm();
  const { errors } = formState;
  const [Account, Site, Building, ParentLoad, LoadsService, LoadsType, Feed, UtilityType] = watch([
    'Account',
    'Site',
    'Building',
    'ParentLoad',
    'LoadsService',
    'LoadsType',
    'Feed',
    'UtilityType',
  ]);
  const [searchAccounts, { data: AccountSearchData, loading: AccountSearchLoading }] = useAccountSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [searchSites, { data: SiteSearchData, loading: SiteSearchLoading }] = useSiteSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [searchBuildings, { data: BuildingSearchData, loading: BuildingSearchLoading }] = useBuildingSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [searchLoads, { data: LoadSearchData, loading: LoadSearchLoading }] = useLoadSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [
    searchLoadsService,
    { data: LoadsServiceSearchData, loading: LoadsServiceSearchLoading },
  ] = useLoadsServiceSearchLazyQuery({ fetchPolicy: 'no-cache' });
  const [
    searchLoadsTypes,
    { data: LoadsTypeSearchData, loading: LoadsTypeSearchLoading },
  ] = useLoadsTypeSearchLazyQuery({ fetchPolicy: 'no-cache' });
  const [searchFeeds, { data: FeedSearchData, loading: FeedSearchLoading }] = useFeedSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [insertFn] = useInsertLoadMutation();

  const [bulkInsertLoadChannelsMutationFn] = useBulkInsertLoadChannelsMutation();
  const [bulkInsertChannelsMetadataFn] = useBulkInsertChannelsMetadataMutation();

  const statusFields = [];
  for (let k in Object.keys(StatusEnum)) {
    if (StatusEnum[k]) {
      statusFields.push({ label: StatusEnum[k], value: Number(k) });
    }
  }
  const utilityTypeFields = [];
  for (let k in Object.keys(UtilityTypeEnum)) {
    if (UtilityTypeEnum[k]) {
      utilityTypeFields.push({ label: UtilityTypeEnum[k], value: Number(k) });
    }
  }

  const channelOptionsList = [
    { Value: 'kWh', Label: 'kWh', Description: 'Energy kWh', Types: [UtilityTypeEnum.Electricity] },
    { Value: 'kVAh', Label: 'kVAh', Description: 'kVAh CONSUMPTION', Types: [UtilityTypeEnum.Electricity] },
    { Value: 'L1A', Label: 'L1A', Description: 'Current L1A', Types: [UtilityTypeEnum.Electricity] },
    { Value: 'L2A', Label: 'L2A', Description: 'Current L2A', Types: [UtilityTypeEnum.Electricity] },
    { Value: 'L3A', Label: 'L3A', Description: 'Current L3A', Types: [UtilityTypeEnum.Electricity] },
    { Value: 'L1V', Label: 'L1V', Description: 'L1V', Types: [UtilityTypeEnum.Electricity] },
    { Value: 'L2V', Label: 'L2V', Description: 'L2V', Types: [UtilityTypeEnum.Electricity] },
    { Value: 'L3V', Label: 'L3V', Description: 'L3V', Types: [UtilityTypeEnum.Electricity] },
    { Value: 'Power Factor', Label: 'Power Factor', Description: 'PF', Types: [UtilityTypeEnum.Electricity] },
    { Value: 'Gas kWh', Label: 'Gas kWh', Description: 'Gas kWh', Types: [UtilityTypeEnum.Gas] },
    { Value: 'GasRaw m3', Label: 'Gas Raw m3', Description: 'GasRaw m3', Types: [UtilityTypeEnum.Gas] },
    { Value: 'Water m3', Label: 'Water m3', Description: 'm3', Types: [UtilityTypeEnum.Water] },
  ].filter((x) => x.Types.indexOf(Number(UtilityType)) > -1);

  const onSubmit = async (data: any) => {
    try {
      const LoadId = uuidv4();
      const variables = {
        object: {
          ...data,
          BuildingId: data.Building.BuildingId,
          ParentLoadId: data.ParentLoad?.LoadId,
          FeedId: data.Feed?.FeedId,
          ServiceId: data.LoadsService?.ServiceId,
          TypeId: data.LoadsType?.TypeId,
          LoadId: LoadId,
          MainIncomer: data.MainIncomer ? true : false,
          CreatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
          UpdatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
        },
      };
      delete variables.object.Site;
      delete variables.object.Account;
      delete variables.object.Building;
      delete variables.object.ParentLoad;
      delete variables.object.LoadsService;
      delete variables.object.LoadsType;
      delete variables.object.Feed;
      delete variables.object.ChannelOptions;

      const { data: resultData, errors } = await insertFn({ variables });
      if (errors?.length > 0) {
        throw new Error(errors[0].message);
      }

      queue.clearAll();
      queue.notify({
        icon: 'done',
        title: 'Success!',
        body: `Load Added! ${data.ChannelOptions?.length > 0 ? 'Adding channels now.' : ''}`,
      });

      if (data.ChannelOptions?.length > 0) {
        const newChannels = mapChannelOptionsToChannels(
          data.ChannelOptions,
          `${data.Building.Label} ${data.Label}`,
          LoadId,
          data.Feed
        );

        const { errors: bulkInsertErrors } = await bulkInsertLoadChannelsMutationFn({
          variables: { objects: newChannels },
        });
        if (bulkInsertErrors?.length > 0) {
          throw new Error(bulkInsertErrors[0].message);
        }
        queue.clearAll();
        queue.notify({
          icon: 'done',
          title: 'Success!',
          body: `Channels Added!`,
        });

        // bulk insert channel metadata
        await bulkInsertChannelsMetadataFn({
          variables: { objects: newChannels.map((x) => ({ ChannelId: x.ChannelId })) },
        });

        const isHubConnect = data?.Feed?.FeedsType?.Label === 'ESP Hub Connect';
        if (isHubConnect) {
          await createFeedsConfigModbusEntries(newChannels);
        }
      }

      setOpen(false);
      setTimeout(() => {
        // window.location.reload();
        history.push('/app/detail/loads/' + resultData.insert_dw_Loads_one.LoadId);
      }, 1000);
    } catch (ex) {
      queue.clearAll();
      queue.notify({
        icon: 'priority_high',
        title: 'Oh oh, error!',
        body: ex.message,
      });
      setError('api', { type: 'manual', message: ex.message });
    }
  };

  let accountDefaultValue = null as any;
  let siteDefaultValue = null as any;
  let buildingDefaultValue = null as any;
  let feedDefaultValue = null as any;
  const lastDetail = JSON.parse(sessionStorage.getItem('lastDetail'));
  if (lastDetail?.data['BuildingId'] && lastDetail?.data['Label']) {
    accountDefaultValue = lastDetail.data.Site.Account;
    siteDefaultValue = lastDetail.data.Site;
    buildingDefaultValue = lastDetail.data;
  } else if (lastDetail?.data['SiteId'] && lastDetail?.data['Label']) {
    accountDefaultValue = lastDetail.data.Account;
    siteDefaultValue = lastDetail.data;
  } else if (lastDetail?.data['AccountId'] && lastDetail?.data['Label']) {
    accountDefaultValue = lastDetail.data;
    // for feeds below
  } else if (lastDetail?.data['Building']) {
    accountDefaultValue = lastDetail.data.Building?.Site.Account;
    siteDefaultValue = lastDetail.data.Building?.Site;
    buildingDefaultValue = lastDetail.data.Building;
  }

  React.useEffect(() => {
    if (buildingDefaultValue) {
      setValue('Building', buildingDefaultValue);
      setValue('Site', buildingDefaultValue.Site);
      setValue('Account', buildingDefaultValue.Site.Account);
      searchLoads({ variables: { BuildingId: buildingDefaultValue.BuildingId, search: `%%` } });
      searchFeeds({ variables: { AccountId: buildingDefaultValue.Site.Account.AccountId, search: `%%` } });
    } else if (siteDefaultValue) {
      setValue('Site', siteDefaultValue);
      setValue('Account', siteDefaultValue.Account);
      searchBuildings({ variables: { SiteId: siteDefaultValue.SiteId, search: `%%` } });
      searchFeeds({ variables: { AccountId: siteDefaultValue.Account.AccountId, search: `%%` } });
    } else if (accountDefaultValue) {
      setValue('Account', accountDefaultValue);
      searchSites({ variables: { AccountId: accountDefaultValue.AccountId, search: `%%` } });
      searchFeeds({ variables: { AccountId: accountDefaultValue.AccountId, search: `%%` } });
    } else {
      searchAccounts({ variables: { search: `%%` } });
      searchSites({ variables: { search: `%%` } });
      searchBuildings({ variables: { search: `%%` } });
      searchFeeds({ variables: { search: `%%` } });
    }
    searchLoadsService({ variables: { search: '%%' } });
    searchLoadsTypes({ variables: { search: '%%' } });
    // eslint-disable-next-line
  }, ['open']);

  return (
    <div className={styles.customModal}>
      <Dialog
        className={styles.addLoad}
        open={open}
        onClose={(evt) => {
          reset();
          clearErrors();
          if (open) {
            setOpen(false);
          }
        }}>
        <form onSubmit={handleSubmit(onSubmit)} data-private autoComplete="off">
          <DialogTitle>Add Load</DialogTitle>
          <DialogContent>
            <div className={styles.row}>
              <Controller
                name="Account"
                control={control}
                rules={{
                  required: 'Please select an account',
                }}
                render={({ field }) => (
                  <Autocomplete
                    label="Account"
                    data={AccountSearchData?.dw_Accounts || []}
                    labelKey="Label"
                    valueKey="AccountId"
                    loading={AccountSearchLoading}
                    getData={searchAccounts}
                    isDisabled={accountDefaultValue}
                    value={Account}
                    callback={(item) => {
                      field.onChange(item?.FullData);
                      if (item?.FullData) {
                        const newQuery = { variables: { AccountId: item.FullData?.AccountId, search: `%%` } };
                        searchSites(newQuery);
                        searchFeeds(newQuery);
                      }
                      setValue('Site', null);
                      setValue('Building', null);
                      setValue('ParentLoad', null);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.Account?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.Account.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <Controller
                name="Site"
                control={control}
                rules={{
                  required: 'Please select a site',
                }}
                render={({ field }) => (
                  <Autocomplete
                    label="Site"
                    data={SiteSearchData?.dw_Sites || []}
                    labelKey="Label"
                    valueKey="SiteId"
                    loading={SiteSearchLoading}
                    value={Site}
                    isDisabled={siteDefaultValue || !Account}
                    getData={(query: any) => {
                      const account = getValues('Account');
                      const newQuery = { ...query };
                      newQuery.variables.AccountId = account?.AccountId;
                      searchSites(newQuery);
                    }}
                    callback={(item) => {
                      field.onChange(item?.FullData);
                      if (item?.FullData) {
                        const newQuery = { variables: { SiteId: item.FullData?.SiteId, search: `%%` } };
                        searchBuildings(newQuery);
                      }
                      setValue('Building', null);
                      setValue('ParentLoad', null);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.Site?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.Site.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <Controller
                name="Building"
                control={control}
                rules={{
                  required: 'Please select a building',
                }}
                render={({ field }) => (
                  <Autocomplete
                    label="Building"
                    data={BuildingSearchData?.dw_Buildings || []}
                    labelKey="Label"
                    valueKey="BuildingId"
                    loading={BuildingSearchLoading}
                    value={Building}
                    isDisabled={buildingDefaultValue || !Site}
                    getData={(query: any) => {
                      const site = getValues('Site');
                      const newQuery = { ...query };
                      newQuery.variables.SiteId = site?.SiteId;
                      searchBuildings(newQuery);
                    }}
                    callback={(item) => {
                      field.onChange(item?.FullData);
                      if (item?.FullData) {
                        const newQuery = { variables: { BuildingId: item.FullData?.BuildingId, search: `%%` } };
                        searchLoads(newQuery);
                      }
                      setValue('ParentLoad', null);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.Building?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.Building.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <Controller
                name="ParentLoad"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    label="Parent Load"
                    data={LoadSearchData?.dw_Loads || []}
                    labelKey="Label"
                    valueKey="LoadId"
                    loading={LoadSearchLoading}
                    value={ParentLoad}
                    isDisabled={!Building}
                    getData={(query: any) => {
                      const building = getValues('Building');
                      const newQuery = { ...query };
                      newQuery.variables.BuildingId = building?.BuildingId;
                      searchLoads(newQuery);
                    }}
                    callback={(item) => {
                      field.onChange(item?.FullData);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.ParentLoad?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.ParentLoad.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <Controller
                name="Feed"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    label="Feed"
                    data={
                      FeedSearchData?.dw_Feeds.map((x) => {
                        return {
                          FeedId: x.FeedId,

                          Label: `${x.Label} - ${x.Building?.Label} ${x.Notes ? `(${x.Notes})` : ''}`,
                          FeedsType: x.FeedsType,
                        };
                      }) || []
                    }
                    labelKey="Label"
                    valueKey="FeedId"
                    loading={FeedSearchLoading}
                    value={Feed}
                    isDisabled={feedDefaultValue || !Account}
                    getData={(query: any) => {
                      const account = getValues('Account');
                      const newQuery = { ...query };
                      newQuery.variables.AccountId = account?.AccountId;
                      searchFeeds(newQuery);
                    }}
                    callback={(item) => {
                      field.onChange(item?.FullData);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.Feed?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.Feed.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <TextField label="Label" name="Label" outlined required {...register('Label')} floatLabel={true} />
              {errors.Label && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a label</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <Controller
                name="UtilityType"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={'1'}
                render={({ field }) => (
                  <Select
                    label="Utility Type"
                    name="UtilityType"
                    outlined
                    required
                    options={utilityTypeFields}
                    defaultValue={'1'}
                    {...field}
                  />
                )}
              />
              {errors.UtilityType && (
                <div className={styles.error}>
                  <Typography use="caption">Please select a valid Utility Type</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <Controller
                name="ChannelOptions"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    label="Channel Options"
                    data={channelOptionsList}
                    labelKey="Label"
                    valueKey="Value"
                    loading={false}
                    getData={(query: any) => channelOptionsList}
                    defaultValue={''}
                    isMulti={true}
                    isDisabled={false}
                    callback={(item) => {
                      field.onChange(item);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.ChannelOptions?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.ChannelOptions.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <Controller
                name="LoadsService"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    label="Loads Service"
                    data={LoadsServiceSearchData?.dw_LoadsService || []}
                    labelKey="Label"
                    valueKey="ServiceId"
                    loading={LoadsServiceSearchLoading}
                    value={LoadsService}
                    getData={searchLoadsService}
                    callback={(item) => {
                      field.onChange(item?.FullData);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.LoadsService?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.LoadsService.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <Controller
                name="LoadsType"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    label="Loads Type"
                    data={LoadsTypeSearchData?.dw_LoadsType || []}
                    labelKey="Label"
                    valueKey="TypeId"
                    loading={LoadsTypeSearchLoading}
                    value={LoadsType}
                    getData={searchLoadsTypes}
                    callback={(item) => {
                      field.onChange(item?.FullData);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.LoadsType?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.LoadsType.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <Controller
                name="Status"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={'1'}
                render={({ field }) => (
                  <Select
                    label="Status"
                    name="Status"
                    outlined
                    required
                    options={statusFields}
                    defaultValue={'1'}
                    {...field}
                  />
                )}
              />
              {errors.Status && (
                <div className={styles.error}>
                  <Typography use="caption">Please select a valid status</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <Controller
                name="MainIncomer"
                control={control}
                render={({ field }) => <Checkbox label="Main Incomer?" {...field} />}
              />
            </div>

            <div className={styles.row}>
              {errors.api && (
                <div className={styles.error} style={{ marginBottom: '16px' }}>
                  <Typography use="caption">{errors.api.message}</Typography>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-between' }}>
            <Button
              type="button"
              label="Close"
              disabled={formState.isSubmitting}
              outlined
              onClick={() => {
                setOpen(false);
                clearErrors();
                reset();
              }}
            />
            <Button
              raised
              disabled={formState.isSubmitting}
              label={formState.isSubmitting ? 'Saving' : 'Save'}
              icon={formState.isSubmitting ? <CircularProgress theme="secondary" /> : null}
              onClick={() => {
                clearErrors();
              }}
              type="submit"
            />
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AddLoadModal;
