import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';
import { Select } from '@rmwc/select';
import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';

import { useAccountSearchLazyQuery, useSiteSearchLazyQuery, useInsertGroupsSiteMutation } from 'generated/graphql';
import { queue, StatusEnum } from 'utilities';
import { Autocomplete } from 'components';

import styles from './CustomModal.module.scss';

export const AddGroupsSiteModal = ({ open, setOpen }: any) => {
  const lastDetail = JSON.parse(sessionStorage.getItem('lastDetail'));
  const { formState, control, handleSubmit, setError, clearErrors, reset, setValue, getValues, watch } = useForm();
  const { errors } = formState;
  const [Account, Site] = watch(['Account', 'Site']);
  const [searchAccounts, { data: AccountSearchData, loading: AccountSearchLoading }] = useAccountSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [searchSites, { data: SiteSearchData, loading: SiteSearchLoading }] = useSiteSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [insertFn] = useInsertGroupsSiteMutation();
  const statusFields = [];
  for (let k in Object.keys(StatusEnum)) {
    if (StatusEnum[k]) {
      statusFields.push({ label: StatusEnum[k], value: Number(k) });
    }
  }

  const onSubmit = async (data: any) => {
    try {
      const variables = {
        object: {
          ...data,
          GroupId: lastDetail.data?.GroupId,
          SiteId: data.Site.SiteId,
          CreatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
          UpdatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
        },
      };
      delete variables.object.Site;
      delete variables.object.Account;
      const { errors } = await insertFn({ variables });
      if (errors?.length > 0) {
        throw new Error(errors[0].message);
      }
      queue.clearAll();
      queue.notify({
        icon: 'done',
        title: 'Success!',
        body: `Group Site Added!`,
      });

      setOpen(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (ex) {
      queue.clearAll();
      queue.notify({
        icon: 'priority_high',
        title: 'Oh oh, error!',
        body: ex.message,
      });
      setError('api', { type: 'manual', message: ex.message });
    }
  };

  let accountDefaultValue = null as any;
  let siteDefaultValue = null as any;
  if (lastDetail?.data['GroupId'] && lastDetail?.data['Label']) {
    accountDefaultValue = lastDetail.data.Account;
  } else if (lastDetail?.data['AccountId'] && lastDetail?.data['Label']) {
    accountDefaultValue = lastDetail.data;
  }

  React.useEffect(() => {
    if (siteDefaultValue) {
      setValue('Site', siteDefaultValue);
      setValue('Account', siteDefaultValue.Account);
    } else if (accountDefaultValue) {
      setValue('Account', accountDefaultValue);
      searchSites({ variables: { AccountId: accountDefaultValue.AccountId, search: `%%` } });
    } else {
      searchAccounts({ variables: { search: `%%` } });
      searchSites({ variables: { search: `%%` } });
    }
    // eslint-disable-next-line
  }, ['open']);

  return (
    <div className={styles.customModal}>
      <Dialog
        className={styles.addBuilding}
        open={open}
        onClose={(evt) => {
          reset();
          clearErrors();
          if (open) {
            setOpen(false);
          }
        }}>
        <form onSubmit={handleSubmit(onSubmit)} data-private autoComplete="off">
          <DialogTitle>Add Group Site</DialogTitle>
          <DialogContent>
            <div className={styles.row}>
              <Controller
                name="Account"
                control={control}
                rules={{
                  required: 'Please select an account',
                }}
                render={({ field }) => (
                  <Autocomplete
                    label="Account"
                    data={AccountSearchData?.dw_Accounts || []}
                    labelKey="Label"
                    valueKey="AccountId"
                    loading={AccountSearchLoading}
                    getData={searchAccounts}
                    isDisabled={accountDefaultValue}
                    value={Account}
                    callback={(item) => {
                      field.onChange(item?.FullData);
                      if (item?.FullData) {
                        const newQuery = { variables: { AccountId: item.FullData?.AccountId, search: `%%` } };
                        searchSites(newQuery);
                      }
                      setValue('Site', null);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.Account?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.Account.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <Controller
                name="Site"
                control={control}
                rules={{
                  required: 'Please select a site',
                }}
                render={({ field }) => (
                  <Autocomplete
                    label="Site"
                    data={SiteSearchData?.dw_Sites || []}
                    labelKey="Label"
                    valueKey="SiteId"
                    loading={SiteSearchLoading}
                    value={Site}
                    isDisabled={siteDefaultValue || !Account}
                    getData={(query: any) => {
                      const account = getValues('Account');
                      const newQuery = { ...query };
                      newQuery.variables.AccountId = account?.AccountId;
                      searchSites(newQuery);
                    }}
                    callback={(item) => {
                      field.onChange(item?.FullData);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.Site?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.Site.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <Controller
                name="Status"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={'1'}
                render={({ field }) => (
                  <Select
                    label="Status"
                    name="Status"
                    outlined
                    required
                    options={statusFields}
                    defaultValue={'1'}
                    {...field}
                  />
                )}
              />
              {errors.Status && (
                <div className={styles.error}>
                  <Typography use="caption">Please select a valid status</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              {errors.api && (
                <div className={styles.error} style={{ marginBottom: '16px' }}>
                  <Typography use="caption">{errors.api.message}</Typography>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-between' }}>
            <Button
              type="button"
              label="Close"
              disabled={formState.isSubmitting}
              outlined
              onClick={() => {
                setOpen(false);
                clearErrors();
                reset();
              }}
            />
            <Button
              raised
              disabled={formState.isSubmitting}
              label={formState.isSubmitting ? 'Saving' : 'Save'}
              icon={formState.isSubmitting ? <CircularProgress theme="secondary" /> : null}
              onClick={() => {
                clearErrors();
              }}
              type="submit"
            />
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AddGroupsSiteModal;
