import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';
import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';

import { Checkbox } from '@rmwc/checkbox';
import { Select } from '@rmwc/select';
import { queue, useAppState } from 'utilities';
import {
  useInsertContactRolesMutation,
  useContactAccountRolesSearchLazyQuery,
  useAccountContactRolesSearchLazyQuery,
} from 'generated/graphql';
import { Autocomplete } from 'components';

import styles from './CustomModal.module.scss';
import { RoleEnum } from '../../utilities/enum';

export const AddContactRoleModal = ({ open, setOpen }: any) => {
  const { upsertContact } = useAppState();
  const { formState, control, handleSubmit, setError, clearErrors, reset, setValue, getValues } = useForm();
  const { errors } = formState;
  const [
    searchAccounts,
    { data: AccountSearchData, loading: AccountSearchLoading },
  ] = useAccountContactRolesSearchLazyQuery({ fetchPolicy: 'no-cache' });
  const [
    searchContacts,
    { data: ContactSearchData, loading: ContactSearchLoading },
  ] = useContactAccountRolesSearchLazyQuery({ fetchPolicy: 'no-cache' });

  const roleFields = [];
  for (let k in Object.keys(RoleEnum)) {
    if (RoleEnum[k]) {
      roleFields.push({ label: RoleEnum[k], value: Number(k) });
    }
  }

  const lastDetail = JSON.parse(sessionStorage.getItem('lastDetail'));
  let accountDefaultValue = null as any;
  let contactDefaultValue = null as any;
  if (lastDetail?.data['AccountId'] && lastDetail?.data['Label']) {
    accountDefaultValue = lastDetail.data;
    setTimeout(() => {
      setValue('Account', {
        label: accountDefaultValue.Label,
        value: accountDefaultValue.AccountId,
        FullData: accountDefaultValue,
      });
    }, 0);
  } else if (lastDetail?.data['ContactId'] && lastDetail?.data['EmailAddress']) {
    contactDefaultValue = lastDetail.data;
    setTimeout(() => {
      setValue('Contact', {
        label: contactDefaultValue.EmailAddress,
        value: contactDefaultValue.ContactId,
        FullData: contactDefaultValue,
      });
    }, 0);
  }

  React.useEffect(() => {
    if (accountDefaultValue) {
      searchContacts({ variables: { search: `%%`, AccountId: accountDefaultValue.AccountId } });
    } else if (contactDefaultValue) {
      searchAccounts({ variables: { search: `%%`, ContactId: contactDefaultValue.ContactId } });
    }
    // eslint-disable-next-line
  }, ['open']);

  const onSubmit = async (data: any) => {
    try {
      let promises = [];
      if (data.Contact.length > 0) {
        promises = data.Contact.map((Contact) => {
          const variables = {
            contactId: Contact.FullData.ContactId,
            emailAddress: Contact.FullData.EmailAddress,
            accountId: data.Account.FullData.AccountId,
            role: Number(data.Role),
            status: 4,
          };
          return upsertContact(variables);
        });
      } else if (data.Account.length > 0) {
        promises = data.Account.map((Account) => {
          const variables = {
            contactId: data.Contact.FullData.ContactId,
            emailAddress: data.Contact.FullData.EmailAddress,
            accountId: Account.FullData.AccountId,
            role: Number(data.Role),
            status: 4,
          };
          return upsertContact(variables);
        });
      }

      const results = await Promise.all(promises);
      if (results.find((x) => x === false)) {
        throw new Error('There was a problem with one of the requests, please reload the page and try again');
      }
      queue.clearAll();
      queue.notify({
        icon: 'done',
        title: 'Success!',
        body: `Account/Contact Roles Added!`,
      });
      setOpen(false);
      setTimeout(() => {
        window.location.reload();
        // history.push('/app/detail/contacts/' + resultData.insert_dw_Contacts_one.ContactId);
      }, 1000);
    } catch (ex) {
      queue.clearAll();
      queue.notify({
        icon: 'priority_high',
        title: 'Oh oh, error!',
        body: ex.message,
      });
      setError('api', { type: 'manual', message: ex.message });
    }
  };

  return (
    <div className={styles.customModal}>
      <Dialog
        className={styles.addContactRole}
        open={open}
        onClose={(evt) => {
          reset();
          clearErrors();
          if (open) {
            setOpen(false);
          }
        }}>
        <form onSubmit={handleSubmit(onSubmit)} data-private autoComplete="off">
          <DialogTitle>Add Account/Contact Role</DialogTitle>
          <DialogContent>
            <div className={styles.row}>
              <Controller
                name="Account"
                control={control}
                rules={{
                  required: 'Please select an account',
                }}
                render={({ field }) => (
                  <Autocomplete
                    label="Account"
                    data={AccountSearchData?.dw_Accounts || []}
                    labelKey="Label"
                    valueKey="AccountId"
                    loading={AccountSearchLoading}
                    getData={(query: any) => {
                      const contact = getValues('Contact');
                      const newQuery = { ...query };
                      newQuery.variables.ContactId = contact?.value;
                      searchAccounts(newQuery);
                    }}
                    defaultValue={accountDefaultValue}
                    isMulti={accountDefaultValue ? false : true}
                    callback={(item) => {
                      field.onChange(item);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.AccountId?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.AccountId.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <Controller
                name="Contact"
                control={control}
                rules={{
                  required: 'Please select an contact',
                }}
                render={({ field }) => (
                  <Autocomplete
                    label="Contact"
                    data={ContactSearchData?.dw_Contacts || []}
                    labelKey="EmailAddress"
                    valueKey="ContactId"
                    loading={ContactSearchLoading}
                    getData={(query: any) => {
                      const account = getValues('Account');
                      const newQuery = { ...query };
                      newQuery.variables.AccountId = account?.value;
                      searchContacts(newQuery);
                    }}
                    defaultValue={contactDefaultValue}
                    isMulti={contactDefaultValue ? false : true}
                    callback={(item) => {
                      field.onChange(item);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.ContactId?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.ContactId.message}</Typography>
                </div>
              ) : null}
            </div>

            {/* <div className={styles.row}>
              <Controller
                name="isAdmin"
                control={control}
                render={({ field }) => <Checkbox label="isAdmin" {...field} />}
              />
            </div> */}
            <div className={styles.row}>
              <Controller
                name="Role"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={'1'}
                render={({ field }) => (
                  <Select
                    label="Role"
                    name="Role"
                    outlined
                    required
                    options={roleFields}
                    defaultValue={'1'}
                    {...field}
                  />
                )}
              />
            </div>
            <div className={styles.row}>
              {errors.api && (
                <div className={styles.error}>
                  <Typography use="caption">{errors.api.message}</Typography>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-between' }}>
            <Button
              type="button"
              label="Close"
              disabled={formState.isSubmitting}
              outlined
              onClick={() => {
                setOpen(false);
                clearErrors();
                reset();
              }}
            />
            <Button
              raised
              disabled={formState.isSubmitting}
              label={formState.isSubmitting ? 'Saving' : 'Save'}
              icon={formState.isSubmitting ? <CircularProgress theme="secondary" /> : null}
              onClick={() => {
                clearErrors();
              }}
              type="submit"
            />
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AddContactRoleModal;
