import React from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';
import { TextField } from '@rmwc/textfield';
import { Select } from '@rmwc/select';
import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';

import { useInsertAccountMutation, useAccountSearchLazyQuery } from 'generated/graphql';
import { queue, StatusEnum } from 'utilities';
import { Autocomplete } from 'components';

import styles from './CustomModal.module.scss';

export const AddAccountModal = ({ open, setOpen }: any) => {
  const history = useHistory();
  const { formState, control, register, handleSubmit, setError, clearErrors, reset } = useForm();
  const { errors } = formState;
  const [searchAccounts, { data: AccountSearchData, loading: AccountSearchLoading }] = useAccountSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [insertFn] = useInsertAccountMutation();
  const statusFields = [];
  for (let k in Object.keys(StatusEnum)) {
    if (StatusEnum[k]) {
      statusFields.push({ label: StatusEnum[k], value: Number(k) });
    }
  }

  const onSubmit = async (data: any) => {
    try {
      const emps = Number(data.NumEmployees) || 0;
      const variables = {
        object: {
          ...data,
          AccountId: uuidv4(),
          NumEmployees: isNaN(emps) ? null : emps,
          CreatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
          UpdatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
        },
      };
      const { data: resultData, errors } = await insertFn({ variables });
      if (errors?.length > 0) {
        throw new Error(errors[0].message);
      }
      queue.clearAll();
      queue.notify({
        icon: 'done',
        title: 'Success!',
        body: `Account Added!`,
      });

      setOpen(false);
      setTimeout(() => {
        // window.location.reload();
        history.push('/app/detail/accounts/' + resultData.insert_dw_Accounts_one.AccountId);
      }, 1000);
    } catch (ex) {
      queue.clearAll();
      queue.notify({
        icon: 'priority_high',
        title: 'Oh oh, error!',
        body: ex.message,
      });
      setError('api', { type: 'manual', message: ex.message });
    }
  };

  return (
    <div className={styles.customModal}>
      <Dialog
        className={styles.addAccount}
        open={open}
        onClose={(evt) => {
          reset();
          clearErrors();
          if (open) {
            setOpen(false);
          }
        }}>
        <form onSubmit={handleSubmit(onSubmit)} data-private autoComplete="off">
          <DialogTitle>Add Account</DialogTitle>
          <DialogContent>
            <div className={styles.row}>
              <TextField label="Label" name="Label" outlined required floatLabel={true} {...register('Label')} />
              {errors.Label && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a label</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <Controller
                name="ParentAccountId"
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field }) => (
                  <Autocomplete
                    label="Parent Account"
                    data={AccountSearchData?.dw_Accounts || []}
                    labelKey="Label"
                    valueKey="AccountId"
                    loading={AccountSearchLoading}
                    getData={searchAccounts}
                    callback={(item) => {
                      field.onChange(item.value);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.ParentAccountId?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.ParentAccountId.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <TextField
                label="Billing Email Address"
                name="BillingEmailAddress"
                outlined
                floatLabel={true}
                type="email"
                {...register('BillingEmailAddress')}
              />
              {errors.BillingEmailAddress && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a valid billing email address</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <TextField
                label="Industry Code"
                name="IndustryCode"
                outlined
                floatLabel={true}
                {...register('IndustryCode')}
              />
              {errors.IndustryCode && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a industry code</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <TextField
                label="WebsiteUrl"
                name="WebsiteUrl"
                outlined
                floatLabel={true}
                type="url"
                {...register('WebsiteUrl')}
              />
              {errors.WebsiteUrl && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a website url</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <TextField
                label="Number of Employees"
                name="NumEmployees"
                outlined
                floatLabel={true}
                type="number"
                {...register('NumEmployees')}
              />
              {errors.NumEmployees && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a number</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <Controller
                name="Status"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={'1'}
                render={({ field }) => (
                  <Select
                    label="Status"
                    name="Status"
                    outlined
                    required
                    options={statusFields}
                    defaultValue={'1'}
                    {...field}
                  />
                )}
              />
              {errors.Status && (
                <div className={styles.error}>
                  <Typography use="caption">Please select a valid status</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <TextField
                textarea
                rows={3}
                characterCount
                label="Notes"
                name="Notes"
                outlined
                floatLabel={true}
                {...register('Notes')}
              />
              {errors.Notes && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a some notes</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              {errors.api && (
                <div className={styles.error} style={{ marginBottom: '16px' }}>
                  <Typography use="caption">{errors.api.message}</Typography>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-between' }}>
            <Button
              type="button"
              label="Close"
              disabled={formState.isSubmitting}
              outlined
              onClick={() => {
                setOpen(false);
                clearErrors();
                reset();
              }}
            />
            <Button
              raised
              disabled={formState.isSubmitting}
              label={formState.isSubmitting ? 'Saving' : 'Save'}
              icon={formState.isSubmitting ? <CircularProgress theme="secondary" /> : null}
              onClick={() => {
                clearErrors();
              }}
              type="submit"
            />
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AddAccountModal;
