import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';
import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';
import { IconButton } from '@rmwc/icon-button';
import XLSX from 'xlsx';
import { FileDrop } from 'react-file-drop';
import Autocomplete from 'components/Autocomplete/Autocomplete';

import {
  AccountDataForCsvImportQuery,
  useAccountDataForCsvImportLazyQuery,
  useAccountSearchLazyQuery,
  useFeedSearchAllLazyQuery,
} from 'generated/graphql';
import { handleCsvImport, CsvResult, MappedCsvRow, numberWithCommas } from 'utilities';

import styles from './CustomModal.module.scss';

const formatFileSize = (size: number) => {
  var i = Math.floor(Math.log(size) / Math.log(1024));
  // @ts-ignore
  return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};

const exportCsv = (data: any[], filename: string) => {
  const output = [] as string[];
  const headers = Object.keys(data[0]).join(',');
  output.push(headers);
  data.forEach((item) => {
    var newObj = Object.values(item).join(',');
    output.push(newObj);
  });
  const link = document.createElement('a');
  link.setAttribute('href', `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURIComponent(output.join('\n'))}`);
  link.setAttribute('download', `${filename}.csv`);
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

let intervalTimer = null as any;

export const ImportCsvModal = ({ open, setOpen }: any) => {
  const fileInputRef = React.useRef(null);

  const [searchAccounts, { data: AccountSearchData, loading: AccountSearchLoading }] = useAccountSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [
    searchAccountData,
    { data: AccountDataSearchData, loading: AccountDataSearchLoading },
  ] = useAccountDataForCsvImportLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [searchFeeds, { data: FeedSearchData, loading: FeedSearchLoading }] = useFeedSearchAllLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [account, setAccount] = React.useState(null);
  const [feed, setFeed] = React.useState(null);
  const [step, setStep] = React.useState(0);
  const [processing, setProcessing] = React.useState(false);
  const [fileName, setFilename] = React.useState('');
  const [csvData, setCsvData] = React.useState([] as MappedCsvRow[]);
  const [analyticsResult, setAnalyticsResult] = React.useState(null as CsvResult);
  const [amountProcessed, setAmountProcessed] = React.useState(0);
  const [totalToProcess, setTotalToProcess] = React.useState(0);
  const [error, setError] = React.useState('');

  const stepLabels = {
    0: 'Setup',
    1: 'Validate Results',
    2: 'Import Csv',
    3: 'Results',
  };

  const onTargetClick = () => {
    fileInputRef.current.click();
  };

  const onFileInputChange = (files: any) => {
    if (files.length > 1 && files[0] && files[0].name) {
      setFilename('');
      setCsvData([]);
      alert('Only 1 file allowed, please try again');
      return;
    }
    const file = files[0];
    if (file.name.indexOf('.csv') === -1 && file.name.indexOf('.xls') === -1) {
      setFilename('');
      setCsvData([]);
      alert('Please upload a csv or xls or xlsx file only');
      return;
    }
    var reader = new FileReader();
    reader.onload = function (e: any) {
      var data = new Uint8Array(e.target.result);
      var workbook = XLSX.read(data, { type: 'array', cellDates: true });
      const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
      const json = XLSX.utils.sheet_to_json(firstSheet);
      const mappedCsvResult = json.map((row: any) => {
        const obj = {};
        for (var k in row) {
          obj[k.replace(/ /g, '')] = row[k];
        }
        return obj as MappedCsvRow;
      });
      const fileSize = formatFileSize(file.size);
      setFilename(`${file.name} (${fileSize})`);
      setCsvData(mappedCsvResult);
    };
    reader.readAsArrayBuffer(file);
  };

  const processCsv = async (
    existingData: AccountDataForCsvImportQuery,
    rows: MappedCsvRow[],
    accountId: string,
    feedId: string,
    trialRun: boolean
  ) => {
    clearInterval(intervalTimer);
    setProcessing(true);

    if (!trialRun) {
      intervalTimer = setInterval(() => {
        const csvResult = JSON.parse(sessionStorage.getItem('csvResult'));
        let amountProcessed = 0;
        for (var k in csvResult) {
          amountProcessed += csvResult[k].complete.length;
          amountProcessed += csvResult[k].error.length;
        }
        setAmountProcessed(amountProcessed);
        setAnalyticsResult(csvResult);
      }, 50);
    }
    const result = await handleCsvImport(existingData, rows, accountId, feedId, trialRun);
    setTimeout(() => {
      if (trialRun) {
        setAnalyticsResult(result);
        let total = 0;
        for (var k in result) {
          total += result[k].new.length;
        }
        setTotalToProcess(total);
      } else {
        const csvResult = JSON.parse(sessionStorage.getItem('csvResult'));
        let amountProcessed = 0;
        for (var k in csvResult) {
          amountProcessed += csvResult[k].complete.length;
          amountProcessed += csvResult[k].error.length;
        }
        setAmountProcessed(amountProcessed);
        setAnalyticsResult(csvResult);
        setStep(step + 1);
      }
      setProcessing(false);
      clearInterval(intervalTimer);
    }, 0);
  };

  React.useEffect(() => {
    if (step === 1) {
      processCsv(AccountDataSearchData, csvData, account?.AccountId, feed?.FeedId, true);
    } else if (step === 2) {
      processCsv(AccountDataSearchData, csvData, account?.AccountId, feed?.FeedId, false);
    }
  }, [step]);

  React.useEffect(() => {
    searchAccounts({ variables: { search: '%%' } });
    searchFeeds({ variables: { search: '%%' } });
  }, []);
  return (
    <div className={styles.customModal}>
      <Dialog
        className={styles.ImportCsvModal}
        open={open}
        onClose={(evt) => {
          if (open) {
            setOpen(false);
          }
        }}>
        <DialogTitle style={{ textAlign: 'center' }}>
          Import Csv (
          <a href="/ESP%20test%20import.csv">
            <small>Export CSV Template</small>
          </a>
          )<br />
          Step {step}: {stepLabels[step]}
          <br />
        </DialogTitle>
        <DialogContent>
          {step === 0 && (
            <>
              <div className={styles.row} style={{ marginTop: '16px' }}>
                <Autocomplete
                  label="Account"
                  data={AccountSearchData?.dw_Accounts || []}
                  labelKey="Label"
                  valueKey="AccountId"
                  loading={AccountSearchLoading}
                  getData={searchAccounts}
                  callback={(item) => {
                    setAccount(item ? item.FullData : null);
                    if (item) {
                      searchAccountData({ variables: { accountId: item.value } });
                    }
                  }}
                />
              </div>
              <div className={styles.row}>
                <Autocomplete
                  label="Feed (search all feeds across all accounts)"
                  data={
                    FeedSearchData?.dw_Feeds.map((x) => {
                      return {
                        FeedId: x.FeedId,
                        Label: `${x.Label} - ${x.Building?.Label} ${x.Notes ? `(${x.Notes})` : ''}`,
                        FeedsType: x.FeedsType,
                      };
                    }) || []
                  }
                  labelKey="Label"
                  valueKey="FeedId"
                  loading={FeedSearchLoading}
                  value={feed}
                  getData={(query: any) => {
                    const newQuery = { ...query };
                    // newQuery.variables.AccountId = account?.AccountId;
                    searchFeeds(newQuery);
                  }}
                  callback={(item) => {
                    setFeed(item ? item.FullData : null);
                  }}
                />
              </div>
            </>
          )}

          <div className={styles.row}>
            {step === 0 && (
              <div className={styles.csvReader}>
                <FileDrop
                  onDrop={(files) => {
                    onFileInputChange(files);
                  }}
                  onTargetClick={onTargetClick}>
                  {fileName ? <p>{fileName}</p> : <p>Drop a csv or xlsx file here</p>}
                  {fileName ? (
                    <p>
                      <b>Click next to continue</b>
                    </p>
                  ) : (
                    ''
                  )}
                </FileDrop>
                <input
                  onChange={(e) => {
                    onFileInputChange(e.target.files);
                    return e;
                  }}
                  ref={fileInputRef}
                  type="file"
                  className="hidden"
                />
              </div>
            )}

            {step === 1 && analyticsResult && (
              <div className={styles.resultTable}>
                <table>
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>New</th>
                      <th>Exists</th>
                      <th>Duplicate</th>
                    </tr>
                  </thead>
                  <tbody>
                    {['Site', 'Building', 'Load', 'Channel'].map((x) => {
                      const accountLbl = `${AccountDataSearchData?.dw_Accounts_by_pk?.Label}`;
                      return renderTableRow(analyticsResult, accountLbl, x, [x, 'new', 'existing', 'duplicate']);
                    })}
                  </tbody>
                </table>

                <p style={{ textAlign: 'center' }}>
                  Please note: only new entries ({numberWithCommas(totalToProcess)}) will be processed
                </p>
              </div>
            )}

            {step > 1 && analyticsResult && (
              <div className={styles.resultTable}>
                <table>
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Complete</th>
                      <th>Error</th>
                    </tr>
                  </thead>
                  <tbody>
                    {['Site', 'Building', 'Load', 'Channel'].map((x) => {
                      const accountLbl = `${AccountDataSearchData?.dw_Accounts_by_pk?.Label}`;
                      return renderTableRow(analyticsResult, accountLbl, x, [x, 'complete', 'error']);
                    })}
                  </tbody>
                </table>

                <div style={{ textAlign: 'center', margin: '32px 0px' }}>
                  {processing ? (
                    <Typography use="headline5">
                      Processed {numberWithCommas(amountProcessed)} / {numberWithCommas(totalToProcess)}
                    </Typography>
                  ) : (
                    <Typography use="headline5">
                      All done! ({numberWithCommas(amountProcessed)} / {numberWithCommas(totalToProcess)})
                    </Typography>
                  )}
                </div>
              </div>
            )}
          </div>

          {error && (
            <div className={styles.csvFormError} style={{ marginTop: '16px' }}>
              {error}
            </div>
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <Button
            type="button"
            label={step === 0 ? 'Close' : 'Back'}
            outlined
            onClick={() => {
              if (step === 0) {
                setOpen(false);
              } else {
                setStep(step - 1);
              }
            }}
            style={{
              opacity: step > 1 ? 0 : 1,
            }}
          />

          <Button
            raised
            disabled={AccountDataSearchLoading || processing}
            label={step === 3 ? 'Close' : 'Next'}
            icon={AccountDataSearchLoading || processing ? <CircularProgress theme="secondary" /> : null}
            onClick={() => {
              if (!account) {
                setError('Please select an account before clicking next');
              } else if (csvData.length === 0) {
                setError('Please upload a CSV before clicking next');
              } else if (step === 3) {
                setOpen(false);
              } else {
                setStep(step + 1);
              }
            }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

const renderTableRow = (analyticsResult: any, accountLbl: string, label: string, keys: string[]) => {
  return (
    <tr key={label}>
      {keys.map((x, i) => {
        if (i === 0) {
          return <td key={i}>{label}</td>;
        } else {
          return (
            <td key={i}>
              <div>
                <span>{numberWithCommas(analyticsResult[label][x].length)}</span>{' '}
                {analyticsResult[label][x].length > 0 && (
                  <IconButton
                    icon="download"
                    label="Export"
                    onClick={() => {
                      exportCsv(analyticsResult[label][x], `${accountLbl} ${label} ${x} export`);
                    }}
                  />
                )}
              </div>
            </td>
          );
        }
      })}
    </tr>
  );
};
export default ImportCsvModal;
