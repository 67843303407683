import apolloClient from 'apollo';
import moment from 'moment';
import {
  AccountLoadDataForCsvImportQuery,
  UpdateLoadBulkItemDocument,
  UpdateLoadBulkItemMutationVariables,
} from 'generated/graphql';
import { CsvResult, MappedLoadCsvRow } from './interfaces';

export const handleLoadCsvImport = async (
  existingData: AccountLoadDataForCsvImportQuery,
  rows: MappedLoadCsvRow[],
  accountId?: string,
  trialRun?: boolean
) => {
  const csvResult = {
    Load: { complete: [], error: [] },
  } as CsvResult;
  sessionStorage.setItem('csvResult', JSON.stringify(csvResult));
  const clonedExistingData = JSON.parse(JSON.stringify(existingData));
  // @ts
  await processRow(rows, 0, clonedExistingData, csvResult, accountId, trialRun);
  return csvResult;
};

const processRow = async (
  rows: MappedLoadCsvRow[],
  index: number,
  existingData: AccountLoadDataForCsvImportQuery,
  csvResult: CsvResult,
  accountId?: string,
  trialRun?: boolean
) => {
  const row = rows[index];
  if (!row) {
    return;
  }
  // row.AccountId = accountId;
  if (row.LoadId) {
    await processItem(row, existingData, csvResult, trialRun);
    sessionStorage.setItem('csvResult', JSON.stringify(csvResult));
  }
  // start next row
  await processRow(rows, index + 1, existingData, csvResult, accountId, trialRun);
};

const processItem = async (
  row: MappedLoadCsvRow,
  existingData: AccountLoadDataForCsvImportQuery,
  csvResult: CsvResult,
  trialRun?: boolean
) => {
  let matchFn = (x: any) => {
    return x['LoadId'] === row['LoadId'];
  };
  const existsInDb = existingData[`dw_Loads`].find(matchFn);
  const payload = mapCsvToDbObj(row);
  // @ts-ignore
  if (payload.clientValidationError) {
    // @ts-ignore
    csvResult['Load'].error.push({ ...payload, Error: payload.clientValidationError });
  } else {
    if (!existsInDb) {
      // @ts-ignore
      csvResult['Load'].error.push({ ...payload, Error: "Couldn't match load id" });
    } else {
      if (!trialRun) {
        // @ts-ignore
        delete payload.clientValidationError;

        try {
          const result = await apolloClient.mutate({
            mutation: UpdateLoadBulkItemDocument,
            variables: payload,
            update: () => {
              return payload.LoadId;
            },
          });
          if (result?.data?.update_dw_Loads_by_pk?.LoadId) {
            csvResult['Load'].complete.push(payload);
          } else {
            // @ts-ignore
            csvResult['Load'].error.push({ ...payload, Error: result.message });
          }
        } catch (ex) {
          console.log('here', ex);
          csvResult['Load'].error.push({ ...payload, Error: ex?.message || ex.toString() });
        }
      } else {
        csvResult['Load'].complete.push(payload);
      }
    }
  }
};

const mapCsvToDbObj = (row: MappedLoadCsvRow) => {
  return {
    LoadId: row.LoadId,
    BuildingId: row.BuildingId,
    ParentLoadId: row.ParentLoadId?.toString() || null,
    Label: row.Label || '',
    IcpNumber: row.IcpNumber?.toString() || '',
    Description: row.Description || '',
    NabersRating: row.NabersRating,
    TransformerCapacity: isNaN(Number(row.TransformerCapacity)) ? 0 : Number(row.TransformerCapacity),
    MainIncomer: row.MainIncomer?.toString() === 'true' ? true : false,
    UtilityType: isNaN(Number(row.UtilityType)) ? 0 : Number(row.UtilityType), // utilityObjRev[row.UtilityType.toLowerCase()] || 0,
    Status: isNaN(Number(row.Status)) ? 0 : Number(row.Status), //StatusEnum[row.Status] || 1,
    ServiceId: row.ServiceId || null,
    TypeId: row.TypeId || null,
    FeedId: row.FeedId || null,
    UpdatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
  } as UpdateLoadBulkItemMutationVariables;
};
