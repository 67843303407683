import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';
import { TextField } from '@rmwc/textfield';
import { Select } from '@rmwc/select';
import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';

import { useInsertReportDetailsMutation, useAccountSearchLazyQuery } from 'generated/graphql';

import { BaselineModeEnum, NotificationFrequencyEnum, ReportTypeEnum, queue } from 'utilities';
import { Autocomplete } from 'components';

import styles from './CustomModal.module.scss';

export const AddReportModal = ({ open, setOpen }: any) => {
  const { formState, control, register, handleSubmit, setError, clearErrors, reset } = useForm();
  const { errors } = formState;

  const [searchAccounts, { data: AccountSearchData, loading: AccountSearchLoading }] = useAccountSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [insertFn] = useInsertReportDetailsMutation();

  const reportTypeFields = [];
  for (let k in Object.keys(ReportTypeEnum)) {
    if (ReportTypeEnum[k]) {
      reportTypeFields.push({ label: ReportTypeEnum[k], value: Number(k) });
    }
  }
  const baselineModeFields = [];
  for (let k in Object.keys(BaselineModeEnum)) {
    if (BaselineModeEnum[k]) {
      baselineModeFields.push({ label: BaselineModeEnum[k], value: Number(k) });
    }
  }
  const notificationFrequencyFields = [];
  for (let k in Object.keys(NotificationFrequencyEnum)) {
    if (NotificationFrequencyEnum[k]) {
      notificationFrequencyFields.push({ label: NotificationFrequencyEnum[k], value: Number(k) });
    }
  }

  const onSubmit = async (data: any) => {
    const selectedAccount = AccountSearchData?.dw_Accounts?.find((x) => x.AccountId === data.AccountId);
    if (selectedAccount?.Reports?.find((x) => x.Type === Number(data.type))) {
      queue.clearAll();
      queue.notify({
        icon: 'priority_high',
        title: 'Oh oh, error!',
        body: 'This account already has a report of this type',
      });
      setError('api', { type: 'manual', message: 'This account already has a report of this type' });
      return;
    }
    try {
      const elec = Number(data.targetElectricity || 95);
      const gas = Number(data.targetGas || 95);
      const water = Number(data.targetWater || 95);
      if (isNaN(elec) || !elec || isNaN(gas) || !gas || isNaN(water) || !water) {
        const error = {
          icon: 'priority_high',
          title: 'Oh oh, error!',
          body:
            'You have entered in an invalid number(s) for one of the targets. Please ensure it is just a number with no special characters.',
        };
        queue.clearAll();
        queue.notify(error);
        setError('api', { type: 'manual', message: error.body });
        return;
      }
      const variables = {
        accountId: selectedAccount.AccountId,
        reportId: uuidv4(),
        baselineMode: Number(data.baselineMode),
        targetElectricity: elec.toFixed(2),
        targetGas: gas.toFixed(2),
        targetWater: water.toFixed(2),
        type: Number(data.type),
        notificationFrequency: Number(data.notificationFrequency),
        startDate: moment(data.startDate, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:00:00'),
        createdOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
        updatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
      };
      const { errors } = await insertFn({ variables });
      if (errors?.length > 0) {
        throw new Error(errors[0].message);
      }
      queue.clearAll();
      queue.notify({
        icon: 'done',
        title: 'Success!',
        body: `Report Added! Reloading page.`,
      });
      setOpen(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (ex) {
      queue.clearAll();
      queue.notify({
        icon: 'priority_high',
        title: 'Oh oh, error!',
        body: ex.message,
      });
      setError('api', { type: 'manual', message: ex.message });
    }
  };

  return (
    <div className={styles.customModal}>
      <Dialog
        className={styles.addReport}
        open={open}
        onClose={(evt) => {
          reset();
          clearErrors();
          if (open) {
            setOpen(false);
          }
        }}>
        <form onSubmit={handleSubmit(onSubmit)} data-private autoComplete="off">
          <DialogTitle>Add Report</DialogTitle>
          <DialogContent>
            <p>
              <small>Please note: Save the target as the actual % of the baseline (e.g. 95).</small>
            </p>
            <div className={styles.row}>
              <Controller
                name="AccountId"
                control={control}
                rules={{
                  required: 'Please select an account',
                }}
                render={({ field }) => (
                  <Autocomplete
                    label="Account"
                    data={AccountSearchData?.dw_Accounts || []}
                    labelKey="Label"
                    valueKey="AccountId"
                    loading={AccountSearchLoading}
                    getData={searchAccounts}
                    callback={(item) => {
                      field.onChange(item?.value);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.AccountId?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.AccountId.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <TextField
                label="Target Electricity"
                name="targetElectricity"
                type="number"
                outlined
                required
                autoFocus
                defaultValue={95}
                step="any"
                floatLabel={true}
                {...register('targetElectricity')}
              />
              {errors.targetElectricity && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter in a valid display name</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <TextField
                label="Target Gas"
                name="targetGas"
                type="number"
                outlined
                required
                autoFocus
                defaultValue={95}
                step="any"
                floatLabel={true}
                {...register('targetGas')}
              />
              {errors.targetGas && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter in a valid display name</Typography>
                </div>
              )}
            </div>
            <div className={styles.row}>
              <TextField
                label="Target Water"
                name="targetWater"
                type="number"
                outlined
                required
                autoFocus
                defaultValue={95}
                step="any"
                floatLabel={true}
                {...register('targetWater')}
              />
              {errors.targetWater && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter in a valid display name</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <Controller
                name="type"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={'1'}
                render={({ field }) => (
                  <Select
                    label="Report Type"
                    name="type"
                    outlined
                    required
                    options={reportTypeFields}
                    defaultValue={'1'}
                    {...field}
                  />
                )}
              />
              {errors.type && (
                <div className={styles.error}>
                  <Typography use="caption">Please select a valid type</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <Controller
                name="baselineMode"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={'1'}
                render={({ field }) => (
                  <Select
                    label="Baseline Mode"
                    name="baselineMode"
                    outlined
                    required
                    options={baselineModeFields}
                    defaultValue={'1'}
                    {...field}
                  />
                )}
              />
              {errors.baselineMode && (
                <div className={styles.error}>
                  <Typography use="caption">Please select a valid baseline mode</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <Controller
                name="startDate"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={moment().startOf('year').format('YYYY-MM-DD')}
                render={({ field }) => (
                  <TextField
                    label="Start Date"
                    name="startDate"
                    type="date"
                    outlined
                    required
                    autoFocus
                    floatLabel={true}
                    {...field}
                  />
                )}
              />
              {errors.startDate && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a valid date DD/MM/YYYY</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <Controller
                name="notificationFrequency"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={'1'}
                render={({ field }) => (
                  <Select
                    label="Notification Frequency"
                    name="type"
                    outlined
                    required
                    options={notificationFrequencyFields}
                    defaultValue={'1'}
                    {...field}
                  />
                )}
              />
              {errors.notificationFrequency && (
                <div className={styles.error}>
                  <Typography use="caption">Please select a valid notification frequency</Typography>
                </div>
              )}
            </div>
            <div className={styles.row}>
              {errors.api && (
                <div className={styles.error} style={{ marginBottom: '16px' }}>
                  <Typography use="caption">{errors.api.message}</Typography>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-between' }}>
            <Button
              type="button"
              label="Close"
              disabled={formState.isSubmitting}
              outlined
              onClick={() => {
                setOpen(false);
                clearErrors();
                reset();
              }}
            />
            <Button
              raised
              disabled={formState.isSubmitting}
              label={formState.isSubmitting ? 'Saving' : 'Save'}
              icon={formState.isSubmitting ? <CircularProgress theme="secondary" /> : null}
              onClick={() => {
                clearErrors();
              }}
              type="submit"
            />
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AddReportModal;
