import React from 'react';
import { RMWCProvider } from '@rmwc/provider';

import '@rmwc/theme/styles';
import '@rmwc/top-app-bar/styles';
import '@rmwc/drawer/styles';
import '@rmwc/list/styles';
import '@rmwc/icon/styles';
// import '@rmwc/icon-button/styles';
import '@rmwc/typography/styles';
import '@rmwc/button/styles';
import '@rmwc/textfield/styles';
import '@rmwc/grid/styles';
import '@rmwc/menu/styles';
import '@rmwc/circular-progress/styles';
import '@rmwc/tabs/styles';
import '@rmwc/elevation/styles';
import '@rmwc/dialog/styles';
import '@rmwc/radio/styles';
import '@rmwc/checkbox/styles';
import '@rmwc/select/styles';
import '@rmwc/snackbar/styles';
import '@rmwc/fab/styles';
// 'primary' | 'secondary' | 'background' | 'surface' | 'error' | 'primaryBg' | 'secondaryBg' | 'onPrimary' | 'onSecondary' | 'onSurface' | 'onError' | 'textPrimaryOnBackground' | 'textSecondaryOnBackground' | 'textHintOnBackground' | 'textDisabledOnBackground' | 'textIconOnBackground' | 'textPrimaryOnLight' | 'textSecondaryOnLight' | 'textHintOnLight' | 'textDisabledOnLight' | 'textIconOnLight' | 'textPrimaryOnDark' | 'textSecondaryOnDark' | 'textHintOnDark' | 'textDisabledOnDark' | 'textIconOnDark'

export const RMWC: React.FC = ({ children }) => <RMWCProvider>{children}</RMWCProvider>;

export default RMWC;
