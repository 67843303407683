import React, { Suspense } from 'react';
import { Route, RouteProps, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import RWMC from 'RMWC';
import AppProvider from 'utilities/state';
import { queue, storeTokenData } from 'utilities';
import { GuestGuard, AuthGuard } from 'components';
import { CoreLayout, AuthenticatedLayout } from 'layouts';
import { ApolloProvider } from '@apollo/client';
import { SnackbarQueue } from '@rmwc/snackbar';
import apolloClient from './apollo';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';

import 'nprogress/nprogress.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/index.scss';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    }
  }
});

function App() {
  // ReactQueryDevtools();
  let scaleViewport = false;
  let scale = '0.45';
  if (window.innerWidth < 768) {
    scaleViewport = true;
    scale = (window.innerWidth / 768 - 0.01).toFixed(2);
  }
  const viewport = scaleViewport
    ? `width=768,initial-scale=${scale},minimum-scale=${scale},maximum-scale=${scale}`
    : 'width=device-width, initial-scale=1';

  return (
    <ApolloProvider client={apolloClient}>
      <QueryClientProvider client={queryClient}>
        <RWMC>
          <Helmet>
            <meta name="viewport" content={viewport} />
          </Helmet>
          <BrowserRouter>
            <AppProvider>
              <Switch>
                <NormalRoute path="/404" component={lazyLoad('./views/NotFound/NotFound')} />
                <NormalRoute exact path="/" component={lazyLoad('./views/Login/Login')} />
                <NormalRoute path="/login" component={lazyLoad('./views/Login/Login')} />
                <NormalRoute
                  path="/reset-password/:code/:email"
                  component={lazyLoad('./views/ResetPassword/ResetPassword')}
                />
                <AuthenticatedRoute path="/app" exact component={lazyLoad('./views/Home/Home')} />
                <AuthenticatedRoute path="/app/list/:type" exact component={lazyLoad('./views/List/List')} />
                <AuthenticatedRoute path="/app/detail/:type/:id" exact component={lazyLoad('./views/Detail/Detail')} />
                <AuthenticatedRoute
                  path="/app/detail/:type/:id/:tab"
                  exact
                  component={lazyLoad('./views/Detail/Detail')}
                />
                <Route
                  path="/federated"
                  component={() => {
                    var token = new URLSearchParams(window.location.search).get('t');
                    if (token !== null && token !== undefined) {
                      //Auth - Fetch
                      storeTokenData({ token: token ?? '' });
                    }
                    return <Redirect to={`/app`} />;
                  }}
                />

                <Route
                  exact
                  path="/logout"
                  component={() => {
                    sessionStorage.clear();
                    localStorage.clear();
                    window.location.href = `${process.env.REACT_APP_IDENTITY}/SignOut?ref=${window.location.origin}`;
                    return <Redirect to="/login" />;
                  }}
                />
                <Route
                  path="/SignOut"
                  component={() => {
                    return <Redirect to={`/login`} />;
                  }}
                />
                <Route render={() => <Redirect to="/404" />} />
              </Switch>
            </AppProvider>
          </BrowserRouter>
          <SnackbarQueue messages={queue.messages} />
        </RWMC>
        <ToastContainer />
      </QueryClientProvider>
    </ApolloProvider>
  );
}

const lazyLoad = (path: string) => {
  return React.lazy(() => import(`${path}`));
};

const NormalRoute: React.FC<RouteProps> = ({ path, exact, component }) => {
  const Component = component;
  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        return (
          <GuestGuard>
            <CoreLayout>
              <Suspense fallback={null}>
                <Component {...props} />
              </Suspense>
            </CoreLayout>
          </GuestGuard>
        );
      }}></Route>
  );
};

const AuthenticatedRoute: React.FC<RouteProps> = ({ path, exact, component }) => {
  const Component = component;
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => (
        <AuthGuard>
          <AuthenticatedLayout>
            <Suspense fallback={null}>
              <Component {...props} />
            </Suspense>
          </AuthenticatedLayout>
        </AuthGuard>
      )}></Route>
  );
};

export default App;
