import React from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';
import { TextField } from '@rmwc/textfield';
import { Checkbox } from '@rmwc/checkbox';
import { Select } from '@rmwc/select';
import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';
import { useQuery } from '@apollo/client';

import {
  useAccountSearchLazyQuery,
  useSiteSearchLazyQuery,
  useBuildingSearchLazyQuery,
  useLoadSearchLazyQuery,
  useFeedSearchLazyQuery,
  useInsertChannelMutation,
  useInsertChannelMetadataMutation,
  useChannelKeySearchLazyQuery,
  ChannelKeySearchDocument,
} from 'generated/graphql';
import {
  ChannelTypeEnum,
  intervalFrequency,
  intervalMins,
  NatureTypeEnum,
  queue,
  StatusEnum,
  SummationTypeEnum,
  timezones,
  uniqueChannelKey,
  unitToSummationType,
  uomTypes,
  useAppState,
  useImperativeQuery,
  ValueTypeEnum as ValueTypeEnumEnum,
} from 'utilities';
import { Autocomplete } from 'components';

import styles from './CustomModal.module.scss';

export const AddChannelModal = ({ open, setOpen }: any) => {
  const { createFeedsConfigModbusEntries } = useAppState();
  const history = useHistory();
  const {
    formState,
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    setValue,
    getValues,
    watch,
  } = useForm();
  const { errors, isSubmitting } = formState;
  const [Account, Site, Building, Load, Feed, ValueTypeEnum, UnitOfMeasure] = watch([
    'Account',
    'Site',
    'Building',
    'Load',
    'Feed',
    'ValueTypeEnum',
    'NatureType',
    'UnitOfMeasure',
  ]);
  const [searchAccounts, { data: AccountSearchData, loading: AccountSearchLoading }] = useAccountSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [searchSites, { data: SiteSearchData, loading: SiteSearchLoading }] = useSiteSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [searchBuildings, { data: BuildingSearchData, loading: BuildingSearchLoading }] = useBuildingSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [searchLoads, { data: LoadSearchData, loading: LoadSearchLoading }] = useLoadSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [searchFeeds, { data: FeedSearchData, loading: FeedSearchLoading }] = useFeedSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const channelKeySearchQuery = useImperativeQuery(ChannelKeySearchDocument);

  const [insertFn] = useInsertChannelMutation();
  const [insertChannelMetadataFn] = useInsertChannelMetadataMutation();
  const statusFields = [];
  for (let k in Object.keys(StatusEnum)) {
    if (StatusEnum[k]) {
      statusFields.push({ label: StatusEnum[k], value: Number(k) });
    }
  }

  const summationTypeFields = [];
  for (let k in Object.keys(SummationTypeEnum)) {
    if (SummationTypeEnum[k]) {
      summationTypeFields.push({ label: SummationTypeEnum[k], value: Number(k) });
    }
  }

  const channelTypeFields = [];
  for (let k in Object.keys(ChannelTypeEnum)) {
    if (ChannelTypeEnum[k]) {
      channelTypeFields.push({ label: ChannelTypeEnum[k], value: Number(k) });
    }
  }

  const valueTypeEnumFields = [];
  for (let k in Object.keys(ValueTypeEnumEnum)) {
    if (ValueTypeEnumEnum[k]) {
      valueTypeEnumFields.push({ label: ValueTypeEnumEnum[k], value: Number(k) });
    }
  }

  const natureTypeEnumFields = [];
  for (let k in Object.keys(NatureTypeEnum)) {
    if (NatureTypeEnum[k]) {
      natureTypeEnumFields.push({ label: NatureTypeEnum[k], value: Number(k) });
    }
  }

  const createDeltaCalculatedChannel = async (variables: any) => {
    try {
      // variables = cumulative channel
      // clone = new calculated channel
      const clone = JSON.parse(JSON.stringify(variables));
      clone.object.ChannelId = uuidv4();
      clone.object.ChannelKey = uniqueChannelKey();
      clone.object.Label += ` Delta`;
      clone.object.ValueTypeEnum = ValueTypeEnumEnum.ActualForInterval;
      clone.object.Type = ChannelTypeEnum.Calculated;
      clone.object.Formula = variables.object.ChannelKey;
      if (variables.object.IntervalFrequency === 'LooseFrequency') {
        clone.object.IntervalFrequency = 'Daily';
        clone.object.IntervalMins = 1440;
      }
      clone.object.ChannelsRules = {
        data: {
          RuleId: uuidv4(),
          SourceChannelId: variables.object.ChannelId,
          SourceChannelKey: variables.object.ChannelKey,
          Status: variables.object.Status,
          CreatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
          UpdatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
        },
      };
      const { data: resultData, errors } = await insertFn({ variables: clone });
      if (errors?.length > 0) {
        throw new Error(errors[0].message);
      }
      // insert Channel Metadata
      await insertChannelMetadataFn({ variables: { object: { ChannelId: clone.object.ChannelId } } });
      queue.clearAll();
      queue.notify({
        icon: 'done',
        title: 'Success!',
        body: `Channel Delta Added!`,
      });
    } catch (ex) {
      queue.clearAll();
      queue.notify({
        icon: 'priority_high',
        title: 'Oh oh, error!',
        body: ex.message,
      });
    }
  };

  const onSubmit = async (data: any) => {
    const output = await channelKeySearchQuery({ ChannelKey: data.ChannelKey || channelKey });
    if (output?.data?.dw_Channels?.length > 0) {
      queue.clearAll();
      queue.notify({
        icon: 'priority_high',
        title: 'Oh oh, error!',
        body: 'This channel key already exists, please use another one to continue',
      });
      return;
    }
    try {
      const variables = {
        object: {
          ...data,
          ChannelKey: data.ChannelKey || channelKey,
          LoadId: data.Load?.LoadId,
          FeedId: data.Feed?.FeedId,
          IntervalMins: intervalMins[intervalFrequency.indexOf(data.IntervalFrequency)],
          EnableAlerts:
            Number(data.ValueTypeEnum) === ValueTypeEnumEnum.Cumulative ? false : data.EnableAlerts ? true : false,
          ChannelId: uuidv4(),
          CreatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
          UpdatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
        },
      };
      if (!data.NatureType) {
        variables.object.NatureType = NatureTypeEnum.Consumption;
      }
      delete variables.object.Account;
      delete variables.object.Site;
      delete variables.object.Building;
      delete variables.object.Load;
      delete variables.object.Feed;
      delete variables.object.createFeedsConfigModbusEntries;

      const { data: resultData, errors } = await insertFn({ variables });
      if (errors?.length > 0) {
        throw new Error(errors[0].message);
      }
      queue.clearAll();
      queue.notify({
        icon: 'done',
        title: 'Success!',
        body: `Channel Added!`,
      });

      // insert Channel Metadata
      await insertChannelMetadataFn({ variables: { object: { ChannelId: variables.object.ChannelId } } });

      if (data?.createFeedsConfigModbusEntries) {
        await createFeedsConfigModbusEntries([variables.object]);
      }

      if (Number(data?.ValueTypeEnum) === ValueTypeEnumEnum.Cumulative) {
        await createDeltaCalculatedChannel(variables);
      }

      setOpen(false);
      setTimeout(() => {
        // window.location.reload();
        history.push('/app/detail/channels/' + resultData.insert_dw_Channels_one.ChannelId);
      }, 1000);
    } catch (ex) {
      queue.clearAll();
      queue.notify({
        icon: 'priority_high',
        title: 'Oh oh, error!',
        body: ex.message,
      });
      setError('api', { type: 'manual', message: ex.message });
    }
  };

  const onUnitChange = (unit) => {
    let map = unitToSummationType[unit];
    setValue('UnitOfMeasure', unit);
    if (map) {
      const test = summationTypeFields.find((x) => x.label === map);
      setValue('SummationType', test.value.toString());
    }
  };

  let accountDefaultValue = null as any;
  let siteDefaultValue = null as any;
  let buildingDefaultValue = null as any;
  let loadDefaultValue = null as any;
  let feedDefaultValue = null as any;
  const lastDetail = JSON.parse(sessionStorage.getItem('lastDetail'));
  if (lastDetail?.data['LoadId'] && lastDetail?.data['Label']) {
    accountDefaultValue = lastDetail.data.Building.Site.Account;
    siteDefaultValue = lastDetail.data.Building.Site;
    buildingDefaultValue = lastDetail.data.Building;
    loadDefaultValue = lastDetail.data;
  } else if (lastDetail?.data['BuildingId'] && lastDetail?.data['Label']) {
    accountDefaultValue = lastDetail.data.Site.Account;
    siteDefaultValue = lastDetail.data.Site;
    buildingDefaultValue = lastDetail.data;
  } else if (lastDetail?.data['SiteId'] && lastDetail?.data['Label']) {
    accountDefaultValue = lastDetail.data.Account;
    siteDefaultValue = lastDetail.data;
  } else if (lastDetail?.data['AccountId'] && lastDetail?.data['Label']) {
    accountDefaultValue = lastDetail.data;
  } else if (lastDetail?.data['Building']) {
    accountDefaultValue = lastDetail.data.Building?.Site.Account;
    siteDefaultValue = lastDetail.data.Building?.Site;
    buildingDefaultValue = lastDetail.data.Building;
  }

  const channelKey = uniqueChannelKey();

  React.useEffect(() => {
    if (loadDefaultValue) {
      setValue('Load', loadDefaultValue);
      setValue('Building', loadDefaultValue.Building);
      setValue('Site', loadDefaultValue.Building.Site);
      setValue('Account', loadDefaultValue.Building.Site.Account);
      searchFeeds({ variables: { AccountId: loadDefaultValue.Building.Site.Account.AccountId, search: `%%` } });
    } else if (buildingDefaultValue) {
      setValue('Building', buildingDefaultValue);
      setValue('Site', buildingDefaultValue.Site);
      setValue('Account', buildingDefaultValue.Site.Account);
      searchLoads({ variables: { BuildingId: buildingDefaultValue.BuildingId, search: `%%` } });
      searchFeeds({ variables: { AccountId: buildingDefaultValue.Site.Account.AccountId, search: `%%` } });
    } else if (siteDefaultValue) {
      setValue('Site', siteDefaultValue);
      setValue('Account', siteDefaultValue.Account);
      searchBuildings({ variables: { SiteId: siteDefaultValue.SiteId, search: `%%` } });
      searchFeeds({ variables: { AccountId: siteDefaultValue.Account.AccountId, search: `%%` } });
    } else if (accountDefaultValue) {
      setValue('Account', accountDefaultValue);
      searchSites({ variables: { AccountId: accountDefaultValue.AccountId, search: `%%` } });
      searchFeeds({ variables: { AccountId: accountDefaultValue.AccountId, search: `%%` } });
    } else {
      searchAccounts({ variables: { search: `%%` } });
      searchSites({ variables: { search: `%%` } });
      searchBuildings({ variables: { search: `%%` } });
      searchFeeds({ variables: { search: `%%` } });
    }
    // eslint-disable-next-line
  }, ['open']);

  return (
    <div className={styles.customModal}>
      <Dialog
        className={styles.addLoad}
        open={open}
        onClose={(evt) => {
          reset();
          clearErrors();
          if (open) {
            setOpen(false);
          }
        }}>
        <form onSubmit={handleSubmit(onSubmit)} data-private autoComplete="off">
          <DialogTitle>Add Channel</DialogTitle>
          <DialogContent>
            <div className={styles.row}>
              <Controller
                name="Account"
                control={control}
                rules={{
                  required: 'Please select an account',
                }}
                render={({ field }) => (
                  <Autocomplete
                    label="Account"
                    data={AccountSearchData?.dw_Accounts || []}
                    labelKey="Label"
                    valueKey="AccountId"
                    loading={AccountSearchLoading}
                    getData={searchAccounts}
                    isDisabled={accountDefaultValue}
                    value={Account}
                    callback={(item) => {
                      field.onChange(item?.FullData);
                      if (item?.FullData) {
                        const newQuery = { variables: { AccountId: item.FullData?.AccountId, search: `%%` } };
                        searchSites(newQuery);
                        searchFeeds(newQuery);
                      }
                      setValue('Site', null);
                      setValue('Building', null);
                      setValue('Load', null);
                      setValue('Feed', null);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.Account?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.Account.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <Controller
                name="Site"
                control={control}
                rules={{
                  required: 'Please select a site',
                }}
                render={({ field }) => (
                  <Autocomplete
                    label="Site"
                    data={SiteSearchData?.dw_Sites || []}
                    labelKey="Label"
                    valueKey="SiteId"
                    loading={SiteSearchLoading}
                    value={Site}
                    isDisabled={siteDefaultValue || !Account}
                    getData={(query: any) => {
                      const account = getValues('Account');
                      const newQuery = { ...query };
                      newQuery.variables.AccountId = account?.AccountId;
                      searchSites(newQuery);
                    }}
                    callback={(item) => {
                      field.onChange(item?.FullData);
                      if (item?.FullData) {
                        const newQuery = { variables: { SiteId: item.FullData?.SiteId, search: `%%` } };
                        searchBuildings(newQuery);
                      }
                      setValue('Building', null);
                      setValue('Load', null);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.Site?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.Site.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <Controller
                name="Building"
                control={control}
                rules={{
                  required: 'Please select a building',
                }}
                render={({ field }) => (
                  <Autocomplete
                    label="Building"
                    data={BuildingSearchData?.dw_Buildings || []}
                    labelKey="Label"
                    valueKey="BuildingId"
                    loading={BuildingSearchLoading}
                    value={Building}
                    isDisabled={buildingDefaultValue || !Site}
                    getData={(query: any) => {
                      const site = getValues('Site');
                      const newQuery = { ...query };
                      newQuery.variables.SiteId = site?.SiteId;
                      searchBuildings(newQuery);
                    }}
                    callback={(item) => {
                      field.onChange(item?.FullData);
                      if (item?.FullData) {
                        const newQuery = { variables: { BuildingId: item.FullData?.BuildingId, search: `%%` } };
                        searchLoads(newQuery);
                      }
                      setValue('Load', null);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.Building?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.Building.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <Controller
                name="Load"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    label="Load"
                    data={LoadSearchData?.dw_Loads || []}
                    labelKey="Label"
                    valueKey="LoadId"
                    loading={LoadSearchLoading}
                    value={Load}
                    isDisabled={loadDefaultValue || !Building}
                    getData={(query: any) => {
                      const building = getValues('Building');
                      const newQuery = { ...query };
                      newQuery.variables.BuildingId = building?.BuildingId;
                      searchLoads(newQuery);
                    }}
                    callback={(item) => {
                      field.onChange(item?.FullData);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.Load?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.Load.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <Controller
                name="Feed"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    label="Feed"
                    data={
                      FeedSearchData?.dw_Feeds.map((x) => {
                        return {
                          FeedId: x.FeedId,
                          Label: `${x.Label} - ${x.Building?.Label} ${x.Notes ? `(${x.Notes})` : ''}`,
                          FeedsType: x.FeedsType,
                        };
                      }) || []
                    }
                    labelKey="Label"
                    valueKey="FeedId"
                    loading={FeedSearchLoading}
                    value={Feed}
                    isDisabled={feedDefaultValue || !Account}
                    getData={(query: any) => {
                      const account = getValues('Account');
                      const newQuery = { ...query };
                      newQuery.variables.AccountId = account?.AccountId;
                      searchFeeds(newQuery);
                    }}
                    callback={(item) => {
                      field.onChange(item?.FullData);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.Feed?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.Feed.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <TextField label="Label" name="Label" outlined required {...register('Label')} floatLabel={true} />
              {errors.Label && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a label</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <TextField
                label="ChannelKey"
                name="ChannelKey"
                outlined
                required
                floatLabel={true}
                defaultValue={channelKey}
                {...register('ChannelKey')}
              />
              {errors.Label && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a channel key</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <Controller
                name="UnitOfMeasure"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={'kWh'}
                render={({ field }) => (
                  <Select
                    label="Unit Of Measure"
                    name="UnitOfMeasure"
                    outlined
                    required
                    options={uomTypes}
                    defaultValue={'kWh'}
                    {...field}
                    onChange={(e) => {
                      // @ts-ignore
                      onUnitChange(e.target.value);
                    }}
                  />
                )}
              />
              {errors.UnitOfMeasure && (
                <div className={styles.error}>
                  <Typography use="caption">Please select a valid Unit Of Measure</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <Controller
                name="SummationType"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={'1'}
                render={({ field }) => (
                  <Select
                    label="Summation Type"
                    name="SummationType"
                    outlined
                    required
                    options={summationTypeFields}
                    defaultValue={'1'}
                    {...field}
                  />
                )}
              />
              {errors.SummationType && (
                <div className={styles.error}>
                  <Typography use="caption">Please select a valid Summation Type</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <Controller
                name="TimeZone"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={timezones[0]}
                render={({ field }) => (
                  <Select
                    label="TimeZone"
                    name="TimeZone"
                    outlined
                    required
                    options={timezones}
                    defaultValue={timezones[0]}
                    {...field}
                  />
                )}
              />
              {errors.TimeZone && (
                <div className={styles.error}>
                  <Typography use="caption">Please select a valid Time Zone</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <Controller
                name="IntervalFrequency"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={intervalFrequency[1]}
                render={({ field }) => (
                  <Select
                    label="Interval Frequency"
                    name="IntervalFrequency"
                    outlined
                    required
                    options={intervalFrequency}
                    defaultValue={intervalFrequency[1]}
                    {...field}
                  />
                )}
              />
              {errors.IntervalFrequency && (
                <div className={styles.error}>
                  <Typography use="caption">Please select a valid Interval Frequency </Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <Controller
                name="Type"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={'1'}
                render={({ field }) => (
                  <Select
                    label="Type"
                    name="Type"
                    outlined
                    required
                    options={channelTypeFields}
                    defaultValue={'1'}
                    {...field}
                  />
                )}
              />
              {errors.Status && (
                <div className={styles.error}>
                  <Typography use="caption">Please select a valid type</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <Controller
                name="ValueTypeEnum"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={'1'}
                render={({ field }) => (
                  <Select
                    label="Value Type Enum"
                    name="ValueTypeEnum"
                    outlined
                    required
                    options={valueTypeEnumFields}
                    defaultValue={'1'}
                    onChange={(e: any) => {
                      const val = Number(e.target.value);
                      if (val === ValueTypeEnumEnum.Cumulative) {
                        setValue('EnableAlerts', false);
                      }
                      return true;
                    }}
                    {...field}
                  />
                )}
              />
              {errors.ValueTypeEnum && (
                <div className={styles.error}>
                  <Typography use="caption">Please select a valid value type</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <Controller
                name="NatureType"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={'1'}
                render={({ field }) => (
                  <Select
                    label="Nature Type Enum"
                    name="NatureType"
                    outlined
                    required
                    options={natureTypeEnumFields}
                    defaultValue={'1'}
                    {...field}
                  />
                )}
              />
              {errors.ValueTypeEnum && (
                <div className={styles.error}>
                  <Typography use="caption">Please select a valid nature type</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <Controller
                name="Status"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={'1'}
                render={({ field }) => (
                  <Select
                    label="Status"
                    name="Status"
                    outlined
                    required
                    options={statusFields}
                    defaultValue={'1'}
                    {...field}
                  />
                )}
              />
              {errors.Status && (
                <div className={styles.error}>
                  <Typography use="caption">Please select a valid status</Typography>
                </div>
              )}
            </div>

            {Number(ValueTypeEnum) !== ValueTypeEnumEnum.Cumulative && (
              <div className={styles.row}>
                <Controller
                  name="EnableAlerts"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      label="Enable Alerts"
                      {...field}
                      disabled={Number(ValueTypeEnum) === ValueTypeEnumEnum.Cumulative}
                    />
                  )}
                />
              </div>
            )}

            {Feed?.FeedsType?.Label === 'ESP Hub Connect' && (
              <div className={styles.row}>
                <Controller
                  name="createFeedsConfigModbusEntries"
                  control={control}
                  render={({ field }) => <Checkbox label="Create Feeds Config Channels?" {...field} />}
                />
              </div>
            )}

            <div className={styles.row}>
              {errors.api && (
                <div className={styles.error} style={{ marginBottom: '16px' }}>
                  <Typography use="caption">{errors.api.message}</Typography>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-between' }}>
            <Button
              type="button"
              label="Close"
              disabled={formState.isSubmitting}
              outlined
              onClick={() => {
                setOpen(false);
                clearErrors();
                reset();
              }}
            />
            <Button
              raised
              disabled={formState.isSubmitting}
              label={formState.isSubmitting ? 'Saving' : 'Save'}
              icon={formState.isSubmitting ? <CircularProgress theme="secondary" /> : null}
              onClick={() => {
                clearErrors();
              }}
              type="submit"
            />
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AddChannelModal;
