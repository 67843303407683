import React from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';
import { Select } from '@rmwc/select';
import { TextField } from '@rmwc/textfield';
import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';

import {
  useAccountSearchLazyQuery,
  useSiteSearchLazyQuery,
  useBuildingSearchLazyQuery,
  useLoadSearchLazyQuery,
  useChannelSearchLazyQuery,
  useInsertFeedConfigModbusMutation,
  useFeedSearchLazyQuery,
} from 'generated/graphql';
import { queue, FeedsConfigStatusEnum, FeedsRegisterTypeEnum } from 'utilities';
import { Autocomplete } from 'components';

import styles from './CustomModal.module.scss';

export const AddFeedsConfigModbusModal = ({ open, setOpen }: any) => {
  const history = useHistory();
  const { formState, control, register, handleSubmit, setError, clearErrors, reset, setValue, getValues, watch } =
    useForm();
  const { errors } = formState;
  const [Account, Site, Building, Load, Channel, Feed] = watch([
    'Account',
    'Site',
    'Building',
    'Load',
    'Channel',
    'Feed',
  ]);
  const [searchAccounts, { data: AccountSearchData, loading: AccountSearchLoading }] = useAccountSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [searchSites, { data: SiteSearchData, loading: SiteSearchLoading }] = useSiteSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [searchBuildings, { data: BuildingSearchData, loading: BuildingSearchLoading }] = useBuildingSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [searchLoads, { data: LoadSearchData, loading: LoadSearchLoading }] = useLoadSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [searchChannels, { data: ChannelSearchData, loading: ChannelSearchLoading }] = useChannelSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [searchFeeds, { data: FeedSearchData, loading: FeedSearchLoading }] = useFeedSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [insertFn] = useInsertFeedConfigModbusMutation();
  const statusFields = [];
  for (let k in Object.keys(FeedsConfigStatusEnum)) {
    if (FeedsConfigStatusEnum[k]) {
      statusFields.push({ label: FeedsConfigStatusEnum[k], value: Number(k) });
    }
  }
  const typeFields = [];
  for (let k in Object.keys(FeedsRegisterTypeEnum)) {
    if (FeedsRegisterTypeEnum[k]) {
      typeFields.push({ label: FeedsRegisterTypeEnum[k], value: Number(k) });
    }
  }

  const onSubmit = async (data: any) => {
    try {
      const variables = {
        object: {
          ...data,
          ChannelId: data.Channel?.ChannelId,
          FeedId: data.Feed?.FeedId,
          ReadFrequencySeconds: Number(data.ReadFrequencySeconds),
          RegisterCount: Number(data.RegisterCount),
          RegisterType: Number(data.RegisterType),
          RegisterAddress: Number(data.RegisterAddress),
          IsBigEndian: true,
          CalculateDelta: false,
          CreatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
          UpdatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
        },
      };
      delete variables.object.Account;
      delete variables.object.Site;
      delete variables.object.Building;
      delete variables.object.Load;
      delete variables.object.Channel;
      delete variables.object.Feed;
      const { data: resultData, errors } = await insertFn({ variables });
      if (errors?.length > 0) {
        throw new Error(errors[0].message);
      }
      queue.clearAll();
      queue.notify({
        icon: 'done',
        title: 'Success!',
        body: `Feeds Config Modbus Added!`,
      });

      setOpen(false);
      setTimeout(() => {
        // window.location.reload();
        history.push(
          '/app/detail/feedsConfigModbus/' +
          resultData.insert_dw_FeedsConfigModbus_one.ChannelId +
          '|' +
          resultData.insert_dw_FeedsConfigModbus_one.FeedId
        );
      }, 1000);
    } catch (ex) {
      queue.clearAll();
      queue.notify({
        icon: 'priority_high',
        title: 'Oh oh, error!',
        body: ex.message,
      });
      setError('api', { type: 'manual', message: ex.message });
    }
  };

  let accountDefaultValue = null as any;
  let siteDefaultValue = null as any;
  let buildingDefaultValue = null as any;
  let loadDefaultValue = null as any;
  let channelDefaultValue = null as any;
  let feedDefaultValue = null as any;
  const lastDetail = JSON.parse(sessionStorage.getItem('lastDetail'));
  if (lastDetail?.data['ChannelId'] && lastDetail?.data['Label']) {
    accountDefaultValue = lastDetail.data.Load.Building.Site.Account;
    siteDefaultValue = lastDetail.data.Load.Building.Site;
    buildingDefaultValue = lastDetail.data.Load.Building;
    loadDefaultValue = lastDetail.data.Load;
    channelDefaultValue = lastDetail.data;
  } else if (lastDetail?.data['FeedId'] && lastDetail?.data['Label']) {
    accountDefaultValue = lastDetail.data.Building.Site.Account;
    siteDefaultValue = lastDetail.data.Building.Site;
    buildingDefaultValue = lastDetail.data.Building;
    // loadDefaultValue = lastDetail.data;
  } else if (lastDetail?.data['LoadId'] && lastDetail?.data['Label']) {
    accountDefaultValue = lastDetail.data.Building.Site.Account;
    siteDefaultValue = lastDetail.data.Building.Site;
    buildingDefaultValue = lastDetail.data.Building;
    loadDefaultValue = lastDetail.data;
  } else if (lastDetail?.data['BuildingId'] && lastDetail?.data['Label']) {
    accountDefaultValue = lastDetail.data.Site.Account;
    siteDefaultValue = lastDetail.data.Site;
    buildingDefaultValue = lastDetail.data;
  } else if (lastDetail?.data['SiteId'] && lastDetail?.data['Label']) {
    accountDefaultValue = lastDetail.data.Account;
    siteDefaultValue = lastDetail.data;
  } else if (lastDetail?.data['AccountId'] && lastDetail?.data['Label']) {
    accountDefaultValue = lastDetail.data;
  }

  React.useEffect(() => {
    if (channelDefaultValue) {
      setValue('Channel', channelDefaultValue);
      setValue('Load', channelDefaultValue.Load);
      setValue('Building', channelDefaultValue.Load.Building);
      setValue('Site', channelDefaultValue.Load.Building.Site);
      setValue('Account', channelDefaultValue.Load.Building.Site.Account);
      searchFeeds({
        variables: { AccountId: channelDefaultValue?.Load.Building.Site.Account.AccountId, search: `%%` },
      });
    } else if (loadDefaultValue) {
      setValue('Load', loadDefaultValue);
      setValue('Building', loadDefaultValue.Building);
      setValue('Site', loadDefaultValue.Building.Site);
      setValue('Account', loadDefaultValue.Building.Site.Account);
      searchChannels({ variables: { LoadId: loadDefaultValue.LoadId, search: `%%` } });
      searchFeeds({ variables: { AccountId: loadDefaultValue.Building.Site.Account.AccountId, search: `%%` } });
    } else if (buildingDefaultValue) {
      setValue('Building', buildingDefaultValue);
      setValue('Site', buildingDefaultValue.Site);
      setValue('Account', buildingDefaultValue.Site.Account);
      searchLoads({ variables: { BuildingId: buildingDefaultValue.BuildingId, search: `%%` } });
      searchFeeds({ variables: { AccountId: buildingDefaultValue.Site.Account.AccountId, search: `%%` } });
    } else if (siteDefaultValue) {
      setValue('Site', siteDefaultValue);
      setValue('Account', siteDefaultValue.Account);
      searchBuildings({ variables: { SiteId: siteDefaultValue.SiteId, search: `%%` } });
      searchFeeds({ variables: { AccountId: siteDefaultValue.Account.AccountId, search: `%%` } });
    } else if (accountDefaultValue) {
      setValue('Account', accountDefaultValue);
      searchSites({ variables: { AccountId: accountDefaultValue.AccountId, search: `%%` } });
      searchFeeds({ variables: { AccountId: accountDefaultValue.AccountId, search: `%%` } });
    } else {
      searchAccounts({ variables: { search: `%%` } });
    }
    // eslint-disable-next-line
  }, ['open']);

  return (
    <div className={styles.customModal}>
      <Dialog
        className={styles.addLoad}
        open={open}
        onClose={(evt) => {
          reset();
          clearErrors();
          if (open) {
            setOpen(false);
          }
        }}>
        <form onSubmit={handleSubmit(onSubmit)} data-private autoComplete="off">
          <DialogTitle>Add Feed Config Modbus</DialogTitle>
          <DialogContent>
            <div className={styles.row}>
              <Controller
                name="Account"
                control={control}
                rules={{
                  required: 'Please select an account',
                }}
                render={({ field }) => (
                  <Autocomplete
                    label="Account"
                    data={AccountSearchData?.dw_Accounts || []}
                    labelKey="Label"
                    valueKey="AccountId"
                    loading={AccountSearchLoading}
                    getData={searchAccounts}
                    isDisabled={accountDefaultValue}
                    value={Account}
                    callback={(item) => {
                      field.onChange(item?.FullData);
                      if (item?.FullData) {
                        const newQuery = { variables: { AccountId: item.FullData?.AccountId, search: `%%` } };
                        searchSites(newQuery);
                        searchFeeds(newQuery);
                      }
                      setValue('Site', null);
                      setValue('Building', null);
                      setValue('Load', null);
                      setValue('Channel', null);
                      setValue('Feed', null);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.Account?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.Account.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <Controller
                name="Site"
                control={control}
                rules={{
                  required: 'Please select a site',
                }}
                render={({ field }) => (
                  <Autocomplete
                    label="Site"
                    data={SiteSearchData?.dw_Sites || []}
                    labelKey="Label"
                    valueKey="SiteId"
                    loading={SiteSearchLoading}
                    value={Site}
                    isDisabled={siteDefaultValue || !Account}
                    getData={(query: any) => {
                      const account = getValues('Account');
                      const newQuery = { ...query };
                      newQuery.variables.AccountId = account?.AccountId;
                      searchSites(newQuery);
                    }}
                    callback={(item) => {
                      field.onChange(item?.FullData);
                      if (item?.FullData) {
                        const newQuery = { variables: { SiteId: item.FullData?.SiteId, search: `%%` } };
                        searchBuildings(newQuery);
                      }
                      setValue('Building', null);
                      setValue('Load', null);
                      setValue('Channel', null);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.Site?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.Site.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <Controller
                name="Building"
                control={control}
                rules={{
                  required: 'Please select a building',
                }}
                render={({ field }) => (
                  <Autocomplete
                    label="Building"
                    data={BuildingSearchData?.dw_Buildings || []}
                    labelKey="Label"
                    valueKey="BuildingId"
                    loading={BuildingSearchLoading}
                    value={Building}
                    isDisabled={buildingDefaultValue || !Site}
                    getData={(query: any) => {
                      const site = getValues('Site');
                      const newQuery = { ...query };
                      newQuery.variables.SiteId = site?.SiteId;
                      searchBuildings(newQuery);
                    }}
                    callback={(item) => {
                      field.onChange(item?.FullData);
                      if (item?.FullData) {
                        const newQuery = { variables: { BuildingId: item.FullData?.BuildingId, search: `%%` } };
                        searchLoads(newQuery);
                      }
                      setValue('Load', null);
                      setValue('Channel', null);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.Building?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.Building.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <Controller
                name="Load"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    label="Load"
                    data={LoadSearchData?.dw_Loads || []}
                    labelKey="Label"
                    valueKey="LoadId"
                    loading={LoadSearchLoading}
                    value={Load}
                    isDisabled={loadDefaultValue || !Building}
                    getData={(query: any) => {
                      const building = getValues('Building');
                      const newQuery = { ...query };
                      newQuery.variables.BuildingId = building?.BuildingId;
                      searchLoads(newQuery);
                    }}
                    callback={(item) => {
                      field.onChange(item?.FullData);
                      if (item?.FullData) {
                        const newQuery = { variables: { LoadId: item.FullData?.LoadId, search: `%%` } };
                        searchChannels(newQuery);
                      }
                      setValue('Channel', null);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.Load?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.Load.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <Controller
                name="Channel"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    label="Channel"
                    data={
                      ChannelSearchData?.dw_Channels.map((x) => {
                        return {
                          ChannelId: x.ChannelId,
                          Label: `${x.Label} ${x.ChannelKey ? `(${x.ChannelKey})` : ''}`,
                        };
                      }) || []
                    }
                    labelKey="Label"
                    valueKey="ChannelId"
                    loading={ChannelSearchLoading}
                    value={Channel}
                    isDisabled={channelDefaultValue || !Load}
                    getData={(query: any) => {
                      const load = getValues('Load');
                      const newQuery = { ...query };
                      newQuery.variables.LoadId = load?.LoadId;
                      searchLoads(newQuery);
                    }}
                    callback={(item) => {
                      field.onChange(item?.FullData);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.Channel?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.Channel.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <Controller
                name="Feed"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    label="Feed"
                    data={
                      FeedSearchData?.dw_Feeds.map((x) => {
                        return {
                          FeedId: x.FeedId,
                          Label: `${x.Label} - ${x.Building?.Label} ${x.Notes ? `(${x.Notes})` : ''}`,
                          FeedsType: x.FeedsType,
                        };
                      }) || []
                    }
                    labelKey="Label"
                    valueKey="FeedId"
                    loading={FeedSearchLoading}
                    value={Feed}
                    isDisabled={feedDefaultValue || !Account}
                    getData={(query: any) => {
                      const account = getValues('Account');
                      const newQuery = { ...query };
                      newQuery.variables.AccountId = account?.AccountId;
                      searchFeeds(newQuery);
                    }}
                    callback={(item) => {
                      field.onChange(item?.FullData);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.Feed?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.Feed.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <TextField
                label="Read Frequency Seconds"
                name="ReadFrequencySeconds"
                outlined
                type="number"
                required
                {...register('ReadFrequencySeconds')}
                floatLabel={true}
              />
              {errors.ReadFrequencySeconds && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a Read Frequency Seconds</Typography>
                </div>
              )}
            </div>
            <div className={styles.row}>
              <TextField
                label="Register Address"
                name="RegisterAddress"
                outlined
                required
                type="number"
                {...register('RegisterAddress')}
                floatLabel={true}
              />
              {errors.RegisterAddress && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a Register Address</Typography>
                </div>
              )}
            </div>
            <div className={styles.row}>
              <TextField
                label="Register Count"
                name="RegisterCount"
                outlined
                required
                type="number"
                {...register('RegisterCount')}
                floatLabel={true}
              />
              {errors.RegisterCount && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a Register Count </Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <Controller
                name="RegisterType"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={'0'}
                render={({ field }) => (
                  <Select
                    label="Register Type"
                    name="RegisterType"
                    outlined
                    required
                    options={typeFields}
                    defaultValue={'0'}
                    {...field}
                  />
                )}
              />
              {errors.Status && (
                <div className={styles.error}>
                  <Typography use="caption">Please select a valid Register Type</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <Controller
                name="Status"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={'1'}
                render={({ field }) => (
                  <Select
                    label="Status"
                    name="Status"
                    outlined
                    required
                    options={statusFields}
                    defaultValue={'1'}
                    {...field}
                  />
                )}
              />
              {errors.Status && (
                <div className={styles.error}>
                  <Typography use="caption">Please select a valid status</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              {errors.api && (
                <div className={styles.error} style={{ marginBottom: '16px' }}>
                  <Typography use="caption">{errors.api.message}</Typography>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-between' }}>
            <Button
              type="button"
              label="Close"
              disabled={formState.isSubmitting}
              outlined
              onClick={() => {
                setOpen(false);
                clearErrors();
                reset();
              }}
            />
            <Button
              raised
              disabled={formState.isSubmitting}
              label={formState.isSubmitting ? 'Saving' : 'Save'}
              icon={formState.isSubmitting ? <CircularProgress theme="secondary" /> : null}
              onClick={() => {
                clearErrors();
              }}
              type="submit"
            />
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AddFeedsConfigModbusModal;
