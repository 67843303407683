import React from 'react';

import { DrawerAppContent } from '@rmwc/drawer';
import { Portal } from '@rmwc/base';
import { Icon } from '@rmwc/icon';
import { DrawerComponent, NavbarComponent, Modal } from 'components';
import { inIframe } from 'utilities';
import styles from './AuthenticatedLayout.module.scss';

let resizeTimer = null as any;
export const AuthenticatedLayout: React.FC = ({ children }) => {
  const [open, setDrawerOpen] = React.useState(window.innerWidth > 841 ? true : false);
  const [navOpen, setNavOpen] = React.useState(false);
  const toggleDrawer = () => {
    setDrawerOpen(!open);
  };

  const isInIframe = inIframe();

  // Eventlistener
  window.addEventListener('resize', () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      setDrawerOpen(window.innerWidth > 841);
    }, 150);
  });

  return (
    <div className={`${styles['authenticated-layout']} ${isInIframe ? styles.inIframe : null}`}>
      <NavbarComponent toggleDrawer={toggleDrawer} open={navOpen} setOpen={setNavOpen} />
      <div className={styles['content']} onClick={() => setNavOpen(false)}>
        <DrawerComponent open={open} />
        <a
          href="#"
          className={`${styles.drawerToggle} ${open ? styles.open : styles.closed}`}
          onClick={(e) => {
            e.preventDefault();
            toggleDrawer();
          }}>
          <Icon icon={open ? 'chevron_left' : 'chevron_right'} />
        </a>
        <DrawerAppContent className={`${styles['drawer-content']} ${open ? styles.open : styles.closed}`}>
          {children}
        </DrawerAppContent>
      </div>

      <Modal />

      <Portal />
    </div>
  );
};

export default React.memo(AuthenticatedLayout);
