import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';
import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';
import { TextField } from '@rmwc/textfield';
import { Checkbox } from '@rmwc/checkbox';
import { Select } from '@rmwc/select';
import { queue, RoleEnum, useAppState } from 'utilities';
import { useAccountContactRolesSearchLazyQuery } from 'generated/graphql';
import { Autocomplete } from 'components';

import styles from './CustomModal.module.scss';

export const AddContactModal = ({ open, setOpen }: any) => {
  const { addContact } = useAppState();
  const { formState, control, handleSubmit, setError, clearErrors, reset, setValue, getValues, register } = useForm();
  const { errors } = formState;
  const [
    searchAccounts,
    { data: AccountSearchData, loading: AccountSearchLoading },
  ] = useAccountContactRolesSearchLazyQuery({ fetchPolicy: 'no-cache' });

  const roleFields = [];
  for (let k in Object.keys(RoleEnum)) {
    if (RoleEnum[k]) {
      roleFields.push({ label: RoleEnum[k], value: Number(k) });
    }
  }

  const lastDetail = JSON.parse(sessionStorage.getItem('lastDetail'));
  let accountDefaultValue = null as any;
  if (lastDetail?.data['AccountId'] && lastDetail?.data['Label']) {
    accountDefaultValue = lastDetail.data;
    setTimeout(() => {
      setValue('Account', {
        label: accountDefaultValue.Label,
        value: accountDefaultValue.AccountId,
        FullData: accountDefaultValue,
      });
    }, 0);
  }

  const onSubmit = async (data: any) => {
    try {
      const result = await addContact(data.Account?.FullData.AccountId, data.EmailAddress, Number(data.Role));
      // const result = await insertFn({ variables: { objects: variables } });
      if (!result) {
        throw new Error('There was a problem adding this contact. Reload the page and try again');
      }
      queue.clearAll();
      queue.notify({
        icon: 'done',
        title: 'Success!',
        body: `Account/Contact Roles Added!`,
      });
      setOpen(false);
      setTimeout(() => {
        // window.location.reload();
        // history.push('/app/detail/contacts/' + resultData.insert_dw_Contacts_one.ContactId);
      }, 1000);
    } catch (ex) {
      queue.clearAll();
      queue.notify({
        icon: 'priority_high',
        title: 'Oh oh, error!',
        body: ex.message,
      });
      setError('api', { type: 'manual', message: ex.message });
    }
  };

  React.useEffect(() => {
    searchAccounts({ variables: { search: `%%` } });
    // eslint-disable-next-line
  }, ['open']);

  return (
    <div className={styles.customModal}>
      <Dialog
        className={styles.addContactRole}
        open={open}
        onClose={(evt) => {
          reset();
          clearErrors();
          if (open) {
            setOpen(false);
          }
        }}>
        <form onSubmit={handleSubmit(onSubmit)} data-private autoComplete="off">
          <DialogTitle>Add Contact</DialogTitle>
          <DialogContent>
            <div className={styles.row}>
              <Controller
                name="Account"
                control={control}
                rules={{
                  required: 'Please select an account',
                }}
                render={({ field }) => (
                  <Autocomplete
                    label="Account"
                    data={AccountSearchData?.dw_Accounts || []}
                    labelKey="Label"
                    valueKey="AccountId"
                    loading={AccountSearchLoading}
                    getData={(query: any) => {
                      const contact = getValues('Contact');
                      const newQuery = { ...query };
                      newQuery.variables.ContactId = contact?.value;
                      searchAccounts(newQuery);
                    }}
                    defaultValue={accountDefaultValue}
                    callback={(item) => {
                      field.onChange(item);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.AccountId?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.AccountId.message}</Typography>
                </div>
              ) : null}
            </div>

            <div className={styles.row}>
              <TextField
                label="Email Address"
                name="EmailAddress"
                outlined
                required
                {...register('EmailAddress')}
                floatLabel={true}
              />
              {errors.EmailAddress && (
                <div className={styles.error}>
                  <Typography use="caption">Please enter a email address</Typography>
                </div>
              )}
            </div>

            <div className={styles.row}>
              <Controller
                name="Role"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={'1'}
                render={({ field }) => (
                  <Select
                    label="Role"
                    name="Role"
                    outlined
                    required
                    options={roleFields}
                    defaultValue={'1'}
                    {...field}
                  />
                )}
              />
            </div>

            <div className={styles.row}>
              {errors.api && (
                <div className={styles.error}>
                  <Typography use="caption">{errors.api.message}</Typography>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-between' }}>
            <Button
              type="button"
              label="Close"
              disabled={formState.isSubmitting}
              outlined
              onClick={() => {
                setOpen(false);
                clearErrors();
                reset();
              }}
            />
            <Button
              raised
              disabled={formState.isSubmitting}
              label={formState.isSubmitting ? 'Saving' : 'Save'}
              icon={formState.isSubmitting ? <CircularProgress theme="secondary" /> : null}
              onClick={() => {
                clearErrors();
              }}
              type="submit"
            />
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AddContactModal;
