import AddReportModal from './AddReport';
import AddContactModal from './AddContact';
import AddContactRoleModal from './AddContactRole';
import AddAccountModal from './AddAccount';
import AddSiteModal from './AddSite';
import AddBuildingModal from './AddBuilding';
import AddLoadModal from './AddLoad';
import AddChannelModal from './AddChannel';
import AddFeedModal from './AddFeed';
import AddProjectModal from './AddProject';
import SearchAccountModal from './SearchAccount';
import SearchSiteModal from './SearchSite';
import SearchBuildingModal from './SearchBuilding';
import SearchContactModal from './SearchContact';
import SearchLoadModal from './SearchLoad';
import SearchFeedModal from './SearchFeed';
import SearchLoadTypeModal from './SearchLoadType';
import SearchLoadServiceModal from './SearchLoadService';
import SearchFeedsTypeModal from './SearchFeedsType';
import AddChannelRuleModal from './AddChannelRule';
import AddChannelsMappingsModal from './AddChannelsMapping';
import AddFeedsConfigModbusModal from './AddFeedsConfigModbus';
import AddFeedsConfigBacNetModal from './AddFeedsConfigBacNet';
import AddDashboardModal from './AddDashboard';
import AddAccountAddressModal from './AddAccountAddress';
import AddAccountSubscriptionModal from './AddAccountSubscription';
import AddGroupModal from './AddGroup';
import AddGroupsSiteModal from './AddGroupsSite';
import TriggerDimensionsModal from './TriggerDimensions';
import TriggerCalculatedChannelsModal from './TriggerCalculatedChannels';
import BulkChangeLoadsFeedModal from './BulkChangeLoadsFeed';
import ImportCsvModal from './ImportCsv';
import ImportCsvLoadModal from './ImportCsvLoad';
import ImportCsvChannelModal from './ImportCsvChannel';
import NotificationChartModal from './NotificationChartModal';
import BulkChangeNotificationGroupsModal from './BulkChangeNotificationGroupsModal';
import ExportAnalyticsModal from './ExportAnalyticsModal';
import NotificationRollingCountChartModal from './NotificationRollingCountChartModal';
import OfflineLoggersModal from './OfflineLoggers';
import MissingNotificationsModal from './MissingNotificationsModal';
import CorrelationReportModal from './CorrelationReportModal';

import { useAppState } from 'utilities';

export const Modal = () => {
  const { openModal, additionalModalProps, toggleModal } = useAppState();
  return (
    <>
      {openModal === 'missingNotificationsChartModal' && (
        <MissingNotificationsModal
          open={openModal === 'missingNotificationsChartModal'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('missingNotificationsChartModal', open);
          }}
        />
      )}

      {openModal === 'offlineLoggers' && (
        <OfflineLoggersModal
          open={openModal === 'offlineLoggers'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('offlineLoggers', open);
          }}
        />
      )}

      {openModal === 'exportAnalytics' && (
        <ExportAnalyticsModal
          open={openModal === 'exportAnalytics'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('exportAnalytics', open);
          }}
        />
      )}

      {openModal === 'bulkChangeNotificationGroupsModal' && (
        <BulkChangeNotificationGroupsModal
          open={openModal === 'bulkChangeNotificationGroupsModal'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('bulkChangeNotificationGroupsModal', open);
          }}
        />
      )}

      {openModal === 'notificationChart' && (
        <NotificationChartModal
          open={openModal === 'notificationChart'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('notificationChart', open);
          }}
        />
      )}

      {openModal === 'notificationRollingCountChart' && (
        <NotificationRollingCountChartModal
          open={openModal === 'notificationRollingCountChart'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('notificationRollingCountChart', open);
          }}
        />
      )}

      {openModal === 'triggerDimensions' && (
        <TriggerDimensionsModal
          open={openModal === 'triggerDimensions'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('triggerDimensions', open);
          }}
        />
      )}

      {openModal === 'triggerCalculatedChannels' && (
        <TriggerCalculatedChannelsModal
          open={openModal === 'triggerCalculatedChannels'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('triggerCalculatedChannels', open);
          }}
        />
      )}

      {openModal === 'addReport' && (
        <AddReportModal
          open={openModal === 'addReport'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('addReport', open);
          }}
        />
      )}

      {openModal === 'addContact' && (
        <AddContactModal
          open={openModal === 'addContact'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('addContact', open);
          }}
        />
      )}
      {openModal === 'addContactRole' && (
        <AddContactRoleModal
          open={openModal === 'addContactRole'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('addContactRole', open);
          }}
        />
      )}
      {openModal === 'addAccount' && (
        <AddAccountModal
          open={openModal === 'addAccount'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('addAccount', open);
          }}
        />
      )}
      {openModal === 'addAccountAddress' && (
        <AddAccountAddressModal
          open={openModal === 'addAccountAddress'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('addAccountAddress', open);
          }}
        />
      )}

      {openModal === 'addAccountSubscription' && (
        <AddAccountSubscriptionModal
          open={openModal === 'addAccountSubscription'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('addAccountSubscription', open);
          }}
        />
      )}
      {openModal === 'addGroup' && (
        <AddGroupModal
          open={openModal === 'addGroup'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('addGroup', open);
          }}
        />
      )}
      {openModal === 'addGroupsSite' && (
        <AddGroupsSiteModal
          open={openModal === 'addGroupsSite'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('addGroupsSite', open);
          }}
        />
      )}
      {openModal === 'addSite' && (
        <AddSiteModal
          open={openModal === 'addSite'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('addSite', open);
          }}
        />
      )}
      {openModal === 'addBuilding' && (
        <AddBuildingModal
          open={openModal === 'addBuilding'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('addBuilding', open);
          }}
        />
      )}
      {openModal === 'addLoad' && (
        <AddLoadModal
          open={openModal === 'addLoad'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('addLoad', open);
          }}
        />
      )}
      {openModal === 'addChannel' && (
        <AddChannelModal
          open={openModal === 'addChannel'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('addChannel', open);
          }}
        />
      )}
      {openModal === 'addFeed' && (
        <AddFeedModal
          open={openModal === 'addFeed'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('addFeed', open);
          }}
        />
      )}
      {openModal === 'addProject' && (
        <AddProjectModal
          open={openModal === 'addProject'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('addProject', open);
          }}
        />
      )}
      {openModal === 'addChannelRule' && (
        <AddChannelRuleModal
          open={openModal === 'addChannelRule'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('addChannelRule', open);
          }}
        />
      )}

      {openModal === 'addChannelsMappings' && (
        <AddChannelsMappingsModal
          open={openModal === 'addChannelsMappings'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('addChannelsMappings', open);
          }}
        />
      )}

      {openModal === 'addFeedsConfigModbus' && (
        <AddFeedsConfigModbusModal
          open={openModal === 'addFeedsConfigModbus'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('addFeedsConfigModbus', open);
          }}
        />
      )}

      {openModal === 'addFeedsConfigBacNet' && (
        <AddFeedsConfigBacNetModal
          open={openModal === 'addFeedsConfigBacNet'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('addFeedsConfigBacNet', open);
          }}
        />
      )}

      {openModal === 'addDashboard' && (
        <AddDashboardModal
          open={openModal === 'addDashboard'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('addDashboard', open);
          }}
        />
      )}

      {openModal === 'searchAccount' && (
        <SearchAccountModal
          open={openModal === 'searchAccount'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('searchAccount', open);
          }}
        />
      )}
      {openModal === 'searchSite' && (
        <SearchSiteModal
          open={openModal === 'searchSite'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('searchSite', open);
          }}
        />
      )}
      {openModal === 'searchBuilding' && (
        <SearchBuildingModal
          open={openModal === 'searchBuilding'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('searchBuilding', open);
          }}
        />
      )}
      {openModal === 'searchContact' && (
        <SearchContactModal
          open={openModal === 'searchContact'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('searchContact', open);
          }}
        />
      )}

      {openModal === 'searchLoad' && (
        <SearchLoadModal
          open={openModal === 'searchLoad'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('searchLoad', open);
          }}
        />
      )}
      {openModal === 'searchFeed' && (
        <SearchFeedModal
          open={openModal === 'searchFeed'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('searchFeed', open);
          }}
        />
      )}
      {openModal === 'searchLoadsType' && (
        <SearchLoadTypeModal
          open={openModal === 'searchLoadsType'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('searchLoadsType', open);
          }}
        />
      )}
      {openModal === 'searchLoadsService' && (
        <SearchLoadServiceModal
          open={openModal === 'searchLoadsService'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('searchLoadsService', open);
          }}
        />
      )}
      {openModal === 'searchFeedsType' && (
        <SearchFeedsTypeModal
          open={openModal === 'searchFeedsType'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('searchFeedsType', open);
          }}
        />
      )}
      {openModal === 'bulkChangeLoadsFeed' && (
        <BulkChangeLoadsFeedModal
          open={openModal === 'bulkChangeLoadsFeed'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('bulkChangeLoadsFeed', open);
          }}
        />
      )}
      {openModal === 'importCsv' && (
        <ImportCsvModal
          open={openModal === 'importCsv'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('importCsv', open);
          }}
        />
      )}

      {openModal === 'importCsvLoad' && (
        <ImportCsvLoadModal
          open={openModal === 'importCsvLoad'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('importCsvLoad', open);
          }}
        />
      )}

      {openModal === 'importCsvChannel' && (
        <ImportCsvChannelModal
          open={openModal === 'importCsvChannel'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('importCsvChannel', open);
          }}
        />
      )}

      {openModal === 'CorrelationReportModal' && (
        <CorrelationReportModal
          open={openModal === 'CorrelationReportModal'}
          additionalModalProps={additionalModalProps}
          setOpen={(open: boolean) => {
            toggleModal('CorrelationReportModal', open);
          }}
        />
      )}
    </>
  );
};

export default Modal;
