import React from 'react';
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';
import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';

import { useBulkChangeChannelsFeedMutation, useFeedSearchAllLazyQuery } from 'generated/graphql';
import { Autocomplete } from 'components';

import styles from './CustomModal.module.scss';
import { queue, useAppState } from 'utilities';
import { Checkbox } from '@rmwc/checkbox';

export const SearchFeedModal = ({ open, setOpen, additionalModalProps }: any) => {
  const { createFeedsConfigModbusEntries } = useAppState();
  const { formState, control, handleSubmit, setError, clearErrors, reset, watch } = useForm();
  const { errors } = formState;
  const [Feed] = watch(['Feed']);
  const [searchFeeds, { data: FeedSearchData, loading: FeedSearchLoading }] = useFeedSearchAllLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [bulkChangeChannelsFeedFn] = useBulkChangeChannelsFeedMutation();

  React.useEffect(() => {
    searchFeeds({
      variables: {
        search: `%%`,
      },
    });
    // eslint-disable-next-line
  }, ['open']);

  const onSubmit = async (data: any) => {
    try {
      const variables = { ...additionalModalProps.variables };
      variables[additionalModalProps.updateFieldName] = data.Feed.FeedId;
      const { errors } = await additionalModalProps.updateFn({ variables });
      if (errors?.length > 0) {
        throw new Error(errors[0].message);
      }
      queue.clearAll();
      queue.notify({
        icon: 'done',
        title: 'Success!',
        body: `Feed Linked!`,
      });

      const channels = additionalModalProps?.variables?.Channels || [];
      if (data.UpdateChannelsFeed && channels.length > 0) {
        const channelIds = channels.map((x) => x.ChannelId);
        queue.notify({ icon: 'done', title: 'Success!', body: `Now changing Channels Feeds, please wait` });
        const changeChannelVariables = {
          ChannelIds: channelIds,
          FeedId: data.Feed?.FeedId,
          UpdatedOn: moment.utc().format('YYYY-MM-DDTHH:mm:00'),
        };
        const { errors: channelsErrors } = await bulkChangeChannelsFeedFn({ variables: changeChannelVariables });
        if (channelsErrors?.length > 0) {
          throw new Error(errors[0].message);
        }
        queue.notify({ icon: 'done', title: 'Success!', body: `Channels Feed Updated!` });
      }
      if (data.createFeedsConfigModbusEntries && channels.length > 0) {
        console.log(channels);
        const result = await createFeedsConfigModbusEntries(channels);
        setOpen(false);
        if (!result) {
          return;
        }
      }
      setOpen(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (ex) {
      queue.clearAll();
      queue.notify({
        icon: 'priority_high',
        title: 'Oh oh, error!',
        body: ex.message,
      });
      setError('api', { type: 'manual', message: ex.message });
    }
  };

  return (
    <div className={styles.customModal}>
      <Dialog
        className={styles.Contact}
        open={open}
        onClose={(evt) => {
          reset();
          clearErrors();
          if (open) {
            setOpen(false);
          }
        }}>
        <form onSubmit={handleSubmit(onSubmit)} data-private autoComplete="off">
          <DialogTitle>Search Feed</DialogTitle>
          <DialogContent>
            <div className={styles.row}>
              <Controller
                name="Feed"
                control={control}
                rules={{
                  required: 'Please select a feed',
                }}
                render={({ field }) => (
                  <Autocomplete
                    label="Feed"
                    data={
                      FeedSearchData?.dw_Feeds.map((x) => {
                        return {
                          FeedId: x.FeedId,
                          Label: `${x.Label} - ${x.Building?.Label} ${x.Notes ? `(${x.Notes})` : ''}`,
                          FeedsType: x.FeedsType,
                        };
                      }) || []
                    }
                    labelKey="Label"
                    valueKey="FeedId"
                    loading={FeedSearchLoading}
                    getData={(query: any) => {
                      const newQuery = { ...query };
                      newQuery.variables.AccountId = additionalModalProps.variables.AccountId;
                      searchFeeds(newQuery);
                    }}
                    value={Feed}
                    callback={(item) => {
                      field.onChange(item.FullData);
                      setTimeout(() => {
                        field.onBlur();
                      }, 0);
                    }}
                  />
                )}
              />
              {errors?.Feed?.message ? (
                <div className={styles.error}>
                  <Typography use="caption">{errors?.Feed.message}</Typography>
                </div>
              ) : null}
            </div>

            {additionalModalProps?.variables?.LoadId && (
              <div className={styles.row}>
                <Controller
                  name="UpdateChannelsFeed"
                  control={control}
                  defaultValue={true}
                  render={({ field }) => <Checkbox label="Update Channels Feed?" defaultChecked {...field} />}
                />
              </div>
            )}

            {additionalModalProps?.variables?.LoadId && Feed?.FeedsType?.Label === 'ESP Hub Connect' && (
              <div className={styles.row}>
                <Controller
                  name="createFeedsConfigModbusEntries"
                  control={control}
                  render={({ field }) => <Checkbox label="Create Feeds Config Channels?" {...field} />}
                />
              </div>
            )}

            <div className={styles.row}>
              {errors.api && (
                <div className={styles.error} style={{ marginBottom: '16px' }}>
                  <Typography use="caption">{errors.api.message}</Typography>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-between' }}>
            <Button
              type="button"
              label="Close"
              disabled={formState.isSubmitting}
              outlined
              onClick={() => {
                setOpen(false);
                clearErrors();
                reset();
              }}
            />
            <Button
              raised
              disabled={formState.isSubmitting}
              label={formState.isSubmitting ? 'Saving' : 'Save'}
              icon={formState.isSubmitting ? <CircularProgress theme="secondary" /> : null}
              onClick={() => {
                clearErrors();
              }}
              type="submit"
            />
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default SearchFeedModal;
