import React from 'react';
import axios from 'axios';
import nProgress from 'nprogress';
import { RouteComponentProps, withRouter } from 'react-router';

import {
  AreaDto,
  MainDto,
  ProfileDto,
  UserDto,
  AccountMetadataDto,
  NodeDto,
  utilityObj,
  typeObj,
  TriggerDimensionsDto,
  TriggerCalculatedChannelsDto,
  DimensionsDto,
  AnalyticsDto,
  AnalyticsRequestDto,
  AnalyticsGroupedResponseDto,
  ActivityDetailDto,
  ActivityDetailLoadDto,
  AnalyticsRequestSingleDto,
  AnalyticsConsumptionData,
  AnalyticsPridictionData,
  NotificationImpact,
} from './interfaces';
import { logout, queueRenewal } from './auth';
import { getParameterByName, mapChannelsToFeedsConfigModbusChannels, toCamel } from './utils';
import { BulkInsertFeedConfigModbusesDocument } from 'generated/graphql';
import apolloClient from 'apollo';
import queue from './queue';
import { CorrelationReport, UpsertContactDto } from 'utilities';

const API_ENV = process.env.REACT_APP_API_ENV || '';
const api = axios.create({ baseURL: `${API_ENV}/` });

// let cancelTokenChart = null as CancelTokenSource;

interface AppProviderProps extends RouteComponentProps {
  children: any;
}

export const defaultState: MainDto = {
  // API
  authenticated: false,
  authenticating: true,
  loadingAccountMetadata: true,
  user: null,
  // UI
  selectedAccount: null,
  openModal: null,
};

/**
 * Methods this file provides externally
 */
export interface StateMethods {
  getState: () => MainDto;
  checkAuthentication: () => void;
  getAccountMetadata: (accountId: string, accountLabel: string) => Promise<AreaDto[]>;
  triggerDimensions: (body: TriggerDimensionsDto) => Promise<boolean>;
  triggerCalculatedChannels: (body: TriggerCalculatedChannelsDto) => Promise<boolean>;
  createFeedsConfigModbusEntries: (channels: any[]) => Promise<boolean>;
  addContact: (accountId: string, emailAddress: string, role: number) => Promise<boolean>;
  upsertContact: (contact: UpsertContactDto) => Promise<boolean>;

  getAreasByType: (
    areas?: AreaDto[]
  ) => {
    account: AreaDto;
    groups: AreaDto[];
    sites: AreaDto[];
    buildings: AreaDto[];
    loads: AreaDto[];
  };
  getAreaById: (id: string, areas?: AreaDto[]) => AreaDto;
  toggleModal: (name: string, open: boolean, additionalModalProps?: any) => void;
  requestAnalyticsSingle: (requestParams: AnalyticsRequestSingleDto) => Promise<AnalyticsConsumptionData[]>;

  requestAnalytics: (requestParams: AnalyticsRequestDto[]) => Promise<AnalyticsGroupedResponseDto[]>;

  getNotificationGroupDetail: (accountId: string, notificationId: string) => Promise<any>;
  getNotificationGroupForecastData: (
    accountId: string,
    notificationId: string,
    loads: ActivityDetailLoadDto[]
  ) => Promise<any>;

  requestPredictionSingle: (accountId: string, notificationId: string) => Promise<AnalyticsPridictionData[]>;

  refreshImpacts: (accountId: string, notificationId: string) => Promise<NotificationImpact[]>;

  getCorrelationReport: (
    channelIds: string[],
    startDate: string,
    endDate: string,
    siteId?: string,
    includeWeather?: boolean
  ) => Promise<CorrelationReport[]>;

  getBuildingDocumentDetails: (accountId: string, buildingId: string, documentId: string) => Promise<string>;
  deleteBuildingDocument: (accountId: string, buildingId: string, documentId: string) => Promise<boolean>;
  uploadBuildingDocument: (accountId: string, buildingId: string, data: any) => Promise<boolean>;

  getLoadDocumentDetails: (accountId: string, loadId: string, documentId: string) => Promise<string>;
  deleteLoadDocument: (accountId: string, loadId: string, documentId: string) => Promise<boolean>;
  uploadLoadDocument: (accountId: string, loadId: string, data: any) => Promise<boolean>;
}

// Context Setup for Global Use
export interface AppContextProps extends MainDto, StateMethods { }
export const AppContext = React.createContext<Partial<AppContextProps>>(defaultState);
export const AppConsumer = AppContext.Consumer;
export const useAppState = () => React.useContext(AppContext);

/**
 * App provider that handles all logic around data and chart/tables
 */
export class AppProvider extends React.Component<AppProviderProps, MainDto> {
  public state: MainDto = { ...defaultState };
  public resizeTimeout = null as any;
  public popStateTimeout = null as any;

  /**
   * On component loading
   */
  public async componentDidMount() {
    this.checkAuthentication();
  }

  /**
   * Provies methods application state
   */
  public render() {
    const { children } = this.props;
    return (
      <AppContext.Provider
        value={{
          ...this.state,
          getState: () => this.state,
          checkAuthentication: this.checkAuthentication,
          getAccountMetadata: this.getAccountMetadata,
          triggerDimensions: this.triggerDimensions,
          triggerCalculatedChannels: this.triggerCalculatedChannels,
          createFeedsConfigModbusEntries: this.createFeedsConfigModbusEntries,
          getAreasByType: this.getAreasByType,
          getAreaById: this.getAreaById,
          toggleModal: this.toggleModal,
          requestAnalyticsSingle: this.requestAnalyticsSingle,
          requestAnalytics: this.requestAnalytics,
          getNotificationGroupDetail: this.getNotificationGroupDetail,
          getNotificationGroupForecastData: this.getNotificationGroupForecastData,
          getCorrelationReport: this.getCorrelationReport,
          getBuildingDocumentDetails: this.getBuildingDocumentDetails,
          deleteBuildingDocument: this.deleteBuildingDocument,
          uploadBuildingDocument: this.uploadBuildingDocument,
          getLoadDocumentDetails: this.getLoadDocumentDetails,
          deleteLoadDocument: this.deleteLoadDocument,
          uploadLoadDocument: this.uploadLoadDocument,
          addContact: this.addContact,
          upsertContact: this.upsertContact,
          requestPredictionSingle: this.requestPredictionSingle,
          refreshImpacts: this.refreshImpacts,
        }}>
        {children}
      </AppContext.Provider>
    );
  }

  private getStorageData = () => {
    const user = { profile: null } as UserDto;
    const token = localStorage.getItem('token') as string;
    // const sessionId = localStorage.getItem('sessionId') as string;
    let profile = JSON.parse(sessionStorage.getItem('profile')) as ProfileDto;
    return {
      user,
      token,
      // sessionId,
      profile,
    };
  };

  /**
   *
   */
  private checkAuthentication = async () => {
    const storageData = this.getStorageData();
    if (!storageData.token) {
      sessionStorage.clear();
      localStorage.clear();
      this.setState({ authenticated: false, authenticating: false, user: null });
      return false;
    } else {
      this.setState({ authenticated: true, authenticating: false });
    }
    // start loader
    nProgress.start();
    // load profile
    if (!storageData.profile || !storageData.profile.id) {
      const profileResult = await this.getProfile();
      if (profileResult) {
        storageData.user.profile = profileResult;
        storageData.user.profile.accounts.sort((a, b) => (a.label > b.label ? 1 : -1));
        this.setState({ user: storageData.user });
      } else {
        logout(true);
        return;
      }
    }

    // queue renewal now
    // queueRenewal();
    setTimeout(async () => {
      nProgress.done();
    }, 0);
  };

  /**
   * Trigger Dimensions api
   */
  private triggerDimensions = async (body: TriggerDimensionsDto) => {
    try {
      const token = localStorage.getItem('token');
      const { status } = await api.post<any>('/admin/dimensions/trigger', body, {
        headers: { Authorization: `Bearer ${token}` },
        validateStatus: (status) => status === 400 || status === 200 || status === 204,
      });
      if (status === 200 || status === 201 || status === 204) {
        return true;
      } else {
        throw Error();
      }
    } catch (error) {
      return false;
    }
  };

  /**
   * Trigger Calculated Channels api
   */
  private triggerCalculatedChannels = async (body: TriggerCalculatedChannelsDto) => {
    try {
      const token = localStorage.getItem('token');
      const { status } = await api.post<any>('/admin/calculatedchannels/trigger', body, {
        headers: { Authorization: `Bearer ${token}` },
        validateStatus: (status) => status === 400 || status === 200 || status === 204,
      });
      if (status === 200 || status === 201 || status === 204) {
        return true;
      } else {
        throw Error();
      }
    } catch (error) {
      return false;
    }
  };

  /**
   * Create Feeds Config Channels
   */
  private createFeedsConfigModbusEntries = async (channels: any[]) => {
    try {
      queue.notify({ icon: 'done', title: 'Success!', body: 'Adding feeds config channels, please wait!' });
      const bulkInsertVariables = mapChannelsToFeedsConfigModbusChannels(channels);
      await apolloClient.mutate({
        mutation: BulkInsertFeedConfigModbusesDocument,
        variables: {
          objects: bulkInsertVariables,
        },
      });
      queue.notify({ icon: 'done', title: 'Success!', body: 'Feeds Config Channels Created!' });
      return true;
    } catch (error) {
      queue.clearAll();
      queue.notify({
        icon: 'priority_high',
        title: 'Oh oh, error!',
        body:
          'There was an error with Feeds Config Channels, one or more may already exist. Please double check to ensure they were created correctly.',
      });
      return false;
    }
  };

  /**
   * Add Contact via API
   * @param accountId
   * @param emailAddress
   * @param role
   * @returns
   */
  private addContact = async (accountId: string, emailAddress: string, role: number) => {
    const token = localStorage.getItem('token');
    try {
      // contact.accountId = accountId;
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.put<any>(
        `/admin/contacts/create/${accountId}`,
        {
          email: emailAddress,
          role: role,
        },
        options
      );
      if (result && [200, 204].indexOf(result.status) > -1) {
        return true;
      } else {
        throw Error();
      }
    } catch (ex) {
      return false;
    }
  };

  /**
   * Upsert Contact
   */
  private upsertContact = async (contact: UpsertContactDto) => {
    const token = localStorage.getItem('token');
    try {
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = await api.put<UpsertContactDto>(`/admin/contactsrole`, contact, options);
      if (result && [200, 204].indexOf(result.status) > -1) {
        return true;
      } else {
        throw Error();
      }
    } catch (ex) {
      return false;
    }
  };

  /**
   * Get Profile using access token
   */
  private getProfile = async () => {
    try {
      const token = localStorage.getItem('token');
      const { data } = await api.get<ProfileDto>('profile', { headers: { Authorization: `Bearer ${token}` } });
      if (data && data.id) {
        localStorage.setItem('contactId', data.id);
        localStorage.setItem('displayName', data.particulars.displayName);
        localStorage.setItem('emailAddress', data.particulars.emailAddress);
        return data;
      } else {
        throw Error();
      }
    } catch (error) {
      return null;
    }
  };

  /**
   * Get account meta data
   * @param accountId
   * @param accountLabel
   * @returns
   */
  private getAccountMetadata = async (accountId: string, accountLabel: string) => {
    try {
      const token = localStorage.getItem('token');
      const { data } = await api.get<AccountMetadataDto>(`profile/${accountId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (data && data.id) {
        let areas = [] as AreaDto[];
        // add dashboard aka account level
        const dashboard = {
          id: data.id,
          name: 'Dashboard',
          // aka account
          type: 'dashboard',
          utilities: [],
          parentNames: [],
          parentIds: [],
          subareas: [],
        } as AreaDto;
        dashboard.subareas = this.convertNodesToAreas(data.nodes, [accountLabel], [data.id]);
        areas.push(dashboard);
        return areas;
      } else {
        throw Error();
      }
    } catch (error) {
      return null;
    }
  };

  /**
   * Convert nodes (api) to areas (ui), include parent names for breadcrumbs
   * @param nodes
   * @param parentNames
   */
  private convertNodesToAreas = (nodes: NodeDto[], parentNames: string[], parentIds: string[]) => {
    const areaId = getParameterByName('area') || '';
    const areas = nodes.map((node) => {
      const newNames = node.label === 'Dashboard' ? parentNames : parentNames.concat(node.label);
      const newIds = node.label === 'Dashboard' ? parentIds : parentIds.concat(node.id);
      const area = {
        id: node.id,
        name: node.label,
        type: typeObj[node.type],
        city: node.city,
        unit: node.unit,
        utility: utilityObj[node.utility],
        selected: areaId ? areaId.indexOf(node.id) > -1 : false,
        parentNames: parentNames,
        parentIds: parentIds,
        subareas: node.nodes.length > 0 ? this.convertNodesToAreas(node.nodes, newNames, newIds) : [],
        utilities: [],
        brushUtilities: [],
        tableUtilities: [],
      } as AreaDto;
      area.subareas.sort((a, b) => (a.name > b.name ? 1 : -1));
      return area;
    }) as AreaDto[];
    return areas.sort((a, b) => (a.name > b.name ? 1 : -1));
  };

  /**
   * Get area by id. Recursive through all areas.
   * @param id
   * @param areas
   */
  private getAreaById = (id: string, areas: AreaDto[]) => {
    if (areas.length === 0) {
      return null;
    }
    const foundArea = areas.find((x) => x.id === id);
    if (foundArea) {
      return foundArea;
    } else {
      return this.getAreaById(
        id,
        areas.flatMap((x) => x.subareas)
      );
    }
  };

  /**
   * Returns a break down of areas by type
   */
  private getAreasByType = (propAreas?: AreaDto[]) => {
    const areas = propAreas;
    const account = areas[0];
    const groups = [] as AreaDto[];
    const sites = [] as AreaDto[];
    const buildings = [] as AreaDto[];
    const loads = [] as AreaDto[];
    const innerFunc = (theAreas: AreaDto[]) => {
      theAreas.forEach((x) => {
        if (x.type === 'group') {
          groups.push(x);
        } else if (x.type === 'site') {
          sites.push(x);
        } else if (x.type === 'building') {
          buildings.push(x);
        } else if (x.type === 'load') {
          loads.push(x);
        }
      });
      const subareas = theAreas.flatMap((x) => x.subareas);
      if (subareas.length > 0) {
        innerFunc(theAreas.flatMap((x) => x.subareas));
      }
    };
    if (account && account.subareas && account.subareas.length > 0) {
      innerFunc(account.subareas);
    }
    return { account, groups, sites, buildings, loads };
  };

  private toggleModal = (name: string, open: boolean, additionalModalProps?: any) => {
    if (open) {
      this.setState({ openModal: name, additionalModalProps: additionalModalProps });
    } else {
      this.setState({ openModal: null, additionalModalProps: null });
    }
  };

  private requestAnalyticsSingle = async (requestParams: AnalyticsRequestSingleDto) => {
    const token = localStorage.getItem('token');
    try {
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const { data } = await api.post<AnalyticsDto>('analytics/usage', requestParams, options);
      const updatedData = toCamel(data) as AnalyticsDto;
      if (updatedData.dimensions?.length > 0) {
        return updatedData.dimensions[0].utilities[0].consumption;
      } else {
        return [] as AnalyticsConsumptionData[];
      }
    } catch (ex) {
      console.log(ex);
      return [];
    }
  };

  private requestPredictionSingle = async (accountId: string, notificationId: string) => {
    const token = localStorage.getItem('token');
    try {
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const { data } = await api.get<{ predictions: AnalyticsPridictionData[] }>(
        `/notifications/${accountId}/details/${notificationId}/predictions`,
        options
      );
      return data.predictions;
    } catch (ex) {
      return null;
    }
  };

  private refreshImpacts = async (accountId: string, notificationId: string) => {
    const token = localStorage.getItem('token');
    try {
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const { data } = await api.get<{ impacts: NotificationImpact[] }>(
        `/notifications/${accountId}/details/${notificationId}/impacts`,
        options
      );
      return data.impacts;
    } catch (ex) {
      return null;
    }
  };

  private requestAnalytics = async (requestParams: AnalyticsRequestDto[]) => {
    const token = localStorage.getItem('token');
    try {
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const allPromises = requestParams.map((params) => {
        return new Promise<DimensionsDto[]>(async (resolve, reject) => {
          try {
            let url = 'analytics/usage';
            if (params.valueTypeEnum === 3) {
              url = 'analytics/consumption';
            }
            const { data } = await api.post<AnalyticsDto>(url, params, options);
            const updatedData = toCamel(data) as AnalyticsDto;
            if (updatedData.dimensions?.length > 0) {
              resolve(updatedData.dimensions);
            } else {
              resolve([] as DimensionsDto[]);
            }
          } catch (ex) {
            resolve([] as DimensionsDto[]);
          }
        });
      });

      const results = await Promise.all(allPromises);

      const groupedResults = results.flatMap((result) => {
        return result.flatMap((item) => {
          return { id: item.id, utilities: item.utilities };
        });
      });

      // return results
      return groupedResults as AnalyticsGroupedResponseDto[];
    } catch (ex) {
      console.log(ex);
      return [];
    }
  };

  private getNotificationGroupDetail = async (accountId: string, notificationId: string) => {
    const token = localStorage.getItem('token');
    try {
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const { data } = await api.get<ActivityDetailDto>(
        `/notifications/${accountId}/${notificationId}?includeLoads=true`,
        options
      );
      // return results
      return data;
    } catch (ex) {
      return null;
    }
  };

  private getNotificationGroupForecastData = async (
    accountId: string,
    notificationId: string,
    loads: ActivityDetailLoadDto[]
  ) => {
    const token = localStorage.getItem('token');
    try {
      const options = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const baseUrl = `/notifications/${accountId}/${notificationId}`;
      const promises = loads.map((x) => {
        return new Promise(async (resolve) => {
          try {
            const result = await api.get<any>(`${baseUrl}/${x.loadId}?days=31`, options);
            if (result && result.data && result.data.actual && result.data.forecast) {
              resolve({
                actual: result.data.actual,
                forecast: result.data.forecast,
                loadId: x.loadId,
                utilityType: x.utilityType,
                isMainIncomer: x.isMainIncomer,
                expectedUsage: x.expectedUsage,
              });
            } else {
              throw Error('no data');
            }
          } catch (ex) {
            resolve({
              actual: [],
              forecast: [],
              loadId: [],
              utilityType: x.utilityType,
              isMainIncomer: x.isMainIncomer,
              expectedUsage: x.expectedUsage,
            });
          }
        });
      });
      const results = await Promise.allSettled(promises);
      // @ts-ignore
      return results.flatMap((x) => x.value);
    } catch (ex) {
      return null;
    }
  };

  private getCorrelationReport = async (
    channelIds: string[],
    startDate: string,
    endDate: string,
    siteId?: string,
    includeWeather?: boolean
  ) => {
    const token = localStorage.getItem('token');
    const result = await new Promise<any>(async (resolve) => {
      try {
        const innerResult = await fetch(`${API_ENV}/analytics/correlation`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            channel_ids: channelIds,
            start_date: startDate,
            end_date: endDate,
            site_id: siteId,
            include_weather: includeWeather,
          }),
        });

        const text = await innerResult.text();
        if (!innerResult) {
          throw Error('There was a problem parsing the json');
        }
        const json = JSON.parse(text.replace(/NaN/g, null));
        if (json?.errorMessage || json?.message) {
          throw Error(json?.errorMessage ? json?.errorMessage : json?.message || 'Unknown Error');
        } else {
          resolve(json);
        }
      } catch (ex) {
        queue.notify({
          icon: 'priority_high',
          title: 'Oh oh, error!',
          body: ex.message,
        });
        resolve([]);
      }
    });
    return result;
  };

  private getBuildingDocumentDetails = async (accountId: string, buildingId: string, documentId: string) => {
    const token = localStorage.getItem('token');
    const result = await new Promise<any>(async (resolve) => {
      try {
        const innerResult = await fetch(`${API_ENV}/building/${accountId}/${buildingId}/document/${documentId}`, {
          method: 'GET',
          redirect: 'manual',
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'content-type': 'application/json',
          },
        });

        const json = await innerResult.json();
        resolve(json.url);
      } catch (ex) {
        queue.notify({
          icon: 'priority_high',
          title: 'Oh oh, error!',
          body: ex.message,
        });
        resolve(null);
      }
    });
    return result;
  };

  private deleteBuildingDocument = async (accountId: string, buildingId: string, documentId: string) => {
    const token = localStorage.getItem('token');
    const result = await new Promise<any>(async (resolve) => {
      try {
        const innerResult = await fetch(
          `${API_ENV}/building/${accountId}/${buildingId}/document/${documentId}?status=3`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: 'application/json',
              'content-type': 'application/json',
            },
            body: JSON.stringify({ status: 3 }),
          }
        );
        if (innerResult.status === 204 || innerResult.status === 200) {
          resolve(true);
        } else {
          throw Error('Failed to delete document');
        }
      } catch (ex) {
        queue.notify({
          icon: 'priority_high',
          title: 'Oh oh, error!',
          body: ex.message,
        });
        resolve(false);
      }
    });
    return result;
  };

  private uploadBuildingDocument = async (accountId: string, buildingId: string, data: any) => {
    const token = localStorage.getItem('token');
    const result = await new Promise<any>(async (resolve) => {
      try {
        const innerResult = await fetch(`${API_ENV}/building/${accountId}/${buildingId}/upload`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
          },
          body: data,
        });
        if (innerResult.status === 204 || innerResult.status === 200) {
          resolve(true);
        } else {
          throw Error('Failed to upbuilding document');
        }
      } catch (ex) {
        queue.notify({
          icon: 'priority_high',
          title: 'Oh oh, error!',
          body: ex.message,
        });
        resolve(false);
      }
    });
    return result;
  };

  private getLoadDocumentDetails = async (accountId: string, loadId: string, documentId: string) => {
    const token = localStorage.getItem('token');
    const result = await new Promise<any>(async (resolve) => {
      try {
        const innerResult = await fetch(`${API_ENV}/building/${accountId}/loads/${loadId}/documents/${documentId}`, {
          method: 'GET',
          redirect: 'manual',
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'content-type': 'application/json',
          },
        });

        const json = await innerResult.json();
        resolve(json.url);
      } catch (ex) {
        queue.notify({
          icon: 'priority_high',
          title: 'Oh oh, error!',
          body: ex.message,
        });
        resolve(null);
      }
    });
    return result;
  };

  private deleteLoadDocument = async (accountId: string, loadId: string, documentId: string) => {
    const token = localStorage.getItem('token');
    const result = await new Promise<any>(async (resolve) => {
      try {
        const innerResult = await fetch(
          `${API_ENV}/building/${accountId}/loads/${loadId}/documents/${documentId}?status=3`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: 'application/json',
              'content-type': 'application/json',
            },
            body: JSON.stringify({ status: 3 }),
          }
        );
        if (innerResult.status === 204 || innerResult.status === 200) {
          resolve(true);
        } else {
          throw Error('Failed to delete document');
        }
      } catch (ex) {
        queue.notify({
          icon: 'priority_high',
          title: 'Oh oh, error!',
          body: ex.message,
        });
        resolve(false);
      }
    });
    return result;
  };

  private uploadLoadDocument = async (accountId: string, loadId: string, data: any) => {
    const token = localStorage.getItem('token');
    const result = await new Promise<any>(async (resolve) => {
      try {
        const innerResult = await fetch(`${API_ENV}/building/${accountId}/loads/${loadId}/upload`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
          },
          body: data,
        });
        if (innerResult.status === 204 || innerResult.status === 200) {
          resolve(true);
        } else {
          throw Error('Failed to upload document');
        }
      } catch (ex) {
        queue.notify({
          icon: 'priority_high',
          title: 'Oh oh, error!',
          body: ex.message,
        });
        resolve(false);
      }
    });
    return result;
  };
}

// @ts-ignore
export default withRouter(AppProvider);
